import styled from "@emotion/styled";
import { ReactNode } from "react";
import { color, Flex } from "ui-kit";

type Props = {
  children: ReactNode;
};

export const EmptyStateIcon = ({ children }: Props) => (
  <CircleWrapper alignItems="center" justifyContent="center">
    {children}
  </CircleWrapper>
);

export const EmptyStateContainer = ({ children }: Props) => (
  <Flex
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    minHeight="464px"
  >
    {children}
  </Flex>
);

const CircleWrapper = styled(Flex)`
  background-color: ${color.N100};
  border-radius: 100%;
  height: 183px;
  width: 183px;
  & svg {
    fill: ${color.N300};
  }
`;
