import styled from "@emotion/styled";
import {
  ChangeEvent,
  ElementType,
  FocusEvent,
  forwardRef,
  HTMLProps,
  memo,
  Ref,
  useMemo,
  useState,
} from "react";
import {
  hasDigit,
  hasLowercaseCharacter,
  hasMoreThan8Symbols,
  hasSpecialCharacter,
  hasUppercaseCharacter,
} from "shared-functions/strongPasswordValidation";
import { EyeIcon, EyeOffIcon, Flex } from "ui-kit";
import { v4 as uuidv4 } from "uuid";
import { ErrorMessage } from "../ErrorMessage";
import { Block, Grid } from "../Layout";
import { color } from "../theme";
import { Typography } from "../Typography";
import { InputElement, Label } from "./PureInput";

type InputProps = HTMLProps<HTMLInputElement> & {
  as?: ElementType;
  errorMessage?: string;
  hasErrors?: boolean;
  hasValue?: boolean;
  enableSecurityCheck?: boolean;
  // input has a native `required` property. But to bypass browser validation, need to rely on custom flag
  isRequired?: boolean;
};

export const PasswordInput = forwardRef(
  (props: InputProps, refObject: Ref<HTMLInputElement>) => (
    <InputComponent {...props} ref={null} refObject={refObject} />
  )
);

type Props = InputProps & {
  refObject?: Ref<HTMLInputElement>;
};

const InputComponent = memo(
  ({
    autoFocus,
    placeholder,
    className,
    disabled,
    label,
    name,
    errorMessage,
    hasErrors,
    refObject,
    enableSecurityCheck,
    onChange,
    onFocus,
    defaultValue,
    isRequired,
    ...props
  }: Props) => {
    const [inputValue, setInputValue] = useState(defaultValue || "");
    const [isPasswordVisible, togglePasswordVisibility] =
      useState<boolean>(false);
    const [isPasswordFocused, focusOnPassword] = useState<boolean>(false);
    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
      onChange && onChange(e);
    };
    const id = useMemo(() => uuidv4(), []);
    const arePasswordRulesVisible = useMemo(() => {
      return enableSecurityCheck ? isPasswordFocused || !!hasErrors : false;
    }, [enableSecurityCheck, hasErrors, isPasswordFocused]);
    return (
      <>
        <Block>
          {label && (
            <Block marginBottom={0.5}>
              <Label htmlFor={id}>
                {label}
                {isRequired && (
                  <Typography lineHeight="0" type="h4">
                    &nbsp;&#42;
                  </Typography>
                )}
              </Label>
            </Block>
          )}
          <Flex alignItems="center" position="relative">
            <InputElement
              {...props}
              ref={refObject}
              id={id}
              name={name}
              onChange={onInputChange}
              onFocus={(e: FocusEvent<HTMLInputElement>) => {
                focusOnPassword(true);
                onFocus && onFocus(e);
              }}
              type={isPasswordVisible ? "text" : "password"}
              autoFocus={autoFocus}
              placeholder={placeholder}
              className={className}
              disabled={disabled}
              hasErrors={hasErrors}
              defaultValue={defaultValue}
              hasValue={
                props.hasValue ||
                (!!inputValue && inputValue.toString().length > 0)
              }
            />
            <EyeContainer
              onClick={() => togglePasswordVisibility(!isPasswordVisible)}
              type="button"
            >
              {isPasswordVisible ? (
                <EyeIcon fill={color.N300} />
              ) : (
                <EyeOffIcon />
              )}
            </EyeContainer>
          </Flex>
        </Block>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <StrongPasswordRequirementsContainer
          isVisible={arePasswordRulesVisible}
        >
          <StrongPasswordRequirementsList
            gridColumnGap={8}
            gridTemplateColumns="repeat(2, 1fr)"
          >
            <StrongPasswordRequirementsItem
              type="h5"
              fontColor={resolveHintColorForRule({
                password: inputValue as string,
                hasErrors,
                rule: "lowercase",
              })}
            >
              One lowercase character
            </StrongPasswordRequirementsItem>
            <StrongPasswordRequirementsItem
              type="h5"
              fontColor={resolveHintColorForRule({
                password: inputValue as string,
                hasErrors,
                rule: "character",
              })}
            >
              One special character
            </StrongPasswordRequirementsItem>

            <StrongPasswordRequirementsItem
              type="h5"
              fontColor={resolveHintColorForRule({
                password: inputValue as string,
                hasErrors,
                rule: "uppercase",
              })}
            >
              One uppercase character
            </StrongPasswordRequirementsItem>
            <StrongPasswordRequirementsItem
              type="h5"
              fontColor={resolveHintColorForRule({
                password: inputValue as string,
                hasErrors,
                rule: "length",
              })}
            >
              8 characters minimum
            </StrongPasswordRequirementsItem>
            <StrongPasswordRequirementsItem
              type="h5"
              fontColor={resolveHintColorForRule({
                password: inputValue as string,
                hasErrors,
                rule: "digit",
              })}
            >
              One number
            </StrongPasswordRequirementsItem>
          </StrongPasswordRequirementsList>
        </StrongPasswordRequirementsContainer>
      </>
    );
  }
);

const EyeContainer = styled.button`
  all: unset;
  align-items: center;
  cursor: pointer;
  display: flex;
  position: absolute;
  right: 16px;
`;

type PasswordRulesState = {
  isVisible: boolean;
};

const StrongPasswordRequirementsContainer = styled(Block)<PasswordRulesState>`
  background-color: ${color.N100};
  border-radius: 4px;
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  margin-top: 24px;
  padding: 12px 16px 12px 32px;
`;

const StrongPasswordRequirementsList = Grid.withComponent("ul");

const StrongPasswordRequirementsItem = styled(Typography.withComponent("li"))`
  margin-bottom: 8px;
`;

type PasswordValidationRules =
  | "lowercase"
  | "uppercase"
  | "character"
  | "digit"
  | "length";

function resolveHintColorForRule({
  hasErrors,
  password,
  rule,
}: {
  hasErrors?: boolean;
  password: string;
  rule: PasswordValidationRules;
}): keyof typeof color {
  const getRuleValidator: {
    [key in PasswordValidationRules]: (password: string) => boolean;
  } = {
    lowercase: hasLowercaseCharacter,
    uppercase: hasUppercaseCharacter,
    character: hasSpecialCharacter,
    digit: hasDigit,
    length: hasMoreThan8Symbols,
  };
  const validate = getRuleValidator[rule];
  if (validate(password)) {
    return "G200";
  }
  if (hasErrors && !validate(password)) {
    return "R100";
  }
  return "N300";
}
