import styled from "@emotion/styled";
import { useDoctorAccountWasCreated } from "../useDoctorAccountWasCreated";
import { toDoctorProfileHomePage, useDoctorId } from "Doctor/Router";
import { memo, useRef } from "react";
import StepWizard from "react-step-wizard";
import { Block, boxShadow, color, Flex, Typography, UnifiedLink } from "ui-kit";
import { LicenseForm } from "./License";
import { Navigation } from "./Navigation";
import { PersonalInformationForm } from "./PersonalInformation";
import { ProfessionalInformationForm } from "./ProfessionalInformation";
import { Schedule } from "./Schedule";
import stagesTransitions from "./stages.module.css";

const transitions = {
  enterRight: `${stagesTransitions.animated} ${stagesTransitions.enterRight}`,
  enterLeft: `${stagesTransitions.animated} ${stagesTransitions.enterLeft}`,
  exitRight: `${stagesTransitions.animated} ${stagesTransitions.exitRight}`,
  exitLeft: `${stagesTransitions.animated} ${stagesTransitions.exitLeft}`,
};

export const CreateAccountPage = memo(() => {
  const navigationContainerNode = useRef<HTMLDivElement | null>(null);
  const doctorId = useDoctorId();
  const wasAccountCreated = useDoctorAccountWasCreated();

  if (wasAccountCreated) {
    return (
      <Block padding={7} textAlign="center">
        <Typography as="h1" type="h1">
          You have already created account.{" "}
          <UnifiedLink type="inherit" to={toDoctorProfileHomePage(doctorId)}>
            Go to home page
          </UnifiedLink>
        </Typography>
      </Block>
    );
  }

  return (
    <Main>
      <FormSection flexDirection="column" alignItems="center">
        <Block marginBottom={1}>
          <Typography as="h1" type="h1">
            Create account
          </Typography>
        </Block>
        <Block marginBottom={5}>
          <Block ref={navigationContainerNode} />
        </Block>
        <Block width="680px" overflow="hidden">
          <CreateAccountStages
            nav={<Navigation container={navigationContainerNode} />}
            transitions={transitions}
          >
            <PersonalInformationForm />
            <ProfessionalInformationForm />
            <LicenseForm />
            <Schedule />
          </CreateAccountStages>
        </Block>
      </FormSection>
    </Main>
  );
});

const Main = styled.main`
  background-color: ${color.N100};
  min-height: 100vh;
  padding: 48px;
`;

const FormSection = Flex.withComponent("section");

const CreateAccountStages = styled(StepWizard)`
  background-color: ${color.N0};
  border-radius: 8px;
  box-shadow: ${boxShadow.normal};
  padding: 40px;
  width: 100%;
`;
