import styled from "@emotion/styled";
import { differenceInYears } from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";
import { useStore } from "effector-react";
import { Gender } from "Patient/Model";
import { memo } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { StepWizardChildProps } from "react-step-wizard";
import { getCurrentTimezoneIANA } from "shared-functions/date";
import { checkSizeBelowLimit, useImageData } from "shared-functions/file";
import {
  AddPhotoIcon,
  Avatar,
  Block,
  Button,
  DateInput,
  ErrorMessage,
  errorNotification,
  fromStringToOption,
  Input,
  isFormatMatched,
  LoaderWithOverlay,
  maskedInputToDate,
  Option,
  PhoneNumberInput,
  phoneNumberLengthMatch,
  RadioGroup,
  Selectbox,
  toE164,
  Typography,
} from "ui-kit";
import {
  updatePersonalInformation as updatePatientPersonalInformation,
  usePatient,
} from "../patientSession";

const personTitles: Option[] = [
  { label: "Mr", value: "mr" },
  { label: "Mrs", value: "mrs" },
  { label: "Miss", value: "miss" },
  { label: "Ms", value: "ms" },
];

type PersonalInfomationFormData = {
  photo?: FileList | null;
  title: string;
  firstName: string;
  lastName: string;
  gender: Gender;
  DOB: string;
  phone?: string;
};

type PersonalInformationProps = Partial<StepWizardChildProps>;

export const PersonalInformation = memo(
  ({ nextStep, currentStep, totalSteps }: PersonalInformationProps) => {
    const personalInformation = usePatient()?.personalInformation;
    const {
      control,
      formState: { errors },
      handleSubmit,
      register,
    } = useForm<PersonalInfomationFormData>({
      defaultValues: {
        title: personalInformation?.title,
        firstName: personalInformation?.firstName,
        lastName: personalInformation?.lastName,
        gender: personalInformation?.gender,
        phone: personalInformation?.phone,
        DOB: personalInformation?.DOB
          ? format(
              utcToZonedTime(personalInformation.DOB, getCurrentTimezoneIANA()),
              "MM/dd/yyyy"
            )
          : undefined,
      },
    });

    const { preview, onChange: changePreview } = useImageData(
      personalInformation?.photo?.url
    );

    const { onChange: onPhotoChange, ...photoFormProps } = register("photo");

    const savePersonalInformation: SubmitHandler<
      PersonalInfomationFormData
    > = ({ photo, title, firstName, lastName, gender, DOB, phone }) => {
      updatePatientPersonalInformation({
        photo,
        title,
        firstName,
        lastName,
        gender,
        DOB: maskedInputToDate(DOB)?.toISOString() ?? "",
        phone: toE164(phone),
      })
        .then(nextStep)
        .catch((error: Error) => errorNotification(error.message));
    };

    const isLoading = useStore(updatePatientPersonalInformation.pending);

    return (
      <Form onSubmit={handleSubmit(savePersonalInformation)}>
        {isLoading && <LoaderWithOverlay />}
        <Block marginBottom={3} textAlign="center">
          <Typography as="h2" type="h2">
            Personal data
          </Typography>
        </Block>
        <Block textAlign="center">
          <input
            accept="image/*"
            id="add-photo"
            type="file"
            hidden
            onChange={(e) => {
              const updatedPhoto = e.target.files?.item(0);
              if (checkSizeBelowLimit({ file: updatedPhoto, limitMB: 15 })) {
                changePreview(updatedPhoto ?? null);
                onPhotoChange(e);
              } else {
                errorNotification("Please, add photo under 15MB");
              }
            }}
            {...photoFormProps}
          />
          <AddPhotoLabel htmlFor="add-photo">
            {preview ? (
              <AvatarImage width="140px" height="140px">
                <img src={preview} alt="patient avatar" />
              </AvatarImage>
            ) : (
              <AddPhotoIcon />
            )}
          </AddPhotoLabel>
        </Block>
        <Block marginBottom={3}>
          <Controller
            control={control}
            name="title"
            render={({ field: { onChange, value } }) => (
              <Selectbox
                autoFocus
                isClearable
                label="Title"
                options={personTitles}
                defaultValue={
                  value ? fromStringToOption(value, personTitles) : null
                }
                onChange={(selectedTitle) =>
                  selectedTitle
                    ? onChange((selectedTitle as Option).value)
                    : onChange("")
                }
                placeholder="Title"
                noOptionsMessage={() => "No title"}
              />
            )}
          />
        </Block>
        <Block marginBottom={3}>
          <Input
            defaultValue={personalInformation?.firstName}
            hasErrors={!!errors["firstName"]}
            errorMessage={errors["firstName"]?.message}
            label="First name"
            placeholder="First name"
            type="text"
            isRequired
            {...register("firstName", {
              required: "Please, enter your first name",
            })}
          />
        </Block>
        <Block marginBottom={3}>
          <Input
            defaultValue={personalInformation?.lastName}
            hasErrors={!!errors["lastName"]}
            errorMessage={errors["lastName"]?.message}
            label="Last name"
            placeholder="Last name"
            type="text"
            isRequired
            {...register("lastName", {
              required: "Please, enter your last name",
            })}
          />
        </Block>
        <Block marginBottom={3}>
          <RadioGroup
            defaultValue={personalInformation?.gender}
            labels={["Male", "Female", "Other"]}
            title="Gender"
            isRequired
            {...register("gender", {
              required: "Please, specify gender",
            })}
          />
          {errors["gender"] && (
            <ErrorMessage>{errors["gender"].message}</ErrorMessage>
          )}
        </Block>
        <Block marginBottom={3}>
          <DateInput
            defaultValue={
              personalInformation?.DOB
                ? format(
                    utcToZonedTime(
                      personalInformation.DOB,
                      getCurrentTimezoneIANA()
                    ),
                    "MM/dd/yyyy"
                  )
                : undefined
            }
            errorMessage={errors["DOB"]?.message}
            hasErrors={!!errors["DOB"]}
            label="Date of birth"
            isRequired
            {...register("DOB", {
              required: "Please, enter your date of birth",
              validate: {
                format: isFormatMatched,
                validateAdult,
              },
            })}
          />
        </Block>
        <Block marginBottom={7}>
          <PhoneNumberInput
            label="Phone number (SMS)"
            placeholder="Phone number"
            defaultValue={personalInformation?.phone}
            hasErrors={!!errors["phone"]}
            errorMessage={errors["phone"]?.message}
            {...register("phone", {
              validate: {
                length: phoneNumberLengthMatch,
              },
            })}
          />
        </Block>
        <Block textAlign="right">
          <Button filled type="submit">
            Next step {currentStep}/{totalSteps}
          </Button>
        </Block>
      </Form>
    );
  }
);

function validateAdult(dob: string) {
  // DOB can be an empty string
  const dobAsDate = maskedInputToDate(dob);
  const isYoungerThan18 = dobAsDate
    ? differenceInYears(Date.nowUniversal, dobAsDate) < 18
    : false;
  if (isYoungerThan18) {
    return "According to HIPAA, you should be above 18 years of age";
  }
  return true;
}

const Form = styled.form`
  position: relative;
  width: 100%;
`;

const AddPhotoLabel = styled.label`
  cursor: pointer;
  display: inline-block;
`;

const AvatarImage = styled(Avatar)`
  display: inline-block;
  margin: 16px auto 22px;
`.withComponent("span");
