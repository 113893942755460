import { apiServer } from "apiConnection";
import { AxiosError } from "axios";
import { createEffect, createEvent, createStore, sample } from "effector";
import { errorNotification } from "ui-kit";

type Settings = {
  email?: boolean;
  sms?: boolean;
};

export const loadNotificationsSettings = createEffect({
  name: "loadNotificationsSettings",
  handler: loadNotificationsSettingsRequest,
});

export const toggleEmailSetting = createEvent<boolean>("toggleEmailSetting");

const toggleEmailSettingFx = createEffect({
  name: "toggleEmailSetting",
  handler: async (settings: Settings) => {
    if (!settings.sms && !settings.email) {
      throw new Error(
        "By our policy, at least one type of notifications have to be enabled"
      );
    }
    const savedSettings = await saveNotificationsSettings(settings);
    return savedSettings.email || false;
  },
});

toggleEmailSettingFx.failData.watch((error) =>
  errorNotification(error.message)
);

export const toggleSMSSetting = createEvent<boolean>("toggleSMSSetting");

const toggleSMSSettingFx = createEffect({
  name: "toggleEmailSetting",
  handler: async (settings: Settings) => {
    if (!settings.sms && !settings.email) {
      throw new Error(
        "By our policy, at least one type of notifications have to be enabled"
      );
    }
    const savedSettings = await saveNotificationsSettings(settings);
    return savedSettings.sms || false;
  },
});

toggleSMSSettingFx.failData.watch((error) => errorNotification(error.message));

export const settings = createStore<Settings>({
  email: undefined,
  sms: undefined,
})
  .on(loadNotificationsSettings.doneData, (_, settings) => ({ ...settings }))
  .on(toggleEmailSetting, (settings, email) => ({
    ...settings,
    email,
  }))
  .on(toggleSMSSetting, (settings, sms) => ({
    ...settings,
    sms,
  }))
  .on(toggleEmailSettingFx.failData, (settings) => ({
    ...settings,
    email: !settings.email,
  }))
  .on(toggleSMSSettingFx.failData, (settings) => ({
    ...settings,
    sms: !settings.sms,
  }));

sample({
  source: settings,
  clock: toggleEmailSetting,
  fn: (settings: Settings, email: boolean) => ({
    ...settings,
    email,
  }),
  target: toggleEmailSettingFx,
});

sample({
  source: settings,
  clock: toggleSMSSetting,
  fn: (settings: Settings, sms: boolean) => ({
    ...settings,
    sms,
  }),
  target: toggleSMSSettingFx,
});

async function saveNotificationsSettings(settings: Settings) {
  try {
    const { data: savedSettings } = await apiServer.post<Settings>(
      "/api/doctor/notifications-settings",
      {
        settings,
      }
    );
    return savedSettings;
  } catch (error: unknown) {
    const message = (error as AxiosError<{ message: string }>).response?.data
      .message;
    throw new Error(message);
  }
}

async function loadNotificationsSettingsRequest() {
  try {
    const { data: settings } = await apiServer.get<Settings>(
      "/api/doctor/notifications-settings"
    );
    return settings;
  } catch (error: unknown) {
    const message = (error as AxiosError<{ message: string }>).response?.data
      .message;
    throw new Error(message);
  }
}
