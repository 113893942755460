import { ISO8601 } from "Doctor/Model";

export type Gender = "male" | "female" | "other";

export const ROLE_PATIENT = "patient";

export type PatientDTO = {
  objectId: string;
  customerId?: string; // Defined by payments integration
  defaultPaymentCardId: string; // Defined by payments integration. Need to pick a card to take payments from
  email: string;
  hasAccount: boolean;
  role: typeof ROLE_PATIENT;
  sessionToken?: string;
  personalInformation?: PersonalInformation;
  emergencyInformation?: EmergencyInformation;
};

export type Patient = {
  id: string;
  customerId?: string; // Defined by payments integration
  defaultPaymentCardId: string; // Defined by payments integration. Need to pick a card to take payments from
  email: string;
  hasAccount: boolean;
  role: typeof ROLE_PATIENT;
  sessionToken?: string;
  personalInformation?: PersonalInformation;
  emergencyInformation?: EmergencyInformation;
};

export type PersonalInformationPayload = Omit<PersonalInformation, "photo"> & {
  photo?: FileList | null;
};

type PersonalInformation = {
  photo?: {
    name: string;
    url: string;
  };
  title?: string;
  firstName: string;
  lastName: string;
  gender: Gender;
  DOB: ISO8601;
  phone?: string;
};

export type EmergencyInformation = {
  country?: string;
  state?: string;
  city?: string;
  zipCode?: string;
  address?: string;
  emergencyPhone?: string;
};

export type PatientProfileRouteParams = {
  patientId: string;
};

export function userToPatientAdapter(user: PatientDTO): Patient {
  return {
    id: user.objectId,
    customerId: user.customerId,
    defaultPaymentCardId: user.defaultPaymentCardId,
    email: user.email,
    hasAccount: user.hasAccount || false,
    role: ROLE_PATIENT,
    sessionToken: user.sessionToken,
    personalInformation: user.personalInformation
      ? {
          ...user.personalInformation,
          photo: user.personalInformation.photo
            ? {
                ...user.personalInformation.photo,
                url: decodeURIComponent(user.personalInformation.photo.url),
              }
            : undefined,
        }
      : undefined,
    emergencyInformation: user.emergencyInformation,
  };
}
