import styled from "@emotion/styled";
import { format, utcToZonedTime } from "date-fns-tz";
import { Patient } from "Patient/Model";
import { memo } from "react";
import { getCurrentTimezoneIANA } from "shared-functions/date";
import {
  AddPhotoIcon,
  Avatar,
  Block,
  Flex,
  fromStringToCountryOption,
  fromStringToStateOption,
  Input,
  Typography,
} from "ui-kit";
import { DataContainer } from "./DataContainer";

type Props = {
  patient: Patient;
};

export const PersonalInformationPage = memo(({ patient }: Props) => {
  return (
    <DataContainer>
      <PersonalInformationSection marginBottom={6}>
        <Block textAlign="center">
          <AddPhotoLabel htmlFor="add-photo">
            {patient.personalInformation?.photo ? (
              <AvatarImage width="140px" height="140px">
                <img
                  src={patient.personalInformation.photo.url}
                  alt="patient avatar"
                />
              </AvatarImage>
            ) : (
              <AddPhotoIcon />
            )}
          </AddPhotoLabel>
        </Block>
        <Block marginBottom={3}>
          <Input
            label="Title"
            disabled
            defaultValue={
              capitalize(patient.personalInformation?.title) || "N/A"
            }
          />
        </Block>
        <Block marginBottom={3}>
          <Input
            label="First name"
            disabled
            defaultValue={patient.personalInformation?.firstName}
          />
        </Block>
        <Block marginBottom={3}>
          <Input
            label="Last name"
            disabled
            defaultValue={patient.personalInformation?.lastName}
          />
        </Block>
        <Block marginBottom={3}>
          <Input
            label="Gender"
            disabled
            defaultValue={capitalize(patient.personalInformation?.gender)}
          />
        </Block>
        <Block marginBottom={3}>
          <Input
            label="Date of birth"
            disabled
            defaultValue={
              patient?.personalInformation?.DOB
                ? format(
                    utcToZonedTime(
                      patient.personalInformation.DOB,
                      getCurrentTimezoneIANA()
                    ),
                    "MM/dd/yyyy"
                  )
                : undefined
            }
          />
        </Block>
        <Block>
          <Input
            label="Phone number (SMS)"
            placeholder="Phone number"
            disabled
            defaultValue={patient?.personalInformation?.phone || "N/A"}
          />
        </Block>
      </PersonalInformationSection>
      <EmergencySection>
        <Block marginBottom={3} textAlign="center">
          <Typography as="h2" fontColor="N300" type="h2">
            Information for emergency
          </Typography>
        </Block>
        <Block marginBottom={3}>
          <Input
            disabled
            defaultValue={
              patient.emergencyInformation?.country
                ? fromStringToCountryOption(
                    patient.emergencyInformation?.country
                  ).label
                : "N/A"
            }
            label="Country"
            placeholder="Country"
          />
        </Block>
        <Block marginBottom={3}>
          <Input
            disabled
            defaultValue={
              patient.emergencyInformation?.state
                ? fromStringToStateOption(patient.emergencyInformation?.state)
                    .label
                : "N/A"
            }
            label="State"
            placeholder="State"
          />
        </Block>
        <Flex marginBottom={3}>
          <Block width="70%" paddingRight={2}>
            <Input
              disabled
              defaultValue={patient.emergencyInformation?.city || "N/A"}
              label="City"
              placeholder="City"
            />
          </Block>
          <Block width="30%" paddingLeft={2}>
            <Input
              disabled
              defaultValue={patient.emergencyInformation?.zipCode || "N/A"}
              label="Zip code"
              placeholder="Zip code"
            />
          </Block>
        </Flex>
        <Block marginBottom={3}>
          <Input
            disabled
            defaultValue={patient.emergencyInformation?.address || "N/A"}
            label="Address"
            placeholder="Address"
          />
        </Block>
        <Block>
          <Input
            disabled
            defaultValue={patient.emergencyInformation?.emergencyPhone || "N/A"}
            label="Phone number"
            placeholder="Phone number"
          />
        </Block>
      </EmergencySection>
    </DataContainer>
  );
});

function capitalize(word?: string) {
  if (!word) {
    return undefined;
  }
  return `${word[0].toUpperCase()}${word.slice(1)}`;
}

const AddPhotoLabel = styled.label`
  display: inline-block;
`;

const AvatarImage = styled(Avatar)`
  display: inline-block;
  margin: 16px auto 22px;
`.withComponent("span");

const PersonalInformationSection = Block.withComponent("section");

const EmergencySection = Block.withComponent("section");
