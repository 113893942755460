import styled from "@emotion/styled";
import { useDoctor } from "Doctor/Auth";
import { toDoctorWaitingRoomPage } from "Doctor/Router";
import { useStore } from "effector-react";
import { memo, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Block,
  boxShadow,
  color,
  Flex,
  Grid,
  Typography,
  WaitingRoomIcon,
} from "ui-kit";
import { PageLayout } from "./Layout";
import { $visitsList, loadVisitsAmount } from "./WaitingRoom/list";

export const HomePage = memo(() => {
  const doctor = useDoctor();
  useEffect(() => {
    loadVisitsAmount({ from: Date.nowUniversal });
  }, []);
  const { amount } = useStore($visitsList);

  if (!doctor) {
    return null;
  }

  return (
    <PageLayout>
      {doctor?.onModeration && (
        <ModerationIndicator justifyContent="center" alignItems="center">
          <Typography fontColor="N0" type="h3">
            Moderation
          </Typography>
        </ModerationIndicator>
      )}
      <Block height="100%" padding={7}>
        <Grid
          alignContent="center"
          gridGap={5}
          gridTemplateColumns="repeat(auto-fit, 364px)"
          justifyContent="center"
          height="100%"
        >
          {doctor?.onModeration ? (
            <DisabledTile>
              <Block marginBottom={3}>
                <WaitingRoomIcon fill={color.N200} />
              </Block>
              <Block>
                <Typography type="h2" fontColor="N200">
                  Waiting room
                </Typography>
              </Block>
              <Typography type="h5" fontColor="N200" fontWeight={400}>
                Patients are waiting for your care
              </Typography>
            </DisabledTile>
          ) : (
            <ActiveTile to={toDoctorWaitingRoomPage(doctor!.id)}>
              <Indicator justifyContent="center" alignItems="center">
                <Typography fontColor="N0" type="h3">
                  {amount}
                </Typography>
              </Indicator>
              <Block marginBottom={3}>
                <WaitingRoomIcon fill={color.G200} />
              </Block>
              <Block>
                <Typography type="h2">Waiting room</Typography>
              </Block>
              <Typography type="h5" fontColor="N300" fontWeight={400}>
                Patients are waiting for your care
              </Typography>
            </ActiveTile>
          )}
        </Grid>
      </Block>
    </PageLayout>
  );
});

const DisabledTile = styled(Block)`
  background-color: ${color.N0};
  border-radius: 8px;
  box-shadow: ${boxShadow.normal};
  display: block;
  min-height: 254px;
  padding: 48px 40px 40px;
  position: relative;
  text-align: center;
  text-decoration: none;
`;

const ActiveTile = DisabledTile.withComponent(Link);

const Indicator = styled(Flex)`
  background-color: ${color.O100};
  border-radius: 16px;
  min-height: 32px;
  min-width: 34px;
  padding: 4px 12px;
  position: absolute;
  right: 24px;
  top: 24px;
`;

const ModerationIndicator = styled(Flex)`
  background-color: ${color.O100};
  border-radius: 16px;
  padding: 4px 16px;
  position: absolute;
  right: 24px;
  top: 24px;
`;
