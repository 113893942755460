import { ISO8601 } from "Doctor/Model";

export type PolarAnswer = "yes" | "no";

export type LifeStyleDTO = {
  healthIssues?: PolarAnswer;
  surgeries?: PolarAnswer;
  medicalProcedures?: PolarAnswer;
  disabled?: PolarAnswer;
  sleep7Hours?: PolarAnswer;
  playSports?: PolarAnswer;
  fluVaccines?: PolarAnswer;
  allergy?: PolarAnswer;
  smoke?: PolarAnswer;
  drinkAlcohol?: PolarAnswer;
  drugs?: PolarAnswer;
  workWithToxicChemicals?: PolarAnswer;
  hospitalizedByMentalIssues?: PolarAnswer;
  pregnant?: PolarAnswer;
  pregnancyAmount?: string;
  breastfeeding?: PolarAnswer;
  lastMenstrualPeriod?: ISO8601;
  symptomsLastYear?: string[];
};

export const symptomsLastYear = [
  "Headache",
  "Dizziness",
  "High temperature",
  "Sneezing",
  "Cough or sputum",
  "Sore throat",
  "Pain when swallowing",
  "Stuffy nose or ear",
  "Nasal discharge",
  "Ear pain",
  "Ear drainage",
  "Redness of the eyes",
  "Eye discharge",
  "Changes in vision",
  "Loss of sense of smell",
  "Loss of taste buds",
  "Hearing or speech loss",
  "Anxiety",
  "Depression",
  "Panic attacks",
  "Mood changes",
  "Fatigue",
  "Weakness",
  "Loss of appetite",
  "Sleep problems",
  "Night sweats",
  "Heart pain",
  "Heartbeat",
  "Shortness of breath",
  "Inconsistent breathing",
  "Weight gain",
  "Weight loss",
  "Abdominal pain",
  "Abdominal discomfort",
  "Constipation",
  "Diarrhea",
  "Blood in the stool",
  "Nausea or vomiting",
  "Heartburn or reflux",
  "Frequent urination",
  "Discomfort when urinating",
  "Pain when urinating",
  "Blood in the urine",
  "Discharge from the penis",
  "Testicular edema",
  "Testicular pain",
  "Kidney pain",
  "Liver pain",
  "Pressure",
  "Blood pressure",
  "Chest pressure",
  "Chest pain",
  "Nosebleed",
  "Loss of consciousness",
  "Memory loss",
  "Itching",
  "Redness",
  "Skin rashes or ulcers",
  "Skin color change",
  "Bruises",
  "Swelling",
  "Back pain",
  "Joint stiffness",
  "Limited movement",
  "Muscle weakness",
  "Muscle pain",
  "Numbness or tingling",
  "Convulsions",
  "Other issues",
];
