/**
 * For usage inside Input module only.
 * The goal is to have a single source of truth for inputs appearance
 */

import styled from "@emotion/styled";
import { color } from "../theme";
import { getBorderColor } from "./getBorderColor";

export const Label = styled.label`
  cursor: pointer;
  color: ${color.N400};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
`;

type InputState = {
  boxShadow?: string;
  disabled?: boolean;
  hasErrors?: boolean;
  hasValue?: boolean;
  inFocus?: boolean;
};

export const InputElement = styled("input", {
  shouldForwardProp: (prop) =>
    !["boxShadow", "hasErrors", "hasValue", "inFocus"].includes(prop),
})<InputState>`
  ${({ boxShadow, disabled, hasErrors, hasValue, inFocus }) => `
    background-color: ${disabled ? color.N100 : color.N0};
    border: 1px solid ${getBorderColor({
      disabled,
      hasErrors,
      hasValue,
      inFocus,
    })};
    border-radius: 4px;
    box-shadow: ${boxShadow ? boxShadow : "none"};
    color: ${color.N400};
    padding: 10px 14px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    width: 100%;
    &:focus {
      border-color: ${color.G200};
      outline: none;
    }
    &::placeholder {
      color: ${color.N300};
    };
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: ${color.N300};
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: ${color.N400};
    }
  `};
`;
