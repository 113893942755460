import { useParams } from "react-router-dom";
import { usePatient } from "./patientSession";

/**
 * Authentication in our case means that patient has an active session
 * AND patient's id in URL matches the real patient's id
 **/
export function useAuthenticatedPatient() {
  const patient = usePatient();
  const URLParams = useParams();

  return patient !== undefined && patient.id === URLParams.patientId
    ? patient
    : null;
}
