import { memo } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { patientLoginPage } from "../routes";
import { useAuthenticatedPatient } from "./useAuthenticatedPatient";
import { useLocationWithValidPathname } from "./useLocationWithValidPathname";

export const AuthBarier = memo(() => {
  const authenticatedPatient = useAuthenticatedPatient();
  const validLocation = useLocationWithValidPathname();

  return authenticatedPatient ? (
    <Outlet />
  ) : (
    <Navigate
      replace
      to={{
        pathname: patientLoginPage,
      }}
      state={{
        from: validLocation,
      }}
    />
  );
});
