import { doctorLoginPage } from "Doctor/Router";
import { memo } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuthenticatedDoctor } from "./useAuthenticatedDoctor";
import { useLocationWithValidPathname } from "./useLocationWithValidPathname";

export const AuthBarier = memo(() => {
  const authenticatedDoctor = useAuthenticatedDoctor();
  const validLocation = useLocationWithValidPathname();

  if (!authenticatedDoctor) {
    return (
      <Navigate
        replace
        to={{
          pathname: doctorLoginPage,
        }}
        state={{
          from: validLocation,
        }}
      />
    );
  }
  return <Outlet />;
});
