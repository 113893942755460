export function hasMoreThan8Symbols(password: string = "") {
  return password.length > 8;
}

export function hasSpecialCharacter(password: string = "") {
  return /[!@#$%^&*(),.?":{}|<>]/g.test(password);
}

export function hasDigit(password: string = "") {
  return /[0-9]/g.test(password);
}

export function hasLowercaseCharacter(password: string = "") {
  return /[a-z]/g.test(password);
}

export function hasUppercaseCharacter(password: string = "") {
  return /[A-Z]/g.test(password);
}
