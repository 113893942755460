import styled from "@emotion/styled";
import { useStore } from "effector-react";
import {
  familyDiseasesFullList,
  familyDiseasesStore,
  loadFamilyDiseases,
} from "Patient/Record";
import { memo, useEffect, useState } from "react";
import {
  Block,
  Checkbox,
  Flex,
  Grid,
  RecordFamilyIcon,
  Typography,
} from "ui-kit";
import { EmptyStateContainer, EmptyStateIcon } from "./EmptyState";
import { LoaderWithSpacing } from "./Loader";

type Props = {
  patientId: string;
};

export const FamilyDiseasesPage = memo(({ patientId }: Props) => {
  const [isDataLoading, setIsLoading] = useState(true);
  const familyDiseases = useStore(familyDiseasesStore);

  useEffect(() => {
    loadFamilyDiseases(patientId).finally(() => setIsLoading(false));
  }, [patientId]);

  if (isDataLoading) {
    return <LoaderWithSpacing />;
  }

  if (familyDiseases.length === 0) {
    return <EmptyState />;
  }

  return (
    <DiseasesContainer>
      {familyDiseasesFullList.map((disease) => (
        <Block key={disease}>
          <Checkbox
            defaultChecked={familyDiseases.includes(disease)}
            label={capitalize(disease)}
            disabled
          />
        </Block>
      ))}
    </DiseasesContainer>
  );
});

const EmptyState = () => (
  <EmptyStateContainer>
    <Flex justifyContent="center" marginBottom={4}>
      <EmptyStateIcon>
        <RecordFamilyIcon width="86px" height="86px" />
      </EmptyStateIcon>
    </Flex>
    <Block marginBottom={1}>
      <Typography fontColor="N300" type="h2">
        No family diseases
      </Typography>
    </Block>
    <Block>
      <Typography fontColor="N300" fontWeight={400} type="h3">
        Family diseases of the patient will be displayed here
      </Typography>
    </Block>
  </EmptyStateContainer>
);

const DiseasesContainer = styled(Grid)`
  grid-template-rows: repeat(5, auto);
  grid-auto-flow: column;
  grid-column-gap: 48px;
  grid-row-gap: 24px;
  grid-auto-columns: 1fr;
  margin: 48px auto 64px;
  max-width: 772px;
`;

function capitalize(word?: string) {
  if (!word) {
    return undefined;
  }
  return `${word[0].toUpperCase()}${word.slice(1)}`;
}
