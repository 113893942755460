import { apiServer } from "apiConnection";
import { createEffect, createStore } from "effector";
import { successNotification } from "ui-kit";
import { FamilyDiseasesDTO } from "./typeDefinitions";

export const loadFamilyDiseases = createEffect("loadFamilyDiseases", {
  handler: async (patientId: string) =>
    await getFamilyDiseasesRequest(patientId),
});

export const saveFamilyDiseases = createEffect("saveFamilyDiseases", {
  handler: async ({
    patientId,
    diseases,
  }: {
    patientId: string;
    diseases: FamilyDiseasesDTO;
  }) => await saveFamilyDiseasesRequest(patientId, diseases),
});

saveFamilyDiseases.doneData.watch(() => {
  successNotification("Family diseases were updated");
});

async function getFamilyDiseasesRequest(patientId: string) {
  const { data: diseases } = await apiServer.get<FamilyDiseasesDTO>(
    `/api/patient/${patientId}/family-diseases`
  );
  return diseases;
}

async function saveFamilyDiseasesRequest(
  patientId: string,
  diseases: FamilyDiseasesDTO
) {
  const { data: savedDiseases } = await apiServer.post<FamilyDiseasesDTO>(
    `/api/patient/${patientId}/family-diseases`,
    { diseases }
  );
  return savedDiseases;
}

export const familyDiseases = createStore<FamilyDiseasesDTO>([]).on(
  [loadFamilyDiseases.doneData, saveFamilyDiseases.doneData],
  (state, familyDiseases) => familyDiseases
);
