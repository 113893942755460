import styled from "@emotion/styled";
import { useStore } from "effector-react";
import { $stream, VolumeAdjuster, VolumeMeter } from "MediaStream";
import { memo, SyntheticEvent, useCallback } from "react";
import {
  AudioIcon,
  Block,
  BoxWithShadow,
  Button,
  color,
  Flex,
  Grid,
  HDIcon,
  Label as CheckboxLabel,
  MicrophoneIcon,
  RadioButton,
  ReverseDirectionIcon,
  Screen169Icon,
  ScreenIcon,
  Toggle,
  Typography,
  VideoIcon,
} from "ui-kit";
import {
  settings as $settings,
  applyHD,
  changeMicSetting,
  changeScreenSize,
  changeSpeakerSetting,
  changeVideoSetting,
  reverseVideo,
} from "./streamSettings";

type Props = {
  onBack: () => void;
};

export const Settings = memo(({ onBack }: Props) => {
  const settings = useStore($settings);
  const { publisher, subscribers } = useStore($stream);
  const subscriberAudioTrack = subscribers[0]?.audio;
  const publisherAudioTrack = publisher?.audio;

  const noop = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    e.stopPropagation();
  }, []);

  return (
    <PageContainer>
      <Flex
        alignItems="center"
        justifyContent="center"
        marginBottom={3}
        position="relative"
      >
        <Typography as="h1" type="h1">
          Settings
        </Typography>
        <Block position="absolute" right="0">
          <Button onClick={onBack}>Back to visit</Button>
        </Block>
      </Flex>
      <DevicesSettingsContainer>
        <DeviceSetting>
          <Block textAlign="right">
            <Toggle
              checked={settings.video}
              onChange={() =>
                changeVideoSetting({
                  publisher,
                  video: !settings.video,
                })
              }
            />
          </Block>
          <Block textAlign="center">
            <VideoIcon
              fill={settings.video ? color.G200 : color.N300}
              height="64px"
              width="64px"
            />
          </Block>
          <Block textAlign="center" position="relative">
            <Typography fontColor="N300" type="h4">
              Video
            </Typography>
          </Block>
        </DeviceSetting>
        <DeviceSetting>
          <Block textAlign="right">
            <Toggle
              checked={settings.speaker}
              onChange={() =>
                changeSpeakerSetting({
                  subscribers,
                  speaker: !settings.speaker,
                })
              }
            />
          </Block>
          <Block textAlign="center" position="relative">
            <AudioIcon
              fill={settings.speaker ? color.G200 : color.N300}
              height="64px"
              width="64px"
            />
          </Block>
          <Block textAlign="center">
            <Typography fontColor="N300" type="h4">
              Speaker
            </Typography>
          </Block>
        </DeviceSetting>
        <DeviceSetting>
          <Block textAlign="right">
            <Toggle
              checked={settings.microphone}
              onChange={() =>
                changeMicSetting({
                  publisher,
                  microphone: !settings.microphone,
                })
              }
            />
          </Block>
          <Block textAlign="center" position="relative">
            <MicrophoneIcon
              fill={settings.microphone ? color.G200 : color.N300}
              height="64px"
              width="64px"
            />
          </Block>
          <Block textAlign="center">
            <Typography fontColor="N300" type="h4">
              Microphone
            </Typography>
          </Block>
        </DeviceSetting>
      </DevicesSettingsContainer>
      <ScreenSettingsSection>
        <Block marginBottom={3} textAlign="center">
          <Typography as="h2" fontColor="N300" type="h1">
            Screen
          </Typography>
        </Block>
        <ScreenSettingsContainer>
          <ScreenSettingsGroup>
            <Block marginRight={2}>
              <ScreenSetting
                type="button"
                selected={settings.screenSize === "wide"}
                onClick={() => changeScreenSize("wide")}
              >
                <Block marginRight={2}>
                  <RadioButton
                    id="screenSize-wide"
                    name="screenSize"
                    checked={settings.screenSize === "wide"}
                    onChange={noop}
                  />
                </Block>
                <Block marginRight={1.5}>
                  <Screen169Icon
                    height="32px"
                    width="40px"
                    fill={
                      settings.screenSize === "wide" ? color.G200 : color.N200
                    }
                  />
                </Block>
                <Typography type="h5">16:9 Wide-screen format</Typography>
              </ScreenSetting>
            </Block>
            <Block marginLeft={2}>
              <ScreenSetting
                type="button"
                selected={settings.screenSize === "original"}
                onClick={() => changeScreenSize("original")}
              >
                <Block marginRight={2}>
                  <RadioButton
                    id="screenSize-original"
                    name="screenSize"
                    checked={settings.screenSize === "original"}
                    onChange={noop}
                  />
                </Block>
                <Block marginRight={1.5}>
                  <ScreenIcon
                    height="32px"
                    width="40px"
                    fill={
                      settings.screenSize === "original"
                        ? color.G200
                        : color.N200
                    }
                  />
                </Block>
                <Typography type="h5">Original state</Typography>
              </ScreenSetting>
            </Block>
          </ScreenSettingsGroup>
          <ScreenSettingsGroup>
            <Block marginRight={2}>
              <ScreenSetting
                type="button"
                selected={settings.hd}
                onClick={() => applyHD(!settings.hd)}
              >
                <Block marginRight={2}>
                  <CheckboxMark checked={settings.hd} hasText={false} />
                </Block>
                <Block marginRight={1.5}>
                  <HDIcon fill={settings.hd ? color.G200 : color.N200} />
                </Block>
                <Typography type="h5">Enable HD</Typography>
              </ScreenSetting>
            </Block>
            <Block marginLeft={2}>
              <ScreenSetting
                type="button"
                selected={settings.reversedVideo}
                onClick={() => reverseVideo(!settings.reversedVideo)}
              >
                <Block marginRight={2}>
                  <CheckboxMark
                    checked={settings.reversedVideo}
                    hasText={false}
                  />
                </Block>
                <Block marginRight={1.5}>
                  <ReverseDirectionIcon
                    fill={settings.reversedVideo ? color.G200 : color.N200}
                  />
                </Block>
                <Typography type="h5">Reverse my video mirror</Typography>
              </ScreenSetting>
            </Block>
          </ScreenSettingsGroup>
        </ScreenSettingsContainer>
      </ScreenSettingsSection>
      <section>
        <Block marginBottom={3} textAlign="center">
          <Typography as="h2" fontColor="N300" type="h1">
            Sound
          </Typography>
        </Block>
        <SoundSettingsContainer>
          <SoundSetting>
            <SoundIconArea>
              <AudioIcon fill={color.N200} height="64px" width="64px" />
            </SoundIconArea>
            <Block padding={4}>
              <Block marginBottom={4} textAlign="center">
                <Typography type="h2" fontColor="N300">
                  Speaker
                </Typography>
              </Block>
              <Grid
                alignItems="center"
                gridTemplateColumns="max-content 248px"
                gridTemplateRows="auto auto"
                gridColumnGap={3}
                gridRowGap={3}
              >
                <Typography type="h3">Output level</Typography>
                <VolumeMeter
                  stream={
                    subscriberAudioTrack?.mediaStreamTrack
                      ? new MediaStream([subscriberAudioTrack.mediaStreamTrack])
                      : undefined
                  }
                  enabled={!subscribers[0]?.isMuted}
                />
                <Typography type="h3">Volume</Typography>
                <VolumeAdjuster
                  stream={
                    subscriberAudioTrack?.mediaStreamTrack
                      ? new MediaStream([subscriberAudioTrack.mediaStreamTrack])
                      : undefined
                  }
                />
              </Grid>
            </Block>
          </SoundSetting>
          <SoundSetting>
            <SoundIconArea>
              <MicrophoneIcon fill={color.N200} height="64px" width="64px" />
            </SoundIconArea>
            <Block padding={4}>
              <Block marginBottom={4} textAlign="center">
                <Typography type="h2" fontColor="N300">
                  Microphone
                </Typography>
              </Block>
              <Grid
                alignItems="center"
                gridTemplateColumns="max-content 248px"
                gridTemplateRows="auto auto"
                gridColumnGap={3}
                gridRowGap={3}
              >
                <Typography type="h3">Output level</Typography>
                <VolumeMeter
                  stream={
                    publisherAudioTrack?.mediaStreamTrack
                      ? new MediaStream([publisherAudioTrack.mediaStreamTrack])
                      : undefined
                  }
                  enabled={publisherAudioTrack?.isEnabled}
                />
                <Typography type="h3">Volume</Typography>
                <VolumeAdjuster
                  stream={
                    publisherAudioTrack?.mediaStreamTrack
                      ? new MediaStream([publisherAudioTrack.mediaStreamTrack])
                      : undefined
                  }
                />
              </Grid>
            </Block>
          </SoundSetting>
        </SoundSettingsContainer>
      </section>
    </PageContainer>
  );
});

const PageContainer = styled(Block)`
  background-color: ${color.N100};
  min-height: 100vh;
  padding: 24px 32px 32px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 3;
`;

const DevicesSettingsContainer = styled(Grid)`
  grid-column-gap: 24px;
  grid-template-columns: repeat(3, auto);
  justify-content: center;
  margin-bottom: 64px;
`.withComponent("section");

const DeviceSetting = styled(BoxWithShadow)`
  flex-direction: column;
  justify-content: space-between;
  height: 178px;
  padding: 16px 16px 24px;
  width: 271px;
`;

const ScreenSettingsSection = styled(Block)`
  margin-bottom: 64px;
`.withComponent("section");

const ScreenSettingsContainer = styled(Grid)`
  grid-column-gap: 32px;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  padding: 0 116px;
`;

const ScreenSettingsGroup = styled(BoxWithShadow)`
  align-items: center;
  height: 168px;
  justify-content: center;
`;

type SettingState = {
  selected: boolean;
};

const ScreenSetting = styled(Flex)<SettingState>`
  align-items: center;
  background: none;
  border: ${({ selected }) =>
    `1px solid ${selected ? color.G200 : color.N200}`};
  border-radius: 4px;
  cursor: pointer;
  max-height: 72px;
  outline: none;
  padding: 20px 24px;
`.withComponent("button");

const SoundSettingsContainer = ScreenSettingsContainer;

const SoundSetting = styled(Grid)`
  grid-template-columns: 1fr 3fr;
  height: 208px;
`.withComponent(BoxWithShadow);

const SoundIconArea = styled(Flex)`
  align-items: center;
  background-color: ${color.N100};
  border-radius: 8px 0 0 8px;
  border-right: 1px solid ${color.N200};
  justify-content: center;
`;

type CheckboxState = {
  checked: boolean;
};

const CheckboxMark = styled(CheckboxLabel)<CheckboxState>`
  ${({ checked }) => `
   &::before {
      background-color: ${checked ? color.G200 : "transparent"};
      background-image: ${
        checked
          ? `url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDExIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjkxMzA4IDQuMDM1MTZIMS45MTMyNkMxLjk5MjU2IDQuMDM1MjUgMi4wNzA4MSA0LjA1MzMxIDIuMTQyMTMgNC4wODc5OEMyLjIxMzQxIDQuMTIyNjIgMi4yNzU5MSA0LjE3Mjk1IDIuMzI0OTcgNC4yMzUyTDEuOTEzMDggNC4wMzUxNlpNMS45MTMwOCA0LjAzNTE2SDEuMDkzOTRDMC44OTAzMDggNC4wMzUxNiAwLjc3NjYwMiA0LjI2OTc1IDAuOTAyMzIxIDQuNDI5MkwwLjkwMjM3MSA0LjQyOTI3TDQuMTEyMTQgOC40OTU2N0w0LjExMjE4IDguNDk1NzNDNC4zMjI0NyA4Ljc2MTg4IDQuNzI1NjggOC43NjExNCA0LjkzNjY3IDguNDk2MTdMNC45MzcwOCA4LjQ5NTY2TDEwLjY1OTcgMS4yNDM5OEMxMC42NTk4IDEuMjQzNzggMTAuNjYgMS4yNDM1OCAxMC42NjAyIDEuMjQzMzhDMTAuNzg4NCAxLjA4Mjk1IDEwLjY2OTkgMC44NSAxMC40Njg5IDAuODVIOS42NDk4QzkuNDg5NDEgMC44NSA5LjMzNjY4IDAuOTIzNSA5LjIzNzYgMS4wNTA0NEM5LjIzNzUxIDEuMDUwNTUgOS4yMzc0MiAxLjA1MDY2IDkuMjM3MzQgMS4wNTA3N0w0LjUyNDAzIDcuMDIxNTdNMS45MTMwOCA0LjAzNTE2TDQuNTI0MDMgNy4wMjE1N000LjUyNDAzIDcuMDIxNTdMMi4zMjUwNiA0LjIzNTMxTDQuNTI0MDMgNy4wMjE1N1oiIGZpbGw9IndoaXRlIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjAuMyIvPgo8L3N2Zz4K")`
          : "none"
      } ;
      border-color: ${checked ? color.G200 : color.N300};
    }
  `}
`;
