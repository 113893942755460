import {
  ComponentType,
  FunctionComponent,
  lazy,
  memo,
  ReactNode,
  Suspense,
} from "react";
import { Flex, Loader } from "ui-kit";

type LoadableTypes<T> = {
  loader: () => Promise<ComponentType<T>>;
  loading?: ReactNode;
};

export function loadOnDemand<T>({
  loader,
  loading = <DefaultFallbackLoader />,
}: LoadableTypes<T>): FunctionComponent<T> {
  const Loader = lazy(() =>
    loader().then((res) => ({
      default: res,
    }))
  );
  return function (props) {
    return (
      <Suspense fallback={loading}>
        <Loader {...(props as any)} />
      </Suspense>
    );
  };
}

const DefaultFallbackLoader = memo(() => (
  <Flex height="100vh" justifyContent="center" alignItems="center">
    <Loader />
  </Flex>
));
