import { format, subYears } from "date-fns";
import { useDoctor } from "Doctor/Auth";
import { Language } from "Doctor/Model";
import { loadSpecialties } from "Doctor/requests";
import { memo, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { debouncePromise } from "shared-functions/delay";
import {
  AsyncSelectbox,
  Block,
  Flex,
  fromStringToOption,
  Input,
  Option,
  Selectbox,
  Typography,
} from "ui-kit";

const languageOptions: Option[] = [
  { value: "en", label: "English" },
  { value: "es", label: "Español" },
  { value: "zh", label: "中文" },
  { value: "de", label: "Deutsch" },
  { value: "fr", label: "Français" },
  { value: "ru", label: "Русский" },
];

const loadSpecialtiesOptions = (searchInput: string) => {
  return loadSpecialties(searchInput)
    .then((specialties) => specialties.map((sp) => fromStringToOption(sp)))
    .catch((error) => {
      console.error(error);
      return [];
    });
};

function experienceOptions(): Option[] {
  const YEARS_OF_EXPERIENCE_LIMIT = 50;
  return new Array(YEARS_OF_EXPERIENCE_LIMIT)
    .fill(undefined)
    .map((_, index) => {
      const experienceYear = format(subYears(Date.nowUniversal, index), "yyyy");
      return {
        label: experienceYear,
        value: experienceYear,
      };
    });
}

export const ProfessionalInformationForm = memo(() => {
  const doctor = useDoctor();
  const {
    control,
    clearErrors,
    formState: { errors },
    register,
    watch,
  } = useFormContext();

  const graduationYear = watch("graduationYear");
  const practiceStarted = watch("practiceStarted");

  useEffect(() => {
    if (graduationYear && practiceStarted && practiceStarted > graduationYear) {
      clearErrors("graduationYear");
      clearErrors("practiceStarted");
    }
  }, [clearErrors, graduationYear, practiceStarted]);

  return (
    <>
      <Block marginBottom={3} textAlign="center">
        <Typography as="h2" fontColor="N300" type="h2">
          Professional information
        </Typography>
      </Block>
      <Block marginBottom={3}>
        <Controller
          control={control}
          name="specialty"
          rules={{
            required: "Please, select a specilaty",
          }}
          render={({
            field: { onChange, value: specialty },
            fieldState: { error },
          }) => (
            <AsyncSelectbox
              loadOptions={debouncePromise(loadSpecialtiesOptions, 1000)}
              defaultOptions
              hasErrors={!!error}
              errorMessage={error}
              placeholder="Specialty"
              label="Specialty"
              isRequired
              noOptionsMessage={() => "No specialties"}
              onChange={(specialtyOption) =>
                onChange(
                  specialtyOption ? (specialtyOption as Option).value : ""
                )
              }
              defaultValue={specialty ? fromStringToOption(specialty) : null}
            />
          )}
        />
      </Block>
      <Block marginBottom={3}>
        <Controller
          control={control}
          name="practiceStarted"
          rules={{
            required: "Please, select a start practice year",
            validate: (practiceStarted) => {
              if (graduationYear && practiceStarted < graduationYear) {
                return "Start of practice cannot be earlier than graduation year";
              }
              return true;
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Selectbox
              isClearable
              hasErrors={!!error}
              errorMessage={error}
              options={experienceOptions()}
              placeholder="Year"
              label="Start of practice"
              isRequired
              noOptionsMessage={() => "Out of range"}
              onChange={(experienceOption) =>
                onChange(
                  experienceOption ? (experienceOption as Option).value : ""
                )
              }
              defaultValue={
                value ? fromStringToOption(value, experienceOptions()) : null
              }
            />
          )}
        />
      </Block>
      <Block marginBottom={3}>
        <Controller
          control={control}
          name="language"
          rules={{ required: "Please, select language" }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Selectbox
              closeMenuOnSelect={false}
              isMulti
              isClearable={false}
              hasErrors={!!error}
              errorMessage={error}
              options={languageOptions}
              placeholder="Language"
              label="Language"
              isRequired
              noOptionsMessage={() => "No languages"}
              onChange={(languageOptions) =>
                languageOptions
                  ? onChange(
                      (languageOptions as Option[]).map(
                        (language) => language.value as Language
                      )
                    )
                  : ""
              }
              defaultValue={
                value
                  ? value.map((v: Language) =>
                      fromStringToOption(v, languageOptions)
                    )
                  : null
              }
            />
          )}
        />
      </Block>
      <Block marginBottom={3}>
        <Input
          defaultValue={
            doctor?.professionalInformation?.education.medicalSchool
          }
          errorMessage={errors["medicalSchool"]?.message}
          hasErrors={!!errors["medicalSchool"]}
          label="University or college"
          placeholder="University or college"
          isRequired
          {...register("medicalSchool", {
            required:
              "Please, add the university or college you graduated from",
          })}
        />
      </Block>
      <Flex marginBottom={7}>
        <Block marginRight={1.5} width="50%">
          <Input
            defaultValue={doctor?.professionalInformation?.education.degree}
            errorMessage={errors["degree"]?.message}
            hasErrors={!!errors["degree"]}
            label="Degree"
            placeholder="Degree"
            isRequired
            {...register("degree", {
              required: "Please, specify your degree",
            })}
          />
        </Block>
        <Block marginLeft={1.5} width="50%">
          <Input
            defaultValue={
              doctor?.professionalInformation?.education.graduationYear
            }
            errorMessage={errors["graduationYear"]?.message}
            hasErrors={!!errors["graduationYear"]}
            label="Graduation year"
            isRequired
            placeholder="Graduation year"
            {...register("graduationYear", {
              required: "Please, enter a graduation year, e.g. 2005",
              validate: (graduationYear?: string) => {
                if (graduationYear && isValidYear(graduationYear)) {
                  if (graduationYear > practiceStarted) {
                    return "Graduation year cannot be after start of practice";
                  }
                  return true;
                } else {
                  return "Please, enter a valid year, e.g. 2005";
                }
              },
            })}
          />
        </Block>
      </Flex>
    </>
  );
});

function isValidYear(year: string) {
  const yearNumber = Number(year);
  if (!Number.isInteger(yearNumber) || yearNumber < 0) {
    return false;
  }
  return true;
}
