import styled from "@emotion/styled";
import { format, utcToZonedTime } from "date-fns-tz";
import { useDoctor } from "Doctor/Auth";
import { DocumentIcon } from "Patient/Record";
import { ChangeEventHandler, memo, useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { getCurrentTimezoneIANA } from "shared-functions/date";
import {
  Block,
  ClipIcon,
  CloseRoundedIcon,
  color,
  CountrySelectbox,
  DateInput,
  ErrorMessage,
  errorNotification,
  Flex,
  fromStringToCountryOption,
  fromStringToStateOption,
  Grid,
  Input,
  isFormatMatched,
  Option,
  restrictPast,
  StateSelectbox,
  Typography,
} from "ui-kit";

const MAX_FILES = 6;

type Props = {
  licenseDocuments: File[];
  addedDocuments: File[];
  onDocumentsAdd: (updatedDocs: File[]) => void;
  onDocumentRemove: (updatedDocs: File[]) => void;
};

export const LicenseForm = memo(
  ({
    licenseDocuments,
    addedDocuments,
    onDocumentsAdd,
    onDocumentRemove,
  }: Props) => {
    const {
      control,
      register,
      formState: { errors },
      watch,
    } = useFormContext();
    const doctor = useDoctor();
    const defaultExpirationDate = doctor?.license?.expirationDate
      ? format(
          utcToZonedTime(
            doctor?.license?.expirationDate,
            getCurrentTimezoneIANA()
          ),
          "MM/dd/yyyy"
        )
      : undefined;

    const handleLicenseDocumentsChange: ChangeEventHandler<HTMLInputElement> = (
      e
    ) => {
      const acceptedFiles: File[] = [];
      if (e.target.files) {
        for (let i = 0; i < e.target.files?.length; i++) {
          if (e.target.files.item(i) !== null) {
            acceptedFiles[i] = e.target.files.item(i) as File;
          }
        }
      }
      const filesWithoutDuplicates = acceptedFiles.filter((file) =>
        licenseDocuments.every(
          (selectedFile) => selectedFile.name !== file.name
        )
      );
      const nextAddedDocsSet = [...addedDocuments, ...filesWithoutDuplicates];
      if ([...licenseDocuments, ...nextAddedDocsSet].length > MAX_FILES) {
        return errorNotification(`Too many files. Maximum
      ${MAX_FILES} files`);
      }
      onDocumentsAdd(nextAddedDocsSet);
    };

    const removeDocument = useCallback(
      (file: File) => {
        const filteredDocs = addedDocuments.filter(
          (doc) => doc.name !== file.name
        );
        onDocumentRemove(filteredDocs);
      },
      [addedDocuments, onDocumentRemove]
    );

    const selectedCountry = watch("country");

    return (
      <>
        <Block marginBottom={3} textAlign="center">
          <Typography as="h2" fontColor="N300" type="h2">
            License
          </Typography>
        </Block>
        <Block marginBottom={3}>
          <Input
            defaultValue={doctor?.license?.number}
            label="License number"
            hasErrors={!!errors["number"]}
            errorMessage={errors["number"]?.message}
            placeholder="License number"
            {...register("number")}
          />
        </Block>
        <Flex marginBottom={3}>
          <Block width="50%" marginRight={1.5}>
            <Controller
              control={control}
              name="country"
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <CountrySelectbox
                  isClearable
                  hasErrors={invalid}
                  errorMessage={error}
                  placeholder="Country of license"
                  label="Country of license"
                  defaultValue={value ? fromStringToCountryOption(value) : null}
                  onChange={(countryOption) =>
                    onChange(
                      countryOption ? (countryOption as Option).value : ""
                    )
                  }
                />
              )}
            />
          </Block>
          <Block width="50%" marginLeft={1.5}>
            {selectedCountry === "US" && (
              <Controller
                control={control}
                name="state"
                render={({
                  field: { onChange, value },
                  fieldState: { invalid, error },
                }) => (
                  <StateSelectbox
                    isClearable
                    hasErrors={invalid}
                    errorMessage={error}
                    placeholder="License state"
                    label="License state"
                    defaultValue={value ? fromStringToStateOption(value) : null}
                    onChange={(stateOption) => {
                      onChange(
                        stateOption ? (stateOption as Option).value : ""
                      );
                    }}
                  />
                )}
              />
            )}
          </Block>
        </Flex>
        <Block marginBottom={3}>
          <Input
            defaultValue={doctor?.license?.npi}
            label="NPI number"
            placeholder="NPI number"
            hasErrors={!!errors["npi"]}
            errorMessage={errors["npi"]?.message}
            {...register("npi")}
          />
        </Block>
        <Block>
          <DateInput
            defaultValue={defaultExpirationDate}
            label="License expiration date"
            hasErrors={!!errors["expiration"]}
            errorMessage={errors["expiration"]?.message}
            {...register("expiration", {
              validate: {
                format: isFormatMatched,
                restrictPast: (licenseDate) =>
                  restrictPast(licenseDate) ||
                  "Please, provide an unexpired license",
              },
            })}
          />
          <Controller
            control={control}
            name="licenseDocuments"
            render={({ fieldState: { error } }) => (
              <>
                <LicenseDocuments>
                  <input
                    type="file"
                    multiple
                    accept="image/*, application/pdf"
                    className="files"
                    onChange={handleLicenseDocumentsChange}
                    onClick={(event) => {
                      // @ts-ignore
                      event.currentTarget.value = null;
                    }}
                  />
                  <ClipIcon className="icon" />
                  <DocumentTitle className="title">
                    Attach license documents or diploma
                  </DocumentTitle>
                  <Typography lineHeight="0" type="h4">
                    &nbsp;&#42;
                  </Typography>
                </LicenseDocuments>
                {error?.message ? (
                  <Block>
                    <ErrorMessage>{error.message}</ErrorMessage>
                  </Block>
                ) : null}
              </>
            )}
          />
          {[...licenseDocuments, ...addedDocuments].length > 0 && (
            <Grid
              gridTemplateColumns="repeat(3, 1fr)"
              gridTemplateRows="auto"
              gridColumnGap={2}
              gridRowGap={2}
              marginTop={2}
            >
              {licenseDocuments.map((file) => (
                <DocumentPreview key={file.name}>
                  <DocumentIcon
                    className="icon"
                    name={file.name}
                    fill={color.N300}
                  />
                  <Typography className="fileName" fontColor="N300" type="h5">
                    {file.name}
                  </Typography>
                </DocumentPreview>
              ))}
              {addedDocuments.map((file) => (
                <DocumentPreview key={file.name}>
                  <DocumentIcon
                    className="icon"
                    name={file.name}
                    fill={color.N300}
                  />
                  <Typography className="fileName" fontColor="N300" type="h5">
                    {file.name}
                  </Typography>
                  <CloseRoundedIcon
                    onClick={() => removeDocument(file)}
                    cursor="pointer"
                    width="16px"
                    height="16px"
                    fill={color.N300}
                  />
                </DocumentPreview>
              ))}
            </Grid>
          )}
        </Block>
      </>
    );
  }
);

const DocumentPreview = styled(Flex)`
  align-items: center;
  & .fileName {
    margin-right: 8px;
    max-width: 232px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & .icon {
    margin-right: 8px;
  }
`;

const LicenseDocuments = styled("label")`
  color: ${color.G200};
  column-gap: 4px;
  cursor: pointer;
  display: inline-block;
  margin-top: 12px;
  & .files {
    display: none;
  }
  & .title {
    color: ${color.G200};
  }
  & .icon {
    fill: ${color.G200};
  }
  &:hover .title,
  &:focus .title {
    color: ${color.G300};
  }
  &:hover .icon,
  &:focus .icon {
    color: ${color.G300};
  }
`;

const DocumentTitle = styled(Typography)`
  padding-left: 4px;
`;
