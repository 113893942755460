import { ReactNode } from "react";
import { supportPage } from "Support/routes";
import { UnifiedLink } from "ui-kit";

type FAQs = {
  [key: string]: Question[];
};

type Question = {
  id: string;
  question: ReactNode;
  answer: ReactNode;
};

export const faqs: FAQs = {
  General: [
    {
      id: "what-is-this",
      question: <>What is&nbsp;PocketDoctor?</>,
      answer: (
        <>
          <p>
            PocketDoctor is a telemedicine service that connects patient and
            doctor online, simply and quickly through video, audio and text
            chat.
          </p>
        </>
      ),
    },
    {
      id: "doctor-help",
      question: <>How can PocketDoctor help?</>,
      answer: (
        <>
          <p>
            We&nbsp;can help with almost anything your general practitioner can
            do. Using our service, you will feel the simplicity of&nbsp;health
            care and cost savings.
          </p>
        </>
      ),
    },
    {
      id: "what-need-to-use",
      question: <>What do&nbsp;I&nbsp;need to&nbsp;use PocketDoctor?</>,
      answer: (
        <>
          <p>
            You will need a&nbsp;mobile phone, tablet, or&nbsp;computer.
            We&nbsp;strongly recommend use a&nbsp;mobile phone.
          </p>
        </>
      ),
    },
    {
      id: "register-steps",
      question: (
        <>
          How long does it&nbsp;usually take to&nbsp;register with PocketDoctor?
        </>
      ),
      answer: (
        <>
          <p>
            Everything is&nbsp;very simple, registration will take no&nbsp;more
            than 3 minutes.
          </p>
        </>
      ),
    },
    {
      id: "without-registration",
      question: <>Can I&nbsp;use PocketDoctor without registering?</>,
      answer: (
        <>
          <p>
            No, because the patient must have a&nbsp;personal account, where his
            medical data is&nbsp;located, so&nbsp;that the doctor can monitor
            the patient. Also, so&nbsp;that the doctor can contact the patient
            or&nbsp;the patient&rsquo;s relatives in&nbsp;an&nbsp;emergency.
            Therefore, we&nbsp;ask you to&nbsp;pass an authorization.
          </p>
        </>
      ),
    },
    {
      id: "a11y",
      question: <>Where is PocketDoctor available?</>,
      answer: (
        <>
          <p>
            We&nbsp;are currently available anywhere in&nbsp;the world.
            In&nbsp;the United States, our network of&nbsp;certified physicians
            will be&nbsp;available to&nbsp;you depending on&nbsp;which state you
            are in. We&nbsp;ensure that every doctor is&nbsp;licensed
            to&nbsp;practice medicine in&nbsp;your state.
          </p>
        </>
      ),
    },
    {
      id: "when-a11y",
      question: <>When is&nbsp;PocketDoctor available?</>,
      answer: (
        <>
          <p>
            We&nbsp;are available 24 hours a&nbsp;day, 7 days a&nbsp;week and
            365 days a&nbsp;year, even on&nbsp;public holidays anywhere
            in&nbsp;the world.
          </p>
        </>
      ),
    },
    {
      id: "doctor-locations",
      question: <>Where are the PocketDoctor doctors located?</>,
      answer: (
        <>
          <p>
            At&nbsp;the moment, all our doctors are located in&nbsp;the United
            States.
          </p>
        </>
      ),
    },
    {
      id: "what-doctors-will-do",
      question: <>What will doctors do&nbsp;to&nbsp;help me?</>,
      answer: (
        <>
          <p>
            You will be&nbsp;advised by&nbsp;an&nbsp;experienced doctor
            on&nbsp;your health issues. He&nbsp;will tell you what
            to&nbsp;do&nbsp;and what to&nbsp;pay attention to, after he can
            write out a&nbsp;prescription id needed. Doctors will also
            be&nbsp;able to&nbsp;send you for examinations or&nbsp;laboratory
            analysis. After that, the doctor will decipher your tests and
            explain the results. It&nbsp;will also give recommendations for
            further actions.
          </p>
        </>
      ),
    },
    {
      id: "diagnose",
      question: (
        <>How can you diagnose me&nbsp;without seeing me&nbsp;in&nbsp;person?</>
      ),
      answer: (
        <>
          <p>
            The PocketDoctor uses evidence-based science to&nbsp;treat only
            those conditions that are safe for online care. We&rsquo;ll ask you
            questions about your symptoms and medical history, just like the
            ones you&rsquo;ll hear in&nbsp;a&nbsp;doctor&rsquo;s office
            or&nbsp;emergency room, but online. If&nbsp;necessary, we&nbsp;will
            ask for some photos to&nbsp;help diagnose your condition.
            In&nbsp;addition, the doctor will know about you the main things
            that you have indicated in&nbsp;My&nbsp;record.
          </p>
        </>
      ),
    },
    {
      id: "what-treat",
      question: <>What do&nbsp;your doctors treat?</>,
      answer: (
        <>
          <p>
            With the help of&nbsp;PocketDoctor, you can treat various common
            health problems:
          </p>
          <p>
            Pharyngitis, Tonsillitis, Folliculitis, Eye infections,
            Conjunctivitis, Rhinitis, Fever, Flu, Depression, Stress, Panic
            disorders, Anxiety, Acne, Spots, Moles, Rash, Itching, Redness,
            Scabies, Urticaria, Chicken pox, Eczema, Psoriasis, Dermatitis,
            Warts, Herpes, Ringworm, Impetigo, Pink acne, Hair follicles, Skin
            infections, Insect bites, Diaper rash, Bruises, Bruises, Abrasions,
            Minor wounds, Cuts, Swelling, Sprains and deformations, Allergies,
            Pregnancy, Suffocation, Diarrhea, Constipation, Incontinence,
            Vomiting, Diarrhea, Insomnia, Weight Loss, Obesity, Hair loss,
            Gastritis, Gastroenteritis, Diabetes, Abdominal pain, Nausea,
            Urinary tract infection, Respiratory tract infections, Sexually
            transmitted infections, Pressure, High blood pressure, Back pain,
            Joint aches and pains, Smoking cessation, Sexual health, Erectile
            dysfunction, Vaginal problems, Cellulite, Convulsions, First Degree
            Burn, Ingrown toenail, Nail infection, Hypoglycemia, Sinusitis,
            Gastroenteritis, Yeast infection, Abscess, Acid reflux, Arthritis,
            Bacterial vaginosis, Yeast infections, Tonsillitis, Lumbago, Croup,
            Gout, Streptococcal, Gonorrhea, Gordeolum, and much more&hellip;
          </p>
          <p>
            Please note: PocketDoctor should not be&nbsp;used in&nbsp;emergency
            situations. If&nbsp;you think you have an&nbsp;emergency, call 911
            or&nbsp;your local emergency services immediately.
          </p>
        </>
      ),
    },
    {
      id: "emergency",
      question: (
        <>Can PocketDoctor handle emergency or&nbsp;emergency situations?</>
      ),
      answer: (
        <>
          <p>
            You should not use PocketDoctor in&nbsp;emergency or&nbsp;emergency
            situations!
          </p>
          <p>
            <strong>
              (If&nbsp;you believe that your situation or&nbsp;someone
              else&rsquo;s situation is&nbsp;or&nbsp;may become
              an&nbsp;emergency or&nbsp;unresolved situation,
              <br />
              you should IMMEDIATELY STOP USING OR&nbsp;ATTEMPTING TO&nbsp;USE
              the service and immediately call 911 or&nbsp;your local emergency
              services)
            </strong>
          </p>
          <p>
            You should also call the emergency service, for example
            if&nbsp;someone has:
          </p>
          <ul>
            <li>Severe bleeding</li>
            <li>Shortness of&nbsp;breath</li>
            <li>Reducing the level of&nbsp;breathing</li>
            <li>Head or&nbsp;spinal injuries</li>
            <li>Severe chest pain</li>
            <li>Loss of&nbsp;consciousness</li>
            <li>Loss of&nbsp;movement</li>
            <li>Acute entangled state</li>
            <li>Seizures, seizures that don&rsquo;t stop</li>
            <li>
              If&nbsp;you or&nbsp;someone else is&nbsp;suffering from
              a&nbsp;heart attack or&nbsp;stroke
            </li>
          </ul>
        </>
      ),
    },
    {
      id: "who-are-doctors",
      question: <>Who are your healthcare professionals?</>,
      answer: (
        <>
          <p>
            Our doctors have graduated from the best medical universities,
            received medical training, are certified in&nbsp;the United States
            in&nbsp;their specialty, have a&nbsp;license and diplomas. Our team
            of&nbsp;doctors includes experienced and accredited licensed
            professional consultants.
          </p>
        </>
      ),
    },
    {
      id: "how-check-doctors",
      question: <>How do&nbsp;you check your doctors?</>,
      answer: (
        <>
          <p>
            Each of&nbsp;our doctors is&nbsp;carefully selected to&nbsp;become
            a&nbsp;part of&nbsp;PocketDoctor!
          </p>
          <p>
            The initial selection includes interviews with our medical
            management, as&nbsp;well as&nbsp;a&nbsp;thorough analysis
            of&nbsp;their experience, training, licenses, diplomas. This
            is&nbsp;then subjected to&nbsp;a&nbsp;standard of&nbsp;checks. After
            that, doctors are trained in&nbsp;telemedicine and then practice.
          </p>
        </>
      ),
    },
    {
      id: "who-founded",
      question: <>Who founded PocketDoctor?</>,
      answer: (
        <>
          <p>
            The founders of&nbsp;PocketDoctor are Maxim Gerasimovich and Artem
            Lyubchuk. Currently, Maxim Gerasimovich is&nbsp;the founder
            of&nbsp;the project and CEO, Artem Lyubchuk is&nbsp;the co-founder
            of&nbsp;the project and CTO. The company was founded on&nbsp;April
            7, 2021.
          </p>
        </>
      ),
    },
  ],
  "My account": [
    {
      id: "patient-account",
      question: (
        <>
          How do&nbsp;I&nbsp;create a&nbsp;new personal account for
          a&nbsp;patient?
        </>
      ),
      answer: (
        <>
          <p>
            If&nbsp;you are already logged in&nbsp;to&nbsp;your personal
            account, first log out of&nbsp;it, and then
            go&nbsp;to&nbsp;Registration. To&nbsp;create a&nbsp;new personal
            account, go&nbsp;to&nbsp;the home page and click the Sing
            Up&nbsp;button in&nbsp;the upper-right corner. Please note that
            a&nbsp;single user cannot have multiple accounts, they will
            be&nbsp;deleted. A&nbsp;new email address is&nbsp;required for each
            new personal account.
          </p>
        </>
      ),
    },
    {
      id: "login-patient",
      question: (
        <>How do&nbsp;I&nbsp;log in&nbsp;to&nbsp;my&nbsp;personal account?</>
      ),
      answer: (
        <>
          <p>
            On&nbsp;the main page of&nbsp;PocketDoctor, in&nbsp;the upper-right
            corner of&nbsp;the page, click the sign in&nbsp;icon, then enter
            your email address and password.
          </p>
        </>
      ),
    },
    {
      id: "change-personal-info",
      question: <>How do&nbsp;I&nbsp;change my&nbsp;personal information?</>,
      answer: (
        <>
          <p>
            After you have logged in&nbsp;to&nbsp;your personal account, select
            &laquo;My record&raquo; page and go to &laquo;Personal data&raquo;
            section. After that, select what personal information you want
            to&nbsp;change, then click &laquo;Save&raquo;.
          </p>
        </>
      ),
    },
    {
      id: "need-location",
      question: <>Why do&nbsp;you need my&nbsp;location?</>,
      answer: (
        <>
          <p>
            We&nbsp;use your location to&nbsp;find a&nbsp;local doctor for you
            and the nearest pharmacy to&nbsp;you, where we&nbsp;will send
            a&nbsp;prescription to&nbsp;get your medication delivered
            as&nbsp;quickly as&nbsp;possible.
          </p>
        </>
      ),
    },
    {
      id: "need-emergency-number",
      question: <>Why do&nbsp;you need an&nbsp;emergency number?</>,
      answer: (
        <>
          <p>
            This is&nbsp;necessary in&nbsp;order to&nbsp;contact your family
            or&nbsp;friends in&nbsp;an&nbsp;emergency.
          </p>
        </>
      ),
    },
    {
      id: "need-email",
      question: <>Why do&nbsp;you need my&nbsp;email address?</>,
      answer: (
        <>
          <p>
            We&nbsp;use your email address so&nbsp;that you can safely register
            and log in. Also remind you of&nbsp;the upcoming consultation.
          </p>
        </>
      ),
    },
    {
      id: "multiple-accounts",
      question: <>Can I&nbsp;have multiple personal accounts?</>,
      answer: (
        <>
          <p>
            No, multiple personal accounts for one person are prohibited.
            If&nbsp;you have created several personal accounts for yourself
            by&nbsp;accident, please contact our support service for help:{" "}
            <UnifiedLink to={supportPage}>click here</UnifiedLink>
          </p>
        </>
      ),
    },
    {
      id: "same-account",
      question: (
        <>Can I&nbsp;and my&nbsp;partner use the same personal account?</>
      ),
      answer: (
        <>
          <p>
            No. One person per one personal account. This ensures your security
            and privacy.
          </p>
        </>
      ),
    },
    {
      id: "multiple-emails",
      question: (
        <>Can I&nbsp;use multiple emails for my&nbsp;personal account?</>
      ),
      answer: (
        <>
          <p>
            No. Currently, all PocketDoctor accounts can only use one email
            address for login and notifications.
          </p>
        </>
      ),
    },
    {
      id: "same-email-separate-accounts",
      question: (
        <>
          Can I&nbsp;and my&nbsp;partner use the same email address for our
          separate personal accounts?
        </>
      ),
      answer: (
        <>
          <p>
            No. One person per one personal account. This ensures your security
            and privacy.
          </p>
        </>
      ),
    },
    {
      id: "no-account-child",
      question: (
        <>
          I&nbsp;do&nbsp;not have a&nbsp;personal account, can I&nbsp;treat
          my&nbsp;child?
        </>
      ),
      answer: (
        <>
          <p>
            No. To&nbsp;treat your child, you need to&nbsp;create
            a&nbsp;personal account. Children under the age of&nbsp;18 can have
            a&nbsp;personal account only under the name of&nbsp;one of&nbsp;the
            parents.
          </p>
        </>
      ),
    },
    {
      id: "delete-account",
      question: (
        <>What if&nbsp;I&nbsp;want to&nbsp;delete my&nbsp;personal account?</>
      ),
      answer: (
        <>
          <p>
            If&nbsp;you complain, contact customer support with these issues and
            your account will be&nbsp;suspended.
          </p>
        </>
      ),
    },
  ],
  Security: [
    {
      id: "confidentiality",
      question: <>Is&nbsp;PocketDoctor confidential?</>,
      answer: (
        <>
          <p>
            Protecting patient data is&nbsp;a&nbsp;top priority for us, and
            PocketDoctor does not share personal data with third parties, with
            the exception of&nbsp;pharmacies and doctors that we&nbsp;have
            access to. Therefore, PocketDoctor encrypts all Internet traffic
            we&nbsp;use 256-bit SSL certificates, which include digital
            certificate authentication and 256-bit data encryption. 256-bit SSL
            certificates are the same type of&nbsp;security used by&nbsp;banks
            they are trusted by&nbsp;more than 99.9% of&nbsp;modern Internet
            users also all information is&nbsp;not stored on&nbsp;servers.
          </p>
          <p>
            In&nbsp;simple terms, this means that the content you enter
            on&nbsp;this site is&nbsp;encrypted and cannot be&nbsp;read
            by&nbsp;anyone trying to&nbsp;steal your personal information.
            We&nbsp;also protect your personal information by&nbsp;requesting
            an&nbsp;individual account and password, ensuring that you exit our
            site in&nbsp;a&nbsp;timely manner, and our team provides constant
            monitoring to&nbsp;ensure that our site is&nbsp;as&nbsp;secure
            as&nbsp;possible. In&nbsp;addition, to&nbsp;ensure the highest level
            of&nbsp;security, we&nbsp;use two-factor authentication-familiar
            from electronic banking.
          </p>
        </>
      ),
    },
    {
      id: "legitimity",
      question: <>Is&nbsp;the service you are offering legal?</>,
      answer: (
        <>
          <p>
            Yes, we&nbsp;act in&nbsp;accordance with HIPAA laws for online
            consultation.
          </p>
        </>
      ),
    },
    {
      id: "doctor-tells-personal",
      question: (
        <>What if&nbsp;the doctor tells me&nbsp;my&nbsp;personal information?</>
      ),
      answer: (
        <>
          <p>
            Do&nbsp;not worry, all doctors are thoroughly checked, they are
            monitored, and doctors also work under a&nbsp;contract not
            to&nbsp;disclose the patient&rsquo;s personal information.
          </p>
        </>
      ),
    },
    {
      id: "recorded-consultation",
      question: (
        <>Will my&nbsp;consultations be&nbsp;recorded on&nbsp;PocketDoctor?</>
      ),
      answer: (
        <>
          <p>
            Privacy is&nbsp;of&nbsp;the utmost importance to&nbsp;us.
            We&nbsp;respect the confidentiality of&nbsp;every patient and doctor
            consultation, and no&nbsp;visits are ever recorded
            at&nbsp;PocketDoctor.
          </p>
        </>
      ),
    },
    {
      id: "who-has-access",
      question: <>Who else can access my&nbsp;information?</>,
      answer: (
        <>
          <p>
            All the information you exchange through PocketDoctor
            is&nbsp;between you and your team of&nbsp;doctors. All data
            is&nbsp;encrypted before being transferred between your browser and
            the site server, and therefore third parties will not have access
            to&nbsp;your information.
          </p>
        </>
      ),
    },
    {
      id: "what-if-hacked",
      question: (
        <>
          What should I&nbsp;do&nbsp;if&nbsp;my&nbsp;personal account
          is&nbsp;hacked?
        </>
      ),
      answer: (
        <>
          <p>
            If&nbsp;you received a&nbsp;notification about changes to&nbsp;your
            account that you didn&rsquo;t make, or&nbsp;about an&nbsp;attempt
            to&nbsp;log in&nbsp;to&nbsp;your account, it&rsquo;s possible that
            someone hacked it. If&nbsp;you believe that someone has logged into
            your account without permission, you need to&nbsp;protect your
            account. To&nbsp;do&nbsp;this, you will need
            to&nbsp;go&nbsp;to&nbsp;your personal account, then
            go&nbsp;to&nbsp;settings and perform several actions to&nbsp;change
            your password and make sure that all the recent changes to&nbsp;your
            account were made by&nbsp;you. If&nbsp;you can&rsquo;t log
            in&nbsp;to&nbsp;your account, please contact customer support
            immediately.
          </p>
        </>
      ),
    },
    {
      id: "when-login-logout",
      question: (
        <>
          When do&nbsp;I&nbsp;need to&nbsp;log out and log in&nbsp;from
          my&nbsp;personal account on&nbsp;someone else&rsquo;s device?
        </>
      ),
      answer: (
        <>
          <ol>
            <li>
              When logging in, make sure that you have not saved your password
              on&nbsp;the device.
            </li>
            <li>
              When you log out, make sure that your password is&nbsp;not saved
              by&nbsp;closing the tab in&nbsp;the browser with the site and
              opening it&nbsp;after this operation, your account should not
              be&nbsp;on&nbsp;the site.
            </li>
          </ol>
        </>
      ),
    },
    {
      id: "detect-steal-attempts",
      question: (
        <>
          How can I&nbsp;detect attempts to&nbsp;steal my&nbsp;password and
          information from my&nbsp;personal account?
        </>
      ),
      answer: (
        <>
          <p>
            Sometimes attackers create fake sites that look like login pages
            on&nbsp;PocketDoctor. With their help, they try to&nbsp;steal
            usernames, passwords, and other information. This is&nbsp;called
            &laquo;phishing&raquo;. Remember that PocketDoctor never sends
            an&nbsp;email asking you to&nbsp;provide your account password, full
            credit card number, social security number, etc. If&nbsp;you receive
            a&nbsp;suspicious email, do&nbsp;not click on&nbsp;the links
            in&nbsp;it&nbsp;and do&nbsp;not respond to&nbsp;it, you can also
            block it.
          </p>
        </>
      ),
    },
  ],
  Payments: [
    {
      id: "how-much-cost",
      question: (
        <>
          How much does a&nbsp;consultation with a&nbsp;doctor
          in&nbsp;PocketDoctor cost?
        </>
      ),
      answer: (
        <>
          <p>
            The cost of&nbsp;the consultation is&nbsp;$49 for a&nbsp;family
            doctor and general practitioners, $75 for a&nbsp;dermatologist, $249
            for a&nbsp;psychologist (first visit) and $99 for subsequent visits.
            Prices are for a&nbsp;15-minute consultation.
          </p>
        </>
      ),
    },
    {
      id: "what-included",
      question: (
        <>
          What is&nbsp;included in&nbsp;my&nbsp;amount of&nbsp;money that
          I&nbsp;pay for the consultation?
        </>
      ),
      answer: (
        <>
          <p>
            Your consultation includes: an&nbsp;individual treatment plan from
            certified doctors. Each treatment plan includes a&nbsp;conclusion
            and a&nbsp;prescription that describes your diagnosis, how you
            should be&nbsp;treated and what to&nbsp;take from the medications.
          </p>
        </>
      ),
    },
    {
      id: "currency",
      question: <>What currency is&nbsp;needed for payment?</>,
      answer: (
        <>
          <p>We&nbsp;accept dollars, pounds and euros.</p>
        </>
      ),
    },
    {
      id: "payment-methods",
      question: <>What payment methods do&nbsp;you accept?</>,
      answer: (
        <>
          <p>
            For payment, you can use debit or&nbsp;credit cards: Visa,
            MasterCard, Discover, and American Express.
          </p>
        </>
      ),
    },
    {
      id: "why-not-free",
      question: <>Is&nbsp;PocketDoctor free?</>,
      answer: (
        <>
          <p>
            PocketDoctor has a&nbsp;paid plan and a&nbsp;free plan to&nbsp;use
            the platform for consultation with doctors.
          </p>
          <p>
            The price you pay for an&nbsp;individual visit on&nbsp;a&nbsp;tight
            schedule is&nbsp;used to&nbsp;cover the cost of&nbsp;the
            doctor&rsquo;s time. Keep in&nbsp;mind that our prices are much less
            than you would pay for an&nbsp;emergency room or&nbsp;ambulance,
            with our doctors available to&nbsp;you anywhere, anytime.
          </p>
        </>
      ),
    },
    {
      id: "is-payment-secure",
      question: <>Is&nbsp;payment via PocketDoctor secure?</>,
      answer: (
        <>
          <p>
            Yes, all data is&nbsp;encrypted before being transferred between
            your browser and the site server. We&nbsp;do&nbsp;not store payment
            card details on&nbsp;the server. The site is&nbsp;regularly checked
            for vulnerabilities.
          </p>
        </>
      ),
    },
    {
      id: "is-credit-card-safe",
      question: <>Is&nbsp;my&nbsp;credit card information safe?</>,
      answer: (
        <>
          <p>
            Yes, the cardholder&rsquo;s data is&nbsp;stored using one
            of&nbsp;the most advanced encryption methods available. We&nbsp;use
            multiple encryption keys that are stored on&nbsp;different physical
            servers. The data store where the cardholder&rsquo;s data
            is&nbsp;stored cannot be&nbsp;connected via the Internet.
            We&nbsp;believe it&nbsp;is&nbsp;important to&nbsp;keep your personal
            information separate to&nbsp;protect your privacy.
          </p>
        </>
      ),
    },
    {
      id: "how-money-debited",
      question: (
        <>
          How and when is&nbsp;money debited from the card in&nbsp;PocketDoctor?
        </>
      ),
      answer: (
        <>
          <p>
            When you request a&nbsp;consultation, the amount
            is&nbsp;automatically pre-authorized from your card. We&nbsp;have
            not withdrawn the money from your account, it&nbsp;is&nbsp;held
            by&nbsp;your bank until the consultation with your doctor
            is&nbsp;successful. Funds are automatically released and returned
            back to&nbsp;your card if&nbsp;your consultation request was
            cancelled within 48 hours or&nbsp;was unsuccessful.
          </p>
          <p>
            It&nbsp;depends on&nbsp;your bank how long it&nbsp;will take
            to&nbsp;return the funds to&nbsp;your card. Usually, it&nbsp;may
            take several business days for the Bank to&nbsp;return the funds
            to&nbsp;your card. In&nbsp;the event that a&nbsp;doctor receives
            your request and deems it&nbsp;unsuitable for treatment
            at&nbsp;PocketDoctor, or&nbsp;if&nbsp;it&nbsp;is&nbsp;a&nbsp;medical
            emergency, we&nbsp;will notify you immediately and your credit card
            will not be&nbsp;charged.
          </p>
        </>
      ),
    },
    {
      id: "payment-sure",
      question: (
        <>
          How can I&nbsp;be&nbsp;sure that my&nbsp;payment will go&nbsp;through?
        </>
      ),
      answer: (
        <>
          <p>
            Upon completion of&nbsp;each order, you will receive
            a&nbsp;confirmation email. If&nbsp;your payment is&nbsp;not
            successful, we&nbsp;will send you a&nbsp;reminder email with another
            chance to&nbsp;make your payment.
          </p>
        </>
      ),
    },
    {
      id: "money-back",
      question: <>Can I get my money back?</>,
      answer: (
        <>
          <p>
            We&nbsp;can guarantee that if&nbsp;you are not completely satisfied
            with the consultation or&nbsp;your treatment plan does not work,
            we&nbsp;will refund your money or&nbsp;consult you again as&nbsp;you
            decide.
          </p>
        </>
      ),
    },
    {
      id: "how-change-credit-card",
      question: <>How can I&nbsp;change my&nbsp;credit card?</>,
      answer: (
        <>
          <p>
            In&nbsp;your personal account, in&nbsp;the upper-left corner, select
            settings, then change your credit information and click save.
          </p>
        </>
      ),
    },
    {
      id: "not-accept-card",
      question: <>Why does the system not accept my&nbsp;card?</>,
      answer: (
        <>
          <p>
            If&nbsp;you have any problems with paying with a&nbsp;credit card
            via the Internet, we&nbsp;will be&nbsp;happy to&nbsp;help you write
            to&nbsp;the support service.
          </p>
        </>
      ),
    },
    {
      id: "card-rejected",
      question: <>What if&nbsp;my&nbsp;card is&nbsp;rejected?</>,
      answer: (
        <>
          <p>
            Don&rsquo;t worry, your card may be&nbsp;rejected for several
            reasons. We&nbsp;will immediately send you an&nbsp;email
            to&nbsp;help you update your payment information on&nbsp;our
            website.
          </p>
        </>
      ),
    },
    {
      id: "multiple-cards",
      question: (
        <>
          Is&nbsp;it&nbsp;possible to&nbsp;have multiple credit or&nbsp;debit
          cards in&nbsp;my&nbsp;personal account?
        </>
      ),
      answer: (
        <>
          <p>
            Yes, you can before starting the consultation, you need
            to&nbsp;choose one of&nbsp;several cards that you will use
            in&nbsp;your personal account.
          </p>
        </>
      ),
    },
    {
      id: "online-card-prejudice",
      question: (
        <>
          I&nbsp;am&nbsp;not comfortable sharing my&nbsp;credit card information
          online, what should I&nbsp;do?
        </>
      ),
      answer: (
        <>
          <p>
            We&nbsp;store your payment information on&nbsp;separate secure
            servers where there is&nbsp;no&nbsp;internet access. Third parties
            will never be&nbsp;able to&nbsp;get access to&nbsp;your card.
            We&nbsp;currently only accept credit and debit cards
            as&nbsp;a&nbsp;form of&nbsp;payment.
          </p>
        </>
      ),
    },
    {
      id: "charging-missed-appointment",
      question: (
        <>
          Will I&nbsp;be&nbsp;charged if&nbsp;I&nbsp;miss or&nbsp;cancel
          a&nbsp;visit?
        </>
      ),
      answer: (
        <>
          <p>
            You can cancel a&nbsp;visit for up&nbsp;to&nbsp;48 hours. However,
            if&nbsp;you cancel after this time period or&nbsp;fail to&nbsp;show
            up&nbsp;for a&nbsp;visit, you will be&nbsp;charged the full
            cancellation fee.
          </p>
        </>
      ),
    },
    {
      id: "subscription",
      question: (
        <>
          Do&nbsp;you have a&nbsp;subscription and how much does it&nbsp;cost?
        </>
      ),
      answer: (
        <>
          <p>
            At&nbsp;the moment, PocketDoctor does not offer a&nbsp;subscription.
            It&nbsp;will appear soon. We&nbsp;apologize for not being
            comfortable.
          </p>
        </>
      ),
    },
  ],
  Technical: [
    {
      id: "use-device",
      question: <>On&nbsp;which devices can I&nbsp;use PocketDoctor?</>,
      answer: (
        <>
          <p>
            PocketDoctor can be&nbsp;used on&nbsp;all common technical devices
            with high-speed Internet access. For example,
            on&nbsp;a&nbsp;smartphone, tablet, or&nbsp;computer.
            We&nbsp;recommend using PocketDoctor on&nbsp;a&nbsp;smartphone
            or&nbsp;tablet, as&nbsp;receiving and uploading images can
            be&nbsp;done from a&nbsp;single device and it&nbsp;is&nbsp;much more
            convenient.
          </p>
        </>
      ),
    },
    {
      id: "no-device",
      question: (
        <>
          I&nbsp;don&rsquo;t have a&nbsp;smartphone, tablet, or&nbsp;computer,
          is&nbsp;there any other way to&nbsp;talk to&nbsp;a&nbsp;doctor?
        </>
      ),
      answer: (
        <>
          <p>
            Unfortunately, no. To&nbsp;visit one of&nbsp;our doctors, you will
            need a&nbsp;mobile device, a&nbsp;phone, a&nbsp;tablet
            or&nbsp;a&nbsp;computer with Internet access.
          </p>
        </>
      ),
    },
    {
      id: "system-requirements",
      question: (
        <>
          What computer system requirements are required to&nbsp;use
          PocketDoctor?
        </>
      ),
      answer: (
        <>
          <p>To&nbsp;use PocketDoctor, you need to:</p>
          <ol>
            <li>
              At&nbsp;least Windows &reg; 7, Vista, XP, or&nbsp;Mac running OSX
              10.6 (Snow Leopard).
            </li>
            <li>
              High-speed Internet connection of&nbsp;at&nbsp;least 50 Mbps.
            </li>
            <li>
              Web camera with a&nbsp;resolution of&nbsp;at&nbsp;least 1.3
              megapixels.
            </li>
            <li>
              Microphone (most webcams already have a&nbsp;built-in microphone).
            </li>
            <li>Speakers.</li>
          </ol>
        </>
      ),
    },
    {
      id: "browsers-support",
      question: <>What web browsers does PocketDoctor support?</>,
      answer: (
        <>
          <p>
            We&nbsp;currently support: Google Chrome, Safari, Microsoft Edge and
            Mozilla Firefox.
          </p>
        </>
      ),
    },
    {
      id: "internet-connection",
      question: (
        <>
          Do&nbsp;I&nbsp;need an&nbsp;internet connection to&nbsp;use
          PocketDoctor?
        </>
      ),
      answer: (
        <>
          <p>
            Yes, to&nbsp;use PocketDoctor, you need an&nbsp;internet connection.
            For a&nbsp;high-quality signal, we&nbsp;recommend using Wi-Fi /5G
            /4G/ LTE.
          </p>
        </>
      ),
    },
    {
      id: "which-network",
      question: (
        <>On&nbsp;which network do&nbsp;I&nbsp;need to&nbsp;use PocketDoctor?</>
      ),
      answer: (
        <>
          <p>
            Our services are best available using high-speed internet connection
            / Wi-Fi / 5G /4G/ LTE for high-quality signal.
          </p>
        </>
      ),
    },
    {
      id: "connection-cutting-off",
      question: (
        <>
          What if&nbsp;my&nbsp;connection is&nbsp;cut off during
          a&nbsp;doctor&rsquo;s visit?
        </>
      ),
      answer: (
        <>
          <p>
            The Internet connection must be&nbsp;high-speed WIFI, 5G, 4G, LTE.
            If&nbsp;you have faced network issues, reload the browser page, and
            enter to the visit with your doctor again. You can also experiment
            by&nbsp;selecting video, audio, or&nbsp;chat mode. If&nbsp;all
            of&nbsp;the above does not work, please contact customer support.
          </p>
        </>
      ),
    },
    {
      id: "camera-doesnt-work",
      question: (
        <>
          What if&nbsp;a&nbsp;consultation has started and the camera
          doesn&rsquo;t work?
        </>
      ),
      answer: (
        <>
          <p>If&nbsp;your camera doesn&rsquo;t work:</p>
          <ol>
            <li>
              If&nbsp;your computer is&nbsp;equipped with an&nbsp;external video
              camera, make sure that the external video camera is&nbsp;connected
              to&nbsp;the computer, or&nbsp;check whether the camera
              is&nbsp;connected via bluetooth.
            </li>
            <li>
              <p>
                Make sure that you have allowed the system to&nbsp;use the video
                camera when you receive the appropriate request.
              </p>
              <p>
                If&nbsp;the system does not ask you for permission
                to&nbsp;connect the camera:
              </p>
              <p>
                In&nbsp;the browser settings, click &laquo;Always grant
                PocketDoctor access to&nbsp;my&nbsp;camera and
                microphone&raquo;, click &laquo;Done&raquo;, and then reload the
                page by&nbsp;clicking on&nbsp;the &laquo;Reload&raquo; button.
              </p>
            </li>
            <li>
              Go&nbsp;to&nbsp;your computer settings and check if&nbsp;access
              to&nbsp;the camera is&nbsp;not denied.
            </li>
            <li>
              Next step, you may have more than one camera connected
              to&nbsp;your computer. The following steps will help you move the
              system from camera to&nbsp;camera and check if&nbsp;you can see
              yourself. The following operations should be&nbsp;repeated for
              each of&nbsp;your cameras. Click on&nbsp;the camera icon
              on&nbsp;the right side of&nbsp;the browser&rsquo;s address bar.
              Click on&nbsp;the list of&nbsp;cameras in&nbsp;the window that
              appears, and then on&nbsp;the name of&nbsp;one of&nbsp;the
              unselected cameras. Click on&nbsp;the &laquo;Done&raquo; button
              and reload the page. After reloading the page, recheck the camera
              using the test module (from the menu in&nbsp;the top right corner
              of&nbsp;the website). If&nbsp;you still can&rsquo;t see yourself,
              please, try to replace the camera.
            </li>
            <li>
              If&nbsp;the previous steps did not solve the problem,
              it&nbsp;is&nbsp;possible that the driver (the program that
              is&nbsp;responsible for the operation of&nbsp;your camera)
              on&nbsp;your computer is&nbsp;outdated. To&nbsp;do&nbsp;this,
              reinstall the driver on&nbsp;your computer.
            </li>
            <li>
              If&nbsp;the previous tips didn&rsquo;t help, select audio
              or&nbsp;text mode on&nbsp;the top of&nbsp;the screen.
            </li>
          </ol>
        </>
      ),
    },
    {
      id: "mic-doesnt-work",
      question: (
        <>
          What if&nbsp;a&nbsp;consultation has started and the microphone
          doesn&rsquo;t work?
        </>
      ),
      answer: (
        <>
          <p>If&nbsp;your microphone doesn&rsquo;t work:</p>
          <ol>
            <li>
              Make sure that the microphone is&nbsp;connected to&nbsp;the
              computer. The microphone input is&nbsp;usually pink or&nbsp;has
              a&nbsp;microphone symbol somewhere near the input, or&nbsp;check
              if&nbsp;the microphone is&nbsp;connected via bluetooth.
            </li>
            <li>
              If&nbsp;there is&nbsp;a&nbsp;button on&nbsp;the headphones
              or&nbsp;microphone that turns the microphone on&nbsp;or&nbsp;off
              (mute), make sure that its position allows the microphone
              to&nbsp;work. Increase the sensitivity level of&nbsp;the
              microphone.
            </li>
            <li>
              Increase the sensitivity level of&nbsp;the microphone. You can do
              it on your device or in the chat settings.
            </li>
            <li>
              Make sure that you have allowed the system to&nbsp;use the
              microphone when you receive the appropriate request. <br />{" "}
              If&nbsp;&laquo;Block microphone access&raquo; is&nbsp;selected
              in&nbsp;the browser settings, select &laquo;Always grant
              PocketDoctor access to&nbsp;the microphone&raquo;, click
              &laquo;Done&raquo;, and then reload the page by&nbsp;clicking
              on&nbsp;the &laquo;Reload&raquo; button that appears under the
              browser address bar.
            </li>
            <li>
              Go&nbsp;to&nbsp;your computer settings and check if&nbsp;access
              to&nbsp;the microphone is&nbsp;not denied.
              <br /> If&nbsp;the previous steps did not solve the problem,
              it&nbsp;is&nbsp;possible that the driver (the program that
              is&nbsp;responsible for the operation of&nbsp;your microphone)
              on&nbsp;your computer is&nbsp;outdated. To&nbsp;do&nbsp;this,
              reinstall the driver on&nbsp;your computer.
            </li>
            <li>
              If&nbsp;the previous tips did not help, select the text mode
              on&nbsp;the top of&nbsp;the screen.
            </li>
          </ol>
        </>
      ),
    },
    {
      id: "speaker-doesnt-work",
      question: (
        <>
          What if&nbsp;a&nbsp;consultation has started and the speaker
          doesn&rsquo;t work?
        </>
      ),
      answer: (
        <>
          <p>If&nbsp;your speakers don&rsquo;t work:</p>
          <ol>
            <li>
              Make sure that the speakers are connected to&nbsp;the computer.
              The speaker input is&nbsp;usually green or&nbsp;has a&nbsp;speaker
              symbol somewhere near the input, or&nbsp;check if&nbsp;the
              speakers are connected via bluetooth. This item does not apply
              to&nbsp;computers that have built-in speakers.
            </li>
            <li>
              If&nbsp;the volume control is&nbsp;present on&nbsp;the headphones
              or&nbsp;speaker, make sure that its position corresponds
              to&nbsp;the maximum volume.
            </li>
            <li>
              Increase the overall sound level of&nbsp;your computer. You can do
              it on your device or in the chat settings.
            </li>
            <li>
              Go&nbsp;to&nbsp;your computer settings and check if&nbsp;access
              to&nbsp;the speakers is&nbsp;not denied.
            </li>
            <li>
              If&nbsp;the previous steps did not solve the problem,
              it&nbsp;is&nbsp;possible that the driver (the program that
              is&nbsp;responsible for the operation of&nbsp;your speakers)
              on&nbsp;your computer is&nbsp;outdated. To&nbsp;do&nbsp;this,
              reinstall the driver on&nbsp;your computer.
            </li>
            <li>
              If&nbsp;the previous tips did not help, select the text mode
              on&nbsp;the top of&nbsp;the screen.
            </li>
          </ol>
        </>
      ),
    },
  ],
};
