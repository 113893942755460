import styled from "@emotion/styled";
import { compareDesc, format, parseISO } from "date-fns";
import { EmptyStateIcon } from "Doctor/Profile/PatientRecord/EmptyState";
import { toDoctorPatientRecordAddLabTest, useDoctorId } from "Doctor/Router";
import { useStore } from "effector-react";
import {
  deleteLabTest,
  DocumentIcon,
  LabTestDTO,
  labTests as $labTests,
  loadLabTests,
} from "Patient/Record";
import { usePatientId } from "Patient/Router";
import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Block,
  Button,
  color,
  EditIcon,
  Flex,
  LoaderWithOverlay,
  ModalWindow,
  RecordLabIcon,
  TrashIcon,
  Typography,
} from "ui-kit";
import { LoaderWithSpacing } from "../Loader";
import { EditLabTest } from "./EditView";

export const LabTestsList = memo(() => {
  const navigate = useNavigate();
  const [doctorId, patientId] = [useDoctorId(), usePatientId()];
  const labTests = useStore($labTests);
  const [docIdMarkedForDeletion, markDocumentForDeletion] = useState<string>();
  const [recordsInEditMode, setEditMode] = useState<string[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const isDeleting = useStore(deleteLabTest.pending);

  useEffect(() => {
    loadLabTests({ patientId }).finally(() => setIsLoading(false));
  }, [patientId]);

  if (isLoading) {
    return <LoaderWithSpacing />;
  }

  return (
    <>
      {isDeleting && <LoaderWithOverlay />}
      {docIdMarkedForDeletion && (
        <ModalWindow>
          <Block padding={8}>
            <Block textAlign="center" marginBottom={3}>
              <Typography type="h3">Do you really want to delete?</Typography>
            </Block>
            <Flex alignItems="center" justifyContent="center">
              <Block marginRight={2}>
                <Button
                  type="button"
                  onClick={() => {
                    markDocumentForDeletion(undefined);
                    deleteLabTest({
                      patientId,
                      documentId: docIdMarkedForDeletion,
                    });
                  }}
                >
                  Yes
                </Button>
              </Block>
              <Block marginLeft={2}>
                <Button
                  type="button"
                  onClick={() => markDocumentForDeletion(undefined)}
                >
                  No
                </Button>
              </Block>
            </Flex>
          </Block>
        </ModalWindow>
      )}
      {labTests.length > 0 ? (
        <Block marginTop={3} marginBottom={6} paddingLeft={5} paddingRight={5}>
          {labTests.sort(newestOnTop).map((test) => (
            <DocumentSection key={test.objectId}>
              {recordsInEditMode.includes(test.objectId) ? (
                <EditLabTest
                  labTestId={test.objectId}
                  documents={test.labTestsDocuments}
                  description={test.description}
                  afterFinish={() =>
                    setEditMode(
                      recordsInEditMode.filter((id) => id !== test.objectId)
                    )
                  }
                />
              ) : (
                <>
                  <Flex
                    alignItems="flex-start"
                    justifyContent="space-between"
                    marginBottom={1}
                  >
                    <Block>
                      {test.labTestsDocuments.map((metadata) => (
                        <Flex key={metadata.url} alignItems="center">
                          <Flex alignItems="center" marginRight={1}>
                            <DocumentIcon
                              name={metadata.name}
                              fill={color.G200}
                            />
                          </Flex>
                          <DocumentUploadLink
                            target="_blank"
                            type="h2"
                            href={metadata.url}
                          >
                            {shrinkFileName(metadata.name)}
                          </DocumentUploadLink>
                        </Flex>
                      ))}
                    </Block>
                    {doctorId === test.author.id && (
                      <Flex>
                        <Block marginRight={2}>
                          <EditRecordIcon
                            fill={color.N300}
                            onClick={() =>
                              setEditMode([...recordsInEditMode, test.objectId])
                            }
                          />
                        </Block>
                        <Block>
                          <RemoveRecordIcon
                            onClick={() =>
                              markDocumentForDeletion(test.objectId)
                            }
                            fill={color.N300}
                          />
                        </Block>
                      </Flex>
                    )}
                  </Flex>
                  <Block marginBottom={1}>
                    <Typography type="h4" fontColor="N300">
                      Uploaded:{" "}
                      {format(parseISO(test.createdAt), "MM/dd/yyyy hh:mm a")}{" "}
                      by{" "}
                      {test.author.id === doctorId ? "you" : test.author.name}
                    </Typography>
                  </Block>
                  <Block>
                    <Typography type="h4">
                      {test.description || "N/A"}
                    </Typography>
                  </Block>
                </>
              )}
            </DocumentSection>
          ))}
        </Block>
      ) : (
        <Block marginBottom={8} marginTop={6} textAlign="center">
          <Flex justifyContent="center" marginBottom={4}>
            <EmptyStateIcon>
              <RecordLabIcon width="86px" height="86px" />
            </EmptyStateIcon>
          </Flex>
          <Block marginBottom={1}>
            <Typography fontColor="N300" type="h2">
              No Lab tests
            </Typography>
          </Block>
          <Block marginBottom={4}>
            <Typography fontColor="N300" fontWeight={400} type="h3">
              Add Lab tests for the patient
            </Typography>
          </Block>
          <Flex justifyContent="center">
            <Block textAlign="center">
              <Button
                filled
                onClick={() =>
                  navigate(
                    toDoctorPatientRecordAddLabTest({ doctorId, patientId })
                  )
                }
              >
                Add
              </Button>
            </Block>
          </Flex>
        </Block>
      )}
    </>
  );
});

function newestOnTop(labTest1: LabTestDTO, labTest2: LabTestDTO) {
  return compareDesc(
    new Date(labTest1.createdAt),
    new Date(labTest2.createdAt)
  );
}

function shrinkFileName(name: string) {
  const filenameChunks = name.split(".");
  const [filename, format] = [
    filenameChunks.slice(0, -1).join(".").slice(0, 30),
    filenameChunks[filenameChunks.length - 1],
  ];
  return `${filename}${name.length > 30 ? "..." : "."}${format}`;
}

const DocumentSection = styled.section`
  border-bottom: 1px solid ${color.N200};
  margin-bottom: 32px;
  padding-bottom: 32px;
  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

const DocumentUploadLink = styled(Typography)`
  color: ${color.G200};
  &:hover {
    color: ${color.G300};
  }
`.withComponent("a");

const EditRecordIcon = styled(EditIcon)`
  &:hover,
  &:focus {
    cursor: pointer;
    fill: ${color.O200};
  }
`;

const RemoveRecordIcon = styled(TrashIcon)`
  &:hover,
  &:focus {
    cursor: pointer;
    fill: ${color.O200};
  }
`;
