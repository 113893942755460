import { VolumeRenderer } from "./VolumeRenderer";

export class VolumeAnimator {
  private ctx: AudioContext;
  private rafId: number = 0;
  private isStopping: boolean = false;
  private renderer: VolumeRenderer;
  private stream?: MediaStream;
  private analyser?: AnalyserNode;

  constructor(ctx: AudioContext, renderer: VolumeRenderer) {
    this.ctx = ctx;
    this.renderer = renderer;
  }

  changeStream(stream?: MediaStream) {
    const { isStopping } = this;
    this.stop();
    if (stream) {
      if (stream.id === this.stream?.id) {
        if (!isStopping) {
          this.start();
        }
      } else {
        this.stream = stream;
        const audioAnalyzer = setupAnalyzer({ audioContext: this.ctx, stream });
        audioAnalyzer.minDecibels = -100;
        audioAnalyzer.maxDecibels = -30;
        audioAnalyzer.fftSize = 64;
        this.analyser = audioAnalyzer;
      }
    }
  }

  start() {
    this.renderer.start();
    this.isStopping = false;

    const drawLoop = () => {
      if (this.isStopping || !this.analyser) {
        this.isStopping = false;
        return;
      }
      const volume = getVolume(this.analyser);
      this.renderer.draw(volume);
      this.rafId = window.requestAnimationFrame(drawLoop);
    };

    drawLoop();
  }

  stop() {
    this.renderer.stop();
    this.isStopping = true;
    window.cancelAnimationFrame(this.rafId);
  }
}

function setupAnalyzer({
  audioContext,
  stream,
}: {
  audioContext: AudioContext;
  stream: MediaStream;
}) {
  const node = audioContext.createMediaStreamSource(stream);
  const analyser = audioContext.createAnalyser();
  node.connect(analyser);
  return analyser;
}

function getVolume(analyser: AnalyserNode) {
  const buckets = new Uint8Array(analyser.frequencyBinCount);
  analyser.getByteFrequencyData(buckets);
  return buckets.reduce((a, b) => a + b) / analyser.frequencyBinCount / 128;
}
