import { color as UIcolors } from "ui-kit";

interface MeterDrawerOptions {
  width: number;
  height: number;
}

const WATCHDOG_PERIOD = 1000;
const BLOCKS = 18;
const TOO_LOUD = 15 / BLOCKS;
const TOO_QUIET = 2 / BLOCKS;
const DECAY_RATE = 0.9;

export class VolumeRenderer {
  canvasCtx: CanvasRenderingContext2D;
  prevVolume: number = 0;
  width: number;
  height: number;
  readonly bucketCount: number = BLOCKS;
  readonly bucketSize: number = 1 / this.bucketCount;
  readonly barSpacing: number;
  readonly barWidth: number;
  readonly bucketCeilings: number[] = Array(this.bucketCount)
    .fill(0)
    .map((_, i) => (i + 1) * this.bucketSize); // range [1/18...1]
  private watchdog: number = +Date.nowUniversal;
  private watchdogExpired: boolean = false;
  private watchdogTimer: number = window.setTimeout(() => {
    this.checkWatchdog();
  }, WATCHDOG_PERIOD / 2);

  constructor(
    ctx: CanvasRenderingContext2D,
    { height, width }: MeterDrawerOptions
  ) {
    const dpr = window.devicePixelRatio || 1;
    this.canvasCtx = ctx;
    this.height = height;
    this.width = width;
    this.barSpacing = (this.width / dpr) * 0.016;
    this.barWidth = this.width / (this.bucketCount * dpr) - this.barSpacing;
    this.stop();
  }

  start() {
    this.prevVolume = 0;
    this.watchdogExpired = false;
    this.startWatchdog();
  }

  stop() {
    this.prevVolume = 0;
    window.clearTimeout(this.watchdogTimer);
    this.watchdogExpired = false;
    this.draw(0);
  }

  clear() {
    const { canvasCtx, height, width } = this;
    canvasCtx.clearRect(0, 0, width, height);
  }

  draw(volume: number) {
    this.watchdog = +Date.nowUniversal;
    const {
      prevVolume,
      canvasCtx,
      barSpacing,
      barWidth,
      height: barHeight,
      bucketCount,
      bucketSize,
    } = this;
    const vol = Math.max(volume, prevVolume * DECAY_RATE);
    this.prevVolume = vol;
    this.clear();

    this.bucketCeilings.forEach((bucketCeiling, i) => {
      let color = UIcolors.N200;

      if (vol > bucketCeiling) {
        if (bucketCeiling > TOO_LOUD) {
          color = UIcolors.R100;
        }
        if (bucketCeiling <= TOO_QUIET) {
          color = UIcolors.O100;
        }
        if (bucketCeiling > TOO_QUIET && bucketCeiling <= TOO_LOUD) {
          color = UIcolors.G200;
        }
      }

      canvasCtx.fillStyle = this.watchdogExpired ? UIcolors.N100 : color;

      const barX = (barWidth + barSpacing) * bucketCount * bucketSize * i;
      const barY = 0;

      canvasCtx.fillRect(barX, barY, barWidth, barHeight);

      /**
       * Handle partial filling of the last block
       */
      if (
        !this.watchdogExpired &&
        bucketCeiling > vol &&
        vol > bucketCeiling - this.bucketSize
      ) {
        let color = UIcolors.G200;
        if (vol > TOO_LOUD) {
          color = UIcolors.R100;
        }
        if (vol <= TOO_QUIET) {
          color = UIcolors.O100;
        }
        canvasCtx.fillStyle = color;
        canvasCtx.fillRect(
          barX,
          barY,
          ((vol % this.bucketSize) / this.bucketSize) * barWidth,
          barHeight
        );
      }
    });
  }

  private startWatchdog() {
    this.watchdog = +Date.nowUniversal;
    this.watchdogTimer = window.setTimeout(() => {
      this.checkWatchdog();
    }, WATCHDOG_PERIOD / 2);
  }

  private checkWatchdog() {
    const now = +Date.nowUniversal;
    if (now - this.watchdog > WATCHDOG_PERIOD) {
      this.watchdogExpired = true;
      this.draw(0);
    } else {
      this.watchdogExpired = false;
    }
    this.startWatchdog();
  }
}
