import styled from "@emotion/styled";
import { useDoctor } from "Doctor/Auth";
import {
  toDoctorPatientRecordHome,
  toDoctorProfileHomePage,
  toDoctorWaitingRoomPage,
} from "Doctor/Router";
import {
  VideoPlayer,
  VisitStream,
  joinSession,
  leaveSession,
} from "MediaStream";
import { Visit, fetchVisitById } from "Visit";
import { cancelRequest } from "apiConnection";
import {
  differenceInSeconds,
  formatDistance,
  intervalToDuration,
  isAfter,
} from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";
import { useStore } from "effector-react";
import { memo, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getCurrentTimezoneIANA } from "shared-functions/date";
import {
  AudioIcon,
  AutoUpdates,
  Avatar,
  Block,
  BoxWithShadow,
  Button,
  ButtonsGroup,
  ChatIcon,
  Flex,
  GearIcon,
  Grid,
  IncognitoAvatar,
  Loader,
  PhoneRetroIcon,
  ProfileAvatarSMIcon,
  ProfileIncognito,
  Typography,
  UnifiedLink,
  VideoIcon,
  boxShadow,
  color,
  errorNotification,
} from "ui-kit";
import { Settings } from "./Settings";
import { settings, setupStreamDevices } from "./streamSettings";
import { $visitStream, startVirtualVisit } from "./visitStream";

export const VirtualVisitPage = memo(() => {
  const { visitId } = useParams<{ visitId: string }>();
  const me = useDoctor()!; // Contract: only logged-in doctor have access to this page (see Doctor/Router)
  const [visit, setVisit] = useState<Visit>();
  const [isVisitLoading, setVisitLoading] = useState(true);
  const [isVisitOver, setVisitOver] = useState(false);
  const [isVisitPending, setIsVisitPending] = useState(false);
  const { publisher, subscribers, session, sessionError } =
    useStore($visitStream);
  const { screenSize, reversedVideo } = useStore(settings);
  const [areSettingsOpened, displaySettings] = useState(false);
  const sessionIsLoading = useStore(joinSession.pending);
  // for one-to-one video calls, first subscriber is a patient
  const patientOnCall = subscribers.length > 0 ? subscribers[0] : undefined;
  const patientName = visit?.patient.personalInformation?.title
    ? `${capitalize(visit?.patient.personalInformation?.title)}. ${
        visit?.patient.personalInformation?.firstName
      } ${visit?.patient.personalInformation?.lastName}`
    : `${visit?.patient.personalInformation?.firstName} ${visit?.patient.personalInformation?.lastName}`;
  const navigate = useNavigate();

  const beforeUnloadListener = useCallback(() => {
    if (!isVisitOver && session) {
      leaveSession();
    }
  }, [isVisitOver, session]);

  useEffect(() => {
    window.addEventListener("beforeunload", beforeUnloadListener);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadListener);
    };
  }, [beforeUnloadListener]);

  useEffect(() => {
    fetchVisitById(visitId as string)
      .then((visit) => {
        const isPending = shouldWaitForVisit(visit);
        const isOver = isVisitTimeOver(visit);
        setVisitLoading(false);
        setVisit(visit);
        setIsVisitPending(isPending);
        setVisitOver(isOver);
        return {
          visitId: visit.id,
          connectionType: visit.connectionType,
          isPending,
          isOver,
        };
      })
      .then(
        ({
          visitId,
          connectionType,
          isPending,
          isOver,
        }: {
          visitId: string;
          connectionType: VisitStream;
          isPending: boolean;
          isOver: boolean;
        }) => {
          if (
            !(
              session ||
              sessionIsLoading ||
              sessionError ||
              isPending ||
              isOver
            )
          ) {
            setupStreamDevices(connectionType);
            joinSession({ sessionName: visitId, userId: me.id })
              .then(({ sessionName }) => startVirtualVisit(sessionName))
              .catch((error) => {
                errorNotification(error.message);
              });
          }
        }
      );
    return () => {
      cancelRequest();
    };
  }, [me.id, session, sessionIsLoading, sessionError, visitId]);

  const leaveVisit = useCallback(() => {
    leaveSession();
    if (isVisitTimeOver(visit!)) {
      // Show thank you screen instead of redirect to WR
      return setVisitOver(true);
    }
    navigate(toDoctorWaitingRoomPage(me.id));
  }, [navigate, me, visit]);

  if (isVisitLoading) {
    return (
      <PageContainer>
        <Flex
          alignItems="center"
          height="100%"
          width="100%"
          justifyContent="center"
        >
          <Loader />
        </Flex>
      </PageContainer>
    );
  }

  if (!(isVisitLoading || visit)) {
    return (
      <PageContainer>
        <Flex
          alignItems="center"
          height="100%"
          width="100%"
          justifyContent="center"
        >
          <Typography as="h1" type="h1">
            No visit found. Make sure that visit was booked.{" "}
            <UnifiedLink type="inherit" to={toDoctorWaitingRoomPage(me.id)}>
              Go to waiting room
            </UnifiedLink>
          </Typography>
        </Flex>
      </PageContainer>
    );
  }

  if (isVisitPending) {
    return (
      <PageContainer>
        <Grid
          height="100%"
          gridTemplateColumns="100%"
          gridTemplateRows="repeat(2, 50%)"
        >
          <VisitWaitMessageContainer textAlign="center">
            <Typography fontColor="N300" type="h1">
              Visit with {patientName} will start in{" "}
              <AutoUpdates
                interval={1}
                whenStop={() => !shouldWaitForVisit(visit!)}
                onStop={() => {
                  if (
                    visit?.id &&
                    !(session || sessionIsLoading || sessionError)
                  ) {
                    joinSession({ sessionName: visit.id, userId: me.id })
                      .then(({ sessionName }) => startVirtualVisit(sessionName))
                      .catch((error) => {
                        errorNotification(error.message);
                      });
                  }
                }}
              >
                {() =>
                  formatDistance(
                    Date.nowUniversal,
                    utcToZonedTime(
                      visit!.timeSlot.from,
                      getCurrentTimezoneIANA()
                    )
                  )
                }
              </AutoUpdates>
            </Typography>
            <Block>
              <UnifiedLink type="h1" to={toDoctorWaitingRoomPage(me.id)}>
                Go to waiting room
              </UnifiedLink>
            </Block>
          </VisitWaitMessageContainer>
          <GreenUnderlay height="100%" />
        </Grid>
      </PageContainer>
    );
  }

  if (isVisitOver) {
    return (
      <Flex
        alignItems="center"
        flexDirection="column"
        height="100vh"
        justifyContent="center"
      >
        <Block marginBottom={4}>
          <Typography as="h3" type="h1" fontWeight={700} fontColor="N300">
            Thank you for your visit!
          </Typography>
        </Block>
        <Block textAlign="center">
          <Button
            filled
            onClick={() => navigate(toDoctorProfileHomePage(me.id))}
          >
            Home
          </Button>
        </Block>
      </Flex>
    );
  }

  return (
    <PageContainer>
      {areSettingsOpened && <Settings onBack={() => displaySettings(false)} />}
      <VisitContainer
        gridTemplateColumns="100%"
        gridTemplateRows="120px 1fr"
        position="relative"
      >
        <VisitInfoHeader
          gridTemplateColumns="repeat(3, 1fr)"
          alignItems="center"
        >
          <Flex alignItems="center">
            <Avatar height="64px" marginRight={2} width="64px">
              {visit?.doctor.personalInformation?.photo ? (
                <img
                  src={visit?.doctor.personalInformation?.photo.url}
                  alt="Doctor avatar"
                />
              ) : (
                <IncognitoAvatar className="incognito">
                  <ProfileIncognito height="32px" width="32px" />
                </IncognitoAvatar>
              )}
            </Avatar>
            <Block>
              <Block marginBottom={0.5}>
                <Typography type="h3">
                  Dr.&nbsp;
                  {`${visit?.doctor.personalInformation?.firstName} ${visit?.doctor.personalInformation?.lastName}`}
                </Typography>
              </Block>
              <Block>
                <Typography fontColor="N300" type="h4">
                  Consultation{" "}
                  {format(
                    utcToZonedTime(
                      visit!.timeSlot.from,
                      getCurrentTimezoneIANA()
                    ),
                    "hh:mm"
                  )}
                  &nbsp;
                  {format(
                    utcToZonedTime(
                      visit!.timeSlot.from,
                      getCurrentTimezoneIANA()
                    ),
                    "a"
                  )}{" "}
                  &mdash;{" "}
                  {format(
                    utcToZonedTime(
                      visit!.timeSlot.to,
                      getCurrentTimezoneIANA()
                    ),
                    "hh:mm"
                  )}
                  &nbsp;
                  {format(
                    utcToZonedTime(
                      visit!.timeSlot.to,
                      getCurrentTimezoneIANA()
                    ),
                    "a"
                  )}
                </Typography>
              </Block>
            </Block>
          </Flex>
          <Block>
            <ButtonsGroup
              config={[
                {
                  isSelected: visit?.connectionType === "video",
                  title: "Video",
                  clickCallback: () => {},
                  leftSideIcon: <VideoIcon />,
                },
                {
                  isDisabled: true,
                  isSelected: visit?.connectionType === "audio",
                  tooltip: "In development",
                  title: "Audio",
                  clickCallback: () => {},
                  leftSideIcon: <AudioIcon />,
                },
                {
                  isDisabled: true,
                  isSelected: visit?.connectionType === "text",
                  tooltip: "In development",
                  title: "Text",
                  clickCallback: () => {},
                  leftSideIcon: <ChatIcon />,
                },
              ]}
            />
          </Block>
          <ProfileActionsContainer>
            <ActionButton type="button" onClick={() => displaySettings(true)}>
              <GearIcon
                className="icon"
                fill={color.N300}
                height="32px"
                width="32px"
              />
            </ActionButton>
            <ActionButton
              type="button"
              onClick={() =>
                navigate(
                  toDoctorPatientRecordHome({
                    doctorId: visit!.doctor.id,
                    patientId: visit!.patient.id,
                  })
                )
              }
            >
              <ProfileAvatarSMIcon
                className="icon"
                fill={color.N300}
                height="35px"
                width="35px"
              />
            </ActionButton>
          </ProfileActionsContainer>
        </VisitInfoHeader>
        <MediaArea>
          {patientOnCall?.video ? (
            <>
              <PatientStreamDataContainer>
                <Typography fontColor="N0" type="h2">
                  {patientName}
                </Typography>
              </PatientStreamDataContainer>
              <MainVideo
                fullWidth={screenSize === "wide"}
                audioTrack={
                  patientOnCall.isMuted === true ? null : patientOnCall.audio
                }
                videoTrack={patientOnCall.video}
              />
            </>
          ) : (
            <Flex alignItems="center" justifyContent="center" height="100%">
              <Typography type="h1" as="h1">
                Waiting for {patientName}
              </Typography>
            </Flex>
          )}
          <ThumbnailVideoContainer>
            {publisher?.video ? (
              <VideoPlayer
                mirrored={reversedVideo}
                audioTrack={publisher.audio}
                videoTrack={publisher.video}
              />
            ) : null}
          </ThumbnailVideoContainer>
          <StreamActionsContainer
            className="streamActionsContainer"
            visible={Boolean(sessionError)}
          >
            <HangUpCallContainer alignItems="center">
              <HangUpIcon onClick={leaveVisit}>
                <PhoneRetroIcon width="24px" height="24px" />
              </HangUpIcon>
              <Typography fontColor="G200" type="h4">
                <AutoUpdates
                  interval={1}
                  whenStop={() => isVisitTimeOver(visit!)}
                >
                  {() => {
                    if (isVisitTimeOver(visit!)) {
                      return "00:00:00";
                    }
                    const timeLeft = intervalToDuration({
                      start: Date.nowUniversal,
                      end: utcToZonedTime(
                        visit!.timeSlot.to,
                        getCurrentTimezoneIANA()
                      ),
                    });
                    return `${formatTimerDigits(
                      timeLeft.hours
                    )}:${formatTimerDigits(
                      timeLeft.minutes
                    )}:${formatTimerDigits(timeLeft.seconds)}`;
                  }}
                </AutoUpdates>
              </Typography>
            </HangUpCallContainer>
          </StreamActionsContainer>
        </MediaArea>
      </VisitContainer>
    </PageContainer>
  );
});

function shouldWaitForVisit(visit: Visit) {
  return (
    differenceInSeconds(
      utcToZonedTime(visit.timeSlot.from, getCurrentTimezoneIANA()),
      Date.nowUniversal
    ) > 0
  );
}

function isVisitTimeOver(visit: Visit) {
  return isAfter(
    Date.nowUniversal,
    utcToZonedTime(visit.timeSlot.to, getCurrentTimezoneIANA())
  );
}

function capitalize(word?: string) {
  if (!word) {
    return undefined;
  }
  return `${word[0].toUpperCase()}${word.slice(1)}`;
}

function formatTimerDigits(time?: number) {
  if (!time) {
    return "00";
  }
  if (time < 10) {
    return `0${time}`;
  }
  return time;
}

const PageContainer = styled(Block)`
  height: 100vh;
  position: relative;
  width: 100%;
`;

const VisitContainer = styled(Grid)`
  background-color: ${color.N0};
  height: 100%;
`;

const VisitInfoHeader = styled(Grid)`
  border-radius: 10px 10px 0 0;
  box-shadow: ${boxShadow.extraSmall};
  padding: 0 32px;
`;

const ProfileActionsContainer = styled(Grid)`
  grid-auto-flow: column;
  grid-column-gap: 32px;
  justify-content: end;
`;

const ActionButton = styled.button`
  all: unset;
  & .icon {
    cursor: pointer;
  }
  & .icon:hover {
    fill: ${color.G200};
  }
`;

const PatientStreamDataContainer = styled(Block)`
  left: 32px;
  position: absolute;
  top: 32px;
  z-index: 2;
`;

const MainVideo = styled(VideoPlayer)`
  height: 100%;
  width: 100%;
`;

const ThumbnailVideoContainer = styled(BoxWithShadow)`
  background-color: ${color.N300};
  bottom: 32px;
  border: 3px solid ${color.N0};
  border-radius: 10px;
  width: 292px;
  height: 198px;
  position: absolute;
  right: 32px;
`;

type ActionsContainerState = {
  visible: boolean;
};

const StreamActionsContainer = styled(Flex)<ActionsContainerState>`
  bottom: 32px;
  justify-content: center;
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  position: absolute;
  width: 100%;
  transition: opacity 0.15s ease-in;
`;

const MediaArea = styled(Block)`
  position: relative;

  & .streamActionsContainer {
    opacity: 0;
  }
  &:hover .streamActionsContainer {
    opacity: 1;
  }
`;

const VisitWaitMessageContainer = styled(Block)`
  place-self: center;
`;

const GreenUnderlay = styled(Block)`
  background-color: ${color.G200};
`;

const HangUpCallContainer = styled(Flex)`
  background-color: ${color.N0};
  border-radius: 64px;
  box-shadow: ${boxShadow.extraSmall};
  padding: 8px;
  top: 50%;
  width: 160px;
  z-index: 2;
`;

const HangUpIcon = styled(Flex)`
  align-items: center;
  background-color: ${color.O100};
  border-radius: 50%;
  justify-content: center;
  height: 48px;
  margin-right: 16px;
  width: 48px;
  &:hover,
  &:focus {
    background-color: ${color.O200};
    cursor: pointer;
  }
`;
