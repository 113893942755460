import { useDoctor } from "./doctorSession";

export function useDoctorAccountWasCreated() {
  const doctor = useDoctor();
  if (doctor === undefined) {
    return false;
  }
  if (!doctor.hasAccount) {
    return doctor.onModeration;
  }
  return true;
}
