import {
  ChangeEvent,
  ElementType,
  forwardRef,
  HTMLProps,
  memo,
  ReactNode,
  Ref,
  useMemo,
  useState,
} from "react";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { ErrorMessage } from "../ErrorMessage";
import { Block } from "../Layout";
import { Typography } from "../Typography";
import { InputElement, Label } from "./PureInput";

export const Input = forwardRef(
  (props: InputProps, refObject: Ref<HTMLInputElement>) => (
    <InputComponent {...props} ref={null} refObject={refObject} />
  )
);

type InputProps = HTMLProps<HTMLInputElement> & {
  as?: ElementType;
  boxShadow?: string;
  errorMessage?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | string;
  icon?: ReactNode;
  hasErrors?: boolean;
  hasValue?: boolean;
  // input has a native `required` property. But to bypass browser validation, need to rely on custom flag
  isRequired?: boolean;
  optional?: boolean;
};

type Props = InputProps & {
  refObject?: Ref<HTMLInputElement>;
};

const InputComponent = memo(
  ({
    type = "text",
    autoFocus,
    placeholder,
    boxShadow,
    className,
    disabled,
    icon,
    label,
    name,
    errorMessage,
    hasErrors,
    refObject,
    onChange,
    onFocus,
    defaultValue,
    isRequired,
    optional,
    ...props
  }: Props) => {
    const [inputValue, setInputValue] = useState(defaultValue || "");
    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
      onChange && onChange(e);
    };
    const id = useMemo(() => uuidv4(), []);
    return (
      <>
        <Block position="relative">
          {label && (
            <Block marginBottom={0.5}>
              <Label htmlFor={id}>
                {label}
                {isRequired && (
                  <Typography lineHeight="0" type="h4">
                    &nbsp;&#42;
                  </Typography>
                )}
                {optional && (
                  <Typography
                    lineHeight="0"
                    fontColor="N300"
                    fontStyle="italic"
                    type="h6"
                  >
                    &nbsp;(Optional)
                  </Typography>
                )}
              </Label>
            </Block>
          )}
          <Block position="relative">
            <InputElement
              {...props}
              ref={refObject}
              id={id}
              name={name}
              onChange={onInputChange}
              onFocus={onFocus}
              type={type}
              autoFocus={autoFocus}
              placeholder={placeholder}
              boxShadow={boxShadow}
              className={className}
              disabled={disabled}
              hasErrors={hasErrors}
              defaultValue={defaultValue}
              hasValue={
                props.hasValue === undefined
                  ? !!inputValue && inputValue.toString().length > 0
                  : props.hasValue
              }
            />
            {icon}
          </Block>
        </Block>
        {errorMessage && <ErrorMessage>{errorMessage.toString()}</ErrorMessage>}
      </>
    );
  }
);
