import { memo, ReactNode, SVGProps } from "react";
import {
  CSVFileIcon,
  DOCFileIcon,
  FileIcon,
  JPGFileIcon,
  PDFFileIcon,
  PNGFileIcon,
  XLSFileIcon,
} from "ui-kit";

type Props = SVGProps<SVGSVGElement> & {
  name: string;
};

export const Icon = memo(({ name, ...svgProps }: Props) => {
  return <>{toIcon({ name, props: svgProps })}</>;
});

function toIcon({
  name,
  props,
}: {
  name: string;
  props: SVGProps<SVGSVGElement>;
}) {
  let fileType = "general";
  const nameLowercase = name.toLowerCase();
  if (nameLowercase.includes(".jpg") || nameLowercase.includes(".jpeg")) {
    fileType = "image/jpeg";
  }
  if (nameLowercase.includes(".png")) {
    fileType = "image/png";
  }
  if (nameLowercase.includes(".xls") || nameLowercase.includes(".xlsx")) {
    fileType = "application/xls";
  }
  if (nameLowercase.includes(".pdf")) {
    fileType = "application/pdf";
  }
  if (nameLowercase.includes(".csv")) {
    fileType = "text/csv";
  }
  if (nameLowercase.includes(".doc") || nameLowercase.includes(".docx")) {
    fileType = "application/msword";
  }
  const mimeTypeToIconMap: {
    [key: string]: ReactNode;
  } = {
    "image/jpeg": <JPGFileIcon {...props} />,
    "image/png": <PNGFileIcon {...props} />,
    "application/xls": <XLSFileIcon {...props} />,
    "application/pdf": <PDFFileIcon {...props} />,
    "text/csv": <CSVFileIcon {...props} />,
    "application/msword": <DOCFileIcon {...props} />,
    general: <FileIcon {...props} />,
  };
  return mimeTypeToIconMap[fileType] || <FileIcon {...props} />;
}
