import axios, { AxiosError } from "axios";
import { createEvent } from "effector";
import { errorNotification } from "ui-kit";

export const apiServer = axios.create();

export const clearSession = createEvent<void>("clearSesion");

export let cancelRequest = () => {};

apiServer.interceptors.request.use(
  function (config) {
    const abortController = new AbortController();
    config.signal = abortController.signal;
    cancelRequest = () => abortController.abort();
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

apiServer.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error: AxiosError<{ code: number; message: string }>) {
    // Handle Parse Server Invalid Session token separately
    if (error.response?.data.code === 209) {
      errorNotification(error.response.data.message);
      clearSession();
    }
    return Promise.reject(error);
  }
);
