import {
  EmergencyInformation,
  Patient,
  PatientDTO,
  PersonalInformationPayload,
  ROLE_PATIENT,
  userToPatientAdapter,
} from "Patient/Model";
import { addCard, removeCard } from "Patient/Profile/paymentCards";
import { clearSession } from "apiConnection";
import { createEffect, createEvent, createStore, forward } from "effector";
import { useStore } from "effector-react";
import { errorNotification, successNotification } from "ui-kit";
import {
  Credentials,
  createAccount as createAccountRequest,
  logIn as logInRequest,
  logOut as logOutRequest,
  signUp as signUpRequest,
  updateEmailRequest,
  updateEmergencyInformation as updateEmergencyInformationRequest,
  updatePasswordRequest,
  updatePersonalInformation as updatePersonalInformationRequest,
} from "./requests";

export const createAccount = createEffect("patientCreateAccount", {
  handler: async () => {
    return await createAccountRequest();
  },
});

export const logIn = createEffect("patientLogIn", {
  handler: async ({ email, password }: Credentials) => {
    const patient = await logInRequest({ email, password });
    return patient;
  },
});

export const logOut = createEffect<void, Promise<boolean>>("patientLogOut", {
  handler: async () => await logOutRequest(),
});

export const signUp = createEffect("signUpPatient", {
  handler: async ({ email, password }: Credentials) =>
    await signUpRequest({ email, password }),
});

export const updateEmail = createEffect("updateEmail", {
  handler: ({ newEmail }: { newEmail: string }) => updateEmailRequest(newEmail),
});

updateEmail.doneData.watch(() => successNotification("Email was updated"));
updateEmail.failData.watch((error) => errorNotification(error.message));

export const updatePassword = createEffect("updatePassword", {
  handler: ({
    oldPassword,
    newPassword,
  }: {
    oldPassword: string;
    newPassword: string;
  }) => updatePasswordRequest({ oldPassword, newPassword }),
});

forward({
  from: updatePassword.doneData,
  to: logOut,
});

updatePassword.doneData.watch(() => {
  successNotification(
    "Password was updated. Please, login again with new password"
  );
});
updatePassword.failData.watch((error) => errorNotification(error.message));

export const updatePersonalInformation = createEffect(
  "updatePersonalInformation",
  {
    handler: async (personalInformation: PersonalInformationPayload) => {
      return await updatePersonalInformationRequest(personalInformation);
    },
  }
);

export const updateEmergencyInformation = createEffect(
  "updateEmergencyInformation",
  {
    handler: async (emergencyInformation?: EmergencyInformation) => {
      return updateEmergencyInformationRequest(emergencyInformation);
    },
  }
);

export const initializePatientFromSession = createEvent<PatientDTO>(
  "initializePatientFromSession"
);

export const $patientSession = createStore<Patient | null>(null)
  .on(initializePatientFromSession, (state, patientDTO) => ({
    ...userToPatientAdapter(patientDTO),
  }))
  .on(createAccount.doneData, (state, patient) => ({ ...patient }))
  .on(signUp.doneData, (state, patient) => ({ ...patient }))
  .on(updatePersonalInformation.doneData, (state, patient) => ({
    ...patient,
  }))
  .on(updateEmergencyInformation.doneData, (state, patient) => ({
    ...patient,
  }))
  .on(updateEmail.doneData, (state, patient) => ({ ...patient }))
  .on(logIn.doneData, (state, patient) => ({ ...patient }))
  .on(addCard.doneData, (patient, addedCard) => {
    if (!patient) {
      return null;
    }
    return {
      ...patient,
      defaultPaymentCardId: addedCard.isDefault
        ? addedCard.id
        : patient.defaultPaymentCardId,
    };
  })
  .on(removeCard.doneData, (patient, { defaultCardId }) => {
    if (!patient) {
      return null;
    }
    return {
      ...patient,
      defaultPaymentCardId: defaultCardId,
    };
  })
  .reset([logOut.done, clearSession]);

export const usePatient = () => {
  const session = useStore($patientSession);
  if (!session || session.role !== ROLE_PATIENT) {
    return undefined;
  }
  return session;
};

export const usePatientAccountWasCreated = () => {
  const patient = usePatient();
  if (!patient) {
    return false;
  }
  return patient.hasAccount;
};
