import { useParams } from "react-router-dom";
import { PatientProfileRouteParams } from "./Model";

export type RouteLocation = {
  from: {
    pathname: string;
  };
  anchor?: string; // use case: navigate to specific section on a page. E.g. booking form of a doctor
} | null;

export function usePatientId() {
  /**
   * At first, look at url parameters
   * If patient id not found, search in session store
   */
  const { patientId } = useParams<PatientProfileRouteParams>();
  // Before update to react-router v6, patient id returned a plaing string.
  // Need to do the manual type casting escape hatch to prevent 'undefined Error' across the callers
  return patientId as string;
}
