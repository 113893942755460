import styled from "@emotion/styled";
import { toDoctorProfileHomePage } from "Doctor/Router";
import { ScheduleForm } from "Doctor/ScheduleForm";
import { useStore } from "effector-react";
import { memo, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { StepWizardChildProps } from "react-step-wizard";
import { useAuthBroadcastSignal } from "Session";
import {
  Block,
  ChevronLeftIcon,
  color,
  errorNotification,
  LoaderWithOverlay,
  Typography,
} from "ui-kit";
import { createAccount, updateSchedule } from "../doctorSession";

type Props = Partial<StepWizardChildProps>;

export const Schedule = memo(({ previousStep }: Props) => {
  const { notifyOthersAboutLogIn } = useAuthBroadcastSignal();

  const navigate = useNavigate();
  const onSave = () =>
    createAccount()
      .then((doctor) => {
        navigate(toDoctorProfileHomePage(doctor.id));
        return doctor;
      })
      .then(() => notifyOthersAboutLogIn())
      .catch((error) => errorNotification(error.message));

  const isUpdatingSchedule = useStore(updateSchedule.pending);
  const isCreatingAccount = useStore(createAccount.pending);

  return (
    <Block position="relative">
      {(isUpdatingSchedule || isCreatingAccount) && <LoaderWithOverlay />}
      <Block marginBottom={4} textAlign="center">
        <Typography as="h2" type="h2">
          Schedule
        </Typography>
      </Block>
      <ScheduleForm
        onSave={onSave}
        resetButton={
          <LinkLabel onClick={previousStep!}>Previous step</LinkLabel>
        }
        submitTitle="Create account"
      />
    </Block>
  );
});

type LinkLabelProps = {
  children: ReactNode;
  onClick: () => void;
};

const LinkLabel = memo(({ children, onClick }: LinkLabelProps) => (
  <LinkLabelComponent type="h4" fontColor="G200" onClick={onClick}>
    <ChevronLeftIcon stroke={color.G200} />
    <LinkContent fontColor="G200" type="h4">
      {children}
    </LinkContent>
  </LinkLabelComponent>
));

const LinkLabelComponent = styled(Typography)`
  cursor: pointer;
  &:hover span,
  &:hover svg {
    color: ${color.G300};
  }
`;

const LinkContent = styled(Typography)`
  padding-left: 8px;
`;
