import { apiServer } from "apiConnection";
import { AxiosError } from "axios";
import { userToDoctorAdapter } from "Doctor/Model";
import { VisitStream } from "MediaStream";
import { userToPatientAdapter } from "Patient/Model";
import { getCurrentTimezoneIANA } from "shared-functions/date";
import { TimeSlot, Visit, VisitDTO, VisitPayment } from "./Model";

export async function fetchVisitById(visitId: string) {
  try {
    const { data: visitDTO } = await apiServer.get<VisitDTO>(
      `/api/visits/${visitId}`
    );
    return visitDTOAdapter(visitDTO);
  } catch (error: any) {
    throw new Error(`${error.code}: ${error.message}`);
  }
}

type SchedulingVisit = {
  patientId?: string;
  doctorId?: string;
  timeSlot?: TimeSlot;
  connectionType: VisitStream;
  payment?: VisitPayment;
  description?: string;
};

export async function bookVisit({
  patientId,
  doctorId,
  timeSlot,
  connectionType,
  payment,
  description,
}: SchedulingVisit): Promise<Visit> {
  try {
    if (!patientId) {
      throw new Error("You have to be logged in as a patient to book a visit");
    }
    if (!doctorId) {
      throw new Error("You have to choose a doctor before booking a visit");
    }
    if (!timeSlot) {
      throw new Error("You have to select date and time for visit");
    }
    const { data: savedVisit } = await apiServer.post<VisitDTO>(
      "/api/visits/book",
      {
        patientId,
        doctorId,
        timeSlot: {
          ...timeSlot,
          timezone: getCurrentTimezoneIANA(),
        },
        connectionType,
        payment,
        description,
      }
    );
    return visitDTOAdapter(savedVisit);
  } catch (error: any) {
    throw new Error(`${error.code}: ${error.message}`);
  }
}

type VisitsRequest = {
  userId: string;
  from?: Date;
  to?: Date;
};

export async function fetchScheduledVisitsForPatient({
  userId,
  from,
  to,
}: VisitsRequest): Promise<Visit[]> {
  const { data: visitsList } = await apiServer.post<VisitDTO[]>(
    "/api/visits/patient",
    {
      userId,
      from,
      to,
    }
  );
  return visitsList.map(visitDTOAdapter);
}

export async function fetchScheduledVisitsAmountForPatient({
  userId,
  from,
  to,
}: VisitsRequest): Promise<number> {
  const { data: amount } = await apiServer.post<number>(
    "/api/visits/patient/amount",
    {
      userId,
      from: from,
      to: to,
    }
  );
  return amount;
}

export async function fetchScheduledVisitsForDoctor({
  userId,
  from,
  to,
}: VisitsRequest): Promise<Visit[]> {
  const { data: visitsList } = await apiServer.post<VisitDTO[]>(
    "/api/visits/doctor",
    {
      userId,
      from: from,
      to: to,
    }
  );
  return visitsList.map(visitDTOAdapter);
}

export async function fetchScheduledVisitsAmountForDoctor({
  userId,
  from,
  to,
}: VisitsRequest): Promise<number> {
  const { data: amount } = await apiServer.post<number>(
    "/api/visits/doctor/amount",
    {
      userId,
      from: from,
      to: to,
    }
  );
  return amount;
}

export function rateVisit({
  visitId,
  rating,
}: {
  visitId: string;
  rating: number;
}) {
  return apiServer.post<void>(`/api/visits/${visitId}/rating`, {
    rating,
  });
}

export async function cancelVisit(id: string) {
  try {
    const { data: canceledVisitId } = await apiServer.post<string>(
      "/api/visits/cancel",
      {
        id,
      }
    );
    return canceledVisitId;
  } catch (error: any) {
    throw new Error(error);
  }
}

/**
 * Payments Operations
 */

export async function makePayment({
  token,
  verificationToken,
  patientId,
  doctorId,
  timeSlot,
}: {
  token?: string;
  verificationToken?: string;
  patientId?: string;
  doctorId?: string;
  timeSlot?: TimeSlot;
}) {
  if (!token) {
    throw new Error(
      "Payment is failed due to unexpected system reason, apologize. Please, try again"
    );
  }
  if (!patientId) {
    throw new Error(
      "Patient is not defined. Please, log in to your account and try one more time"
    );
  }
  if (!doctorId) {
    throw new Error(
      "Doctor is not defined. Probably, you haven't chosen a visit, somehow"
    );
  }
  if (!timeSlot) {
    throw new Error(
      "Timeslot not selecte. Probably, you haven't chosen a visit, somehow"
    );
  }
  try {
    const { data: paymentResponse } = await apiServer.post("/api/visits/pay", {
      token,
      verificationToken,
      patientId,
      doctorId,
      timeSlot,
      locationId: process.env.REACT_APP_SQUARE_LOCATION_ID,
    });
    return paymentResponse;
  } catch (error: unknown) {
    throw new Error(
      (error as AxiosError<{ message: string }>).response?.data.message
    );
  }
}

function visitDTOAdapter(visitDTO: VisitDTO): Visit {
  return {
    id: visitDTO.objectId,
    patient: userToPatientAdapter(visitDTO.patient),
    doctor: userToDoctorAdapter(visitDTO.doctor),
    rating: visitDTO.rating,
    timeSlot: visitDTO.timeSlot,
    connectionType: visitDTO.connectionType,
    payment: visitDTO.payment,
    description: visitDTO.description,
  };
}
