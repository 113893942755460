import { memo } from "react";
import { Helmet } from "react-helmet";
import { supportPage } from "Support/routes";
import { Block, Typography, UnifiedLink } from "ui-kit";
import { GeneralPage } from "ui-kit/Page";
import { ContentSection, TextContent } from "./Content";

export const PrivacyPolicyPage = memo(() => (
  <GeneralPage>
    <Helmet>
      <title>Privacy policy</title>
    </Helmet>
    <Block textAlign="center" marginTop={8} marginBottom={5}>
      <Block marginBottom={2}>
        <Typography as="h1" type="h1" fontColor="G200">
          Privacy statement
        </Typography>
      </Block>
      <Typography as="h2" type="h2" fontColor="G200">
        PocketDoctor, Inc. Privacy Statement
      </Typography>
      <Block textAlign="center" marginTop={0.5}>
        <Typography type="h5" fontWeight="bold">
          Date last modified: December 2, 2021
        </Typography>
      </Block>
    </Block>
    <TextContent>
      <ContentSection>
        <p>
          PocketDoctor, Inc. and its affiliates ("PocketDoctor," "we," "our,"
          and/or "us") value the privacy of individuals who use our website (the
          "Site"), mobile application, and related services, including the
          PocketDoctor Premium and Free Services, (collectively, our
          "Services").
        </p>
        <p>
          This privacy statement ("Privacy Statement") explains how we collect,
          use, and share information from or about individuals who use our
          Services ("Members"). It describes what information other members or
          doctors can see when they use our Services. This Privacy Statement
          also tells you about your rights and choices with respect to Personal
          Information, and how you can contact us if you have any questions or
          concerns. By using our Services, you agree to the collection, use,
          disclosure, and procedures this Privacy Statement describes. In
          addition to this Privacy Statement, your use of our Services is also
          subject to our Terms of Use.
        </p>
        <p>
          For the purpose of this Privacy Statement, "Personal Information"
          means any information that, by itself or in combination with other
          information, identifies or can reasonably be used to identify an
          individual, such as their name, email address, telephone number,
          address, date of birth, or healthcare information. Personal
          Information does not include information that is anonymized.
          "Protected Health Information" has the meaning defined in the Health
          Insurance Portability and Accountability Act ("HIPAA"), and is not
          Personal Information for the purposes of this Privacy Statement, but
          is rather subject to our{" "}
          <a href="#hipaa-privacy-practices">Notice of Privacy Practices</a>{" "}
          described below.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Information We Collect
        </Typography>
        <p>
          We may collect a variety of information from or about you or your
          devices from various sources, as described below.
        </p>
        <p>
          You can browse many areas of the Site and/or our applications without
          providing any Personal Information. However, at certain areas of the
          Site, we may ask that you provide Personal Information. Where
          applicable, we indicate whether and why you must provide us with your
          Personal Information, as well as the consequences of failing to do so.
          If you do not provide your Personal Information when requested, you
          may not be able to use our Services if that information is necessary
          to provide you with our Services or if we are legally required to
          collect it.
        </p>
        <Typography as="h2" type="h2">
          Information You Provide to Us
        </Typography>
        <p>
          <strong>Registration and Profile Information.</strong> We collect the
          information you provide when you create a PocketDoctor account,
          including your name, email address, password, and date of birth. We
          will also receive any optional information you choose to add to your
          PocketDoctor Profile ("Profile"), such as your health goals,
          medications, medical conditions, location, and other information.
          Health conditions and treatment recommendations often depend on your
          age, gender, and where you live. Having robust Profile information
          lets PocketDoctor give you a personalized experience, and it helps
          PocketDoctor doctors give you an appropriate treatment plan.
        </p>
        <p>
          <strong>Payment Information.</strong> When you add a credit card or
          payment method to your Profile or make a purchase through our
          Services, we will collect that payment card information a third party
          service provider that handles payments for us will receive your
          payment card information.
        </p>
        <p>
          <strong>Communications.</strong> If you contact us directly, we may
          receive additional information about you. For example, when you
          contact us for customer support, we will receive your name, email
          address, phone number, the contents of a message or attachments that
          you may send to us, and other information you choose to provide. If
          you subscribe to our newsletter, then we will collect certain
          information from you, such as your email address. When we send you
          emails, we may track whether you open them to learn how to deliver a
          better customer experience and improve our Services.
        </p>
        <p>
          <strong>Carrers.</strong> If you decide that you wish to apply for a
          job with us, you may submit your contact information, cover letter,
          and your resume online. We will collect the information you choose to
          provide on your resume, such as your education and employment
          experience.
        </p>
        <Typography as="h2" type="h2">
          Information We Collect When You Use Our Services
        </Typography>
        <p>
          <strong>Location Information.</strong> When you use our Services,
          including our mobile application, if you allow us, we may receive your
          precise location information. We use your location information to
          connect you with local doctors in order to comply with regulations and
          to personalize and improve your experience by suggesting service
          providers that are located near to you. Service providers including
          but not limited to pharmacies, doctors, and lab test centers. We also
          use your location information to help our doctors develop an
          appropriate treatment plan for you. This location information is also
          used to help troubleshoot network connectivity and user experience
          issues. We also infer the general physical location of your device and
          the geographic regions our Members come from. For example, your
          internet protocol ("IP") address may indicate your general geographic
          region.
        </p>
        <p>
          <strong>Device Information.</strong> We receive information about the
          device and software you use to access our Services, including IP
          address, web browser type, operating system version, phone carrier and
          manufacturer, installed applications, device identifiers, mobile
          advertising identifiers, and push notification tokens.
        </p>
        <p>
          <strong>Usage Information.</strong> To help us understand how you use
          our Services and to help us improve them, when you use our Services,
          we automatically receive information about your interactions with our
          Services, such as the pages or other content you view, any content you
          post, and the dates and times of your visits.
        </p>
        <p>
          <strong>Information from Cookies and Similar Technologies</strong>. A
          cookie is a small piece of data that a website can send to your
          computer's internet browser, which is then stored on your computer's
          operating system. Cookies are how websites recognize users and keep
          track of their preferences. We and third-party partners collect
          information using cookies, pixel tags, or similar technologies. Our
          third-party partners, such as analytics and advertising partners, may
          use these technologies to collect information about your online
          activities over time and across different services. For more
          information about our use of cookies, please see our Cookie Policy.
        </p>
        <p>
          Please review your web browser's "Help" file to learn the proper way
          to modify your cookie settings. Please note that if you delete or
          choose not to accept cookies from the Service, you may not be able to
          utilize the features of the Service to their fullest potential.
        </p>
        <Typography as="h2" type="h2">
          Information We Receive from Third Parties
        </Typography>
        <p>
          <strong>Virtual Consult Summaries.</strong> At the end of every
          virtual visit (meaning a consultation between a doctor and a patient
          through our Services using text chat and/or video), the doctor will
          prepare a note about the virtual visit (the "Consult Summary"), which
          may include health information such as symptoms, diagnosis, and
          treatment. These Consult Summaries will become part of your Profile.
          When you initiate virtual visits using our Services, the doctor that
          you see or chat with will be able to view all past Consult Summaries
          to be able to give you appropriate care.
        </p>
        <p>
          <strong>Social Media Accounts. </strong>We may obtain Personal
          Information about you from third party social media services, such as
          Facebook and Twitter, if you choose to link our Services with third
          party social media accounts ("Social Media Account") by either: (i)
          providing your Social Media Account login information to PocketDoctor
          through the Services; or (ii) allowing PocketDoctor to access your
          Social Media Account, as is permitted under the applicable terms and
          conditions that govern your use of the respective Social Media
          Account.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          HealthKit Data
        </Typography>
        <p>
          If you choose to connect your PocketDoctor and HealthKit accounts,
          PocketDoctor will only have access to information from your HealthKit
          which you select and direct to be shared with PocketDoctor. Once
          you've selected which data to share, the information is sent to
          PocketDoctor from your HealthKit and incorporated into the medical
          record maintained by PocketDoctor on your behalf. The data in your
          medical record is used solely for the purposes of enabling a medical
          provider to provide you with health care services when you engage in a
          telehealth visit. The only party outside of PocketDoctor that may have
          access to the HealthKit data is a medical provider from one of
          PocketDoctor's partners if you request care from them to enable them
          to view your medical record. Your HealthKit data will not be shared
          with any other third parties, (including for any advertising,
          marketing or data mining purpose), except as such disclosure is
          required by applicable law. PocketDoctor may use the data in your
          medical record to communicate with you, to the extent you agree
          through this privacy policy and in preferences within the app, and in
          an aggregated, non-identifiable form for health product development
          and internal analytics.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          How We Use the Information We Collect
        </Typography>
        <p>
          PocketDoctor uses the information we collect for the following
          purposes:
        </p>
        <ul>
          <li>
            To operate, provide, maintain, improve and enhance our Services;
          </li>
          <li>
            To personalize your experience on our Services, such as by providing
            tailored content and recommendations. For example, we use your email
            address to help you create, log into, and manage your account on our
            Services. This lets us personalize your experience and give you
            relevant information. It also powers the features that help you
            better understand, engage with, and track your health and to present
            you with personalized, relevant information;
          </li>
          <li>
            To understand and analyze how you use our Services and to develop
            new products, services, features, and functionality;
          </li>
          <li>
            To build a profile about you and make automated decisions based on
            your information to generate better answers to your health questions
            and effectively triage your symptoms. This information is not used
            for marketing;
          </li>
          <li>To connect you with a doctor that suits your needs;</li>
          <li>
            For marketing and advertising purposes, such as developing and
            providing promotional and advertising materials that may be
            relevant, valuable or otherwise of interest to you. We also may use
            the information that we learn about you to assist us in advertising
            our Services on third party websites. Where required under
            applicable law, we will only send you marketing communications with
            your consent;
          </li>
          <li>
            To communicate with you via email, text messages, push notifications
            and phone calls, in order to provide you with updates and other
            information relating to our Services, provide information that you
            request, respond to comments and questions, and otherwise provide
            customer support;
          </li>
          <li>To facilitate transactions and payments;</li>
          <li>
            To facilitate the connection of Social Media Accounts to our
            Services to provide information from Social Media Accounts to your
            Profile. Depending on the Social Media Accounts you choose and
            subject to the privacy settings that you have set in such Social
            Media Accounts, we will access, make available and store (if
            applicable and as permitted by the social media service and
            authorized by you) the information in your Social Media Accounts so
            that it is available on and through your Profile on the Services;
          </li>
          <li>
            For our business purposes, such as audits, for quality assurance
            purposes, to find and prevent fraud, and respond to trust and safety
            issues that may arise;
          </li>
          <li>
            For compliance purposes, including enforcing our Terms of Use or
            other legal rights, or as may be required by applicable laws and
            regulations or requested by any judicial process or governmental
            agency;
          </li>
          <li>
            For other purposes for which we provide specific notice at the time
            the information is collected;
          </li>
          <li>
            To aggregate or otherwise de-identify information collected through
            the Services and use and disclose it for other business purposes
            after the data can no longer be reasonably linked to an identifiable
            person; and
          </li>
          <li>
            To market and advertise products and services, including through
            inferred interests from interactions with our websites and apps.
          </li>
        </ul>
        <p>
          If you are located in the European Economic Area ("EEA"), we only
          process your Personal Information based on a valid legal ground,
          including when:
        </p>
        <ul>
          <li>
            <strong> Consent.</strong> You have consented to the use of your
            Personal Information, for example for marketing purposes or to track
            your online activities via Cookies and similar technologies.
          </li>
          <li>
            <strong> Contract.</strong> We need your Personal Information to
            provide you with our Services, for example for account registration
            and management or to respond to your inquiries.
          </li>
        </ul>
        <p>
          Legal Obligation. We have a legal obligation to use your Personal
          Information, for example to comply with tax and accounting
          obligations, or abide by local, state and Federal laws.
        </p>
        <ul>
          <li>
            <strong> Legitimate Interest</strong>. We or a third party have a
            legitimate interest in using your Personal Information. In
            particular, we have a legitimate interest in using your Personal
            Information for product development and internal analytics purposes,
            and otherwise to improve the safety, security, and performance of
            our Services. We only rely on our or a third party's legitimate
            interests to process your Personal Information when these interests
            are not overridden by your rights and interests.
          </li>
        </ul>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          How We Share the Personal Information We Collect
        </Typography>
        <p>
          We may share or otherwise disclose Personal Information in the
          circumstances described below.
        </p>
        <p>
          <strong>Affiliates.</strong> We may disclose Personal Information to
          our affiliates or partners to provide the Services or for other
          purposes for which the information was collected.
        </p>
        <p>
          <strong>Vendors and Service Providers. </strong>We may share Personal
          Information we receive with vendors and service providers in
          connection with the provision of the Services
        </p>
        <p>
          Our service providers, such as prescription services, may be
          responsible for providing notices to Members. In the event Personal
          Information is (a) to be used for a purpose that is materially
          different from the purposes for which the Personal Information was
          originally collected or subsequently authorized, or (b) transferred to
          a third party acting as a data controller, Members will be given,
          where practical and appropriate, an opportunity to opt out of having
          non-sensitive Personal Information used or transferred. For sensitive
          information, including health related information, members will opt in
          before such use or transfer.
        </p>
        <p>
          In some instances, PocketDoctor may retain other service providers to
          perform functions on our behalf, including, but not limited to,
          website developers, IT services providers, shipping or direct mail
          organizations, storage facilities, or entities assisting us in a
          recruitment process.
        </p>
        <p>
          <strong>Analytics Partners.</strong> We may make certain Personal
          Information available to third parties for analytics purposes,
          including: (a) for PocketDoctor business or marketing purposes, such
          as to track sales leads; or (b) to leverage third-party tools to
          understand Members' interests, habits, and usage patterns, and/or
          functionality available through our Services. We only share your
          Personal Information with analytics partners to improve our own
          service and/or to deliver healthcare to you. We do not sell your
          Personal Information to advertisers.
        </p>
        <p>
          <strong>As Required by Law and Similar Disclosures. </strong>We may
          access, preserve, and disclose Personal Information if we believe
          doing so is required or appropriate, in our sole discretion, to: (a)
          comply with any applicable law, regulation, legal process or
          governmental request, such as a court order or subpoena, or otherwise
          cooperate with law enforcement or governmental agencies; (b) take
          precautions against liability; (c) protect your, our, or others'
          rights, property, or safety; (d) investigate and defend ourselves
          against any third-party claims or allegations; and (e) protect the
          security or integrity of our Services and any facilities or equipment
          used to make our Services available. For the avoidance of doubt, the
          disclosure of Personal Information may occur if you post any
          objectionable content on or through the Services.
        </p>
        <p>
          <strong>Member Content. </strong>Our Services are social services in
          which you can pose questions and find answers to other Members'
          questions. Your questions will be visible and searchable by other
          users by default and might be read, collected, and used by others.
          Please note that our Terms of Use do not allow you to include Personal
          Information (such as your name, email address, or phone number) in any
          publicly available questions posted to our Services. PocketDoctor
          cannot control how such content is seen or used. We are not
          responsible for the other Members' use of available Personal
          Information, so you should carefully consider whether and what to
          post. Please visit the customer service help center at{" "}
          <UnifiedLink to={supportPage}>support</UnifiedLink> to request removal
          of Personal Information.
        </p>
        <p>
          <strong>Social Media Services. </strong>Our Services may allow you to,
          upon your direction, share Personal Information with certain social
          media services, such as Facebook, Twitter, Pinterest, and Google Plus.
          Please consider any impact on your privacy and anonymity when posting
          content to any and all social media services. You understand and agree
          that the use of Personal Information by any social media services will
          be governed by the respective privacy policies of those social media
          services and your settings on their platforms. We encourage you to
          review their privacy policies.
        </p>
        <p>
          <strong>Marketing.</strong> We do not rent, sell, or share Personal
          Information about you with non affiliated companies for their direct
          marketing purposes, unless we have your permission.
        </p>
        <p>
          <strong>Virtual Doctor Visits.</strong> We may share Personal
          Information with PocketDoctor doctors in order to facilitate your
          treatment and care. Like an in-person patient-doctor interaction,
          PocketDoctor virtual consults are confidential, but not anonymous.
          When using PocketDoctor Premium and Free Services, your Profile
          information, such as your real name and health information, are
          visible to doctors with whom you see or chat with in a virtual visit.
          This Profile information is not visible to other Members or to doctors
          who are not providing care or services in a virtual visit.
        </p>
        <p>
          By initiating a virtual consult, you consent to sharing your name and
          the health information in your Profile with doctors who treat you in
          virtual visits.
        </p>
        <p>
          Mergers, Sales, or Other Asset Transfers. We may disclose and
          otherwise transfer Personal Information to service providers,
          advisors, potential transactional partners, or other third parties in
          connection with the consideration, negotiation, or completion of a
          corporate transaction in which we are acquired by or merged with
          another company or we sell, liquidate, or transfer all or a portion of
          our assets.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          International Transfers of Personal Information
        </Typography>
        <p>
          PocketDoctor may transfer Personal Information for the purposes
          described in this Privacy Statement to a third party acting as a data
          controller or as an agent. If we intend to disclose Personal
          Information to a third party acting as a data controller or as an
          agent we will comply with, and protect, Personal Information as
          provided in the Accountability for Onward Transfer Principle (the
          "Principles"). When providing our Services, we disclose Personal
          Information as provided in our agreement with Members.
        </p>
        <p>
          We remain responsible for the processing of Personal Information
          received under the Privacy Shield and subsequently transferred to a
          third party acting as an agent if the agent processes such Personal
          Information in a manner inconsistent with the Principles, unless we
          prove that we are not responsible for the event giving rise to the
          damage.
        </p>
        <p>
          By providing any information, including Personal Information, on or to
          the Services, you acknowledge and consent that your information may be
          transferred across national borders, including to countries outside
          the EEA, such as the United States.
        </p>
        <p>
          If you are located in the EEA or Switzerland, we comply with
          applicable legal requirements for the transfer of Personal Information
          to countries outside of the EEA or Switzerland. We may transfer
          Personal Information to countries for which adequacy decisions have
          been issued (e.g., Canada), use contractual protections for the
          transfer of Personal Information, or rely on third parties' Privacy
          Shield certifications, where applicable. You may contact us as
          specified below to obtain a copy of the safeguards we use to transfer
          Personal Information outside of the EEA or Switzerland.
        </p>
        <p>
          PocketDoctor complies with the EU-U.S. Privacy Shield Framework and
          the Swiss-U.S. Privacy Shield Frameworks, as set forth by the U.S.
          Department of Commerce regarding the collection, use, and retention of
          Personal Information transferred from the European Union and/or
          Switzerland, to the United States.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Security
        </Typography>
        <p>
          We make commercially reasonable efforts to protect Personal
          Information by using physical and electronic safeguards designed to
          protect the integrity and security of the Personal Information we
          maintain. We also use certain physical, organizational, and technical
          safeguards designed to comply with the Health Insurance Portability
          and Accountability Act ("HIPAA") security standards for interactions
          subject to HIPAA security regulations. PocketDoctor takes commercially
          reasonable precautions, considering the risks involved in the
          processing and the nature of the Personal Information, designed to
          protect Personal Information from loss, misuse and unauthorized
          access, disclosure, alteration and destruction. However, as no
          electronic transmission or storage of Personal Information can be
          entirely secure, we can make no guarantees as to the security or
          privacy of Personal Information.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Information Retention
        </Typography>
        <p>
          We take measures to retain your Personal Information for a period that
          is no longer than necessary to fulfill the purposes outlined in this
          Privacy Statement, unless a longer retention period is required or
          permitted by law. When determining the retention period, we take into
          account various criteria, such as the type of Services provided to
          you, the nature and length of our relationship with you, the impact on
          the Services we provide to you if we delete some Personal Information
          from or about you, and mandatory retention periods provided by law and
          the relevant statute of limitations.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Your Choices and Rights
        </Typography>
        <ul>
          <li>
            <strong> Sharing Preferences.</strong> We provide you with settings
            to allow you to set your sharing preferences for content you post to
            our Services.
          </li>
          <li>
            <strong> Optional Profile Information. </strong>You can add, edit,
            or delete optional Personal Information appearing in your Profile at
            any time in your account settings under edit profile link.
          </li>
          <li>
            <strong> Required Account Information. </strong>Certain Personal
            Information is required for account functionality and can be edited
            but not deleted. For example, you can edit, but not remove, the
            email address and password required for login.
          </li>
          <li>
            <strong>Health Records. </strong>You can amend your health
            information and can add information to your Consult Summaries to
            make your information more accurate or complete. Accordingly, if you
            would like to request access to, or to limit the use or disclosure
            of Personal Information, please contact the doctor to which you
            provided the Personal Information in connection with our Services.
            If you contact us with the name of the doctor to which you provided
            Personal Information, we will refer your request to that doctor and
            support them in responding to your request.
          </li>
          <li>
            <strong> Public Content.</strong> You can request the removal of
            Public Content by visiting the customer service help center at{" "}
            <UnifiedLink to={supportPage}>support</UnifiedLink>.
          </li>
        </ul>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Marketing Communications
        </Typography>
        <p>
          You can unsubscribe from our marketing communications, such as
          announcements of new features or special offers, via the link provided
          in the promotional emails. will PocketDoctor never share your email
          address or other contact information to third parties for their own
          marketing purposes without your explicit permission. Even if you opt
          out of receiving promotional messages from us, you will continue to
          receive administrative messages from us.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Notifications
        </Typography>
        <p>
          We will ask you if you want to receive notifications when you open an
          account with PocketDoctor. If you agree, PocketDoctor may send you
          email, SMS, or mobile push notices, providing you with account-related
          reminders or updates, or letting you know that you have a message on
          our Services. You may opt out at any time by adjusting your
          notification settings in the settings page.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Do Not Track
        </Typography>
        <p>
          There is no accepted standard on how to respond to Do Not Track
          signals, and we do not respond to such signals.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Deactivating Your Account
        </Typography>
        <p>
          To deactivate your account, sign in, go to the settings page, and
          choose the deactivate your account option. You will receive an email
          confirming that your account has been deactivated. Your public
          questions that have received answers will continue to appear
          anonymously on our Services even if you deactivate your account.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          European Privacy Rights
        </Typography>
        <p>
          If you are located in the EEA or Switzerland, you are entitled to
          reach out to us via the contact details in this Privacy Statement and
          ask us for an overview of your Personal Information or ask for a copy
          of such Personal Information. In addition, you may request us to
          update and correct inaccuracies, delete your Personal Information,
          restrict our processing of your Personal Information, or exercise your
          right to data portability, and to transfer your Personal Information
          to another company. In some cases, you may object to the processing of
          your Personal Information and, where we have asked you for your
          consent to process your Personal Information, you can withdraw it at
          any time. We will apply your preferences going forward and this will
          not affect the lawfulness of the processing before your consent was
          withdrawn. We always enjoy hearing from you and appreciate your
          business. Should you nonetheless have unresolved concerns, you have
          the right to lodge a complaint with the supervisory authority of your
          residence, place of work or where the incident took place.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Children
        </Typography>
        <p>
          Our Services are not intended for or directed to children under 16
          years of age, and we do not knowingly collect Personal Information
          from children under the age of 13. If you learn that your child has
          provided us with Personal Information without your consent, then you
          may alert us at{" "}
          <a href="mailto:info@pocketdoctor.us">info@pocketdoctor.us</a>. If we
          learn that we have collected any Personal Information from children
          under 13, then we will promptly take steps to delete such information
          and terminate the child's account.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Contact PocketDoctor
        </Typography>
        <p>
          PocketDoctor is responsible, or the "data controller", for the
          processing of your Personal Information processed in connection with
          the Services. If you have any questions, comments, or concerns about
          our processing activities, please email us at{" "}
          <a href="mailto:info@pocketdoctor.us">info@pocketdoctor.us</a>, or via
          traditional mail to 16192 Coastal Highway, Lewes, Delaware 19958,
          County of Sussex.
        </p>
        <p>
          You can also use our Contact Us page to make requests regarding
          managing and processing your information.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Changes To This Privacy Statement
        </Typography>
        <p>
          We reserve the right to change this Privacy Statement at any time. We
          will post any adjustments to the Privacy Statement on this page, and
          the revised version will be effective when it is posted. If we
          materially change the ways in which we use or share Personal
          Information previously collected from you through the Services, we
          will notify you through the Services, by email, or other
          communication.
        </p>
      </ContentSection>
      <ContentSection>
        <Block
          id="hipaa-privacy-practices"
          marginBottom={5}
          marginTop={8}
          textAlign="center"
        >
          <Typography as="h1" fontColor="G200" type="h1">
            PocketDoctor HIPAA Notice of Privacy Practices
          </Typography>
          <p>
            <Typography type="h5" fontWeight="bold">
              Date last modified: December 2, 2021
            </Typography>
          </p>
        </Block>
        <p>
          THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED
          AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE
          REVIEW IT CAREFULLY.
        </p>
        <Typography as="h2" type="h2">
          Overview of Our Responsibilities
        </Typography>
        <p>
          If you use the PocketDoctor Premium and Free Services to access
          virtual, on-demand care provided by doctors, the data generated during
          this visit may be &ldquo;protected health information&rdquo; or
          &ldquo;PHI&rdquo; as defined by the Health Insurance Portability and
          Accountability Act, commonly referred to as &ldquo;HIPAA&rdquo;. PHI
          is information that is created or maintained by certain entities,
          including health care providers, that relates to (a) your past,
          present or future physical or mental health or condition, (b) the
          provision of health care to you, or (c) the past, present, or future
          payment for the provision of healthcare to you, and that identifies
          you, or reasonably could be used to identify you.
        </p>
        <p>
          The health care providers who are part of PocketDoctor
          (&ldquo;PocketDoctor Providers&rdquo;) are required by law to maintain
          the privacy and security of your PHI and provide you with this Notice
          of Privacy Practices (the &ldquo;Notice&rdquo;), which describe its
          duties and your rights with respect to your PHI. We will not use or
          share your PHI other than as described here unless you tell us we can
          in writing. Let us know if you change your mind. We will let you know
          promptly if a breach occurs that may have compromised the privacy or
          security of your PHI.
        </p>
        <p>
          The PocketDoctor Providers will abide by this Notice while it is in
          effect and reserve the right to change the terms of the Notice at any
          time. The changes will apply to any PHI maintained by PocketDoctor
          Providers, including PHI created or received by PocketDoctor Providers
          when the prior Notice was in effect. The new Notice will be posted on
          PocketDoctor website, available on the PocketDoctor App, and a copy
          will be made available to you upon request.
        </p>
        <p>
          For more information see:{" "}
          <a href="https://www.hhs.gov/hipaa/for-individuals/notice-privacy-practices/index.html">
            https://www.hhs.gov/hipaa/for-individuals/notice-privacy-practices/index.html
          </a>
        </p>
        <Typography as="h2" type="h2">
          How May PocketDoctor Providers Use and Disclose My Protected Health
          Information?
        </Typography>
        <ol>
          <li>
            <strong> Treatment, Payment and Operations.</strong> PocketDoctor
            Providers may use and disclosure your PHI for treatment, payment and
            operations purposes as permitted by HIPAA. The following are
            examples of permitted treatment, payment and operations purposes,
            but not a complete list of all permitted purposes:
          </li>
        </ol>
        <ul>
          <li>
            <strong> Treatment:</strong> PocketDoctor Providers may disclose
            your PHI to another health care provider for the purpose of
            obtaining prior medical records, consulting regarding your health
            care, treating you during a visit, or attending to your follow-up
            care.
          </li>
          <li>
            <strong> Payment:</strong> PocketDoctor Providers may use and
            disclose your PHI to your health insurer or health plan in order to
            process payment of claims or other charges related to providing
            health care to you through the Services.
          </li>
          <li>
            <strong> Health care Operations:</strong> PocketDoctor Providers may
            use and disclosure your PHI in order to carry out certain health
            care operations, including but not limited to quality review
            assessments and improvement activities, developing clinical
            guidelines, case management and care coordination, and evaluating
            practitioner and provider performance.
          </li>
        </ul>
        <p>
          Note that PocketDoctor may carry out these health care operational
          activities on its own, or in certain circumstances, may retain a third
          party to help them carry out certain functions if those third parties
          (called &ldquo;business associates&rdquo;) also agree to be bound by
          HIPAA through written agreement.
        </p>
        <ol start={2}>
          <li>
            <strong>Pursuant to an Authorization.</strong> PocketDoctor
            Providers may use and disclosure your PHI if you provide written
            authorization that complies with the requirements under HIPAA, but
            only to the extent permitted by such authorization. You can revoke
            your authorization at any time in writing.
          </li>
          <li>
            <strong>As required by law.</strong> PocketDoctor Providers may use
            and disclosure your PHI to the extent required to comply with
            federal or state law.
          </li>
        </ol>
        <Typography as="h2" type="h2">
          Are There Other Circumstances in Addition to the Above in Which
          PocketDoctor Providers May Use and Disclose My PHI?
        </Typography>
        <p>
          Yes. PocketDoctor Providers are allowed or required to share your
          information in other ways &ndash; usually in ways that contribute to
          the public good, such as public health and research. We have to meet
          many conditions in the law before we can share your information for
          these purposes. For more information see:{" "}
          <a href="https://www.hhs.gov/hipaa/for-individuals/notice-privacy-practices/index.html">
            https://www.hhs.gov/hipaa/for-individuals/notice-privacy-practices/index.html
          </a>
        </p>
        <p>
          <strong>Help with public health and safety issues:</strong>{" "}
          PocketDoctor Providers can share information about you for certain
          situations such as:
        </p>
        <ul>
          <li>Preventing disease</li>
          <li>Helping with product recalls</li>
          <li>Reporting adverse reactions to medications</li>
          <li>Reporting suspected abuse, neglect or domestic violence</li>
          <li>
            Preventing or reducing a serious threat to anyone&rsquo;s health or
            safety
          </li>
          <li>
            <strong> Do Research:</strong> We can use or share your information
            in certain ways for health research.
          </li>
          <li>
            <strong> Respond to organ and tissue donation requests:</strong> We
            can share health information about you with organ procurement
            organizations.
          </li>
        </ul>
        <p>
          Note that unlike some other health care providers, PocketDoctor
          Providers do not currently create the following types of PHI: (a)
          create or manage a hospital directory, or (b) create or maintain
          psychotherapy notes.
        </p>
        <p>
          Also, if certain state laws are more restrictive than HIPAA as to what
          health information can be shared without first obtaining your consent,
          we will always follow those laws. For example, some states would not
          allow us to disclose substance abuse treatment records or HIV status
          without your written permission, even for a purpose permitted under
          HIPAA. In these cases, we will follow the more restrictive rule that
          applies to the PocketDoctor Providers and your health information in
          that situation.
        </p>
        <p>
          Finally, PocketDoctor Providers will never use or share your
          information without first obtaining your written permission for a
          marketing purposes (unless permitted by HIPAA) or to sell your
          information.
        </p>
        <Typography as="h2" type="h2">
          What Are My Rights When It Comes to My PHI?
        </Typography>
        <p>
          <strong>When it comes to your PHI, you have certain rights. </strong>
          This section explains your rights and some of our responsibilities to
          help you access those rights.
        </p>
        <p>
          <strong>
            Get an electronic or paper copy of your medical record or direct us
            to share it with others
          </strong>
        </p>
        <ul>
          <li>
            You can ask to see or get an electronic or paper copy of your
            medical record and other health information we have about you. Ask
            us how to do this.
          </li>
          <li>
            We will provide a copy or a summary of your health information,
            usually within 30 days of your request. We may charge a reasonable,
            cost-based fee.
          </li>
          <li>
            You can also direct us to share your medical record or portions of
            it with your family, close friends or others involved in your care.
          </li>
        </ul>
        <p>
          <strong>Ask us to correct your medical record</strong>
        </p>
        <ul>
          <li>
            You can ask us to correct health information about you that you
            think is incorrect or incomplete. Ask us how to do this.
          </li>
          <li>
            We may say &ldquo;no&rdquo; to your request, but we&rsquo;ll tell
            you why in writing within 60 days.
          </li>
        </ul>
        <p>
          <strong>Request confidential communications</strong>
        </p>
        <ul>
          <li>
            You can ask us to contact you in a specific way (for example, home
            or office phone) or to send mail to a different address.
          </li>
          <li>We will say &ldquo;yes&rdquo; to all reasonable requests.</li>
        </ul>
        <p>
          <strong>Ask us to limit what we use or share</strong>
        </p>
        <ul>
          <li>
            You can ask us not to use or share certain health information for
            treatment, payment, or our operations.
          </li>
          <li>
            We are not required to agree to your request, and we may say
            &ldquo;no&rdquo; if it would affect your care.
          </li>
          <li>
            If you pay for a service or health care item out of-pocket in full,
            you can ask us not to share that information for the purpose of
            payment or our operations with your health insurer.
          </li>
          <li>
            We are not required to agree to your request, and we may say
            &ldquo;no&rdquo; if it would affect your care.
          </li>
        </ul>
        <p>
          <strong>
            Get a list of those with whom we&rsquo;ve shared information
          </strong>
        </p>
        <ul>
          <li>
            You can ask for a list (accounting) of the times we&rsquo;ve shared
            your health information for six years prior to the date you ask, who
            we shared it with, and why.
          </li>
          <li>
            You can ask for a list (accounting) of the times we&rsquo;ve shared
            your health information for six years prior to the date you ask, who
            we shared it with, and why. We will include all the disclosures
            except for those about treatment, payment, and health care
            operations, and certain other disclosures (such as any you asked us
            to make). We&rsquo;ll provide one accounting a year for free but
            will charge a reasonable, cost-based fee if you ask for another one
            within 12 months.
          </li>
        </ul>
        <p>
          <strong>Get a copy of this Notice</strong>
        </p>
        <ul>
          <li>
            You can ask for a paper copy of this notice at any time, even if you
            have agreed to receive the notice electronically. We will provide
            you with a paper copy promptly.
          </li>
        </ul>
        <p>
          <strong>Choose someone to act for your</strong>
        </p>
        <ul>
          <li>
            If you have given someone medical power of attorney or if someone is
            your legal guardian, that person can exercise your rights and make
            choices about your health information.
          </li>
        </ul>
        <p>
          <strong>
            File a complaint if you feel your rights have been violated
          </strong>
        </p>
        <ul>
          <li>
            You can complain if you feel we have violated your rights by
            contacting us using the information provided at the end of this
            Notice.
          </li>
          <li>
            You can file a complaint with the U.S. Department of Health and
            Human Services Office for Civil Rights by sending a letter to 200
            Independence Avenue, S.W., Washington, D.C. 20201, calling
            1-877-696- 6775, or visiting{" "}
            <a href="https://www.hhs.gov/hipaa/filing-a-complaint/what-to-expect/index.html">
              https://www.hhs.gov/hipaa/filing-a-complaint/what-to-expect/index.html
            </a>
          </li>
          <li>We will not retaliate against you for filing a complaint.</li>
        </ul>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Who Do I Contact at PocketDoctor For a Reason Related to This Notice?
        </Typography>
        <p>
          You can contact PocketDoctor in writing at the following address for a
          reason related to this Notice, such as:
        </p>
        <ul>
          <li>To get a copy of this Notice</li>
          <li>As for a copy of your medical record</li>
          <li>Ask for a correction to be made to your medical record</li>
          <li>File a complaint</li>
          <li>Appoint a representative</li>
          <li>Exercise any of your other rights listed above</li>
        </ul>
        <p>Please write to the PocketDoctor contact for any of these issues:</p>
        <ul>
          <li>
            <a href="mailto:info@pocketdoctor.us">info@pocketdoctor.us</a>
          </li>
          <li>PocketDoctor, Inc.</li>
        </ul>
        <p>16192 Coastal Highway, Lewes, Delaware 19958, County of Sussex.</p>
      </ContentSection>
    </TextContent>
  </GeneralPage>
));
