import styled from "@emotion/styled";
import { ComponentType, ReactNode } from "react";
import { color } from "./theme";

export type Props = {
  children: ReactNode;
  as?: ComponentType | string;
  type?: Type;
  fontFamily?: FontFamily;
  fontColor?: keyof typeof color;
  fontSize?: string; // Using this prop as a workaround until standardized sizes are defined.
  fontWeight?: FontWeight;
  fontStyle?: FontStyle;
  lineHeight?: string;
  textTransform?: TextTransform;
  wordBreak?: WordBreak;
};

export const Typography = styled("span", {
  shouldForwardProp: (prop) =>
    ["children", "data-target", "onClick", "target", "href"].includes(prop),
})<Props>`
  ${({
    type = "h4",
    fontFamily = "inherit",
    fontColor = "N400",
    fontSize,
    fontWeight,
    fontStyle = "normal",
    lineHeight,
    textTransform = "none",
    wordBreak = "break-word",
  }: Props) => ({
    color: fontColor ? color[fontColor] : fonts[type].color,
    fontFamily: fontFamily === "inherit" ? "inherit" : fontFamilies[fontFamily],
    fontSize: fontSize ? fontSize : fonts[type].fontSize,
    lineHeight: lineHeight ? lineHeight : fonts[type].lineHeight,
    fontWeight: fontWeight ? fontWeight : fonts[type].fontWeight,
    fontStyle,
    margin: 0,
    textTransform,
    wordBreak,
  })}
`;

type FontConfig = {
  [key: string]: {
    color: keyof typeof color;
    fontSize: string;
    fontWeight: number | "inherit";
    lineHeight: string;
  };
};

const fonts: FontConfig = {
  h1: {
    color: "N400",
    fontSize: "36px",
    fontWeight: 700,
    lineHeight: "48px",
  },
  h2: {
    color: "N400",
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 700,
  },
  h3: {
    color: "N400",
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: 500,
  },
  h4: {
    color: "N400",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
  },
  h5: {
    color: "N400",
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: 400,
  },
  h6: {
    color: "N400",
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 400,
  },
  inherit: {
    color: "inherit",
    fontSize: "inherit",
    lineHeight: "inherit",
    fontWeight: "inherit",
  },
};

export type Type = keyof typeof fonts;

export const fontFamilies = {
  Roboto: [
    "Roboto",
    "BlinkMacSystemFont",
    "Segoe UI",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    "sans-serif",
  ].join(","),
};

type FontFamily = keyof typeof fontFamilies | "inherit";

type FontWeight =
  | "bold"
  | "bolder"
  | "lighter"
  | "normal"
  | 100
  | 200
  | 300
  | 400
  | 500
  | 600
  | 700
  | 800
  | 900;

type FontStyle = "normal" | "italic" | "oblique" | "initial" | "inherit";

type TextTransform = "uppercase" | "lowercase" | "capitalize" | "none";

type WordBreak = "break-all" | "break-word" | "keep-all" | "normal";
