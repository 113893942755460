import { isBefore, parseISO } from "date-fns";
import { $doctorSession } from "Doctor/Auth/doctorSession";
import { Model as Doctor } from "Doctor/Model";
import { attach, createEffect, createStore } from "effector";
import {
  fetchScheduledVisitsAmountForDoctor,
  fetchScheduledVisitsForDoctor,
  Visit,
} from "Visit";

type VisitRequest = {
  doctorId: string;
  from?: Date;
  to?: Date;
};

const loadVisitsFx = createEffect({
  handler: async ({ doctorId, from, to }: VisitRequest) => {
    if (!doctorId) {
      throw new Error(
        "Client app: must provide doctor id to fetch upcoming visits for doctor"
      );
    }
    return await fetchScheduledVisitsForDoctor({ userId: doctorId, from, to });
  },
});

const loadScheduledVisitsAmountFx = createEffect({
  handler: async ({ doctorId, from, to }: VisitRequest) => {
    return await fetchScheduledVisitsAmountForDoctor({
      userId: doctorId,
      from,
      to,
    });
  },
});

type DateRange = {
  from?: Date;
  to?: Date;
};

export const loadVisits = attach({
  effect: loadVisitsFx,
  source: $doctorSession,
  mapParams: ({ from, to }: DateRange, doctorSession: Doctor | null) =>
    ({
      doctorId: doctorSession ? doctorSession.id : undefined,
      from,
      to,
    } as VisitRequest),
});

export const loadVisitsAmount = attach({
  effect: loadScheduledVisitsAmountFx,
  source: $doctorSession,
  mapParams: ({ from, to }: DateRange, doctorSession: Doctor | null) =>
    ({
      doctorId: doctorSession ? doctorSession.id : undefined,
      from,
      to,
    } as VisitRequest),
});

type VisitsListStore = {
  amount?: number;
  visits: Visit[];
};

export const $visitsList = createStore<VisitsListStore>({
  visits: [],
})
  .on(loadVisitsFx.done, (_, { result: visits }) => ({
    amount: visits.length,
    visits,
  }))
  .on(loadVisitsAmount.done, (state, { result: amount }) => ({
    ...state,
    amount,
  }));

/**
 * History of visits
 */
export const loadPastVisits = createEffect({
  handler: async ({ doctorId, to }: VisitRequest) => {
    return await fetchScheduledVisitsForDoctor({
      userId: doctorId,
      to,
    });
  },
});

export const $visitsHistory = createStore<Visit[]>([]).on(
  loadPastVisits.done,
  (state, { result: pastVisits, params: { to } }) =>
    pastVisits.filter((visit) =>
      to ? isBefore(parseISO(visit.timeSlot.to), to) : true
    )
);
