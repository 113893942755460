import styled from "@emotion/styled";
import { memo, ReactNode, SyntheticEvent, useCallback, useState } from "react";
import { Flex } from "./Layout";
import { color, hexToRGBA } from "./theme";

const buttonsSizes = {
  large: {
    fontSize: "18px",
    height: "40px",
    lineHeight: "22px",
    padding: "8px 24px",
  },
  small: {
    fontSize: "18px",
    height: "32px",
    lineHeight: "22px",
    padding: "4px 12px",
  },
};

type Size = keyof typeof buttonsSizes;

type ButtonProps = {
  disabled?: boolean;
  filled?: boolean;
  size?: Size;
};

export const Button = styled.button<ButtonProps>`
  ${({ disabled, filled, size = "large" }: ButtonProps) => `
    background-color: ${getColor({ filled, disabled, prop: "background" })};
    border: 1px solid ${getColor({ filled, disabled, prop: "border" })};
    border-radius: 4px;
    color: ${getColor({ filled, disabled, prop: "font" })};
    cursor: ${disabled ? "not-allowed" : "pointer"};
    font-size: ${buttonsSizes[size].fontSize};
    font-weight: 500;
    height: ${buttonsSizes[size].height};
    line-height: ${buttonsSizes[size].lineHeight};
    outline: none;
    overflow: hidden;
    padding: ${buttonsSizes[size].padding};
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      background-color: ${getColor({
        filled,
        disabled,
        prop: "backgroundHover",
      })};
      border-color: ${getColor({ filled, disabled, prop: "borderHover" })};
      color: ${getColor({ filled, disabled, prop: "fontHover" })};
    }
    &:focus {
      border-color: ${color.G300};
    }
  `}
`;

type ToggleButtonProps = {
  size?: Size;
  className?: string;
  clickCallback: (e: SyntheticEvent<HTMLButtonElement>) => void;
  children: ReactNode;
};
export const ToggleButton = memo(
  ({ size, className, clickCallback, children }: ToggleButtonProps) => {
    const [selected, setSelected] = useState<boolean>(false);
    const onClick = useCallback(
      (e: SyntheticEvent<HTMLButtonElement>) => {
        setSelected(!selected);
        clickCallback(e);
      },
      [selected, setSelected, clickCallback]
    );
    return (
      <Button
        className={className}
        filled={selected}
        size={size}
        onClick={onClick}
      >
        {children}
      </Button>
    );
  }
);

type ButtonsGroupPros = {
  size?: Size;
  config: ButtonInGroupConfig[];
  className?: string;
};

type ButtonInGroupConfig = {
  isSelected: boolean;
  isDisabled?: boolean;
  tooltip?: string;
  leftSideIcon?: ReactNode;
  rightSideIcon?: ReactNode;
  title: string;
  clickCallback: (e: SyntheticEvent<HTMLButtonElement>) => void;
};

export const ButtonsGroup = memo(
  ({ config, size, className }: ButtonsGroupPros) => {
    return (
      <Flex justifyContent="center">
        {config.map((button, index) => {
          if (index === 0) {
            return (
              <FirstButtonInGroup
                key={button.title}
                selected={button.isSelected}
                disabled={button.isDisabled}
                size={size}
                title={button.tooltip}
                type="button"
                className={className}
                onClick={button.clickCallback}
              >
                {button.leftSideIcon && (
                  <ButtonLeftIconContainer
                    className="icon"
                    selected={button.isSelected}
                  >
                    {button.leftSideIcon}
                  </ButtonLeftIconContainer>
                )}
                <span>{button.title}</span>
                {button.rightSideIcon && (
                  <ButtonRightIconContainer
                    className="icon"
                    selected={button.isSelected}
                  >
                    {button.rightSideIcon}
                  </ButtonRightIconContainer>
                )}
              </FirstButtonInGroup>
            );
          }
          if (index === config.length - 1) {
            return (
              <LastButtonInGroup
                key={button.title}
                selected={button.isSelected}
                disabled={button.isDisabled}
                size={size}
                title={button.tooltip}
                type="button"
                className={className}
                onClick={button.clickCallback}
              >
                {button.leftSideIcon && (
                  <ButtonLeftIconContainer
                    className="icon"
                    selected={button.isSelected}
                  >
                    {button.leftSideIcon}
                  </ButtonLeftIconContainer>
                )}
                <span>{button.title}</span>
                {button.rightSideIcon && (
                  <ButtonRightIconContainer
                    className="icon"
                    selected={button.isSelected}
                  >
                    {button.rightSideIcon}
                  </ButtonRightIconContainer>
                )}
              </LastButtonInGroup>
            );
          }
          return (
            <ButtonInGroup
              key={button.title}
              selected={button.isSelected}
              disabled={button.isDisabled}
              size={size}
              title={button.tooltip}
              type="button"
              className={className}
              onClick={button.clickCallback}
            >
              {button.leftSideIcon && (
                <ButtonLeftIconContainer
                  className="icon"
                  selected={button.isSelected}
                >
                  {button.leftSideIcon}
                </ButtonLeftIconContainer>
              )}
              <span>{button.title}</span>
              {button.rightSideIcon && (
                <ButtonRightIconContainer
                  className="icon"
                  selected={button.isSelected}
                >
                  {button.rightSideIcon}
                </ButtonRightIconContainer>
              )}
            </ButtonInGroup>
          );
        })}
      </Flex>
    );
  }
);

type ToggleButtonComponentProps = {
  selected: boolean;
  disabled?: boolean;
};

const ButtonSingleSwitch = styled(Button)<ToggleButtonComponentProps>`
  ${({ selected, disabled }) => `
    align-items: flex-start;
    background-color: ${
      selected
        ? color.N0
        : getColor({ switcher: true, disabled, prop: "background" })
    };
    border-color: ${
      selected
        ? color.G200
        : getColor({ switcher: true, disabled, prop: "border" })
    };
    color: ${
      selected
        ? color.G200
        : getColor({ switcher: true, disabled, prop: "font" })
    };
    display: flex;
    height: auto;
    max-height: 54px;
    padding: 15px 40px;
    &:hover {
      background-color: ${getColor({
        switcher: true,
        disabled,
        prop: "backgroundHover",
      })};
      border-color: ${getColor({
        switcher: true,
        disabled,
        prop: "borderHover",
      })};
      color: ${getColor({ switcher: true, disabled, prop: "fontHover" })};
    }
    &:hover .icon svg {
      fill: ${disabled ? color.N200 : color.G100};
    }
  `}
`;

const FirstButtonInGroup = styled(ButtonSingleSwitch)`
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
`;
const LastButtonInGroup = styled(ButtonSingleSwitch)`
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
`;
const ButtonInGroup = styled(ButtonSingleSwitch)`
  border-radius: 0;
`;

const ButtonRightIconContainer = styled.span<ToggleButtonComponentProps>`
  padding-left: 8px;
  & svg {
    fill: ${({ selected }) => (selected ? color.G100 : color.N200)};
  }
`;

const ButtonLeftIconContainer = styled.span<ToggleButtonComponentProps>`
  padding-right: 8px;
  & svg {
    fill: ${({ selected }) => (selected ? color.G100 : color.N200)};
  }
`;

type ButtonStates = {
  prop: keyof typeof colorsMap;
  disabled?: boolean;
  filled?: boolean;
  switcher?: boolean;
};

function getColor({ prop, disabled, filled, switcher }: ButtonStates) {
  const colorsForProperty = colorsMap[prop];
  let colorsForType = colorsForProperty.empty;
  if (filled) {
    colorsForType = colorsForProperty.filled;
  }
  if (switcher) {
    colorsForType = colorsForProperty.switcher;
  }
  const colorForState = disabled
    ? colorsForType.disabled
    : colorsForType.enabled;
  return colorForState;
}

const colorsMap = {
  background: {
    empty: {
      disabled: "transparent",
      enabled: "transparent",
    },
    filled: {
      disabled: color.N200,
      enabled: color.G200,
    },
    switcher: {
      disabled: "transparent",
      enabled: "transparent",
    },
  },
  backgroundHover: {
    empty: {
      disabled: "transparent",
      enabled: hexToRGBA(color.G100, 0.06),
    },
    filled: {
      disabled: color.N200,
      enabled: color.G300,
    },
    switcher: {
      disabled: "transparent",
      enabled: hexToRGBA(color.G100, 0.06),
    },
  },
  border: {
    empty: {
      disabled: color.N300,
      enabled: color.G200,
    },
    filled: {
      disabled: color.N200,
      enabled: color.G200,
    },
    switcher: {
      disabled: color.N200,
      enabled: "#d0d1d9",
    },
  },
  borderHover: {
    empty: {
      disabled: color.N300,
      enabled: color.G300,
    },
    filled: {
      disabled: color.N200,
      enabled: color.G300,
    },
    switcher: {
      disabled: color.N200,
      enabled: color.G300,
    },
  },
  font: {
    empty: {
      disabled: color.N300,
      enabled: color.G200,
    },
    filled: {
      disabled: color.N300,
      enabled: color.N0,
    },
    switcher: {
      disabled: color.N200,
      enabled: color.N300,
    },
  },
  fontHover: {
    empty: {
      disabled: color.N300,
      enabled: color.G300,
    },
    filled: {
      disabled: color.N300,
      enabled: color.N0,
    },
    switcher: {
      disabled: color.N200,
      enabled: color.G300,
    },
  },
};
