import { useCallback, useState } from "react";

export function readAsBase64(file: File | null): Promise<string | undefined> {
  return new Promise((resolve, reject) => {
    if (!file) {
      resolve(undefined);
    }
    const reader = new FileReader();
    reader.readAsDataURL(file!);
    reader.onloadend = function () {
      resolve(reader.result as string);
    };
    reader.onerror = function () {
      reject(reader.error);
    };
  });
}

export function getFileBinaryData(file: File): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = function () {
      resolve(reader.result as ArrayBuffer);
    };
    reader.onerror = function () {
      reject(reader.error);
    };
  });
}

export async function base64ToFile({
  name,
  fileContent,
}: {
  name: string;
  fileContent: string;
}): Promise<File> {
  const response = await fetch(fileContent);
  const arrayBuffer = await response.blob();
  return new File([arrayBuffer], name);
}

export function useImageData(defaultUrl?: string) {
  const [preview, changePreview] = useState(defaultUrl);
  const [base64Data, setBase64Data] = useState<string>();
  const onChange = useCallback((file: File | null) => {
    if (file) {
      const src = URL.createObjectURL(file);
      changePreview(src);
    }
    readAsBase64(file).then(setBase64Data);
  }, []);
  return { preview, base64Data, onChange };
}

export function checkSizeBelowLimit({
  file,
  limitMB,
}: {
  file?: File | null;
  limitMB: number;
}) {
  return file ? file.size / 1000000 <= limitMB : true;
}
