import styled from "@emotion/styled";
import { memo } from "react";
import { Block, Flex } from "../Layout";
import { color } from "../theme";
import { Typography } from "../Typography";

type Props = {
  description?: string;
  size?: "m" | "l";
};

export const Loader = memo(({ description, size = "m" }: Props) => (
  <Flex alignItems="center" flexDirection="column" textAlign="center">
    <LoaderIconContainer hasDescription={!!description} size={size}>
      <span className="dot left" />
      <span className="dot center" />
      <span className="dot right" />
    </LoaderIconContainer>
    {description && (
      <Block>
        <Typography fontColor="N300">{description}</Typography>
      </Block>
    )}
  </Flex>
));

export const LoaderWithOverlay = memo(({ description }: Props) => (
  <Overlay
    alignItems="center"
    justifyContent="center"
    left="0"
    height="100%"
    position="absolute"
    top="0"
    width="100%"
  >
    <Loader description={description} />
  </Overlay>
));

const Overlay = styled(Flex)`
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 3;
`;

type LoaderState = {
  hasDescription: boolean;
  size: "m" | "l";
};

const LoaderIconContainer = styled(Flex)<LoaderState>`
  ${({ size, hasDescription }) => `
    align-items: center;
    color: ${color.N300};
    margin-bottom: ${hasDescription ? "8px" : "0"};
    padding: 8px;
    transition: color 150ms;
    & .dot {
      background-color: ${color.N300};
      border-radius: 50%;
      height: ${size === "l" ? "16px" : "14px"} ;
      width: ${size === "l" ? "16px" : "14px"} ;
    }

    & .dot.left {
      animation: dotFlashing 1s ease-in-out 0ms infinite;
    }

    & .dot.center {
      animation: dotFlashing 1s ease-in-out 160ms infinite;
    }

    & .dot.right {
      animation: dotFlashing 1s ease-in-out 320ms infinite;
    }

    & .dot.center {
      margin-left: ${size === "l" ? "10px" : "8px"};
      margin-right: ${size === "l" ? "10px" : "8px"};
    }

    @keyframes dotFlashing {
      0%,
      80%,
      100% {
        opacity: 0;
      }
      40% {
        opacity: 1;
      }
    }
  `};
`;
