import { addMinutes, isBefore } from "date-fns";

type Interval = {
  from: Date;
  to: Date;
  interval: number;
};

export function splitRangeByInterval({ from, to, interval }: Interval): Date[] {
  const range: Date[] = [from];
  while (isBefore(range[range.length - 1], to)) {
    range.push(addMinutes(range[range.length - 1], interval));
  }
  return range;
}
