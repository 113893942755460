import styled from "@emotion/styled";
import { Block, Typography } from "ui-kit";

export const TextContent = styled(Typography.withComponent(Block))`
  width: 1280px;
  margin: 0 auto 64px;
  & p {
    margin: 0 0 12px;
  }
  & ul,
  ol {
    padding: revert;
    margin-bottom: 16px;
  }
  & li {
    margin-bottom: 4px;
  }
  & h2 {
    margin: 24px 0 16px;
  }
  & h3 {
    margin: 24px 0 8px;
  }
`;

export const ContentSection = styled(Block)`
  margin-bottom: 24px;
`.withComponent("section");
