import { Conclusion, ConclusionDTO } from "./typeDefinitions";

export function conclusionRecordAdapter(
  conclustionDTO: ConclusionDTO
): Conclusion {
  return {
    id: conclustionDTO.objectId,
    author: {
      id: conclustionDTO.author.id,
      name: conclustionDTO.author.name,
    },
    diseaseName: conclustionDTO.diseaseName,
    prescription: conclustionDTO.prescription,
    rehabilitation: conclustionDTO.rehabilitation,
    medicalExaminations: conclustionDTO.medicalExaminations,
    createdAt: conclustionDTO.createdAt,
    updatedAt: conclustionDTO.updatedAt,
  };
}
