import styled, { CSSObject } from "@emotion/styled";
import { LicenseDocument } from "Doctor/Model";
import { DocumentIcon } from "Patient/Record";
import { format, utcToZonedTime } from "date-fns-tz";
import { useStore } from "effector-react";
import {
  ChangeEventHandler,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { StepWizardChildProps } from "react-step-wizard";
import { getCurrentTimezoneIANA } from "shared-functions/date";
import {
  Block,
  Button,
  ChevronLeftIcon,
  ClipIcon,
  CloseRoundedIcon,
  CountrySelectbox,
  DateInput,
  ErrorMessage,
  Flex,
  Grid,
  Input,
  LoaderWithOverlay,
  Option,
  StateSelectbox,
  Typography,
  color,
  errorNotification,
  fromStringToCountryOption,
  fromStringToStateOption,
  isFormatMatched,
  maskedInputToDate,
  restrictPast,
} from "ui-kit";
import { updateLicense, useDoctor } from "../doctorSession";

const licenseFieldsNames = {
  number: "number",
  country: "country",
  state: "state",
  npi: "npi",
  expiration: "expiration",
  document: "document",
};

type LicenseData = {
  number: string;
  country: string;
  state?: string;
  npi: string;
  expiration: string;
  licenseDocuments?: File[];
};

type Props = Partial<StepWizardChildProps>;

const MAX_FILES = 6;

export const LicenseForm = memo(
  ({ currentStep, previousStep, nextStep, totalSteps }: Props) => {
    const doctor = useDoctor();
    const defaultExpirationDate = doctor?.license?.expirationDate
      ? format(
          utcToZonedTime(
            doctor?.license?.expirationDate,
            getCurrentTimezoneIANA()
          ),
          "MM/dd/yyyy"
        )
      : undefined;
    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
      setError,
      clearErrors,
      watch,
    } = useForm<LicenseData>({
      defaultValues: {
        [licenseFieldsNames.number]: doctor?.license?.number || "",
        [licenseFieldsNames.country]: doctor?.license?.country || "",
        [licenseFieldsNames.state]: doctor?.license?.state || "",
        [licenseFieldsNames.npi]: doctor?.license?.npi || "",
        [licenseFieldsNames.expiration]: defaultExpirationDate || "",
        [licenseFieldsNames.document]: [],
      },
    });

    const [selectedFiles, selectFiles] = useSelectedFiles(
      doctor?.license?.documents || []
    );

    const handleLicenseDocumentsChange: ChangeEventHandler<HTMLInputElement> = (
      e
    ) => {
      clearErrors("licenseDocuments");
      const acceptedFiles: File[] = [];
      if (e.target.files) {
        for (let i = 0; i < e.target.files?.length; i++) {
          if (e.target.files.item(i) !== null) {
            acceptedFiles[i] = e.target.files.item(i) as File;
          }
        }
      }
      const filesWithoutDuplicates = acceptedFiles.filter((file) =>
        selectedFiles.every((selectedFile) => selectedFile.name !== file.name)
      );
      const nextFilesSet = [...selectedFiles, ...filesWithoutDuplicates];
      if (nextFilesSet.length > MAX_FILES) {
        return errorNotification(`Too many files. Maximum
      ${MAX_FILES} files`);
      }
      selectFiles(nextFilesSet);
    };

    const saveLicense = ({
      number,
      country,
      state,
      npi,
      expiration,
    }: LicenseData) => {
      if (selectedFiles.length === 0) {
        return setError("licenseDocuments", {
          message:
            "Please, attach at least one document confirming your qualifications",
          type: "required",
        });
      }
      updateLicense({
        number,
        npi,
        country,
        state: country === "US" ? state : undefined,
        expirationDate: maskedInputToDate(expiration)?.toISOString() || "",
        licenseDocuments: selectedFiles,
      })
        .then(nextStep)
        .catch((error) => errorNotification(error.message));
    };

    const removeDocument = useCallback(
      (file: File) => {
        selectFiles(
          selectedFiles.filter((sectedFile) => sectedFile.name !== file.name)
        );
      },
      [selectedFiles, selectFiles]
    );

    const isUpdating = useStore(updateLicense.pending);
    const selectedCountry = watch("country");

    return (
      <Form onSubmit={handleSubmit(saveLicense)}>
        {isUpdating && <LoaderWithOverlay />}
        <Block marginBottom={3} textAlign="center">
          <Typography as="h2" type="h2">
            License
          </Typography>
        </Block>
        <Block marginBottom={3}>
          <Input
            defaultValue={doctor?.license?.number}
            label="License number"
            hasErrors={!!errors["number"]}
            errorMessage={errors["number"]?.message}
            placeholder="License number"
            {...register("number")}
          />
        </Block>
        <Flex marginBottom={3}>
          <Block width="50%" marginRight={1.5}>
            <Controller
              control={control}
              name="country"
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <CountrySelectbox
                  isClearable
                  hasErrors={invalid}
                  errorMessage={error}
                  placeholder="Country of license"
                  label="Country of license"
                  defaultValue={value ? fromStringToCountryOption(value) : null}
                  onChange={(countryOption) =>
                    onChange(
                      countryOption ? (countryOption as Option).value : ""
                    )
                  }
                />
              )}
            />
          </Block>
          <Block width="50%" marginLeft={1.5}>
            {selectedCountry === "US" && (
              <Controller
                control={control}
                name="state"
                render={({
                  field: { onChange, value },
                  fieldState: { invalid, error },
                }) => (
                  <StateSelectbox
                    isClearable
                    hasErrors={invalid}
                    errorMessage={error}
                    placeholder="License state"
                    label="License state"
                    defaultValue={value ? fromStringToStateOption(value) : null}
                    onChange={(stateOption) => {
                      onChange(
                        stateOption ? (stateOption as Option).value : ""
                      );
                    }}
                    styles={{
                      menuList: (base: CSSObject) => ({
                        ...base,
                        maxHeight: "168px",
                      }),
                    }}
                  />
                )}
              />
            )}
          </Block>
        </Flex>
        <Block marginBottom={3}>
          <Input
            defaultValue={doctor?.license?.npi}
            label="NPI number"
            placeholder="NPI number"
            hasErrors={!!errors["npi"]}
            errorMessage={errors["npi"]?.message}
            {...register("npi")}
          />
        </Block>
        <Block marginBottom={7}>
          <DateInput
            defaultValue={defaultExpirationDate}
            label="License expiration date"
            hasErrors={!!errors["expiration"]}
            errorMessage={errors["expiration"]?.message}
            {...register("expiration", {
              validate: {
                format: isFormatMatched,
                restrictPast: (licenseDate) =>
                  restrictPast(licenseDate) ||
                  "Please, provide an unexpired license",
              },
            })}
          />
          <Controller
            control={control}
            name="licenseDocuments"
            render={({ fieldState: { error } }) => (
              <>
                <LicenseDocuments>
                  <input
                    type="file"
                    multiple
                    accept="image/*, application/pdf"
                    className="files"
                    onChange={handleLicenseDocumentsChange}
                    onClick={(event) => {
                      // @ts-ignore
                      event.currentTarget.value = null;
                    }}
                  />
                  <ClipIcon className="icon" />
                  <DocumentTitle className="title">
                    Attach license documents or diploma
                  </DocumentTitle>
                  <Typography lineHeight="0" type="h4">
                    &nbsp;&#42;
                  </Typography>
                </LicenseDocuments>
                {error?.message ? (
                  <Block>
                    <ErrorMessage>{error.message}</ErrorMessage>
                  </Block>
                ) : null}
              </>
            )}
          />
          {selectedFiles.length > 0 && (
            <Grid
              gridTemplateColumns="repeat(3, 1fr)"
              gridTemplateRows="auto"
              gridColumnGap={2}
              gridRowGap={2}
              marginTop={2}
            >
              {selectedFiles.map((file) => (
                <DocumentPreview key={file.name}>
                  <DocumentIcon
                    className="icon"
                    name={file.name}
                    fill={color.N300}
                  />
                  <Typography className="fileName" fontColor="N300" type="h5">
                    {file.name}
                  </Typography>
                  <CloseRoundedIcon
                    onClick={() => removeDocument(file)}
                    cursor="pointer"
                    width="16px"
                    height="16px"
                    fill={color.N300}
                  />
                </DocumentPreview>
              ))}
            </Grid>
          )}
        </Block>
        <Flex alignItems="center" justifyContent="space-between">
          <LinkLabel onClick={previousStep!}>Previous step</LinkLabel>
          <Button filled type="submit">
            Next step {currentStep}/{totalSteps}
          </Button>
        </Flex>
      </Form>
    );
  }
);

function useSelectedFiles(
  documents: LicenseDocument[]
): [File[], React.Dispatch<React.SetStateAction<File[]>>] {
  const [selectedFiles, selectFiles] = useState<File[]>([]);

  useEffect(() => {
    if (documents.length > 0) {
      Promise.all(documents.map((doc) => fetch(doc.url)))
        .then((allDocumentsResponse) =>
          Promise.all(allDocumentsResponse.map((docR) => docR.blob()))
        )
        .then((blobsFileList) =>
          blobsFileList.map(
            (blob, index) =>
              new File([blob], documents[index].name, { type: blob.type })
          )
        )
        .then(selectFiles);
    }
  }, [documents]);

  return [selectedFiles, selectFiles];
}

const Form = styled.form`
  position: relative;
  width: 100%;
`;

type LinkLabelProps = {
  children: ReactNode;
  onClick: () => void;
};

const LinkLabel = memo(({ children, onClick }: LinkLabelProps) => (
  <LinkLabelComponent type="h4" fontColor="G200" onClick={onClick}>
    <ChevronLeftIcon stroke={color.G200} />
    <LinkContent fontColor="G200" type="h4">
      {children}
    </LinkContent>
  </LinkLabelComponent>
));

const LinkLabelComponent = styled(Typography)`
  cursor: pointer;
  &:hover span,
  &:hover svg {
    color: ${color.G300};
  }
`;

const LinkContent = styled(Typography)`
  padding-left: 8px;
`;

const LicenseDocuments = styled("label")`
  color: ${color.G200};
  column-gap: 4px;
  cursor: pointer;
  display: inline-block;
  margin-top: 12px;
  & .files {
    display: none;
  }
  & .title {
    color: ${color.G200};
  }
  & .icon {
    fill: ${color.G200};
  }
  &:hover .title,
  &:focus .title {
    color: ${color.G300};
  }
  &:hover .icon,
  &:focus .icon {
    color: ${color.G300};
  }
`;

const DocumentPreview = styled(Flex)`
  align-items: center;
  & .fileName {
    margin-right: 8px;
    max-width: 132px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & .icon {
    margin-right: 8px;
  }
`;

const DocumentTitle = styled(Typography)`
  padding-left: 4px;
`;
