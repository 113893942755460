import styled from "@emotion/styled";
import { useStore } from "effector-react";
import {
  loadSelectedPharmacies,
  pharmacies as $pharmacies,
} from "Patient/Record";
import { memo, useEffect, useState } from "react";
import {
  Block,
  color,
  Flex,
  Grid,
  RecordPharmacyIcon,
  Typography,
} from "ui-kit";
import { EmptyStateContainer, EmptyStateIcon } from "./EmptyState";
import { LoaderWithSpacing } from "./Loader";

type Props = {
  patientId: string;
};

export const PharmacyPage = memo(({ patientId }: Props) => {
  const [arePharmaciesLoading, setPharmaciesLoadState] = useState(false);
  const { selected: selectedPharmacies } = useStore($pharmacies);
  const [emptyStateView, displayEmptyStateView] = useState(false);

  useEffect(() => {
    async function loadPharmaciesAndSwitchView(patientId: string) {
      const selectedPharmacies = await loadSelectedPharmacies(patientId);
      if (selectedPharmacies.length === 0) {
        return displayEmptyStateView(true);
      }
      // when have selected pharmacies - hide empty view and go to the list view
      return displayEmptyStateView(false);
    }
    setPharmaciesLoadState(true);
    loadPharmaciesAndSwitchView(patientId).finally(() =>
      setPharmaciesLoadState(false)
    );
  }, [patientId]);

  if (arePharmaciesLoading) {
    return <LoaderWithSpacing />;
  }

  if (emptyStateView) {
    return (
      <EmptyStateContainer>
        <Flex justifyContent="center" marginBottom={4}>
          <EmptyStateIcon>
            <RecordPharmacyIcon width="86px" height="86px" />
          </EmptyStateIcon>
        </Flex>
        <Block marginBottom={1}>
          <Typography fontColor="N300" type="h2">
            No selected pharmacies
          </Typography>
        </Block>
        <Block marginBottom={4}>
          <Typography fontColor="N300" fontWeight={400} type="h3">
            After the patient add pharmacies, you can send a prescription there
          </Typography>
        </Block>
      </EmptyStateContainer>
    );
  }

  return (
    <PharmaciesListContainer>
      {selectedPharmacies.length > 0 && (
        <Block marginBottom={5}>
          {selectedPharmacies.map((pharmacy) => (
            <PharmacyPlaceContainer
              key={pharmacy.place_id}
              alignItems="center"
              gridTemplateColumns="2fr 3fr"
              gridColumnGap={6}
            >
              <Block>
                <Typography type="h2">{pharmacy.name}</Typography>
              </Block>
              <Block>
                <Typography type="h4" fontColor="N300">
                  Address: {pharmacy.vicinity}
                </Typography>
              </Block>
            </PharmacyPlaceContainer>
          ))}
        </Block>
      )}
    </PharmaciesListContainer>
  );
});

const PharmaciesListContainer = styled(Block)`
  margin: 32px 0;
  padding: 0 40px;
`;

const PharmacyPlaceContainer = styled(Grid)`
  border-top: 1px solid ${color.N200};
  padding-bottom: 24px;
  padding-top: 24px;
  &:last-of-type {
    border-bottom: 1px solid ${color.N200};
  }
`;
