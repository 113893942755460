import { combine, createEvent, createStore } from "effector";
import { $stream, leaveSession } from "MediaStream";

type VirtualVisit = string | null;

export const startVirtualVisit = createEvent<string>("startVirtualVisit");

const $virtualVisit = createStore<VirtualVisit>(null)
  .on(startVirtualVisit, (state, visitId) => visitId)
  .reset(leaveSession);

export const $visitStream = combine(
  { $stream, $virtualVisit },
  ({ $stream, $virtualVisit }) => ({ ...$stream, visitId: $virtualVisit })
);
