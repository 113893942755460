import { apiServer } from "apiConnection";
import { ISO8601 } from "Doctor/Model";
import { attach, createEffect, createStore } from "effector";
import { successNotification } from "ui-kit";
import { procedureRecordAdapter } from "./DTOAdapter";
import { ProcedureRecord, ProcedureRecordDTO } from "./typeDefinitions";

export const loadProcedure = createEffect("loadProcedure", {
  handler: async (patientId: string) => await getProcedure(patientId),
});

export const addProcedureRecord = createEffect("addProcedureRecord", {
  handler: async ({
    patientId,
    type,
    name,
    description,
    periodFrom,
    periodTo,
  }: {
    patientId: string;
    type: "current" | "past";
    name: string;
    description: string;
    periodFrom?: ISO8601;
    periodTo?: ISO8601;
  }) =>
    await createProcedureRecord({
      patientId,
      type,
      name,
      description,
      periodFrom,
      periodTo,
    }),
});

addProcedureRecord.doneData.watch(() => {
  successNotification("Procedure was added");
});

export const editProcedureRecord = createEffect("editProcedureRecord", {
  handler: async ({
    patientId,
    recordId,
    type,
    name,
    description,
    periodFrom,
    periodTo,
  }: {
    patientId: string;
    recordId: string;
    type: "current" | "past";
    name: string;
    description: string;
    periodFrom?: ISO8601;
    periodTo?: ISO8601;
  }) =>
    await updateProcedureRecord({
      patientId,
      recordId,
      type,
      name,
      description,
      periodFrom,
      periodTo,
    }),
});

editProcedureRecord.doneData.watch(() => {
  successNotification("Procedure was updated");
});

const hydrated_moveProcedureToPast = createEffect("moveProcedureToPast", {
  handler: async ({
    patientId,
    recordId,
    store,
  }: {
    patientId: string;
    recordId: string;
    store: ProcedureRecord[];
  }) => {
    const procedureRecord = store
      .filter(({ type }) => type === "current")
      .find(({ id }) => id === recordId);
    if (!procedureRecord) {
      throw new Error(`Procedure record with id ${recordId} not found`);
    }
    return await updateProcedureRecord({
      patientId,
      recordId,
      type: "past",
      name: procedureRecord.name,
      description: procedureRecord.description,
      periodFrom: procedureRecord.createdAt,
      periodTo: Date.nowUniversal.toISOString(),
    });
  },
});

hydrated_moveProcedureToPast.doneData.watch(() => {
  successNotification("Procedure was moved to past");
});

export const deleteProcedureRecord = createEffect("deleteProcedureRecord", {
  handler: async ({
    patientId,
    recordId,
  }: {
    patientId: string;
    recordId: string;
  }) => await removeProcedureRecord({ patientId, recordId }),
});

deleteProcedureRecord.doneData.watch(() => {
  successNotification("Procedure was removed");
});

async function getProcedure(patientId: string) {
  const { data: procedureDTO } = await apiServer.get<ProcedureRecordDTO[]>(
    `/api/patient/${patientId}/procedures`
  );
  return procedureDTO;
}

async function createProcedureRecord({
  patientId,
  type,
  name,
  description,
  periodFrom,
  periodTo,
}: {
  patientId: string;
  type: "current" | "past";
  name: string;
  description: string;
  periodFrom?: ISO8601;
  periodTo?: ISO8601;
}) {
  const { data: createdRecord } = await apiServer.post<ProcedureRecordDTO>(
    `/api/patient/${patientId}/procedures`,
    {
      type,
      name,
      description,
      periodFrom,
      periodTo,
    }
  );
  return createdRecord;
}

async function updateProcedureRecord({
  patientId,
  recordId,
  type,
  name,
  description,
  periodFrom,
  periodTo,
}: {
  patientId: string;
  recordId: string;
  type: "current" | "past";
  name: string;
  description: string;
  periodFrom?: ISO8601;
  periodTo?: ISO8601;
}) {
  const { data: updatedRecord } = await apiServer.post<ProcedureRecordDTO>(
    `/api/patient/${patientId}/procedures/${recordId}`,
    {
      type,
      name,
      description,
      periodFrom,
      periodTo,
    }
  );
  return updatedRecord;
}

async function removeProcedureRecord({
  patientId,
  recordId,
}: {
  patientId: string;
  recordId: string;
}) {
  const { data: removedRecord } = await apiServer.delete<{ id: string }>(
    `/api/patient/${patientId}/procedures/${recordId}`
  );
  return removedRecord.id;
}

export const procedures = createStore<ProcedureRecord[]>([])
  .on(loadProcedure.doneData, (state, procedureDTO) =>
    procedureDTO.map(procedureRecordAdapter)
  )
  .on(addProcedureRecord.doneData, (state, procedureDTO) => [
    ...state,
    procedureRecordAdapter(procedureDTO),
  ])
  .on(
    [editProcedureRecord.doneData, hydrated_moveProcedureToPast.doneData],
    (state, updatedRecordDTO) =>
      state.map((record) =>
        record.id === updatedRecordDTO.objectId
          ? procedureRecordAdapter(updatedRecordDTO)
          : record
      )
  )
  .on(deleteProcedureRecord.doneData, (state, recordId) =>
    state.filter((record) => record.id !== recordId)
  );

export const moveProcedureToPast = attach({
  effect: hydrated_moveProcedureToPast,
  source: procedures,
  mapParams: (
    { patientId, recordId }: { patientId: string; recordId: string },
    store
  ) => ({ patientId, recordId, store }),
});
