import styled from "@emotion/styled";
import {
  endOfDay,
  format as formatDate,
  getHours,
  getMinutes,
  getSeconds,
  isAfter,
  isBefore,
  isMatch,
  isToday,
  isValid,
  parse,
  setSeconds,
} from "date-fns";
import { setHours, setMinutes, startOfDay } from "date-fns/esm";
import {
  ChangeEvent,
  ElementType,
  forwardRef,
  HTMLProps,
  memo,
  Ref,
  useEffect,
  useMemo,
  useState,
} from "react";
import ReactCalendar, {
  CalendarProps as NativeCalendarProps,
} from "react-calendar";
import { useDetectClickOutside } from "react-detect-click-outside";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import InputWithMask, { ReactInputMask } from "react-input-mask";
import { v4 as uuidv4 } from "uuid";
import { BoxWithShadow } from "../BoxWithShadow";
import { ErrorMessage } from "../ErrorMessage";
import { CalendarIcon, ChevronLeftIcon } from "../icons";
import { Block, Flex } from "../Layout";
import { boxShadow, color } from "../theme";
import { Typography } from "../Typography";
import { InputElement, Label } from "./PureInput";

type CalendarProps = Pick<
  NativeCalendarProps,
  | "minDate"
  | "maxDate"
  | "minDetail"
  | "maxDetail"
  | "next2Label"
  | "prev2Label"
  | "defaultView"
  | "value"
> & {
  onCalendarDateChange?: (date: Date) => void;
};

type Props = HTMLProps<HTMLInputElement> &
  CalendarProps & {
    calendarEnabled?: boolean;
    errorMessage?:
      | FieldError
      | Merge<FieldError, FieldErrorsImpl<any>>
      | string;
    hasErrors?: boolean;
    hasValue?: boolean;
    // input has a native `required` property. But to bypass a browser validation, need to rely on the custom flag
    isRequired?: boolean;
  };

export const DateInput = forwardRef(
  (props: Props, refObject: Ref<ReactInputMask>) => (
    <DateInputComponent {...props} ref={null} refObject={refObject} />
  )
);

type DateInputProps = Props & {
  refObject?: Ref<ReactInputMask>;
};

const DateInputComponent = memo(
  ({
    label,
    refObject,
    onChange,
    className,
    disabled,
    defaultValue,
    hasErrors,
    errorMessage,
    isRequired,
    ...props
  }: DateInputProps) => {
    const {
      calendarEnabled,
      defaultView,
      hasValue,
      minDate,
      maxDate,
      minDetail,
      maxDetail,
      next2Label,
      onCalendarDateChange,
      prev2Label,
      ...inputProps
    } = props;
    const [inputValue, setInputValue] = useState(
      (defaultValue as string | undefined) || ""
    );

    // This input is uncontrolled
    // But a booking form needs to reset input value after booking was finished
    useEffect(() => {
      if (hasValue === false) {
        setInputValue("");
      }
    }, [hasValue]);

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
      onChange && onChange(e);
    };

    const { placeholder, mask, format } = dateConfig();

    const [calendarOpened, toggleCalendar] = useState(false);
    const changeDateFromCalendarAndClose = (date: Date) => {
      setInputValue(formatDate(date, format));
      onCalendarDateChange && onCalendarDateChange(date);
      toggleCalendar(false);
    };

    const id = useMemo(() => uuidv4(), []);

    const calendarRef = useDetectClickOutside({
      onTriggered: (e) => {
        const clickedAreaId = e.target ? (e.target as any).id : undefined;
        if (clickedAreaId !== id) {
          toggleCalendar(false);
        }
      },
    });

    return (
      <>
        <Block position="relative">
          {label && (
            <Block marginBottom={0.5}>
              <Label htmlFor={id}>
                {label}
                {isRequired && (
                  <Typography lineHeight="0" type="h4">
                    &nbsp;&#42;
                  </Typography>
                )}
              </Label>
            </Block>
          )}
          <DateInputElement
            {...inputProps}
            as={inputProps.as as ElementType<any> | undefined}
            className={className}
            disabled={disabled}
            hasErrors={hasErrors}
            hasValue={
              hasValue !== undefined
                ? hasValue
                : clearMask(inputValue).length > 0
            }
            onChange={onInputChange}
            onFocus={() => toggleCalendar(true)}
            placeholder={placeholder}
            id={id}
            mask={mask}
            ref={refObject}
            type="text"
            value={inputValue}
          />
          {calendarEnabled && (
            <CalendarButtonContainer>
              <div ref={calendarRef}>
                <Calendar
                  defaultView={defaultView}
                  minDate={minDate}
                  maxDate={maxDate}
                  minDetail={minDetail}
                  maxDetail={maxDetail}
                  next2Label={next2Label}
                  prev2Label={prev2Label}
                  value={maskedInputToDate(inputValue)}
                  onCalendarDateChange={changeDateFromCalendarAndClose}
                  opened={calendarOpened}
                  onToggleCalendar={() => toggleCalendar(!calendarOpened)}
                />
              </div>
            </CalendarButtonContainer>
          )}
        </Block>
        {errorMessage && (
          <Block>
            <ErrorMessage>{errorMessage.toString()}</ErrorMessage>
          </Block>
        )}
      </>
    );
  }
);

type CalendarComponentProps = CalendarProps & {
  opened: boolean;
  onToggleCalendar: () => void;
};

const Calendar = memo(
  ({
    opened,
    onToggleCalendar,
    onCalendarDateChange,
    ...props
  }: CalendarComponentProps) => {
    return (
      <>
        <CalendatButton
          onClick={onToggleCalendar}
          type="button"
          aria-label="select-date-calendar-button"
        >
          <CalendarIcon
            className="icon"
            fill={opened ? color.G200 : color.N300}
          />
        </CalendatButton>
        {opened && (
          <CalendarContainer>
            <StyledCalendar
              {...props}
              // @ts-ignore-next-line
              onChange={(date) => {
                if (onCalendarDateChange && date !== null) {
                  onCalendarDateChange(date as Date);
                }
              }}
              calendarType="gregory"
              locale="en-US"
              navigationLabel={({ label }) => (
                <Typography type="h3">{label}</Typography>
              )}
              nextLabel={<NextMonthIcon stroke={color.N300} />}
              prevLabel={<ChevronLeftIcon stroke={color.N300} />}
            />
            <Guide>
              <Flex alignItems="center">
                <DisabledColor />
                <Typography type="h6" fontColor="N300">
                  Not available date
                </Typography>
              </Flex>
              <Flex alignItems="center">
                <SelectedColor />
                <Typography type="h6" fontColor="N300">
                  Selected date
                </Typography>
              </Flex>
            </Guide>
          </CalendarContainer>
        )}
      </>
    );
  }
);

const DateInputElement = InputElement.withComponent(InputWithMask);

type DateOutputFormat = {
  format: string;
  placeholder: string;
  mask: string;
};

function dateConfig(): DateOutputFormat {
  // Assumption: US locale by default
  return {
    placeholder: "month/day/year",
    format: "MM/dd/yyyy",
    mask: "99/99/9999",
  };
}

function clearMask(dateWithMask?: string) {
  if (!dateWithMask) {
    return "";
  }
  return dateWithMask.replace(/[\\._\s]/g, "");
}

export function maskedInputToDate(value?: string) {
  const { format } = dateConfig();
  let date = parse(clearMask(value), format, Date.nowUniversal);
  // By default, date parsed with the time 00:00:00s
  // convert to precise time for today
  if (isValid(date) && isToday(date)) {
    date = setHours(date, getHours(Date.nowUniversal));
    date = setMinutes(date, getMinutes(Date.nowUniversal));
    date = setSeconds(date, getSeconds(Date.nowUniversal));
  }
  return isValid(date) ? date : undefined;
}

/**
 * Validators
 */

export function isFormatMatched(value?: string) {
  // when value is empty, pass format check
  if (clearMask(value).replace(/\//g, "") === "") {
    return true;
  }
  const { format, placeholder } = dateConfig();
  return (
    isMatch(clearMask(value), format) ||
    `Date should be in the format ${placeholder}`
  );
}

export function restrictPast(value?: string) {
  const asDate = maskedInputToDate(value);
  if (!asDate) {
    return true;
  }
  return isAfter(endOfDay(asDate), Date.nowUniversal);
}

export function restrictFuture(value?: string) {
  const asDate = maskedInputToDate(value);
  if (!asDate) {
    return true;
  }
  return isBefore(startOfDay(asDate), Date.nowUniversal);
}

export const CalendarContainer = styled(BoxWithShadow)`
  background-color: ${color.N0};
  flex-direction: column;
  padding: 12px 8px;
  position: absolute;
  right: -12px;
  top: calc(100% + 8px);
  width: 308px;
  z-index: 4;
  &:before {
    border-bottom: 6px solid ${color.N0};
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    box-shadow: ${boxShadow.extraSmall};
    content: "";
    height: 0;
    margin-left: -3px;
    position: absolute;
    right: 14px;
    top: -6px;
    width: 0;
  }
`;

const StyledCalendar = styled(ReactCalendar)`
  & [type="button"] {
    all: unset;
    text-align: center;
  }
  .react-calendar__navigation {
    align-items: center;
    border-bottom: 1px solid ${color.N200};
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
  }
  .react-calendar__navigation__arrow {
    cursor: pointer;
    padding: 0 8px;
    &[disabled] {
      cursor: not-allowed;
    }
    &[disabled] svg {
      stroke: ${color.N200};
    }
  }
  .react-calendar__month-view__weekdays {
    border-bottom: 1px solid ${color.N200};
    color: ${color.N300};
    display: grid !important;
    grid-template-columns: repeat(7, 24px);
    grid-column-gap: 16px;
    font-size: 12px;
    line-height: 16px;
    padding: 8px 12px;
    text-align: center;
    & abbr {
      text-decoration: none;
    }
  }
  .react-calendar__month-view__weekdays__weekday {
    flex-basis: auto !important;
    max-width: none !important;
  }
  .react-calendar__month-view__days {
    align-items: center;
    border-bottom: 1px solid ${color.N200};
    color: ${color.N400};
    display: grid !important;
    grid-template-columns: repeat(7, 24px);
    grid-column-gap: 16px;
    grid-row-gap: 12px;
    padding: 8px 12px;
    font-size: 12px;
    line-height: 16px;
  }
  .react-calendar__month-view__days__day {
    border-radius: 2px;
    cursor: pointer;
    height: 24px;
    flex-basis: auto !important;
    max-width: none !important;
    &:hover,
    &:focus {
      background-color: ${color.N100};
      color: ${color.G200};
    }
    &[disabled] {
      background-color: ${color.N200};
      color: ${color.N400};
      cursor: not-allowed;
    }
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: ${color.N300};
  }
  .react-calendar__tile--active {
    background-color: ${color.G200};
    color: ${color.N0};
    &:hover,
    &:focus {
      background-color: ${color.G200};
      color: ${color.N0};
    }
  }
`;

const CalendarButtonContainer = styled.span`
  position: absolute;
  right: 12px;
  bottom: 8px;
`;

const CalendatButton = styled.button`
  all: unset;

  & .icon {
    cursor: pointer;
  }
  & .icon:hover,
  & .icon:focus {
    fill: ${color.G200};
  }
`;

const NextMonthIcon = styled(ChevronLeftIcon)`
  transform: rotate(180deg);
`;

const Guide = styled(Flex)`
  justify-content: space-between;
  padding: 12px 18px 0;
`;

const Color = styled.span`
  border-radius: 2px;
  height: 12px;
  margin-right: 12px;
  width: 12px;
`;

const DisabledColor = styled(Color)`
  background-color: ${color.N200};
`;

const SelectedColor = styled(Color)`
  background-color: ${color.G200};
`;
