import styled from "@emotion/styled";
import { format } from "date-fns";
import { homePage } from "Home/routes";
import {
  cookiePolicyURL,
  DMCAURL,
  nonDiscriminationPolicyURL,
  privacyPolicyURL,
  termsOfUseURL,
} from "Policies";
import { memo } from "react";
import { Link, NavLink } from "react-router-dom";
import { Typography } from "ui-kit";
import { Logo } from "./icons";
import { Block, Flex, Grid } from "./Layout";
import { color } from "./theme";
import { ReactComponent as GDPRLogo } from "ui-kit/icons/GDPR.svg";
import { ReactComponent as CTDLogo } from "ui-kit/icons/CTD.svg";
import { ReactComponent as HipaaLogo } from "ui-kit/icons/Hipaa.svg";

export const Footer = memo(() => (
  <FooterContainer gridTemplateColumns="max-content auto" gridColumnGap={8}>
    <Flex flexDirection="column" justifyContent="space-between">
      <Block>
        <Block marginBottom={2}>
          <Link to={homePage}>
            <Logo fill={color.N200} width="188px" />
          </Link>
        </Block>
        <Block>
          <Typography fontColor="N300" type="h5">
            &copy;&nbsp;{format(Date.nowUniversal, "yyyy")}{" "}
            PocketDoctor,&nbsp;Inc. All rights reserved
          </Typography>
        </Block>
      </Block>
      <ProtectionBrands position="relative" bottom="12px">
        <GDPRLogo width={48} height={52} />
        <CTDLogo width={48} height={52} />
        <HipaaLogo width={48} height={52} />
      </ProtectionBrands>
    </Flex>
    <Grid
      gridTemplateColumns="140px 200px 140px"
      gridColumnGap={8}
      justifyContent="center"
    >
      <Block>
        <Block marginBottom={3}>
          <FooterLink to={privacyPolicyURL}>Privacy policy</FooterLink>
        </Block>
        <Block marginBottom={3}>
          <FooterLink to={termsOfUseURL}>Terms of use</FooterLink>
        </Block>
        <Block marginBottom={3}>
          <FooterLink to={cookiePolicyURL}>Cookie policy</FooterLink>
        </Block>
        <Block>
          <FooterLinkDisabled to="undefined">Site map</FooterLinkDisabled>
        </Block>
      </Block>
      <Block>
        <Block marginBottom={3}>
          <FooterLink to={nonDiscriminationPolicyURL}>
            Non-discrimination policy
          </FooterLink>
        </Block>
        <Block marginBottom={3}>
          <FooterLink to={DMCAURL}>DMCA</FooterLink>
        </Block>
        <Block marginBottom={3}>
          <FooterLinkDisabled to="undefined">Principles</FooterLinkDisabled>
        </Block>
        <Block>
          <FooterLinkDisabled to="undefined">Our team</FooterLinkDisabled>
        </Block>
      </Block>
      <Block>
        <Block marginBottom={3}>
          <FooterLinkDisabled to="undefined">
            Free consultations
          </FooterLinkDisabled>
        </Block>
        <Block marginBottom={3}>
          <FooterLinkDisabled to="undefined">For doctors</FooterLinkDisabled>
        </Block>
        <Block marginBottom={3}>
          <FooterLinkDisabled to="undefined">Hot line</FooterLinkDisabled>
        </Block>
        <Block>
          <FooterLinkDisabled to="undefined">Blog</FooterLinkDisabled>
        </Block>
      </Block>
    </Grid>
  </FooterContainer>
));

const FooterContainer = styled(Grid.withComponent("footer"))`
  background-color: ${color.N400};
  padding: 72px 320px;
`;

const FooterLink = styled(NavLink)`
  border-bottom: none;
  color: ${color.N200};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
  &.active,
  &:hover,
  &:focus {
    color: ${color.G100};
  }
  &.active {
    cursor: default;
  }
`;

const FooterLinkDisabled = styled(FooterLink.withComponent("span"))`
  color: ${color.N300};
  cursor: not-allowed;
  &:hover,
  &:focus {
    color: ${color.N300};
  }
`;

const ProtectionBrands = styled(Flex)`
  column-gap: 12px;
`;
