import { memo } from "react";
import {
  fromStringToOption,
  Option,
  Selectbox,
  SelectProps,
} from "./Selectbox";

export const StateSelectbox = memo((props: SelectProps) => (
  <Selectbox
    {...props}
    options={USAStates}
    noOptionsMessage={() => "No states"}
  />
));

export function fromStringToStateOption(value: string) {
  return fromStringToOption(value, USAStates);
}

const USAStates: Option[] = [
  { label: "Alabama", value: "US-AL" },
  { label: "Alaska", value: "US-AK" },
  { label: "American Samoa", value: "US-AS" },
  { label: "Arizona", value: "US-AZ" },
  { label: "Arkansas", value: "US-AR" },
  { label: "California", value: "US-CA" },
  { label: "Colorado", value: "US-CO" },
  { label: "Connecticut", value: "US-CT" },
  { label: "Delaware", value: "US-DE" },
  { label: "District of Columbia", value: "US-DC" },
  { label: "Florida", value: "US-FL" },
  { label: "Georgia", value: "US-GA" },
  { label: "Guam", value: "US-GU" },
  { label: "Hawaii", value: "US-HI" },
  { label: "Idaho", value: "US-ID" },
  { label: "Illinois", value: "US-IL" },
  { label: "Indiana", value: "US-IN" },
  { label: "Iowa", value: "US-IA" },
  { label: "Kansas", value: "US-KS" },
  { label: "Kentucky", value: "US-KY" },
  { label: "Louisiana", value: "US-LA" },
  { label: "Maine", value: "US-ME" },
  { label: "Maryland", value: "US-MD" },
  { label: "Massachusetts", value: "US-MA" },
  { label: "Michigan", value: "US-MI" },
  { label: "Minnesota", value: "US-MN" },
  { label: "Mississippi", value: "US-MS" },
  { label: "Missouri", value: "US-MO" },
  { label: "Montana", value: "US-MT" },
  { label: "Nebraska", value: "US-NE" },
  { label: "Nevada", value: "US-NV" },
  { label: "New Hampshire", value: "US-NH" },
  { label: "New Jersey", value: "US-NJ" },
  { label: "New Mexico", value: "US-NM" },
  { label: "New York", value: "US-NY" },
  { label: "North Carolina", value: "US-NC" },
  { label: "North Dakota", value: "US-ND" },
  { label: "Northern Mariana Islands", value: "US-MP" },
  { label: "Ohio", value: "US-OH" },
  { label: "Oklahoma", value: "US-OK" },
  { label: "Oregon", value: "US-OR" },
  { label: "Pennsylvania", value: "US-PA" },
  { label: "Puerto Rico", value: "US-PR" },
  { label: "Puerto Rico", value: "US-PR" },
  { label: "Rhode Island", value: "US-RI" },
  { label: "South Carolina", value: "US-SC" },
  { label: "South Dakota", value: "US-SD" },
  { label: "Tennessee", value: "US-TN" },
  { label: "Texas", value: "US-TX" },
  { label: "Utah", value: "US-UT" },
  { label: "Vermont", value: "US-VT" },
  { label: "Virgin Islands", value: "US-VI" },
  { label: "Virginia", value: "US-VA" },
  { label: "Washington", value: "US-WA" },
  { label: "West Virginia", value: "US-WV" },
  { label: "Wisconsin", value: "US-WI" },
  { label: "Wyoming", value: "US-WY" },
];
