import { aboutPage } from "About/routes";
import {
  useDoctor,
  AuthBarier as DoctorAuthBarier,
  CreatedAccountBarier as DoctorCreatedAccountBarier,
  PatientRecordBarier as DoctorAccessToPatientRecordBarier,
} from "Doctor/Auth";
import {
  doctorCreateAccountPage,
  doctorFAQPage,
  doctorLoginPage,
  doctorNotificationsPage,
  doctorPatientRecordAddConclusion,
  doctorPatientRecordAddLabTest,
  doctorPatientRecordCaseHistory,
  doctorPatientRecordConclusionsList,
  doctorPatientRecordFamilyDiseases,
  doctorPatientRecordHealthMetrics,
  doctorPatientRecordHome,
  doctorPatientRecordLabTests,
  doctorPatientRecordLifestyle,
  doctorPatientRecordMedicine,
  doctorPatientRecordPharmacy,
  doctorPatientRecordProcedures,
  doctorPatientRecordSurgeries,
  doctorPatientRecordVaccination,
  doctorProfileHomePage,
  doctorProfilePage,
  doctorProfileSettingsPage,
  doctorRegistrationPage,
  doctorSupportPage,
  doctorVirtualVisitPage,
  doctorVisitsHistoryPage,
  doctorWaitingRoomPage,
  toDoctorPatientRecordAddConclusion,
  toDoctorPatientRecordAddLabTest,
  toDoctorProfileHomePage,
} from "Doctor/Router";
import { useStore } from "effector-react";
import { FAQPage as FAQPageRoute } from "FAQ/routes";
import { loginHomePage, registrationHomePage } from "Home/routes";
import { doctorsListPage } from "OurDoctors/routes";
import {
  usePatient,
  AuthBarier as PatientAuthBarier,
  CreatedAccountBarier as WhenPatientAccountCreatedBarier,
} from "Patient/Auth";
import { Patient } from "Patient/Model";
import {
  conclusionsList as $conclusionsList,
  labTests as $labTests,
} from "Patient/Record";
import {
  addCaseHistory,
  addLabTest,
  addMedicine,
  addProcedure,
  addSurgery,
  addVaccination,
  caseHistory,
  conclusions,
  familyDiseases,
  healthMetrics,
  labTests,
  lifestyle,
  medicine,
  patientLoginPage,
  patientProfileCreateAccount,
  patientProfileFAQ,
  patientProfileHome,
  patientProfileNotifications,
  patientProfileSettings,
  patientProfileSupport,
  patientProfileVisitsHistory,
  patientProfileWaitingRoom,
  patientRegistrationPage,
  patientVirtualVisitPage,
  personalInformation,
  pharmacy,
  procedures,
  surgeries,
  talkToDoctor,
  toPatientProfilHome,
  vaccination,
} from "Patient/routes";
import {
  cookiePolicyURL,
  DMCAURL,
  nonDiscriminationPolicyURL,
  privacyPolicyURL,
  termsOfUseURL,
} from "Policies";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { loadOnDemand } from "shared-functions/loadOnDemand";
import { supportPage } from "Support/routes";
import { Button } from "ui-kit";

export function RootRouter() {
  const navigate = useNavigate();
  const labTestsStore = useStore($labTests);
  const conclusionsStore = useStore($conclusionsList);

  return (
    <Routes>
      {/* Cover pages (without authentication) */}
      <Route path="/" element={<RouteWithAuthRedirection />}>
        <Route index element={<HomePage />} />
        <Route path={doctorsListPage} element={<OurDoctorsPage />} />
        <Route path={FAQPageRoute} element={<FAQPage />} />
        <Route path={supportPage} element={<SupportPage />} />
        <Route path={loginHomePage} element={<LoginHomePage />} />
        <Route path={registrationHomePage} element={<RegistrationHomePage />} />
        <Route path={patientLoginPage} element={<PatientLoginPage />} />
        <Route
          path={patientRegistrationPage}
          element={<PatientRegistrationPage />}
        />
        <Route path={doctorLoginPage} element={<DoctorLoginPage />} />
        <Route
          path={doctorRegistrationPage}
          element={<DoctorRegistrationPage />}
        />
      </Route>
      <Route path={aboutPage} element={<AboutPage />} />
      <Route path={privacyPolicyURL} element={<PrivacyPolicyPage />} />
      <Route path={termsOfUseURL} element={<TermsOfUsePage />} />
      <Route path={cookiePolicyURL} element={<CookiePolicyPage />} />
      <Route
        path={nonDiscriminationPolicyURL}
        element={<NonDiscriminationPolicyPage />}
      />
      <Route path={DMCAURL} element={<DMCAPage />} />

      {/* Patient Profile */}
      <Route path={patientProfileCreateAccount} element={<PatientAuthBarier />}>
        <Route index element={<PatientCreateAccountPage />} />
      </Route>
      <Route
        path={patientProfileHome}
        element={<WhenPatientAccountCreatedBarier />}
      >
        <Route index element={<PatientHomePage />} />
        <Route path={talkToDoctor} element={<PatientTalkToDoctorPage />} />
        <Route
          path={patientProfileWaitingRoom}
          element={<PatientWaitingRoomPage />}
        />
        <Route
          path={patientProfileVisitsHistory}
          element={<PatientVisitsHistoryPage />}
        />
        <Route
          path={patientProfileSettings}
          element={<PatientProfileSettings />}
        />
        <Route
          path={patientProfileNotifications}
          element={<PatientNotificationsCenter />}
        />
        <Route path={patientProfileSupport} element={<PatientSupportPage />} />
        <Route path={patientProfileFAQ} element={<PatientFAQPage />} />
        <Route
          path={patientVirtualVisitPage}
          element={<PatientVirtualVisitPage />}
        />
        <Route
          path={personalInformation}
          element={<PatientPersonalInformationPage />}
        />
        <Route path={caseHistory} element={<PatientCaseHistoryList />} />
        <Route path={addCaseHistory} element={<PatientAddCaseHistory />} />
        <Route path={vaccination} element={<PatientVaccinationList />} />
        <Route path={addVaccination} element={<PatientAddVacine />} />
        <Route path={surgeries} element={<PatientSurgeriesList />} />
        <Route path={addSurgery} element={<PatientAddSurgery />} />
        <Route path={conclusions} element={<PatientConclusionsList />} />
        <Route path={healthMetrics} element={<PatientHealthMetricsPage />} />
        <Route path={familyDiseases} element={<PatientFamilyDiseases />} />
        <Route path={procedures} element={<PatientProceduresList />} />
        <Route path={addProcedure} element={<PatientAddProcedure />} />
        <Route path={pharmacy} element={<PatientPharmacyPage />} />
        <Route path={lifestyle} element={<PatientLifestylePage />} />
        <Route path={medicine} element={<PatientMedicineList />} />
        <Route path={addMedicine} element={<PatientAddMedicine />} />
        <Route path={labTests} element={<PatientLabTestsList />} />
        <Route path={addLabTest} element={<PatientAddLabTestPage />} />
      </Route>

      {/* Doctor Profile */}
      <Route path={doctorCreateAccountPage} element={<DoctorAuthBarier />}>
        <Route index element={<DoctorCreateAccountPage />} />
      </Route>
      <Route
        path={doctorProfileHomePage}
        element={<DoctorCreatedAccountBarier />}
      >
        <Route index element={<DoctorProfileHomePage />} />
        <Route path={doctorFAQPage} element={<DoctorFAQPage />} />
        <Route path={doctorSupportPage} element={<DoctorSupportPage />} />
        <Route
          path={doctorNotificationsPage}
          element={<DoctorNotificationsCenter />}
        />
        <Route
          path={doctorProfileSettingsPage}
          element={<DoctorProfileSettingsPage />}
        />
        <Route
          path={doctorWaitingRoomPage}
          element={<DoctorWaitingRoomPage />}
        />
        <Route
          path={doctorVisitsHistoryPage}
          element={<DoctorVisitsHistoryPage />}
        />
        <Route
          path={doctorVirtualVisitPage}
          element={<DoctorVirtualVisitPage />}
        />
        <Route path={doctorProfilePage} element={<DoctorEditProfilePage />} />
      </Route>

      {/* Doctor Profile routes than need access to a patient record */}
      <Route
        path={doctorPatientRecordHome}
        element={
          <DoctorAccessToPatientRecordBarier title="Personal data">
            {(patient: Patient) => (
              <DoctorPatientRecordPersonalInformationPage patient={patient} />
            )}
          </DoctorAccessToPatientRecordBarier>
        }
      />
      <Route
        path={doctorPatientRecordCaseHistory}
        element={
          <DoctorAccessToPatientRecordBarier title="Case history">
            {(patient: Patient) => (
              <DoctorPatientRecordCaseHistoryPage patientId={patient.id} />
            )}
          </DoctorAccessToPatientRecordBarier>
        }
      />
      <Route
        path={doctorPatientRecordVaccination}
        element={
          <DoctorAccessToPatientRecordBarier title="Vaccination">
            {(patient: Patient) => (
              <DoctorPatientRecordVaccinationPage patientId={patient.id} />
            )}
          </DoctorAccessToPatientRecordBarier>
        }
      />
      <Route
        path={doctorPatientRecordConclusionsList}
        element={
          <DoctorAccessToPatientRecordBarier
            title="Conclusions"
            recordSpecificContorls={(patientId, doctorId) =>
              conclusionsStore.length > 0 ? (
                <Button
                  onClick={() => {
                    navigate({
                      pathname: toDoctorPatientRecordAddConclusion({
                        patientId,
                        doctorId,
                      }),
                    });
                  }}
                >
                  Add conclusion
                </Button>
              ) : null
            }
          >
            {() => <DoctorPatientRecordConclusionsListPage />}
          </DoctorAccessToPatientRecordBarier>
        }
      />
      <Route
        path={doctorPatientRecordAddConclusion}
        element={
          <DoctorAccessToPatientRecordBarier title="Conclusions">
            {(patient: Patient) => (
              <DoctorPatientRecordAddConclusionPage patientId={patient.id} />
            )}
          </DoctorAccessToPatientRecordBarier>
        }
      />
      <Route
        path={doctorPatientRecordHealthMetrics}
        element={
          <DoctorAccessToPatientRecordBarier title="Health metrics">
            {(patient: Patient) => (
              <DoctorPatientRecordHealthMetricsPage patientId={patient.id} />
            )}
          </DoctorAccessToPatientRecordBarier>
        }
      />
      <Route
        path={doctorPatientRecordFamilyDiseases}
        element={
          <DoctorAccessToPatientRecordBarier title="Family diseases">
            {(patient: Patient) => (
              <DoctorPatientRecordFamilyDiseasesPage patientId={patient.id} />
            )}
          </DoctorAccessToPatientRecordBarier>
        }
      />
      <Route
        path={doctorPatientRecordProcedures}
        element={
          <DoctorAccessToPatientRecordBarier title="Procedures">
            {(patient: Patient) => (
              <DoctorPatientRecordProceduresPage patientId={patient.id} />
            )}
          </DoctorAccessToPatientRecordBarier>
        }
      />
      <Route
        path={doctorPatientRecordPharmacy}
        element={
          <DoctorAccessToPatientRecordBarier title="Pharmacy">
            {(patient: Patient) => (
              <DoctorPatientRecordPharmacyPage patientId={patient.id} />
            )}
          </DoctorAccessToPatientRecordBarier>
        }
      />
      <Route
        path={doctorPatientRecordLifestyle}
        element={
          <DoctorAccessToPatientRecordBarier title="Lifestyle">
            {(patient: Patient) => (
              <DoctorPatientRecordLifestylePage patientId={patient.id} />
            )}
          </DoctorAccessToPatientRecordBarier>
        }
      />
      <Route
        path={doctorPatientRecordMedicine}
        element={
          <DoctorAccessToPatientRecordBarier title="Medicine">
            {(patient: Patient) => (
              <DoctorPatientRecordMedicinePage patientId={patient.id} />
            )}
          </DoctorAccessToPatientRecordBarier>
        }
      />
      <Route
        path={doctorPatientRecordSurgeries}
        element={
          <DoctorAccessToPatientRecordBarier title="Surgeries">
            {(patient: Patient) => (
              <DoctorPatientRecordSurgeriesListPage patientId={patient.id} />
            )}
          </DoctorAccessToPatientRecordBarier>
        }
      />
      <Route
        path={doctorPatientRecordLabTests}
        element={
          <DoctorAccessToPatientRecordBarier
            title="Lab tests"
            recordSpecificContorls={(patientId, doctorId) =>
              labTestsStore.length > 0 ? (
                <Button
                  onClick={() =>
                    navigate(
                      toDoctorPatientRecordAddLabTest({ doctorId, patientId })
                    )
                  }
                >
                  Add Lab test
                </Button>
              ) : null
            }
          >
            {() => <DoctorPatientRecordLabTestsListPage />}
          </DoctorAccessToPatientRecordBarier>
        }
      />
      <Route
        path={doctorPatientRecordAddLabTest}
        element={
          <DoctorAccessToPatientRecordBarier title="Lab tests">
            {() => <DoctorPatientRecordAddLabTestPage />}
          </DoctorAccessToPatientRecordBarier>
        }
      />
    </Routes>
  );
}

type EmptyProps = {};

type DoctorPatientPersonalInfoProps = {
  patient: Patient;
};

type DoctorPatientMedicalRecordsProps = {
  patientId: string;
};

const HomePage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "home-page" */ "Home").then(
      (res) => res.HomePage
    ),
});

const OurDoctorsPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "our-doctors" */ "OurDoctors").then(
      (res) => res.OurDoctorsPage
    ),
});

const LoginHomePage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "login" */ "Home").then(
      (res) => res.LoginHomePage
    ),
});

const RegistrationHomePage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "registration" */ "Home").then(
      (res) => res.RegistrationHomePage
    ),
});

const PatientLoginPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "patient-login" */ "Patient/Auth").then(
      (def) => def.PatientLoginPage
    ),
});

const PatientRegistrationPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "patient-registration" */ "Patient/Auth").then(
      (res) => res.PatientRegistrationPage
    ),
});

const DoctorLoginPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "doctor-login" */ "Doctor/Auth").then(
      (res) => res.LoginPage
    ),
});

const DoctorRegistrationPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "doctor-registration" */ "Doctor/Auth").then(
      (res) => res.RegistrationPage
    ),
});

const FAQPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "faq" */ "FAQ").then((res) => res.FAQPage),
});

const SupportPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "contact" */ "Support").then(
      (res) => res.SupportPage
    ),
});

const AboutPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "about" */ "About").then(
      (res) => res.AboutPage
    ),
});

const CookiePolicyPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "policies" */ "Policies").then(
      (res) => res.CookiePolicyPage
    ),
});

const NonDiscriminationPolicyPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "policies" */ "Policies").then(
      (res) => res.NonDiscriminationPolicyPage
    ),
});

const PrivacyPolicyPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "policies" */ "Policies").then(
      (res) => res.PrivacyPolicyPage
    ),
});

const TermsOfUsePage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "policies" */ "Policies").then(
      (res) => res.TermsOfUsePage
    ),
});

const DMCAPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "policies" */ "Policies").then(
      (res) => res.DMCAPage
    ),
});

const PatientCreateAccountPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(
      /* webpackChunkName: "patient-create-account" */ "Patient/Auth"
    ).then((res) => res.CreateAccountPage),
});

const PatientHomePage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "patient-profile" */ "Patient/Profile").then(
      (res) => res.HomePage
    ),
});

const PatientTalkToDoctorPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "patient-profile" */ "Patient/Profile").then(
      (res) => res.TalkToDoctorPage
    ),
});

const PatientWaitingRoomPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "patient-profile" */ "Patient/Profile").then(
      (res) => res.WaitingRoomPage
    ),
});

const PatientVisitsHistoryPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "patient-profile" */ "Patient/Profile").then(
      (res) => res.VisitsHistoryPage
    ),
});

const PatientProfileSettings = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "patient-profile" */ "Patient/Profile").then(
      (res) => res.PatientProfileSettings
    ),
});

const PatientNotificationsCenter = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "patient-profile" */ "Patient/Profile").then(
      (res) => res.NotificationsCenter
    ),
});

const PatientSupportPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "patient-profile" */ "Patient/Profile").then(
      (res) => res.SupportPage
    ),
});

const PatientFAQPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "patient-profile" */ "Patient/Profile").then(
      (res) => res.FAQPage
    ),
});

const PatientPersonalInformationPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(
      /* webpackChunkName: "patient-medical-records" */ "Patient/Profile"
    ).then((res) => res.PersonalInformationPage),
});

const PatientCaseHistoryList = loadOnDemand<EmptyProps>({
  loader: () =>
    import(
      /* webpackChunkName: "patient-medical-records" */ "Patient/Profile"
    ).then((res) => res.CaseHistoriesList),
});

const PatientAddCaseHistory = loadOnDemand<EmptyProps>({
  loader: () =>
    import(
      /* webpackChunkName: "patient-medical-records" */ "Patient/Profile"
    ).then((res) => res.AddCaseHistory),
});

const PatientVaccinationList = loadOnDemand<EmptyProps>({
  loader: () =>
    import(
      /* webpackChunkName: "patient-medical-records" */ "Patient/Profile"
    ).then((res) => res.VaccinationList),
});

const PatientAddVacine = loadOnDemand<EmptyProps>({
  loader: () =>
    import(
      /* webpackChunkName: "patient-medical-records" */ "Patient/Profile"
    ).then((res) => res.AddVaccine),
});

const PatientSurgeriesList = loadOnDemand<EmptyProps>({
  loader: () =>
    import(
      /* webpackChunkName: "patient-medical-records" */ "Patient/Profile"
    ).then((res) => res.SurgeriesList),
});

const PatientAddSurgery = loadOnDemand<EmptyProps>({
  loader: () =>
    import(
      /* webpackChunkName: "patient-medical-records" */ "Patient/Profile"
    ).then((res) => res.AddSurgery),
});

const PatientConclusionsList = loadOnDemand<EmptyProps>({
  loader: () =>
    import(
      /* webpackChunkName: "patient-medical-records" */ "Patient/Profile"
    ).then((res) => res.ConclusionsList),
});

const PatientHealthMetricsPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(
      /* webpackChunkName: "patient-medical-records" */ "Patient/Profile"
    ).then((res) => res.HealthMetricsPage),
});

const PatientFamilyDiseases = loadOnDemand<EmptyProps>({
  loader: () =>
    import(
      /* webpackChunkName: "patient-medical-records" */ "Patient/Profile"
    ).then((res) => res.FamilyDiseasesPage),
});

const PatientProceduresList = loadOnDemand<EmptyProps>({
  loader: () =>
    import(
      /* webpackChunkName: "patient-medical-records" */ "Patient/Profile"
    ).then((res) => res.ProceduresList),
});

const PatientAddProcedure = loadOnDemand<EmptyProps>({
  loader: () =>
    import(
      /* webpackChunkName: "patient-medical-records" */ "Patient/Profile"
    ).then((res) => res.AddProcedure),
});

const PatientPharmacyPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(
      /* webpackChunkName: "patient-medical-records" */ "Patient/Profile"
    ).then((res) => res.PharmacyPage),
});

const PatientLifestylePage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(
      /* webpackChunkName: "patient-medical-records" */ "Patient/Profile"
    ).then((res) => res.LifestylePage),
});

const PatientMedicineList = loadOnDemand<EmptyProps>({
  loader: () =>
    import(
      /* webpackChunkName: "patient-medical-records" */ "Patient/Profile"
    ).then((res) => res.MedicineList),
});

const PatientAddMedicine = loadOnDemand<EmptyProps>({
  loader: () =>
    import(
      /* webpackChunkName: "patient-medical-records" */ "Patient/Profile"
    ).then((res) => res.AddMedicine),
});

const PatientLabTestsList = loadOnDemand<EmptyProps>({
  loader: () =>
    import(
      /* webpackChunkName: "patient-medical-records" */ "Patient/Profile"
    ).then((res) => res.LabTestsList),
});

const PatientAddLabTestPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(
      /* webpackChunkName: "patient-medical-records" */ "Patient/Profile"
    ).then((res) => res.AddLabTestPage),
});

const PatientVirtualVisitPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(
      /* webpackChunkName: "patient-virtual-visit" */ "Patient/VirtualVisit"
    ).then((res) => res.VirtualVisitPage),
});

const DoctorCreateAccountPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "doctor-create-account" */ "Doctor/Auth").then(
      (res) => res.CreateAccountPage
    ),
});

const DoctorProfileHomePage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "doctor-profile" */ "Doctor/Profile").then(
      (res) => res.ProfileHomePage
    ),
});

const DoctorProfileSettingsPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "doctor-profile" */ "Doctor/Profile").then(
      (res) => res.ProfileSettingsPage
    ),
});

const DoctorWaitingRoomPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "doctor-profile" */ "Doctor/Profile").then(
      (res) => res.WaitingRoomPage
    ),
});

const DoctorVisitsHistoryPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "doctor-profile" */ "Doctor/Profile").then(
      (res) => res.VisitsHistoryPage
    ),
});

const DoctorEditProfilePage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "doctor-profile" */ "Doctor/Profile").then(
      (res) => res.ProfilePage
    ),
});

const DoctorSupportPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "doctor-profile" */ "Doctor/Profile").then(
      (res) => res.SupportPage
    ),
});

const DoctorFAQPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "doctor-profile" */ "Doctor/Profile").then(
      (res) => res.FAQPage
    ),
});

const DoctorNotificationsCenter = loadOnDemand<EmptyProps>({
  loader: () =>
    import(/* webpackChunkName: "doctor-profile" */ "Doctor/Profile").then(
      (res) => res.NotificationsCenter
    ),
});

const DoctorPatientRecordPersonalInformationPage =
  loadOnDemand<DoctorPatientPersonalInfoProps>({
    loader: () =>
      import(
        /* webpackChunkName: "doctor-medical-records" */ "Doctor/Profile"
      ).then((res) => res.PatientRecordPersonalInformationPage),
  });

const DoctorPatientRecordCaseHistoryPage =
  loadOnDemand<DoctorPatientMedicalRecordsProps>({
    loader: () =>
      import(
        /* webpackChunkName: "doctor-medical-records" */ "Doctor/Profile"
      ).then((res) => res.PatientRecordCaseHistoryPage),
  });

const DoctorPatientRecordVaccinationPage =
  loadOnDemand<DoctorPatientMedicalRecordsProps>({
    loader: () =>
      import(
        /* webpackChunkName: "doctor-medical-records" */ "Doctor/Profile"
      ).then((res) => res.PatientRecordVaccinationPage),
  });

const DoctorPatientRecordConclusionsListPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(
      /* webpackChunkName: "doctor-medical-records" */ "Doctor/Profile"
    ).then((res) => res.PatientRecordConclusionsListPage),
});

const DoctorPatientRecordAddConclusionPage =
  loadOnDemand<DoctorPatientMedicalRecordsProps>({
    loader: () =>
      import(
        /* webpackChunkName: "doctor-medical-records" */ "Doctor/Profile"
      ).then((res) => res.PatientRecordAddConclusionPage),
  });

const DoctorPatientRecordHealthMetricsPage =
  loadOnDemand<DoctorPatientMedicalRecordsProps>({
    loader: () =>
      import(
        /* webpackChunkName: "doctor-medical-records" */ "Doctor/Profile"
      ).then((res) => res.PatientRecordHealthMetricsPage),
  });

const DoctorPatientRecordFamilyDiseasesPage =
  loadOnDemand<DoctorPatientMedicalRecordsProps>({
    loader: () =>
      import(
        /* webpackChunkName: "doctor-medical-records" */ "Doctor/Profile"
      ).then((res) => res.PatientRecordFamilyDiseasesPage),
  });

const DoctorPatientRecordProceduresPage =
  loadOnDemand<DoctorPatientMedicalRecordsProps>({
    loader: () =>
      import(
        /* webpackChunkName: "doctor-medical-records" */ "Doctor/Profile"
      ).then((res) => res.PatientRecordProceduresPage),
  });

const DoctorPatientRecordPharmacyPage =
  loadOnDemand<DoctorPatientMedicalRecordsProps>({
    loader: () =>
      import(
        /* webpackChunkName: "doctor-medical-records" */ "Doctor/Profile"
      ).then((res) => res.PatientRecordPharmacyPage),
  });

const DoctorPatientRecordLifestylePage =
  loadOnDemand<DoctorPatientMedicalRecordsProps>({
    loader: () =>
      import(
        /* webpackChunkName: "doctor-medical-records" */ "Doctor/Profile"
      ).then((res) => res.PatientRecordLifestylePage),
  });

const DoctorPatientRecordMedicinePage =
  loadOnDemand<DoctorPatientMedicalRecordsProps>({
    loader: () =>
      import(
        /* webpackChunkName: "doctor-medical-records" */ "Doctor/Profile"
      ).then((res) => res.PatientRecordMedicinePage),
  });

const DoctorPatientRecordSurgeriesListPage =
  loadOnDemand<DoctorPatientMedicalRecordsProps>({
    loader: () =>
      import(
        /* webpackChunkName: "doctor-medical-records" */ "Doctor/Profile"
      ).then((res) => res.PatientRecordSurgeriesListPage),
  });

const DoctorPatientRecordLabTestsListPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(
      /* webpackChunkName: "doctor-medical-records" */ "Doctor/Profile"
    ).then((res) => res.PatientRecordLabTestsListPage),
});

const DoctorPatientRecordAddLabTestPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(
      /* webpackChunkName: "doctor-medical-records" */ "Doctor/Profile"
    ).then((res) => res.PatientRecordAddLabTestPage),
});

const DoctorVirtualVisitPage = loadOnDemand<EmptyProps>({
  loader: () =>
    import(
      /* webpackChunkName: "doctor-virtual-visit" */ "Doctor/VirtualVisit"
    ).then((res) => res.VirtualVisitPage),
});

function RouteWithAuthRedirection() {
  const loggedInPatient = usePatient();
  const loggedInDoctor = useDoctor();
  let redirectionURL: string | undefined = undefined;
  // By design, system redirects a logged in user to account home page, when navigating to the root page
  // Reason: the cover side of the app is for the demo purpose. Authenticated user has the same functionality in his account
  if (loggedInPatient && loggedInPatient.hasAccount) {
    redirectionURL = toPatientProfilHome(loggedInPatient.id);
  }
  if (loggedInDoctor && loggedInDoctor.hasAccount) {
    redirectionURL = toDoctorProfileHomePage(loggedInDoctor.id);
  }
  return redirectionURL ? (
    <Navigate
      to={{
        pathname: redirectionURL,
      }}
      replace
    />
  ) : (
    <Outlet />
  );
}
