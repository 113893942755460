import styled from "@emotion/styled";
import {
  differenceInDays,
  differenceInMinutes,
  differenceInYears,
  parse,
  parseISO,
} from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";
import { useDoctor } from "Doctor/Auth";
import { ISO8601 } from "Doctor/Model";
import {
  toDoctorPatientRecordHome,
  toDoctorVirtualVisitPage,
} from "Doctor/Router";
import { useStore } from "effector-react";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentTimezoneIANA } from "shared-functions/date";
import {
  AutoUpdates,
  Avatar,
  Block,
  BoxWithShadow,
  Button,
  ChevronLeftIcon,
  color,
  Flex,
  Grid,
  Loader,
  TooltipWindow,
  Typography,
  UnifiedLink,
} from "ui-kit";
import { Visit } from "Visit";
import { $accessController, checkAccess } from "../PatientRecord/access";
import defaultAvatar from "./avatar-default.jpg";
import { loadVisits } from "./list";

type Props = {
  visitsList: Visit[];
  hideConnectionControls?: boolean;
  sorting: "asc" | "desc";
};

export const VisitsList = memo(
  ({ visitsList, hideConnectionControls, sorting }: Props) => {
    const isLoading = useStore(loadVisits.pending);
    const { accessMap, isAccessDataLoaded } = useStore($accessController);
    const doctor = useDoctor();

    useEffect(() => {
      if (doctor?.id && !isLoading) {
        const uniquePatientsList = Array.from(
          new Set(visitsList.map((visit) => visit.patient.id))
        );
        checkAccess({
          doctorId: doctor.id,
          patientIdsList: [...uniquePatientsList],
        });
      }
    }, [doctor?.id, isLoading, visitsList]);

    if (isLoading) {
      return (
        <Block textAlign="center">
          <Loader />
        </Block>
      );
    }

    const groupedVisits = groupVisitsWithSameDate(visitsList);
    return (
      <>
        {Object.entries(groupedVisits)
          .sort(
            sorting === "asc"
              ? sortVisitsGroupByDateAsc
              : sortVisitsGroupByDateDesc
          )
          .map(([ISODate, visitsList]) => (
            <Block key={ISODate} marginBottom={4}>
              <Block marginBottom={3} textAlign="center">
                <Typography type="h2">
                  {format(
                    parse(ISODate, "yyyy-MM-dd", Date.nowUniversal),
                    "MMMM dd, yyyy"
                  )}
                </Typography>
                &nbsp;
                <Typography fontColor="N300" type="h2">
                  {format(
                    parse(ISODate, "yyyy-MM-dd", Date.nowUniversal),
                    "EEEE"
                  )}
                </Typography>
              </Block>
              {visitsList
                .sort(
                  sorting === "asc"
                    ? sortVisitsInsideGroupByDateAsc
                    : sortVisitsInsideGroupByDateDesc
                )
                .map((visit) => (
                  <Block marginBottom={2} key={visit.id}>
                    <SingleVisit
                      visit={visit}
                      hasAccessToPatientRecord={
                        isAccessDataLoaded && accessMap[visit.patient.id]
                      }
                      hideConnectionControls={hideConnectionControls}
                    />
                  </Block>
                ))}
            </Block>
          ))}
      </>
    );
  }
);

type VisitProps = {
  visit: Visit;
  hasAccessToPatientRecord?: boolean;
  hideConnectionControls?: boolean;
};

const DETAILS_HEIGHT = 38;

const SingleVisit = memo(
  ({
    hasAccessToPatientRecord,
    hideConnectionControls,
    visit: { id: visitId, patient, doctor, description, timeSlot },
  }: VisitProps) => {
    const aboutContainerRef = useRef<HTMLDivElement>(null);
    const [areDetailsVisible, setViewDetails] = useState<boolean>(false);
    const [displayViewDetailsBtn, setViewDetailsBtn] = useState(
      // DETAILS_HEIGHT equals to one row. So to prevent single rowed text to have view details,
      // added +4 delta pixels (it's an empirical value)
      aboutContainerRef.current
        ? aboutContainerRef.current.clientHeight > DETAILS_HEIGHT + 4
        : false
    );

    useEffect(() => {
      setViewDetailsBtn(
        aboutContainerRef.current
          ? aboutContainerRef.current.clientHeight > DETAILS_HEIGHT + 4
          : false
      );
    }, []);

    // By design, joining visit room is available in 30 minutes before visit
    // Don't remember why such restriction is needed, looks nonsense
    const shouldDisplayJoinVisit = () => {
      const VISIT_OPENING_TIME = 30;
      return (
        differenceInMinutes(new Date(timeSlot.from), Date.nowUniversal) <=
        VISIT_OPENING_TIME
      );
    };
    const [visitOpened, setVisitOpened] = useState(shouldDisplayJoinVisit());

    const navigate = useNavigate();
    const goToPatientRecord = useCallback(() => {
      navigate(
        toDoctorPatientRecordHome({
          doctorId: doctor.id,
          patientId: patient.id,
        })
      );
    }, [doctor.id, patient.id, navigate]);

    return (
      <VisitContainer gridTemplateColumns="142px auto 197px">
        <AvatarImg height="142px" position="relative" width="142px">
          <Img
            onClick={goToPatientRecord}
            src={patient.personalInformation?.photo?.url || defaultAvatar}
            alt="Doctor's avatar"
          />
        </AvatarImg>
        <PatientInfoContainer>
          <Block marginBottom={0.5}>
            {hasAccessToPatientRecord ? (
              <UnifiedLink
                to={toDoctorPatientRecordHome({
                  doctorId: doctor.id,
                  patientId: patient.id,
                })}
                type="h2"
              >
                {patient.personalInformation?.title && (
                  <TitleLabel>{patient.personalInformation?.title}.</TitleLabel>
                )}
                {patient.personalInformation?.title && "\u00A0"}
                {patient.personalInformation?.firstName}{" "}
                {patient.personalInformation?.lastName}
              </UnifiedLink>
            ) : (
              <>
                <DisabledLink type="h2" data-tooltip-id={patient.id}>
                  {patient.personalInformation?.title && (
                    <TitleLabel>
                      {patient.personalInformation?.title}.
                    </TitleLabel>
                  )}
                  {patient.personalInformation?.title && "\u00A0"}
                  {patient.personalInformation?.firstName}{" "}
                  {patient.personalInformation?.lastName}
                </DisabledLink>
                <TooltipWindow id={patient.id} place="top" clickable>
                  By PocketDoctor policy, an access to the card is closed after
                  three days from the end of the last visit with the patient
                </TooltipWindow>
              </>
            )}
          </Block>
          <Block marginBottom={1.5}>
            <Subtitle fontColor="N300" type="h3">
              Gender:&nbsp;
              {patient.personalInformation?.gender}
            </Subtitle>
            <Subtitle fontColor="N300" type="h3">
              Age:&nbsp;
              {differenceInYears(
                Date.nowUniversal,
                new Date(patient.personalInformation!.DOB)
              )}
            </Subtitle>
          </Block>
          {description && (
            <DetailsContainer shrinked={!areDetailsVisible}>
              <div ref={aboutContainerRef}>
                <Typography fontColor="N300" type="h5">
                  {description}
                </Typography>
              </div>
            </DetailsContainer>
          )}
          {displayViewDetailsBtn && (
            <Block marginTop={1}>
              <ViewDetailsBtn
                type="button"
                onClick={() => {
                  setViewDetails(!areDetailsVisible);
                }}
              >
                <Typography fontColor="G200" type="h4">
                  {areDetailsVisible ? "Hide message" : "View message"}
                </Typography>
                <ViewDetailsArrow
                  opened={areDetailsVisible}
                  stroke={color.G200}
                  height="10px"
                />
              </ViewDetailsBtn>
            </Block>
          )}
        </PatientInfoContainer>
        <Flex
          alignItems="center"
          flexDirection="column"
          justifyContent={hideConnectionControls ? "center" : "flex-start"}
          paddingLeft={6}
          paddingRight={6}
        >
          <Block>
            <Typography
              fontColor="G200"
              type="h2"
              fontSize="28px"
              lineHeight="21px"
            >
              {format(
                utcToZonedTime(timeSlot.from, getCurrentTimezoneIANA()),
                "hh:mm"
              )}
            </Typography>
            &nbsp;
            <Typography fontColor="N300" type="h5">
              {format(
                utcToZonedTime(timeSlot.from, getCurrentTimezoneIANA()),
                "a"
              )}
            </Typography>
          </Block>
          <Separator />
          <Block>
            <Typography
              fontColor="G200"
              type="h2"
              fontSize="28px"
              lineHeight="21px"
            >
              {format(
                utcToZonedTime(timeSlot.to, getCurrentTimezoneIANA()),
                "hh:mm"
              )}
            </Typography>
            &nbsp;
            <Typography fontColor="N300" type="h5">
              {format(
                utcToZonedTime(timeSlot.to, getCurrentTimezoneIANA()),
                "a"
              )}
            </Typography>
          </Block>
          {!hideConnectionControls && (
            <Block marginTop={3.5}>
              <AutoUpdates
                interval={60}
                whenStop={shouldDisplayJoinVisit}
                onStop={() => {
                  setVisitOpened(true);
                }}
              />
              <Block position="relative">
                <Button
                  filled
                  disabled={!visitOpened}
                  type="button"
                  size="small"
                  onClick={() =>
                    navigate(toDoctorVirtualVisitPage(doctor.id, visitId))
                  }
                >
                  Join visit
                </Button>
                {!visitOpened && (
                  <>
                    <TooltipWindow
                      id={`join-visit-${visitId}`}
                      place="bottom"
                      clickable
                    >
                      You can join the visit 30 minutes before the start.
                    </TooltipWindow>
                    <JoinHint data-tooltip-id={`join-visit-${visitId}`} />
                  </>
                )}
              </Block>
            </Block>
          )}
        </Flex>
      </VisitContainer>
    );
  }
);

type VisitsGroupedByDate = {
  [key in ISO8601]: Visit[];
};

function groupVisitsWithSameDate(visitsList: Visit[]): VisitsGroupedByDate {
  return visitsList.reduce((visitsGroupedByDate, visit) => {
    const ISODateKey = format(new Date(visit.timeSlot.from), "yyyy-MM-dd");
    return {
      ...visitsGroupedByDate,
      [ISODateKey]: [...(visitsGroupedByDate[ISODateKey] || []), visit],
    };
  }, {} as VisitsGroupedByDate);
}

function sortVisitsGroupByDateAsc(
  visitsGroupA: [ISO8601, Visit[]],
  visitsGroupB: [ISO8601, Visit[]]
) {
  return differenceInDays(parseISO(visitsGroupA[0]), parseISO(visitsGroupB[0]));
}

function sortVisitsGroupByDateDesc(
  visitsGroupA: [ISO8601, Visit[]],
  visitsGroupB: [ISO8601, Visit[]]
) {
  return differenceInDays(parseISO(visitsGroupB[0]), parseISO(visitsGroupA[0]));
}

function sortVisitsInsideGroupByDateAsc(visitA: Visit, visitB: Visit) {
  return differenceInMinutes(
    parseISO(visitA.timeSlot.from),
    parseISO(visitB.timeSlot.from)
  );
}

function sortVisitsInsideGroupByDateDesc(visitA: Visit, visitB: Visit) {
  return differenceInMinutes(
    parseISO(visitB.timeSlot.from),
    parseISO(visitA.timeSlot.from)
  );
}

const VisitContainer = styled(Grid)`
  padding: 24px 0 24px 48px;
`.withComponent(BoxWithShadow);

const Img = styled.img`
  cursor: pointer;
  display: inline-block;
`;

const AvatarImg = styled(Avatar)`
  & img {
    border: none;
  }
`;

const PatientInfoContainer = styled(Block)`
  border-right: 1px solid ${color.N200};
  padding-left: 24px;
  padding-right: 24px;
`;

const DisabledLink = styled(Typography)`
  cursor: not-allowed;
  text-decoration: underline;
`;

const Subtitle = styled(Typography)`
  border-right: 1px solid ${color.N300};
  padding-left: 12px;
  padding-right: 12px;
  &:first-of-type {
    padding-left: 0;
  }
  &:last-of-type {
    border-right: none;
    padding-right: 0;
  }
`;

const Separator = styled(Block)`
  background-color: ${color.N200};
  height: 1px;
  margin: 16px auto 16px 28px;
  width: 19px;
`;

const TitleLabel = styled.span`
  text-transform: capitalize;
`;

type DetailsState = {
  shrinked: boolean;
};

const DetailsContainer = styled(Block)<DetailsState>`
  max-height: ${({ shrinked }) => (shrinked ? `${DETAILS_HEIGHT}px` : "none")};
  overflow: hidden;
`;

const ViewDetailsBtn = styled.button`
  all: unset;
  cursor: pointer;
  &:hover span,
  &:focus span {
    color: ${color.G300};
  }
`;

const ViewDetailsArrow = styled(ChevronLeftIcon, {
  shouldForwardProp: (prop) => prop !== "opened",
})<{
  opened: boolean;
}>`
  margin-left: 4px;
  transform: ${({ opened }) => `rotate(${opened ? "90deg" : "270deg"})`};
`;

const JoinHint = styled.span`
  cursor: not-allowed;
  display: inline-block;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
`;
