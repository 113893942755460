import { apiServer } from "apiConnection";
import { createEffect, createStore } from "effector";
import { successNotification } from "ui-kit";
import { conclusionRecordAdapter } from "./DTOAdapter";
import { Conclusion, ConclusionDTO } from "./typeDefinitions";

export const loadConclusions = createEffect("loadConclusions", {
  handler: async (patientId: string) => await getConclusions(patientId),
});

export const addConclusion = createEffect("addConclusion", {
  handler: async ({
    patientId,
    diseaseName,
    prescription,
    rehabilitation,
    medicalExaminations,
  }: {
    patientId: string;
    diseaseName: string;
    prescription?: string;
    rehabilitation?: string;
    medicalExaminations?: string;
  }) =>
    await createConclusion({
      patientId,
      diseaseName,
      prescription,
      rehabilitation,
      medicalExaminations,
    }),
});

addConclusion.doneData.watch(() => {
  successNotification("Conclusion was added");
});

export const editConclusion = createEffect("editConclusion", {
  handler: async ({
    patientId,
    recordId,
    diseaseName,
    prescription,
    rehabilitation,
    medicalExaminations,
  }: {
    patientId: string;
    recordId: string;
    diseaseName: string;
    prescription?: string;
    rehabilitation?: string;
    medicalExaminations?: string;
  }) =>
    await updateConclusion({
      patientId,
      recordId,
      diseaseName,
      prescription,
      rehabilitation,
      medicalExaminations,
    }),
});

editConclusion.doneData.watch(() => {
  successNotification("Conclusion was updated");
});

export const deleteConclusion = createEffect("deleteConclusion", {
  handler: async ({
    patientId,
    recordId,
  }: {
    patientId: string;
    recordId: string;
  }) => await removeConclusion({ patientId, recordId }),
});

deleteConclusion.doneData.watch(() => {
  successNotification("Conclusion was removed");
});

async function getConclusions(patientId: string) {
  const { data: vaccineDTO } = await apiServer.get<ConclusionDTO[]>(
    `/api/patient/${patientId}/conclusions`
  );
  return vaccineDTO;
}

async function createConclusion({
  patientId,
  diseaseName,
  prescription,
  rehabilitation,
  medicalExaminations,
}: {
  patientId: string;
  diseaseName: string;
  prescription?: string;
  rehabilitation?: string;
  medicalExaminations?: string;
}) {
  const { data: createdRecord } = await apiServer.post<ConclusionDTO>(
    `/api/patient/${patientId}/conclusions`,
    {
      diseaseName,
      prescription,
      rehabilitation,
      medicalExaminations,
    }
  );
  return createdRecord;
}

async function updateConclusion({
  patientId,
  recordId,
  diseaseName,
  prescription,
  rehabilitation,
  medicalExaminations,
}: {
  patientId: string;
  recordId: string;
  diseaseName: string;
  prescription?: string;
  rehabilitation?: string;
  medicalExaminations?: string;
}) {
  const { data: updatedRecord } = await apiServer.post<ConclusionDTO>(
    `/api/patient/${patientId}/conclusions/${recordId}`,
    {
      diseaseName,
      prescription,
      rehabilitation,
      medicalExaminations,
    }
  );
  return updatedRecord;
}

async function removeConclusion({
  patientId,
  recordId,
}: {
  patientId: string;
  recordId: string;
}) {
  const { data: removedRecord } = await apiServer.delete<{ id: string }>(
    `/api/patient/${patientId}/conclusions/${recordId}`
  );
  return removedRecord.id;
}

export const conclusionsList = createStore<Conclusion[]>([])
  .on(loadConclusions.doneData, (state, conclusions) =>
    conclusions.map(conclusionRecordAdapter)
  )
  .on(addConclusion.doneData, (state, conclustionDTO) => [
    ...state,
    conclusionRecordAdapter(conclustionDTO),
  ])
  .on(editConclusion.doneData, (state, conclusionDTO) =>
    state.map((record) =>
      record.id === conclusionDTO.objectId
        ? conclusionRecordAdapter(conclusionDTO)
        : record
    )
  )
  .on(deleteConclusion.doneData, (state, removedId) =>
    state.filter((record) => record.id !== removedId)
  );
