import { loginHomePage, registrationHomePage } from "Home/routes";

export const patientLoginPage = `${loginHomePage}/patient`;
export const patientRegistrationPage = `${registrationHomePage}/patient`;

export const patientProfileHome = "/patient/:patientId";
export const toPatientProfilHome = (patientID: string) =>
  `/patient/${patientID}`;

export const patientProfileCreateAccount = `${patientProfileHome}/create-account`;
export const toCreateAccount = (patientID: string) =>
  `${toPatientProfilHome(patientID)}/create-account`;

export const talkToDoctor = "/patient/:patientId/talk-to-doctor";
export const toTalkToDoctor = (patientID: string) =>
  `${toPatientProfilHome(patientID)}/talk-to-doctor`;

export const patientProfileWaitingRoom = `${patientProfileHome}/waiting-room`;
export const toWaitingRoom = (patientID: string) =>
  `${toPatientProfilHome(patientID)}/waiting-room`;

export const patientProfileVisitsHistory = `${patientProfileHome}/visits-history`;
export const toVisitsHistory = (patientID: string) =>
  `${toPatientProfilHome(patientID)}/visits-history`;

export const patientVirtualVisitPage = `${patientProfileHome}/visit/:visitId`;
export const toPatientVirtualVisitPage = (patientID: string, visitID: string) =>
  `${toPatientProfilHome(patientID)}/visit/${visitID}`;

export const toPatientFAQ = (patientID: string) =>
  `${toPatientProfilHome(patientID)}/faq`;

export const toPatientSupport = (patientID: string) =>
  `${toPatientProfilHome(patientID)}/support`;

export const patientProfileSettings = `${patientProfileHome}/settings`;
export const toPatientProfileSettings = (patientID: string) =>
  `${toPatientProfilHome(patientID)}/settings`;

export const patientProfileNotifications = `${patientProfileHome}/notifications`;
export const toPatientProfileNotifications = (patientID: string) =>
  `${toPatientProfilHome(patientID)}/notifications`;

export const patientProfileSupport = `${patientProfileHome}/support`;
export const toPatientProfileSupport = (patientID: string) =>
  `${toPatientProfilHome(patientID)}/support`;

export const patientProfileFAQ = `${patientProfileHome}/faq`;
export const toPatientProfileFAQ = (patientID: string) =>
  `${toPatientProfilHome(patientID)}/faq`;

/**
 * My record
 */
const patientProfileRecord = `${patientProfileHome}/my-record`;
export const toMyRecord = (patientID: string) =>
  `${toPatientProfilHome(patientID)}/my-record`;

export const personalInformation = `${patientProfileRecord}/personal-information`;
export const toPersonalInformation = (patientID: string) =>
  `${toMyRecord(patientID)}/personal-information`;

export const caseHistory = `${patientProfileRecord}/case-history`;
export const toCaseHistory = (patientID: string) =>
  `${toMyRecord(patientID)}/case-history`;

export const addCaseHistory = `${patientProfileRecord}/case-history/add`;
export const toAddCaseHistory = (patientID: string) =>
  `${toMyRecord(patientID)}/case-history/add`;

export const vaccination = `${patientProfileRecord}/vaccination`;
export const toVaccination = (patientID: string) =>
  `${toMyRecord(patientID)}/vaccination`;

export const addVaccination = `${patientProfileRecord}/vaccination/add`;
export const toAddVaccination = (patientID: string) =>
  `${toMyRecord(patientID)}/vaccination/add`;

export const surgeries = `${patientProfileRecord}/surgeries`;
export const toSurgeries = (patientID: string) =>
  `${toMyRecord(patientID)}/surgeries`;

export const addSurgery = `${patientProfileRecord}/surgeries/add`;
export const toAddSurgery = (patientID: string) =>
  `${toMyRecord(patientID)}/surgeries/add`;

export const conclusions = `${patientProfileRecord}/conclusions`;
export const toConclusions = (patientID: string) =>
  `${toMyRecord(patientID)}/conclusions`;

export const healthMetrics = `${patientProfileRecord}/health-metrics`;
export const toHealthMetrics = (patientID: string) =>
  `${toMyRecord(patientID)}/health-metrics`;

export const familyDiseases = `${patientProfileRecord}/family-diseases`;
export const toFamilyDiseases = (patientID: string) =>
  `${toMyRecord(patientID)}/family-diseases`;

export const procedures = `${patientProfileRecord}/procedures`;
export const toProcedures = (patientID: string) =>
  `${toMyRecord(patientID)}/procedures`;

export const addProcedure = `${patientProfileRecord}/procedures/add`;
export const toAddProcedure = (patientID: string) =>
  `${toMyRecord(patientID)}/procedures/add`;

export const pharmacy = `${patientProfileRecord}/pharmacy`;
export const toPharmacy = (patientID: string) =>
  `${toMyRecord(patientID)}/pharmacy`;

export const lifestyle = `${patientProfileRecord}/lifestyle`;
export const toLifestyle = (patientID: string) =>
  `${toMyRecord(patientID)}/lifestyle`;

export const medicine = `${patientProfileRecord}/medicine`;
export const toMedicine = (patientID: string) =>
  `${toMyRecord(patientID)}/medicine`;

export const addMedicine = `${patientProfileRecord}/medicine/add`;
export const toAddMedicine = (patientID: string) =>
  `${toMyRecord(patientID)}/medicine/add`;

export const labTests = `${patientProfileRecord}/lab-tests`;
export const toLabTests = (patientID: string) =>
  `${toMyRecord(patientID)}/lab-tests`;

export const addLabTest = `${patientProfileRecord}/lab-tests/add`;
export const toAddLabTest = (patientID: string) =>
  `${toMyRecord(patientID)}/lab-tests/add`;

export const AUTHENTICATED_ROUTES = [
  patientProfileCreateAccount,
  patientProfileHome,
  talkToDoctor,
  patientProfileWaitingRoom,
  patientProfileVisitsHistory,
  patientProfileSettings,
  patientProfileNotifications,
  patientProfileSupport,
  patientProfileFAQ,
  patientVirtualVisitPage,
  personalInformation,
  caseHistory,
  addCaseHistory,
  vaccination,
  addVaccination,
  surgeries,
  addSurgery,
  conclusions,
  healthMetrics,
  familyDiseases,
  procedures,
  addProcedure,
  pharmacy,
  lifestyle,
  medicine,
  addMedicine,
  labTests,
  addLabTest,
];
