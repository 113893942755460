import { apiServer } from "apiConnection";
import { StreamInput } from "./Model";

type TokenResponse = {
  token: string;
  meetingRoomName: string;
};

export async function getConnectionToken({ sessionName, userId }: StreamInput) {
  try {
    const response = await apiServer.post<TokenResponse>(
      "/api/stream/connection-token",
      {
        sessionName,
        userId,
      }
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error.message as string);
  }
}
