import { apiServer } from "apiConnection";
import { addSeconds } from "date-fns";
import { getCurrentTimezoneIANA } from ".";

/**
 * JS current Date() is dependent on user system date/time settings.
 * There can be a case when system time is shifted from real time.
 * Take datetime from an authoritative resource, thus remove dependency on user system time.
 */

export function initializeUniversalDateTime() {
  let _universalDateTime: Date = new Date();
  getCurrentDatetime().then(
    (universalDateTime) => (_universalDateTime = new Date(universalDateTime))
  );

  /**
   * When tab runs in background mode, setInterval freezes:
   * https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#reasons_for_delays_longer_than_specified
   * This affects a time when visit starts, how much time left before a visit
   * We rely on the precise time heavily, so need to re-sync time when user comes back to the tab
   */
  document.addEventListener("visibilitychange", function () {
    if (!document.hidden) {
      getCurrentDatetime().then(
        (universalDateTime) =>
          (_universalDateTime = new Date(universalDateTime))
      );
    }
  });

  window.setInterval(() => {
    _universalDateTime = addSeconds(_universalDateTime, 1);
  }, 1000);

  Object.defineProperties(Date, {
    nowUniversal: {
      get: function () {
        return _universalDateTime;
      },
    },
  });
}

type CurrentDateTime = {
  utc_datetime: string;
};

async function getCurrentDatetime(timezone: string = getCurrentTimezoneIANA()) {
  try {
    const {
      data: { utc_datetime },
    } = await apiServer.post<CurrentDateTime>("/api/session/datetime", {
      timezone,
    });
    return utc_datetime;
  } catch (error: any) {
    // use case: service of world time is down
    // local date as a fallback
    console.error(error);
    return new Date().toISOString();
  }
}
