import { useParams } from "react-router-dom";
import { useDoctor } from "./doctorSession";

/**
 * Authentication in our case means that a doctor has an active session
 * AND doctor's id in URL matches the real doctor's id
 **/
export function useAuthenticatedDoctor() {
  const doctor = useDoctor();
  const URLParams = useParams();
  return doctor !== undefined && doctor.id === URLParams.doctorId
    ? doctor
    : null;
}
