import styled from "@emotion/styled";
import { useStore } from "effector-react";
import { homePage } from "Home/routes";
import { patientLoginPage, toCreateAccount } from "Patient/routes";
import { privacyPolicyURL, termsOfUseURL } from "Policies";
import { memo, useCallback } from "react";
import { Helmet } from "react-helmet";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  hasDigit,
  hasLowercaseCharacter,
  hasMoreThan8Symbols,
  hasSpecialCharacter,
  hasUppercaseCharacter,
} from "shared-functions/strongPasswordValidation";
import {
  Block,
  Button,
  Checkbox,
  color,
  errorNotification,
  Flex,
  Grid,
  HeroBanner,
  Input,
  LoaderWithOverlay,
  Logo,
  PasswordInput,
  Typography,
  UnifiedLink,
} from "ui-kit";
import registrationBg from "./login-page.jpg";
import { signUp } from "./patientSession";

type Credentials = {
  email: string;
  password: string;
  passwordConfirmation: string;
  isAgree: boolean;
};

export const PatientRegistrationPage = memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<Credentials>();
  const isLoading = useStore(signUp.pending);

  const password = watch("password");

  const registerPatient: SubmitHandler<Credentials> = useCallback(
    ({ email, password }) => {
      if (isLoading) {
        return;
      }
      signUp({ email, password })
        .then(({ id: patientId }) => {
          navigate(toCreateAccount(patientId), {
            state: location.state,
          });
        })
        .catch((error: Error) => {
          errorNotification(error.message);
        });
    },
    [navigate, location.state, isLoading]
  );
  return (
    <Grid gridTemplateColumns="30% 70%" height="100vh">
      <Helmet>
        <title>Patient sign up</title>
      </Helmet>
      <RegistrationFormContainer>
        {isLoading && <LoaderWithOverlay />}
        <RegistrationForm
          flexDirection="column"
          justifyContent="space-between"
          width="100%"
        >
          <Block textAlign="left">
            <Link to={homePage}>
              <Logo fill={color.G200} width="188px" />
            </Link>
          </Block>
          <Flex flexGrow={2} alignItems="center">
            <RegistrationFormSection width="100%">
              <Block marginBottom={3} textAlign="center">
                <Typography as="h1" type="h2">
                  Sign up
                </Typography>
              </Block>
              <form onSubmit={handleSubmit(registerPatient)}>
                <Block marginBottom={3}>
                  <Input
                    autoFocus
                    label="Email"
                    type="email"
                    hasErrors={!!errors["email"]}
                    errorMessage={errors["email"]?.message}
                    placeholder="Email"
                    isRequired
                    {...register("email", {
                      required: "Please, enter email",
                    })}
                  />
                </Block>
                <Block marginBottom={3}>
                  <PasswordInput
                    label="Password"
                    hasErrors={!!errors["password"]}
                    errorMessage={errors["password"]?.message}
                    placeholder="Password"
                    enableSecurityCheck
                    isRequired
                    {...register("password", {
                      validate: (password) => isPasswordValid(password),
                    })}
                  />
                </Block>
                <Block marginBottom={3}>
                  <PasswordInput
                    label="Password confirmation"
                    hasErrors={!!errors["passwordConfirmation"]}
                    errorMessage={errors["passwordConfirmation"]?.message}
                    placeholder="Password confirmation"
                    isRequired
                    {...register("passwordConfirmation", {
                      validate: (confirmedPassword) =>
                        (isPasswordValid(password)
                          ? confirmedPassword === password
                          : true) || "Password doesn't match",
                    })}
                  />
                </Block>
                <Block marginBottom={4}>
                  <Checkbox
                    errorMessage={errors["isAgree"]?.message}
                    label={
                      <>
                        I agree to the{" "}
                        <UnifiedLink
                          target="_blank"
                          type="inherit"
                          to={termsOfUseURL}
                        >
                          terms of use
                        </UnifiedLink>{" "}
                        and{" "}
                        <UnifiedLink
                          target="_blank"
                          type="inherit"
                          to={privacyPolicyURL}
                        >
                          privacy policy
                        </UnifiedLink>
                      </>
                    }
                    {...register("isAgree", {
                      required:
                        "You have to agree with the terms of use and privacy policy",
                    })}
                  />
                </Block>
                <Block textAlign="center">
                  <Button filled>Sign up</Button>
                </Block>
              </form>
            </RegistrationFormSection>
          </Flex>
          <Block>
            <Typography type="h4">Already have an account?</Typography>{" "}
            <UnifiedLink to={patientLoginPage} type="h4">
              Log in
            </UnifiedLink>
          </Block>
        </RegistrationForm>
      </RegistrationFormContainer>
      <AdvantagesUnderlay enableOverlay image={registrationBg}>
        <AdvantagesSection
          gridTemplateColumns="100%"
          gridTemplateRows="max-content auto max-content"
        >
          <AdvantagesListContainer
            gridTemplateColumns="repeat(4, 1fr)"
            gridColumnGap={15.5}
            paddingLeft={3}
          >
            <Advantage type="h2" fontColor="N0">
              Security and privacy
            </Advantage>
            <Advantage type="h2" fontColor="N0">
              Certified doctors
            </Advantage>
            <Advantage type="h2" fontColor="N0">
              Easy to use system
            </Advantage>
            <Advantage type="h2" fontColor="N0">
              Money-back guarantee
            </Advantage>
          </AdvantagesListContainer>
          <Invitation>
            Sign up now and make an appointment with a doctor. <br /> Do not
            postpone your health problems for later.
          </Invitation>
          <Disclaimer paddingLeft={6}>
            <Typography fontSize="20px" fontColor="N0" fontWeight={400}>
              In the case of possible or actual emergency situations, do not use
              our service, but immediately call 911 or your local ambulance
              service.
            </Typography>
          </Disclaimer>
        </AdvantagesSection>
      </AdvantagesUnderlay>
    </Grid>
  );
});

function isPasswordValid(password: string = "") {
  return (
    hasLowercaseCharacter(password) &&
    hasUppercaseCharacter(password) &&
    hasSpecialCharacter(password) &&
    hasDigit(password) &&
    hasMoreThan8Symbols(password)
  );
}

const RegistrationFormContainer = styled(Flex)`
  background-color: ${color.N0};
  position: relative;
`;

const RegistrationForm = styled(Flex)`
  background-color: ${color.N0};
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  padding: 72px 72px 32px;
`;

const RegistrationFormSection = Block.withComponent("section");

const AdvantagesUnderlay = styled(HeroBanner)`
  height: 100%;
`;

const AdvantagesSection = styled(Grid.withComponent("section"))`
  height: 100%;
  align-content: space-between;
  padding: 72px 56px 32px 72px;
`;

const AdvantagesListContainer = styled(Grid)`
  grid-column: 1 / end;
  grid-row: 1 / 1;
`;

const Advantage = styled(Typography)`
  align-self: center;
  padding-left: 32px;
  position: relative;
  &::before {
    border-radius: 50%;
    background-color: ${color.G200};
    content: "";
    display: inline-block;
    left: 0;
    margin-top: -10px;
    top: 50%;
    position: absolute;
    height: 20px;
    width: 20px;
  }
`;

const Invitation = styled(Block)`
  align-self: center;
  color: ${color.N0};
  font-family: Roboto, Arial, sans-serif;
  font-size: 50px;
  font-weight: 700;
  grid-column: 1 / end;
  grid-row: 2 / 3;
  line-height: 72px;
  padding-left: 48px;
`;

const Disclaimer = styled(Block)`
  grid-column: 1 / end;
  grid-row: 3 / 3;
`;
