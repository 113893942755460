export const ROLE_DOCTOR = "doctor";

export type DoctorDTO = {
  objectId: string;
  email: string;
  hasAccount: boolean;
  onModeration?: boolean;
  personalInformation?: DoctorPersonalInformation;
  professionalInformation?: DoctorProfessionalInformation;
  price?: Price;
  role: typeof ROLE_DOCTOR;
  sessionToken?: string;
  license?: License;
  schedule?: Schedule;
  rating?: number;
  status: NetworkStatus;
  username: string;
  createdAt: ISO8601;
  updatedAt: ISO8601;
  teamMemberId?: string; // integration with Square
};

export type Model = {
  id: string;
  email: string;
  hasAccount: boolean;
  onModeration: boolean;
  personalInformation?: DoctorPersonalInformation;
  professionalInformation?: DoctorProfessionalInformation;
  price?: Price;
  role: typeof ROLE_DOCTOR;
  sessionToken?: string;
  license?: License;
  schedule?: Partial<Schedule>;
  rating?: number;
  status: NetworkStatus;
  teamMemberId?: string; // integration with Square
};

export type DoctorPersonalInformation = {
  photo?: {
    name: string;
    url: string;
  };
  firstName: string;
  lastName: string;
  DOB: ISO8601;
  phone?: string;
  about: string;
};

export type DoctorProfessionalInformation = {
  specialty: string;
  practiceStarted: string;
  language: Language[];
  education: DoctorEducation;
};

type DoctorEducation = {
  medicalSchool?: string;
  degree?: string;
  graduationYear?: string;
};

export type License = {
  number: string;
  country: string;
  state?: string;
  npi: string;
  expirationDate: ISO8601;
  documents?: LicenseDocument[];
};

export type LicenseRequest = {
  number: string;
  country: string;
  state?: string;
  npi: string;
  expirationDate: ISO8601;
  licenseDocuments?: File[];
};

export type LicenseDocument = {
  name: string;
  url: string;
};

export type WeekDay = {
  workTime: {
    from?: ISO8601;
    to?: ISO8601;
  };
  breakTime: {
    from?: ISO8601;
    to?: ISO8601;
  };
  timezone?: string;
};

export type Schedule = {
  // Despite we need only time, store it in ISO format for standartization
  monday: WeekDay;
  tuesday: WeekDay;
  wednesday: WeekDay;
  thursday: WeekDay;
  friday: WeekDay;
  saturday: WeekDay;
  sunday: WeekDay;
};

type NetworkStatus = "online" | "offline" | "busy";

export type Price = {
  amount: number;
  currency: Currency;
};

export type Currency = "USD";

export type Language = "en" | "es" | "zh" | "de" | "fr" | "ru";

export type ISO8601 = string;

export type DoctorProfileRouterParams = { doctorId: string };

export function userToDoctorAdapter(user: DoctorDTO): Model {
  if (user.role !== ROLE_DOCTOR) {
    throw new Error("User is not a doctor");
  }
  return {
    id: user.objectId,
    email: user.email,
    hasAccount: user.hasAccount || false,
    onModeration: user.onModeration || false,
    personalInformation: user.personalInformation,
    professionalInformation: user.professionalInformation,
    price: user.price,
    role: ROLE_DOCTOR,
    sessionToken: user.sessionToken,
    license: user.license,
    schedule: user.schedule,
    rating: user.rating,
    status: "online", // TODO: resolve status based on schedule of a doctor
    teamMemberId: user.teamMemberId,
  };
}
