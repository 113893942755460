export const color = {
  G100: "#7BC6B6",
  G200: "#23A085",
  G300: "#1E8972",
  G500: "#00ff00",
  R100: "#C13343",
  O100: "#FF7E2B",
  O200: "#E76512",
  N0: "#ffffff",
  N100: "#F9FAFC",
  N200: "#E5E7EC",
  N300: "#969EB1",
  N400: "#3D4453",
  inherit: "inherit",
};

export const boxShadow = {
  extraSmall: "0px 4px 14px rgba(0, 0, 0, 0.05)",
  normal: "0px 0px 44px rgba(37, 48, 51, 0.05)",
};

export function hexToRGBA(hex: string, alpha = 1) {
  const aRgbHex = hex.slice(1).match(/.{1,2}/g) as RegExpMatchArray;
  const aRgb = [
    parseInt(aRgbHex[0], 16),
    parseInt(aRgbHex[1], 16),
    parseInt(aRgbHex[2], 16),
    [alpha],
  ];
  return `rgba(${aRgb.join(",")})`;
}
