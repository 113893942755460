import { memo } from "react";
import { Helmet } from "react-helmet";
import { Block, Typography } from "ui-kit";
import { GeneralPage } from "ui-kit/Page";
import { TextContent } from "./Content";

export const NonDiscriminationPolicyPage = memo(() => (
  <GeneralPage>
    <Helmet>
      <title>Non-discrimination policy</title>
    </Helmet>
    <Block textAlign="center" marginTop={8} marginBottom={5}>
      <Typography as="h1" type="h1" fontColor="G200">
        Notification of non-discrimination
      </Typography>
      <Block textAlign="center" marginTop={0.5}>
        <Typography type="h5" fontWeight="bold">
          Date last modified: December 2, 2021
        </Typography>
      </Block>
    </Block>
    <TextContent>
      <p>
        PocketDoctor, Inc. complies with applicable federal and state civil
        rights laws and does not discriminate on the basis of: gender, age,
        height, weight, race, appearance, color, national origin, disability,
        caste, criminal record, ethnicity, marital status, generation, genetic
        characteristic, marital status, religion, political opinion, social
        class, sexual orientation and gender identity, etc.
      </p>
      <p>
        PocketDoctor does not exclude people and not treat them differently
        because of sex, age, height, weight, race, appearance, color, national
        origin, disability, caste, criminal record, ethnicity, marital status,
        generation, genetic characteristics, marital status, religion, political
        opinion, social class, sexual orientation and gender identity, etc..
      </p>
      <ul>
        <li>
          Provides free interpretation services to people with disabilities to
          communicate effectively with us, such as:
        </li>
        <li>Qualified sign language interpreters</li>
        <li>
          Provides free interpretation services to people for whom English is
          not the primary language, for example:
        </li>
        <li>Qualified translators</li>
      </ul>
      <p>
        If you need these services, please contact PocketDoctor by email:{" "}
        <a href="mailto:info@pocketdoctor.us">info@pocketdoctor.us</a>
      </p>
      <p>
        If you believe that PocketDoctor did not provide these services or was
        otherwise discriminated against on the basis of race, color, national
        origin, age, disability, or gender, you can file a complaint with:
      </p>
      <p>Legal Team PocketDoctor</p>
      <p>16192 Coastal Highway, Lewes, Delaware 19958, County of Sussex</p>
      <p>
        <a href="mailto:info@pocketdoctor.us">info@pocketdoctor.us</a>
      </p>
      <p>
        You can also file a civil rights complaint with the U.S. Department of
        Health and Human Services Office of Civil Rights electronically through
        the Office of Civil Rights Complaints Portal, available at{" "}
        <a href="https://ocrportal.hhs.gov/ocr/portal/lobby.jsf">
          https://ocrportal.hhs.gov/ocr/portal/lobby.jsf
        </a>
        , either by mail or phone:
      </p>
      <p>
        U.S. Department of Health and Human Services 200 Independence Avenue SW.
        Room 509F, HHH Building, Washington, DC, 20201 (Toll-free):
        1-800-868-1019, 800-537-7697 (TDD).
      </p>
      <p>
        Complaint forms are available at{" "}
        <a href="http://www.hhs.gov/ocr/office/file/index.html">
          http://www.hhs.gov/ocr/office/file/index.html
        </a>
        .
      </p>
    </TextContent>
  </GeneralPage>
));
