import { apiServer } from "apiConnection";
import { ISO8601 } from "Doctor/Model";
import { attach, createEffect, createStore } from "effector";
import { successNotification } from "ui-kit";
import { caseHistoryRecordAdapter } from "./DTOAdapter";
import { CaseHistoryRecord, CaseHistoryRecordDTO } from "./typeDefinitions";

export const loadCaseHistory = createEffect("loadCaseHistory", {
  handler: async (patientId: string) => await getCaseHistory(patientId),
});

export const addCaseHistoryRecord = createEffect("addCaseHistoryRecord", {
  handler: async ({
    patientId,
    type,
    symptom,
    description,
    periodFrom,
    periodTo,
  }: {
    patientId: string;
    type: "current" | "past";
    symptom: string;
    description: string;
    periodFrom?: ISO8601;
    periodTo?: ISO8601;
  }) =>
    await createCaseHistoryRecord({
      patientId,
      type,
      symptom,
      description,
      periodFrom,
      periodTo,
    }),
});

addCaseHistoryRecord.doneData.watch(() => {
  successNotification("Case history was added");
});

export const editCaseHistoryRecord = createEffect("editCaseHistoryRecord", {
  handler: async ({
    patientId,
    recordId,
    type,
    symptom,
    description,
    periodFrom,
    periodTo,
  }: {
    patientId: string;
    recordId: string;
    type: "current" | "past";
    symptom: string;
    description: string;
    periodFrom?: ISO8601;
    periodTo?: ISO8601;
  }) =>
    await updateCaseHistoryRecord({
      patientId,
      recordId,
      type,
      symptom,
      description,
      periodFrom,
      periodTo,
    }),
});

editCaseHistoryRecord.doneData.watch(() => {
  successNotification("Case history was updated");
});

const hydrated_moveCaseHistoryToPast = createEffect("moveCaseHistoryToPast", {
  handler: async ({
    patientId,
    recordId,
    store,
  }: {
    patientId: string;
    recordId: string;
    store: CaseHistoryRecord[];
  }) => {
    const caseHistoryRecord = store
      .filter((record) => record.type === "current")
      .find((record) => record.id === recordId);
    if (!caseHistoryRecord) {
      throw new Error("No such current case in history");
    }
    return await updateCaseHistoryRecord({
      patientId,
      recordId,
      type: "past",
      symptom: caseHistoryRecord.symptom,
      description: caseHistoryRecord.description,
      periodFrom: caseHistoryRecord.createdAt,
      periodTo: Date.nowUniversal.toISOString(),
    });
  },
});

hydrated_moveCaseHistoryToPast.doneData.watch(() => {
  successNotification("Case history was moved to past");
});

export const deleteCaseHistoryRecord = createEffect("deleteCaseHistoryRecord", {
  handler: async ({
    patientId,
    recordId,
  }: {
    patientId: string;
    recordId: string;
  }) => await removeCaseHistoryRecord({ patientId, recordId }),
});

deleteCaseHistoryRecord.doneData.watch(() => {
  successNotification("Case history was removed");
});

async function getCaseHistory(patientId: string) {
  const { data: caseHistoryDTO } = await apiServer.get<CaseHistoryRecordDTO[]>(
    `/api/patient/${patientId}/case-history`
  );
  return caseHistoryDTO;
}

async function createCaseHistoryRecord({
  patientId,
  type,
  symptom,
  description,
  periodFrom,
  periodTo,
}: {
  patientId: string;
  type: "current" | "past";
  symptom: string;
  description: string;
  periodFrom?: ISO8601;
  periodTo?: ISO8601;
}) {
  const { data: createdRecord } = await apiServer.post<CaseHistoryRecordDTO>(
    `/api/patient/${patientId}/case-history`,
    {
      type,
      symptom,
      description,
      periodFrom,
      periodTo,
    }
  );
  return createdRecord;
}

async function updateCaseHistoryRecord({
  patientId,
  recordId,
  type,
  symptom,
  description,
  periodFrom,
  periodTo,
}: {
  patientId: string;
  recordId: string;
  type: "current" | "past";
  symptom: string;
  description: string;
  periodFrom?: ISO8601;
  periodTo?: ISO8601;
}) {
  const { data: updatedRecord } = await apiServer.post<CaseHistoryRecordDTO>(
    `/api/patient/${patientId}/case-history/${recordId}`,
    {
      type,
      symptom,
      description,
      periodFrom,
      periodTo,
    }
  );
  return updatedRecord;
}

async function removeCaseHistoryRecord({
  patientId,
  recordId,
}: {
  patientId: string;
  recordId: string;
}) {
  const { data: removedRecord } = await apiServer.delete<{ id: string }>(
    `/api/patient/${patientId}/case-history/${recordId}`
  );
  return removedRecord.id;
}

export const caseHistory = createStore<CaseHistoryRecord[]>([])
  .on(loadCaseHistory.doneData, (state, caseHistoryDTO) =>
    caseHistoryDTO.map(caseHistoryRecordAdapter)
  )
  .on(addCaseHistoryRecord.doneData, (state, caseHistoryRecordDTO) => [
    ...state,
    caseHistoryRecordAdapter(caseHistoryRecordDTO),
  ])
  .on(
    [editCaseHistoryRecord.doneData, hydrated_moveCaseHistoryToPast.doneData],
    (state, updatedRecordDTO) =>
      state.map((record) =>
        record.id === updatedRecordDTO.objectId
          ? caseHistoryRecordAdapter(updatedRecordDTO)
          : record
      )
  )
  .on(deleteCaseHistoryRecord.doneData, (state, recordId) =>
    state.filter((record) => record.id !== recordId)
  );

export const moveCaseHistoryToPast = attach({
  effect: hydrated_moveCaseHistoryToPast,
  source: caseHistory,
  mapParams: (
    { patientId, recordId }: { patientId: string; recordId: string },
    store: CaseHistoryRecord[]
  ) => ({ patientId, recordId, store }),
});
