import styled from "@emotion/styled";
import { formatRelative } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { ISO8601 } from "Doctor/Model";
import { useStore } from "effector-react";
import {
  healthMetrics as $healthMetrics,
  HealthMetricsDTO,
  loadHealthMetrics,
  Measure,
} from "Patient/Record";
import { memo, ReactNode, useEffect, useState } from "react";
import { getCurrentTimezoneIANA } from "shared-functions/date";
import {
  Block,
  color,
  Flex,
  Input,
  RecordHealthMetricsIcon,
  Typography,
} from "ui-kit";
import { DataContainer } from "./DataContainer";
import { EmptyStateContainer, EmptyStateIcon } from "./EmptyState";
import { LoaderWithSpacing } from "./Loader";

type Props = {
  patientId: string;
};

export const HealthMetricsPage = memo(({ patientId }: Props) => {
  const [isDataLoading, setIsLoading] = useState(true);
  const healthMetrics = useStore($healthMetrics);

  useEffect(() => {
    loadHealthMetrics(patientId).finally(() => setIsLoading(false));
  }, [patientId]);

  if (isDataLoading) {
    return <LoaderWithSpacing />;
  }

  if (healthMetricsAreEmpty(healthMetrics)) {
    return <EmptyState />;
  }

  return (
    <DataContainer>
      <BodySizesContainer marginBottom={6}>
        <Block marginBottom={3} textAlign="center">
          <Typography as="h3" fontColor="N300" type="h2">
            Body sizes
          </Typography>
        </Block>
        <MeasurementField
          label="Height"
          defaultValue={healthMetrics.height?.value}
          defaultMeasure={healthMetrics.height?.measure || "inch"}
          updatedAt={healthMetrics.height?.updatedAt}
        />
        <MeasurementField
          label="Weight"
          defaultValue={healthMetrics.weight?.value}
          defaultMeasure={healthMetrics.weight?.measure || "lbs"}
          updatedAt={healthMetrics.weight?.updatedAt}
        />
        <MeasurementField
          label="Chest circumference"
          defaultValue={healthMetrics.chest?.value}
          defaultMeasure={healthMetrics.chest?.measure || "inch"}
          updatedAt={healthMetrics.chest?.updatedAt}
        />
        <MeasurementField
          label="Waist circumference"
          defaultValue={healthMetrics.waist?.value}
          defaultMeasure={healthMetrics.waist?.measure || "inch"}
          updatedAt={healthMetrics.waist?.updatedAt}
        />
        <MeasurementField
          label="Hip circumference"
          defaultValue={healthMetrics.hip?.value}
          defaultMeasure={healthMetrics.hip?.measure || "inch"}
          updatedAt={healthMetrics.hip?.updatedAt}
        />
        <MeasurementField
          label="Arm length"
          defaultValue={healthMetrics.arm?.value}
          defaultMeasure={healthMetrics.arm?.measure || "inch"}
          updatedAt={healthMetrics.arm?.updatedAt}
        />
        <MeasurementField
          label="Leg length"
          defaultValue={healthMetrics.leg?.value}
          defaultMeasure={healthMetrics.leg?.measure || "inch"}
          updatedAt={healthMetrics.leg?.updatedAt}
        />
      </BodySizesContainer>
      <HealthIndicatorsContainer>
        <Block marginBottom={3} textAlign="center">
          <Typography as="h3" fontColor="N300" type="h2">
            Vital signs
          </Typography>
        </Block>
        <MeasurementField
          label="Body temperature"
          defaultValue={healthMetrics.temperature?.value}
          defaultMeasure={healthMetrics.temperature?.measure || "far"}
          updatedAt={healthMetrics.temperature?.updatedAt}
        />
        <MeasurementField
          label="Heart rate"
          defaultValue={healthMetrics.heartRate?.value}
          defaultMeasure={healthMetrics.heartRate?.measure || "bpm"}
          updatedAt={healthMetrics.heartRate?.updatedAt}
        />
        <MeasurementField
          label="Pulse rate"
          defaultValue={healthMetrics.pulseRate?.value}
          defaultMeasure={healthMetrics.pulseRate?.measure || "bpm"}
          updatedAt={healthMetrics.pulseRate?.updatedAt}
        />
        <MeasurementField
          label="Pulse oximetry"
          defaultValue={healthMetrics.pulseOximetry?.value}
          defaultMeasure={healthMetrics.pulseOximetry?.measure || "spo2"}
          updatedAt={healthMetrics.pulseOximetry?.updatedAt}
        />
        <MeasurementField
          label="Blood group"
          defaultValue={healthMetrics.bloodGroup?.value}
          defaultMeasure={healthMetrics.bloodGroup?.measure || "abo"}
        />
        <MeasurementField
          label="Blood pressure"
          defaultValue={healthMetrics.bloodPressure?.value}
          defaultMeasure={healthMetrics.bloodPressure?.measure || "mmhg"}
          updatedAt={healthMetrics.bloodPressure?.updatedAt}
        />
        <MeasurementField
          label="Glucose"
          defaultValue={healthMetrics.glucose?.value}
          defaultMeasure={healthMetrics.glucose?.measure || "mmol-l"}
          updatedAt={healthMetrics.glucose?.updatedAt}
        />
        <MeasurementField
          label="Body cholesterol"
          defaultValue={healthMetrics.bodyCholesterol?.value}
          defaultMeasure={healthMetrics.bodyCholesterol?.measure || "mg-dl"}
          updatedAt={healthMetrics.bodyCholesterol?.updatedAt}
        />
        <MeasurementField
          label="LDL cholesterol"
          defaultValue={healthMetrics.LDLCholesterol?.value}
          defaultMeasure={healthMetrics.LDLCholesterol?.measure || "mg-dl"}
          updatedAt={healthMetrics.LDLCholesterol?.updatedAt}
        />
        <MeasurementField
          label="HDL cholesterol"
          defaultValue={healthMetrics.HDLCholesterol?.value}
          defaultMeasure={healthMetrics.HDLCholesterol?.measure || "mg-dl"}
          updatedAt={healthMetrics.HDLCholesterol?.updatedAt}
        />
        <MeasurementField
          label="Triglycerides"
          defaultValue={healthMetrics.triglycerides?.value}
          defaultMeasure={healthMetrics.triglycerides?.measure || "mmol-l"}
          updatedAt={healthMetrics.triglycerides?.updatedAt}
        />
      </HealthIndicatorsContainer>
    </DataContainer>
  );
});

function healthMetricsAreEmpty(healthMetrics: Partial<HealthMetricsDTO>) {
  return !Object.values(healthMetrics).some((metric) => !!metric?.value);
}

type MeasurementFieldProps = {
  label: string;
  defaultMeasure?: string;
  defaultValue?: string;
  updatedAt?: ISO8601;
};

const MeasurementField = memo(
  ({
    label,
    defaultMeasure,
    defaultValue,
    updatedAt,
  }: MeasurementFieldProps) => {
    return (
      <Block marginBottom={3} position="relative">
        <MeasurementInput
          disabled
          defaultValue={defaultValue || "N/A"}
          icon={
            defaultMeasure ? (
              <MeasureLabel>
                <Typography type="h5">
                  {measureValueToLabel[defaultMeasure as Measure]}
                </Typography>
              </MeasureLabel>
            ) : undefined
          }
          label={label}
        />
        {updatedAt && (
          <UpdateTimeContainer>
            <Typography type="h5" fontColor="N300">
              Updated:{" "}
              {formatRelative(
                utcToZonedTime(updatedAt, getCurrentTimezoneIANA()),
                Date.nowUniversal
              )}
            </Typography>
          </UpdateTimeContainer>
        )}
      </Block>
    );
  }
);

const EmptyState = () => (
  <EmptyStateContainer>
    <Flex justifyContent="center" marginBottom={4}>
      <EmptyStateIcon>
        <RecordHealthMetricsIcon width="86px" height="86px" />
      </EmptyStateIcon>
    </Flex>
    <Block marginBottom={1}>
      <Typography fontColor="N300" type="h2">
        No health metrics
      </Typography>
    </Block>
    <Block>
      <Typography fontColor="N300" fontWeight={400} type="h3">
        Body sizes and vital signs of the patient will be displayed here
      </Typography>
    </Block>
  </EmptyStateContainer>
);

const MeasurementInput = styled(Input)`
  padding-right: 104px;
`;

const MeasureLabel = styled.span`
  align-items: center;
  background-color: ${color.N200};
  border-radius: 0 4px 4px 0;
  display: inline-flex;
  justify-content: center;
  position: absolute;
  right: 1px;
  bottom: 1px;
  height: calc(100% - 2px);
  min-width: 104px;
  padding-left: 16px;
  padding-right: 16px;
`;

const UpdateTimeContainer = styled(Block)`
  position: absolute;
  right: 0;
  top: 0;
`;

const measureValueToLabel: { [key in Measure]: ReactNode } = {
  inch: "inch",
  lbs: "lbs",
  far: "\u2109",
  bpm: "BPM",
  spo2: (
    <>
      SpO<sub>2</sub>
    </>
  ),
  abo: "ABO",
  mmhg: "mmHg",
  "mg-dl": "mg/dL",
  "mmol-l": "mmol/L",
};

const BodySizesContainer = Block.withComponent("section");
const HealthIndicatorsContainer = Block.withComponent("section");
