import styled from "@emotion/styled";
import { memo, RefObject, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { StepWizardChildProps } from "react-step-wizard";
import { Block, color } from "ui-kit";

type NavigationProps = Partial<StepWizardChildProps> & {
  container: RefObject<HTMLDivElement>;
};

export const Navigation = memo(
  ({ container, currentStep, totalSteps }: NavigationProps) => {
    const [
      navigationContainer,
      setNavigationContainer,
    ] = useState<HTMLDivElement>();

    useEffect(() => {
      if (container.current) {
        setNavigationContainer(container.current);
      }
    }, [container]);

    if (!navigationContainer) {
      return null;
    }

    return createPortal(
      <NavigationProgress position="relative">
        <NavigationStep currentStep={currentStep!} totalSteps={totalSteps!} />
      </NavigationProgress>,
      navigationContainer
    );
  }
);

const NavigationProgress = styled(Block)`
  background-color: ${color.N200};
  border-radius: 4px;
  height: 4px;
  margin: 0 auto;
  postion: relative;
  width: 245px;
`;

type NavigationStepState = {
  currentStep: number;
  totalSteps: number;
};

const NavigationStep = styled.span<NavigationStepState>`
  background-color: ${color.G200};
  border-radius: 4px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: width 0.3s ease-out;
  width: ${({ currentStep, totalSteps }) =>
    `${(currentStep / totalSteps) * 100}%`};
`;
