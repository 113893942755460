import logoGreen from "./meta-health-green.png";
import logoWhite from "./meta-health.png";

type Props = {
  green?: boolean;
};

export const MetaLogo = ({ green }: Props) => (
  <img src={green ? logoGreen : logoWhite} alt="Meta Health" width="160" />
);
