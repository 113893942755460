import styled from "@emotion/styled";
import { endOfDay, endOfMonth, endOfWeek, isBefore, parseISO } from "date-fns";
import { toDoctorVisitsHistoryPage, useDoctorId } from "Doctor/Router";
import { useStore } from "effector-react";
import { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Block,
  Button,
  ButtonsGroup,
  color,
  Flex,
  Loader,
  Typography,
  Watches3ClockIcon,
} from "ui-kit";
import { Visit } from "Visit";
import { PageLayout } from "../Layout";
import { $visitsList, loadVisits } from "./list";
import { VisitsList } from "./VisitList";

type Period = "day" | "week" | "month" | "year";

export const WaitingRoomPage = memo(() => {
  // On this page, we should see upcoming visits
  const { visits: upcomingVisits } = useStore($visitsList);
  const [areVisitsLoaded, setVisitsLoadedState] = useState(false);
  const [upcomingFilteredVisit, setVisitsList] =
    useState<Visit[]>(upcomingVisits);
  const [selectedPeriod, setSelectedPeriod] = useState<Period>();

  useEffect(() => {
    setVisitsLoadedState(false);
    loadVisits({ from: Date.nowUniversal }).finally(() =>
      setVisitsLoadedState(true)
    );
  }, []);

  const showVisitsForDay = () =>
    setSelectedPeriod(selectedPeriod === "day" ? undefined : "day");

  const showVisitsForWeek = () =>
    setSelectedPeriod(selectedPeriod === "week" ? undefined : "week");

  const showVisitsForMonth = () =>
    setSelectedPeriod(selectedPeriod === "month" ? undefined : "month");

  const visitsForToday = useCallback(
    () =>
      upcomingVisits.filter(({ timeSlot }) =>
        isBefore(parseISO(timeSlot.to), endOfDay(Date.nowUniversal))
      ),
    [upcomingVisits]
  );
  const visitsForThisWeek = useCallback(
    () =>
      upcomingVisits.filter(({ timeSlot }) =>
        isBefore(parseISO(timeSlot.to), endOfWeek(Date.nowUniversal))
      ),
    [upcomingVisits]
  );
  const visitsForThisMonth = useCallback(
    () =>
      upcomingVisits.filter(({ timeSlot }) =>
        isBefore(parseISO(timeSlot.to), endOfMonth(Date.nowUniversal))
      ),
    [upcomingVisits]
  );

  useEffect(() => {
    if (selectedPeriod === "day") {
      return setVisitsList(visitsForToday());
    }
    if (selectedPeriod === "week") {
      return setVisitsList(visitsForThisWeek());
    }
    if (selectedPeriod === "month") {
      return setVisitsList(visitsForThisMonth());
    }
    return setVisitsList(upcomingVisits);
  }, [
    selectedPeriod,
    visitsForToday,
    visitsForThisWeek,
    visitsForThisMonth,
    upcomingVisits,
  ]);

  return (
    <PageLayout>
      <Block marginTop={3} paddingLeft={5} paddingRight={5}>
        <PageTitle />
      </Block>
      {areVisitsLoaded ? (
        <ContentContainer>
          {upcomingFilteredVisit.length === 0 ? (
            <>
              {upcomingVisits.length > 0 && (
                <ButtonsGroup
                  config={[
                    {
                      isSelected: selectedPeriod === "day",
                      title: `Day\u00A0\u007C\u00A0${visitsForToday().length}`,
                      clickCallback: showVisitsForDay,
                    },
                    {
                      isSelected: selectedPeriod === "week",
                      title: `Week\u00A0\u007C\u00A0${
                        visitsForThisWeek().length
                      }`,
                      clickCallback: showVisitsForWeek,
                    },
                    {
                      isSelected: selectedPeriod === "month",
                      title: `Month\u00A0\u007C\u00A0${
                        visitsForThisMonth().length
                      }`,
                      clickCallback: showVisitsForMonth,
                    },
                  ]}
                />
              )}
              <NoVisits />
            </>
          ) : (
            <>
              <Block marginBottom={5}>
                <ButtonsGroup
                  config={[
                    {
                      isSelected: selectedPeriod === "day",
                      title: `Day\u00A0\u007C\u00A0${visitsForToday().length}`,
                      clickCallback: showVisitsForDay,
                    },
                    {
                      isSelected: selectedPeriod === "week",
                      title: `Week\u00A0\u007C\u00A0${
                        visitsForThisWeek().length
                      }`,
                      clickCallback: showVisitsForWeek,
                    },
                    {
                      isSelected: selectedPeriod === "month",
                      title: `Month\u00A0\u007C\u00A0${
                        visitsForThisMonth().length
                      }`,
                      clickCallback: showVisitsForMonth,
                    },
                  ]}
                />
              </Block>
              <VisitsList visitsList={upcomingFilteredVisit} sorting="asc" />
            </>
          )}
        </ContentContainer>
      ) : (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
    </PageLayout>
  );
});

const NoVisits = memo(() => (
  <Block marginTop={13} textAlign="center">
    <Block marginBottom={6}>
      <Watches3ClockIcon fill={color.N300} />
    </Block>
    <Block marginBottom={1}>
      <Typography fontColor="N300" type="h2">
        No upcoming visits
      </Typography>
    </Block>
    <Block>
      <Typography fontColor="N300" type="h3">
        Your visits with patients will be displayed here
      </Typography>
    </Block>
  </Block>
));

const PageTitle = memo(() => {
  const navigate = useNavigate();
  const doctorId = useDoctorId();
  const goToVisitsHistory = useCallback(() => {
    navigate(toDoctorVisitsHistoryPage(doctorId));
  }, [navigate, doctorId]);

  return (
    <Flex alignItems="center" justifyContent="center" position="relative">
      <Typography as="h1" type="h1">
        Waiting room
      </Typography>
      <Block position="absolute" right="0">
        <Button onClick={goToVisitsHistory}>View history</Button>
      </Block>
    </Flex>
  );
});

const ContentContainer = styled(Block)`
  margin: 40px auto;
  width: 840px;
`;

const LoaderContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  height: calc(100vh - 176px);
`;
