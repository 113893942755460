import styled from "@emotion/styled";
import { memo, ReactNode } from "react";
import { createPortal } from "react-dom";
import { BoxWithShadow } from "./BoxWithShadow";
import { Flex } from "./Layout";

type Props = {
  children: ReactNode;
};

export const ModalWindow = memo(({ children }: Props) => {
  return createPortal(
    <Underlay
      alignItems="center"
      justifyContent="center"
      width="100vw"
      height="100vh"
      position="fixed"
      top="0"
      left="0"
    >
      <BoxWithShadow
        alignItems="center"
        justifyContent="center"
        minWidth="440px"
        minHeight="280px"
      >
        {children}
      </BoxWithShadow>
    </Underlay>,
    document.getElementById("modal-container") as Element
  );
});

const Underlay = styled(Flex)`
  background-color: rgba(0, 0, 0, 0.5);
`;
