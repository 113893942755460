import { patientLoginPage, toCreateAccount } from "Patient/routes";
import { memo } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { usePatientAccountWasCreated } from "./patientSession";
import { useAuthenticatedPatient } from "./useAuthenticatedPatient";
import { useLocationWithValidPathname } from "./useLocationWithValidPathname";

export const CreatedAccountBarier = memo(() => {
  const authenticatedPatient = useAuthenticatedPatient();
  const validLocation = useLocationWithValidPathname();
  const isAccountCreated = usePatientAccountWasCreated();

  if (!authenticatedPatient) {
    return (
      <Navigate
        replace
        to={{
          pathname: patientLoginPage,
        }}
        state={{
          from: validLocation,
        }}
      />
    );
  }

  if (!isAccountCreated) {
    return (
      <Navigate
        replace
        to={{
          // At this point, we know patient is authenticated, i.e. it's defined
          pathname: toCreateAccount(authenticatedPatient.id),
        }}
      />
    );
  }

  return <Outlet />;
});
