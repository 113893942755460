import { DoctorPatientRecord } from "Doctor/Profile";
import { doctorLoginPage, toDoctorCreateAccountPage } from "Doctor/Router";
import { Patient } from "Patient/Model";
import { memo, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useAuthenticatedDoctor } from "./useAuthenticatedDoctor";
import { useBlockRouteOnModeration } from "./useBlockRouteOnModeration";
import { useDoctorAccountWasCreated } from "./useDoctorAccountWasCreated";
import { useLocationWithValidPathname } from "./useLocationWithValidPathname";

type Props = {
  children: (patient: Patient) => ReactNode;
  recordSpecificContorls?: (patientId: string, doctorId: string) => ReactNode;
  title: string;
};

export const PatientRecordBarier = memo(
  ({ recordSpecificContorls, title, children }: Props) => {
    const authenticatedDoctor = useAuthenticatedDoctor();
    const validLocation = useLocationWithValidPathname();
    const isAccountCreated = useDoctorAccountWasCreated();
    const shouldBlockRouteOnModeration = useBlockRouteOnModeration();

    if (!authenticatedDoctor) {
      return (
        <Navigate
          replace
          to={{
            pathname: doctorLoginPage,
          }}
          state={{
            from: validLocation,
          }}
        />
      );
    }

    if (!isAccountCreated || shouldBlockRouteOnModeration) {
      return (
        <Navigate
          replace
          to={{
            pathname: toDoctorCreateAccountPage(authenticatedDoctor.id),
          }}
        />
      );
    }

    return (
      <DoctorPatientRecord
        recordSpecificControls={recordSpecificContorls}
        title={title}
      >
        {(patient: Patient) => children(patient)}
      </DoctorPatientRecord>
    );
  }
);
