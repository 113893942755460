import { apiServer } from "apiConnection";
import { createEffect, createStore } from "effector";

type Access = {
  isAccessDataLoaded: boolean;
  accessMap: AccessMap;
};

type AccessMap = { [key in string]?: boolean };

export const checkAccess = createEffect("checkAccessToPatientRecord", {
  handler: async ({
    doctorId,
    patientIdsList,
  }: {
    doctorId: string;
    patientIdsList: string[];
  }) => {
    const { data: access } = await apiServer.post<AccessMap>(
      "/api/doctor/patient-record-access",
      {
        doctorId,
        patientIdsList,
      }
    );
    return access;
  },
});

export const $accessController = createStore<Access>({
  isAccessDataLoaded: false,
  accessMap: {},
}).on(checkAccess.done, (_, { result: accessMap }) => ({
  isAccessDataLoaded: true,
  accessMap,
}));
