import { BLOCKED_ON_MODERATION_ROUTES } from "Doctor/Router";
import { matchPath, useLocation } from "react-router-dom";
import { useDoctor } from "./doctorSession";

export function useBlockRouteOnModeration() {
  const doctor = useDoctor();
  const location = useLocation();
  if (doctor === undefined) {
    return false;
  }
  const isCurrentURLInBlockadeList =
    BLOCKED_ON_MODERATION_ROUTES.find((route) =>
      matchPath(route, location.pathname)
    ) !== undefined;
  if (isCurrentURLInBlockadeList && doctor.onModeration) {
    return true;
  }
  return false;
}
