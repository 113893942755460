import styled from "@emotion/styled";
import { memo } from "react";
import { Helmet } from "react-helmet";
import { Block, BoxWithShadow, HeroBanner, Typography } from "ui-kit";
import { GeneralPage } from "ui-kit/Page";
import { Form } from "./Form";
import contacts from "./support.jpg";

export const SupportPage = memo(() => (
  <GeneralPage>
    <Helmet>
      <title>Support</title>
    </Helmet>
    <Hero enableOverlay image={contacts} flexDirection="column">
      <Block marginBottom={2} marginTop={10} textAlign="center">
        <Typography as="h1" type="h1" fontColor="N0">
          Support
        </Typography>
      </Block>
      <Description textAlign="center" width="500px">
        <Typography as="h3" type="h3" fontColor="N0">
          In the case of possible or actual emergency situations, immediately
          call 911 or your local ambulance service.
        </Typography>
      </Description>
    </Hero>
    <FormContainer>
      <FormPositionContainer padding={5}>
        <Form />
      </FormPositionContainer>
    </FormContainer>
  </GeneralPage>
));

const Hero = styled(HeroBanner)`
  height: 528px;
  .image {
    background-position-y: -40px;
  }
`;

const Description = styled(Block)`
  margin: 0 auto;
`;

const FormContainer = styled(Block)`
  min-height: 448px;
  padding: 72px 0;
  position: relative;
`;

const FormPositionContainer = styled(BoxWithShadow)`
  margin: 0 auto 0 -310px;
  left: 50%;
  position: absolute;
  top: -250px;
  width: 620px;
`;
