import styled from "@emotion/styled";
import { apiServer } from "apiConnection";
import { AxiosError } from "axios";
import { memo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Block,
  Button,
  Flex,
  Input,
  LoaderWithOverlay,
  Selectbox,
  Textarea,
  errorNotification,
  successNotification,
} from "ui-kit";

type ContactData = {
  topic: string;
  firstName: string;
  lastName: string;
  email: string;
  message: string;
};

type Topic = {
  label: string;
  value: string;
};

const topics: Topic[] = [
  {
    label: "A common request",
    value: "common",
  },
  {
    label: "Personnel department",
    value: "personnel",
  },
  {
    label: "Technical support",
    value: "technical",
  },
  {
    label: "Partnership relation",
    value: "partnership",
  },
  {
    label: "MEDIA",
    value: "media",
  },
  {
    label: "Legal request",
    value: "legal",
  },
  {
    label: "Other",
    value: "other",
  },
];

type Props = {
  firstName?: string;
  lastName?: string;
  email?: string;
};

export const Form = memo(({ firstName, lastName, email }: Props) => {
  const {
    control,
    register,
    resetField,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ContactData>({
    defaultValues: {
      firstName: firstName || "",
      lastName: lastName || "",
      email: email || "",
    },
  });

  const [requestSending, setRequestSending] = useState(false);

  const sendContact: SubmitHandler<ContactData> = (contactData) => {
    setRequestSending(true);
    apiServer
      .post("/api/support", contactData)
      .then(() => {
        resetField("message");
        successNotification(
          "Your request was sent to our support center. We will reply as soon as possible"
        );
      })
      .catch((error: unknown) => {
        const message = (error as AxiosError<{ message: string }>).response
          ?.data.message;
        if (message) {
          errorNotification(message);
        }
      })
      .finally(() => setRequestSending(false));
  };

  const message = watch("message");

  return (
    <FormContainer>
      {requestSending ? <LoaderWithOverlay /> : null}
      <FormComponent onSubmit={handleSubmit(sendContact)} width="100%">
        <Block marginBottom={3}>
          <Controller
            control={control}
            name="topic"
            rules={{ required: "Select a subject" }}
            render={({
              field: { onChange },
              fieldState: { invalid, error },
            }) => (
              <Selectbox
                autoFocus
                isClearable
                hasErrors={invalid}
                errorMessage={error}
                label="Subject"
                noOptionsMessage={() => "No subject"}
                onChange={(topic) => {
                  onChange((topic as Topic)?.value);
                }}
                options={topics}
                placeholder="Select a subject"
              />
            )}
          />
        </Block>
        <Flex marginBottom={3}>
          <Block marginRight={2} width="50%">
            <Input
              type="text"
              errorMessage={errors["firstName"]?.message}
              hasErrors={!!errors["firstName"]}
              label="First name"
              placeholder="First name"
              defaultValue={firstName}
              {...register("firstName", { required: "Enter first name" })}
            />
          </Block>
          <Block marginLeft={2} width="50%">
            <Input
              type="text"
              errorMessage={errors["lastName"]?.message}
              hasErrors={!!errors["lastName"]}
              label="Last name"
              placeholder="Last name"
              defaultValue={lastName}
              {...register("lastName", { required: "Enter last name" })}
            />
          </Block>
        </Flex>
        <Block marginBottom={3}>
          <Input
            type="email"
            errorMessage={errors["email"]?.message}
            hasErrors={!!errors["email"]}
            label="E-mail"
            placeholder="E-mail"
            defaultValue={email}
            {...register("email", { required: "Enter e-mail" })}
          />
        </Block>
        <Block marginBottom={3}>
          <Textarea
            label="Message"
            hasErrors={!!errors["message"]}
            errorMessage={errors["message"]?.message}
            placeholder="Message"
            hasValue={message ? message.length > 0 : false}
            value={message}
            {...register("message", { required: "Add a message" })}
          />
        </Block>
        <Block textAlign="center">
          <Button filled type="submit">
            Send
          </Button>
        </Block>
      </FormComponent>
    </FormContainer>
  );
});

const FormContainer = styled(Block)`
  position: relative;
  width: 100%;
`;

const FormComponent = Block.withComponent("form");
