import { createEvent, createStore } from "effector";
import {
  leaveSession,
  LocalMediaParticipant,
  RemoteMediaParticipant,
  subscriberMuted,
  subscriberUnmuted,
  VisitStream,
} from "MediaStream";

type Settings = {
  video: boolean;
  speaker: boolean;
  microphone: boolean;
  screenSize: "wide" | "original";
  hd: boolean;
  reversedVideo: boolean;
};

export const setupStreamDevices =
  createEvent<VisitStream>("setupStreamDevices");

export const changeVideoSetting = createEvent<{
  publisher: LocalMediaParticipant | undefined;
  video: boolean;
}>("changeVideoSetting");
export const changeMicSetting = createEvent<{
  publisher: LocalMediaParticipant | undefined;
  microphone: boolean;
}>("changeMicSetting");
export const changeSpeakerSetting = createEvent<{
  subscribers: RemoteMediaParticipant[];
  speaker: boolean;
}>("changeSpeakerSetting");
export const changeScreenSize = createEvent<"wide" | "original">(
  "changeScreenSize"
);
export const applyHD = createEvent<boolean>("applyHD");
export const reverseVideo = createEvent<boolean>("reverseVideo");

export const settings = createStore<Settings>({
  video: true,
  speaker: true,
  microphone: true,
  screenSize: "wide",
  hd: false,
  reversedVideo: false,
})
  .on(setupStreamDevices, (settings, streamType) => {
    if (streamType === "video") {
      return {
        ...settings,
        video: true,
        speaker: true,
        microphone: true,
      };
    }
    if (streamType === "audio") {
      return {
        ...settings,
        video: false,
        speaker: true,
        microphone: true,
      };
    }
    if (streamType === "text") {
      return {
        ...settings,
        video: false,
        speaker: false,
        microphone: false,
      };
    }
    return settings;
  })
  .on(changeVideoSetting, (state, { video }) => ({ ...state, video }))
  .on(changeMicSetting, (state, { microphone }) => ({ ...state, microphone }))
  .on(changeSpeakerSetting, (state, { speaker }) => ({ ...state, speaker }))
  .on(changeScreenSize, (state, screenSize) => ({ ...state, screenSize }))
  .on(applyHD, (state, hd) => ({ ...state, hd }))
  .on(reverseVideo, (state, reversedVideo) => ({ ...state, reversedVideo }))
  .reset(leaveSession);

changeVideoSetting.watch(({ publisher, video }) => {
  publisher?.video.enable(video);
});

changeMicSetting.watch(({ publisher, microphone }) => {
  publisher?.audio.enable(microphone);
});

changeSpeakerSetting.watch(({ subscribers, speaker }) => {
  subscribers.forEach((sub) => {
    if (speaker) {
      subscriberUnmuted({ id: sub.id });
    } else {
      subscriberMuted({ id: sub.id });
    }
  });
});
