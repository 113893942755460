import { useMemo } from "react";
import { useCallback, ReactNode, useState } from "react";
import { StarEmpty, StarFilled } from "../icons";
import { Rating } from "./Rating";

type RatingCallback = (value: number) => void;

type TShirtSize = "s" | "m" | "l";

type Props = {
  initialRating?: number;
  start?: number;
  stop?: number;
  step?: number;
  readonly?: boolean;
  fractions?: number;
  size?: TShirtSize;
  emptySymbol?: ReactNode;
  fullSymbol?: ReactNode;
  className?: string;
  onClick?: RatingCallback;
  onChange?: RatingCallback;
  onHover?: (value?: number) => void;
};

function getSize(size: TShirtSize) {
  switch (size) {
    case "s":
      return "18px";
    case "m":
      return "24px";
    case "l":
      return "36px";
    default:
      return "24px";
  }
}

export const RatingAPILayer = ({
  initialRating = 0,
  start = 0,
  stop = 5,
  step = 1,
  fractions = 1,
  size = "s",
  readonly = false,
  emptySymbol,
  fullSymbol,
  className,
  onChange,
  onClick,
  onHover,
}: Props) => {
  const [value, setValue] = useState(initialRating);

  const translateDisplayValueToValue = useCallback(
    (displayValue: number) => {
      const translatedValue = displayValue * step + start;
      // minimum value cannot be equal to start, since it's exclusive
      return translatedValue === start
        ? translatedValue + 1 / fractions
        : translatedValue;
    },
    [fractions, start, step]
  );

  const translateValueToDisplayValue = useCallback(
    (value?: number) => {
      if (value === undefined) {
        return 0;
      }
      return (value - start) / step;
    },
    [start, step]
  );

  const handleClick = useCallback(
    (updatedValue: number) => {
      const newValue = translateDisplayValueToValue(updatedValue);
      if (onClick) {
        onClick(newValue);
      }
      if (newValue !== value) {
        setValue(newValue);
        if (onChange) {
          onChange(newValue);
        }
      }
    },
    [onChange, onClick, value, translateDisplayValueToValue]
  );

  const handleHover = useCallback(
    (displayValue?: number) => {
      const value =
        displayValue === undefined
          ? displayValue
          : translateDisplayValueToValue(displayValue);
      if (onHover) {
        onHover(value);
      }
    },
    [translateDisplayValueToValue, onHover]
  );

  const totalSymbols = useMemo(
    () => Math.floor((stop - start) / step),
    [stop, start, step]
  );

  return (
    <Rating
      className={className}
      totalSymbols={totalSymbols}
      value={translateValueToDisplayValue(value)}
      readonly={readonly}
      fractions={fractions}
      emptySymbol={
        emptySymbol || (
          <StarEmpty width={getSize(size)} height={getSize(size)} />
        )
      }
      fullSymbol={
        fullSymbol || (
          <StarFilled width={getSize(size)} height={getSize(size)} />
        )
      }
      onClick={handleClick}
      onHover={handleHover}
    />
  );
};
