import styled from "@emotion/styled";
import { differenceInMinutes, format, parseISO } from "date-fns";
import { useStore } from "effector-react";
import {
  loadProcedure,
  ProcedureRecord,
  procedures as $procedures,
} from "Patient/Record";
import { memo, useEffect, useState } from "react";
import { Block, color, Flex, RecordBandIcon, Typography } from "ui-kit";
import { EmptyStateContainer, EmptyStateIcon } from "./EmptyState";
import { LoaderWithSpacing } from "./Loader";

type Props = {
  patientId: string;
};

type ProceduresRecordsSplitted = {
  current: ProcedureRecord[];
  past: ProcedureRecord[];
};

export const ProceduresPage = memo(({ patientId }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const proceduresList = useStore($procedures);

  useEffect(() => {
    loadProcedure(patientId).finally(() => setIsLoading(false));
  }, [patientId]);

  const splittedRecords = proceduresList.reduce(
    (splittedRecords, record) => ({
      current:
        record.type === "current"
          ? [...splittedRecords.current, record]
          : splittedRecords.current,
      past:
        record.type === "past"
          ? [...splittedRecords.past, record]
          : splittedRecords.past,
    }),
    { current: [], past: [] } as ProceduresRecordsSplitted
  );

  if (isLoading) {
    return <LoaderWithSpacing />;
  }

  if (proceduresList.length === 0) {
    return (
      <EmptyStateContainer>
        <Flex justifyContent="center" marginBottom={4}>
          <EmptyStateIcon>
            <RecordBandIcon width="86px" height="86px" />
          </EmptyStateIcon>
        </Flex>
        <Block marginBottom={1}>
          <Typography fontColor="N300" type="h2">
            No procedures
          </Typography>
        </Block>
        <Block marginBottom={4}>
          <Typography fontColor="N300" fontWeight={400} type="h3">
            Procedures the patient is taking or has taken will be displayed here
          </Typography>
        </Block>
      </EmptyStateContainer>
    );
  }

  return (
    <Block marginTop={3} paddingLeft={5} paddingRight={5}>
      {splittedRecords.current.length > 0 && (
        <Block marginBottom={6}>
          <Block marginBottom={3}>
            <Typography as="h3" fontColor="N300" type="h2">
              Current
            </Typography>
          </Block>
          {splittedRecords.current.sort(newestOnTop).map((record) => (
            <TreatmentRecordContainer key={record.id}>
              <Block marginBottom={1}>
                <Typography as="h4" type="h2">
                  {record.name}
                </Typography>
              </Block>
              <Block marginBottom={1}>
                <Typography fontColor="N300" type="h4">
                  Updated at:{" "}
                  {format(parseISO(record.updatedAt), "MM/dd/yyyy hh:mm a")}
                </Typography>
              </Block>
              <Block>
                <Typography fontWeight={400} type="h3">
                  {record.description}
                </Typography>
              </Block>
            </TreatmentRecordContainer>
          ))}
        </Block>
      )}
      {splittedRecords.past.length > 0 && (
        <Block marginBottom={6}>
          <Block marginBottom={3}>
            <Typography as="h3" fontColor="N300" type="h2">
              Past
            </Typography>
          </Block>
          {splittedRecords.past.sort(newestOnTop).map((record) => (
            <TreatmentRecordContainer key={record.id}>
              <Block marginBottom={1}>
                <Typography as="h4" type="h2">
                  {record.name}
                </Typography>
              </Block>
              <Block marginBottom={1}>
                <Typography fontColor="N300" type="h4">
                  Period:{" "}
                  {record.periodFrom &&
                    format(parseISO(record.periodFrom), "MM/dd/yyyy")}
                  {record.periodFrom && record.periodTo && (
                    <>&nbsp;&mdash;&nbsp;</>
                  )}
                  {record.periodTo &&
                    format(parseISO(record.periodTo), "MM/dd/yyyy")}
                </Typography>
              </Block>
              <Block>
                <Typography fontWeight={400} type="h3">
                  {record.description}
                </Typography>
              </Block>
            </TreatmentRecordContainer>
          ))}
        </Block>
      )}
    </Block>
  );
});

function newestOnTop(procedure1: ProcedureRecord, procedure2: ProcedureRecord) {
  return differenceInMinutes(
    new Date(
      procedure2.periodTo || procedure2.periodFrom || procedure2.updatedAt
    ),
    new Date(
      procedure1.periodTo || procedure1.periodFrom || procedure1.updatedAt
    )
  );
}

const TreatmentRecordContainer = styled.section`
  border-bottom: 1px solid ${color.N200};
  margin-bottom: 32px;
  padding-bottom: 32px;
  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;
