import styled from "@emotion/styled";
import { aboutPage } from "About/routes";
import { FAQPage } from "FAQ/routes";
import { loginHomePage, registrationHomePage } from "Home";
import { homePage } from "Home/routes";
import { doctorsListPage } from "OurDoctors/routes";
import { memo, useCallback } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { supportPage } from "Support/routes";
import { Button } from "./Buttons";
import { Logo } from "./icons";
import { Block, Flex, Grid } from "./Layout";
import { color } from "./theme";

export const Header = memo(() => {
  const navigate = useNavigate();

  const goToLoginPage = useCallback(() => {
    navigate(loginHomePage);
  }, [navigate]);

  const goToRegistrationPage = useCallback(() => {
    navigate(registrationHomePage);
  }, [navigate]);

  return (
    <HeaderPanel>
      <HeaderLayout alignItems="center" justifyContent="space-between">
        <Grid
          alignItems="center"
          gridTemplateColumns="max-content auto max-content"
          gridColumnGap={2}
          width="100%"
        >
          <Block>
            <Link to={homePage}>
              <Logo fill={color.N200} width="188px" />
            </Link>
          </Block>
          <Navigation justifyContent="center">
            <MenuItem to={FAQPage}>
              <MeuItemTitle>FAQ</MeuItemTitle>
            </MenuItem>
            <MenuItem to={supportPage}>
              <MeuItemTitle>Support</MeuItemTitle>
            </MenuItem>
            <MenuItem to={doctorsListPage}>
              <MeuItemTitle>Our doctors</MeuItemTitle>
            </MenuItem>
            <MenuItem to={aboutPage}>
              <MeuItemTitle>About</MeuItemTitle>
            </MenuItem>
          </Navigation>
          <Flex alignItems="center">
            <AuthButton onClick={goToLoginPage}>Log in</AuthButton>
            <AuthButton onClick={goToRegistrationPage}>Sign up</AuthButton>
          </Flex>
        </Grid>
      </HeaderLayout>
    </HeaderPanel>
  );
});

const HeaderPanel = styled("header")`
  background-color: ${color.G200};
`;

const HeaderLayout = styled(Flex)`
  height: 100%;
  margin: 0 auto;
  padding: 0 32px;
  width: 1344px;
`;

const Navigation = Flex.withComponent("nav");

const MenuItem = styled(NavLink)`
  align-items: center;
  color: ${color.N200};
  display: flex;
  font-weight: 500;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
  margin: 0 24px;
  padding: 0 8px;
  text-decoration: none;
  &.active,
  &:hover,
  &:focus {
    color: ${color.N0};
  }
  &.active {
    cursor: default;
  }
`;

const MeuItemTitle = styled("span")``;

const AuthButton = styled(Button)`
  border-color: ${color.N200};
  color: ${color.N200};
  &:hover,
  &:focus {
    border-color: ${color.N0};
    color: ${color.N0};
  }
  &:last-of-type {
    margin-left: 24px;
  }
`;
