import { apiServer } from "apiConnection";
import { ISO8601 } from "Doctor/Model";
import { createEffect, createStore } from "effector";
import { successNotification } from "ui-kit";
import { VaccineDTO } from "./typeDefinitions";

export const loadVaccination = createEffect("loadVaccination", {
  handler: async (patientId: string) => await getVaccination(patientId),
});

export const addVaccine = createEffect("addVaccine", {
  handler: async ({
    patientId,
    name,
    date,
    description,
  }: {
    patientId: string;
    name: string;
    date: ISO8601;
    description: string;
  }) =>
    await createVaccineRecord({
      patientId,
      name,
      date,
      description,
    }),
});

addVaccine.doneData.watch(() => {
  successNotification("Vaccination was added");
});

export const editVaccine = createEffect("editVaccine", {
  handler: async ({
    patientId,
    recordId,
    name,
    date,
    description,
  }: {
    patientId: string;
    recordId: string;
    name: string;
    date: ISO8601;
    description: string;
  }) =>
    await updateVaccineRecord({
      patientId,
      recordId,
      name,
      date,
      description,
    }),
});

editVaccine.doneData.watch(() => {
  successNotification("Vaccination was updated");
});

export const deleteVaccine = createEffect("deleteVaccine", {
  handler: async ({
    patientId,
    recordId,
  }: {
    patientId: string;
    recordId: string;
  }) => await removeVaccineRecord({ patientId, recordId }),
});

deleteVaccine.doneData.watch(() => {
  successNotification("Vaccination was removed");
});

async function getVaccination(patientId: string) {
  const { data: vaccineDTO } = await apiServer.get<VaccineDTO[]>(
    `/api/patient/${patientId}/vaccination`
  );
  return vaccineDTO;
}

async function createVaccineRecord({
  patientId,
  name,
  date,
  description,
}: {
  patientId: string;
  name: string;
  date: ISO8601;
  description: string;
}) {
  const { data: createdRecord } = await apiServer.post<VaccineDTO>(
    `/api/patient/${patientId}/vaccination`,
    {
      name,
      date,
      description,
    }
  );
  return createdRecord;
}

async function updateVaccineRecord({
  patientId,
  recordId,
  name,
  date,
  description,
}: {
  patientId: string;
  recordId: string;
  name: string;
  date: ISO8601;
  description: string;
}) {
  const { data: updatedRecord } = await apiServer.post<VaccineDTO>(
    `/api/patient/${patientId}/vaccination/${recordId}`,
    {
      name,
      date,
      description,
    }
  );
  return updatedRecord;
}

async function removeVaccineRecord({
  patientId,
  recordId,
}: {
  patientId: string;
  recordId: string;
}) {
  const { data: removedRecord } = await apiServer.delete<{ id: string }>(
    `/api/patient/${patientId}/vaccination/${recordId}`
  );
  return removedRecord.id;
}

export const vaccination = createStore<VaccineDTO[]>([])
  .on(loadVaccination.doneData, (state, vaccination) => vaccination)
  .on(addVaccine.doneData, (state, vaccineDTO) => [...state, vaccineDTO])
  .on(editVaccine.doneData, (state, vaccineDTO) =>
    state.map((record) => (record.id === vaccineDTO.id ? vaccineDTO : record))
  )
  .on(deleteVaccine.doneData, (state, recordId) =>
    state.filter((record) => record.id !== recordId)
  );
