import { CaseHistoryRecord, CaseHistoryRecordDTO } from "./typeDefinitions";

export function caseHistoryRecordAdapter(
  record: CaseHistoryRecordDTO
): CaseHistoryRecord {
  return {
    id: record.objectId,
    type: record.type,
    symptom: record.symptom,
    description: record.description,
    periodFrom: record.type === "past" ? record.periodFrom : undefined,
    periodTo: record.type === "past" ? record.periodTo : undefined,
    createdAt: record.createdAt,
    updatedAt: record.updatedAt,
  };
}
