/*
  The Broadcast channel is used to notify other tabs about changing current session accoutn.
  The covered scenario: user has multiple tabs opened with the same account.
  If he signs in/out in one tab, he can still get to another tab and perform some actions,
  which will turn into an unexpected API errors, because session token is empty.
  The solution is to reload other tabs, so they get a fresh state.
*/

import { BroadcastChannel } from "broadcast-channel";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";

type LogOutProps = {
  onLogOutSignal: (event: MessageEvent<any>) => void;
};

type LogInProps = {
  onLogInSignal: (event: MessageEvent<any>) => void;
};

type AuthSignalContext = {
  notifyOthersAboutLogOut: () => void;
  notifyOthersAboutLogIn: () => void;
};

const AuthSignal = createContext<AuthSignalContext>({
  notifyOthersAboutLogOut: () => {},
  notifyOthersAboutLogIn: () => {},
});

export function AuthBroadcastSignalProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { notifyOthersAboutLogOut } = useLogoutBroadcastChannel({
    onLogOutSignal: () => window.location.reload(),
  });

  const { notifyOthersAboutLogIn } = useLogInBroadcastSignal({
    onLogInSignal: () => window.location.reload(),
  });

  return (
    <AuthSignal.Provider
      value={{ notifyOthersAboutLogOut, notifyOthersAboutLogIn }}
    >
      {children}
    </AuthSignal.Provider>
  );
}

export function useAuthBroadcastSignal() {
  return useContext(AuthSignal);
}

function useLogInBroadcastSignal({ onLogInSignal }: LogInProps) {
  const logInChannel = useMemo(() => new BroadcastChannel("login"), []);

  useEffect(() => {
    logInChannel.addEventListener("message", (event) => {
      onLogInSignal(event);
      logInChannel.close();
    });
  }, [logInChannel, onLogInSignal]);

  const notifyOthersAboutLogIn = useCallback(() => {
    logInChannel.postMessage("log in");
  }, [logInChannel]);

  return { notifyOthersAboutLogIn };
}

function useLogoutBroadcastChannel({ onLogOutSignal }: LogOutProps) {
  const logOutChannel = useMemo(() => new BroadcastChannel("logout"), []);

  useEffect(() => {
    logOutChannel.addEventListener("message", (event) => {
      onLogOutSignal(event);
      logOutChannel.close();
    });
  }, [logOutChannel, onLogOutSignal]);

  const notifyOthersAboutLogOut = useCallback(() => {
    logOutChannel.postMessage("log out");
  }, [logOutChannel]);

  return { notifyOthersAboutLogOut };
}
