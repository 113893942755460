import styled from "@emotion/styled";
import { doctorsListPage } from "OurDoctors/routes";
import { RouteLocation } from "Patient/Router";
import { patientRegistrationPage } from "Patient/routes";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import {
  Block,
  boxShadow,
  Button,
  color,
  Flex,
  Grid,
  HeroBanner,
  Typography,
} from "ui-kit";
import { GeneralPage } from "ui-kit/Page";
import homeBg from "./hero-banner.jpg";

export const HomePage = memo(() => {
  const navigate = useNavigate();
  const redirectToDoctorsList = () =>
    navigate(patientRegistrationPage, {
      state: { from: { pathname: doctorsListPage } } as RouteLocation,
    });
  return (
    <GeneralPage>
      <HeroSection image={homeBg}>
        <Overlay />
        <HeroInfoContainer
          gridRowGap={3}
          gridTemplateColumns="100%"
          gridTemplateRows="auto auto"
        >
          <ConsultationTextContainer textAlign="center">
            <Block marginBottom={4}>
              <Typography type="h2" fontColor="N0" fontWeight={500}>
                Online consultation with the best doctors in the world anywhere,
                anytime, 24/7/365 days a year. <br />
                Only up to $99 without insurance. Cheaper than an offline
                doctor.
              </Typography>
            </Block>
            <Block>
              <HeroButton filled onClick={redirectToDoctorsList}>
                Talk to your doctor
              </HeroButton>
            </Block>
          </ConsultationTextContainer>
          <Grid
            alignItems="center"
            gridTemplateColumns="repeat(4, 1fr)"
            gridColumnGap={14}
          >
            <Advantage fontColor="N0" type="h2">
              Security and privacy
            </Advantage>
            <Advantage fontColor="N0" type="h2">
              Certified doctors
            </Advantage>
            <Advantage fontColor="N0" type="h2">
              Simple use system
            </Advantage>
            <Advantage fontColor="N0" type="h2">
              Money-back guarantee
            </Advantage>
          </Grid>
        </HeroInfoContainer>
      </HeroSection>
      <HowItWorksContainer textAlign="center">
        <Block marginBottom={6}>
          <Typography as="h2" type="h1">
            How it works?
          </Typography>
        </Block>
        <HowItWorksStepsContainer gridTemplateColumns="repeat(3, 1fr)">
          <Block>
            <Block marginBottom={2} position="relative">
              <StepNumber alignItems="center" justifyContent="center">
                1
              </StepNumber>
              <HowItWorksConnection width="50%" left="auto" right="0" />
            </Block>
            <Block paddingLeft={3} paddingRight={3}>
              <Block>
                <Typography as="h3" fontColor="G200" type="h2">
                  Register now
                </Typography>
              </Block>
              <Block>
                <Typography fontWeight={400} type="h3">
                  Register in a couple of minutes
                </Typography>
              </Block>
            </Block>
          </Block>
          <Block>
            <Block marginBottom={2} position="relative">
              <StepNumber alignItems="center" justifyContent="center">
                2
              </StepNumber>
              <HowItWorksConnection width="100%" left="auto" right="0" />
            </Block>
            <Block paddingLeft={3} paddingRight={3}>
              <Block>
                <Typography as="h3" fontColor="G200" type="h2">
                  Find a doctor
                </Typography>
              </Block>
              <Block>
                <Typography fontWeight={400} type="h3">
                  Choose a doctor and tell them about your health issues
                </Typography>
              </Block>
            </Block>
          </Block>
          <Block>
            <Block marginBottom={2} position="relative">
              <StepNumber alignItems="center" justifyContent="center">
                3
              </StepNumber>
              <HowItWorksConnection width="50%" left="0" right="auto" />
            </Block>
            <Block paddingLeft={3} paddingRight={3}>
              <Block>
                <Typography as="h3" fontColor="G200" type="h2">
                  Get a consultation
                </Typography>
              </Block>
              <Block>
                <Typography fontWeight={400} type="h3">
                  Get a consultation and a doctor's prescription online here!
                </Typography>
              </Block>
            </Block>
          </Block>
        </HowItWorksStepsContainer>
      </HowItWorksContainer>
    </GeneralPage>
  );
});

const HeroSection = styled(HeroBanner)`
  height: calc(100vh - 90px);
  position: relative;
  & .image {
    background-size: cover;
  }
`;

const HeroInfoContainer = styled(Grid)`
  align-items: end;
  height: 100%;
  margin: 0 auto;
  padding: 32px 32px;
  position: relative;
  width: 1344px;
`;

const HeroButton = styled(Button)`
  background-color: ${color.O100};
  border-color: ${color.O100};
  height: auto;
  padding-bottom: 16px;
  padding-top: 16px;
  font-size: 24px;
  line-height: 32px;
  width: 100%;
  max-width: 320px;
  &:hover,
  &:focus {
    background-color: ${color.O200};
    border-color: ${color.O200};
  }
`;

const Overlay = styled.div`
  background-color: rgba(10, 10, 10, 0.28);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
`;

const ConsultationTextContainer = styled(Block.withComponent("section"))`
  margin: 0 0 0 auto;
  width: 400px;
`;

const Advantage = styled(Typography)`
  padding-left: 32px;
  position: relative;
  &::before {
    border-radius: 50%;
    background-color: ${color.G200};
    content: "";
    display: inline-block;
    left: 0;
    margin-top: -10px;
    top: 50%;
    position: absolute;
    height: 20px;
    width: 20px;
  }
`;

const HowItWorksContainer = styled(Block.withComponent("section"))`
  margin: 56px auto 120px;
  width: 1208px;
`;

const HowItWorksStepsContainer = styled(Grid)`
  grid-row: 1 / 1;
`;

const StepNumber = styled(Flex)`
  background-color: ${color.N0};
  border-radius: 50%;
  box-shadow: ${boxShadow.normal};
  color: ${color.N400};
  font-size: 36px;
  font-weight: 700;
  height: 74px;
  line-height: 68px;
  margin: 0 auto;
  position: relative;
  width: 74px;
  z-index: 1;
`;

type ConnectionLine = {
  width: string;
  left: string;
  right: string;
};

const HowItWorksConnection = styled(Block)<ConnectionLine>`
  position: absolute;
  border-bottom: 1px dashed ${color.N300};
  height: 1px;
  right: 0;
  top: 50%;
  width: ${({ width }) => width};
  z-index: 0;
`;
