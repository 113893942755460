import {
  toDoctorPatientRecordConclusionsList,
  useDoctorId,
} from "Doctor/Router";
import { useStore } from "effector-react";
import { addConclusion } from "Patient/Record";
import { memo, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Block,
  Button,
  ErrorMessage,
  Flex,
  Input,
  LoaderWithOverlay,
  Textarea,
  Typography,
} from "ui-kit";
import { DataContainer } from "../DataContainer";

type ConclusionFormData = {
  diseaseName: string;
  prescription?: string;
  rehabilitation?: string;
  medicalExaminations?: string;
  hasConclusionNote?: boolean;
};

type Props = {
  patientId: string;
};

export const AddConclusion = memo(({ patientId }: Props) => {
  const doctorId = useDoctorId();
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
    watch,
  } = useForm<ConclusionFormData>();
  const navigate = useNavigate();

  const save = ({
    diseaseName,
    prescription,
    rehabilitation,
    medicalExaminations,
  }: ConclusionFormData) => {
    addConclusion({
      patientId,
      diseaseName,
      prescription,
      rehabilitation,
      medicalExaminations,
    }).then(() =>
      navigate(toDoctorPatientRecordConclusionsList({ patientId, doctorId }))
    );
  };

  const prescriptionValue = watch("prescription");
  const rehabilitationValue = watch("rehabilitation");
  const medicalExaminationsValue = watch("medicalExaminations");

  useEffect(() => {
    register("hasConclusionNote", {
      validate: () => {
        if (
          !(
            prescriptionValue?.trim() ||
            rehabilitationValue?.trim() ||
            medicalExaminationsValue?.trim()
          )
        ) {
          return "Please fill in at least one field for the patient's treatment strategy";
        }
        return true;
      },
    });
  }, [
    register,
    prescriptionValue,
    medicalExaminationsValue,
    rehabilitationValue,
  ]);

  useEffect(() => {
    if (
      prescriptionValue?.trim() ||
      rehabilitationValue?.trim() ||
      medicalExaminationsValue?.trim()
    ) {
      return clearErrors("hasConclusionNote");
    }
  }, [
    clearErrors,
    prescriptionValue,
    medicalExaminationsValue,
    rehabilitationValue,
  ]);

  const cancel = useCallback(() => {
    reset();
    navigate(toDoctorPatientRecordConclusionsList({ patientId, doctorId }));
  }, [navigate, patientId, reset, doctorId]);

  const isAdding = useStore(addConclusion.pending);

  return (
    <>
      {isAdding && <LoaderWithOverlay />}
      <Form onSubmit={handleSubmit(save)} onReset={cancel}>
        <Block marginBottom={3} textAlign="center">
          <Typography as="h3" fontColor="N300" type="h2">
            Add conclusion
          </Typography>
        </Block>
        <Block marginBottom={3}>
          <Input
            hasErrors={!!errors["diseaseName"]}
            errorMessage={errors["diseaseName"]?.message}
            label="Name of the patient's disease"
            placeholder="Write the name of the patient's disease"
            {...register("diseaseName", {
              required: "Please, add disease name",
            })}
          />
        </Block>
        <Block marginBottom={4}>
          <Block marginBottom={1}>
            <Textarea
              hasErrors={
                !!errors["prescription"] || !!errors["hasConclusionNote"]
              }
              errorMessage={errors["prescription"]?.message}
              label="Prescription"
              placeholder="Write a prescription for treating a patient"
              maxLength={500}
              {...register("prescription")}
            />
          </Block>
          <Block marginBottom={1}>
            <Textarea
              hasErrors={
                !!errors["rehabilitation"] || !!errors["hasConclusionNote"]
              }
              errorMessage={errors["rehabilitation"]?.message}
              label="Rehabilitation"
              placeholder="Describe the next steps to rehabilitate the patient"
              maxLength={500}
              {...register("rehabilitation")}
            />
          </Block>
          <Block>
            <Textarea
              hasErrors={
                !!errors["medicalExaminations"] || !!errors["hasConclusionNote"]
              }
              errorMessage={errors["medicalExaminations"]?.message}
              label="Medical examinations"
              placeholder="Write down what lab tests are necessary for the patient"
              maxLength={500}
              {...register("medicalExaminations")}
            />
          </Block>
          {errors["hasConclusionNote"] && (
            <Block>
              <ErrorMessage>{errors["hasConclusionNote"].message}</ErrorMessage>
            </Block>
          )}
        </Block>
        <Flex alignItems="center" justifyContent="center">
          <Block>
            <Button filled type="submit">
              Save
            </Button>
          </Block>
          <Block marginLeft={3}>
            <Button type="reset">Cancel</Button>
          </Block>
        </Flex>
      </Form>
    </>
  );
});

const Form = DataContainer.withComponent("form");
