import { apiServer } from "apiConnection";
import { AxiosError } from "axios";
import { createEffect, createStore } from "effector";
import { errorNotification, successNotification } from "ui-kit";
import { LabTestDTO } from "./typeDefinitions";

export const loadLabTests = createEffect("loadLabTests", {
  handler: async ({ patientId }: { patientId: string }) =>
    await getLabTests(patientId),
});

export const uploadLabTest = createEffect("uploadLabTest", {
  handler: async ({
    patientId,
    documents,
    description,
  }: {
    patientId: string;
    documents: File[];
    description?: string;
  }) => await uploadLabTestRequest({ patientId, documents, description }),
});

uploadLabTest.doneData.watch(() => successNotification("Lab test was added"));
uploadLabTest.failData.watch((error) => {
  const errorMessage = (error as AxiosError<{ message: string }>).response?.data
    .message;
  if (errorMessage) {
    errorNotification(errorMessage);
  }
});

export const editLabTest = createEffect("editLabTest", {
  handler: async ({
    patientId,
    labTestId,
    documents,
    description,
  }: {
    patientId: string;
    labTestId: string;
    documents: File[];
    description?: string;
  }) =>
    await editLabTestRequest({ patientId, labTestId, documents, description }),
});

editLabTest.doneData.watch(() => successNotification("Lab test was updated"));
editLabTest.failData.watch((error) => {
  const errorMessage = (error as AxiosError<{ message: string }>).response?.data
    .message;
  if (errorMessage) {
    errorNotification(errorMessage);
  }
});

export const deleteLabTest = createEffect("deleteLabTest", {
  handler: async ({
    patientId,
    documentId,
  }: {
    patientId: string;
    documentId: string;
  }) => await removeLabTest({ patientId, documentId }),
});

deleteLabTest.doneData.watch(() => successNotification("Lab test was removed"));

async function getLabTests(patientId: string) {
  const { data: labTests } = await apiServer.get<LabTestDTO[]>(
    `/api/patient/${patientId}/lab-tests`
  );
  return labTests;
}

async function uploadLabTestRequest({
  patientId,
  documents,
  description,
}: {
  patientId: string;
  documents: File[];
  description?: string;
}) {
  const labTestsData = new FormData();
  documents.forEach((document) => {
    labTestsData.append("documents", document, document.name);
  });
  if (description) {
    labTestsData.append("description", description);
  }
  const { data: uploadedLabTests } = await apiServer.post<LabTestDTO>(
    `/api/patient/${patientId}/lab-tests`,
    labTestsData
  );
  return uploadedLabTests;
}

async function editLabTestRequest({
  patientId,
  labTestId,
  documents,
  description,
}: {
  patientId: string;
  labTestId: string;
  documents: File[];
  description?: string;
}) {
  const labTestsData = new FormData();
  documents.forEach((document) => {
    labTestsData.append("documents", document, document.name);
  });
  if (description) {
    labTestsData.append("description", description);
  }
  const { data: updatedLabTest } = await apiServer.post<LabTestDTO>(
    `/api/patient/${patientId}/lab-tests/${labTestId}`,
    labTestsData
  );
  return updatedLabTest;
}

async function removeLabTest({
  patientId,
  documentId,
}: {
  patientId: string;
  documentId: string;
}) {
  const { data: removedLabTestId } = await apiServer.delete<string>(
    `/api/patient/${patientId}/lab-tests/${documentId}`
  );
  return removedLabTestId;
}

export const labTests = createStore<LabTestDTO[]>([])
  .on(loadLabTests.doneData, (state, labTests) => labTests)
  .on(uploadLabTest.doneData, (state, document) => [...state, document])
  .on(editLabTest.doneData, (state, updatedLabTest) =>
    state.map((test) =>
      test.objectId === updatedLabTest.objectId ? updatedLabTest : test
    )
  )
  .on(deleteLabTest.doneData, (state, documentId) =>
    state.filter((doc) => doc.objectId !== documentId)
  );
