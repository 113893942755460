export const familyDiseases = [
  "asthma",
  "cancer",
  "stroke",
  "diabetes",
  "heart disease",
  "high cholesterol",
  "depression",
  "arterial pressure",
  "allergies",
  "metabolic disorders",
  "thyroid diseases",
  "obesity",
  "sickle cell anemia",
  "cystic fibrosis",
  "eczema",
  "Tay-Sachs",
  "huntington disease",
  "thalassemia",
  "down syndrome",
  "other",
] as const;

export type FamilyDiseasesDTO = Array<(typeof familyDiseases)[number]>;
