import styled from "@emotion/styled";
import { Flex, Loader } from "ui-kit";

export const LoaderWithSpacing = () => (
  <LoaderContainer alignContent="center" justifyContent="center">
    <Loader />
  </LoaderContainer>
);

const LoaderContainer = styled(Flex)`
  align-content: center;
  justify-content: center;
  margin: 212px 0;
`;
