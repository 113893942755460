import { apiServer } from "apiConnection";
import { createEffect, createStore } from "effector";
import { successNotification } from "ui-kit";
import { LifeStyleDTO } from "./typeDefinitions";

export const loadLifeStyle = createEffect("loadLifeStyle", {
  handler: async (patientId: string) => await getLifeStyle(patientId),
});

export const saveLifeStyle = createEffect("saveLifeStyle", {
  handler: async ({
    patientId,
    lifeStyle,
  }: {
    patientId: string;
    lifeStyle: LifeStyleDTO;
  }) => await saveLifeStyleRequest(patientId, lifeStyle),
});

saveLifeStyle.doneData.watch(() => {
  successNotification("Life style was updated");
});

async function getLifeStyle(patientId: string) {
  const { data: lifeStyle } = await apiServer.get<LifeStyleDTO>(
    `/api/patient/${patientId}/lifestyle`
  );
  return lifeStyle;
}

async function saveLifeStyleRequest(
  patientId: string,
  lifeStyle: LifeStyleDTO
) {
  const { data: updatedLifeStyle } = await apiServer.post(
    `/api/patient/${patientId}/lifestyle`,
    {
      lifeStyle,
    }
  );
  return updatedLifeStyle;
}

export const lifeStyle = createStore<Partial<LifeStyleDTO>>({}).on(
  [loadLifeStyle.doneData, saveLifeStyle.doneData],
  (state, lifeStyle) => lifeStyle
);
