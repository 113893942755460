import { cancelRequest } from "apiConnection";
import { memo, ReactNode, useEffect, useState } from "react";
import { loadSession } from "./loadSession";

type Props = {
  children: ReactNode;
};

export const Session = memo(({ children }: Props) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadSession().finally(() => setIsLoading(false));
    return () => {
      cancelRequest();
    };
  }, []);

  if (isLoading) {
    return null;
  }

  return <>{children}</>;
});
