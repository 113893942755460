import styled from "@emotion/styled";
import { memo, useEffect, useMemo, useState } from "react";
import { getTrackBackground, Range } from "react-range";
import { Block, color, Grid, SpeakerOffIcon, SpeakerOnIcon } from "ui-kit";

type Props = {
  stream?: MediaStream;
  onChange?: (audio: MediaStreamTrack) => void;
};

export const VolumeAdjuster = memo(({ stream, onChange }: Props) => {
  const [volume, setVolume] = useState(0.5);
  const AudioContext = window.AudioContext || window.webkitAudioContext;
  const audioContext = useMemo(() => new AudioContext(), [AudioContext]);

  useEffect(() => {
    if (stream) {
      const mediaStreamSource = audioContext.createMediaStreamSource(stream);
      const mediaStreamDestination = audioContext.createMediaStreamDestination();
      const gainNode = audioContext.createGain();
      mediaStreamSource.connect(gainNode);
      gainNode.connect(mediaStreamDestination);
      gainNode.gain.value = volume;
      const modifiedAudioTrack = mediaStreamDestination.stream.getAudioTracks()[0];
      onChange && onChange(modifiedAudioTrack);
    }
  }, [audioContext, stream, volume, onChange]);

  return (
    <Grid
      alignItems="center"
      gridColumnGap={1}
      gridTemplateColumns="auto 1fr auto"
      width="248px"
    >
      <SpeakerOffIcon />
      <Range
        step={0.01}
        min={0}
        max={1}
        values={[volume]}
        onChange={(volumes) => {
          setVolume(volumes[0] || 0);
        }}
        renderTrack={({ props, children }) => (
          <VolumeRangeTrack {...props} volume={[volume]}>
            {children}
          </VolumeRangeTrack>
        )}
        renderThumb={({ props }) => <VolumeRangeThumb {...props} />}
      />
      <SpeakerOnIcon />
    </Grid>
  );
});

type RangeState = {
  volume: number[];
};

const VolumeRangeTrack = styled(Block)<RangeState>`
  background: ${({ volume }) =>
    getTrackBackground({
      values: volume,
      colors: [color.G200, color.N200],
      min: 0,
      max: 1,
    })};
  border-radius: 2px;
  height: 5px;
  width: 100%;
`;

const VolumeRangeThumb = styled(Block)`
  background-color: ${color.G200};
  border-radius: 50%;
  height: 12px;
  width: 12px;
`;
