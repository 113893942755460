import styled from "@emotion/styled";
import { doctorLoginPage } from "Doctor/Router";
import { patientLoginPage } from "Patient/routes";
import { memo } from "react";
import { Link } from "react-router-dom";
import { Block, color, Flex, Typography } from "ui-kit";
import { GeneralPage } from "ui-kit/Page";

export const LoginHomePage = memo(() => (
  <GeneralPage>
    <Flex flexDirection="column" justifyContent="center" height="100%">
      <Block textAlign="center" marginBottom={3}>
        <Typography as="h2" type="h2">
          Are you entering as a patient or a doctor?
        </Typography>
      </Block>
      <Flex justifyContent="center">
        <Block>
          <Link to={patientLoginPage}>
            <AuthRedirectButton>
              <AuthButtonTitle fontColor="N0" type="h2" fontWeight="bold">
                For patients
              </AuthButtonTitle>
            </AuthRedirectButton>
          </Link>
        </Block>
        <Block marginLeft={3}>
          <Link to={doctorLoginPage}>
            <AuthRedirectButton>
              <AuthButtonTitle fontColor="N0" type="h2" fontWeight="bold">
                For doctors
              </AuthButtonTitle>
            </AuthRedirectButton>
          </Link>
        </Block>
      </Flex>
    </Flex>
  </GeneralPage>
));

const AuthRedirectButton = styled.span`
  background-color: ${color.G200};
  border-radius: 6px;
  color: ${color.N0};
  display: inline-block;
  padding: 40px;
  text-align: center;
  &:hover {
    background-color: ${color.G300};
    svg {
      fill: ${color.G300};
    }
  }
`;

const AuthButtonTitle = styled(Typography)`
  display: inline-block;
  text-align: center;
  width: 100%;
`;
