import styled from "@emotion/styled";
import {
  memo,
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Block, ErrorMessage, Flex } from "ui-kit";
import { VolumeAnimator } from "./VolumeAnimator";
import { VolumeRenderer } from "./VolumeRenderer";

type Props = {
  stream?: MediaStream;
  enabled?: boolean;
};

export const VolumeMeter = memo(({ stream, enabled = true }: Props) => {
  const AudioContext = window.AudioContext || window.webkitAudioContext;
  const audioContext = useMemo(() => new AudioContext(), [AudioContext]);
  const canvas: RefObject<HTMLCanvasElement> = useRef(null);
  const [contextState, setContextState] = useState(audioContext.state);
  const [unableToProvideData, setUnableToProvideData] = useState(false);

  const rendererRef: MutableRefObject<VolumeRenderer | null> = useRef(null);
  const animatorRef: MutableRefObject<VolumeAnimator | null> = useRef(null);

  const onStateChange = useCallback(() => {
    setContextState(audioContext.state);
  }, [audioContext]);

  useEffect(() => {
    audioContext.addEventListener("statechange", onStateChange);
    return () => {
      audioContext.removeEventListener("statechange", onStateChange);
    };
  }, [audioContext, onStateChange]);

  useEffect(() => {
    setContextState(audioContext.state);
  }, [audioContext]);

  const onUnableToProvideData = useCallback(() => {
    setUnableToProvideData(true);
  }, []);
  const onAbleToProvideData = useCallback(() => {
    setUnableToProvideData(false);
  }, []);

  useEffect(() => {
    if (stream) {
      const audioTrack = stream.getAudioTracks()[0];
      if (audioTrack) {
        monitorAudioTrack(audioTrack, {
          onEnabledChanged: () => {},
          onStopCalled: () => {},
        });
        setUnableToProvideData(audioTrack.muted);
        audioTrack.addEventListener("mute", onUnableToProvideData);
        audioTrack.addEventListener("unmute", onAbleToProvideData);
      }
      return () => {
        audioTrack.removeEventListener("mute", onUnableToProvideData);
        audioTrack.removeEventListener("unmute", onAbleToProvideData);
      };
    }
  }, [stream, onAbleToProvideData, onUnableToProvideData]);

  useEffect(() => {
    if (canvas.current) {
      const canvasCtx = setupCanvas(canvas.current);
      rendererRef.current = new VolumeRenderer(canvasCtx, {
        height: canvas.current.height,
        width: canvas.current.width,
      });
    }
  }, []);

  useEffect(() => {
    if (rendererRef.current) {
      animatorRef.current = new VolumeAnimator(
        audioContext,
        rendererRef.current
      );
    }
  }, [audioContext]);

  useEffect(() => {
    if (animatorRef.current) {
      animatorRef.current.changeStream(stream);
      if (enabled) {
        animatorRef.current.start();
      } else {
        animatorRef.current.stop();
      }
    }
  }, [enabled, stream]);

  // prettier-ignore
  const error = 
      !enabled                ?   ("Disabled")                                   : 
      unableToProvideData     ?   ("Audio Input halted")                         : 
                                  null;

  return (
    <Flex flexDirection="column" justifyContent="flex-start">
      <MeterComponent
        isVisible={!(contextState === "running" && error)}
        ref={canvas}
      />
      {contextState === "running" && error && (
        <Block>
          <ErrorMessage>{error}</ErrorMessage>
        </Block>
      )}
    </Flex>
  );
});

const MeterComponent = styled.canvas<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  height: 16px;
  width: 248px;
`;

type MonitorOptions = {
  onEnabledChanged: (e: boolean) => unknown;
  onStopCalled: () => unknown;
};

function monitorAudioTrack(
  track: MediaStreamTrack & { watched?: boolean },
  { onEnabledChanged, onStopCalled }: MonitorOptions
) {
  if (track.watched) {
    return;
  }
  const originalEnabled = Object.getOwnPropertyDescriptor(
    Object.getPrototypeOf(track),
    "enabled"
  );
  if (!originalEnabled) {
    throw new Error('Cannot stalk "enabled"');
  }

  const originalStop = Object.getOwnPropertyDescriptor(
    Object.getPrototypeOf(track),
    "stop"
  );
  if (!originalStop) {
    throw new Error('Cannot stalk "stop"');
  }

  let _enabled = track.enabled;

  Object.defineProperty(track, "enabled", {
    ...originalEnabled,
    get: function () {
      return _enabled;
    },
    set: function (e) {
      _enabled = e;
      originalEnabled.set!.call(track, e);
      onEnabledChanged(e);
    },
  });

  Object.defineProperty(track, "stop", {
    ...originalStop,
    value: function () {
      onStopCalled();
      return originalStop.value.call(track);
    },
  });

  Object.defineProperty(track, "watched", {
    value: true,
  });
}

function setupCanvas(
  canvas: HTMLCanvasElement | null
): CanvasRenderingContext2D {
  if (!canvas) {
    throw new Error("Cannot get a reference to the canvas");
  }
  const dpr = window.devicePixelRatio || 1;
  const rect = canvas.getBoundingClientRect();
  canvas.width = rect.width * dpr;
  canvas.height = rect.height * dpr;
  const canvasCtx = canvas.getContext("2d");
  if (!canvasCtx) {
    throw new Error("2D context not avaiable");
  }
  canvasCtx.scale(dpr, dpr);
  return canvasCtx;
}
