import styled from "@emotion/styled";
import { logOut } from "Doctor/Auth";
import { Model as DoctorDTO } from "Doctor/Model";
import { toDoctorProfileSettingsPage } from "Doctor/Router";
import { homePage } from "Home/routes";
import { useAuthBroadcastSignal } from "Session";
import { memo, useCallback } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Block, DoorOutIcon, Flex, GearIcon, Typography, color } from "ui-kit";

type ProfileMenuProps = {
  doctor: DoctorDTO;
  hideMenu?: () => void;
};

export const QuickMenu = memo(({ doctor, hideMenu }: ProfileMenuProps) => {
  const navigate = useNavigate();
  const goHome = useCallback(() => {
    navigate(homePage);
  }, [navigate]);

  const { notifyOthersAboutLogOut } = useAuthBroadcastSignal();

  return (
    <ProfileMenuContainer>
      {!doctor.onModeration && (
        <ProfileMenuLink to={toDoctorProfileSettingsPage(doctor.id)}>
          <GearIcon fill={color.N300} />
          <Block paddingLeft={1}>
            <Typography className="title" type="h5">
              Settings
            </Typography>
          </Block>
        </ProfileMenuLink>
      )}
      <LogOutButton
        onClick={() => {
          logOut()
            .then(() => hideMenu && hideMenu())
            .then(goHome)
            .then(notifyOthersAboutLogOut)
            .catch(alert);
        }}
      >
        <DoorOutIcon className="icon" fill={color.N300} />
        <LogOutLabelContainer>
          <Typography className="title" type="h5">
            Log out
          </Typography>
        </LogOutLabelContainer>
      </LogOutButton>
    </ProfileMenuContainer>
  );
});

const ProfileMenuContainer = styled(Block)`
  background-color: ${color.N0};
  border: 1px solid ${color.N200};
  border-radius: 4px;
  margin-top: 4px;
  max-width: 100%;
  min-width: 176px;
  padding: 10px 8px 8px;
  // TODO: when find use case, move positioning into a parent component where menu is used
  position: absolute;
  top: 100%;
  z-index: 1;
`;

const ProfileMenuItem = styled(Flex)`
  align-items: center;
  border-radius: 2px;
  margin-bottom: 2px;
  padding: 6px 8px;
  text-decoration: none;
  &:hover,
  &:focus {
    background-color: ${color.N100};
  }
  &:hover .title,
  &:focus .title {
    color: ${color.G200};
  }
`;

const ProfileMenuLink = styled(ProfileMenuItem)`
  &.active {
    background-color: ${color.N100};
  }
  &.active .title {
    color: ${color.G200};
  }
`.withComponent(NavLink);

const LogOutButton = styled(ProfileMenuItem.withComponent("button"))`
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  width: 100%;
  & .icon {
    margin-left: 2px;
  }
`;

const LogOutLabelContainer = styled(Block)`
  padding-left: 6px;
`;
