import "index.css";
import { memo, StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Helmet } from "react-helmet";
import { BrowserRouter } from "react-router-dom";
import { RootRouter } from "RootRouter";
import * as serviceWorker from "serviceWorker";
import { initializeUniversalDateTime } from "shared-functions/date";
import { NotificationToastContainer } from "ui-kit";
import { Session, AuthBroadcastSignalProvider } from "./Session";

const RootComponent = memo(() => {
  return (
    <>
      <Helmet defaultTitle="PocketDoctor" titleTemplate="%s - PocketDoctor" />
      <StrictMode>
        <BrowserRouter>
          <Session>
            <AuthBroadcastSignalProvider>
              <RootRouter />
            </AuthBroadcastSignalProvider>
            <NotificationToastContainer />
          </Session>
        </BrowserRouter>
      </StrictMode>
    </>
  );
});

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(<RootComponent />);

initializeUniversalDateTime();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
