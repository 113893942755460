import styled from "@emotion/styled";
import { AudioTrack, VideoTrack } from "twilio-video";
import { memo, useEffect, useRef } from "react";
import { Block } from "ui-kit";

type Props = {
  audioTrack: AudioTrack | null;
  videoTrack: VideoTrack;
  className?: string;
  fullWidth?: boolean;
  mirrored?: boolean;
};

export const VideoPlayer = memo(
  ({ className, fullWidth, mirrored, audioTrack, videoTrack }: Props) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const audioEl = useRef<HTMLAudioElement | null>(null);

    useEffect(() => {
      const videoEl = videoRef.current;
      if (videoEl) {
        videoTrack.attach(videoEl);
      }
      return () => {
        if (videoEl) {
          videoTrack.detach(videoEl);
          // See: https://github.com/twilio/twilio-video.js/issues/1528
          videoEl.srcObject = null;
        }
      };
    }, [videoTrack]);

    useEffect(() => {
      audioEl.current = audioTrack ? audioTrack.attach() : null;
      if (audioEl.current && containerRef.current) {
        containerRef.current.appendChild(audioEl.current);
      }
      return () =>
        audioTrack?.detach().forEach((el) => {
          el.remove();
          // See: https://github.com/twilio/twilio-video.js/issues/1528
          el.srcObject = null;
        });
    }, [audioTrack]);

    return (
      <VideoPlayerContainer
        fullWidth={fullWidth}
        mirrored={mirrored}
        className={className}
        position="relative"
        ref={containerRef}
      >
        <video autoPlay ref={videoRef} className="video" />
      </VideoPlayerContainer>
    );
  }
);

type PlayerState = {
  fullWidth?: boolean;
  mirrored?: boolean;
};

const VideoPlayerContainer = styled(Block)<PlayerState>`
  height: 100%;
  width: 100%;
  .video {
    height: 100%;
    object-fit: ${({ fullWidth }) =>
      fullWidth === false ? "contain" : "cover"};
    position: absolute;
    transform: ${({ mirrored }) => `scaleX(${mirrored === true ? "-1" : "1"})`};
    width: 100%;
    z-index: 1;
  }
`;
