import { memo } from "react";
import { Helmet } from "react-helmet";
import { Block, Typography } from "ui-kit";
import { GeneralPage } from "ui-kit/Page";
import { ContentSection, TextContent } from "./Content";

export const DMCAPage = memo(() => (
  <GeneralPage>
    <Helmet>
      <title>DMCA</title>
    </Helmet>
    <Block textAlign="center" marginTop={8} marginBottom={5}>
      <Typography as="h1" type="h1" fontColor="G200">
        Digital Millennium Copyright Act (DMCA) Protection &amp; Takedown
      </Typography>
    </Block>
    <TextContent>
      <ContentSection>
        <Typography as="h3" type="h3">
          Report alleged copyright infringement
        </Typography>
        <p>
          PocketDoctor responds to notices of alleged infringement that comply
          with the Digital Millennium Copyright Act (the text of which can be
          found at the U.S. Copyright Office Web Site,{" "}
          <a href="https://www.copyright.gov">https://www.copyright.gov</a>) and
          other applicable intellectual property laws.
        </p>
        <p>
          Our response may include removing or disabling access to material
          claimed to be the subject of infringing activity and/or terminating
          subscribers. If we remove or disable access in response to such a
          notice, we may notify the owner or administrator of the affected site
          or content so that he or she can make a counter-notification. It is
          also our policy to document all notices of alleged infringement on
          which we act, including by sending a copy of the notice to one or more
          third parties or making it available to the public.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Infringement Notification
        </Typography>
        <p>
          <strong>
            IMPORTANT: Be sure you have a right to file a DMCA notice before
            doing so. Misrepresentations made in your notice regarding whether
            material or activity is infringing may expose you to liability for
            damages (including costs and attorneys' fees). Courts have found
            that before sending a notice, you must consider copyright defenses,
            limitations or exceptions (and have ordered a company to pay more
            than $100,000 in costs and attorney fees after finding that a
            company targeted content protected by the U.S. fair use doctrine).
            Contact an attorney before making a DMCA submission if you are not
            sure whether material available online infringes your copyright.
          </strong>
        </p>
        <p>
          If you have an intellectual property rights-related complaint about
          material posted on the Service, you may contact our Designated Agent
          at the following address:
        </p>
        <p>PocketDoctor, Inc.</p>
        <p>ATTN: Legal Department (Copyright Notification)</p>
        <p>16192 Coastal Highway, Lewes Delaware 19958, County of Sussex</p>
        <p>
          Email: <a href="mailto:info@pocketdoctor.us">info@pocketdoctor.us</a>
        </p>
        <p>
          <strong>Your Contact Information</strong>
        </p>
        <ul>
          <li>First name</li>
          <li>Last name</li>
          <li>Company name (if applicable)</li>
          <li>Copyright holder you represent (if applicable)</li>
          <li>Email address</li>
          <li>Country/Region</li>
        </ul>
      </ContentSection>
      <ContentSection>
        <Typography as="h3" type="h3">
          Your Contact Information
        </Typography>
        <ul>
          <li>First name</li>
          <li>Last name</li>
          <li>Company name (if applicable)</li>
          <li>Copyright holder you represent (if applicable)</li>
          <li>Email address</li>
          <li>Country/Region</li>
        </ul>
        <Typography as="h3" type="h3">
          Your Copyrighted Work
        </Typography>
        <ul>
          <li>
            Provide information that identifies in sufficient detail the
            copyrighted work that you believe has been infringed upon. If you
            claim that more than one work has been infringed, submit each
            additional work as a separate claim.
          </li>
        </ul>
        <Typography as="h3" type="h3">
          Where Can We See An Authorized Example Of The Work?
        </Typography>
        <ul>
          <li>
            Provide URL(s) (without any password or access code restrictions)
            not on PocketDoctor where an example of the copyrighted work can be
            viewed.
          </li>
        </ul>
        <Typography as="h3" type="h3">
          Location of infringing materia
        </Typography>
        <p>
          Please provide the URL(s), screenshots, or description of whereon
          PocketDoctor of the allegedly infringing material that you are asking
          us to remove.
        </p>
        <Typography as="h3" type="h3">
          Sworn Statement
        </Typography>
        <ul>
          <li>
            You must swear to the following or include the following statements
            in your DMCA takedown submission to PocketDoctor:
          </li>
        </ul>
        <p>
          "I have a good faith belief that use of the copyrighted materials
          described above as allegedly infringing is not authorized by the
          copyright owner, its agent, or the law."
        </p>
        <ul>
          <li>
            Please include an electronic or physical signature of the person
            authorized to act on behalf of the owner of the copyright or other
            right being infringed.
          </li>
        </ul>
        <Typography as="h3" type="h3">
          Printed Date
        </Typography>
        <p>
          Please note that we will not process your complaint if it isn't
          properly filled out or if the complaint is incomplete. If multiple
          PocketDoctor products are affected, please note that you must submit a
          notice for each affected product.
        </p>
      </ContentSection>
    </TextContent>
  </GeneralPage>
));
