import styled from "@emotion/styled";
import { useStore } from "effector-react";
import { DocumentIcon, editLabTest, LabTestDocument } from "Patient/Record";
import { usePatientId } from "Patient/Router";
import { memo, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, useForm } from "react-hook-form";
import {
  Block,
  Button,
  CloseRoundedIcon,
  color,
  ErrorMessage,
  errorNotification,
  Flex,
  Grid,
  LoaderWithOverlay,
  Textarea,
  Typography,
} from "ui-kit";

type Props = {
  labTestId: string;
  documents: LabTestDocument[];
  description?: string;
  afterFinish: () => void;
};

type LabTestFormData = {
  files?: File[];
  description?: string;
};

const MAX_FILES = 3;

export const EditLabTest = memo(
  ({ labTestId, documents, description, afterFinish }: Props) => {
    const [selectedFiles, selectFiles] = useSelectedFiles(documents);
    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
    } = useDropzone({
      accept: {
        "image/*": [".png", ".jpeg", ".jpg", ".webp", ".tiff"],
        "application/pdf": [".pdf"],
        "application/msword": [".doc"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [".docx"],
        "text/csv": [".csv"],
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
        "application/vnd.oasis.opendocument.text": [".odt"],
      },
      maxFiles: MAX_FILES,
      onDropAccepted: (acceptedFiles: File[]) => {
        clearErrors("files");
        const parseFilesWithoutDuplicatesPromise = acceptedFiles.filter(
          (file) =>
            selectedFiles.every(
              (selectedFile) => selectedFile.name !== file.name
            )
        );
        Promise.all(parseFilesWithoutDuplicatesPromise).then(
          (filesWithoutDuplicates) => {
            const nextFilesSet = [...selectedFiles, ...filesWithoutDuplicates];
            if (nextFilesSet.length > MAX_FILES) {
              return errorNotification(`Too many files. Maximum
              ${MAX_FILES} files`);
            }
            selectFiles(nextFilesSet);
          }
        );
      },
      onDropRejected: (fileRejections) => {
        fileRejections.forEach(({ file, errors }) => {
          errors.forEach((error) =>
            errorNotification(`${file.name}: ${error.message}`)
          );
        });
      },
    });
    const { control, register, handleSubmit, reset, setError, clearErrors } =
      useForm<LabTestFormData>({
        defaultValues: {
          description,
        },
      });
    const patientId = usePatientId();

    const cancel = useCallback(() => {
      reset();
      afterFinish();
    }, [afterFinish, reset]);

    const updateLabTests = (documentMeta: LabTestFormData) => {
      if (selectedFiles.length === 0) {
        return setError("files", {
          type: "required",
          message: "Plase, attach document",
        });
      }
      editLabTest({
        labTestId,
        patientId,
        documents: selectedFiles,
        description: documentMeta.description,
      }).then(afterFinish);
    };

    const removeDocument = useCallback(
      (file: File) => {
        selectFiles(
          selectedFiles.filter((sectedFile) => sectedFile.name !== file.name)
        );
      },
      [selectedFiles, selectFiles]
    );

    const isUpdating = useStore(editLabTest.pending);

    return (
      <Form onSubmit={handleSubmit(updateLabTests)} onReset={cancel}>
        {isUpdating && <LoaderWithOverlay />}
        <Block marginBottom={2}>
          <Controller
            control={control}
            name="files"
            render={({ fieldState: { error } }) => (
              <>
                <FileUploadInputContainer
                  {...getRootProps({
                    isDragActive,
                    isDragAccept,
                    isDragReject,
                  })}
                >
                  <input {...getInputProps()} />
                  <Block textAlign="center">
                    <Typography fontColor="N300" type="h5">
                      Drag and drop documents here, or click to select files.
                      Maximum {MAX_FILES} files
                    </Typography>
                  </Block>
                  <Block textAlign="center">
                    <Typography fontColor="N300" type="h5">
                      Supported formats: image, pdf, doc, docx, xls, csv
                    </Typography>
                  </Block>
                </FileUploadInputContainer>
                {error?.message ? (
                  <Block>
                    <ErrorMessage>{error?.message}</ErrorMessage>
                  </Block>
                ) : null}
              </>
            )}
          />
        </Block>

        {selectedFiles.length > 0 && (
          <Grid
            gridTemplateColumns="repeat(3, max-content)"
            gridTemplateRows="auto"
            gridColumnGap={2}
            marginBottom={3}
          >
            {selectedFiles.map((file) => (
              <DocumentPreview key={file.name}>
                <DocumentIcon
                  className="icon"
                  name={file.name}
                  fill={color.N300}
                />
                <Typography className="fileName" fontColor="N300" type="h5">
                  {file.name}
                </Typography>
                <CloseRoundedIcon
                  onClick={() => removeDocument(file)}
                  cursor="pointer"
                  width="16px"
                  height="16px"
                  fill={color.N300}
                />
              </DocumentPreview>
            ))}
          </Grid>
        )}
        <Block>
          <Textarea
            defaultValue={description}
            label="Description"
            placeholder="Describe the details of the laboratory testing"
            maxLength={500}
            {...register("description")}
          />
        </Block>
        <Flex alignItems="center" justifyContent="center" marginTop={4}>
          <Block marginRight={3}>
            <Button filled type="submit">
              Save
            </Button>
          </Block>
          <Block>
            <Button type="reset">Cancel</Button>
          </Block>
        </Flex>
      </Form>
    );
  }
);

function useSelectedFiles(
  labTestsDocuments: LabTestDocument[]
): [File[], React.Dispatch<React.SetStateAction<File[]>>] {
  const [selectedFiles, selectFiles] = useState<File[]>([]);

  useEffect(() => {
    Promise.all(labTestsDocuments.map((doc) => fetch(doc.url)))
      .then((allDocumentsResponse) =>
        Promise.all(allDocumentsResponse.map((docR) => docR.blob()))
      )
      .then((blobsFileList) =>
        blobsFileList.map(
          (blob, index) =>
            new File([blob], labTestsDocuments[index].name, { type: blob.type })
        )
      )
      .then(selectFiles);
  }, [labTestsDocuments]);

  return [selectedFiles, selectFiles];
}

type DragState = {
  isDragActive?: boolean;
};

const FileUploadInputContainer = styled(Block)<DragState>`
  ${({ isDragActive }) => `
      background-color: ${color.N100};
      border: 1px dashed ${color.N200};
      border-radius: 4px;
      opacity: ${isDragActive ? "0.7" : "1"};
      padding: 40px;
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
  `}
`;

const DocumentPreview = styled(Flex)`
  align-items: center;
  & .fileName {
    margin-right: 8px;
    max-width: 234px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & .icon {
    margin-right: 8px;
  }
`;

const Form = styled.form`
  position: relative;
`;
