import {
  CardNetworkBrand,
  PaymentIntentResult,
  PaymentMethod,
} from "@stripe/stripe-js";
import { TimeSlot } from "Visit";
import { apiServer } from "apiConnection";
import { AxiosError } from "axios";
import { createEffect, createStore } from "effector";
import { errorNotification, successNotification } from "ui-kit";

/**
 * Everything related to Payments processing is stored here, in a single place
 */

type PaymentCardRequest = {
  cardId?: PaymentMethod | string | null;
};

type PaymentCardResponse = {
  id: string;
  billing_details: {
    address: {
      city?: string;
      country?: string;
      line1?: string;
      line2?: string;
      postal_code?: string;
      state?: string;
    };
    email?: string;
    name?: string;
    phone?: string;
  };
  card: {
    brand: CardNetworkBrand;
    checks: {
      address_line1_check: null;
      address_postal_code_check: null;
      cvc_check: "pass";
    };
    country: "US";
    exp_month: 12;
    exp_year: 2034;
    fingerprint: "Xt5EWLLDS7FJjR1c";
    funding: "credit";
    generated_from: null;
    last4: "4242";
    networks: {
      available: ["visa"];
      preferred: null;
    };
    three_d_secure_usage: {
      supported: boolean;
    };
    wallet: null;
    created: number;
    customer: string;
    livemode: boolean;
    metadata: Object;
    redaction: null;
    type: "card";
  };
  isDefault?: boolean;
};

export const addCard = createEffect("addCard", {
  handler: async ({ cardId }: PaymentCardRequest) => {
    if (!cardId) {
      throw new Error(
        "Payment is failed due to unexpected system reason, apologize. Please, try again"
      );
    }
    try {
      const { data: savedCard } = await apiServer.post<PaymentCardResponse>(
        "/api/patient/card",
        {
          cardId,
        }
      );
      return savedCard;
    } catch (error: unknown) {
      throw new Error(
        (error as AxiosError<{ message: string }>).response?.data.message
      );
    }
  },
});

export const loadPaymentCards = createEffect("loadPaymentCards", {
  handler: async ({ customerId }: { customerId?: string }) => {
    try {
      const { data: paymentCards } = await apiServer.post<
        PaymentCardResponse[]
      >("/api/patient/cards-list", {
        customerId,
      });
      return paymentCards;
    } catch (error: unknown) {
      throw new Error(
        (error as AxiosError<{ message: string }>).response?.data.message
      );
    }
  },
});

export const switchDefaultCard = createEffect("switchDefaultCard", {
  handler: async (cardId: string) => {
    try {
      const { data: defaultCardId } = await apiServer.put<string>(
        "/api/patient/switch-card",
        {
          cardId,
        }
      );
      return defaultCardId;
    } catch (error: unknown) {
      throw new Error(
        (error as AxiosError<{ message: string }>).response?.data.message
      );
    }
  },
});

switchDefaultCard.done.watch(() =>
  successNotification("Default payment card was switched")
);
switchDefaultCard.failData.watch((error) => errorNotification(error.message));

export const removeCard = createEffect("removeCard", {
  handler: async (cardId: string) => {
    try {
      const {
        data: { removedCardId, defaultCardId },
      } = await apiServer.delete<{
        removedCardId: string;
        defaultCardId: string;
      }>(`/api/patient/card/${encodeURIComponent(cardId)}`);
      return { removedCardId, defaultCardId };
    } catch (error: unknown) {
      throw new Error(
        (error as AxiosError<{ message: string }>).response?.data.message
      );
    }
  },
});

removeCard.done.watch(() => successNotification("Payment card was removed"));
removeCard.failData.watch((error) => errorNotification(error.message));

export const fetchPaymentSecret = createEffect("fetchPaymentSecret", {
  handler: async ({
    doctorId,
    patientId,
    timeSlot,
  }: {
    doctorId: string;
    patientId: string;
    timeSlot?: TimeSlot;
  }) => {
    try {
      if (!timeSlot) {
        throw new Error("You have to select date and time for visit");
      }
      const {
        data: { clientSecret },
      } = await apiServer.post<{ clientSecret: string }>(
        "/api/payments/secret",
        {
          doctorId,
          patientId,
          timeSlot,
        }
      );
      return clientSecret;
    } catch (error) {
      throw new Error(
        (error as AxiosError<{ message: string }>).response?.data.message
      );
    }
  },
});

export const fetchSaveIntentSecret = createEffect("fetchSaveIntentSecret", {
  handler: async ({ patientId }: { patientId: string }) => {
    try {
      const {
        data: { intentSecret },
      } = await apiServer.post<{ intentSecret: string }>(
        "/api/payments/save-intent",
        { patientId }
      );
      return intentSecret;
    } catch (error) {
      throw new Error(
        (error as AxiosError<{ message: string }>).response?.data.message
      );
    }
  },
});

export const confirmCardPayment = createEffect("confirmCardPayment", {
  handler: async (cb: () => Promise<PaymentIntentResult> | undefined) =>
    await cb(),
});

export const $paymentCards = createStore<PaymentCardResponse[]>([])
  .on(loadPaymentCards.doneData, (_, cardsList) => cardsList)
  .on(addCard.doneData, (cardsList, newCard) => [...cardsList, newCard])
  .on(removeCard.doneData, (cardsList, { removedCardId, defaultCardId }) => {
    const cardsListWithoutRemoved: PaymentCardResponse[] = cardsList.filter(
      ({ id }) => id !== removedCardId
    );
    // Switch default payment card
    return cardsListWithoutRemoved.map((card) => ({
      ...card,
      isDefault: card.id === defaultCardId,
    }));
  })
  .on(switchDefaultCard.doneData, (cardsList, defaultCardId) =>
    cardsList.map((card) => ({ ...card, isDefault: card.id === defaultCardId }))
  );

export async function searchBillingAddress(input: string) {
  const { data } = await apiServer.post<{
    predictions: google.maps.places.AutocompletePrediction[];
    sessionToken: string;
  }>("/api/patient/address-search", {
    input,
  });
  return data;
}
