import styled from "@emotion/styled";
import { memo } from "react";
import { toast, ToastContainer, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {
  CheckmarkRoundedIcon,
  CloseIcon,
  CloseRoundedIcon,
  WarningIcon,
} from "./icons";
import { Grid } from "./Layout";
import { boxShadow, color } from "./theme";
import { Typography } from "./Typography";

export const NotificationToastContainer = memo(() => (
  <CustomizedToastContainer
    hideProgressBar
    position="bottom-right"
    closeButton={false}
  />
));

const CustomizedToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    background-color: ${color.N0};
    border-radius: 8px;
    box-shadow: ${boxShadow.normal};
  }
`;

type NotificationProps = {
  message: string;
  closeToast?: () => void;
};

export const successNotification = (message: string) =>
  toast(<SuccessMessage message={message} />);

const SuccessMessage = ({ message, closeToast }: NotificationProps) => {
  return (
    <Grid
      alignItems="center"
      gridTemplateColumns="24px auto 16px"
      gridColumnGap={1}
    >
      <CheckmarkRoundedIcon height="24px" width="24px" />
      <Typography fontWeight={600} type="h5">
        {message}
      </Typography>
      <CloseIcon onClick={closeToast!} />
    </Grid>
  );
};

export const warningNotification = (
  message: string,
  options?: ToastOptions
) => {
  return toast(<WarningMessage message={message} />, options);
};

const WarningMessage = ({ message, closeToast }: NotificationProps) => {
  return (
    <Grid
      alignItems="center"
      gridTemplateColumns="24px auto 16px"
      gridColumnGap={1}
    >
      <WarningIcon height="24px" width="24px" />
      <Typography fontWeight={600} type="h5">
        {message}
      </Typography>
      <CloseIcon onClick={closeToast!} />
    </Grid>
  );
};

export const errorNotification = (message: string) =>
  toast(<ErrorMessage message={message} />);

const ErrorMessage = ({ message, closeToast }: NotificationProps) => {
  return (
    <Grid
      alignItems="center"
      gridTemplateColumns="24px auto 16px"
      gridColumnGap={1}
    >
      <CloseRoundedIcon fill={color.R100} height="24px" width="24px" />
      <Typography fontWeight={600} type="h5">
        {message}
      </Typography>
      <CloseIcon onClick={closeToast!} />
    </Grid>
  );
};
