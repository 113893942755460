import styled from "@emotion/styled";
import {
  ChangeEvent,
  ElementType,
  forwardRef,
  HTMLProps,
  memo,
  Ref,
  useState,
} from "react";
import { Block, Flex } from "./Layout";
import { color } from "./theme";
import { Typography } from "./Typography";

type Props = HTMLProps<HTMLInputElement> & {
  as?: ElementType<any>;
  // input has a native `required` property. But to bypass browser validation, need to rely on custom flag
  isRequired?: boolean;
  labels: string[];
};

export const RadioGroup = forwardRef(
  (props: Props, refObject: Ref<HTMLInputElement>) => (
    <RadioGroupComponent {...props} ref={null} refObject={refObject} />
  )
);

type RadioGroupProps = Props & {
  refObject?: Ref<HTMLInputElement>;
};

const RadioGroupComponent = memo(
  ({
    labels,
    name,
    defaultValue,
    onChange,
    title,
    refObject,
    readOnly,
    isRequired,
    ...props
  }: RadioGroupProps) => {
    const [selected, setSelected] = useState(
      defaultValue as string | undefined
    );
    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      setSelected(e.target.value);
      onChange && onChange(e);
    };

    if (readOnly) {
      const selectedAnswer = labels.find(
        (label) => label.toLowerCase() === selected?.toLowerCase()
      );
      return (
        <>
          {title && (
            <Block marginBottom={1.5}>
              <Typography type="h5">{title}</Typography>
            </Block>
          )}
          <Flex>
            <Typography fontWeight={500} type="h5">
              {selectedAnswer || "N/A"}
            </Typography>
          </Flex>
        </>
      );
    }

    return (
      <>
        {title && (
          <Block marginBottom={1.5}>
            <Typography type="h5">
              {title}
              {isRequired && (
                <Typography lineHeight="0" type="h4">
                  &nbsp;&#42;
                </Typography>
              )}
            </Typography>
          </Block>
        )}
        <Flex alignItems="center">
          {labels.map((label) => {
            const id = `${title}_${label}`;
            return (
              <RadioContainer key={id}>
                <Input
                  {...props}
                  checked={label.toLowerCase() === selected?.toLowerCase()}
                  name={name}
                  onChange={onInputChange}
                  value={label.toLowerCase()}
                  type="radio"
                  id={id}
                  ref={refObject}
                />
                <Label htmlFor={id} hasText={!!label}>
                  <Typography type="h5">{label || ""}</Typography>
                </Label>
              </RadioContainer>
            );
          })}
        </Flex>
      </>
    );
  }
);

type RadioButtonProps = HTMLProps<HTMLInputElement> & {
  as?: ElementType<any>;
  refObject?: Ref<HTMLInputElement>;
};

export const RadioButton = memo(({ refObject, ...props }: RadioButtonProps) => (
  <>
    <Input {...props} type="radio" ref={refObject} />
    <Label htmlFor={props.id} hasText={!!props.label}>
      <Typography type="h5">{props.label || ""}</Typography>
    </Label>
  </>
));

const RadioContainer = styled(Block)`
  margin-right: 24px;
  &:last-of-type {
    margin-right: 0;
  }
`;

type InputState = {
  checked?: boolean;
};

const Input = styled.input<InputState>`
  position: absolute;
  opacity: 0;
  z-index: -1;
  &:checked + label::before {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8Y2lyY2xlIGN4PSI0IiBjeT0iNCIgcj0iNCIgZmlsbD0iIzIzQTA4NSIvPgo8L3N2Zz4K");
  }
  &:not(:checked) + label:before {
    background-image: none;
  }
  &:hover + label::before,
  &:checked:hover + label::before,
  &:focus + label:before {
    border-color: ${color.G200};
  }
`;

type LabelState = {
  hasText: boolean;
};

const Label = styled.label<LabelState>`
  display: inline-flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  &::before {
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 8px 8px;
    border: 1px solid ${color.N400};
    border-radius: 50%;
    content: "";
    display: inline-block;
    height: 16px;
    margin-right: ${({ hasText }) => (hasText ? "8px" : "0")};
    width: 16px;
  }
`;
