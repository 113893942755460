import styled from "@emotion/styled";
import {
  differenceInMinutes,
  format,
  isAfter,
  isBefore,
  parseISO,
} from "date-fns";
import { endOfDay } from "date-fns/fp";
import { useStore } from "effector-react";
import {
  loadSurgeries,
  surgeries as $surgeries,
  SurgeryDTO,
} from "Patient/Record";
import { memo, useEffect, useState } from "react";
import { Block, color, Flex, RecordSurgeryIcon, Typography } from "ui-kit";
import { EmptyStateContainer, EmptyStateIcon } from "./EmptyState";
import { LoaderWithSpacing } from "./Loader";

type Props = {
  patientId: string;
};

type SurgeriesRecordsSplitted = {
  upcoming: SurgeryDTO[];
  past: SurgeryDTO[];
};

export const SurgeriesListPage = memo(({ patientId }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const surgeries = useStore($surgeries);

  useEffect(() => {
    loadSurgeries(patientId).finally(() => setIsLoading(false));
  }, [patientId]);

  const splittedRecords = surgeries.reduce(
    (splittedRecords, record) => ({
      upcoming: isAfter(endOfDay(parseISO(record.date)), Date.nowUniversal)
        ? [...splittedRecords.upcoming, record]
        : splittedRecords.upcoming,
      past: isBefore(endOfDay(parseISO(record.date)), Date.nowUniversal)
        ? [...splittedRecords.past, record]
        : splittedRecords.past,
    }),
    { upcoming: [], past: [] } as SurgeriesRecordsSplitted
  );

  if (isLoading) {
    return <LoaderWithSpacing />;
  }

  if (surgeries.length === 0) {
    return (
      <EmptyStateContainer>
        <Flex justifyContent="center" marginBottom={4}>
          <EmptyStateIcon>
            <RecordSurgeryIcon width="86px" height="86px" />
          </EmptyStateIcon>
        </Flex>
        <Block marginBottom={1}>
          <Typography fontColor="N300" type="h2">
            No patient surgeries
          </Typography>
        </Block>
        <Block marginBottom={4}>
          <Typography fontColor="N300" fontWeight={400} type="h3">
            The patient's surgeries will be displayed here
          </Typography>
        </Block>
      </EmptyStateContainer>
    );
  }

  return (
    <Block marginTop={3} marginBottom={6} paddingLeft={5} paddingRight={5}>
      {splittedRecords.upcoming.length > 0 && (
        <Block marginBottom={6}>
          <Block marginBottom={3}>
            <Typography as="h3" fontColor="N300" type="h2">
              Upcoming
            </Typography>
          </Block>
          {splittedRecords.upcoming.sort(newestOnTop).map((record) => (
            <SurgeryRecordContainer key={record.id}>
              <Block marginBottom={1}>
                <Typography as="h4" type="h2">
                  {record.name}
                </Typography>
              </Block>
              <Block marginBottom={1}>
                <Typography fontColor="N300" type="h4">
                  Upcoming date: {format(parseISO(record.date), "MM/dd/yyyy")}
                </Typography>
              </Block>
              <Block marginRight={2}>
                <Typography fontWeight={400} type="h3">
                  {record.description}
                </Typography>
              </Block>
            </SurgeryRecordContainer>
          ))}
        </Block>
      )}
      {splittedRecords.past.length > 0 && (
        <Block>
          <Block marginBottom={3}>
            <Typography as="h3" fontColor="N300" type="h2">
              Past
            </Typography>
          </Block>
          {splittedRecords.past.sort(newestOnTop).map((record) => (
            <SurgeryRecordContainer key={record.id}>
              <Block marginBottom={1}>
                <Typography as="h4" type="h2">
                  {record.name}
                </Typography>
              </Block>
              <Block marginBottom={1}>
                <Typography fontColor="N300" type="h4">
                  When made: {format(parseISO(record.date), "MM/dd/yyyy")}
                </Typography>
              </Block>
              <Block>
                <Typography fontWeight={400} type="h3">
                  {record.description}
                </Typography>
              </Block>
            </SurgeryRecordContainer>
          ))}
        </Block>
      )}
    </Block>
  );
});

function newestOnTop(surgery1: SurgeryDTO, surgery2: SurgeryDTO) {
  return differenceInMinutes(new Date(surgery2.date), new Date(surgery1.date));
}

const SurgeryRecordContainer = styled.section`
  border-bottom: 1px solid ${color.N200};
  margin-bottom: 32px;
  padding-bottom: 32px;
  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;
