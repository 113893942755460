import styled from "@emotion/styled";
import { memo, ReactNode } from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Block, Grid } from "./Layout";
import { color } from "./theme";

type Props = {
  children: ReactNode;
};

export const GeneralPage = memo(({ children }: Props) => (
  <Grid
    minHeight="100vh"
    width="100%"
    gridTemplateColumns="1fr"
    gridTemplateRows="90px auto max-content"
  >
    <Header />
    <Main>{children}</Main>
    <Footer />
  </Grid>
));

const Main = styled(Block.withComponent("main"))`
  background-color: ${color.N100};
`;
