import { doctorLoginPage, toDoctorCreateAccountPage } from "Doctor/Router";
import { memo } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuthenticatedDoctor } from "./useAuthenticatedDoctor";
import { useBlockRouteOnModeration } from "./useBlockRouteOnModeration";
import { useDoctorAccountWasCreated } from "./useDoctorAccountWasCreated";
import { useLocationWithValidPathname } from "./useLocationWithValidPathname";

export const CreatedAccountBarier = memo(() => {
  const authenticatedDoctor = useAuthenticatedDoctor();
  const validLocation = useLocationWithValidPathname();
  const isAccountCreated = useDoctorAccountWasCreated();
  const shouldBlockRouteOnModeration = useBlockRouteOnModeration();

  if (!authenticatedDoctor) {
    return (
      <Navigate
        replace
        to={{
          pathname: doctorLoginPage,
        }}
        state={{
          from: validLocation,
        }}
      />
    );
  }

  if (!isAccountCreated || shouldBlockRouteOnModeration) {
    return (
      <Navigate
        replace
        to={{
          pathname: toDoctorCreateAccountPage(authenticatedDoctor.id),
        }}
      />
    );
  }

  return <Outlet />;
});
