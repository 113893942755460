import styled from "@emotion/styled";
import { memo, useState } from "react";
import {
  Block,
  boxShadow,
  Button,
  ChevronLeftIcon,
  color,
  Flex,
  HeroBanner,
  Input,
  Typography,
} from "ui-kit";
import { PageLayout } from "../Layout";
import { faqs } from "./faqs";

export const FAQPage = memo(() => {
  const [openedQuestion, setOpenedQuestion] = useState<string>();
  return (
    <PageLayout>
      <HeroSection
        alignItems="center"
        flexDirection="column"
        position="relative"
        justifyContent="flex-start"
      >
        <Block marginBottom={3} textAlign="center">
          <Typography as="h1" fontColor="N0" type="h1">
            FAQ
          </Typography>
        </Block>
        <SearchSectionContainer alignItems="center" width="720px">
          <Block className="search-input" marginRight={2}>
            <Input
              autoFocus
              boxShadow={boxShadow.normal}
              placeholder="Search"
            />
          </Block>
          <Block className="search-submit" textAlign="right">
            <SeachButton filled size="large">
              Search
            </SeachButton>
          </Block>
        </SearchSectionContainer>
      </HeroSection>
      <FAQContainer>
        {Object.keys(faqs).map((sectionName) => (
          <FAQQuestionsSection key={sectionName}>
            <Block marginBottom={2}>
              <Typography as="h2" type="h2">
                {sectionName}
              </Typography>
            </Block>
            {faqs[sectionName].map((question) => (
              <Block key={question.id} marginBottom={2}>
                <FAQQuestionTitle as="h3" type="h3" fontColor="G200">
                  <FAQQuestionTitleQuestion
                    onClick={() =>
                      openedQuestion === question.id
                        ? setOpenedQuestion("")
                        : setOpenedQuestion(question.id)
                    }
                  >
                    {question.question}
                  </FAQQuestionTitleQuestion>
                  <FAQQuestionTitleIcon
                    cursor="pointer"
                    stroke={color.G200}
                    opened={openedQuestion === question.id}
                    onClick={() =>
                      openedQuestion === question.id
                        ? setOpenedQuestion("")
                        : setOpenedQuestion(question.id)
                    }
                  />
                </FAQQuestionTitle>
                <FAQQuestionAnswer
                  as="div"
                  type="h4"
                  fontColor="N300"
                  opened={openedQuestion === question.id}
                >
                  {question.answer}
                </FAQQuestionAnswer>
              </Block>
            ))}
          </FAQQuestionsSection>
        ))}
      </FAQContainer>
    </PageLayout>
  );
});

const HeroSection = styled(HeroBanner)`
  height: auto;
  margin-bottom: 48px;
  padding-top: 64px;
`;

const SearchSectionContainer = styled(Flex)`
  margin: 0 auto;
  padding-bottom: 64px;

  & .search-input {
    flex-grow: 1;
  }
  & .search-submit {
    flex-grow: 0;
  }
`;

const SeachButton = styled(Button)`
  background-color: ${color.O100};
  border-color: ${color.O100};
  &:hover {
    background-color: ${color.O200};
    border-color: ${color.O200};
  }
`;

const FAQContainer = styled(Block)`
  padding: 0 40px;
`;

const FAQQuestionsSection = styled(Block.withComponent("section"))`
  margin-bottom: 48px;
`;

const FAQQuestionTitle = styled(Typography)`
  align-items: center;
  display: flex;
  margin-bottom: 8px;
  margin-top: 0;
`;

const FAQQuestionTitleQuestion = styled.span`
  cursor: pointer;
  padding-right: 8px;
`;

type Answer = {
  opened: boolean;
};

const FAQQuestionTitleIcon = styled(ChevronLeftIcon, {
  shouldForwardProp: (prop) => prop !== "opened",
})<Answer>`
  transition: transform 0.15s ease-out;
  transform: ${({ opened }) => (opened ? "rotate(90deg)" : "rotate(270deg)")};
`;

const FAQQuestionAnswer = styled(Typography.withComponent("div"), {
  shouldForwardProp: (prop) => prop !== "opened",
})<Answer>`
  height: ${({ opened }) => (opened ? "auto" : "0")};
  overflow-y: hidden;
  transition: height 0.3s ease-out;
  & p {
    margin: 8px 0;
  }
  & ul,
  ol {
    padding: revert;
  }
`;
