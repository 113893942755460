import styled from "@emotion/styled";
import { usePatientId } from "Patient/Router";
import { toPatientProfilHome } from "Patient/routes";
import { memo, RefObject, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import StepWizard, { StepWizardChildProps } from "react-step-wizard";
import { Block, boxShadow, color, Flex, Typography, UnifiedLink } from "ui-kit";
import { usePatientAccountWasCreated } from "../patientSession";
import { EmergencyInformation } from "./EmergencyInformation";
import { PersonalInformation } from "./PersonalInformation";
import stagesTransitions from "./stages.module.css";

const transitions = {
  enterRight: `${stagesTransitions.animated} ${stagesTransitions.enterRight}`,
  enterLeft: `${stagesTransitions.animated} ${stagesTransitions.enterLeft}`,
  exitRight: `${stagesTransitions.animated} ${stagesTransitions.exitRight}`,
  exitLeft: `${stagesTransitions.animated} ${stagesTransitions.exitLeft}`,
};

export const CreateAccountPage = memo(() => {
  const navigationContainerNode = useRef(null);
  const wasAccountCreated = usePatientAccountWasCreated();
  // Here we make an assumption that patient is in a session
  const patientId = usePatientId();

  if (wasAccountCreated) {
    return (
      <Block padding={7} textAlign="center">
        <Typography as="h1" type="h1">
          You have already created account.{" "}
          <UnifiedLink type="inherit" to={toPatientProfilHome(patientId)}>
            Go to home page
          </UnifiedLink>
        </Typography>
      </Block>
    );
  }
  return (
    <Main>
      <FormSection flexDirection="column" alignItems="center">
        <Block marginBottom={1}>
          <Typography as="h1" type="h1">
            Create account
          </Typography>
        </Block>
        <Block marginBottom={5}>
          <Block ref={navigationContainerNode} />
        </Block>
        <Block width="680px" overflow="hidden">
          <CreateAccountStages
            nav={<Navigation container={navigationContainerNode} />}
            transitions={transitions}
          >
            <PersonalInformation />
            <EmergencyInformation />
          </CreateAccountStages>
        </Block>
      </FormSection>
    </Main>
  );
});

type NavigationProps = Partial<StepWizardChildProps> & {
  container: RefObject<HTMLDivElement>;
};

const Navigation = memo(
  ({ container, currentStep, totalSteps }: NavigationProps) => {
    const [navigationContainer, setNavigationContainer] =
      useState<HTMLDivElement>();

    useEffect(() => {
      if (container.current) {
        setNavigationContainer(container.current);
      }
    }, [container]);

    if (!navigationContainer) {
      return null;
    }

    return createPortal(
      <NavigationProgress position="relative">
        <NavigationStep currentStep={currentStep!} totalSteps={totalSteps!} />
      </NavigationProgress>,
      navigationContainer
    );
  }
);

const CreateAccountStages = styled(StepWizard)`
  background-color: ${color.N0};
  border-radius: 8px;
  box-shadow: ${boxShadow.normal};
  padding: 40px;
  width: 100%;
`;

const NavigationProgress = styled(Block)`
  background-color: ${color.N200};
  border-radius: 4px;
  height: 4px;
  margin: 0 auto;
  postion: relative;
  width: 245px;
`;

type NavigationStepState = {
  currentStep: number;
  totalSteps: number;
};

const NavigationStep = styled.span<NavigationStepState>`
  background-color: ${color.G200};
  border-radius: 4px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: width 0.3s ease-out;
  width: ${({ currentStep, totalSteps }) =>
    `${(currentStep / totalSteps) * 100}%`};
`;

const Main = styled.main`
  background-color: ${color.N100};
  border-radius: 4px;
  min-height: 100vh;
  padding: 48px;
`;

const FormSection = Flex.withComponent("section");
