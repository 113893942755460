import { color } from "../theme";

type BorderState = {
  disabled?: boolean;
  hasErrors?: boolean;
  hasValue?: boolean;
  inFocus?: boolean;
};

export function getBorderColor({
  disabled,
  hasErrors,
  hasValue,
  inFocus,
}: BorderState) {
  if (disabled) {
    return color.N100;
  }
  if (hasErrors) {
    return color.R100;
  }
  if (hasValue) {
    return color.N300;
  }
  if (inFocus) {
    return color.G200;
  }
  return color.N200;
}
