import {
  Model as Doctor,
  DoctorDTO,
  DoctorPersonalInformation,
  DoctorProfessionalInformation,
  LicenseRequest,
  ROLE_DOCTOR,
  Schedule,
  userToDoctorAdapter,
} from "Doctor/Model";
import { clearSession } from "apiConnection";
import { createEffect, createEvent, createStore, forward } from "effector";
import { useStore } from "effector-react";
import { errorNotification, successNotification } from "ui-kit";
import {
  Credentials,
  createAccount as createAccountRequest,
  logIn as logInRequest,
  logOut as logOutRequest,
  signUp as signUpRequest,
  updateEmailRequest,
  updateLicense as updateLicenseRequest,
  updatePasswordRequest,
  updatePersonalInformation as updatePersonalInformationRequest,
  updateProfessionalInformation as updateProfessionalInformationRequest,
  updateSchedule as updateScheduleRequest,
} from "./requests";

export const createAccount = createEffect("doctorCreateAccount", {
  handler: async () => {
    return await createAccountRequest();
  },
});

export const logIn = createEffect("doctorLogIn", {
  handler: async ({ email, password }: Credentials) => {
    const doctor = await logInRequest({ email, password });
    return doctor;
  },
});

export const logOut = createEffect<void, Promise<boolean>>("doctorLogOut", {
  handler: async () => await logOutRequest(),
});

export const signUp = createEffect("signUpDoctor", {
  handler: async ({ email, password }: Credentials) => {
    const doctor = await signUpRequest({ email, password });
    return doctor;
  },
});

export const updateEmail = createEffect("updateEmail", {
  handler: ({ newEmail }: { newEmail: string }) => updateEmailRequest(newEmail),
});

updateEmail.doneData.watch(() => successNotification("Email was updated"));

updateEmail.failData.watch((error) => {
  errorNotification(error.message);
});

export const updatePassword = createEffect("updatePassword", {
  handler: ({
    oldPassword,
    newPassword,
  }: {
    oldPassword: string;
    newPassword: string;
  }) => updatePasswordRequest({ oldPassword, newPassword }),
});

forward({
  from: updatePassword.doneData,
  to: logOut,
});

updatePassword.doneData.watch(() => {
  successNotification(
    "Password was updated. Please, login again with the new password"
  );
});
updatePassword.failData.watch((error) => {
  return errorNotification(error.message);
});

export type PersonalInformationPayload = Omit<
  DoctorPersonalInformation,
  "photo"
> & {
  photo?: FileList | null;
};

export const updatePersonalInformation = createEffect(
  "updatePersonalInformation",
  {
    handler: async (personalInformation: PersonalInformationPayload) => {
      return await updatePersonalInformationRequest(personalInformation);
    },
  }
);

export const updateProfessionalInformation = createEffect(
  "updateProfessionalInformation",
  {
    handler: async (professionalInformation: DoctorProfessionalInformation) => {
      return await updateProfessionalInformationRequest(
        professionalInformation
      );
    },
  }
);

export const updateLicense = createEffect("updateLicense", {
  handler: async (license: LicenseRequest) => {
    return await updateLicenseRequest(license);
  },
});

export const updateSchedule = createEffect("updateSchedule", {
  handler: async (schedule: Partial<Schedule>) => {
    return updateScheduleRequest(schedule);
  },
});

export const initializeDoctorFromSession = createEvent<DoctorDTO>(
  "initializeDoctorFromSession"
);

export const $doctorSession = createStore<Doctor | null>(null)
  .on(initializeDoctorFromSession, (state, doctorDTO) => ({
    ...userToDoctorAdapter(doctorDTO),
  }))
  .on(createAccount.doneData, (state, doctor) => ({ ...doctor }))
  .on(signUp.doneData, (state, doctor) => ({ ...doctor }))
  .on(logIn.doneData, (state, doctor) => ({ ...doctor }))
  .on(updateEmail.doneData, (state, patient) => ({ ...patient }))
  .on(updatePersonalInformation.doneData, (state, doctor) => ({
    ...doctor,
  }))
  .on(updateProfessionalInformation.doneData, (state, doctor) => ({
    ...doctor,
  }))
  .on(updateLicense.doneData, (state, doctor) => ({
    ...doctor,
  }))
  .on(updateSchedule.doneData, (state, doctor) => ({
    ...doctor,
  }))
  .on([logOut.doneData, clearSession], () => null);

export function useDoctor() {
  const session = useStore($doctorSession);
  if (!session || session.role !== ROLE_DOCTOR) {
    return undefined;
  }
  return session;
}
