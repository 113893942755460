import { apiServer } from "apiConnection";
import { attach, createEffect, createStore } from "effector";
import { getPublicIp } from "shared-functions/ip";
import { errorNotification, successNotification } from "ui-kit";
import { PharmacyDTO } from "./typeDefinitions";

export const loadPharmaciesAroundLocation = createEffect(
  "loadPharmaciesAroundLocation",
  {
    handler: async () => await getPharmaciesAroundLocation(),
  }
);

loadPharmaciesAroundLocation.failData.watch((error) =>
  errorNotification(error.message)
);

export const loadPharmaciesAroundPlace = createEffect(
  "loadPharmaciesAroundPlace",
  {
    handler: async ({
      placeId,
      sessionToken,
    }: {
      placeId: string;
      sessionToken: string;
    }) => await getPharmaciesAroundPlace({ placeId, sessionToken }),
  }
);

export const loadSelectedPharmacies = createEffect("loadSelectedPharmacies", {
  handler: async (patientId: string) =>
    await getSelectedPharmacies({ patientId }),
});

const hydrated_selectPharmacy = createEffect("selectPharmacy", {
  handler: async ({
    patientId,
    pharmacy,
    selectedPharmacies,
  }: {
    patientId: string;
    pharmacy: PharmacyDTO;
    selectedPharmacies: PharmacyDTO[];
  }) => {
    if (
      selectedPharmacies.find(
        (selectedPharmacy) => selectedPharmacy.place_id === pharmacy.place_id
      )
    ) {
      throw new Error("This pharmacy is already selected");
    }
    return await selectPharmacyRequest({ patientId, pharmacy });
  },
});

hydrated_selectPharmacy.doneData.watch(() => {
  successNotification("Pharmacy was added");
});

hydrated_selectPharmacy.failData.watch((error) => {
  errorNotification(error.message);
});

export const removePharmacy = createEffect("removePharmacy", {
  handler: async ({
    patientId,
    pharmacyId,
  }: {
    patientId: string;
    pharmacyId?: string;
  }) => pharmacyId && (await removePharmacyRequest({ patientId, pharmacyId })),
});

removePharmacy.doneData.watch(() => {
  successNotification("Pharmacy was removed");
});

export async function searchByAddress(input: string) {
  const { data } = await apiServer.post<{
    predictions: google.maps.places.AutocompletePrediction[];
    sessionToken: string;
  }>("/api/patient/address-search", {
    input,
  });
  return data;
}

async function getPharmaciesAroundLocation() {
  const ip = await getPublicIp();
  const { data } = await apiServer.post<PharmacyDTO[]>(
    "/api/patient/pharmacies-around-location",
    { ip }
  );
  return data;
}

async function getPharmaciesAroundPlace({
  placeId,
  sessionToken,
}: {
  placeId: string;
  sessionToken: string;
}) {
  const { data } = await apiServer.post<PharmacyDTO[]>(
    "/api/patient/pharmacies-around-place",
    {
      placeId,
      sessionToken,
    }
  );
  return data;
}

async function getSelectedPharmacies({ patientId }: { patientId: string }) {
  const { data: pharmaciesList } = await apiServer.get<PharmacyDTO[]>(
    `/api/patient/${patientId}/pharmacies`
  );
  return pharmaciesList;
}

async function selectPharmacyRequest({
  patientId,
  pharmacy,
}: {
  patientId: string;
  pharmacy: PharmacyDTO;
}) {
  const { data: selectedPharmacy } = await apiServer.post<PharmacyDTO>(
    `/api/patient/${patientId}/pharmacies`,
    {
      pharmacy,
    }
  );
  return selectedPharmacy;
}

async function removePharmacyRequest({
  patientId,
  pharmacyId,
}: {
  patientId: string;
  pharmacyId: string;
}) {
  const { data: deletedPharmacyId } = await apiServer.delete<string>(
    `/api/patient/${patientId}/pharmacies/${pharmacyId}`
  );
  return deletedPharmacyId;
}

type PharmaciesStore = {
  selected: PharmacyDTO[];
  suggested: PharmacyDTO[];
};

export const pharmacies = createStore<PharmaciesStore>({
  selected: [],
  suggested: [],
})
  .on(
    [loadPharmaciesAroundLocation.doneData, loadPharmaciesAroundPlace.doneData],
    (state, suggestedPharmacies) => ({
      ...state,
      suggested: suggestedPharmacies,
    })
  )
  .on(loadSelectedPharmacies.doneData, (state, selectedPharmacies) => ({
    ...state,
    selected: selectedPharmacies,
  }))
  .on(hydrated_selectPharmacy.doneData, (state, selectedPharmacy) => ({
    selected: [...state.selected, selectedPharmacy],
    suggested: state.suggested.filter(
      (pharmacy) => pharmacy.place_id !== selectedPharmacy.place_id
    ),
  }))
  .on(removePharmacy.doneData, (state, removedPharmacyId) => ({
    ...state,
    selected: state.selected.filter(
      (pharmacy) => pharmacy.place_id !== removedPharmacyId
    ),
  }));

export const selectPharmacy = attach({
  effect: hydrated_selectPharmacy,
  source: pharmacies,
  mapParams: (
    {
      patientId,
      pharmacy,
    }: {
      patientId: string;
      pharmacy: PharmacyDTO;
    },
    { selected }: PharmaciesStore
  ) => ({ patientId, pharmacy, selectedPharmacies: selected }),
});
