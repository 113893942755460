import { homePage } from "Home/routes";
import { memo } from "react";
import { Helmet } from "react-helmet";
import { Block, Typography, UnifiedLink } from "ui-kit";
import { GeneralPage } from "ui-kit/Page";
import { ContentSection, TextContent } from "./Content";
import { privacyPolicyURL } from "./routes";

export const CookiePolicyPage = memo(() => (
  <GeneralPage>
    <Helmet>
      <title>Cookie policy</title>
    </Helmet>
    <Block textAlign="center" marginTop={8} marginBottom={5}>
      <Typography as="h1" type="h1" fontColor="G200">
        Cookie policy
      </Typography>
      <Block textAlign="center" marginTop={0.5}>
        <Typography type="h5" fontWeight="bold">
          Date last modified: December 2, 2021
        </Typography>
      </Block>
    </Block>
    <TextContent>
      <ContentSection>
        <p>
          This PocketDoctor, Inc. cookie policy ("Cookie Policy") explains what
          cookies are and how we use them on the PocketDoctor website (
          <UnifiedLink to={homePage}>www.pocketdoctor.us</UnifiedLink>) and
          dependent pages (the "Site"). It supplements our Privacy Statement,
          which you can find{" "}
          <UnifiedLink to={privacyPolicyURL}>here</UnifiedLink>. By using our
          Site, you agree with our use of cookies as described in this Cookie
          Policy.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h3" type="h3">
          About cookies
        </Typography>
        <p>
          Cookies are small bits of data that a site can send to a computer or
          device browser. Cookies are used to help sites recognize users and
          keep track of their preferences. Session cookies are deleted from your
          computer or device when you close your browser. Persistent cookies
          remain on your device until they expire or are deleted.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          How and why we use cookies on the site
        </Typography>
        <p>
          We use both session and persistent cookies, but most of our cookies
          are session-based or expire when you log out of our service. Some
          locally stored cookies we use for specific features do not expire but
          can be deleted by you.
        </p>
        <p>We use cookies to improve your experience of our service by:</p>
        <ul>
          <li>
            Remembering your personal settings, privacy preferences, including
            collecting your login information.
          </li>
          <li>
            Enabling functionality and personalization not available without
            cookies.
          </li>
          <li>
            Delivering the most relevant information to you about PocketDoctor,
            including product updates, upgrade information, new content, and
            special offers.
          </li>
          <li>
            Improving the content and performance of our Site by tracking visits
            to discover how users navigate our service (this data is aggregated
            across all of our users and is anonymous).
          </li>
        </ul>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          What cookies we use on the site
        </Typography>
        <p>
          Below is an overview of the types of Cookies we and third parties may
          use to collect Personal Information.
        </p>
        <ul>
          <li>
            <u>Operational Cookies necessary for the Site.</u> Some Cookies are
            strictly necessary to make our Site available to you. You can block
            these Cookies by changing your browser settings but, because they
            are strictly necessary for the operation of the Site, we cannot
            provide you with the Site without this type of Cookies.
          </li>
          <li>
            <u>Functional Cookies.</u> Functional Cookies let us operate certain
            functions of the Site according to your preferences. Deletion of
            these types of Cookies will result in limited functionality of the
            Site and less personalized experience.
          </li>
          <li>
            <u>Analytical Cookies.</u> We use Cookies for website analytics
            purposes in order to operate, maintain and improve our Site. We may
            use our own analytics Cookies or use third party analytics providers
            such as Google Analytics and Google Tag Manager to collect and
            process certain analytics data on our behalf. These third parties
            may also collect information about your use of other websites, apps,
            and online resources. For instance, Google Analytics helps us
            understand how users engage with the Site. Like many services,
            Google Analytics uses first-party Cookies to track user
            interactions. This information is used to compile reports and to
            help us improve our Site. The reports disclose website trends
            without identifying individual visitors. For more information on
            Google Analytics, click here.
          </li>
          <li>
            <u>Advertising Cookies.</u> We work with third-party advertising
            partners to show you ads that we think may interest you, including
            about PocketDoctor as well as third party websites and apps. To do
            so, we and our advertising partners may place and access Cookies
            through our Site and otherwise collect or access Personal
            Information collected over time and across different online
            services. We do not permit the collection of Personally Identifiable
            Information by third-party advertisers about you while you are on
            our site. Our advertising partners may also collect information
            about your use of other websites, apps, and online resources.
          </li>
        </ul>
        <p>
          Where required by applicable law, we obtain your consent to use
          Cookies.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          How you can manage and disable cookies
        </Typography>
        <p>
          Disabling or rejecting cookies will limit the functionality and
          convenience of our service. You can set your browser to notify you
          when you receive cookies and allow you to reject them. You can also
          delete cookies already on your computer, and set most web browsers to
          prevent new cookies from being set. However, if you delete cookies
          and/or prevent new ones, you will have to reenter preferences and
          settings every time you visit our Site, and some services and
          functionality may not work.
        </p>
        <p>
          To learn more about how to control and/or delete your cookies, visit
          www.aboutcookies.org.
        </p>
        <p>
          More information about how PocketDoctor sets third-party cookies is
          contained in our Privacy Statement.
        </p>
      </ContentSection>
    </TextContent>
  </GeneralPage>
));
