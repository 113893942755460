import { AUTHENTICATED_ROUTES } from "Doctor/Router";
import { useLocation, useParams, Location, matchPath } from "react-router-dom";
import { useDoctor } from "./doctorSession";

/**
 * This hook is a wrapper around the react-router's `useLocation`.
 * It covers use case, when doctor's id was changed in URL manually, and it doesn't match with the real id now.
 * Retuns a location with replaced pathname
 */
export function useLocationWithValidPathname(): Location {
  const doctor = useDoctor();
  const location = useLocation();
  const URLParams = useParams();
  if (!doctor?.id) {
    return location;
  }
  const isPathnameValid = doctor.id === URLParams.doctorId;
  if (isPathnameValid) {
    return location;
  }
  let currentPath = AUTHENTICATED_ROUTES.find((route) =>
    matchPath(route, location.pathname)
  );
  if (!currentPath) {
    return location;
  }
  if (URLParams.doctorId) {
    currentPath = currentPath.replace(":doctorId", doctor.id);
  }
  if (URLParams.patientId) {
    currentPath = currentPath.replace(":patientId", URLParams.patientId);
  }
  return {
    ...location,
    pathname: currentPath,
  };
}
