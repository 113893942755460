import { memo } from "react";
import {
  fromStringToOption,
  Option,
  Selectbox,
  SelectProps,
} from "./Selectbox";

export const CountrySelectbox = memo((props: SelectProps) => (
  <Selectbox
    {...props}
    options={SUPPORTED_LOCATIONS}
    noOptionsMessage={() => "No countries"}
  />
));

export function fromStringToCountryOption(value: string) {
  return fromStringToOption(value, SUPPORTED_LOCATIONS);
}

export const SUPPORTED_LOCATIONS: Option[] = [
  {
    label: "USA",
    value: "US",
  },
  {
    label: "England",
    value: "GB",
  },
  {
    label: "Spain",
    value: "ES",
  },
  {
    label: "Germany",
    value: "DE",
  },
  {
    label: "France",
    value: "FR",
  },
  {
    label: "China",
    value: "CN",
  },
];
