import { memo } from "react";
import { Helmet } from "react-helmet";
import { supportPage } from "Support/routes";
import { Block, Typography, UnifiedLink } from "ui-kit";
import { GeneralPage } from "ui-kit/Page";
import { ContentSection, TextContent } from "./Content";
import { homePage } from "Home/routes";

export const TermsOfUsePage = memo(() => (
  <GeneralPage>
    <Helmet>
      <title>Terms of use</title>
    </Helmet>
    {/* For patients */}
    <Block
      id="terms-patients"
      textAlign="center"
      marginTop={8}
      marginBottom={5}
    >
      <Typography as="h1" type="h1" fontColor="G200">
        Terms of use (for patients)
      </Typography>
      <Block textAlign="center" marginTop={0.5}>
        <Typography type="h5" fontWeight="bold">
          Date last modified: December 2, 2021
        </Typography>
      </Block>
    </Block>
    <TextContent>
      <ContentSection>
        <Typography as="h2" type="h2">
          What is&nbsp;this document?
        </Typography>
        <p>
          These Terms of&nbsp;Use (&laquo;Terms&raquo;
          or&nbsp;&laquo;TOU&raquo;) is&nbsp;an&nbsp;agreement between you (the
          &laquo;User&raquo;) and PocketDoctor, Inc.
          (&laquo;PocketDoctor&raquo;). It&nbsp;describes the rules you agree
          to&nbsp;follow when using our mobile applications and website(s) (the
          &laquo;Apps&raquo;), including when you ask questions and when you
          view or&nbsp;input content on&nbsp;or&nbsp;into the Apps.
        </p>
        <p>
          These Terms of&nbsp;Use apply to&nbsp;your use of&nbsp;PocketDoctor
          as&nbsp;a&nbsp;patient or&nbsp;member. Medical Experts participating
          on&nbsp;PocketDoctor are subject to&nbsp;different guidelines and
          terms found in&nbsp;
          <a href="#terms-medical-experts">Medical Expert Terms of&nbsp;Use</a>.
          If&nbsp;you are viewing these Terms of&nbsp;Use for members and
          patients in&nbsp;error and you are a&nbsp;Medical Expert, please
          review and accept the{" "}
          <a href="#terms-medical-experts">
            Terms of&nbsp;Use for Medical Experts
          </a>
          .
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Definitions
        </Typography>
        <p>
          To&nbsp;make this document shorter and clearer, we&rsquo;ve included
          some definitions:
        </p>
        <ul>
          <li>
            &laquo;24/7 Doctor Visits&raquo; means PocketDoctor virtual urgent
            care service.
          </li>
          <li>
            &laquo;PocketDoctor&raquo; or&nbsp;the &laquo;Apps&raquo; means{" "}
            <UnifiedLink to={homePage}>https://www.pocketdoctor.us</UnifiedLink>{" "}
            and related web sites and PocketDoctor mobile applications
            (including PocketDoctor for Doctors, and any other Apps
            we&nbsp;offer). are located in&nbsp;Delaware.
          </li>
          <li>
            &laquo;Content&raquo; means text, graphics, images, and any other
            material entered, processed, contained on&nbsp;or&nbsp;accessed
            through the Apps, including Content created, modified,
            or&nbsp;submitted by&nbsp;Medical Experts.
          </li>
          <li>
            &laquo;PocketDoctor Premium Plan&raquo; means a&nbsp;full membership
            plan offered through apps and a&nbsp;website called
            &laquo;PocketDoctor&raquo;.
          </li>
          <li>
            &laquo;Services&raquo; means services provided through the Apps.
          </li>
          <li>
            &laquo;Free services&raquo; means free services provided through the
            app.
          </li>
          <li>
            &laquo;User&raquo; is&nbsp;a&nbsp;user who uses or&nbsp;uses the
            PocketDoctor website or&nbsp;application.
          </li>
          <li>
            &laquo;Medical Expert&raquo; means a&nbsp;medical professional
            or&nbsp;professional authorized to&nbsp;participate
            in&nbsp;or&nbsp;through the PocketDoctor applications
            or&nbsp;websites.
          </li>
          <li>
            &laquo;Doctor&raquo; means a&nbsp;medical professional
            or&nbsp;professional authorized to&nbsp;participate
            in&nbsp;or&nbsp;through the PocketDoctor applications
            or&nbsp;websites.
          </li>
          <li>
            &laquo;Virtual Consult&raquo; means a&nbsp;consultation between
            a&nbsp;Medical Expert and a&nbsp;patient on&nbsp;the Apps including,
            but not limited&nbsp;to, 24/7 Doctor Visits.
          </li>
        </ul>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Introduction
        </Typography>
        <Typography as="h3" type="h3">
          PocketDoctor Services
        </Typography>
        <p>
          PocketDoctor connects you with doctors and trusted health information
          to&nbsp;help you feel good and to&nbsp;live a&nbsp;happier, healthier
          life. PocketDoctor offers standard (free) services, paid services,
          as&nbsp;well as&nbsp;a&nbsp;paid membership option, available through
          the same Apps. PocketDoctor standard services encompass health
          information and not healthcare.
        </p>
        <p>
          <Typography as="h3" type="h3">
            Remember:
          </Typography>
        </p>
        <ul>
          <li>
            Always consult with your existing doctor or&nbsp;healthcare provider
            or&nbsp;use a&nbsp;Virtual Consult whenever you have a&nbsp;personal
            question about a&nbsp;medical condition or&nbsp;symptom.
          </li>
          <li>
            Never disregard professional medical advice, or&nbsp;delay seeking
            medical advice or&nbsp;treatment, because of&nbsp;something you read
            or&nbsp;learn on&nbsp;PocketDoctor. Always consult with your
            existing doctor or&nbsp;healthcare provider or&nbsp;use
            a&nbsp;Virtual Consult whenever you have a&nbsp;personal question
            about a&nbsp;medical condition or&nbsp;symptom.
          </li>
          <li>
            IMMEDIATELY CALL 911 OR YOUR LOCAL EMERGENCY SERVICE OR YOUR DOCTOR
            IF YOU BELIEVE YOU MAY OR DO HAVE A MEDICAL EMERGENCY.
          </li>
        </ul>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          These Terms of&nbsp;Use are an&nbsp;Agreement
        </Typography>
        <p>
          Generally, this agreement governs your use of&nbsp;the Apps. Other
          terms may apply to&nbsp;your use of&nbsp;a&nbsp;specific feature.
          If&nbsp;there is&nbsp;a&nbsp;conflict between this TOU and terms
          posted for a&nbsp;specific feature, the latter terms apply
          to&nbsp;your use of&nbsp;that feature or&nbsp;part.
        </p>
        <p style={{ textTransform: "uppercase", fontWeight: "bold" }}>
          THESE TERMS OF&nbsp;USE REQUIRE THE USE OF&nbsp;ARBITRATION
          TO&nbsp;RESOLVE DISPUTES AND ALSO LIMIT THE REMEDIES AVAILABLE
          TO&nbsp;YOU IN&nbsp;THE EVENT OF&nbsp;A&nbsp;DISPUTE.
          BY&nbsp;ACCEPTING THESE TERMS, YOU ARE WAIVING THE RIGHT
          TO&nbsp;A&nbsp;TRIAL BY&nbsp;JURY OR&nbsp;TO&nbsp;PARTICIPATE
          IN&nbsp;ANY CLASS ACTION OR&nbsp;REPRESENTATIVE PROCEEDING. SEE
          ARBITRATION AGREEMENT.
        </p>
        <Typography as="h3" type="h3">
          These Terms of&nbsp;Use are an&nbsp;Agreement
        </Typography>
        <p>
          These Terms of&nbsp;Use may change on&nbsp;a&nbsp; going-forward basis
          at&nbsp;any time upon 7 days' notice. Please check these Terms
          periodically for changes. If&nbsp;a&nbsp;change to&nbsp;these Terms
          materially modifies your rights or&nbsp;obligations, we&nbsp;may
          require that you accept the modified Terms in&nbsp;order
          to&nbsp;continue to&nbsp;use the Service. Material modifications are
          effective upon your acceptance of&nbsp;the modified Terms. Immaterial
          modifications are effective upon publication. If&nbsp;you do&nbsp;not
          agree to&nbsp;all of&nbsp;these Terms of&nbsp;Use, please do&nbsp;not
          use PocketDoctor.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          What We Do and What We Don't Do (PocketDoctor)
        </Typography>
        <Typography as="h3" type="h3">
          We Support Your Health Decisions through Information
        </Typography>
        <p>
          PocketDoctor is designed to support the health decisions and choices
          that you make. Always use common sense when making health decisions,
          and consult with your doctor whenever appropriate. PocketDoctor cannot
          make decisions for you, but can help you find health information. On
          PocketDoctor you can ask informational questions and find educational
          answers and health by Medical Experts. Except as may be provided via a
          Virtual Consult, this information is not medical care and no
          doctor-patient relationship is created by, such as our Q&amp;A and
          symptom assessment tools. Content and non-Virtual Care Services on
          PocketDoctor are not a substitute for medical advice, diagnosis,
          treatment, or care from your physician. Except as may be provided via
          a Virtual Consult, information PocketDoctor is not a substitute for a
          formal diagnosis, physical examination, or prescription and should not
          be used to treat a medical condition. Do not ignore or delay obtaining
          professional medical advice because of information accessed through
          the Apps.
        </p>
        <p>
          Please use PocketDoctor Virtual Consults or see your doctor in person
          if you are looking for a personal medical evaluation, diagnosis,
          prescription, or treatment.
        </p>
        <Typography as="h3" type="h3">
          We Provide Access to Doctors for Care (Virtual Consults)
        </Typography>
        <p>
          Via PocketDoctor Virtual Consults, you can obtain care (if you are
          located in a state where such service is available). Additional terms
          apply to Virtual Consults. PocketDoctor Virtual Consults may support
          doctor-patient relationships. Virtual Consults may be used where an
          ongoing doctor-patient relationship exists, or where a temporary
          doctor-patient relationship is created by the use of Virtual Consults.
        </p>
        <p>
          Whenever we use the words "your physician" or "your doctor" or
          "healthcare provider" or similar words on PocketDoctor, including in
          these Terms of Use, we mean your personal doctor with whom you have of
          an actual, mutually acknowledged, doctor/healthcare provider-patient
          relationship. If you participate in a Virtual Consult on PocketDoctor,
          you understand and agree that the Medical Experts on PocketDoctor are
          not your primary care physician, and you agree not to use PocketDoctor
          Virtual Consults as a substitute for interactions with your primary
          care physician or health insurance. (Where available, you may also use
          Virtual Consults with your existing doctor(s).
        </p>
        <Typography as="h3" type="h3">
          We Provide Information without Recommendation or Endorsement
        </Typography>
        <p>
          We do not recommend or endorse any specific Content, Services, tests,
          doctors, products, procedures, opinions, or other information that may
          be mentioned on PocketDoctor. PocketDoctor is designed to be used with
          common sense, and in connection with the advice of your doctor or
          healthcare provider. Reliance on PocketDoctor Content or Services is
          solely at your own risk. Some Services and Content (including posts by
          Medical Experts) may not be reviewed or certified by PocketDoctor. We
          cannot guarantee that the Content and Services will help you achieve
          any specific goals or results. Content regarding dietary supplements
          or other treatments or regimens have not been evaluated by the Food
          and Drug Administration and is not intended to diagnose, treat, cure,
          or prevent any disease.
        </p>
        <Typography as="h3" type="h3">
          We're Not for Use in Emergencies
        </Typography>
        <p>
          Never use PocketDoctor or Content or Services in a potential or actual
          medical emergency.{" "}
          <strong>CALL 911 OR YOUR DOCTOR FOR ALL MEDICAL EMERGENCIES.</strong>
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          PocketDoctor Medical Experts
        </Typography>
        <Typography as="h3" type="h3">
          Independence of PocketDoctor Medical Experts
        </Typography>
        <p>
          The inclusion of Medical Experts, professionals and specialists on
          PocketDoctor or in any professional directory on PocketDoctor does not
          imply PocketDoctor recommendation or endorsement of such professional
          nor is such information intended as a tool for verifying the
          credentials, qualifications, or abilities of any professional. SUCH
          INFORMATION IS PROVIDED ON AN "AS-IS" BASIS AND POCKETDOCTOR DISCLAIMS
          ALL WARRANTIES, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
          TO THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
          PARTICULAR PURPOSE. POCKETDOCTOR SHALL IN NO EVENT BE LIABLE TO YOU OR
          TO ANYONE FOR ANY DECISION MADE OR ACTION TAKEN BY ANY PARTY
          (INCLUDING, WITHOUT LIMITATION, ANY USER) IN RELIANCE ON INFORMATION
          ABOUT MEDICAL EXPERTS, PROFESSIONALS AND SPECIALISTS ON POCKETDOCTOR.
          The use of PocketDoctor by any entity or individual to verify the
          credentials of professionals or specialists is prohibited.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Your Account and Your Use of PocketDoctor
        </Typography>
        <Typography as="h3" type="h3">
          You are responsible for your account. Keep your password and real name
          private
        </Typography>
        <p>
          Accurate and complete registration information is required to use
          PocketDoctor. You are solely responsible for the security of your
          passwords and for any use of your account, including any access to
          personal information in your account. If you suspect unauthorized use
          of your account, change your password immediately. Allowing any other
          person or entity to use your identity for posting on or using
          PocketDoctor is not permitted. Do not include your real name or any
          other information that could be used to identify you in anything you
          post that is publicly visible on PocketDoctor (such as in questions or
          other public submissions).
        </p>
        <p>
          We reserve the right to revoke or deactivate your username and
          password at any time. You may terminate your account and these Terms
          at any time by selecting to deactivate your account in the settings
          page or visiting the customer service help center at{" "}
          <UnifiedLink to={supportPage}>support</UnifiedLink>.
        </p>
        <Typography as="h3" type="h3">
          You must be an adult to use PocketDoctor
        </Typography>
        <p>
          Currently, you must be at least 16 years old to use PocketDoctor and
          at least 18 years old to register for PocketDoctor Premium Plan and
          Free Plan or to participate in a Virtual Consult.
        </p>
        <Typography as="h3" type="h3">
          Your use must be Legal and Appropriate
        </Typography>
        <p>
          Your use of PocketDoctor and any Content and Services must comply with
          all applicable federal and state laws, regulations, and ordinances.
          You may not access our networks, computers, or the Content and
          Services in any manner that could damage, disable, overburden, or
          impair them, or interfere with any other person's use and enjoyment.
          You may not attempt to gain unauthorized access to any Content or
          Services, other accounts, computer systems, or networks connected to
          PocketDoctor, the Content, or Services. You may not use any automated
          means (such as a scraper) to access PocketDoctor, the Content, or
          Services. Unauthorized access includes using credentials to access
          PocketDoctor. Any attempt by any individual or entity to solicit login
          information of any other user or PocketDoctor Medical Expert, or to
          access any such account, is an express and direct violation of these
          Terms and of applicable law, including relevant privacy and security
          laws and laws prohibiting unfair or unethical business practices.
        </p>
        <p>
          We maintain guidelines and a code of conduct for users of
          PocketDoctor. By using our Services you agree to abide by our
          Guidelines.
        </p>
        <Typography as="h3" type="h3">
          We'll send you notices and information
        </Typography>
        <p>
          For more details about when and how we can communicate with you,
          please consult our Privacy Statement.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Content and Services
        </Typography>
        <Typography as="h3" type="h3">
          PocketDoctor is a paid and free Service That Offers Paid Services and
          Free Services
        </Typography>
        <p>
          You can ask health questions for free and get health information
          answers and PocketDoctor tips. PocketDoctor also provides additional
          services and Content that can be accessed for a fee and free of
          charge, such as virtual consultations. PocketDoctor also offers a
          PocketDoctor Premium Plan and a Free Plan. We will never ask you for
          your credit card or other payment information unless you indicate that
          you would like to use a paid service.
        </p>
        <Typography as="h3" type="h3">
          PocketDoctor is a paid and free Service That Offers Paid Services and
          Free Services
        </Typography>
        <p>
          PocketDoctor is a service for individuals to use to support their
          personal health decisions. You may use PocketDoctor for personal, but
          not for commercial purposes in accordance with all applicable federal,
          state and local laws. The Content is licensed only for the personal,
          household, and educational use of a single individual. No commercial
          use or commercial redistribution of any Content is permitted. Licensed
          U.S. doctors and other qualified medical experts may apply to
          participate on PocketDoctor for educational purposes as Medical
          Experts, or to provide Virtual Consults. Companies or other
          organizations may not become registered members or use our Apps
          through individual members, but we do offer enterprise services for
          organizations.
        </p>
        <Typography as="h3" type="h3">
          PocketDoctor does not guarantee the accuracy of Third Party Content
        </Typography>
        <p>
          PocketDoctor has no editorial control over or responsibility for
          Content provided by third parties. Any opinions, statements, products,
          services, or other information expressed or made available by third
          parties (including Medical Experts) or users on PocketDoctor are those
          of such third parties or users. PocketDoctor does not have any
          obligation to monitor such third party Content. We make no
          representations about the accuracy or reliability of any opinion,
          statement, or other information provided by any third party, and we do
          not represent or warrant that your use of the Content displayed or
          referenced on PocketDoctor will not infringe the rights of third
          parties not owned by or affiliated with PocketDoctor.
        </p>
        <Typography as="h3" type="h3">
          We are not responsible for anything outside of PocketDoctor
        </Typography>
        <p>
          The Apps may contain links to other apps, web sites, information,
          software, data, or other content, online or offline ("External Content
          and Services"). Such External Content and Services and related
          information are outside of our control. We do not control, endorse,
          verify the truth or accuracy of, or review content outside of
          PocketDoctor, and we are not responsible for such content. We do not
          warrant, nor are we in any way responsible for, information, software,
          data, or privacy policies related or pertaining to External Content
          and Services.
        </p>
        <Typography as="h3" type="h3">
          PocketDoctor may communicate with you via email, SMS, text and mobile
          push notification
        </Typography>
        <p>
          When you install our app on your mobile device, you may agree to
          receive push notifications, which are messages an app sends you on
          your mobile device when the app is not on. You can turn off
          notifications by visiting your mobile device's "settings" page.
        </p>
        <p>
          We may send you emails concerning our products and services, as well
          as those of third parties. You may opt out of promotional emails by
          following the unsubscribe instructions in the promotional email
          itself.
        </p>
        <p>
          PocketDoctor and those acting on our behalf may send you text (SMS)
          messages to the phone number you provide us. These messages may
          include operational messages about your use of the Services, as well
          as marketing messages. If you opt out, you may continue to receive
          text messages for a short period while PocketDoctor processes your
          request, and you may also receive text messages confirming the receipt
          of your opt-out request. Opting out of receiving operational text
          messages may impact the functionality that the Service provides to
          you. Text messages may be sent using an automatic telephone dialing
          system. Your agreement to receive text messages is not a condition of
          any purchase or use of the Service. Standard messaging, data and other
          fees may be charged by your carrier.
        </p>
        <p>
          Your carrier may prohibit or restrict certain mobile features, and
          certain mobile features may be incompatible with your carrier or
          mobile device. As applicable, instructions regarding how to opt-out of
          mobile features will be disclosed in connection with such features
          (instructions typically require you to text a keyword, such as "STOP,"
          "CANCEL," "UNSUBSCRIBE," to the applicable short code for the mobile
          feature, or to change your profile settings inside the Apps).
        </p>
        <p>
          You agree to notify PocketDoctor of any changes to your mobile number
          and update your account(s) on the PocketDoctor Platforms. You also
          understand and agree that by receiving communications you will be
          charged by your wireless or internet provider and that such emails,
          SMS, or mobile telephone notification may be generated by automated
          systems.
        </p>
        <Typography as="h3" type="h3">
          You agree that we may send you limited personal information by email,
          SMS, text, and mobile push notification
        </Typography>
        <p>
          When you use action-oriented features on PocketDoctor (such as
          subscribing to a health checklist or participating in a Virtual
          Consult), you agree that PocketDoctor, Inc. may send you automated
          content via email, mobile telephone, or other contact information
          provided by you in your account settings. This content may contain
          protected health information under HIPAA, including content related to
          conditions, treatments, and medications. You understand and agree that
          by using these features, you are expressly opting into receiving your
          own protected health information by email, SMS/text, or mobile push
          notifications. These communications from the Apps are not encrypted.
          Although unlikely, it is possible for these communications to be
          intercepted or accessed without your authorization. By using the Apps,
          you release PocketDoctor from any liability arising from or related to
          any such interception or unauthorized access.
        </p>
        <Typography as="h3" type="h3">
          Mandatory Removal of Content and Services
        </Typography>
        <p>
          If you violate any of these Terms of Use, your permission to use the
          Content and Services automatically terminates and you must immediately
          destroy any copies you have made of any portion of the Content. (For
          more information, see Termination.)
        </p>
        <Typography as="h3" type="h3">
          We cannot control external communications (including email, SMS, and
          notifications)
        </Typography>
        <p>
          Email, short message services (SMS), text message communications and
          mobile push notifications from the Apps are not encrypted. You can opt
          out of receiving email, SMS/text messages, and mobile push
          notifications. Although unlikely, it is possible for these
          communications to be intercepted or accessed without your
          authorization. By using the Apps, you release PocketDoctor from any
          liability arising from or related to any such interception or
          unauthorized access.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Advertising
        </Typography>
        <Typography as="h3" type="h3">
          Strictly Limited Content
        </Typography>
        <p>
          To further our mission of making reliable health information available
          to the public, we accept limited advertising and sponsorship under
          strict guidelines. Advertisers and sponsors are not permitted to make
          unsubstantiated health claims or suggest that PocketDoctor endorses
          any product or service. PocketDoctor does not endorse products or
          services. Advertisements may be placed on our website or mobile apps
          adjacent to content related to advertiser or sponsor interest, and
          also may be presented to users using non-personal data. PocketDoctor
          does not permit the collection of any personally identifiable
          information from our site visitors on PocketDoctor web or mobile apps.
          For more information see below:{" "}
          <UnifiedLink to={supportPage}>support</UnifiedLink>.
        </p>
        <Typography as="h3" type="h3">
          Advertising and Promotion Criteria
        </Typography>
        <p>
          PocketDoctor offers select advertising opportunities on our site and
          in our apps. Advertising and sponsorship revenue is used to support
          our mission of providing free access to doctor-created health content.
          Criteria are subject to change without notice. For information
          contact:{" "}
          <a href="mailto:info@pocketdoctor.us">info@pocketdoctor.us</a>.
        </p>
        <Typography as="h3" type="h3">
          Permissible Advertising
        </Typography>
        <p>
          Corporate image advertising or sponsorship, FDA-approved
          over-the-counter medications or prescription drugs (excluding Schedule
          IV drugs), health-related consumer products, fitness products or
          services, medical products or devices, pharmacies (retail and
          VIPPS-compliant online).
        </p>
        <Typography as="h3" type="h3">
          Prohibited Advertising
        </Typography>
        <p>
          Alcohol, tobacco products, weapons, health care services (clinics,
          hospitals, procedures, lab testing), legal services, pornography,
          gambling, political ads, social cause ads, religious ads, competitor
          ads, comparative advertising (including any mention of competitive
          brand names within an advertisement), pop-ups and floating content,
          and any ads that enable collection of personally identifiable
          information on the PocketDoctor site.
        </p>
        <Typography as="h3" type="h3">
          Limitations
        </Typography>
        <ul>
          <li>
            No substantiated health-related claims (supporting documentation may
            be required)
          </li>
          <li>
            No implication of endorsement by PocketDoctor affiliated entities,
            or doctors on PocketDoctor
          </li>
          <li>
            No collection of user personal information or contact information
            without express and legally compliant user consent and no appliets,
            cookies, or files may be placed that transmit personally
            identifiable information
          </li>
          <li>
            Final approval of all content is at the discretion of PocketDoctor
            and verification of content and placement may be required before it
            is produced on our site or apps
          </li>
          <li>
            Paid content must be clearly identifiable as separate and distinct
            from PocketDoctor content
          </li>
          <li>
            PocketDoctor reserves the right to determine the placement of paid
            content, and to reject, cancel or remove at any time any paid
            content from our site or apps for any reason
          </li>
          <li>No co-branding of our health information</li>
          <li>PocketDoctor does not endorse any products or services</li>
          <li>
            These guidelines are not exhaustive and are subject to change at
            PocketDoctor, Inc sole discretion at any time
          </li>
        </ul>
        <p>
          Any paid advertising listings posted on search results may be
          identified as "Ads by Google" and may be removed if they do not meet
          our criteria. Some listings may appear that don't meet our ad
          guidelines because of the way ads are served by Google.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Property Rights
        </Typography>
        <Typography as="h3" type="h3">
          PocketDoctor owns or has rights to the Content and Services
        </Typography>
        <p>
          When you use the Apps, you do so under a license from us. Subject to
          your complete and ongoing compliance with these Terms, PocketDoctor
          grants you, solely for your personal, non-commercial use, a limited,
          non-exclusive, non-transferable, non-sublicensable, revocable license
          to: (a) install and use one object code copy of our mobile App
          obtained from a legitimate marketplace on a mobile device that you own
          or control; and (b) access and use the Content and Services. This
          means that you may not use the Apps for any commercial purpose, that
          we can take away your right to use the Apps, and that you cannot give
          this license away to someone else. All right, title, and interest in
          and to the Apps, Services, and the Content, together with all related
          intellectual property rights are the property of PocketDoctor or our
          affiliates, excluding your rights under applicable law to any
          information or Content related to Virtual Consults. Rights retained by
          other parties in the Content are their respective rights. PocketDoctor
          reserves all rights to the Content not granted expressly in these
          Terms.
        </p>
        <Typography as="h3" type="h3">
          You agree not to infringe our Copyrights, Trademarks, Trade Secrets,
          Patents, or other intellectual property rights
        </Typography>
        <p>
          PocketDoctor and other related marks are registered trademarks of
          PocketDoctor. Any other trademark, brand, or content on PocketDoctor
          that is not the property of PocketDoctor is the property of its
          respective owner. You agree not to violate, or encourage others to
          violate, any right of a third party, including by infringing or
          misappropriating any third party intellectual property rights. You may
          not reproduce, create derivative works of, distribute, publicly
          display the Content (or any portion of it) without our prior written
          consent. However, you may use App features to repost Content or
          portions of Content, including through other third party applications
          and mediums (such as Facebook or Twitter), so long as you do not
          modify that Content or the functionality of those features. This
          re-posting right does not create any additional rights in such
          Content. Additionally, you may not use any metatags or any other
          "hidden text" utilizing the name "PocketDoctor" without our prior
          written permission.
        </p>
        <Typography as="h3" type="h3">
          You agree not to access, attempt to access, or use our data without
          our permission
        </Typography>
        <p>
          Except and solely to the extent such a restriction is impermissible
          under applicable law, you may not: (a) reproduce, distribute, publicly
          display, or publicly perform the Apps, Services, or Content; (b) make
          modifications to the Apps, Services, or Content; or (c) interfere with
          or circumvent any feature of the Apps, including any security or
          access control mechanism. If you are prohibited under applicable law
          from using the Service, you may not use it. You agree not to access,
          attempt to access, request access not authorized by the Apps or use
          any App Content or data without our permission. This means that you
          agree not to transmit, download, upload, post, sell, rent, license,
          transfer, disclose, mirror, frame, reverse engineer, decompile,
          disassemble, or use any aspect of the Apps or any Content, in whole or
          in part, in any form or by any means.
        </p>
        <Typography as="h3" type="h3">
          Contact us if you believe materials on our Apps infringe your
          copyright
        </Typography>
        <p>
          If you believe any materials accessible on or from PocketDoctor
          infringe your valid and enforceable copyright, you may request removal
          of (or access to) those materials (or access thereto) from us by
          contacting us and providing us with information pursuant to our DMCA
          Removal Procedure.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Submissions
        </Typography>
        <Typography as="h3" type="h3">
          You agree to abide by our Submission Guidelines
        </Typography>
        <p>
          You agree that you will not upload or transmit any communications or
          content of any type that infringes or violates any rights of any
          party. The personal information you submit to PocketDoctor is governed
          by the Privacy Statement (the terms of which govern in the event of
          any inconsistency with this TOU). You agree that submissions will
          comply with PocketDoctor Guidelines. If you are a user, you agree to
          the User Guidelines. If you are a Medical Expert, you agree to the
          Medical Expert Guidelines.
        </p>
        <Typography as="h3" type="h3">
          You give us rights in what you submit
        </Typography>
        <p>
          Don't submit anything to us if you don't want to give us rights to it.
          If you wish to keep any content, business information, ideas, concepts
          or inventions private or proprietary, do not submit them on, through,
          or to PocketDoctor, by email or otherwise. With the exception of any
          personal data or information you submit (which shall be maintained in
          accordance with our Privacy Statement) and other information governed
          by the Health Insurance Portability and Accountability Act of 1996,
          Public Law 104-191 and the rules and regulations promulgated
          thereunder (as amended to date, "HIPAA"), or other applicable laws, if
          you make any submissions (by email or otherwise) on, to or through
          PocketDoctor, including but not limited to media (including
          photographs), data, questions, comments, suggestions, business
          information, ideas, concepts or inventions (collectively
          "Submissions"), you make such submission without any restrictions or
          expectation of compensation, privacy, or confidentiality. You agree
          that your Submissions may be used by us without restriction for any
          purpose whatsoever. By making any a Submission, you grant PocketDoctor
          a worldwide, non-exclusive, irrevocable, royalty-free, fully paid
          right and license (with the right to sublicense) to host, store,
          transfer, display, perform, reproduce, modify for the purpose of
          formatting for display, and distribute your Submissions, in whole or
          in part, in any media formats and through any media channels now known
          or hereafter developed. This means PocketDoctor has the complete right
          to freely use, create derivative works from and modify, such
          Submissions in any way, commercial or otherwise (including developing
          and marketing products or features using such information), and for
          any purpose whatsoever and without limitation. PocketDoctor may
          sublicense its rights.
        </p>
        <p>
          By making any a Submission, you further agree to indemnify
          PocketDoctor and its affiliates, directors, officers, Medical Experts,
          and employees, and to hold them harmless from any and all claims and
          expenses, including attorneys' fees, arising from your Submissions, or
          your failure to comply with these Terms.
        </p>
        <p>
          PocketDoctor does not accept unsolicited recruiter or similar
          submissions. Any candidate submissions by a recruiter or other third
          party without a valid and signed recruiting agreement in place with
          PocketDoctor prior to such submission will not be subject to any
          recruiter or similar fees.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Termination
        </Typography>
        <Typography as="h3" type="h3">
          All of our members are required to honor this Agreement
        </Typography>
        <p>
          Your permission to use the Apps, Content, and Services ends
          immediately if you violate any of the terms of this Agreement. We may
          place limits on, modify, or terminate your right to access and use
          Apps and the Services and/or Content at any time for any reason or no
          reason, with or without notice. This suspension or termination may
          delete information, files, and other previously available Content. We
          also reserve the right to modify or discontinue the Services at any
          time (including by limiting or discontinuing certain features of the
          Apps), temporarily or permanently, without notice to you. We will have
          no liability whatsoever on account of any change to the Services or
          any suspension or termination of your access to or use of the
          Services.
        </p>
        <p>
          You may terminate your account at any time by visiting the customer
          service help center at{" "}
          <UnifiedLink to={supportPage}>support</UnifiedLink>. If you terminate
          your account, you remain obligated to pay all outstanding fees, if
          any, incurred prior to termination relating to your use of the
          Services.
        </p>
        <Typography as="h3" type="h3">
          Effect of Termination
        </Typography>
        <p>
          Upon termination of these Terms: (a) your license rights will
          terminate and you must immediately cease all use of the Service; (b)
          you will no longer be authorized to access your account or the
          Service; (c) you must pay PocketDoctor any unpaid amount that was due
          prior to termination; and (d) all payment obligations accrued prior to
          termination, this section, and sections regarding Submissions,
          ownership, indemnities, disclaimer of warranties, limitations on
          liability, and the Arbitration Agreement will survive.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Indemnification &amp; Exclusions and Limitations
        </Typography>
        <Typography as="h3" type="h3">
          Exclusion of Warranties
        </Typography>
        <p>
          THE APPS AND THE CONTENT AND SERVICES ARE PROVIDED "AS IS" AND ON AN
          "AS AVAILABLE" BASIS WITHOUT WARRANTY OR CONDITION OF ANY KIND, EITHER
          EXPRESS OR IMPLIED. NEITHER WE, POCKETDOCTOR MEDICAL EXPERTS, NOR ANY
          OF OUR LICENSORS MAKE ANY EXPRESS WARRANTIES, AND WE AND EACH OF THEM
          DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING THE IMPLIED WARRANTIES OF
          ACCURACY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT. NEITHER WE, POCKETDOCTOR MEDICAL EXPERTS, NOR ANY OF
          OUR LICENSORS MAKE ANY WARRANTY THAT CONTENT OR SERVICES SATISFY
          GOVERNMENT REGULATIONS, INCLUDING THOSE REQUIRING DISCLOSURE OF
          INFORMATION ON PRESCRIPTION DRUG PRODUCTS. POCKETDOCTOR AND THE
          CONTENT AND SERVICES WERE DEVELOPED FOR USE IN THE UNITED STATES, AND
          NEITHER WE NOR ANY OF OUR LICENSORS MAKE ANY REPRESENTATION CONCERNING
          POCKETDOCTOR AND THE CONTENT OR SERVICES WHEN USED IN ANY OTHER
          COUNTRY.
        </p>
        <p>
          NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
          FROM THE SERVICE POCKETDOCTOR, OR ANY MATERIALS OR CONTENT AVAILABLE
          THROUGH THE APPS, WILL CREATE ANY WARRANTY THAT IS NOT EXPRESSLY
          STATED IN THESE TERMS.
        </p>
        <p>
          Specifically, and without limiting the foregoing, we, our licensors,
          and our suppliers, make no representations or warranties about: (i)
          the accuracy, reliability, completeness, currentness, or timeliness of
          the Content provided on or through the use of the App; or (ii)
          regulations requiring disclosure of information on prescription drug
          products or the approval or compliance of any software tools with
          PocketDoctor. Any location data accessed via the Apps may be
          inaccurate or incomplete and any use of such data is at your own risk.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Limitation of Liability of PocketDoctor and Third Party Beneficiaries
        </Typography>
        <p>
          WE ARE NOT RESPONSIBLE FOR ANY DAMAGE THAT MAY RESULT FROM THE SERVICE
          AND YOUR DEALING WITH ANY OTHER USER, INCLUDING MEDICAL EXPERTS. YOU
          UNDERSTAND AND AGREE THAT YOU USE ANY PORTION OF THE SERVICE AT YOUR
          OWN DISCRETION AND RISK, AND THAT WE ARE NOT RESPONSIBLE FOR ANY
          DAMAGE TO YOUR PROPERTY OR ANY LOSS OF DATA, INCLUDING SUBMISSIONS.
        </p>
        <p>
          To the fullest extent permitted by law, in no event will PocketDoctor,
          its licensors, suppliers, or any third parties mentioned on
          PocketDoctor be liable for any personal injury, including death,
          attributable to or caused by your use or misuse of PocketDoctor or
          Content (including Medical Expert Content). Any claims arising in
          connection with your use of the Apps, Services, or any Content must be
          brought within one (1) year of the first date of the event giving rise
          to such action. Remedies under these Terms are exclusive and are
          limited to those expressly provided for in these Terms. You expressly
          agree that Medical Experts are third party beneficiaries under these
          Terms and may enforce the rights hereunder, including Limitation of
          Liability rights.
        </p>
        <p>
          NEITHER WE, POCKETDOCTOR MEDICAL EXPERTS, NOR ANY OF OUR LICENSORS MAY
          BE HELD LIABLE UNDER THIS AGREEMENT FOR ANY DAMAGES OTHER THAN DIRECT
          DAMAGES, EVEN IF THE PARTY KNOWS OR SHOULD KNOW THAT OTHER DAMAGES ARE
          POSSIBLE, OR THAT DIRECT DAMAGES ARE NOT A SATISFACTORY REMEDY. THESE
          LIMITATIONS APPLY TO YOU ONLY TO THE EXTENT THEY ARE LAWFUL IN YOUR
          JURISDICTION. EXCEPT AS PROVIDED IN THE ARBITRATION AGREEMENT, NEITHER
          US, THE MEDICAL EXPERTS ON POCKETDOCTOR, NOR ANY OF OUR LICENSORS MAY
          BE HELD LIABLE UNDER THIS AGREEMENT TO ANY USER FOR ANY CLAIMS (IN
          AGGREGATE OVER ALL TIME) FOR MORE THAN THE GREATER OF: (I) FEES PAID
          BY THE USER TO POCKETDOCTOR OVER THE COURSE OF THE SIX (6) MONTHS
          IMMEDIATELY PRIOR TO THE INCIDENT GIVING TO THE ALLEGED DAMAGES; OR
          (II) FOR USERS WHO HAVE NOT SO USED PAID SERVICES, TO ONE HUNDRED
          DOLLARS ($100).
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Limitations of Liability and Indemnity for Expert Content
        </Typography>
        <p>
          Informational content on the Apps that is created, modified,
          submitted, or validated by Medical Experts or other healthcare experts
          (collectively, "Expert Content") is subject to the following
          additional terms and conditions and agreements by You.
        </p>
        <p>
          Expert Content is for informational purposes only. For medical advice,
          treatment, or diagnosis, see your personal doctor or healthcare
          provider. PocketDoctor is not responsible for Expert Content. The
          authors or posters of Expert Content ("Posters") are solely
          responsible for such content. No representations, warranties, or
          guarantees of any kind are made regarding the Expert Content. Under no
          circumstances shall any party be liable (to you or to any other
          person) for any damages or harm (of any type or under any legal
          theory) resulting from or related to the Expert Content. No party
          shall have any liability for: (a) any loss or injury caused, in whole
          or in part, by a Poster's actions, omissions, or negligence, in
          procuring, compiling, or delivering information within or through
          Expert Content; (b) any errors, omissions, or inaccuracies in Expert
          Content (regardless of cause), or delays or interruptions in delivery
          of such information; or (c) any decision made or action taken or not
          taken in reliance upon such information. You agree to indemnify and
          hold the Poster (and the Poster's employer and/or sponsor) harmless
          from any claim or demand, including attorneys' fees, made by any third
          party as a result of any violation of law that occurs by you through
          your use of Expert Content or this Site and/or anything you do using
          Expert Content, our Apps and/or the information contained therein.
        </p>
        <p>
          IF YOU ARE DISSATISFIED WITH ANY OF THE CONTENT OR MATERIALS ON OUR
          SITE, OR ANY SERVICES OR INFORMATION AVAILABLE THROUGH THE SITE, YOUR
          SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE ACCESSING AND USING OUR
          SITE. THIS LIMITATION APPLIES EVEN IF YOUR REMEDIES UNDER THIS
          AGREEMENT FAIL OF THEIR ESSENTIAL PURPOSE.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Licensed Content
        </Typography>
        <Typography as="h3" type="h3">
          Certain Content may be licensed from third-parties
        </Typography>
        <p>
          The licenses for some of this Content may contain additional terms.
          When such Content licenses contain additional terms, we will make
          these terms available to you on those pages or in the Terms of Use.
          The Apps may include code and components licensed under an open source
          license.
        </p>
        <Typography as="h3" type="h3">
          Foreign Languages
        </Typography>
        <p>
          Where PocketDoctor Content or Services (including these Terms) are
          translated into languages other than English, all such translations
          are for the convenience of our users only, and PocketDoctor is not
          responsible or liable in the event of any translation inaccuracy. The
          English-language version of these Terms shall control and apply In the
          event of any conflict with content or translation. You understand that
          Content, including but not limited to questions and answers, may not
          have the same meaning in translation, and that treatments (including
          but not limited to medication names) and other information may differ
          from country to country and in different languages and may not be
          available in all places. Additionally, you understand and agree that
          Virtual Consults with Medical Experts may not be available in
          languages other than English, and you agree not to conduct a
          consultation with a doctor in a language in which you are not
          proficient without the use of a professional translator. If you are
          connected to a Medical Expert who speaks your language, you understand
          that the doctor may not be a native speaker and that the doctor's
          ability to communicate may be limited. If you choose to use a
          translator in connection with a Virtual Consult, you understand that
          PocketDoctor is not liable for any errors or omissions in translation.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          General Legal Terms
        </Typography>
        <Typography as="h3" type="h3">
          General Terms
        </Typography>
        <p>
          These Terms, and the other agreements referenced in it (like our
          Privacy Statement and Cookie Policy), are the entire agreement between
          you and us relating to the PocketDoctor Apps. Your use of the Services
          is subject to all additional terms, policies, rules, or guidelines
          applicable to the Service or certain features of the Service that we
          may post on or link to from the Service (the "Additional Terms"). All
          Additional Terms are incorporated by this reference into, and made a
          part of these Terms. These Terms replace any prior agreements unless
          such prior or subsequent agreement explicitly provides otherwise and
          specifically references these Terms. If there is any conflict between
          these Terms and a mutually signed written agreement between you and us
          related to PocketDoctor, the signed written agreement will control.
          You may not assign or transfer these Terms or your rights under these
          Terms, in whole or in part, by operation of law or otherwise, without
          our prior written consent. We may assign these Terms at any time
          without notice or consent.
        </p>
        <p>
          If we choose not to enforce any provision of these Terms, we retain
          the right to enforce it in the future. This means that the failure to
          enforce any provision of these Terms does not constitute a waiver of
          that provision. If any provision in these Terms is found to be
          unenforceable, that provision and any related provisions will be
          interpreted to best accomplish the unenforceable provision's essential
          purpose.
        </p>
        <p>
          This agreement is governed by Delaware law, excluding Delaware
          choice-of-law rules. THE EXCLUSIVE VENUE FOR ANY DISPUTE RELATING TO
          THIS AGREEMENT IS DELAWARE. YOU AND US CONSENT TO THE PERSONAL
          JURISDICTION OF THESE COURTS. Nothing in this agreement limits either
          party's ability to seek equitable relief.
        </p>
        <p>
          IF YOU ARE DISSATISFIED WITH ANY OF THE CONTENT ON OUR APPS, OR ANY
          SERVICES OR INFORMATION AVAILABLE ON OR THROUGH THE APPS, YOUR SOLE
          AND EXCLUSIVE REMEDY IS TO DISCONTINUE ACCESSING AND USING OUR APPS.
          THIS LIMITATION APPLIES EVEN IF YOUR REMEDIES UNDER THIS AGREEMENT
          FAIL THEIR ESSENTIAL PURPOSE.
        </p>
        <p>
          The Apps and Services are offered by PocketDoctor, Inc. located at:
          16192 Coastal Highway, Lewes Delaware 19958, County of Sussex. You may
          contact us by sending correspondence to that address or by visiting
          the customer service help center at{" "}
          <UnifiedLink to={supportPage}>support</UnifiedLink>.
        </p>
        <p>
          We are under no obligation to provide support for the Service. In
          instances where we may offer support, the support will be subject to
          published policies.
        </p>
        <p>
          Notice Regarding Apple. This section only applies to the extent you
          are using our mobile application on an iOS device. You acknowledge
          that these Terms are between you and PocketDoctor only, not with Apple
          Inc. ("Apple"), and Apple is not responsible for the App or the
          content thereof. Apple has no obligation to furnish any maintenance
          and support services with respect to the App. If the App fails to
          conform to any applicable warranty, you may notify Apple and Apple
          will refund any applicable purchase price for the mobile application
          to you; and, to the maximum extent permitted by applicable law, Apple
          has no other warranty obligation with respect to the App. Apple is not
          responsible for addressing any claims by you or any third party
          relating to the App or your possession and/or use of the App,
          including: (a) product liability claims; (b) any claim that the App
          fails to conform to any applicable legal or regulatory requirement; or
          (c) claims arising under consumer protection or similar legislation.
          Apple is not responsible for the investigation, defense, settlement
          and discharge of any third party claim that the App and/or your
          possession and use of the App infringe a third party's intellectual
          property rights. You agree to comply with any applicable third party
          terms when using the App. Apple and Apple's subsidiaries are third
          party beneficiaries of these Terms, and upon your acceptance of these
          Terms, Apple will have the right (and will be deemed to have accepted
          the right) to enforce these Terms against you as a third party
          beneficiary of these Terms. You hereby represent and warrant that: (i)
          you are not located in a country that is subject to a U.S. Government
          embargo, or that has been designated by the U.S. Government as a
          "terrorist supporting" country; and (ii) you are not listed on any
          U.S. Government list of prohibited or restricted parties.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          User Guidelines
        </Typography>
        <p>
          By using PocketDoctor, including Virtual Consults, you agree to abide
          by the following guidelines. Any violation may result in limitation,
          suspension, or termination of your access to our Apps.
        </p>
        <Typography as="h3" type="h3">
          General Guidelines
        </Typography>
        <ul>
          <li>
            <strong>Respect</strong>. You agree to engage with Medical Experts
            in a respectful manner and to refrain from inappropriate language
            and behavior.
          </li>
          <li>
            <strong>Appropriate Use.</strong> You agree to only use PocketDoctor
            and Virtual Consults in a manner that is not: unlawful, threatening,
            harassing, abusive, defamatory, slanderous, libelous, harmful to
            minors, vulgar, gratuitously violent, obscene, pornographic,
            indecent, lewd, invasive of another's privacy, or racially,
            ethnically or otherwise offensive, hateful, or abusive.
          </li>
        </ul>
        <Typography as="h3" type="h3">
          Guidelines for Posting Content (Including Questions)
        </Typography>
        <ul>
          <li>
            <strong>Informational Questions Only.</strong> Only informational
            questions submitted for educational purposes are allowed. Do not
            post any facts that give the impression that a question is uniquely
            patient-specific, or are about a specific person, including
            yourself.
          </li>
          <li>
            <strong>
              No Requests for Medical Care, Diagnosis, or Treatment.
            </strong>{" "}
            No requests for prescription, diagnosis, or treatment should be made
            on PocketDoctor (for medical care, please use Virtual Consults, as
            appropriate and available). Any second opinions received on
            PocketDoctor are not diagnosis, prescription, or treatment, and are
            for informational purposes only.
          </li>
          <li>
            <strong>No Guarantees.</strong> There is no guarantee that any
            submitted question will be answered; questions are answered at the
            sole discretion of participating Medical Experts. No follow-up
            questions or personal information should be included in any notes or
            other posts on or through PocketDoctor.
          </li>
          <li>
            <strong>Only Adults May Post.</strong> Minors (persons under the age
            of 18) may not obtain Virtual Consults on PocketDoctor, but
            PocketDoctor permits the posting of questions by persons 16 years of
            age and older. Caregivers may post educational questions related to
            authorized care recipients (such as the mother posting questions
            about an infant).
          </li>
          <li>
            <strong>No Posting of Personally Identifiable Information.</strong>{" "}
            Do not post name(s), email address(es), or telephone number(s),
            URLs, or any other confidential or personally identifiable
            information about you or any other person or entity on PocketDoctor.
            The foregoing does not apply to the extent necessary to use Virtual
            Consults or PocketDoctor Premium Plan and Free Plan in accordance
            with these Terms.
          </li>
          <li>
            <strong> No Posting for Illegal Purposes.</strong> Do not use
            PocketDoctor for any purpose in violation of local, state, federal,
            or international laws.
          </li>
          <li>
            <strong>No Infringing or Impersonating Postings.</strong> Do not
            post material that infringes on the copyrights or other intellectual
            property rights of others (never post a picture of another person
            without permission) or on the privacy or publicity rights of others.
            Do not post impersonating another person or entity.
          </li>
          <li>
            <strong>No Inappropriate Postings.</strong> Do not post material
            that is unlawful, misleading, obscene, sexually explicit, medically
            inappropriate, derogatory, defamatory, threatening, harassing,
            abusive, slanderous, hateful, or embarrassing to any other person or
            entity, or any material that could give rise to any civil or
            criminal liability under applicable law or regulations or that
            otherwise may be in conflict with our Terms or Privacy Statement.
          </li>
          <li>
            <strong>No Viruses or Software.</strong> You agree that you will not
            upload any material that contains software viruses or any other
            computer code, files, or programs designed to interrupt, destroy or
            limit the functionality of any computer software or the Apps.
          </li>
          <li>
            <strong>No Excessive or Inappropriate use.</strong> Do not use the
            Apps excessively, inappropriately, or in ways that the Apps were not
            designed for or that are not, in the opinion of PocketDoctor Medical
            Experts, medically appropriate or legitimate.
          </li>
          <li>
            <strong> No Promotions or Links.</strong> Do not post
            advertisements, solicitations, or links to other websites or
            individuals.
          </li>
          <li>
            <strong> No Spam or Schemes.</strong> Do not post the same question
            more than once or "spam" PocketDoctor. Do not post chain letters or
            pyramid or other schemes.
          </li>
          <li>
            <strong> No Detrimental Behavior.</strong> Engaging in any other
            conduct that restricts or inhibits any other person from using or
            enjoying PocketDoctor, or which, in the judgment of PocketDoctor,
            exposes us or any of our members, partners or suppliers to any
            liability or detriment of any type.
          </li>
        </ul>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Guidelines Specific to Virtual Consults and Other Paid Services
        </Typography>
        <ul>
          <li>
            <strong>Treatment.</strong> You agree not to make demands for
            treatment from a Medical Expert on PocketDoctor.
          </li>
          <li>
            <strong>Care.</strong> Medical care (including Virtual Consults with
            Medical Experts, prescriptions, diagnosis, or treatment
            recommendations) is available on PocketDoctor, and you agree that
            such care is limited to medically necessary and appropriate care, as
            determined by the treating Medical Expert.
          </li>
          <li>
            <strong>Care Limitations.</strong> You may use Virtual Consults
            regarding discrete and episodic medical issues. Examples of
            inappropriate uses of Virtual Consults are (but are not limited to)
            use of Virtual Consults to replace or obtain: emergency care;
            in-person routine physicals and vaccinations (except for vaccines
            that can be ordered for you to get at a pharmacy); health
            verifications or disability forms that require an in-person exam;
            in-person tests or screenings, such as Pap smears, breast exams,
            prostate exams, and strep throat swabs; lifestyle treatments, such
            as medication for smoking cessation, losing weight, stopping
            addiction, and erectile dysfunction; and ongoing care of chronic
            conditions like diabetes, high blood pressure, arthritis, anxiety,
            depression, epilepsy, lupus, and chronic pain (except for medical
            advice of a limited nature); as well as falsifying your location,
            doctor-shopping or otherwise repeating consults for the same issue.
            We reserve the right to review your usage and to suspend or
            terminate your PocketDoctor account if we find that you have misused
            Virtual Consults. Our conclusions based on those reviews are final.
          </li>
          <li>
            <strong>Prescriptions and Lab Tests.</strong> You agree that you do
            not have a right to expect, demand, or to receive any particular
            prescription or lab test when using PocketDoctor. Prescriptions and
            lab tests, where available, are provided at the sole discretion of
            the consulting Medical Expert. Patients who are located outside the
            U.S. may not receive lab orders and may receive prescription
            recommendations only.
          </li>
          <li>
            <strong>Prescriptions and Lab Tests Limitations.</strong> You
            understand and agree that not all prescriptions and lab tests are
            available using PocketDoctor. The following medication categories
            cannot be prescribed or recommended in Virtual Consults on
            PocketDoctor: DEA category I-IV medications, lifestyle medications
            (such as weight loss or hair loss medication), and U.S.-state
            regulated medications. Lab tests are currently ordered using Quest
            Diagnostics, and it may not be possible to order all of Quest
            Diagnostics lab tests (if any) in all U.S. states.
          </li>
          <li>
            <strong>Duration of Treatment.</strong> You agree that the duration
            of any treatment prescribed or recommended may be limited. It is up
            to the consulting Medical Expert to determine the appropriate
            duration of any treatment, if any, made available through a Virtual
            Consult. Where prescriptions are provided, they may be a
            short-course to hold you over until you are able to consult with
            your regular doctor.
          </li>
          <li>
            <strong>Dress Code.</strong> You agree to be appropriately dressed
            and to show personal or private body parts only when it is of
            medical relevance to do so.
          </li>
        </ul>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Reservation of Rights
        </Typography>
        <ul>
          <li>
            <strong>Rejection and Removal of content.</strong> Subject to
            applicable law and any related limitations, we reserve the right to
            review any submission or Content on our Apps and Services, and to
            not post such Content or to remove or request the removal of any
            Content for any reason, at any time, without prior notice, at our
            sole discretion.
          </li>
          <li>
            <strong>Termination.</strong> We reserve the right to restrict or
            terminate access to our Content or Services by any individual who
            violates our Terms, or engages in any other conduct that, in our
            sole judgement, restricts or inhibits any other person from using or
            enjoying PocketDoctor or exposes us or any of our members, partners
            or suppliers to any liability or detriment of any type.
          </li>
        </ul>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          PocketDoctor Arbitration Agreement
        </Typography>
        <Typography as="h3" type="h3">
          Summary
        </Typography>
        <p>
          PocketDoctor Terms require the use of arbitration to resolve disputes
          and also limits the remedies available to you in the event of a
          dispute. Most concerns can be resolved quickly by visiting the
          customer service help center at{" "}
          <UnifiedLink to={supportPage}>support</UnifiedLink>. In the unlikely
          event that we are unable to resolve a legitimate legal complaint, we
          each agree to resolve those disputes through binding arbitration or
          small claims instead of in courts of general jurisdiction. Arbitration
          is more informal than a lawsuit in court. Arbitration uses a neutral
          arbitrator instead of a judge or jury, allows for more limited
          discovery than in court, and is subject to very limited review by
          courts. Arbitrators can award the same damages and relief that a court
          can award. Any arbitration under this Agreement will take place on an
          individual basis; class arbitrations and class actions are not
          permitted. We request that you work with us in good faith to resolve
          any disputes for 30 days after notifying us of such issues before
          filing arbitration. You understand that any award available under
          arbitration is subject to the limitations in the PocketDoctor TOUs.
          YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND
          POCKETDOCTOR ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO
          PARTICIPATE IN A CLASS ACTION.
        </p>
        <Typography as="h3" type="h3">
          Agreement
        </Typography>
        <p>
          First things first: let's try to sort it out. We want to address your
          concerns without a formal arbitration or case. Before filing a claim
          against PocketDoctor, you agree to make a good faith effort to try to
          resolve the dispute informally by contacting{" "}
          <a href="mailto:info@pocketdoctor.us">info@pocketdoctor.us</a> and
          responding promptly to any related communications. We'll try to
          resolve the dispute by contacting you via email. If a dispute is not
          resolved within 30 days of submission, you or PocketDoctor may bring a
          formal proceeding.
        </p>
        <p>
          This Arbitration Agreement ("Arbitration Agreement") is a condition of
          the Terms of Use of PocketDoctor and the Apps. The effective date of
          this agreement is March 31, 2021 (the "Effective Date").
        </p>
        <Typography as="h3" type="h3">
          General
        </Typography>
        <p>
          If you reside in the United States or are otherwise subject to the US
          Federal Arbitration Act, you agree that any and all disputes or claims
          that have arisen or may arise between us &mdash; except as set forth
          below ("Exceptions") &mdash; shall be resolved exclusively through
          final and binding arbitration, rather than in court. The Federal
          Arbitration Act governs the interpretation and enforcement of this
          Arbitration Agreement.
        </p>
        <p>
          Any arbitration proceedings shall be conducted by the American
          Arbitration Association ("AAA") under its rules and procedures
          applicable at that time, including the AAA's Supplementary Procedures
          for Consumer-Related Disputes (to the extent applicable), as modified
          by our Arbitration Agreement. You may review those rules and
          procedures, and obtain a form for initiating arbitration proceedings
          at the AAA's website. The AAA Rules and filing forms are available
          online at www.adr.org, by calling the AAA at 1-800-778-7879, or by
          contacting PocketDoctor. The arbitration shall be held in Santa Clara
          County, California or at another mutually agreed location. If the
          reasonable value of the relief sought is US$10,000 or less, either of
          us may elect to have the arbitration conducted by telephone or based
          solely on written submissions, which election shall be binding on us
          subject to the arbitrator's discretion to require an in-person
          hearing. Attendance at an in-person hearing may be made by telephone
          by you and/or us, unless the arbitrator requires otherwise.
        </p>
        <Typography as="h3" type="h3">
          Exceptions
        </Typography>
        <p>
          Notwithstanding the foregoing, nothing in this Arbitration Agreement
          will be deemed to waive, preclude, or otherwise limit the right of
          either party to: (a) bring an individual action in small claims court;
          (b) pursue an enforcement action through the applicable federal,
          state, or local agency if that action is available; (c) seek
          injunctive relief in a court of law in aid of arbitration; or (d) to
          file suit in a court of law to address an intellectual property
          infringement claim.
        </p>
        <Typography as="h3" type="h3">
          Notice
        </Typography>
        <p>
          A party who intends to seek arbitration must first send to the other,
          by certified mail, a written Notice of Dispute ("Notice"). The Notice
          to PocketDoctor should be addressed to: General Counsel, PocketDoctor,
          16192 Coastal Highway, Lewes Delaware 19958, County of Sussex.
          ("Notice Address"). The Notice must (a) describe the nature and basis
          of the claim or dispute; and (b) set forth the specific relief sought
          and the specific applicable legal basis for the requested relief
          ("Demand"). The parties will make good faith efforts to resolve the
          claim directly, but if the parties do not reach an agreement to do so
          within 30 days after the Notice of Arbitration is received, you or
          PocketDoctor may commence an arbitration proceeding. All arbitration
          proceedings between the parties will be confidential unless otherwise
          agreed by the parties in writing. During the arbitration, the amount
          of any settlement offer made by you or PocketDoctor must not be
          disclosed to the arbitrator until after the arbitrator makes a final
          decision and award, if any.
        </p>
        <p>
          The arbitrator will decide the substance of all claims in accordance
          with the laws of the State of California, including recognized
          principles of equity, and will honor all claims of privilege
          recognized by law. The arbitrator shall not be bound by rulings in
          prior arbitrations involving different PocketDoctor users, but is
          bound by rulings in prior arbitrations involving the same user to the
          extent required by applicable law. The arbitrator's award shall be
          final and binding and judgment on the award rendered by the arbitrator
          may be entered in any court having jurisdiction over the parties,
          except for a limited right of appeal under the Federal Arbitration
          Act.
        </p>
        <Typography as="h3" type="h3">
          Opt-Out
        </Typography>
        <p>
          If you do not wish to resolve disputes by binding arbitration, you may
          opt out of this Arbitration Agreement within 30 days after the date
          that you agree to the Terms by sending a letter to General Counsel,
          PocketDoctor, Inc. located at 16192 Coastal Highway, Lewes Delaware
          19958, County of Sussex that specifies: your full legal name, the
          email address associated with your account on the Service, and a
          statement that you wish to opt out of arbitration ("Opt-Out Notice").
          Once PocketDoctor receives your Opt-Out Notice, this Arbitration
          Agreement will be void and any action arising out of the Terms will be
          resolved under the governing law and jurisdiction set forth in the
          Terms. The remaining provisions of the Terms will not be affected by
          your Opt-Out Notice.
        </p>
        <Typography as="h3" type="h3">
          Fees
        </Typography>
        <p>
          The AAA rules will govern the payment of all filing, administration
          and arbitrator fees, unless our Arbitration Agreement expressly
          provides otherwise. If the amount of any claim in an arbitration is US
          $5,000 or less, PocketDoctor will pay all arbitrator fees associated
          with the arbitration, so long as: (a) you make a written request for
          such payment of fees and submit it to the AAA with your Demand for
          Arbitration; and (b) your claim is not determined by the arbitrator to
          be frivolous or without merit under PocketDoctor Terms of Use or
          otherwise ("Frivolous Claims"). In such case, we will make
          arrangements to pay all necessary fees directly to the AAA. If the
          amount of the claim exceeds US$10,000 and you are able to demonstrate
          that the costs of arbitration will be prohibitive as compared to the
          costs of litigation, PocketDoctor will pay as much of the filing,
          administration, and arbitrator fees as the arbitrator deems necessary
          to prevent the arbitration from being cost-prohibitive. If the
          arbitrator determines the claim(s) you assert in the arbitration are
          Frivolous Claims, you agree to reimburse PocketDoctor for all fees
          associated with the arbitration paid by PocketDoctor. PocketDoctor
          obligations to pay these fees shall be made after a preliminary
          determination that the claims are not barred or limited by the
          PocketDoctor Terms of Use. Bared or limited claims constitute
          Frivolous Claims.
        </p>
        <Typography as="h3" type="h3">
          PROHIBITION OF CLASS AND REPRESENTATIVE ACTIONS
        </Typography>
        <p>
          YOU AND POCKETDOCTOR AGREE, AS PART OF THE ARBITRATION AGREEMENT, THAT
          EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL
          BASIS AND NOT AS PART OF ANY PURPORTED CLASS OR REPRESENTATIVE ACTION
          OR PROCEEDING. WE REFER TO THIS AS THE "PROHIBITION OF CLASS AND
          REPRESENTATIVE ACTIONS". UNLESS BOTH YOU AND WE AGREE OTHERWISE, THE
          ARBITRATOR MAY NOT CONSOLIDATE OR JOIN YOUR OR OUR CLAIM WITH ANOTHER
          PERSON'S OR PARTY'S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER ANY
          FORM OF A CONSOLIDATED, REPRESENTATIVE OR CLASS PROCEEDING. THE
          ARBITRATOR MAY ONLY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND
          DECLARATORY RELIEF) IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF
          AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY
          THAT PARTY'S INDIVIDUAL CLAIM(S). ANY RELIEF AWARDED CANNOT AFFECT
          OTHER POCKETDOCTOR USERS.
        </p>
        <Typography as="h3" type="h3">
          Enforceability
        </Typography>
        <p>
          Except with respect to the Prohibition of Class and Representative
          Actions, if a court decides that any part of this Arbitration
          Agreement is invalid or unenforceable, the other parts of this
          Arbitration Agreement shall continue to apply. If a court decides that
          the Prohibition of Class and Representative Actions is invalid or
          unenforceable, then the entire Arbitration Agreement shall be null and
          void.
        </p>
        <Typography as="h3" type="h3">
          Modifications
        </Typography>
        <p>
          If PocketDoctor makes any future change to this Arbitration Agreement,
          other than a change to PocketDoctor Notice Address, you may reject the
          change by sending us written notice within 30 days of the change to
          the Notice Address, in which case your account with PocketDoctor will
          be immediately terminated and this arbitration provision, as in effect
          immediately prior to the changes you rejected will survive.
        </p>
        <Typography as="h3" type="h3">
          CLAIMS ARE TIME-BARRED
        </Typography>
        <p>
          You agree that regardless of any statute or law to the contrary or the
          applicable dispute resolution process, any claim or cause of action
          you may have arising out of or related to use of the Service or
          otherwise under these must be filed within one (1) year after such
          claim or cause of action arose or you hereby agree to be forever
          barred from bringing such claims.
        </p>
        <Typography as="h3" type="h3">
          Additional Terms: Virtual Consults
        </Typography>
        <p>
          THE FOLLOWING TERMS APPLY TO YOUR USE OF VIRTUAL CONSULTS PERFORMED BY
          VIDEO, AUDIO (VOICE), OR TEXT CHAT, OR BY ASYNCHRONOUS TEXT INBOX
          CONSULTS (COLLECTIVELY, "VIRTUAL CONSULTS"). BY USING VIRTUAL
          CONSULTS, YOU REPRESENT THAT YOU UNDERSTAND AND AGREE TO ALL OF THE
          FOLLOWING IN ADDITION TO OUR BASIC TERMS OF USE.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Understandings, Agreements, and Representations
        </Typography>
        <Typography as="h3" type="h3">
          Medical Agreement
        </Typography>
        <ul>
          <li>
            You are entering into an agreement with PocketDoctor, Inc. which
            shall be a provider of professional medical services to you through
            the PocketDoctor Apps.
          </li>
          <li>
            You are entering into a physician - patient relationship with the
            Medical Expert associated with PocketDoctor, Inc. that personally
            performs the Virtual Consult for you.
          </li>
          <li>
            You agree to use your legal name and real medical information in
            Virtual Consults.
          </li>
          <li>
            You agree to use only one PocketDoctor account for maintaining your
            medical records and for doing Virtual Consults.
          </li>
          <li>
            You represent and warrant to PocketDoctor, Inc. that you have a
            primary care physician.
          </li>
          <li>
            You understand and agree that the physicians associated with
            PocketDoctor, Inc. are only providing minor non-emergency
            primary-care medical services with respect to Virtual Consults.
          </li>
          <li>
            You understand that you should never delay seeking advice from you
            primary care physician or other health professionals due to
            information provided by a Healthcare Provider through PocketDoctor.
            You agree to seek emergency help when needed, and to continue to
            consult with your primary care physician as recommended by Medical
            Experts on PocketDoctor and by your primary care physician.
          </li>
          <li>
            PocketDoctor, Inc. is the provider of certain administrative
            services to PocketDoctor, Inc. and does not provide professional
            medical services.
          </li>
          <li>
            You agree that physicians associated with PocketDoctor, Inc. in
            performing a Virtual Consult, may not prescribe for you, the
            following drugs:
          </li>
          <li>
            prescriptions for narcotics or DEA (Drug Enforcement Administration)
            (
            <a href="http://www.deadiversion.usdoj.gov/schedules/">
              http://www.deadiversion.usdoj.gov/schedules/
            </a>
            ) controlled substances (Schedule I, II, III, IV);
          </li>
          <li>prescriptions for medications that are restricted by states;</li>
          <li>prescriptions for medications for psychiatric illnesses; or</li>
          <li>
            prescriptions for lifestyle medications such as erectile dysfunction
            or diet drugs.
          </li>
          <li>
            PocketDoctor is a company, not a doctor, and does not practice
            medicine and does not participate in or interfere with the practice
            of medicine by Medical Experts on PocketDoctor, each of whom is
            responsible for his or her services and compliance with the
            requirements applicable to his or her profession and license.
          </li>
          <li>
            You understand that if your medical condition warrants emergency
            help, the Medical Experts associated with PocketDoctor, Inc. will
            direct you to the nearest local hospital emergency department or
            emergency room.
          </li>
          <li>
            You understand that the services offered by PocketDoctor, Inc. they
            are not health insurance and are not a substitute for health
            insurance. You agree not to use services such as health insurance.
            Virtual consultations are intended to be used independently of
            health insurance as a paid and free service, and unless explicitly
            stated otherwise, the associated fees may not qualify for insurance
            or HSA or similar reimbursement.
          </li>
          <li>
            You agree to refrain from excessive or inappropriate use of Virtual
            Consults, including but not limited to multiple Virtual Consults for
            the same issue without a valid medical reason, including but not
            limited to: initiating a subsequent Virtual Consult before you have
            received the Summary Notes from the doctor of the prior Virtual
            Consult; attempts to obtain prescriptions unavailable through
            PocketDoctor Premium and Free Services or not previously prescribed
            to you in a prior Virtual Consult for the same issue; multiple
            Virtual Consults for a symptom or condition when you have been
            advised to seek in-person care; or any other behavior deemed by
            PocketDoctor or a doctor to be excessive or inappropriate.
          </li>
          <li>
            Other than for mutually agreed upon in-person care with a Medical
            Expert on PocketDoctor, you agree to refrain from contacting or
            seeking to contact a Medical Expert for telemedicine care outside of
            the platform (such as by phone, email, or other messaging system).
            This protects both patients and Medical Experts and ensures clinical
            care is delivered in a reliable, continuous, and controlled
            platform. PocketDoctor is not responsible for any interactions with
            Medical Experts not conducted on the PocketDoctor platform.
          </li>
        </ul>
        <Typography as="h3" type="h3">
          Health Information
        </Typography>
        <ul>
          <li>
            You agree to the entry of your medical records into the
            PocketDoctor, Inc. secure computer database and understand that all
            reasonable measures have been taken to safeguard your medical
            information, in accordance with federal HIPAA standards, as amended
            to date. You acknowledge that no computer or phone system is totally
            secure. PocketDoctor, Inc. recognizes your privacy and, in
            accordance with our Privacy Statement, will not release information
            to anyone without your written authorization or as required by law.
          </li>
          <li>
            PocketDoctor maintains protected health information (PHI) in
            compliance with federal privacy and security rules (HIPAA and
            HITECH). Our contractual obligations with health care providers are
            as a Business Associate of health care providers who are Covered
            Entities such rules. PocketDoctor collects information for the
            purposes of providing our Services (including Virtual Consults),
            marketing and promoting our Services to you, and for market research
            data.
          </li>
          <li>
            You understand and agree that Virtual Consults involve the
            communication of your medical information, both orally and visually,
            to Medical Experts and other health care practitioners located in
            other parts of the state/jurisdiction or outside of the
            state/jurisdiction in which you are located at the time of a Virtual
            Consult and afterward.
          </li>
          <li>
            You also understand that PocketDoctor, Inc. a "Covered Entity" as
            defined under the Health Insurance Portability and Accountability
            Act and associated regulations. However, you understand that Medical
            Experts associated with PocketDoctor, Inc. are "Covered Entities"
            that are subject to the provisions of HIPAA pursuant to 45 CFR 103.
            Therefore, you understand and agree that your personally
            identifiable health information ("Health Information") provided to
            Medical Expert associated with PocketDoctor, Inc. is subject to or
            protected by HIPAA. We will maintain the privacy of your Health
            Information as required by HIPAA.
          </li>
          <li>
            You also understand and agree that by using PocketDoctor we may
            automatically send information related to your Virtual Consult(s),
            including related reminders, to you, and you opt in to receiving
            this information by email, mobile notification, or using other
            contact information provided by you in your account settings and
            profile information. You may opt out of receiving any such
            communication via email or mobile notification at any time through
            your account notification settings. If you prefer not to receive or
            do not consent to the receipt of personal health information by
            email or mobile notification, you agree to update your account
            notification settings before using Virtual Consults or any features
            utilizing reminders.
          </li>
          <li>
            You represent and warrant to us that, to the extent you enter
            "protected health information" (as such term is defined under HIPAA)
            for a third person, you are legally authorized to do so on behalf of
            such third person, and such third person has validly consented to
            your inputting of, and your review of "protected health information"
            of such third person on PocketDoctor and via the PocketDoctor
            website and mobile apps.
          </li>
        </ul>
        <Typography as="h3" type="h3">
          Consult Quality
        </Typography>
        <ul>
          <li>
            Virtual Consults are primary care consults, not specialist consults,
            and are not intended to address medical concerns typically addressed
            by medical specialists.
          </li>
          <li>
            You agree that Medical Experts performing Virtual Consults may
            prescribe allowed medications in such Virtual Consults when in the
            Medical Expert's sole judgement it is medically appropriate to do
            so. You agree that you are not guaranteed any prescription in a
            Virtual Consult. The determination that a medical concern warrants a
            prescription is always made at the discretion of the consulting
            Medical Expert in a Virtual Consult and not prior to such a consult.
            You agree to maintain up-to-date medication information in your
            Health Profile on PocketDoctor so that consulting Medical Experts
            may know your medication history.
          </li>
          <li>
            You understand and agree that PocketDoctor and Medical Experts on
            PocketDoctor are not responsible for disconnections or connection
            quality issues you may experience during or outside of Virtual
            Consults as a result of your mobile device's or computer's internet
            connectivity.
          </li>
          <li>
            You agree to refrain from abusive language or engaging in
            inappropriate behavior with Medical Experts during a Virtual Consult
            and agree that a Medical Expert may terminate a Virtual Consult at
            any time should inappropriate behavior or language be used, or if in
            the Medical Expert's sole judgment, the Virtual Consult is no longer
            appropriate or productive.
          </li>
          <li>
            PocketDoctor Premium Plan and Free Plan subscriptions and Virtual
            Consult fees do not include nor cover the costs of any
            recommendations or treatments associated with any Virtual Consult
            (including but not limited to: prescriptions, lab tests, services or
            treatments, devices, or referrals for non- PocketDoctor consults).
          </li>
          <li>
            24/7 Doctor Visits offer primary-care consults from Medical Experts
            only via the PocketDoctor Apps. Specialist care is not available.
            Services are provided under an "on call" model where you will be
            connected with the first-available licensed PocketDoctor Medical
            Expert. If you select a specific doctor (including specialists) for
            a 24/7 Doctor Visit, this may subject to an additional per-consult
            fee. PocketDoctor Premium Plan and Free Plan may not cover the cost
            of 24/7 Doctor Visits that originated from searching for a specific
            doctor or specialty. Pricing will be shown prior to initiating such
            24/7 Doctor Visit and by participating in such a 24/7 Doctor Visit
            you agree to pay the fees associated with such a consult.
          </li>
        </ul>
        <Typography as="h3" type="h3">
          Your Rights
        </Typography>
        <p>
          You have all the following rights with respect to Virtual Consults:
        </p>
        <ul>
          <li>
            <strong>Free Choice.</strong> I have the right to withhold or
            withdraw my consent to Virtual Consults at any time without
            affecting my right to future care or treatment.
          </li>
          <li>
            <strong>Access to Information.</strong> I have the right to inspect
            all medical information transmitted during a Virtual Consult,
            including, without limitation, the name of the licensed Medical
            Expert associated with PocketDoctor, Inc. treating me, and may
            receive copies of this information for a reasonable fee.
          </li>
          <li>
            <strong>Confidentiality. </strong> I understand that the laws that
            protect the confidentiality of medical information apply to Virtual
            Consults, and that no information or images from such interaction
            which identifies me will be disclosed to other entities without my
            consent.
          </li>
          <li>
            <strong>Risks. </strong> I understand that there are risks from
            Virtual Consults, including the following: (i) loss of records from
            failure of electronic equipment; (ii) power or other technical
            failures with loss of communication; and (iii) invasion of
            electronic records by outsiders (hackers). Finally, I understand
            that it is impossible to list every possible risk, that my condition
            may not be cured or improved, and in rare cases, may get worse.
          </li>
          <li>
            <strong> Benefits.</strong> I understand that I can expect the
            following benefits from Virtual Consults, but that no results can be
            guaranteed or assured: (i) reduced visit time; (ii) rapid innovation
            of treatments; and (iii) focused information.
          </li>
          <li>
            <strong> Follow-up.</strong> In the event that the diagnosis and
            treatment by a Medical Expert associated with PocketDoctor, Inc.
            does not resolve the medical issue for which I sought a 24/7 Doctor
            Visit, I agree to consult with my primary care physician in person
            for follow-up treatment, and/or seek treatment, if necessary at a
            local hospital emergency department.
          </li>
          <li>
            <strong> Consequences. </strong> I understand that, by having my
            consent to live Virtual Consults performed by video or telephone or
            asynchronous inbox consults, my Medical Expert associated with
            PocketDoctor, Inc. may communicate medical information concerning me
            to physicians and other health care practitioners located in other
            parts of the state/jurisdiction or outside the state/jurisdiction.
          </li>
          <li>
            <strong> Quality Assurance.</strong> I understand that records
            related to these consults may be reviewed under a PocketDoctor
            quality assurance program. Records of PocketDoctor Quality Assurance
            Committee records subject to confidentiality.
          </li>
        </ul>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Your Obligations
        </Typography>
        <ul>
          <li>
            <strong> Privacy Statement and Cookie Policy.</strong> You agree to
            PocketDoctor Privacy Statement and Cookie Policy, the terms of which
            are incorporated herein by reference.
          </li>
          <li>
            <strong> True and Accurate Information. </strong> You agree that all
            information you provide on PocketDoctor Premium and Free Services
            relates to you and is current, complete, and accurate. Additionally,
            you agree to update and maintain such information on PocketDoctor
            Premium and Free Services to ensure that it is true, accurate,
            current and complete at all times.
          </li>
          <li>
            <strong> Payment.</strong> In the future, if you use PocketDoctor as
            an individual and purchase access to a Premium PocketDoctor Plan or
            paid services, you agree to the PocketDoctor Payment Agreement, and
            pay all fees or charges to your account in accordance with the terms
            of fees, charges and billing in effect at the time of payment of the
            fee or charges.
          </li>
          <li>
            <strong> Emergency Help.</strong> You will agree to seek emergency
            help when needed or as recommended by Medical Experts you consult
            with. You agree to continue to consult with your primary care doctor
            as recommended by your primary care doctor or the Medical Experts
            you consult with.
          </li>
        </ul>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Agreement between PocketDoctor and patients
        </Typography>
        <ol>
          <li>
            The user agrees that the doctor only recommends opinions
            on&nbsp;medications, therapies, treatment strategies, etc.
            PocketDoctor is&nbsp;not responsible for the conclusions and
            consequences of&nbsp;the treatment.
          </li>
          <li>
            If&nbsp;the user has applied for an&nbsp;emergency case of&nbsp;his
            health, then the doctors and the PocketDoctor company are not
            responsible for the consequences of&nbsp;this action and the company
            does not refund the money. PocketDoctor warned.
          </li>
          <li>
            If&nbsp;the patient has signed up&nbsp;6&nbsp;months in&nbsp;advance
            and the doctor no&nbsp;longer works for PocketDoctor by&nbsp;this
            time and the user has not canceled the visit before that, then the
            user has no&nbsp;claims against PocketDoctor.
          </li>
          <li>
            If&nbsp;the user did not fill out &laquo;My&nbsp;Record&raquo;
            before meeting with the doctor and after that, there is&nbsp;little
            time left for a&nbsp;dialogue with this doctor because the doctor
            clarified the symptoms and medical history of&nbsp;the user, then
            the user has no&nbsp;complaints to&nbsp;PocketDoctor and doctors and
            the money is&nbsp;not refunded.
          </li>
          <li>
            The user is&nbsp;responsible for the incorrectly specified emergency
            address and other data.
          </li>
          <li>
            If&nbsp;the doctor does not appear within 7&nbsp;minutes,
            we&nbsp;will refund the money if&nbsp;the user notifies&nbsp;us
            about&nbsp;it, provided that this is&nbsp;a&nbsp;valid
            case.&nbsp;3&nbsp;minutes of&nbsp;waiting for the doctor
            is&nbsp;normal, the company does not return the money for this.
          </li>
          <li>
            You agree that the doctor looks at&nbsp;your card and your personal
            PHI data.
          </li>
          <li>
            If&nbsp;the user has an&nbsp;emergency, he&nbsp;calls 911 not
            to&nbsp;make an&nbsp;appointment to&nbsp;see a&nbsp;doctor
            in&nbsp;the PocketDoctor system. If&nbsp;a&nbsp;user signed
            up&nbsp;for a&nbsp;visit with&nbsp;us and it&nbsp;caused severe
            consequences, we&nbsp;are not to&nbsp;blame.
          </li>
          <li>
            If&nbsp;the connection is&nbsp;disconnected for more than
            2&nbsp;minutes during the visit, PocketDoctor does not refund the
            money.
          </li>
          <li>
            If, for example, a&nbsp;patient is&nbsp;in&nbsp;the USA state
            of&nbsp;Florida and he&nbsp;posted the state of&nbsp;New York
            on&nbsp;the &laquo;Talk to&nbsp;your doctor&raquo; page in&nbsp;the
            search engine and then signed up&nbsp;for a&nbsp;visit, and then
            communicated with doctors from other states where doctors cannot
            consult in&nbsp;this state in&nbsp;which the user is&nbsp;currently
            located, then the user is&nbsp;not a&nbsp;doctor and not
            a&nbsp;PocketDoctor company.
          </li>
          <li>
            If&nbsp;the user has moved to&nbsp;another state in&nbsp;the USA,
            he&nbsp;must change his residential address in&nbsp;his settings and
            communicate with doctors from his new state of&nbsp;residence.
          </li>
          <li>
            It&nbsp;is&nbsp;forbidden for users to&nbsp;negotiate
            to&nbsp;communicate with doctors behind the aisles of&nbsp;the
            PocketDoctor product.
          </li>
          <li>
            If&nbsp;the user left the visit with the doctor and did not return
            to&nbsp;the visit within 5&nbsp;minutes, then the money is&nbsp;not
            being stirred up&nbsp;and the doctor stops the visit.
          </li>
          <li>
            The user cannot pay for visits with black currency and stolen money
            or&nbsp;cards in&nbsp;cases where this has happened, the company
            is&nbsp;not responsible for this and does not return the money.
          </li>
          <li>
            If&nbsp;the user does not show up&nbsp;for a&nbsp;meeting with the
            doctor within 10&nbsp;minutes, the doctor ends the meeting and the
            money is&nbsp;not returned to&nbsp;the user.
          </li>
          <li>
            If&nbsp;the doctor does not show up&nbsp;for the visit, the money
            is&nbsp;returned to&nbsp;the patient.
          </li>
          <li>
            We&nbsp;are not responsible for the user&rsquo;s life after
            interacting with the PocketDoctor product.
          </li>
          <li>
            The user cannot have claims against the doctor and sue them. The
            user resolves all issues with PocketDoctor and agrees with this.
          </li>
          <li>
            If&nbsp;the user signed up&nbsp;for a&nbsp;visit but did not manage
            to&nbsp;cancel the meeting in&nbsp;48&nbsp;hours, then we&nbsp;will
            not refund the money if&nbsp;the user managed to&nbsp;cancel the
            visit in&nbsp;48&nbsp;hours, then we&nbsp;will refund the money.
          </li>
          <li>
            To&nbsp;get the money back, the user must have a&nbsp;reasonable
            reason for the refund.
          </li>
          <li>
            If&nbsp;the user did not prepare for the visit and then did not pass
            the device setup test, he&nbsp;is&nbsp;given another 7-10 minutes
            to&nbsp;fix everything if&nbsp;he&nbsp;does not fix communication
            problems, then the doctor goes to&nbsp;the next user and the money
            is&nbsp;not returned.
          </li>
          <li>
            The user cannot enter into a&nbsp;relationship with PocketDoctor
            doctors, the user and the doctor will be&nbsp;blocked for this.
          </li>
          <li>
            If&nbsp;the user did not have time to&nbsp;set up&nbsp;his
            communication device before contacting the doctor and for this
            reason, the connection was lost or&nbsp;the connection was lost many
            times and as&nbsp;a&nbsp;result, it&nbsp;was not possible
            to&nbsp;contact the doctor, then PocketDoctor does not return the
            money.
          </li>
          <li>
            If&nbsp;a&nbsp;doctor does not suit the user or&nbsp;he&nbsp;chose
            the wrong doctor and he&nbsp;did not have time to&nbsp;cancel the
            visit in&nbsp;48&nbsp;hours, then the money will not
            be&nbsp;refunded to&nbsp;the user.
          </li>
          <li>
            The user cannot tell his contact information and address data.
          </li>
          <li>
            The user cannot insult and discriminate against a&nbsp;doctor and
            try to&nbsp;sell him something for this act of&nbsp;lifetime account
            blocking.
          </li>
          <li>
            The user cannot post inappropriate information in&nbsp;the account
            and the &laquo;My&nbsp;Record&raquo; card.
          </li>
          <li>
            The user can challenge all questions to&nbsp;PocketDoctor through
            the Belarusian court.
          </li>
          <li>
            The user must communicate politely and respectfully with each doctor
            and not discriminate against gender, age, height, weight, race,
            appearance, skin color, national origin, disability, caste, criminal
            record, ethnicity, marital status, generation, genetic
            characteristics, marital status, religion, social class, sexual
            orientation, and gender identity, etc.
          </li>
          <li>
            The user cannot entice doctors from our PocketDoctor product
            to&nbsp;personal work or&nbsp;their own companies
            or&nbsp;competitors&rsquo; companies.
          </li>
          <li>
            The doctor may terminate the consultation if&nbsp;the user does not
            behave decently and insults the doctor.
          </li>
        </ol>
      </ContentSection>
    </TextContent>

    {/* For doctors */}
    <Block
      id="terms-medical-experts"
      textAlign="center"
      marginTop={8}
      marginBottom={5}
    >
      <Typography as="h1" type="h1" fontColor="G200">
        Terms of use (for medical experts)
      </Typography>
      <Block textAlign="center" marginTop={0.5}>
        <Typography type="h5" fontWeight="bold">
          Date last modified: December 2, 2021
        </Typography>
      </Block>
    </Block>
    <TextContent>
      <ContentSection>
        <Typography as="h2" type="h2">
          What is this document?
        </Typography>
        <p>
          These Terms of&nbsp;Use (&laquo;Terms&raquo;
          or&nbsp;&laquo;TOU&raquo;) is&nbsp;an&nbsp;agreement between you
          (&laquo;Medical Expert&raquo;) and PocketDoctor, Inc.
          (&laquo;PocketDoctor&raquo;). It&nbsp;describes the rules you agree
          to&nbsp;follow when using our mobile applications and website(s) (the
          &laquo;Apps&raquo;), including when you view or&nbsp;input content
          on&nbsp;or&nbsp;into the Apps.
        </p>
        <p>
          The PocketDoctor Privacy Statement and Cookie Policy govern all
          privacy and data use related to our Apps. These are separate
          documents, but are incorporated into this TOU, and you should read
          them.
        </p>
        <p>
          These Terms of Use apply to your use of PocketDoctor as a Medical
          Expert. Members and patients participating on PocketDoctor are subject
          to different guidelines and terms, referenced{" "}
          <a href="#terms-patients">here</a>. If you are viewing these Terms of
          Use for Medical Experts in error and you are a patient or a member,
          please review and accept the{" "}
          <a href="#terms-patients">Terms of Use for Patients</a>.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Definitions
        </Typography>
        <p>
          To make this document shorter and clearer, we've included some
          definitions:
        </p>
        <ul>
          <li>
            "PocketDoctor" or the "Apps" means{" "}
            <UnifiedLink to={homePage}>https://www.pocketdoctor.us</UnifiedLink>{" "}
            and related web sites and PocketDoctor mobile applications
            (including PocketDoctor for Doctors, and any other Apps we offer).
            PocketDoctor, Inc are located in Delaware.
          </li>
          <li>
            "Content" means text, graphics, images, and any other material
            entered, processed, contained on or accessed through the Apps,
            including Content created, modified, or submitted by Medical
            Experts.
          </li>
          <li>"Services" means services provided through the Apps.</li>
          <li>
            "Premium Services" means paid services provided through the Apps.
          </li>
          <li>
            &laquo;Free services&raquo; means free services provided through the
            app.
          </li>
          <li>
            &laquo;User&raquo; is&nbsp;a&nbsp;user who uses or&nbsp;uses the
            PocketDoctor website or&nbsp;application.
          </li>
          <li>
            &laquo;Medical Expert&raquo; means a&nbsp;medical professional
            or&nbsp;professional authorized to&nbsp;participate
            in&nbsp;or&nbsp;through the PocketDoctor applications
            or&nbsp;websites.
          </li>
          <li>
            &laquo;Doctor&raquo; means a&nbsp;medical professional
            or&nbsp;professional authorized to&nbsp;participate
            in&nbsp;or&nbsp;through the PocketDoctor applications
            or&nbsp;websites.
          </li>
          <li>
            "Virtual Consult" means a consultation between a Medical Expert and
            a patient on the Apps.
          </li>
        </ul>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Introduction
        </Typography>
        <Typography as="h3" type="h3">
          PocketDoctor Services
        </Typography>
        <p>
          PocketDoctor connects individuals with doctors and trusted health
          information. PocketDoctor offers standard (free) and premium (paid)
          services, available through the same Apps. PocketDoctor standard
          services encompass health information and not healthcare. PocketDoctor
          Premium and Free Services may be used for healthcare via virtual
          doctor consults.
        </p>
        <Typography as="h3" type="h3">
          These Terms of Use are an Agreement
        </Typography>
        <p>
          Generally, this agreement governs your use of the Apps. Other terms
          may apply to your use of a specific feature. If there is a conflict
          between this TOU and terms posted for a specific feature, the latter
          terms apply to your use of that feature or part.
        </p>
        <p>
          <strong>
            THESE TERMS OF USE REQUIRE THE USE OF ARBITRATION TO RESOLVE
            DISPUTES AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU IN THE EVENT
            OF A DISPUTE. BY ACCEPTING THESE TERMS, YOU ARE WAIVING THE RIGHT TO
            A TRIAL BY JURY OR TO PARTICIPATE IN ANY CLASS ACTION OR
            REPRESENTATIVE PROCEEDING. SEE ARBITRATION AGREEMENT.
          </strong>
        </p>
        <Typography as="h3" type="h3">
          We May Update this Agreement
        </Typography>
        <p>
          These Terms of Use may change on a going-forward basis at any time
          upon 7 days' notice. Please check these Terms periodically for
          changes. If a change to these Terms materially modifies your rights or
          obligations, we may require that you accept the modified Terms in
          order to continue to use the Service. Material modifications are
          effective upon your acceptance of the modified Terms. Immaterial
          modifications are effective upon publication. If you do not agree to
          all of these Terms of Use, please do not use PocketDoctor.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          PocketDoctor Medical Experts
        </Typography>
        <Typography as="h3" type="h3">
          Independence of PocketDoctor Medical Experts
        </Typography>
        <p>
          PocketDoctor Medical Experts, professionals, and specialists utilizing
          or featured on PocketDoctor are not employees or independent
          contractor service providers of PocketDoctor. Any opinions, advice, or
          information expressed by any such individuals are those of the
          individual and the individual alone, and they do not reflect the
          opinions of PocketDoctor. PocketDoctor does not recommend or endorse
          any specific tests, physicians, products, procedures, opinions, or
          other information that may be mentioned on PocketDoctor or by a
          licensee of PocketDoctor. (Medical Experts on PocketDoctor Premium and
          Free Services are independent contractors of separate professional
          corporations.)
        </p>
        <p>
          The inclusion of Medical Experts, professionals, and specialists on
          PocketDoctor or in any professional directory on PocketDoctor does not
          imply PocketDoctor recommendation or endorsement of such professional
          nor is such information intended as a tool for verifying the
          credentials, qualifications, or abilities of any professional. SUCH
          INFORMATION IS PROVIDED ON AN "AS-IS" BASIS AND POCKETDOCTOR DISCLAIMS
          ALL WARRANTIES, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
          TO THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
          PARTICULAR PURPOSE. POCKETDOCTOR SHALL IN NO EVENT BE LIABLE TO YOU OR
          TO ANYONE FOR ANY DECISION MADE OR ACTION TAKEN BY ANY PARTY
          (INCLUDING, WITHOUT LIMITATION, ANY USER) IN RELIANCE ON INFORMATION
          ABOUT MEDICAL EXPERTS, PROFESSIONALS, AND SPECIALISTS ON POCKETDOCTOR.
          The use of PocketDoctor by any entity or individual to verify the
          credentials of professionals or specialists is prohibited.
        </p>
        <p>
          All opinions and statements expressed by PocketDoctor Medical Experts
          on or through PocketDoctor are solely the individual, independent
          opinions and statements of such individuals and do not reflect the
          opinions of PocketDoctor, its affiliates, or any other organizations
          or institutions to which such PocketDoctor Medical Expert or such
          specialist or professional is affiliated with or provides services
          through.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Your Account and Your Use of PocketDoctor
        </Typography>
        <Typography as="h3" type="h3">
          You are responsible for your account. Keep your password and real name
          private
        </Typography>
        <p>
          Accurate and complete registration information is required to use
          PocketDoctor. You are solely responsible for the security of your
          passwords and for any use of your account, including any access to
          personal information in your account. If you suspect unauthorized use
          of your account, change your password immediately. Allowing any other
          person or entity to use your identity for posting on or using
          PocketDoctor is not permitted.
        </p>
        <p>
          We reserve the right to revoke or deactivate your username and
          password at any time. You may terminate your account and these Terms
          at any time by selecting to deactivate your account in the settings
          page or visiting the customer service help center at{" "}
          <UnifiedLink to={supportPage}>support</UnifiedLink>.
        </p>
        <Typography as="h3" type="h3">
          You must be an adult to use PocketDoctor
        </Typography>
        <p>You must be at least 18 years old to use PocketDoctor.</p>
        <Typography as="h3" type="h3">
          Your use must be Legal and Appropriate
        </Typography>
        <p>
          Your use of PocketDoctor and any Content and Services must comply with
          all applicable federal and state laws, regulations, and ordinances.
          You may not access our networks, computers, or the Content and
          Services in any manner that could damage, disable, overburden, or
          impair them, or interfere with any other person's use and enjoyment.
          You may not attempt to gain unauthorized access to any Content or
          Services, other accounts, computer systems, or networks connected to
          PocketDoctor, the Content, or Services. You may not use any automated
          means (such as a scraper) to access PocketDoctor, the Content, or
          Services. Unauthorized access includes using credentials to access
          PocketDoctor. Any attempt by any individual or entity to solicit login
          information of any other user or PocketDoctor Medical Expert, or to
          access any such account, is an express and direct violation of these
          Terms and of applicable law, including relevant privacy and security
          laws and laws prohibiting unfair or unethical business practices.
        </p>
        <p>
          We maintain guidelines and a code of conduct for both Premium Services
          users and Medical Experts who use PocketDoctor. By using our services,
          including PocketDoctor Premium and Free Services, you agree to abide
          by our Guidelines.
        </p>
        <Typography as="h3" type="h3">
          We'll send you notices and information
        </Typography>
        <p>
          For more details about when and how we can communicate with you,
          please consult our Privacy Statement.
        </p>
        <Typography as="h3" type="h3">
          You agree to arbitration.
        </Typography>
        <p>
          Most concerns or disputes can be resolved quickly by visiting the
          customer service help center at{" "}
          <UnifiedLink to={supportPage}>support</UnifiedLink>. In the unlikely
          event that we are unable to resolve a legitimate legal complaint, you
          agree to resolve those disputes through binding arbitration or small
          claims instead of in courts of general jurisdiction. By using our
          Services, you agree to arbitrate any disputes with us under the terms
          of our Arbitration Agreement.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Content and Services
        </Typography>
        <Typography as="h3" type="h3">
          PocketDoctor is a paid and free Service That Offers Paid Services and
          Free Services
        </Typography>
        <p>
          You can ask health questions for free and get health information
          answers and PocketDoctor tips. PocketDoctor also provides additional
          services and Content that can be accessed for a fee and free of
          charge, such as virtual consultations. PocketDoctor also offers a
          PocketDoctor Premium Plan and a Free Plan. We will never ask you for
          your credit card or other payment information unless you indicate that
          you would like to use a paid service.
        </p>
        <Typography as="h3" type="h3">
          PocketDoctor is a paid and free Service That Offers Paid Services and
          Free Services
        </Typography>
        <p>
          PocketDoctor is a service for individuals to use to support their
          personal health decisions. You may use PocketDoctor for personal, but
          not for commercial purposes in accordance with all applicable federal,
          state and local laws. The Content is licensed only for the personal,
          household, and educational use of a single individual. No commercial
          use or commercial redistribution of any Content is permitted. Licensed
          U.S. doctors and other qualified medical experts may apply to
          participate on PocketDoctor for educational purposes as Medical
          Experts, or to provide Virtual Consults. Companies or other
          organizations may not become registered members or use our Apps
          through individual members, but we do offer enterprise services for
          organizations.
        </p>
        <Typography as="h3" type="h3">
          PocketDoctor does not guarantee the accuracy of Third Party Content
        </Typography>
        <p>
          PocketDoctor has no editorial control over or responsibility for
          Content provided by third parties. Any opinions, statements, products,
          services, or other information expressed or made available by third
          parties (including Medical Experts) or users on PocketDoctor are those
          of such third parties or users. PocketDoctor does not have any
          obligation to monitor such third party Content. We make no
          representations about the accuracy or reliability of any opinion,
          statement, or other information provided by any third party, and we do
          not represent or warrant that your use of the Content displayed or
          referenced on PocketDoctor will not infringe the rights of third
          parties not owned by or affiliated with PocketDoctor.
        </p>

        <Typography as="h3" type="h3">
          PocketDoctor may not be used for illegal purposes
        </Typography>
        <p>
          The Content and Services may not be used for any illegal purpose. You
          may not access our networks, computers, or the Content and Services in
          any manner that could damage, disable, overburden, or impair them, or
          interfere with any other person's use and enjoyment. You may not
          attempt to gain unauthorized access to any Content or Services, other
          accounts, computer systems, or networks connected to PocketDoctor, the
          Content, or Services. You may not use any automated means (such as a
          scraper) to access PocketDoctor, the Content, or Services for any
          purpose. Such unauthorized access includes, but is not limited to,
          using another person's login credentials to access PocketDoctor. Any
          attempt by any individual or entity to solicit login information of
          any other user or PocketDoctor Medical Expert or to access any such
          account is an express and direct violation of these Terms of Use and
          of applicable law, including relevant privacy and security laws and
          laws prohibiting unfair or unethical business practices.
        </p>

        <Typography as="h3" type="h3">
          PocketDoctor does not make recommendations or endorsements
        </Typography>
        <p>
          We do not recommend or endorse any specific Content, Services, tests,
          doctors, products, procedures, opinions, or other information that may
          be mentioned on PocketDoctor. Reliance on PocketDoctor Content or
          Services is solely at your own risk. Some Content and Services,
          including posts by Medical Experts, may be or have been provided by
          third parties directly and are not reviewed or certified by
          PocketDoctor. We cannot guarantee that the Content and Services will
          help you achieve any specific goals or results. Content regarding
          dietary supplements or other treatments or regimens has not been
          evaluated by the Food and Drug Administration and is not intended to
          diagnose, treat, cure, or prevent any disease.
        </p>
        <Typography as="h3" type="h3">
          We are not responsible for anything outside of PocketDoctor
        </Typography>
        <p>
          The Apps may contain links to other apps, web sites, information,
          software, data, or other content, online or offline ("External Content
          and Services"). Such External Content and Services and related
          information are outside of our control. We do not control, endorse,
          verify the truth or accuracy of, or review content outside of
          PocketDoctor, and we are not responsible for such content. We do not
          warrant, nor are we in any way responsible for, information, software,
          data, or privacy policies related or pertaining to External Content
          and Services.
        </p>
        <Typography as="h3" type="h3">
          PocketDoctor may communicate with you via email, SMS, text and mobile
          push notification
        </Typography>
        <p>
          When you install our app on your mobile device, you may agree to
          receive push notifications, which are messages an app sends you on
          your mobile device when the app is not on. You can turn off
          notifications by visiting your mobile device's "settings" page.
        </p>
        <p>
          We may send you emails concerning our products and services, as well
          as those of third parties. You may opt out of promotional emails by
          following the unsubscribe instructions in the promotional email
          itself.
        </p>
        <p>
          PocketDoctor and those acting on our behalf may send you text (SMS)
          messages to the phone number you provide us. These messages may
          include operational messages about your use of the Services, as well
          as marketing messages. If you opt out, you may continue to receive
          text messages for a short period while PocketDoctor processes your
          request, and you may also receive text messages confirming the receipt
          of your opt-out request. Opting out of receiving operational text
          messages may impact the functionality that the Service provides to
          you. Text messages may be sent using an automatic telephone dialing
          system. Your agreement to receive text messages is not a condition of
          any purchase or use of the Service. Standard messaging, data, and
          other fees may be charged by your carrier.
        </p>
        <p>
          Your carrier may prohibit or restrict certain mobile features, and
          certain mobile features may be incompatible with your carrier or
          mobile device. As applicable, instructions regarding how to opt-out of
          mobile features will be disclosed in connection with such features
          (instructions typically require you to text a keyword, such as "STOP,"
          "CANCEL," "UNSUBSCRIBE," to the applicable shortcode for the mobile
          feature, or to change your profile settings inside the Apps).
        </p>
        <p>
          You agree to notify PocketDoctor of any changes to your mobile number
          and update your account(s) on the PocketDoctor Platforms. You also
          understand and agree that by receiving communications you will be
          charged by your wireless or internet provider and that such emails,
          SMS, or mobile telephone notification may be generated by automated
          systems.
        </p>
        <Typography as="h3" type="h3">
          You agree that we may send you limited personal information by email,
          SMS, text, and mobile push notification
        </Typography>
        <p>
          When you use action-oriented features on PocketDoctor and PocketDoctor
          Premium and Free Services (such as subscribing to a health checklist
          or participating in a Virtual Consult), you agree that PocketDoctor,
          Inc. may send you automated content via email, mobile telephone, or
          other contact information provided by you in your account settings.
          This content may contain protected health information under HIPAA,
          including content related to conditions, treatments, and medications.
          You understand and agree that by using these features, you are
          expressly opting into receiving your own protected health information
          by email, SMS/text, or mobile push notifications. These communications
          from the Apps are not encrypted. Although unlikely, it is possible for
          these communications to be intercepted or accessed without your
          authorization. By using the Apps, you release PocketDoctor from any
          liability arising from or related to any such interception or
          unauthorized access.
        </p>
        <Typography as="h3" type="h3">
          Mandatory Removal of Content and Services
        </Typography>
        <p>
          If you violate any of these Terms of Use, your permission to use the
          Content and Services automatically terminates and you must immediately
          destroy any copies you have made of any portion of the Content. (For
          more information, see Termination.)
        </p>
      </ContentSection>

      <ContentSection>
        <Typography as="h2" type="h2">
          Use of Your Information
        </Typography>
        <p>
          We only use data you share with PocketDoctor as set forth in the
          PocketDoctor Privacy Statement, your account settings, and in
          accordance with applicable law.
        </p>

        <Typography as="h3" type="h3">
          We cannot control external communications (including email, SMS, and
          notifications)
        </Typography>
        <p>
          Email, short message services (SMS), text message communications, and
          mobile push notifications from the Apps are not encrypted. You can opt
          out of receiving email, SMS/text messages, and mobile push
          notifications. Although unlikely, it is possible for these
          communications to be intercepted or accessed without your
          authorization. By using the Apps, you release PocketDoctor from any
          liability arising from or related to any such interception or
          unauthorized access.
        </p>
      </ContentSection>

      <ContentSection>
        <Typography as="h2" type="h2">
          Advertising
        </Typography>
        <Typography as="h3" type="h3">
          We Do Not Advertise
        </Typography>
        <p>There is no paid advertising on PocketDoctor.</p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Property Rights
        </Typography>
        <Typography as="h3" type="h3">
          PocketDoctor owns or has rights to the Content and Services
        </Typography>
        <p>
          When you use the Apps, you do so under a license from us. Subject to
          your complete and ongoing compliance with these Terms, PocketDoctor
          grants you, solely for your personal, non-commercial use, a limited,
          non-exclusive, non-transferable, non-sublicensable, revocable license
          to: (a) install and use one object code copy of our mobile App
          obtained from a legitimate marketplace on a mobile device that you own
          or control; and (b) access and use the Content and Services. This
          means that you may not use the Apps for any commercial purpose, that
          we can take away your right to use the Apps, and that you cannot give
          this license away to someone else. All right, title, and interest in
          and to the Apps, Services, and the Content, together with all related
          intellectual property rights are the property of PocketDoctor or our
          affiliates, excluding your rights under applicable law to any
          information or Content related to Virtual Consults on PocketDoctor
          Premium and Free Services. Rights retained by other parties in the
          Content are their respective rights. PocketDoctor reserves all rights
          to the Content not granted expressly in these Terms.
        </p>
        <Typography as="h3" type="h3">
          You agree not to infringe our Copyrights, Trademarks, Trade Secrets,
          Patents, or other intellectual property rights
        </Typography>
        <p>
          PocketDoctor and other related marks are registered trademarks of
          PocketDoctor, Inc. Any other trademark, brand, or content on
          PocketDoctor that is not the property of PocketDoctor is the property
          of its respective owner. You agree not to violate, or encourage others
          to violate, any right of a third party, including by infringing or
          misappropriating any third party intellectual property rights. You may
          not reproduce, create derivative works of, distribute, publicly
          display the Content (or any portion of it) without our prior written
          consent. However, you may use App features to repost Content or
          portions of Content, including through other third party applications
          and mediums (such as Facebook or Twitter), so long as you do not
          modify that Content or the functionality of those features. This
          re-posting right does not create any additional rights in such
          Content. Additionally, you may not use any metatags or any other
          "hidden text" utilizing the name "PocketDoctor" without our prior
          written permission.
        </p>
        <Typography as="h3" type="h3">
          You agree not to access, attempt to access, or use our data without
          our permission
        </Typography>
        <p>
          Except and solely to the extent such a restriction is impermissible
          under applicable law, you may not: (a) reproduce, distribute, publicly
          display, or publicly perform the Apps, Services, or Content; (b) make
          modifications to the Apps, Services, or Content; or (c) interfere with
          or circumvent any feature of the Apps, including any security or
          access control mechanism. If you are prohibited under applicable law
          from using the Service, you may not use it. You agree not to access,
          attempt to access, request access not authorized by the Apps or use
          any App Content or data without our permission. This means that you
          agree not to transmit, download, upload, post, sell, rent, license,
          transfer, disclose, mirror, frame, reverse engineer, decompile,
          disassemble, or use any aspect of the Apps or any Content, in whole or
          in part, in any form or by any means.
        </p>
        <Typography as="h3" type="h3">
          Contact us if you believe materials on our Apps infringe your
          copyright
        </Typography>
        <p>
          If you believe any materials accessible on or from PocketDoctor
          infringe your valid and enforceable copyright, you may request removal
          of (or access to) those materials (or access thereto) from us by
          contacting us and providing us with information pursuant to our DMCA
          Removal Procedure.
        </p>
      </ContentSection>

      <ContentSection>
        <Typography as="h2" type="h2">
          Submissions
        </Typography>
        <Typography as="h3" type="h3">
          You agree to abide by our Submission Guidelines
        </Typography>
        <p>
          You agree that you will not upload or transmit any communications or
          content of any type that infringes or violates any rights of any
          party. The personal information you submit to PocketDoctor is governed
          by the Privacy Statement (the terms of which govern in the event of
          any inconsistency with this TOU). You agree that submissions will
          comply with PocketDoctor Guidelines, and if you make any posting to
          PocketDoctor, you agree to Medical Expert Guidelines.
        </p>
        <Typography as="h3" type="h3">
          You give us rights in what you submit
        </Typography>
        <p>
          Don't submit anything to us if you don't want to give us rights to it.
          If you wish to keep any content, business information, ideas, concepts
          or inventions private or proprietary, do not submit them on, through,
          or to PocketDoctor, by email or otherwise. With the exception of any
          personal data or information you submit (which shall be maintained in
          accordance with our Privacy Statement) and other information governed
          by the Health Insurance Portability and Accountability Act of 1996,
          Public Law 104-191 and the rules and regulations promulgated
          thereunder (as amended to date, "HIPAA"), or other applicable laws, if
          you make any submissions (by email or otherwise) on, to or through
          PocketDoctor, including but not limited to media (including
          photographs), data, questions, comments, suggestions, business
          information, ideas, concepts or inventions (collectively
          "Submissions"), you make such submission without any restrictions or
          expectation of compensation, privacy, or confidentiality. You agree
          that your Submissions may be used by us without restriction for any
          purpose whatsoever. By making any a Submission, you grant PocketDoctor
          a worldwide, non-exclusive, irrevocable, royalty-free, fully paid
          right and license (with the right to sublicense) to host, store,
          transfer, display, perform, reproduce, modify for the purpose of
          formatting for display, and distribute your Submissions, in whole or
          in part, in any media formats and through any media channels now known
          or hereafter developed. This means PocketDoctor has the complete right
          to freely use, create derivative works from and modify, such
          Submissions in any way, commercial or otherwise (including developing
          and marketing products or features using such information), and for
          any purpose whatsoever and without limitation. PocketDoctor may
          sublicense its rights.
        </p>
        <p>
          By making any a Submission, you further agree to indemnify
          PocketDoctor and its affiliates, directors, officers, Medical Experts,
          and employees, and to hold them harmless from any and all claims and
          expenses, including attorneys' fees, arising from your Submissions, or
          your failure to comply with these Terms.
        </p>
        <p>
          PocketDoctor does not accept unsolicited recruiter or similar
          submissions. Any candidate submissions by a recruiter or other third
          party without a valid and signed recruiting agreement in place with
          PocketDoctor prior to such submission will not be subject to any
          recruiter or similar fees.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          PocketDoctor Medical Experts
        </Typography>
        <Typography as="h3" type="h3">
          About PocketDoctor Medical Experts
        </Typography>
        <p>
          Medical Experts on the Apps include respected doctors throughout the
          United States, licensed doctors may participate as Medical Experts on
          PocketDoctor (including PocketDoctor Premium and Free Services).
          Doctors whose licenses are or become suspended or revoked, for any
          reason, are not permitted and agree not to participate in any way,
          including but not limited to submitting Content to PocketDoctor as a
          Medical Expert on PocketDoctor or participating on PocketDoctor
          Premium and Free Services. Supplemental Terms of Use apply to Medical
          Experts who participate on PocketDoctor (including those who provide
          Premium and Free Services).
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Termination
        </Typography>
        <Typography as="h3" type="h3">
          All of our members are required to honor this Agreement
        </Typography>
        <p>
          Your permission to use the Apps, Content, and Services ends
          immediately if you violate any of the terms of this Agreement. We may
          place limits on, modify, or terminate your right to access and use
          Apps and the Services and/or Content at any time for any reason or no
          reason, with or without notice. This suspension or termination may
          delete information, files, and other previously available Content. We
          also reserve the right to modify or discontinue the Services at any
          time (including by limiting or discontinuing certain features of the
          Apps), temporarily or permanently, without notice to you. We will have
          no liability whatsoever on account of any change to the Services or
          any suspension or termination of your access to or use of the
          Services.
        </p>
        <p>
          You may terminate your account at any time by visiting the customer
          service help center at{" "}
          <UnifiedLink to={supportPage}>support</UnifiedLink>. If you terminate
          your account, you remain obligated to pay all outstanding fees, if
          any, incurred prior to termination relating to your use of the
          Services.
        </p>
        <Typography as="h3" type="h3">
          Effect of Termination
        </Typography>
        <p>
          Upon termination of these Terms: (a) your license rights will
          terminate and you must immediately cease all use of the Service; (b)
          you will no longer be authorized to access your account or the
          Service; (c) you must pay PocketDoctor any unpaid amount that was due
          prior to termination; and (d) all payment obligations accrued prior to
          termination, this section, and sections regarding Submissions,
          ownership, indemnities, disclaimer of warranties, limitations on
          liability, and the Arbitration Agreement will survive.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Indemnification &amp; Exclusions and Limitations
        </Typography>
        <Typography as="h3" type="h3">
          Exclusion of Warranties
        </Typography>
        <p>
          THE APPS AND THE CONTENT AND SERVICES ARE PROVIDED "AS IS" AND ON AN
          "AS AVAILABLE" BASIS WITHOUT WARRANTY OR CONDITION OF ANY KIND, EITHER
          EXPRESS OR IMPLIED. NEITHER WE, POCKETDOCTOR MEDICAL EXPERTS, NOR ANY
          OF OUR LICENSORS MAKE ANY EXPRESS WARRANTIES, AND WE AND EACH OF THEM
          DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING THE IMPLIED WARRANTIES OF
          ACCURACY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT. NEITHER WE, POCKETDOCTOR MEDICAL EXPERTS, NOR ANY OF
          OUR LICENSORS MAKE ANY WARRANTY THAT CONTENT OR SERVICES SATISFY
          GOVERNMENT REGULATIONS, INCLUDING THOSE REQUIRING DISCLOSURE OF
          INFORMATION ON PRESCRIPTION DRUG PRODUCTS. POCKETDOCTOR AND THE
          CONTENT AND SERVICES WERE DEVELOPED FOR USE IN THE UNITED STATES, AND
          NEITHER WE NOR ANY OF OUR LICENSORS MAKE ANY REPRESENTATION CONCERNING
          POCKETDOCTOR AND THE CONTENT OR SERVICES WHEN USED IN ANY OTHER
          COUNTRY.
        </p>
        <p>
          NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
          FROM THE SERVICE OR POCKETDOCTOR, OR ANY MATERIALS OR CONTENT
          AVAILABLE THROUGH THE APPS, WILL CREATE ANY WARRANTY THAT IS NOT
          EXPRESSLY STATED IN THESE TERMS.
        </p>
        <p>
          Specifically, and without limiting the foregoing, we, our licensors,
          and our suppliers, make no representations or warranties about: (i)
          the accuracy, reliability, completeness, currentness, or timeliness of
          the Content provided on or through the use of the App; or (ii)
          regulations requiring disclosure of information on prescription drug
          products or the approval or compliance of any software tools with
          PocketDoctor. Any location data accessed via the Apps may be
          inaccurate or incomplete and any use of such data is at your own risk.
        </p>
        <Typography as="h3" type="h3">
          Limitation of Liability of PocketDoctor and Third Party Beneficiaries
        </Typography>
        <p>
          WE ARE NOT RESPONSIBLE FOR ANY DAMAGE THAT MAY RESULT FROM THE SERVICE
          AND YOUR DEALING WITH ANY OTHER USER, INCLUDING MEDICAL EXPERTS. YOU
          UNDERSTAND AND AGREE THAT YOU USE ANY PORTION OF THE SERVICE AT YOUR
          OWN DISCRETION AND RISK, AND THAT WE ARE NOT RESPONSIBLE FOR ANY
          DAMAGE TO YOUR PROPERTY OR ANY LOSS OF DATA, INCLUDING SUBMISSIONS.
        </p>
        <p>
          To the fullest extent permitted by law, in no event will PocketDoctor,
          its licensors, suppliers, or any third parties mentioned on
          PocketDoctor be liable for any personal injury, including death,
          attributable to or caused by your use or misuse of PocketDoctor or
          Content (including Medical Expert Content). Any claims arising in
          connection with your use of the Apps, Services, or any Content must be
          brought within one (1) year of the first date of the event giving rise
          to such action. Remedies under these Terms are exclusive and are
          limited to those expressly provided for in these Terms. You expressly
          agree that Medical Experts are third party beneficiaries under these
          Terms and may enforce the rights hereunder, including Limitation of
          Liability rights.
        </p>
        <p>
          NEITHER WE, POCKETDOCTOR MEDICAL EXPERTS, NOR ANY OF OUR LICENSORS MAY
          BE HELD LIABLE UNDER THIS AGREEMENT FOR ANY DAMAGES OTHER THAN DIRECT
          DAMAGES, EVEN IF THE PARTY KNOWS OR SHOULD KNOW THAT OTHER DAMAGES ARE
          POSSIBLE, OR THAT DIRECT DAMAGES ARE NOT A SATISFACTORY REMEDY. THESE
          LIMITATIONS APPLY TO YOU ONLY TO THE EXTENT THEY ARE LAWFUL IN YOUR
          JURISDICTION. EXCEPT AS PROVIDED IN THE ARBITRATION AGREEMENT, NEITHER
          US, THE MEDICAL EXPERTS ON POCKETDOCTOR, NOR ANY OF OUR LICENSORS MAY
          BE HELD LIABLE UNDER THIS AGREEMENT TO ANY USER FOR ANY CLAIMS (IN
          AGGREGATE OVER ALL TIME) FOR MORE THAN THE GREATER OF: (I) FEES PAID
          BY THE USER TO POCKETDOCTOR OVER THE COURSE OF THE SIX (6) MONTHS
          IMMEDIATELY PRIOR TO THE INCIDENT GIVING TO THE ALLEGED DAMAGES; OR
          (II) FOR USERS WHO HAVE NOT SO USED PAID PREMIUM SERVICES, TO ONE
          HUNDRED DOLLARS ($100).
        </p>
        <Typography as="h3" type="h3">
          Limitations of Liability and Indemnity for Expert Content
        </Typography>
        <p>
          Informational content on the Apps that is created, modified,
          submitted, or validated by Medical Experts or other healthcare experts
          (collectively, "Expert Content") is subject to the following
          additional terms and conditions and agreements by You.
        </p>
        <p>
          Expert Content is for informational purposes only. For medical advice,
          treatment, or diagnosis, see your personal doctor or healthcare
          provider. PocketDoctor is not responsible for Expert Content. The
          authors or posters of Expert Content ("Posters") are solely
          responsible for such content. No representations, warranties, or
          guarantees of any kind are made regarding the Expert Content. Under no
          circumstances shall any party be liable (to you or to any other
          person) for any damages or harm (of any type or under any legal
          theory) resulting from or related to the Expert Content. No party
          shall have any liability for: (a) any loss or injury caused, in whole
          or in part, by a Poster's actions, omissions, or negligence, in
          procuring, compiling, or delivering information within or through
          Expert Content; (b) any errors, omissions, or inaccuracies in Expert
          Content (regardless of cause), or delays or interruptions in delivery
          of such information; or (c) any decision made or action taken or not
          taken in reliance upon such information. You agree to indemnify and
          hold the Poster (and the Poster's employer and/or sponsor) harmless
          from any claim or demand, including attorneys' fees, made by any third
          party as a result of any violation of law that occurs by you through
          your use of Expert Content or this Site and/or anything you do using
          Expert Content, our Apps and/or the information contained therein.
        </p>
        <p>
          IF YOU ARE DISSATISFIED WITH ANY OF THE CONTENT OR MATERIALS ON OUR
          SITE, OR ANY SERVICES OR INFORMATION AVAILABLE THROUGH THE SITE, YOUR
          SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE ACCESSING AND USING OUR
          SITE. THIS LIMITATION APPLIES EVEN IF YOUR REMEDIES UNDER THIS
          AGREEMENT FAIL OF THEIR ESSENTIAL PURPOSE.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Licensed Content
        </Typography>
        <Typography as="h3" type="h3">
          Certain Content may be licensed from third-parties
        </Typography>
        <p>
          The licenses for some of this Content may contain additional terms.
          When such Content licenses contain additional terms, we will make
          these terms available to you on those pages, in the Terms of Use, or
          in the Additional Information section of our Apps (which is
          incorporated herein by reference).
        </p>

        <Typography as="h3" type="h3">
          Foreign Languages
        </Typography>
        <p>
          Where PocketDoctor Content or Services (including these Terms) are
          translated into languages other than English, all such translations
          are for the convenience of our users only, and PocketDoctor is not
          responsible or liable in the event of any translation inaccuracy. The
          English-language version of these Terms shall control and apply In the
          event of any conflict with content or translation. You understand that
          Content, including but not limited to questions and answers, may not
          have the same meaning in translation, and that treatments (including
          but not limited to medication names) and other information may differ
          from country to country and in different languages and may not be
          available in all places. Additionally, you understand and agree that
          Virtual Consults with Medical Experts may not be available in
          languages other than English, and you agree not to conduct a
          consultation in a language in which you are not proficient without the
          use of a professional translator. If you choose to use a translator in
          connection with a Virtual Consult, you understand that PocketDoctor is
          not liable for any errors or omissions in translation.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          General Legal Terms
        </Typography>
        <Typography as="h3" type="h3">
          General Terms
        </Typography>
        <p>
          These Terms, and the other agreements referenced in it (like our
          Privacy Statement and Cookie Policy), are the entire agreement between
          you and us relating to the PocketDoctor Apps. Your use of the Services
          is subject to all additional terms, policies, rules, or guidelines
          applicable to the Service or certain features of the Service that we
          may post on or link to from the Service (the "Additional Terms"). All
          Additional Terms are incorporated by this reference into, and made a
          part of these Terms. These Terms replace any prior agreements unless
          such prior or subsequent agreement explicitly provides otherwise and
          specifically references these Terms. If there is any conflict between
          these Terms and a mutually signed written agreement between you and us
          related to PocketDoctor, the signed written agreement will control.
          You may not assign or transfer these Terms or your rights under these
          Terms, in whole or in part, by operation of law or otherwise, without
          our prior written consent. We may assign these Terms at any time
          without notice or consent.
        </p>
        <p>
          If we choose not to enforce any provision of these Terms, we retain
          the right to enforce it in the future. This means that the failure to
          enforce any provision of these Terms does not constitute a waiver of
          that provision. If any provision in these Terms is found to be
          unenforceable, that provision and any related provisions will be
          interpreted to best accomplish the unenforceable provision's essential
          purpose.
        </p>
        <p>
          This agreement is governed by Delaware law, excluding Delaware
          choice-of-law rules. THE EXCLUSIVE VENUE FOR ANY DISPUTE RELATING TO
          THIS AGREEMENT IS DELAWARE. YOU AND US CONSENT TO THE PERSONAL
          JURISDICTION OF THESE COURTS. Nothing in this agreement limits either
          party's ability to seek equitable relief.
        </p>
        <p>
          IF YOU ARE DISSATISFIED WITH ANY OF THE CONTENT ON OUR APPS, OR ANY
          SERVICES OR INFORMATION AVAILABLE ON OR THROUGH THE APPS, YOUR SOLE
          AND EXCLUSIVE REMEDY IS TO DISCONTINUE ACCESSING AND USING OUR APPS.
          THIS LIMITATION APPLIES EVEN IF YOUR REMEDIES UNDER THIS AGREEMENT
          FAIL THEIR ESSENTIAL PURPOSE.
        </p>
        <p>
          The Apps and Services are offered by PocketDoctor, Inc. located at
          16192 Coastal Highway, Lewes, Delaware 19958, County of Sussex. You
          may contact us by sending correspondence to that address or by
          visiting the customer service help center at{" "}
          <UnifiedLink to={supportPage}>support</UnifiedLink>.
        </p>
        <p>
          We are under no obligation to provide support for the Service. In
          instances where we may offer support, the support will be subject to
          published policies.
        </p>
        <p>
          Notice Regarding Apple. This section only applies to the extent you
          are using our mobile application on an iOS device. You acknowledge
          that these Terms are between you and PocketDoctor only, not with Apple
          Inc. ("Apple"), and Apple is not responsible for the App or the
          content thereof. Apple has no obligation to furnish any maintenance
          and support services with respect to the App. If the App fails to
          conform to any applicable warranty, you may notify Apple and Apple
          will refund any applicable purchase price for the mobile application
          to you; and, to the maximum extent permitted by applicable law, Apple
          has no other warranty obligation with respect to the App. Apple is not
          responsible for addressing any claims by you or any third party
          relating to the App or your possession and/or use of the App,
          including: (a) product liability claims; (b) any claim that the App
          fails to conform to any applicable legal or regulatory requirement; or
          (c) claims arising under consumer protection or similar legislation.
          Apple is not responsible for the investigation, defense, settlement
          and discharge of any third party claim that the App and/or your
          possession and use of the App infringe a third party's intellectual
          property rights. You agree to comply with any applicable third party
          terms when using the App. Apple and Apple's subsidiaries are third
          party beneficiaries of these Terms, and upon your acceptance of these
          Terms, Apple will have the right (and will be deemed to have accepted
          the right) to enforce these Terms against you as a third party
          beneficiary of these Terms. You hereby represent and warrant that: (i)
          you are not located in a country that is subject to a U.S. Government
          embargo, or that has been designated by the U.S. Government as a
          "terrorist supporting" country; and (ii) you are not listed on any
          U.S. Government list of prohibited or restricted parties.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Medical Expert Guidelines
        </Typography>
        <p>
          By participating in PocketDoctor or PocketDoctor Premium and Free
          Services you agree to abide by the following guidelines.
        </p>
        <Typography as="h3" type="h3">
          General Guidelines
        </Typography>
        <ul>
          <li>
            <strong> Respect.</strong> You agree to engage with Medical Experts
            in a respectful manner and to refrain from inappropriate language
            and behavior.
          </li>
          <li>
            <strong> Appropriate Use.</strong> You agree to only use
            PocketDoctor and PocketDoctor Premium and Free services in a manner
            that is not: unlawful, threatening, harassing, abusive, defamatory,
            slanderous, libelous, harmful to minors, vulgar, gratuitously
            violent, obscene, pornographic, indecent, lewd, invasive of
            another's privacy, or racially, ethnically or otherwise offensive,
            hateful, or abusive.
          </li>
        </ul>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Guidelines for Posting Content (Including Answers) (Non-Premium
          Services)
        </Typography>
        <ul>
          <li>
            <strong> Informational Answers Only.</strong> Only informational
            answers for educational purposes are allowed. No personal,
            individualized health answers are permitted.
          </li>
          <li>
            <strong> No Obligation to Answer Questions. </strong>You have no
            obligation to answer any submitted question.
          </li>
          <li>
            <strong> Only Make Useful Submissions.</strong> Make Expert
            Submissions only when you reasonably believe that they are likely to
            be useful and helpful to PocketDoctor members.
          </li>
          <li>
            <strong> Only Agree when you Agree.</strong> Agree with Expert
            Submissions only when you have fully reviewed and actually agree
            with the content, and do not agree with your own Submissions.
          </li>
          <li>
            <strong> Avoid Revealing Private Information.</strong> Help us keep
            the personal information of our members confidential. Please don't
            refer to members in or on Content in public areas by their real
            names, even if this information has been disclosed to you.
          </li>
          <li>
            <strong> Avoid Offending Others.</strong> Responses should always be
            appropriate. Brief, casual, and friendly responses are the most
            useful. Help PocketDoctor remain a trusted resource, by avoiding
            potentially offensive content whenever possible.
          </li>
          <li>
            <strong> Avoid Suggesting a Physician-Patient Relationship.</strong>{" "}
            Share your professional, clinical expertise though answers that are
            evidence-based and consistent with accepted standards in the medical
            community, but refrain from using language that suggests an answer
            is provided for a single member or a duty of care and a
            physician-patient relationship.
          </li>
          <li>
            <strong> Avoid Commercial Responses. </strong>Using answers as a
            vehicle to promote a product or service is prohibited. You are
            allowed to link to external websites that are both appropriate and
            related to your responses, but answers on PocketDoctor should be
            complete and should not be used for primarily promotional purposes.
            Please do not plagiarize, and cite sources where applicable.
          </li>
          <li>
            <strong>
              {" "}
              Avoid Advertising or Specifically Soliciting Patients.
            </strong>{" "}
            Users on PocketDoctor can find your professional contact information
            through your virtual practice, and can contact you for an
            appointment. However, please avoid violating medical board rules
            regarding advertising and patient solicitation.
          </li>
          <li>
            <strong> Avoid Disclosing Confidential Material.</strong> Be careful
            not to discuss privileged or confidential details of past or current
            patients, or accidentally revealing confidential information in an
            indirect way that could be traced back to specific patients.
          </li>
          <li>
            <strong> Avoid Violating Board Rules.</strong> Avoid other actions
            that violate board or other rules that apply to you.
          </li>
          <li>
            <strong> Rules Governing Other Submissions.</strong> All other
            information, including suggestions, feedback, ideas, concepts and
            any other materials you disclose or offer to us on or in connection
            with PocketDoctor or any Content, are governed by the Terms of Use.
            This means that these submissions are made without any restrictions
            and without any expectation of confidentiality or compensation. You
            agree not to assert any property right or moral right of any kind
            with respect to any such submissions. You agree to our standard
            terms governing media submissions, except for the prohibition
            against uploading a facial portrait image of yourself for your
            profile picture. You agree to your likeness, photograph, name,
            biographical information and any and all other information appearing
            on the Apps.
          </li>
        </ul>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Guidelines Specific to Premium and Free Services and Virtual Consults
        </Typography>
        <ul>
          <li>
            <strong> Application Only.</strong> Participation as a Medical
            Expert in PocketDoctor Premium and Free Services is subject to a
            separate application.
          </li>
          <li>
            <strong> Additional Contractual Terms. </strong>The terms of any
            related contracts or agreements entered into in connection with
            providing PocketDoctor Premium and Free Services apply to the
            provision of such services.
          </li>
          <li>
            <strong> Care Limitations. </strong>You agree to notify a patient
            whenever in-person care is recommended, including when a user
            appears to be repeatedly using or overusing Virtual Consults.
          </li>
          <li>
            <strong> Prescriptions and Lab Tests.</strong> Prescriptions and lab
            tests, where available, are provided at the sole discretion of the
            consulting Medical Expert. Patients who are located outside the U.S.
            may not receive lab orders and may receive prescription
            recommendations only.
          </li>
          <li>
            <strong> Prescriptions and Lab Tests Limitations.</strong> The
            following medication categories cannot be prescribed or recommended
            in Virtual Consults on PocketDoctor: DEA category I-IV medications,
            lifestyle medications (such as weight loss or hair loss medication),
            and U.S.-state regulated medications. Lab tests are currently
            ordered using Quest Diagnostics, and it may not be possible to order
            all of Quest Diagnostics lab tests (if any) in all U.S. states.
          </li>
          <li>
            <strong> Duration of Treatment.</strong> It is up to you to
            determine the appropriate duration of any treatment, if any, made
            available through a Virtual Consult.
          </li>
          <li>
            <strong> Appearance.</strong> You agree to be appropriately and
            professionally dressed and to be in an environment that is
            appropriate for the delivery of virtual care.
          </li>
        </ul>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          PocketDoctor Arbitration Agreement
        </Typography>
        <Typography as="h3" type="h3">
          Summary
        </Typography>
        <p>
          PocketDoctor Terms require the use of arbitration to resolve disputes
          and also limits the remedies available to you in the event of a
          dispute. Most concerns can be resolved quickly by visiting the
          customer service help center at{" "}
          <UnifiedLink to={supportPage}>support</UnifiedLink>. In the unlikely
          event that we are unable to resolve a legitimate legal complaint, we
          each agree to resolve those disputes through binding arbitration or
          small claims instead of in courts of general jurisdiction. Arbitration
          is more informal than a lawsuit in court. Arbitration uses a neutral
          arbitrator instead of a judge or jury, allows for more limited
          discovery than in court, and is subject to very limited review by
          courts. Arbitrators can award the same damages and relief that a court
          can award. Any arbitration under this Agreement will take place on an
          individual basis; class arbitrations and class actions are not
          permitted. We request that you work with us in good faith to resolve
          any disputes for 30 days after notifying us of such issues before
          filing arbitration. You understand that any award available under
          arbitration is subject to the limitations in the PocketDoctor TOUs.
          YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND
          POCKETDOCTOR ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO
          PARTICIPATE IN A CLASS ACTION.
        </p>
        <Typography as="h3" type="h3">
          Agreement
        </Typography>
        <p>
          First things first: let's try to sort it out. We want to address your
          concerns without a formal arbitration or case. Before filing a claim
          against PocketDoctor, you agree to make a good faith effort to try to
          resolve the dispute informally by contacting{" "}
          <a href="mailto:info@pocketdoctor.us">info@pocketdoctor.us</a> and
          responding promptly to any related communications. We'll try to
          resolve the dispute by contacting you via email. If a dispute is not
          resolved within 30 days of submission, you or PocketDoctor may bring a
          formal proceeding.
        </p>
        <p>
          This Arbitration Agreement ("Arbitration Agreement") is a condition of
          the Terms of Use of PocketDoctor and the Apps. The effective date of
          this agreement is April 1, 2021 (the "Effective Date").
        </p>
        <Typography as="h3" type="h3">
          General
        </Typography>
        <p>
          If you reside in the United States or are otherwise subject to the US
          Federal Arbitration Act, you agree that any and all disputes or claims
          that have arisen or may arise between us - except as set forth below
          ("Exceptions") - shall be resolved exclusively through final and
          binding arbitration, rather than in court. The Federal Arbitration Act
          governs the interpretation and enforcement of this Arbitration
          Agreement.
        </p>
        <p>
          Any arbitration proceedings shall be conducted by the American
          Arbitration Association ("AAA") under its rules and procedures
          applicable at that time, including the AAA's Supplementary Procedures
          for Consumer-Related Disputes (to the extent applicable), as modified
          by our Arbitration Agreement. You may review those rules and
          procedures, and obtain a form for initiating arbitration proceedings
          at the AAA's website. The AAA Rules and filing forms are available
          online at www.adr.org, by calling the AAA at 1-800-778-7879, or by
          contacting PocketDoctor. The arbitration shall be held in Santa Clara
          County, California or at another mutually agreed location. If the
          reasonable value of the relief sought is US$10,000 or less, either of
          us may elect to have the arbitration conducted by telephone or based
          solely on written submissions, which election shall be binding on us
          subject to the arbitrator's discretion to require an in-person
          hearing. Attendance at an in-person hearing may be made by telephone
          by you and/or us, unless the arbitrator requires otherwise.
        </p>
        <Typography as="h3" type="h3">
          Exceptions
        </Typography>
        <p>
          Notwithstanding the foregoing, nothing in this Arbitration Agreement
          will be deemed to waive, preclude, or otherwise limit the right of
          either party to: (a) bring an individual action in small claims court;
          (b) pursue an enforcement action through the applicable federal,
          state, or local agency if that action is available; (c) seek
          injunctive relief in a court of law in aid of arbitration; or (d) to
          file suit in a court of law to address an intellectual property
          infringement claim.
        </p>
        <Typography as="h3" type="h3">
          Notice
        </Typography>
        <p>
          A party who intends to seek arbitration must first send to the other,
          by certified mail, a written Notice of Dispute ("Notice"). The Notice
          to PocketDoctor should be addressed to: General Counsel, PocketDoctor,
          Inc. 16192 Coastal Highway, Lewes, Delaware 19958, County of Sussex
          ("Notice Address"). The Notice must (a) describe the nature and basis
          of the claim or dispute; and (b) set forth the specific relief sought
          and the specific applicable legal basis for the requested relief
          ("Demand"). The parties will make good faith efforts to resolve the
          claim directly, but if the parties do not reach an agreement to do so
          within 30 days after the Notice of Arbitration is received, you or
          PocketDoctor may commence an arbitration proceeding. All arbitration
          proceedings between the parties will be confidential unless otherwise
          agreed by the parties in writing. During the arbitration, the amount
          of any settlement offer made by you or PocketDoctor must not be
          disclosed to the arbitrator until after the arbitrator makes a final
          decision and award, if any.
        </p>
        <p>
          The arbitrator will decide the substance of all claims in accordance
          with the laws of the State of California, including recognized
          principles of equity, and will honor all claims of privilege
          recognized by law. The arbitrator shall not be bound by rulings in
          prior arbitrations involving different PocketDoctor users, but is
          bound by rulings in prior arbitrations involving the same user to the
          extent required by applicable law. The arbitrator's award shall be
          final and binding and judgment on the award rendered by the arbitrator
          may be entered in any court having jurisdiction over the parties,
          except for a limited right of appeal under the Federal Arbitration
          Act.
        </p>
        <Typography as="h3" type="h3">
          Opt-Out
        </Typography>
        <p>
          If you do not wish to resolve disputes by binding arbitration, you may
          opt out of this Arbitration Agreement within 30 days after the date
          that you agree to the Terms by sending a letter to General Counsel,
          PocketDoctor, Inc., 16192 Coastal Highway, Lewes, Delaware 19958,
          County of Sussex that specifies: your full legal name, the email
          address associated with your account on the Service, and a statement
          that you wish to opt out of arbitration ("Opt-Out Notice"). Once
          PocketDoctor receives your Opt-Out Notice, this Arbitration Agreement
          will be void and any action arising out of the Terms will be resolved
          under the governing law and jurisdiction set forth in the Terms. The
          remaining provisions of the Terms will not be affected by your Opt-Out
          Notice.
        </p>
        <Typography as="h3" type="h3">
          Fees
        </Typography>
        <p>
          The AAA rules will govern the payment of all filing, administration
          and arbitrator fees, unless our Arbitration Agreement expressly
          provides otherwise. If the amount of any claim in an arbitration is
          US$5,000 or less, PocketDoctor will pay all arbitrator fees associated
          with the arbitration, so long as: (a) you make a written request for
          such payment of fees and submit it to the AAA with your Demand for
          Arbitration; and (b) your claim is not determined by the arbitrator to
          be frivolous or without merit under PocketDoctor Terms of Use or
          otherwise ("Frivolous Claims"). In such case, we will make
          arrangements to pay all necessary fees directly to the AAA. If the
          amount of the claim exceeds US$10,000 and you are able to demonstrate
          that the costs of arbitration will be prohibitive as compared to the
          costs of litigation, PocketDoctor will pay as much of the filing,
          administration, and arbitrator fees as the arbitrator deems necessary
          to prevent the arbitration from being cost-prohibitive. If the
          arbitrator determines the claim(s) you assert in the arbitration are
          Frivolous Claims, you agree to reimburse PocketDoctor for all fees
          associated with the arbitration paid by PocketDoctor. PocketDoctor
          obligations to pay these fees shall be made after a preliminary
          determination that the claims are not barred or limited by the
          PocketDoctor Terms of Use. Bared or limited claims constitute
          Frivolous Claims.
        </p>
        <Typography as="h3" type="h3">
          PROHIBITION OF CLASS AND REPRESENTATIVE ACTIONS
        </Typography>
        <p>
          YOU AND POCKETDOCTOR AGREE, AS PART OF THE ARBITRATION AGREEMENT, THAT
          EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL
          BASIS AND NOT AS PART OF ANY PURPORTED CLASS OR REPRESENTATIVE ACTION
          OR PROCEEDING. WE REFER TO THIS AS THE "PROHIBITION OF CLASS AND
          REPRESENTATIVE ACTIONS". UNLESS BOTH YOU AND WE AGREE OTHERWISE, THE
          ARBITRATOR MAY NOT CONSOLIDATE OR JOIN YOUR OR OUR CLAIM WITH ANOTHER
          PERSON'S OR PARTY'S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER ANY
          FORM OF A CONSOLIDATED, REPRESENTATIVE OR CLASS PROCEEDING. THE
          ARBITRATOR MAY ONLY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND
          DECLARATORY RELIEF) IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF
          AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY
          THAT PARTY'S INDIVIDUAL CLAIM(S). ANY RELIEF AWARDED CANNOT AFFECT
          OTHER POCKETDOCTOR USERS.
        </p>
        <Typography as="h3" type="h3">
          Enforceability
        </Typography>
        <p>
          Except with respect to the Prohibition of Class and Representative
          Actions, if a court decides that any part of this Arbitration
          Agreement is invalid or unenforceable, the other parts of this
          Arbitration Agreement shall continue to apply. If a court decides that
          the Prohibition of Class and Representative Actions is invalid or
          unenforceable, then the entire Arbitration Agreement shall be null and
          void.
        </p>
        <Typography as="h3" type="h3">
          Modifications
        </Typography>
        <p>
          If PocketDoctor makes any future change to this Arbitration Agreement,
          other than a change to PocketDoctor Notice Address, you may reject the
          change by sending us written notice within 30 days of the change to
          the Notice Address, in which case your account with PocketDoctor will
          be immediately terminated and this arbitration provision, as in effect
          immediately prior to the changes you rejected will survive.
        </p>
        <Typography as="h3" type="h3">
          CLAIMS ARE TIME-BARRED
        </Typography>
        <p>
          You agree that regardless of any statute or law to the contrary or the
          applicable dispute resolution process, any claim or cause of action
          you may have arising out of or related to use of the Service or
          otherwise under these must be filed within one (1) year after such
          claim or cause of action arose or you hereby agree to be forever
          barred from bringing such claims.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Additional Terms: Medical Experts
        </Typography>
        <p>
          THESE ADDITIONAL TERMS APPLY TO POCKETDOCTOR MEDICAL EXPERTS
          ("ADDITIONAL EXPERT TERMS") WHEN USING POCKETDOCTOR AS A POCKETDOCTOR
          MEDICAL EXPERT. IF YOU ARE A POCKETDOCTOR MEDICAL EXPERT YOU AGREE TO
          ABIDE BY ALL POCKETDOCTOR TERMS OF USE AND THAT WHERE AN ADDITIONAL
          EXPERT TERM CONTRADICTS A MEMBER TERM OF USE, THE ADDITIONAL EXPERT
          TERM SHALL PREVAIL. THESE ADDITIONAL EXPERT TERMS APPLY TO YOUR USE OF
          POCKETDOCTOR AS A POCKETDOCTOR MEDICAL EXPERT; IF YOU USE THE APPS AS
          A MEMBER, YOU AGREE TO ALL MEMBER TERMS OF USE.
        </p>
        <Typography as="h3" type="h3">
          Medical Experts: Information
        </Typography>
        <p>
          As a PocketDoctor Medical Expert, you are required to register with
          and use your real name, professional contact information, and (if you
          choose to upload a picture) a real image of you in your public profile
          on PocketDoctor ("Public Profile"). You consent to PocketDoctor use on
          or in connection with the Apps of your name, likeness, photograph,
          biographical information and other personal information provided by
          you to PocketDoctor or that is publicly available about you.
        </p>
        <Typography as="h3" type="h3">
          Medical Experts: Doctors in Good Standing
        </Typography>
        <p>
          Licensed doctors may apply to and participate as Medical Experts on
          PocketDoctor. Doctors whose licenses are or become suspended or
          revoked, for any reason, are not permitted and agree not to
          participate in any way, including but not limited to submitting
          Content to PocketDoctor as a Medical Expert on PocketDoctor.
          PocketDoctor may expand the network to include other licensed medical
          experts.
        </p>
        <Typography as="h3" type="h3">
          Medical Experts: You Can Control Your Expert Submissions
        </Typography>
        <p>
          You retain the right to edit and delete your own Expert Submissions
          that appear in your Public Profile at any time. You also retain the
          right to use and create derivative works from your own Expert
          Submissions elsewhere (such as on your own practice website, blog, or
          in your own social media posts).
        </p>
        <Typography as="h3" type="h3">
          Medical Experts: Permission to Post and Use Expert Submissions
        </Typography>
        <p>
          When a PocketDoctor Medical Expert submission relates to any
          PocketDoctor feature or function and is made for publication on
          PocketDoctor (such as an answer to a question) (an "Expert
          Submission"), you grant PocketDoctor an unrestricted right without
          limitation to use, reproduce, extract data from or add data to,
          publish and post any such Expert Submissions, including in connection
          with or on PocketDoctor, including in connection with your name. This
          means that when you make such a submission to us, you are giving us
          rights to this content, including the right to post it on PocketDoctor
          and use it in connection with PocketDoctor. You also give us the right
          to give or transfer all rights granted herein to others. While Expert
          Submissions generally are posted to PocketDoctor, we do not have an
          obligation to post any particular Expert Submission on PocketDoctor
          and reserve the right, at our sole discretion and for any reason
          whatsoever (including but not limited to an Expert Submission being
          reported to us), to not post and/or to remove any Expert Submissions
          at any time.
        </p>
        <Typography as="h3" type="h3">
          Medical Experts: Rules when Submitting Expert Content
        </Typography>
        <p>
          So that everyone can enjoy PocketDoctor, we have rules for Expert
          Public Submissions. By submitting content to PocketDoctor you agree to
          follow our Medical Expert Guidelines.
        </p>
        <Typography as="h3" type="h3">
          Medical Experts: PocketDoctor Premium Services
        </Typography>
        <p>
          You will receive compensation for participating in PocketDoctor
          Premium Services pursuant to the additional terms applicable to those
          services and related agreements, which are incorporated herein by
          reference. When you sign up for and deliver care through PocketDoctor
          Premium Services, you agree to abide by all of the terms of the
          applications you submit to, and the contractual agreements you enter
          into with, PocketDoctor, Inc. related to those services (including any
          representations and warranties and terms governing indemnification and
          obligations).
        </p>
        <Typography as="h3" type="h3">
          Medical Experts: PocketDoctor Free Services
        </Typography>
        <p>
          You will not be compensated for participating in PocketDoctor Free
          Services in accordance with the additional terms applicable to these
          services and related agreements, which are incorporated herein by
          reference. PocketDoctor Free Services is a charity service. When you
          register and provide medical care through PocketDoctor Free Services,
          you agree to be bound by all the terms of the applications that you
          submit, as well as the privacy policy and terms of use that you enter
          into with PocketDoctor or PocketDoctor, Inc. related to these services
          (including any representations and warranties, as well as the terms
          governing damages and obligations).
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Medical Experts: Conflicts Disclosure Policy
        </Typography>
        <Typography as="h3" type="h3">
          Disclosure of Relationships with Industry
        </Typography>
        <p>
          You agree to comply with this Conflicts Disclosure Policy ("CDP") when
          using PocketDoctor or making Expert Submissions. This CDP governs the
          disclosure of financial interests by PocketDoctor Medical Experts.
          Conflicts of interest can be financial and/or personal. As used in
          this policy, "Industry" means any company, entity, or third party that
          produces, manufactures, or distributes a pharmaceutical, medical
          device, implant, or other medical care-related product or service.
        </p>
        <p>
          All Medical Experts with a material financial relationship with
          Industry must disclose such a relationship in any answer or other
          similar Content submitted to PocketDoctor when such Content mentions
          any product or service in which such an interest exists, with the
          following or similar language: "The author or poster of this content
          has a financial interest in a product or service mentioned herein."
        </p>
        <Typography as="h3" type="h3">
          No Posting Public Content for Pay
        </Typography>
        <p>
          Medical Experts may not post public Content on PocketDoctor in
          exchange for compensation of any kind, including but not limited to as
          a paid consultant to any entity (including any company or
          organization) or individual. Medical Experts should ensure that any
          conflict or potential conflict of interest does not affect or appear
          to affect his or her contributions of Content to the PocketDoctor.
          Medical Experts approved for PocketDoctor Premium Services may receive
          compensation for services rendered through PocketDoctor Premium
          Services under the terms governing those services.
        </p>
        <Typography as="h3" type="h3">
          No Posting of Content Written by Interested Parties
        </Typography>
        <p>
          Medical Experts may not publish Content under their own names written
          in whole or material part by: (i) parties with a financial interest in
          any product or service mentioned in the Content; or (ii) employees of
          Industry. Accepting compensation for posting Content to public areas
          and/or posting ghostwriting by interested parties on PocketDoctor is
          prohibited.
        </p>
      </ContentSection>
      <ContentSection>
        <Typography as="h2" type="h2">
          Agreement between PocketDoctor and medical experts
        </Typography>
        <ol>
          <li>
            If&nbsp;a&nbsp;doctor discloses PHI information, he&nbsp;will
            be&nbsp;liable by&nbsp;the law.
          </li>
          <li>
            If&nbsp;the user has requested a&nbsp;refund for the consultation,
            then PocketDoctor does not pay the doctor a&nbsp;legitimate
            percentage of&nbsp;the payment for the visit.
          </li>
          <li>
            If&nbsp;the doctor wants to&nbsp;leave the PocketDoctor service,
            he&nbsp;must notify the PocketDoctor company of&nbsp;his departure,
            and then consult the remaining users who have signed up&nbsp;for
            a&nbsp;visit to&nbsp;him, and the PocketDoctor company makes the
            doctor unavailable for the next visit entries from users.
          </li>
          <li>
            The doctor cannot enter into a&nbsp;relationship with the user
            of&nbsp;the PocketDoctor product.
          </li>
          <li>
            The doctor agrees that his personal information (first name, last
            name, photo, description of&nbsp;his work experience, work
            experience) is&nbsp;available for public access and viewing.
          </li>
          <li>
            The doctor answers the user&rsquo;s questions only on&nbsp;the case
            and according to&nbsp;the user&rsquo;s consultation plan, there are
            no&nbsp;unnecessary dialogues about life that the doctor and the
            user cannot conduct.
          </li>
          <li>
            The doctor cannot make prescriptions to&nbsp;the user through the
            PocketDoctor platform, he&nbsp;can only recommend treatment
            conclusions for the user.
          </li>
          <li>
            The doctor can change the schedule of&nbsp;consultation with the
            user only when there are no&nbsp;registered users to&nbsp;visit him.
            If&nbsp;there is&nbsp;a&nbsp;flow of&nbsp;appointment records
            to&nbsp;the doctor, the doctor presses the stop button in&nbsp;the
            account settings from the moment of&nbsp;the date when he&nbsp;does
            not want to&nbsp;consult, taking into account that the following
            dates are free from the user&rsquo;s record. Only after that, the
            doctor can change his next work schedule where there are
            no&nbsp;records for a&nbsp;visit from the user. Or&nbsp;the doctor
            can contact technical support for help changing his schedule.
          </li>
          <li>
            The doctor pays taxes on&nbsp;his profits himself about the law
            of&nbsp;his country or&nbsp;state.
          </li>
          <li>
            In&nbsp;cases of&nbsp;mail interception (or&nbsp;other methods), the
            PHI of&nbsp;the PocketDoctor user is&nbsp;not guilty, the doctor
            is&nbsp;guilty. And everything that the doctor did that was
            inappropriate about the user is&nbsp;the doctor&rsquo;s fault.
          </li>
          <li>
            The doctor can only give his opinion on&nbsp;the issue of&nbsp;the
            patient&rsquo;s health and give conclusions on&nbsp;treatment
            in&nbsp;the form of&nbsp;advice on&nbsp;which prescriptions
            of&nbsp;medicines, and directions for examinations, the doctor
            cannot enter.
          </li>
          <li>
            If&nbsp;the doctor did not show up&nbsp;for a&nbsp;visit with the
            user, or&nbsp;if&nbsp;there is&nbsp;no&nbsp;connection for
            a&nbsp;visit with the user, or&nbsp;if&nbsp;a&nbsp;failure during
            a&nbsp;visit with the user happened, then the money is&nbsp;returned
            to&nbsp;the patient, but if&nbsp;the consultation is&nbsp;completed
            by&nbsp;the doctor, then the share for the work is&nbsp;transferred
            to&nbsp;the doctor and the PocketDoctor company.
          </li>
          <li>
            It&nbsp;is&nbsp;prohibited to&nbsp;sell and sell information from
            PocketDoctor to&nbsp;third parties.
          </li>
          <li>
            The doctor cannot take screenshots, copy video audio dialogue with
            the user, etc. For such a&nbsp;case, a&nbsp;lifetime account lockout
            and a&nbsp;court by&nbsp;the law.
          </li>
          <li>
            The Doctor will be&nbsp;responsible for the non-legal documents
            provided by&nbsp;the Doctor by&nbsp;the law.
          </li>
          <li>
            The doctor cannot consult if&nbsp;the user has an&nbsp;emergency,
            the doctor must not slowly stop the dialogue and call 911&nbsp;at
            the patient&rsquo;s address.
          </li>
          <li>
            The doctor does not have the right to&nbsp;communicate outside the
            PocketDoctor product with PocketDoctor users, and also the doctor
            cannot redirect the PocketDoctor user to&nbsp;himself for
            a&nbsp;personal examination.
          </li>
          <li>
            The doctor cannot contact other doctors of&nbsp;the PocketDoctor
            product.
          </li>
          <li>
            If&nbsp;the doctor misses more than 3&nbsp;visits for no&nbsp;good
            reason, the account is&nbsp;always blocked.
          </li>
          <li>
            The doctor cannot tell the user his email, phone, social network,
            address and location, and other contact information to&nbsp;contact
            and meet with him.
          </li>
          <li>
            The doctor must communicate politely and respectfully with each
            PocketDoctor user, the doctor cannot offend, incorrectly degenerate,
            and also not discriminate against the user regarding gender, age,
            height, weight, race, appearance, skin color, national origin,
            disability, caste, criminal record, ethnicity, marital status,
            generation, genetic characteristics, marital status, religion,
            social class, sexual orientation and gender identity, etc. For any
            insults and discrimination against the user, the doctor will block
            his account.
          </li>
          <li>
            Doctors cannot lure users from our PocketDoctor product for
            a&nbsp;personal examination of&nbsp;their companies
            or&nbsp;competitors&rsquo; companies.
          </li>
          <li>
            The doctor is&nbsp;obliged to&nbsp;honestly give a&nbsp;treatment
            strategy for the user and always write conclusions in&nbsp;the
            user&rsquo;s &laquo;My&nbsp;Record&raquo; after consulting with the
            user.
          </li>
          <li>
            If&nbsp;the doctor did not have time to&nbsp;set up&nbsp;his
            communication device before meeting with the user and for this
            reason the connection was lost or&nbsp;the connection was lost many
            times or&nbsp;it&nbsp;was not possible to&nbsp;contact the user,
            then PocketDoctor does not transfer money to&nbsp;the doctor&rsquo;s
            account for consultation.
          </li>
          <li>
            The doctor cannot try to&nbsp;sell something
            to&nbsp;a&nbsp;PocketDoctor user.
          </li>
          <li>
            The doctor cannot post inappropriate information in&nbsp;his account
            and the &laquo;My&nbsp;Record&raquo; card of&nbsp;the user.
          </li>
          <li>
            The doctor is&nbsp;obliged to&nbsp;work out the specified period
            of&nbsp;work at&nbsp;the time of&nbsp;registration of&nbsp;his
            account. If&nbsp;the doctor stopped consulting users, taking into
            account that PocketDoctor users were recorded to&nbsp;him for
            an&nbsp;inappropriate reason, then we&nbsp;block the doctor&rsquo;s
            account for always and leave him the appropriate reputation
            of&nbsp;his act for his next job.
          </li>
          <li>
            The PocketDoctor product charges a&nbsp;40% consultation fee for
            one-time payment and patient insurance. The clinic or&nbsp;doctor
            receives&nbsp;60% of&nbsp;the patient&rsquo;s payment amount.
          </li>
        </ol>
      </ContentSection>
    </TextContent>
  </GeneralPage>
));
