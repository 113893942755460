import {
  Model as Doctor,
  DoctorDTO,
  DoctorProfessionalInformation,
  LicenseRequest,
  Schedule,
  userToDoctorAdapter,
} from "Doctor/Model";
import { apiServer } from "apiConnection";
import { AxiosError } from "axios";
import { PersonalInformationPayload } from "./doctorSession";

export type Credentials = {
  email: string;
  password: string;
};

export async function signUp({
  email,
  password,
}: Credentials): Promise<Doctor> {
  try {
    const { data: newDoctor } = await apiServer.post<DoctorDTO>(
      "/api/doctor/signup",
      {
        email,
        password,
      }
    );
    return userToDoctorAdapter(newDoctor);
  } catch (error: unknown) {
    const message = (error as AxiosError<{ message: string }>).response?.data
      .message;
    throw new Error(message);
  }
}

export async function logIn({ email, password }: Credentials): Promise<Doctor> {
  try {
    const { data: doctor } = await apiServer.post<DoctorDTO>(
      "/api/doctor/login",
      {
        email,
        password,
      }
    );
    return userToDoctorAdapter(doctor);
  } catch (error: unknown) {
    const message = (error as AxiosError<{ message: string }>).response?.data
      .message;
    throw new Error(message);
  }
}

export async function logOut() {
  try {
    const { data: success } = await apiServer.post<boolean>(
      "/api/doctor/logout"
    );
    return success;
  } catch (error: unknown) {
    const message = (error as AxiosError<{ message: string }>).response?.data
      .message;
    throw new Error(message);
  }
}

export async function updateEmailRequest(newEmail: string) {
  try {
    const { data: updatedDoctor } = await apiServer.patch<DoctorDTO>(
      "/api/doctor/email-update",
      {
        newEmail,
      }
    );
    return userToDoctorAdapter(updatedDoctor);
  } catch (error: unknown) {
    const message = (error as AxiosError<{ message: string }>).response?.data
      .message;
    throw new Error(message);
  }
}

export async function updatePasswordRequest({
  oldPassword,
  newPassword,
}: {
  oldPassword: string;
  newPassword: string;
}) {
  try {
    const { data: updatedDoctor } = await apiServer.patch<DoctorDTO>(
      "/api/doctor/password-update",
      {
        oldPassword,
        newPassword,
      }
    );
    return userToDoctorAdapter(updatedDoctor);
  } catch (error: unknown) {
    const message = (error as AxiosError<{ message: string }>).response?.data
      .message;
    throw new Error(message);
  }
}

export async function updatePersonalInformation(
  personalInformation: PersonalInformationPayload
): Promise<Doctor> {
  const { photo, firstName, lastName, DOB, phone, about } = personalInformation;
  const personalData = new FormData();
  if (photo?.item(0)) {
    personalData.append(
      "photo",
      photo.item(0) as File,
      photo.item(0)?.name as string
    );
  }
  personalData.append("firstName", firstName);
  personalData.append("lastName", lastName);
  personalData.append("DOB", DOB);
  personalData.append("about", about);
  if (phone) {
    personalData.append("phone", phone);
  }
  try {
    const { data: updatedUser } = await apiServer.patch<DoctorDTO>(
      "/api/doctor/personal",
      personalData
    );
    return userToDoctorAdapter(updatedUser);
  } catch (error: unknown) {
    const message = (error as AxiosError<{ message: string }>).response?.data
      .message;
    throw new Error(message);
  }
}

export async function updateProfessionalInformation(
  professionalInformation: DoctorProfessionalInformation
): Promise<Doctor> {
  try {
    const { data: updatedUser } = await apiServer.patch<DoctorDTO>(
      "/api/doctor/professional",
      {
        professionalInformation,
      }
    );
    return userToDoctorAdapter(updatedUser);
  } catch (error: unknown) {
    const message = (error as AxiosError<{ message: string }>).response?.data
      .message;
    throw new Error(message);
  }
}

export async function updateLicense({
  licenseDocuments,
  ...license
}: LicenseRequest): Promise<Doctor> {
  try {
    const licenseFormData = new FormData();
    Object.entries(license).forEach(([key, value]) =>
      licenseFormData.append(key, value || "")
    );
    licenseDocuments?.forEach((document: File) => {
      licenseFormData.append("documents", document, document.name);
    });
    const { data: updatedUser } = await apiServer.patch<DoctorDTO>(
      "/api/doctor/license",
      licenseFormData
    );
    return userToDoctorAdapter(updatedUser);
  } catch (error: unknown) {
    const message = (error as AxiosError<{ message: string }>).response?.data
      .message;
    throw new Error(message);
  }
}

export async function updateSchedule(
  schedule: Partial<Schedule>
): Promise<Doctor> {
  try {
    const { data: updatedUser } = await apiServer.patch<DoctorDTO>(
      "/api/doctor/schedule",
      {
        schedule,
      }
    );
    return userToDoctorAdapter(updatedUser);
  } catch (error: unknown) {
    const message = (error as AxiosError<{ message: string }>).response?.data
      .message;
    throw new Error(message);
  }
}

export async function createAccount(): Promise<Doctor> {
  try {
    const { data: updatedUser } = await apiServer.patch<DoctorDTO>(
      "/api/doctor/create-account",
      { locationId: process.env.REACT_APP_SQUARE_LOCATION_ID }
    );
    return userToDoctorAdapter(updatedUser);
  } catch (error: unknown) {
    const message = (error as AxiosError<{ message: string }>).response?.data
      .message;
    throw new Error(message);
  }
}
