import styled, { StyledComponent } from "@emotion/styled";
import { HTMLAttributes, RefObject } from "react";

export const Block = asBlock(styled.div<BlockContainerProps>`
  display: ${({ inline }) => (inline ? "inline-block" : "block")};
`);

export const Flex = asFlex(styled(Block)<BlockContainerProps>`
  display: ${(props) => (props.inline ? "inline-flex" : "flex")};
`);

export const Grid = asGrid(styled(Flex)<FlexContainerProps>`
  display: ${(props) => (props.inline ? "inline-grid" : "grid")};
`);

function asBlock(component: StyledComponent<BlockContainerProps, {}, {}>) {
  return styled(component)<BlockContainerProps>`
    ${(props) => margins(props)};
    ${(props) => paddings(props)};
    ${(props) => whiteSpace(props)};
    ${(props) => textAlign(props)};
    ${(props) => overflow(props)};
    ${(props) => overflowX(props)};
    ${(props) => overflowY(props)};
    ${(props) => textTransform(props)};
    ${(props) => width(props)};
    ${(props) => maxWidth(props)};
    ${(props) => minWidth(props)};
    ${(props) => height(props)};
    ${(props) => maxHeight(props)};
    ${(props) => minHeight(props)};
    ${(props) => position(props)};
    ${(props) => topPos(props)};
    ${(props) => right(props)};
    ${(props) => left(props)};
    ${(props) => bottom(props)};
    ${(props) => float(props)};
    ${(props) => zIndex(props)};
  `;
}

function asFlex(component: StyledComponent<BlockContainerProps, {}, {}>) {
  return styled(component)<FlexContainerProps>`
    ${(props) => flexDirection(props)};
    ${(props) => justifyContent(props)};
    ${(props) => flexWrap(props)};
    ${(props) => alignContent(props)};
    ${(props) => alignItems(props)};
    ${(props) => alignSelf(props)};
    ${(props) => flexGrow(props)};
    ${(props) => flexShrink(props)};
  `;
}

function asGrid(component: StyledComponent<FlexContainerProps, {}, {}>) {
  return styled(component)<LayoutContainerProps>`
    ${(props) => alignGridContent(props)};
    ${(props) => gridGap(props)};
    ${(props) => gridColumnGap(props)};
    ${(props) => gridRowGap(props)};
    ${(props) => gridTemplateAreas(props)};
    ${(props) => gridTemplateColumns(props)};
    ${(props) => gridTemplateRows(props)};
    ${(props) => gridRowEnd(props)};
    ${(props) => gridAutoFlow(props)};
  `;
}

type AlignItemsValues =
  | "stretch"
  | "center"
  | "flex-start"
  | "flex-end"
  | "baseline"
  | "initial"
  | "inherit";

type GridAlignValues =
  | "start"
  | "end"
  | "center"
  | "stretch"
  | "space-around"
  | "space-between"
  | "space-evenly";

type FlexDirectionValues =
  | "row"
  | "row-reverse"
  | "column"
  | "column-reverse"
  | "initial"
  | "inherit";

type JustifyContentValues =
  | "flex-start"
  | "flex-end"
  | "center"
  | "space-between"
  | "space-around"
  | "initial"
  | "inherit";

type TextAlignValues =
  | "left"
  | "right"
  | "center"
  | "justify"
  | "initial"
  | "inherit";

type WhiteSpaceValues =
  | "normal"
  | "nowrap"
  | "pre"
  | "pre-line"
  | "pre-wrap"
  | "initial"
  | "inherit";

type FlexWrapValues =
  | "nowrap"
  | "wrap"
  | "wrap-reverse"
  | "initial"
  | "inherit";

type GridTemplateValues =
  | "none"
  | "auto"
  | "max-content"
  | "min-content"
  | string
  | "initial"
  | "inherit";

type OverflowValues =
  | "visible"
  | "hidden"
  | "scroll"
  | "auto"
  | "initial"
  | "inherit";

type TextTransformValues =
  | "none"
  | "capitalize"
  | "uppercase"
  | "lowercase"
  | "initial"
  | "inherit";

type GridFlowValues = "column" | "row";

type PositionValues = "static" | "relative" | "absolute" | "fixed";

type NonStaticOffsetValues = "auto" | 0 | string | "initial" | "inherit";

type SizeValues = "auto" | 0 | string | "initial" | "inherit";

type FloatValues = "none" | "left" | "right" | "initial" | "inherit";

type Indent =
  | "auto"
  | 0
  | 0.5
  | 1
  | 1.5
  | 2
  | 2.5
  | 3
  | 3.5
  | 4
  | 4.5
  | 5
  | 5.5
  | 6
  | 6.5
  | 7
  | 7.5
  | 8
  | 8.5
  | 9
  | 9.5
  | 10
  | 10.5
  | 11
  | 11.5
  | 12
  | 12.5
  | 13
  | 13.5
  | 14
  | 14.5
  | 15
  | 15.5
  | 16
  | 16.5
  | 17
  | 17.5
  | 18
  | 18.5
  | 19
  | 19.5
  | 20;

type BlockContainerProps = HTMLAttributes<HTMLDivElement> & {
  className?: string;
  marginTop?: Indent;
  marginRight?: Indent;
  marginBottom?: Indent;
  marginLeft?: Indent;
  margin?: Indent;
  paddingTop?: Indent;
  paddingRight?: Indent;
  paddingBottom?: Indent;
  paddingLeft?: Indent;
  padding?: Indent;
  whiteSpace?: WhiteSpaceValues;
  width?: SizeValues;
  maxWidth?: SizeValues;
  minWidth?: SizeValues;
  height?: SizeValues;
  maxHeight?: SizeValues;
  minHeight?: SizeValues;
  overflow?: OverflowValues;
  overflowX?: OverflowValues;
  overflowY?: OverflowValues;
  ref?: RefObject<HTMLDivElement>;
  textTransform?: TextTransformValues;
  textAlign?: TextAlignValues;
  position?: PositionValues;
  top?: NonStaticOffsetValues;
  right?: NonStaticOffsetValues;
  left?: NonStaticOffsetValues;
  bottom?: NonStaticOffsetValues;
  inline?: boolean;
  float?: FloatValues;
  id?: string;
  zIndex?: SizeValues;
};

export type FlexContainerProps = BlockContainerProps & {
  alignContent?: AlignItemsValues;
  alignItems?: AlignItemsValues;
  alignSelf?: AlignItemsValues;
  flexDirection?: FlexDirectionValues;
  justifyContent?: JustifyContentValues;
  flexWrap?: FlexWrapValues;
  flexGrow?: number;
  flexShrink?: number;
};

type GridContainerProps = {
  alignGridContent?: GridAlignValues;
  gridGap?: number;
  gridColumnGap?: number;
  gridRowGap?: number;
  gridTemplateAreas?: string;
  gridTemplateColumns?: GridTemplateValues;
  gridTemplateRows?: GridTemplateValues;
  gridRowEnd?: string;
  gridAutoFlow?: GridFlowValues;
};

type LayoutContainerProps = BlockContainerProps &
  FlexContainerProps &
  GridContainerProps;

type MarginsAndPaddings =
  | "marginBottom"
  | "marginTop"
  | "marginLeft"
  | "marginRight"
  | "margin"
  | "paddingBottom"
  | "paddingTop"
  | "paddingLeft"
  | "paddingRight"
  | "padding";

function getSize(ratio?: Indent) {
  if (!ratio) {
    return 0;
  }
  if (ratio === "auto") {
    return "auto";
  }
  return `${ratio * 8}px`;
}

const when = <T,>(condition?: boolean, ...args: T[]) => (condition ? args : []);

const skipValueTransform: Function = (
  val: string | number | undefined | boolean
) => val;

const mapPropToValue =
  (
    prop: keyof LayoutContainerProps,
    customValueTransformer: Function = skipValueTransform
  ) =>
  (props: LayoutContainerProps) =>
    when(typeof props[prop] != "undefined", {
      [prop]: customValueTransformer(props[prop]),
    });

const offsets =
  (values: MarginsAndPaddings[]) => (props: LayoutContainerProps) =>
    values.reduce((acc, prop: MarginsAndPaddings) => {
      return props.hasOwnProperty(prop)
        ? { ...acc, [prop]: getSize(props[prop]) }
        : acc;
    }, {});

const margins = offsets([
  "marginTop",
  "marginRight",
  "marginBottom",
  "marginLeft",
  "margin",
]);

const paddings = offsets([
  "paddingTop",
  "paddingRight",
  "paddingBottom",
  "paddingLeft",
  "padding",
]);

const whiteSpace = mapPropToValue("whiteSpace");
const textAlign = mapPropToValue("textAlign");
const width = mapPropToValue("width");
const maxWidth = mapPropToValue("maxWidth");
const minWidth = mapPropToValue("minWidth");
const height = mapPropToValue("height");
const maxHeight = mapPropToValue("maxHeight");
const minHeight = mapPropToValue("minHeight");
const overflow = mapPropToValue("overflow");
const overflowX = mapPropToValue("overflowX");
const overflowY = mapPropToValue("overflowY");
const textTransform = mapPropToValue("textTransform");
const position = mapPropToValue("position");
const topPos = mapPropToValue("top");
const right = mapPropToValue("right");
const bottom = mapPropToValue("bottom");
const left = mapPropToValue("left");
const float = mapPropToValue("float");
const zIndex = mapPropToValue("zIndex");

const alignContent = mapPropToValue("alignContent");
const alignItems = mapPropToValue("alignItems");
const alignSelf = mapPropToValue("alignSelf");
const flexDirection = mapPropToValue("flexDirection");
const justifyContent = mapPropToValue("justifyContent");
const flexWrap = mapPropToValue("flexWrap");
const flexGrow = mapPropToValue("flexGrow");
const flexShrink = mapPropToValue("flexShrink");

const alignGridContent = mapPropToValue("alignContent");
const gridGap = mapPropToValue("gridGap", getSize);
const gridColumnGap = mapPropToValue("gridColumnGap", getSize);
const gridRowGap = mapPropToValue("gridRowGap", getSize);
const gridTemplateAreas = mapPropToValue("gridTemplateAreas");
const gridTemplateColumns = mapPropToValue("gridTemplateColumns");
const gridTemplateRows = mapPropToValue("gridTemplateRows");
const gridRowEnd = mapPropToValue("gridRowEnd");
const gridAutoFlow = mapPropToValue("gridAutoFlow");
