import styled from "@emotion/styled";
import { Block, Flex } from "./Layout";
import { boxShadow, color } from "./theme";

type Props = {
  className?: string;
  bgColor?: keyof typeof color;
};

export const Avatar = styled(Block)<Props>`
  & img,
  & .incognito {
    border: 3px solid ${color.N0};
    border-radius: 50%;
    box-shadow: ${boxShadow.extraSmall};
    width: 100%;
    height: 100%;
  }
`;

export const IncognitoAvatar = styled(Flex)<Props>`
  align-items: center;
  background-color: ${({ bgColor }) => (bgColor ? color[bgColor] : color.N200)};
  justify-content: center;
`;
