import { loginHomePage, registrationHomePage } from "Home/routes";
import { useParams } from "react-router-dom";
import { DoctorProfileRouterParams } from "./Model";

export const doctorLoginPage = `${loginHomePage}/doctor`;
export const doctorRegistrationPage = `${registrationHomePage}/doctor`;

type PatientRecord = {
  doctorId: string;
  patientId: string;
};

/**
 * Doctor profile routes
 * */
export const doctorProfileHomePage = "/doctor/:doctorId";
export const toDoctorProfileHomePage = (doctorID: string) =>
  `/doctor/${doctorID}`;

export const doctorProfileSettingsPage = `${doctorProfileHomePage}/settings`;
export const toDoctorProfileSettingsPage = (doctorID: string) =>
  `${toDoctorProfileHomePage(doctorID)}/settings`;

export const doctorCreateAccountPage = `${doctorProfileHomePage}/create-account`;
export const toDoctorCreateAccountPage = (doctorID: string) =>
  `${toDoctorProfileHomePage(doctorID)}/create-account`;

export const doctorWaitingRoomPage = `${doctorProfileHomePage}/waiting-room`;
export const toDoctorWaitingRoomPage = (doctorID: string) =>
  `${toDoctorProfileHomePage(doctorID)}/waiting-room`;

export const doctorVisitsHistoryPage = `${doctorProfileHomePage}/visits-history`;
export const toDoctorVisitsHistoryPage = (doctorID: string) =>
  `${toDoctorProfileHomePage(doctorID)}/visits-history`;

export const doctorVirtualVisitPage = `${doctorProfileHomePage}/visit/:visitId`;
export const toDoctorVirtualVisitPage = (doctorID: string, visitID: string) =>
  `${toDoctorProfileHomePage(doctorID)}/visit/${visitID}`;

export const doctorProfilePage = `${doctorProfileHomePage}/profile`;
export const toDoctorEditProfilePage = (doctorID: string) =>
  `${toDoctorProfileHomePage(doctorID)}/profile`;

export const doctorFAQPage = `${doctorProfileHomePage}/faq`;
export const toDoctorFAQPage = (doctorID: string) =>
  `${toDoctorProfileHomePage(doctorID)}/faq`;

export const doctorSupportPage = `${doctorProfileHomePage}/support`;
export const toDoctorSupportPage = (doctorID: string) =>
  `${toDoctorProfileHomePage(doctorID)}/support`;

export const doctorNotificationsPage = `${doctorProfileHomePage}/notifications`;
export const toDoctorNotificationsPage = (doctorID: string) =>
  `${toDoctorProfileHomePage(doctorID)}/notifications`;

export const doctorPatientRecordHome = `${doctorProfileHomePage}/patient-record/:patientId`;
export const toDoctorPatientRecordHome = ({
  doctorId,
  patientId,
}: PatientRecord) =>
  `${toDoctorProfileHomePage(doctorId)}/patient-record/${patientId}`;

export const doctorPatientRecordCaseHistory = `${doctorPatientRecordHome}/case-history`;
export const toDoctorPatientRecordCaseHistory = (recordInput: PatientRecord) =>
  `${toDoctorPatientRecordHome(recordInput)}/case-history`;

export const doctorPatientRecordVaccination = `${doctorPatientRecordHome}/vaccination`;
export const toDoctorPatientRecordVaccination = (recordInput: PatientRecord) =>
  `${toDoctorPatientRecordHome(recordInput)}/vaccination`;

export const doctorPatientRecordConclusionsList = `${doctorPatientRecordHome}/conclusions`;
export const toDoctorPatientRecordConclusionsList = (
  recordInput: PatientRecord
) => `${toDoctorPatientRecordHome(recordInput)}/conclusions`;

export const doctorPatientRecordAddConclusion = `${doctorPatientRecordHome}/conclusions/add`;
export const toDoctorPatientRecordAddConclusion = (
  recordInput: PatientRecord
) => `${toDoctorPatientRecordHome(recordInput)}/conclusions/add`;

export const doctorPatientRecordHealthMetrics = `${doctorPatientRecordHome}/health-metrics`;
export const toDoctorPatientRecordHealthMetrics = (
  recordInput: PatientRecord
) => `${toDoctorPatientRecordHome(recordInput)}/health-metrics`;

export const doctorPatientRecordFamilyDiseases = `${doctorPatientRecordHome}/family-diseases`;
export const toDoctorPatientRecordFamilyDiseases = (
  recordInput: PatientRecord
) => `${toDoctorPatientRecordHome(recordInput)}/family-diseases`;

export const doctorPatientRecordProcedures = `${doctorPatientRecordHome}/procedures`;
export const toDoctorPatientRecordProcedures = (recordInput: PatientRecord) =>
  `${toDoctorPatientRecordHome(recordInput)}/procedures`;

export const doctorPatientRecordPharmacy = `${doctorPatientRecordHome}/pharmacy`;
export const toDoctorPatientRecordPharmacy = (recordInput: PatientRecord) =>
  `${toDoctorPatientRecordHome(recordInput)}/pharmacy`;

export const doctorPatientRecordLifestyle = `${doctorPatientRecordHome}/lifestyle`;
export const toDoctorPatientRecordLifestyle = (recordInput: PatientRecord) =>
  `${toDoctorPatientRecordHome(recordInput)}/lifestyle`;

export const doctorPatientRecordMedicine = `${doctorPatientRecordHome}/medicine`;
export const toDoctorPatientRecordMedicine = (recordInput: PatientRecord) =>
  `${toDoctorPatientRecordHome(recordInput)}/medicine`;

export const doctorPatientRecordSurgeries = `${doctorPatientRecordHome}/surgeries`;
export const toDoctorPatientRecordSurgeries = (recordInput: PatientRecord) =>
  `${toDoctorPatientRecordHome(recordInput)}/surgeries`;

export const doctorPatientRecordLabTests = `${doctorPatientRecordHome}/lab-tests`;
export const toDoctorPatientRecordLabTests = (recordInput: PatientRecord) =>
  `${toDoctorPatientRecordHome(recordInput)}/lab-tests`;

export const doctorPatientRecordAddLabTest = `${doctorPatientRecordHome}/lab-tests/add`;
export const toDoctorPatientRecordAddLabTest = (recordInput: PatientRecord) =>
  `${toDoctorPatientRecordHome(recordInput)}/lab-tests/add`;

export const BLOCKED_ON_MODERATION_ROUTES = [
  doctorProfileSettingsPage,
  doctorWaitingRoomPage,
  doctorVisitsHistoryPage,
  doctorVirtualVisitPage,
  doctorProfilePage,
  doctorPatientRecordConclusionsList,
  doctorPatientRecordAddConclusion,
  doctorPatientRecordLabTests,
  doctorPatientRecordAddLabTest,
];

export const AUTHENTICATED_ROUTES = [
  ...BLOCKED_ON_MODERATION_ROUTES,
  doctorProfileHomePage,
  doctorFAQPage,
  doctorSupportPage,
  doctorNotificationsPage,
  doctorPatientRecordHome,
  doctorPatientRecordCaseHistory,
  doctorPatientRecordVaccination,
  doctorPatientRecordHealthMetrics,
  doctorPatientRecordFamilyDiseases,
  doctorPatientRecordProcedures,
  doctorPatientRecordPharmacy,
  doctorPatientRecordLifestyle,
  doctorPatientRecordMedicine,
  doctorPatientRecordSurgeries,
];

export function useDoctorId() {
  /**
   * At first, look at url parameters
   * If doctor id not found, search in session store
   */
  const { doctorId } = useParams<DoctorProfileRouterParams>();
  // Before update to react-router v6, patient id returned a plaing string.
  // Need to the manual type casting escape hatch to prevent 'undefined Error' across the callers
  return doctorId as string;
}
