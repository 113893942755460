import { apiServer } from "apiConnection";
import { ISO8601 } from "Doctor/Model";
import { attach, createEffect, createStore } from "effector";
import { successNotification } from "ui-kit";
import { SurgeryDTO } from "./typeDefinitions";

export const loadSurgeries = createEffect("loadSurgeries", {
  handler: async (patientId: string) => await getSurgeries(patientId),
});

export const addSurgery = createEffect("addSurgery", {
  handler: async ({
    patientId,
    name,
    date,
    description,
    type,
  }: {
    patientId: string;
    name: string;
    date: ISO8601;
    description: string;
    type: "past" | "upcoming";
  }) =>
    await createSurgeryRecord({
      patientId,
      name,
      date,
      description,
      type,
    }),
});

addSurgery.doneData.watch(() => {
  successNotification("Surgery was added");
});

export const editSurgery = createEffect("editSurgery", {
  handler: async ({
    patientId,
    recordId,
    name,
    date,
    description,
    type,
  }: {
    patientId: string;
    recordId: string;
    name: string;
    date: ISO8601;
    description: string;
    type: "past" | "upcoming";
  }) =>
    await updateSurgeryRecord({
      patientId,
      recordId,
      name,
      date,
      description,
      type,
    }),
});

editSurgery.doneData.watch(() => {
  successNotification("Surgery was updated");
});

const hydrated_moveSurgeryToPast = createEffect("moveSurgeryToPast", {
  handler: async ({
    patientId,
    recordId,
    store,
  }: {
    patientId: string;
    recordId: string;
    store: SurgeryDTO[];
  }) => {
    const surgeryRecord = store
      .filter(({ type }) => type === "upcoming")
      .find(({ id }) => id === recordId);
    if (!surgeryRecord) {
      throw new Error(`Surgery with id ${recordId} not found`);
    }
    return await updateSurgeryRecord({
      patientId,
      recordId,
      type: "past",
      name: surgeryRecord.name,
      description: surgeryRecord.description,
      date: surgeryRecord.date,
    });
  },
});

hydrated_moveSurgeryToPast.doneData.watch(() => {
  successNotification("Surgery was moved to past");
});

export const deleteSurgery = createEffect("deleteSurgery", {
  handler: async ({
    patientId,
    recordId,
  }: {
    patientId: string;
    recordId: string;
  }) => await removeSurgeryRecord({ patientId, recordId }),
});

deleteSurgery.doneData.watch(() => {
  successNotification("Surgery was removed");
});

async function getSurgeries(patientId: string) {
  const { data: surgeriesDTO } = await apiServer.get<SurgeryDTO[]>(
    `/api/patient/${patientId}/surgeries`
  );
  return surgeriesDTO;
}

async function createSurgeryRecord({
  patientId,
  name,
  date,
  description,
  type,
}: {
  patientId: string;
  name: string;
  date: ISO8601;
  description: string;
  type: "past" | "upcoming";
}) {
  const { data: createdRecord } = await apiServer.post<SurgeryDTO>(
    `/api/patient/${patientId}/surgeries`,
    {
      name,
      date,
      description,
      type,
    }
  );
  return createdRecord;
}

async function updateSurgeryRecord({
  patientId,
  recordId,
  name,
  date,
  description,
  type,
}: {
  patientId: string;
  recordId: string;
  name: string;
  date: ISO8601;
  description: string;
  type: "past" | "upcoming";
}) {
  const { data: updatedRecord } = await apiServer.post<SurgeryDTO>(
    `/api/patient/${patientId}/surgeries/${recordId}`,
    {
      name,
      date,
      description,
      type,
    }
  );
  return updatedRecord;
}

async function removeSurgeryRecord({
  patientId,
  recordId,
}: {
  patientId: string;
  recordId: string;
}) {
  const { data: removedRecord } = await apiServer.delete<{ id: string }>(
    `/api/patient/${patientId}/surgeries/${recordId}`
  );
  return removedRecord.id;
}

export const surgeries = createStore<SurgeryDTO[]>([])
  .on(loadSurgeries.doneData, (state, surgeries) => surgeries)
  .on(addSurgery.doneData, (state, surgery) => [...state, surgery])
  .on(
    [editSurgery.doneData, hydrated_moveSurgeryToPast.doneData],
    (state, updatedSurgery) =>
      state.map((record) =>
        record.id === updatedSurgery.id ? updatedSurgery : record
      )
  )
  .on(deleteSurgery.doneData, (state, recordId) =>
    state.filter((record) => record.id !== recordId)
  );

export const moveSurgeryToPast = attach({
  effect: hydrated_moveSurgeryToPast,
  source: surgeries,
  mapParams: (
    { patientId, recordId }: { patientId: string; recordId: string },
    store
  ) => ({ patientId, recordId, store }),
});
