import styled from "@emotion/styled";
import { doctorsListPage } from "OurDoctors/routes";
import { EmergencyInformation as EmergencyFormData } from "Patient/Model";
import { toPatientProfilHome, toTalkToDoctor } from "Patient/routes";
import { useAuthBroadcastSignal } from "Session";
import { useStore } from "effector-react";
import { ReactNode, memo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { StepWizardChildProps } from "react-step-wizard";
import {
  Block,
  Button,
  ChevronLeftIcon,
  CountrySelectbox,
  Flex,
  Input,
  LoaderWithOverlay,
  Option,
  PhoneNumberInput,
  StateSelectbox,
  Tooltip,
  Typography,
  color,
  errorNotification,
  fromStringToCountryOption,
  fromStringToStateOption,
  phoneNumberLengthMatch,
  toE164,
} from "ui-kit";
import {
  createAccount,
  updateEmergencyInformation,
  usePatient,
} from "../patientSession";

type EmergencyProps = Partial<StepWizardChildProps>;

export const EmergencyInformation = memo(({ previousStep }: EmergencyProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const emergencyInformation = usePatient()?.emergencyInformation;

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<EmergencyFormData>({
    defaultValues: {
      country: emergencyInformation?.country || "",
      state: emergencyInformation?.state || "",
      city: emergencyInformation?.city || "",
      address: emergencyInformation?.address,
      zipCode: emergencyInformation?.zipCode || "",
      emergencyPhone: emergencyInformation?.emergencyPhone || "",
    },
  });
  const isSavingEmergencyData = useStore(updateEmergencyInformation.pending);
  const isCreatingAccount = useStore(createAccount.pending);

  const { notifyOthersAboutLogIn } = useAuthBroadcastSignal();

  const saveEmergencyContact = (emergencyData: EmergencyFormData) => {
    updateEmergencyInformation({
      ...emergencyData,
      emergencyPhone: toE164(emergencyData.emergencyPhone),
    })
      .then(() => createAccount())
      .then((patient) => {
        const cameFromDoctorsList = location.state
          ? location.state.from.pathname === doctorsListPage
          : false;
        const nextPage = cameFromDoctorsList
          ? toTalkToDoctor(patient.id)
          : toPatientProfilHome(patient.id);
        navigate(nextPage);
        return patient;
      })
      .then(() => notifyOthersAboutLogIn())
      .catch((error: Error) => errorNotification(error.message));
  };

  return (
    <Form onSubmit={handleSubmit(saveEmergencyContact)}>
      {(isSavingEmergencyData || isCreatingAccount) && <LoaderWithOverlay />}
      <Flex alignItems="center" marginBottom={3} justifyContent="center">
        <Flex marginRight={1.5}>
          <Typography as="h2" type="h2">
            Information for emergency
          </Typography>
        </Flex>
        {/* TODO: Fix tooltip. Now it does not display text */}
        <EmergencyTooltip id="emegrency-info" clickable>
          <Typography type="h5" fontColor="N0">
            In case you feel unwell during your visit, an ambulance will be
            called to this address, as well as your family member or a friend
            will be called to report the incident.
          </Typography>
        </EmergencyTooltip>
      </Flex>
      <Block marginBottom={3}>
        <Controller
          control={control}
          name="country"
          render={({ field: { onChange, value } }) => (
            <CountrySelectbox
              isClearable
              label="Country"
              placeholder="Country"
              defaultValue={value ? fromStringToCountryOption(value) : null}
              onChange={(countryOption) =>
                onChange(countryOption ? (countryOption as Option).value : "")
              }
            />
          )}
        />
      </Block>
      <Block marginBottom={3}>
        <Controller
          control={control}
          name="state"
          render={({ field: { onChange, value } }) => (
            <StateSelectbox
              isClearable
              label="State"
              placeholder="State"
              defaultValue={value ? fromStringToStateOption(value) : null}
              onChange={(stateOption) => {
                onChange(stateOption ? (stateOption as Option).value : "");
              }}
            />
          )}
        />
      </Block>
      <Flex marginBottom={3}>
        <Block width="70%" paddingRight={2}>
          <Input
            label="City"
            placeholder="City"
            defaultValue={emergencyInformation?.city}
            {...register("city")}
          />
        </Block>
        <Block width="30%" paddingLeft={2}>
          <Input
            label="Zip code"
            placeholder="Zip code"
            defaultValue={emergencyInformation?.zipCode}
            {...register("zipCode")}
          />
        </Block>
      </Flex>
      <Block marginBottom={3}>
        <Input
          label="Address"
          placeholder="Address"
          defaultValue={emergencyInformation?.address}
          {...register("address")}
        />
      </Block>
      <Block marginBottom={4}>
        <PhoneNumberInput
          label="Phone number"
          placeholder="Phone number"
          defaultValue={emergencyInformation?.emergencyPhone}
          hasErrors={!!errors["emergencyPhone"]}
          errorMessage={errors["emergencyPhone"]?.message}
          {...register("emergencyPhone", {
            validate: {
              length: phoneNumberLengthMatch,
            },
          })}
        />
      </Block>
      <Flex alignItems="center" justifyContent="space-between">
        <LinkLabel onClick={previousStep!}>Previous step</LinkLabel>
        <Button filled type="submit">
          Create account
        </Button>
      </Flex>
    </Form>
  );
});

const Form = styled.form`
  position: relative;
  width: 100%;
`;

type LinkLabelProps = {
  children: ReactNode;
  onClick: () => void;
};

const LinkLabel = memo(({ children, onClick }: LinkLabelProps) => (
  <LinkLabelComponent type="h4" fontColor="G200" onClick={onClick}>
    <ChevronLeftIcon stroke={color.G200} />
    <LinkContent fontColor="G200" type="h4">
      {children}
    </LinkContent>
  </LinkLabelComponent>
));

const LinkLabelComponent = styled(Typography)`
  cursor: pointer;
  &:hover span,
  &:hover svg {
    color: ${color.G300};
  }
`;

const LinkContent = styled(Typography)`
  padding-left: 8px;
`;

const EmergencyTooltip = styled(Tooltip)`
  margin-top: 4px;
`;
