import { Price } from "Doctor";
import { TimeSlot } from "./Model";
import { apiServer } from "apiConnection";

/**
 * This is used for display purposes only.
 * The charged price might be in a different currency,
 * and calculated on the backend.
 */
export async function calculateTotalPrice({
  doctorId,
  timeSlot,
}: {
  doctorId: string;
  timeSlot?: TimeSlot;
  useCentsBase?: boolean;
}): Promise<Price | undefined> {
  if (!timeSlot) {
    return undefined;
  }
  const { data: calculatedPrice } = await apiServer.post<Price | undefined>(
    "/api/visits/calculate-price",
    {
      doctorId,
      timeSlot,
    }
  );
  return calculatedPrice;
}
