import styled from "@emotion/styled";
import {
  toDoctorPatientRecordCaseHistory,
  toDoctorPatientRecordConclusionsList,
  toDoctorPatientRecordFamilyDiseases,
  toDoctorPatientRecordHealthMetrics,
  toDoctorPatientRecordHome,
  toDoctorPatientRecordLabTests,
  toDoctorPatientRecordLifestyle,
  toDoctorPatientRecordMedicine,
  toDoctorPatientRecordPharmacy,
  toDoctorPatientRecordProcedures,
  toDoctorPatientRecordSurgeries,
  toDoctorPatientRecordVaccination,
  useDoctorId,
} from "Doctor/Router";
import { usePatientId } from "Patient/Router";
import { memo, ReactNode } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  Block,
  color,
  Grid,
  RecordAvatarIcon,
  RecordBandIcon,
  RecordConclusionsIcon,
  RecordFamilyIcon,
  RecordHealthMetricsIcon,
  RecordHeartbeatIcon,
  RecordLabIcon,
  RecordPharmacyIcon,
  RecordPillIcon,
  RecordRunningIcon,
  RecordSurgeryIcon,
  RecordVaccinationIcon,
  Typography,
} from "ui-kit";

export const Navigation = memo(() => {
  const location = useLocation();
  const [doctorId, patientId] = [useDoctorId(), usePatientId()];
  return (
    <Grid
      gridTemplateColumns="repeat(4, 1fr)"
      gridColumnGap={2}
      gridTemplateRows="repeat(3, 56px)"
      gridRowGap={2}
    >
      {navItemsConfig.map((item) => (
        <NavigationItem
          end
          draggable={false}
          key={item.title}
          to={item.path(doctorId, patientId)}
          state={location.state}
        >
          <Block className="icon" marginRight={2}>
            {item.icon}
          </Block>
          <Typography className="title" type="h3">
            {item.title}
          </Typography>
        </NavigationItem>
      ))}
    </Grid>
  );
});

const NavigationItem = styled(NavLink)`
  align-items: center;
  background-color: ${color.N0};
  border: 1px solid ${color.N200};
  border-radius: 8px;
  display: flex;
  padding: 12px 24px;
  text-decoration: none;
  &.active,
  &:hover,
  &:focus {
    border-color: ${color.G200};
  }
  &.active .icon svg,
  &:hover .icon svg,
  &:focus .icon svg {
    fill: ${color.G200};
  }
  &.active .title,
  &:hover .title,
  &:focus .title {
    color: ${color.G200};
  }
`;

type NavItemConfig = {
  icon: ReactNode;
  path: (doctorId: string, patientId: string) => string;
  title: string;
};

const navItemsConfig: NavItemConfig[] = [
  {
    icon: <RecordAvatarIcon fill={color.N300} />,
    path: (doctorId: string, patientId: string) =>
      toDoctorPatientRecordHome({ doctorId, patientId }),
    title: "Personal data",
  },
  {
    icon: <RecordHeartbeatIcon fill={color.N300} />,
    path: (doctorId: string, patientId: string) =>
      toDoctorPatientRecordCaseHistory({ doctorId, patientId }),
    title: "Case history",
  },
  {
    icon: <RecordPillIcon fill={color.N300} />,
    path: (doctorId: string, patientId: string) =>
      toDoctorPatientRecordMedicine({ doctorId, patientId }),
    title: "Medicine",
  },
  {
    icon: <RecordLabIcon fill={color.N300} />,
    path: (doctorId: string, patientId: string) =>
      toDoctorPatientRecordLabTests({ doctorId, patientId }),
    title: "Lab tests",
  },
  {
    icon: <RecordHealthMetricsIcon fill={color.N300} />,
    path: (doctorId: string, patientId: string) =>
      toDoctorPatientRecordHealthMetrics({ doctorId, patientId }),
    title: "Health metrics",
  },
  {
    icon: <RecordFamilyIcon fill={color.N300} />,
    path: (doctorId: string, patientId: string) =>
      toDoctorPatientRecordFamilyDiseases({ doctorId, patientId }),
    title: "Family diseases",
  },
  {
    icon: <RecordVaccinationIcon fill={color.N300} />,
    path: (doctorId: string, patientId: string) =>
      toDoctorPatientRecordVaccination({ doctorId, patientId }),
    title: "Vaccination",
  },
  {
    icon: <RecordPharmacyIcon fill={color.N300} />,
    path: (doctorId: string, patientId: string) =>
      toDoctorPatientRecordPharmacy({ doctorId, patientId }),
    title: "Pharmacy",
  },
  {
    icon: <RecordRunningIcon fill={color.N300} />,
    path: (doctorId: string, patientId: string) =>
      toDoctorPatientRecordLifestyle({ doctorId, patientId }),
    title: "Lifestyle",
  },
  {
    icon: <RecordSurgeryIcon fill={color.N300} />,
    path: (doctorId: string, patientId: string) =>
      toDoctorPatientRecordSurgeries({ doctorId, patientId }),
    title: "Surgeries",
  },
  {
    icon: <RecordBandIcon fill={color.N300} />,
    path: (doctorId: string, patientId: string) =>
      toDoctorPatientRecordProcedures({ doctorId, patientId }),
    title: "Procedures",
  },
  {
    icon: <RecordConclusionsIcon fill={color.N300} />,
    path: (doctorId: string, patientId: string) =>
      toDoctorPatientRecordConclusionsList({ doctorId, patientId }),
    title: "Conclusions",
  },
];
