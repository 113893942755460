import { apiServer } from "apiConnection";
import { ISO8601 } from "Doctor/Model";
import { createEffect, createStore } from "effector";

const NOTIFICATION_TYPE = {
  general: "general",
  moderation: "moderation",
  accountCreated: "accountCreated",
  personalInfoUpdated: "personalInfoUpdated",
  emailUpdated: "emailUpdated",
  passwordUpdated: "passwordUpdated",
  visitScheduled: "visitScheduled",
  visitStartsSoon: "visitStartsSoon",
  visitMissed: "visitMissed",
};

export type Notification = {
  objectId: string;
  type: keyof typeof NOTIFICATION_TYPE;
  isRead: boolean;
  payload?: unknown;
  dateTime: {
    iso: ISO8601;
  };
};

export type NewVisitPayload = {
  timeFrom: ISO8601;
  timeTo: ISO8601;
};

export const loadNotifications = createEffect({
  name: "loadNotifications",
  handler: loadNotificationsForCurrentUser,
});

export const removeNotification = createEffect({
  name: "removeNotification",
  handler: (notificationId: string) =>
    apiServer.delete<void>(`/api/notifications/${notificationId}`),
});

export const notificationsCenter = createStore<Notification[]>([])
  .on(loadNotifications.doneData, (state, notifications) => notifications)
  .on(removeNotification.done, (notifications, { params: notificationId }) =>
    notifications.filter((n) => n.objectId !== notificationId)
  );

async function loadNotificationsForCurrentUser() {
  const { data: notifications } = await apiServer.get<Notification[]>(
    "/api/notifications"
  );
  return notifications;
}

export const markNotificationsAsRead = createEffect({
  name: "markNotificationsAsRead",
  handler: (notificationsId: string[]) =>
    apiServer.post<string[]>("/api/notifications/read", {
      notificationsId,
    }),
});

export const checkUnreadNotifications = createEffect({
  name: "checkUnreadNotifications",
  handler: async () => {
    const {
      data: { amount },
    } = await apiServer.get<{ amount: number }>("/api/notifications/unread");
    return amount;
  },
});

export const $notificationsWatcher = createStore<{
  unreadNotificationsAmount?: number;
}>({})
  .on(checkUnreadNotifications.doneData, (state, amount) => ({
    unreadNotificationsAmount: amount,
  }))
  .reset(markNotificationsAsRead.done);
