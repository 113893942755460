import { apiServer } from "apiConnection";
import { AxiosError } from "axios";
import { initializeDoctorFromSession } from "Doctor/Auth/doctorSession";
import { DoctorDTO, ROLE_DOCTOR } from "Doctor/Model";
import { createEffect, Event, guard, restore } from "effector";
import { initializePatientFromSession } from "Patient/Auth/patientSession";
import { PatientDTO, ROLE_PATIENT } from "Patient/Model";
import { useEffect, useState } from "react";
import { errorNotification } from "ui-kit";

export const loadSession = createEffect<
  void,
  DoctorDTO | PatientDTO | undefined,
  Error
>("loadSession", {
  handler: async () => {
    const { data: user } = await apiServer.get<DoctorDTO | PatientDTO | null>(
      "/api/session"
    );
    if (!user) {
      return undefined;
    }
    return user;
  },
});

guard({
  source: restore(loadSession, null),
  filter: (userSession) => {
    return userSession ? userSession.role === ROLE_DOCTOR : false;
  },
  target: initializeDoctorFromSession as Event<DoctorDTO | undefined>,
});

guard({
  source: restore(loadSession, null),
  filter: (userSession) => {
    return userSession ? userSession.role === ROLE_PATIENT : false;
  },
  target: initializePatientFromSession as Event<PatientDTO | undefined>,
});

loadSession.failData.watch((error) => {
  const errorPayload = (error as AxiosError<{ message: string; code: number }>)
    .response?.data;
  if (errorPayload?.message) {
    errorNotification(errorPayload?.message);
  }
});

// This feature is for the edge case when session is expired,
// or session token is not valid.
// Session can be deleted on the server, or session token can be corrupted in cookies.
// UI should respond to such changes
export function useSessionHealthCheck() {
  const [isSessionTokenValid, setTokenValidity] = useState<boolean>();

  useEffect(() => {
    apiServer
      .get<void>("/api/session/health")
      .then(() => {
        return setTokenValidity(true);
      })
      .catch(() => {
        setTokenValidity(false);
      });
  });

  return isSessionTokenValid;
}
