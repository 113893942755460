import styled from "@emotion/styled";
import { format, parse, subYears } from "date-fns";
import { parseISO } from "date-fns/esm";
import { Language } from "Doctor/Model";
import { loadSpecialties } from "Doctor/requests";
import { useStore } from "effector-react";
import { memo, ReactNode, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { StepWizardChildProps } from "react-step-wizard";
import { debouncePromise } from "shared-functions/delay";
import {
  AsyncSelectbox,
  Block,
  Button,
  ChevronLeftIcon,
  color,
  Flex,
  fromStringToOption,
  Input,
  LoaderWithOverlay,
  Option,
  Selectbox,
  Typography,
} from "ui-kit";
import { updateProfessionalInformation, useDoctor } from "../doctorSession";

const experienceFieldsNames = {
  specialty: "specialty",
  practiceStarted: "practiceStarted",
  language: "language",
  medicalSchool: "medicalSchool",
  degree: "degree",
  graduationYear: "graduationYear",
};

type ExperienceData = {
  specialty: string;
  practiceStarted: string;
  language: Language[];
  medicalSchool?: string;
  degree?: string;
  graduationYear?: string;
};

const languageOptions: Option[] = [
  { value: "en", label: "English" },
  { value: "es", label: "Español" },
  { value: "zh", label: "中文" },
  { value: "de", label: "Deutsch" },
  { value: "fr", label: "Français" },
  { value: "ru", label: "Русский" },
];

const loadSpecialtiesOptions = (searchInput: string) => {
  return loadSpecialties(searchInput)
    .then((specialties) => specialties.map((sp) => fromStringToOption(sp)))
    .catch((error) => {
      console.error(error);
      return [];
    });
};

function experienceOptions(): Option[] {
  const YEARS_OF_EXPERIENCE_LIMIT = 50;
  return new Array(YEARS_OF_EXPERIENCE_LIMIT)
    .fill(undefined)
    .map((_, index) => {
      const experienceYear = format(subYears(Date.nowUniversal, index), "yyyy");
      return {
        label: experienceYear,
        value: experienceYear,
      };
    });
}

type Props = Partial<StepWizardChildProps>;

export const ProfessionalInformationForm = memo(
  ({ nextStep, currentStep, previousStep, totalSteps }: Props) => {
    const doctor = useDoctor();
    const {
      control,
      handleSubmit,
      clearErrors,
      formState: { errors },
      register,
      watch,
    } = useForm<ExperienceData>({
      defaultValues: {
        [experienceFieldsNames.specialty]:
          doctor?.professionalInformation?.specialty || null,
        [experienceFieldsNames.practiceStarted]: doctor?.professionalInformation
          ?.practiceStarted
          ? format(
              parseISO(doctor.professionalInformation.practiceStarted),
              "yyyy"
            )
          : null,
        [experienceFieldsNames.language]:
          doctor?.professionalInformation?.language || null,
        [experienceFieldsNames.medicalSchool]:
          doctor?.professionalInformation?.education.medicalSchool || null,
        [experienceFieldsNames.degree]:
          doctor?.professionalInformation?.education.degree || null,
        [experienceFieldsNames.graduationYear]:
          doctor?.professionalInformation?.education.graduationYear || null,
      },
    });

    const saveExperienceInformation = ({
      specialty,
      practiceStarted,
      language,
      medicalSchool,
      degree,
      graduationYear,
    }: ExperienceData) => {
      updateProfessionalInformation({
        specialty,
        practiceStarted: parse(
          practiceStarted,
          "yyyy",
          Date.nowUniversal
        ).toISOString(),
        language,
        education: {
          medicalSchool,
          degree,
          graduationYear,
        },
      }).then(nextStep);
    };

    const graduationYear = watch("graduationYear");
    const practiceStarted = watch("practiceStarted");

    useEffect(() => {
      if (
        graduationYear &&
        practiceStarted &&
        practiceStarted > graduationYear
      ) {
        clearErrors("graduationYear");
        clearErrors("practiceStarted");
      }
    }, [clearErrors, graduationYear, practiceStarted]);

    const isUpdating = useStore(updateProfessionalInformation.pending);

    return (
      <Form onSubmit={handleSubmit(saveExperienceInformation)}>
        {isUpdating && <LoaderWithOverlay />}
        <Block marginBottom={3} textAlign="center">
          <Typography as="h2" type="h2">
            Professional information
          </Typography>
        </Block>
        <Block marginBottom={3}>
          <Controller
            control={control}
            name="specialty"
            rules={{
              required: "Please, select a specilaty",
            }}
            render={({
              field: { onChange, value: specialty },
              fieldState: { error },
            }) => (
              <AsyncSelectbox
                loadOptions={debouncePromise(loadSpecialtiesOptions, 1000)}
                defaultOptions
                hasErrors={error !== undefined}
                errorMessage={error}
                placeholder="Specialty"
                label="Specialty"
                isRequired
                noOptionsMessage={() => "No specialties"}
                onChange={(specialtyOption) =>
                  onChange(
                    specialtyOption ? (specialtyOption as Option).value : ""
                  )
                }
                defaultValue={specialty ? fromStringToOption(specialty) : null}
              />
            )}
          />
        </Block>
        <Block marginBottom={3}>
          <Controller
            control={control}
            name="practiceStarted"
            rules={{
              required: "Please, select a start practice year",
              validate: (practiceStarted) => {
                if (graduationYear && practiceStarted < graduationYear) {
                  return "Start of practice cannot be earlier than graduation year";
                }
                return true;
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Selectbox
                isClearable
                hasErrors={!!error}
                errorMessage={error}
                options={experienceOptions()}
                placeholder="Year"
                label="Start of practice"
                noOptionsMessage={() => "Out of range"}
                isRequired
                onChange={(experienceOption) =>
                  onChange(
                    experienceOption ? (experienceOption as Option).value : ""
                  )
                }
                defaultValue={
                  value ? fromStringToOption(value, experienceOptions()) : null
                }
              />
            )}
          />
        </Block>
        <Block marginBottom={3}>
          <Controller
            control={control}
            name="language"
            rules={{ required: "Please, select a language" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Selectbox
                closeMenuOnSelect={false}
                isMulti
                isClearable={false}
                hasErrors={!!error}
                errorMessage={error}
                options={languageOptions}
                placeholder="Language"
                label="Language"
                isRequired
                noOptionsMessage={() => "No languages"}
                onChange={(languageOptions) =>
                  languageOptions
                    ? onChange(
                        (languageOptions as Option[]).map(
                          (language) => language.value as Language
                        )
                      )
                    : ""
                }
                defaultValue={
                  value
                    ? value.map((v: Language) =>
                        fromStringToOption(v, languageOptions)
                      )
                    : null
                }
              />
            )}
          />
        </Block>
        <Block marginBottom={3}>
          <Input
            defaultValue={
              doctor?.professionalInformation?.education.medicalSchool
            }
            errorMessage={errors["medicalSchool"]?.message}
            hasErrors={!!errors["medicalSchool"]}
            label="University or college"
            placeholder="University or college"
            isRequired
            {...register("medicalSchool", {
              required:
                "Please, add the university or college you graduated from",
            })}
          />
        </Block>
        <Flex marginBottom={7}>
          <Block marginRight={1.5} width="50%">
            <Input
              defaultValue={doctor?.professionalInformation?.education.degree}
              errorMessage={errors["degree"]?.message}
              hasErrors={!!errors["degree"]}
              label="Degree"
              placeholder="Degree"
              isRequired
              {...register("degree", {
                required: "Please, specify your degree",
              })}
            />
          </Block>
          <Block marginLeft={1.5} width="50%">
            <Input
              defaultValue={
                doctor?.professionalInformation?.education.graduationYear
              }
              errorMessage={errors["graduationYear"]?.message}
              hasErrors={!!errors["graduationYear"]}
              label="Graduation year"
              isRequired
              placeholder="Graduation year"
              {...register("graduationYear", {
                required: "Please, enter a graduation year, e.g. 2005",
                validate: (graduationYear?: string) => {
                  if (graduationYear && isValidYear(graduationYear)) {
                    if (graduationYear > practiceStarted) {
                      return "Graduation year cannot be after start of practice";
                    }
                    return true;
                  } else {
                    return "Please, enter a valid year, e.g. 2005";
                  }
                },
              })}
            />
          </Block>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between">
          <LinkLabel onClick={previousStep!}>Previous step</LinkLabel>
          <Button filled type="submit">
            Next step {currentStep}/{totalSteps}
          </Button>
        </Flex>
      </Form>
    );
  }
);

function isValidYear(year: string) {
  const yearNumber = Number(year);
  if (!Number.isInteger(yearNumber) || yearNumber < 0) {
    return false;
  }
  return true;
}

const Form = styled.form`
  position: relative;
  width: 100%;
`;

type LinkLabelProps = {
  children: ReactNode;
  onClick: () => void;
};

const LinkLabel = memo(({ children, onClick }: LinkLabelProps) => (
  <LinkLabelComponent type="h4" fontColor="G200" onClick={onClick}>
    <ChevronLeftIcon stroke={color.G200} />
    <LinkContent fontColor="G200" type="h4">
      {children}
    </LinkContent>
  </LinkLabelComponent>
));

const LinkLabelComponent = styled(Typography)`
  cursor: pointer;
  &:hover span,
  &:hover svg {
    color: ${color.G300};
  }
`;

const LinkContent = styled(Typography)`
  padding-left: 8px;
`;
