import styled from "@emotion/styled";
import { differenceInMinutes, format, parseISO } from "date-fns";
import { useStore } from "effector-react";
import {
  loadVaccination,
  vaccination as $vaccination,
  VaccineDTO,
} from "Patient/Record";
import { memo, useEffect, useState } from "react";
import { Block, color, Flex, RecordVaccinationIcon, Typography } from "ui-kit";
import { EmptyStateContainer, EmptyStateIcon } from "./EmptyState";
import { LoaderWithSpacing } from "./Loader";

type Props = {
  patientId: string;
};

export const VaccinationPage = memo(({ patientId }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const vaccination = useStore($vaccination);

  useEffect(() => {
    loadVaccination(patientId).finally(() => setIsLoading(false));
  }, [patientId]);

  if (isLoading) {
    return <LoaderWithSpacing />;
  }

  if (vaccination.length === 0) {
    return (
      <EmptyStateContainer>
        <Flex justifyContent="center" marginBottom={4}>
          <EmptyStateIcon>
            <RecordVaccinationIcon width="86px" height="86px" />
          </EmptyStateIcon>
        </Flex>
        <Block marginBottom={1}>
          <Typography fontColor="N300" type="h2">
            Not vaccinated
          </Typography>
        </Block>
        <Block marginBottom={4}>
          <Typography fontColor="N300" fontWeight={400} type="h3">
            Vaccines the patient had will be displayed here
          </Typography>
        </Block>
      </EmptyStateContainer>
    );
  }

  return (
    <Block marginTop={3} marginBottom={6} paddingLeft={5} paddingRight={5}>
      {vaccination.sort(newestOnTop).map((record) => (
        <VaccineRecordContainer key={record.id}>
          <Block marginBottom={1}>
            <Typography as="h4" type="h2">
              {record.name}
            </Typography>
          </Block>
          <Block marginBottom={1}>
            <Typography fontColor="N300" type="h4">
              When been given: {format(parseISO(record.date), "MM/dd/yyyy")}
            </Typography>
          </Block>
          <Block>
            <Typography fontWeight={400} type="h3">
              {record.description}
            </Typography>
          </Block>
        </VaccineRecordContainer>
      ))}
    </Block>
  );
});

function newestOnTop(vaccination1: VaccineDTO, vaccination2: VaccineDTO) {
  return differenceInMinutes(
    new Date(vaccination2.date),
    new Date(vaccination1.date)
  );
}

const VaccineRecordContainer = styled.section`
  border-bottom: 1px solid ${color.N200};
  margin-bottom: 32px;
  padding-bottom: 32px;
  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;
