import styled from "@emotion/styled";
import { memo, MouseEventHandler, ReactNode, useCallback } from "react";

type Props = {
  readonly: boolean;
  activeIcon: ReactNode;
  inactiveIcon: ReactNode;
  percent: number;
  onClick?: MouseEventHandler<HTMLSpanElement>;
  onMouseMove?: MouseEventHandler<HTMLSpanElement>;
};

export const Symbol = memo(
  ({
    readonly,
    activeIcon,
    inactiveIcon,
    percent,
    onClick,
    onMouseMove,
  }: Props) => {
    const showbgIcon = percent < 100;
    const handleMouseMove: MouseEventHandler<HTMLSpanElement> = useCallback(
      (e) => {
        if (onMouseMove) {
          onMouseMove(e);
        }
      },
      [onMouseMove]
    );
    const handleClick: MouseEventHandler<HTMLSpanElement> = useCallback(
      (e) => {
        if (onClick) {
          e.preventDefault();
          onClick(e);
        }
      },
      [onClick]
    );

    return (
      <SymbolContainer
        readonly={readonly}
        onClick={handleClick}
        onMouseMove={handleMouseMove}
      >
        <BackgroundNode isVisible={showbgIcon}>{inactiveIcon}</BackgroundNode>
        <ForegroundNode width={percent}>{activeIcon}</ForegroundNode>
      </SymbolContainer>
    );
  }
);

type ContainerProps = {
  readonly: boolean;
};

const SymbolContainer = styled.span<ContainerProps>`
  cursor: ${({ readonly }) => (readonly ? "inherit" : "pointer")};
  display: inline-block;
  position: relative;
`;

type BackgroundNodeProps = {
  isVisible: boolean;
};

const BackgroundNode = styled.span<BackgroundNodeProps>`
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;

type ForegroundNodeProps = {
  width: number;
};

const ForegroundNode = styled.span<ForegroundNodeProps>`
  display: inline-block;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: ${({ width }) => width + "%"};
`;
