import { isFuture, isValid, parse } from "date-fns";
import {
  ChangeEvent,
  ElementType,
  forwardRef,
  HTMLProps,
  Ref,
  useMemo,
  useState,
} from "react";
import InputWithMask, { ReactInputMask } from "react-input-mask";
import { v4 as uuidv4 } from "uuid";
import { ErrorMessage } from "../ErrorMessage";
import { Block } from "../Layout";
import { InputElement, Label } from "./PureInput";

type Props = HTMLProps<HTMLInputElement> & {
  errorMessage?: string;
  hasErrors?: boolean;
  hasValue?: boolean;
};

export const ExpirationDateInput = forwardRef(
  (props: Props, refObject: Ref<ReactInputMask>) => (
    <ExpirationDateInputComponent {...props} ref={null} refObject={refObject} />
  )
);

type CardInputProps = Props & {
  refObject?: Ref<ReactInputMask>;
};

const ExpirationDateInputComponent = ({
  defaultValue,
  label,
  hasErrors,
  errorMessage,
  hasValue,
  onChange,
  refObject,
  ...props
}: CardInputProps) => {
  const [inputValue, setInputValue] = useState(
    (defaultValue as string | undefined) || ""
  );
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    onChange && onChange(e);
  };
  const id = useMemo(() => uuidv4(), []);
  return (
    <>
      <Block position="relative">
        {label && (
          <Block marginBottom={0.5}>
            <Label htmlFor={id}>{label}</Label>
          </Block>
        )}
        <MaskedInput
          {...props}
          as={props.as as ElementType | undefined}
          ref={refObject}
          hasValue={clearMask(inputValue).length > 0}
          hasErrors={hasErrors}
          mask="99/9999"
          onChange={onInputChange}
          placeholder="month/year"
          type="text"
          value={inputValue}
        />
      </Block>
      {errorMessage && (
        <Block>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </Block>
      )}
    </>
  );
};

const MaskedInput = InputElement.withComponent(InputWithMask);

/**
 * Validators
 */

export function isFormatMatched(expirationDate: string) {
  // skip format check in case of empty value
  if (!clearMask(expirationDate)) {
    return true;
  }
  return (
    isValid(parse(expirationDate, "MM/yyyy", Date.nowUniversal)) ||
    "Date should be in the format month/year"
  );
}

export function restrictPast(expirationDate: string) {
  // skip format check in case of empty value
  if (!clearMask(expirationDate)) {
    return "Enter expiration date";
  }
  return (
    isFuture(parse(expirationDate, "MM/yyyy", Date.nowUniversal)) ||
    "Please, add an unexpired card"
  );
}

function clearMask(dateWithMask?: string) {
  if (!dateWithMask) {
    return "";
  }
  return dateWithMask.replace(/[/_\s]/g, "");
}
