import { apiServer } from "apiConnection";
import { AxiosError } from "axios";
import { DoctorSearchParameters } from "OurDoctors/search";
import { DoctorDTO, Model as Doctor, userToDoctorAdapter } from "./Model";

/**
 * Note: this request not used anywhere. Just keep it for potential usage.
 * */
export async function fetchDoctorById(doctorId: string) {
  try {
    const { data: doctorDTO } = await apiServer.get<DoctorDTO>(
      `/api/doctor/${doctorId}`
    );
    return userToDoctorAdapter(doctorDTO);
  } catch (error: unknown) {
    const message = (error as AxiosError<{ message: string }>).response?.data
      .message;
    throw new Error(message);
  }
}

export async function fetchDoctors(
  parameters: void | DoctorSearchParameters
): Promise<Doctor[]> {
  try {
    const doctorsList = await apiServer.post<DoctorDTO[]>(
      "/api/doctor/list",
      parameters
    );
    return doctorsList.data.map(userToDoctorAdapter);
  } catch (error: unknown) {
    const message = (error as AxiosError<{ message: string }>).response?.data
      .message;
    throw new Error(message);
  }
}

/**
 * Specialties are stored on BE and there is the source of thruth
 */
export async function loadSpecialties(searchInput?: string): Promise<string[]> {
  try {
    const { data: specialties } = await apiServer.get<string[]>(
      "/api/doctor/specialties",
      {
        params: {
          input: searchInput,
        },
      }
    );
    return specialties;
  } catch (error: unknown) {
    const message = (error as AxiosError<{ message: string }>).response?.data
      .message;
    throw new Error(message);
  }
}
