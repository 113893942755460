import styled from "@emotion/styled";
import { differenceInYears, format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useDoctor } from "Doctor/Auth";
import { memo } from "react";
import { useFormContext } from "react-hook-form";
import { getCurrentTimezoneIANA } from "shared-functions/date";
import { checkSizeBelowLimit, useImageData } from "shared-functions/file";
import {
  AddPhotoIcon,
  Avatar,
  Block,
  DateInput,
  errorNotification,
  Input,
  isFormatMatched,
  maskedInputToDate,
  PhoneNumberInput,
  phoneNumberLengthMatch,
  Textarea,
  Typography,
} from "ui-kit";

export const PersonalInformationForm = memo(() => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const doctorInSession = useDoctor();
  const personalInformation = doctorInSession?.personalInformation;
  const defaultDOB = personalInformation?.DOB
    ? format(
        utcToZonedTime(personalInformation.DOB, getCurrentTimezoneIANA()),
        "MM/dd/yyyy"
      )
    : undefined;

  const { preview: avatarPreview, onChange: changePreview } = useImageData(
    personalInformation?.photo?.url
  );
  const { onChange: onPhotoChange, ...photoFormProps } = register("photo");

  return (
    <>
      <Block textAlign="center" marginBottom={3}>
        <Typography as="h2" type="h2" fontColor="N300">
          Personal data
        </Typography>
      </Block>
      <Block textAlign="center">
        <input
          accept="image/*"
          id="add-photo"
          type="file"
          hidden
          onChange={(e) => {
            const updatedPhoto = e.target.files?.item(0);
            if (checkSizeBelowLimit({ file: updatedPhoto, limitMB: 15 })) {
              changePreview(updatedPhoto ?? null);
              onPhotoChange(e);
            } else {
              errorNotification("Please, add photo under 15MB");
            }
          }}
          {...photoFormProps}
        />
        <AddPhotoLabel htmlFor="add-photo">
          {avatarPreview ? (
            <AvatarImage width="140px" height="140px">
              <img src={avatarPreview} alt="Doctor avatar" />
            </AvatarImage>
          ) : (
            <AddPhotoIcon />
          )}
        </AddPhotoLabel>
      </Block>
      <Block marginBottom={3}>
        <Input
          defaultValue={personalInformation?.firstName}
          errorMessage={errors["firstName"]?.message}
          hasErrors={!!errors["lastName"]}
          label="First name"
          placeholder="First name"
          isRequired
          {...register("firstName", {
            required: "Please,enter your first name",
          })}
        />
      </Block>
      <Block marginBottom={3}>
        <Input
          defaultValue={personalInformation?.lastName}
          errorMessage={errors["lastName"]?.message}
          hasErrors={!!errors["lastName"]}
          label="Last name"
          placeholder="Last name"
          isRequired
          {...register("lastName", {
            required: "Please, enter your last name",
          })}
        />
      </Block>
      <Block marginBottom={3}>
        <DateInput
          defaultValue={defaultDOB}
          errorMessage={errors["DOB"]?.message}
          hasErrors={!!errors["DOB"]}
          label="Date of birth"
          isRequired
          {...register("DOB", {
            required: "Please, enter date of birth",
            validate: {
              format: isFormatMatched,
              validateAdult,
            },
          })}
        />
      </Block>
      <Block marginBottom={3}>
        <PhoneNumberInput
          label="Phone number (SMS)"
          placeholder="Phone number"
          defaultValue={personalInformation?.phone}
          hasErrors={!!errors["phone"]}
          errorMessage={errors["phone"]?.message}
          {...register("phone", {
            validate: {
              length: phoneNumberLengthMatch,
            },
          })}
        />
      </Block>
      <Block>
        <Textarea
          defaultValue={personalInformation?.about}
          errorMessage={errors["about"]?.message}
          hasErrors={!!errors["about"]}
          maxLength={500}
          label="About yourself"
          placeholder="Describe your history of healthcare practice"
          isRequired
          {...register("about", {
            required: "Please, describe your history of healthcare practice",
          })}
        />
      </Block>
    </>
  );
});

function validateAdult(dob: string) {
  // DOB can be an empty string
  const dobAsDate = maskedInputToDate(dob);
  const isYoungerThan18 = dobAsDate
    ? differenceInYears(Date.nowUniversal, dobAsDate) < 18
    : false;
  if (isYoungerThan18) {
    return "According to HIPAA, doctor can't be under 18 years of age";
  }
  return true;
}

const AddPhotoLabel = styled.label`
  cursor: pointer;
  display: inline-block;
`;

const AvatarImage = styled(Avatar)`
  display: inline-block;
  margin: 16px auto 22px;
`.withComponent("span");
