import styled from "@emotion/styled";
import {
  memo,
  MouseEvent,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from "react";
import { Symbol } from "./Symbol";

type Props = {
  totalSymbols: number;
  value: number;
  readonly: boolean;
  emptySymbol: ReactNode;
  fullSymbol: ReactNode;
  fractions: number;
  className?: string;
  onClick: (displayValue: number) => void;
  onHover: (displayValue?: number) => void;
};

export const Rating = memo(
  ({
    totalSymbols,
    value,
    readonly,
    emptySymbol,
    fullSymbol,
    fractions,
    className,
    onClick,
    onHover,
  }: Props) => {
    // Indicates the value that is displayed to the user in the form of symbols.
    // It can be either 0 (for no displayed symbols) or (0, end]
    const [displayValue, setDisplayValue] = useState(value);

    // Indicates if the user is currently hovering over the rating element

    const [interacting, setInteraction] = useState(false);
    const prevInteractionState = useRef<boolean>(interacting);

    // When hover ends, call onHover() with no value to display previously selected value
    if (prevInteractionState.current && !interacting) {
      onHover();
      prevInteractionState.current = interacting;
    }

    // TODO: When hover over.
    // Hover in should only be emitted while we are hovering (interacting),
    // unless we changed the value, usually originated by an onClick event.
    // We do not want to emit a hover in event again on the clicked symbol.

    const calculateHoverPercentage = useCallback(
      (event: MouseEvent<HTMLSpanElement>) => {
        const clientX = event.clientX;

        const targetRect = event.currentTarget.getBoundingClientRect();
        const delta = clientX - targetRect.left;

        // Returning 0 if the delta is negative solves the flickering issue
        return delta < 0 ? 0 : delta / targetRect.width;
      },
      []
    );

    const calculateDisplayValue = useCallback(
      (index: number, event: MouseEvent<HTMLSpanElement>) => {
        const percentage = calculateHoverPercentage(event);
        // Get the closest top fraction.
        const fraction = Math.ceil((percentage % 1) * fractions) / fractions;
        // Truncate decimal trying to avoid float precission issues.
        const precision = 10 ** 3;
        const displayValue =
          index +
          (Math.floor(percentage) +
            Math.floor(fraction * precision) / precision);
        // ensure the returned value is greater than 0 and lower than totalSymbols
        return displayValue > 0
          ? displayValue > totalSymbols
            ? totalSymbols
            : displayValue
          : 1 / fractions;
      },
      [fractions, totalSymbols, calculateHoverPercentage]
    );

    const symbolClick = useCallback(
      (index: number, event: MouseEvent<HTMLSpanElement>) => {
        onClick(calculateDisplayValue(index, event));
      },
      [calculateDisplayValue, onClick]
    );

    const symbolMouseMove = useCallback(
      (index: number, event: MouseEvent<HTMLSpanElement>) => {
        setInteraction(!readonly);
        setDisplayValue(calculateDisplayValue(index, event));
      },
      [calculateDisplayValue, readonly]
    );

    const onMouseLeave = useCallback(() => {
      setDisplayValue(value);
      setInteraction(false);
    }, [value]);

    // The amount of full symbols
    const fullSymbols = Math.floor(displayValue);
    const symbolNodes = [];
    const empty = [emptySymbol];
    const full = [fullSymbol];

    for (let index = 0; index < totalSymbols; index++) {
      let percent;
      // Calculate each symbol's fullness percentage
      if (index - fullSymbols < 0) {
        percent = 100;
      } else if (index - fullSymbols === 0) {
        // current symbol is filled partially
        percent = (displayValue - index) * 100;
      } else {
        // current symbol is not part of the calculated rating
        percent = 0;
      }

      symbolNodes.push(
        <Symbol
          key={index}
          readonly={readonly}
          activeIcon={full[index % full.length]}
          inactiveIcon={empty[index % empty.length]}
          percent={percent}
          {...(readonly
            ? {}
            : {
                onClick: (event) => symbolClick(index, event),
                onMouseMove: (event) => symbolMouseMove(index, event),
              })}
        />
      );
    }

    return (
      <RatingContainer
        className={className}
        {...(readonly ? {} : { onMouseLeave })}
      >
        {symbolNodes}
      </RatingContainer>
    );
  }
);

const RatingContainer = styled.span`
  display: inline-block;
`;
