import {
  ChangeEvent,
  ElementType,
  forwardRef,
  HTMLProps,
  memo,
  ReactNode,
  Ref,
  useMemo,
  useState,
} from "react";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import InputWithMask, { ReactInputMask } from "react-input-mask";
import { Typography } from "ui-kit";
import { v4 as uuidv4 } from "uuid";
import { ErrorMessage } from "../ErrorMessage";
import { Block } from "../Layout";
import { InputElement, Label } from "./PureInput";

type InputProps = HTMLProps<HTMLInputElement> & {
  errorMessage?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | string;
  icon?: ReactNode;
  hasErrors?: boolean;
  hasValue?: boolean;
  optional?: boolean;
};

export const PhoneNumberInput = forwardRef(
  (props: InputProps, refObject: Ref<ReactInputMask>) => (
    <PhoneNumberInputComponent {...props} ref={null} refObject={refObject} />
  )
);

type COUNTRY_ALPHA_3 = "USA";

type PhoneInputProps = InputProps & {
  refObject?: Ref<ReactInputMask>;
  country?: COUNTRY_ALPHA_3;
};

const PhoneNumberInputComponent = memo(
  ({
    type = "text",
    className,
    label,
    errorMessage,
    hasErrors,
    disabled,
    refObject,
    onChange,
    country = "USA",
    defaultValue,
    optional,
    ...props
  }: PhoneInputProps) => {
    const [inputValue, setInputValue] = useState(
      E164WithoutCountryCode(country, defaultValue as string | undefined)
    );
    const { mask } = getPhoneNumberFormat(country);
    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      const phoneWithoutCountryCode =
        E164WithoutCountryCode(country, e.target.value) || "";
      setInputValue(phoneWithoutCountryCode);
      onChange && onChange(e);
    };
    const [inFocus, toggleFocus] = useState(false);
    const id = useMemo(() => uuidv4(), []);

    return (
      <>
        <Block position="relative">
          {label && (
            <Block marginBottom={0.5}>
              <Label htmlFor={id}>
                {label}
                {optional && (
                  <Typography
                    lineHeight="0"
                    fontColor="N300"
                    fontStyle="italic"
                    type="h6"
                  >
                    &nbsp;(Optional)
                  </Typography>
                )}
              </Label>
            </Block>
          )}
          <PhoneInputElement
            {...props}
            as={props.as as ElementType<any> | undefined}
            ref={refObject}
            mask={mask}
            id={id}
            alwaysShowMask
            onChange={onInputChange}
            type="tel"
            className={className}
            disabled={disabled}
            defaultValue={E164WithoutCountryCode(
              country,
              defaultValue as string | undefined
            )}
            hasErrors={hasErrors}
            hasValue={!!inputValue && inputValue.length > 0}
            inFocus={inFocus}
            onFocus={() => toggleFocus(true)}
            onBlur={() => toggleFocus(false)}
          />
        </Block>
        {errorMessage && (
          <Block>
            <ErrorMessage>{errorMessage.toString()}</ErrorMessage>
          </Block>
        )}
      </>
    );
  }
);

const PhoneInputElement = InputElement.withComponent(InputWithMask);

const COUNTRY_CODES: {
  [country in COUNTRY_ALPHA_3]: string;
} = {
  USA: "+1",
};

function getPhoneNumberFormat(country: COUNTRY_ALPHA_3 = "USA") {
  const format = {
    mask: "+999999999999999",
    length: 15,
  };
  if (country === "USA") {
    format.mask = `${COUNTRY_CODES["USA"]} 999-999-9999`;
    format.length = 10;
  }
  return format;
}

export function toE164(phone?: string, country: COUNTRY_ALPHA_3 = "USA") {
  if (!(phone && E164WithoutCountryCode(country, phone))) {
    return undefined;
  }
  return phone.replace(/[_-\s]/g, "");
}

function E164WithoutCountryCode(
  country: COUNTRY_ALPHA_3 = "USA",
  phone?: string
) {
  if (!phone || phone === COUNTRY_CODES[country]) {
    return undefined;
  }

  return phone.replace(COUNTRY_CODES[country], "").replace(/[_\-\s]/g, "");
}

/**
 * Validators
 */
export function lengthMatch(number?: string) {
  const phoneE164 = E164WithoutCountryCode(undefined, number);
  const { length } = getPhoneNumberFormat();
  // empty numbers considered valid length
  if (!phoneE164) {
    return true;
  }
  return phoneE164.length === length
    ? true
    : `Please, enter ${length} digits phone number`;
}
