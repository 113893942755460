import { memo, SVGProps } from "react";
import { color } from "ui-kit/theme";
import { ReactComponent as DocuzLogo } from "./docuz.svg";
import { MetaLogo } from "./MetaLogo";
import { ReactComponent as PDLogo } from "./pd-logo.svg";
import { ReactComponent as ZjqHealthLogo } from "./ZjqQzjHealth.svg";

export const Logo = memo((props: SVGProps<SVGSVGElement>) => {
  const host = window.location.host;
  if (host.includes("ipocketdoctor") || host.includes("meta--health")) {
    return <MetaLogo green={props.fill === color.G200} />;
  }
  if (host.includes("docuz")) {
    return <DocuzLogo {...props} />;
  }
  if (host.includes("zjqqzjhealth")) {
    return <ZjqHealthLogo {...props} />;
  }
  return <PDLogo {...props} />;
});
