import styled from "@emotion/styled";
import { memo, ReactNode } from "react";
import { Block, Flex, FlexContainerProps } from "./Layout";
import { color } from "./theme";

type Props = FlexContainerProps & {
  image?: string;
  children?: ReactNode;
  className?: string;
  enableOverlay?: boolean;
};

export const HeroBanner = memo(
  ({ image, children, className, enableOverlay, ...flexProps }: Props) => (
    <HeroContainer {...flexProps} className={className}>
      {image && (
        <HeroImage className="image" image={image} height="100%" width="100%" />
      )}
      {enableOverlay && <HeroOverlay className="overlay" />}
      <Block position="relative" width="100%">
        {children}
      </Block>
    </HeroContainer>
  )
);

const HeroContainer = styled(Flex.withComponent("section"))`
  background-color: ${color.N200};
  height: 720px;
  position: relative;
`;

const HeroImage = styled(Block)<Props>`
  ${({ image }) => `
    background-image: ${image ? `url(${image})` : "none"} ;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
  `}
`;

const HeroOverlay = styled(Block)`
  background-color: #1e1e1f;
  height: 100%;
  position: absolute;
  opacity: 0.58;
  width: 100%;
`;
