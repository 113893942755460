import styled from "@emotion/styled";
import {
  ElementType,
  forwardRef,
  HTMLProps,
  memo,
  ReactNode,
  Ref,
  useMemo,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { ErrorMessage } from "./ErrorMessage";
import { Block } from "./Layout";
import { color } from "./theme";
import { Typography } from "./Typography";

type Props = Omit<HTMLProps<HTMLInputElement>, "label" | "as"> & {
  as?: ElementType<any> | undefined;
  label?: ReactNode;
  errorMessage?: string;
};

type CheckboxProps = Props & {
  refObject?: Ref<HTMLInputElement>;
};

export const Checkbox = forwardRef(
  (props: Props, refObject: Ref<HTMLInputElement>) => (
    <CheckboxComponent {...props} ref={null} refObject={refObject} />
  )
);

const CheckboxComponent = memo(
  ({ errorMessage, label, name, refObject, ...props }: CheckboxProps) => {
    const id = useMemo(() => uuidv4(), []);
    return (
      <>
        <Input {...props} name={name} type="checkbox" id={id} ref={refObject} />
        <Label htmlFor={id} hasText={!!label} disabled={props.disabled}>
          <Typography type="h5">{label || ""}</Typography>
        </Label>
        {errorMessage && (
          <Block>
            <ErrorMessage>{errorMessage}</ErrorMessage>
          </Block>
        )}
      </>
    );
  }
);

type State = {
  disabled?: boolean;
};

const Input = styled.input<State>`
  ${({ disabled }) => `
    position: absolute;
    opacity: 0;
    z-index: -1;
    &:checked + label::before {
      background-color: ${disabled ? color.N300 : color.G200};
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDExIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjkxMzA4IDQuMDM1MTZIMS45MTMyNkMxLjk5MjU2IDQuMDM1MjUgMi4wNzA4MSA0LjA1MzMxIDIuMTQyMTMgNC4wODc5OEMyLjIxMzQxIDQuMTIyNjIgMi4yNzU5MSA0LjE3Mjk1IDIuMzI0OTcgNC4yMzUyTDEuOTEzMDggNC4wMzUxNlpNMS45MTMwOCA0LjAzNTE2SDEuMDkzOTRDMC44OTAzMDggNC4wMzUxNiAwLjc3NjYwMiA0LjI2OTc1IDAuOTAyMzIxIDQuNDI5MkwwLjkwMjM3MSA0LjQyOTI3TDQuMTEyMTQgOC40OTU2N0w0LjExMjE4IDguNDk1NzNDNC4zMjI0NyA4Ljc2MTg4IDQuNzI1NjggOC43NjExNCA0LjkzNjY3IDguNDk2MTdMNC45MzcwOCA4LjQ5NTY2TDEwLjY1OTcgMS4yNDM5OEMxMC42NTk4IDEuMjQzNzggMTAuNjYgMS4yNDM1OCAxMC42NjAyIDEuMjQzMzhDMTAuNzg4NCAxLjA4Mjk1IDEwLjY2OTkgMC44NSAxMC40Njg5IDAuODVIOS42NDk4QzkuNDg5NDEgMC44NSA5LjMzNjY4IDAuOTIzNSA5LjIzNzYgMS4wNTA0NEM5LjIzNzUxIDEuMDUwNTUgOS4yMzc0MiAxLjA1MDY2IDkuMjM3MzQgMS4wNTA3N0w0LjUyNDAzIDcuMDIxNTdNMS45MTMwOCA0LjAzNTE2TDQuNTI0MDMgNy4wMjE1N000LjUyNDAzIDcuMDIxNTdMMi4zMjUwNiA0LjIzNTMxTDQuNTI0MDMgNy4wMjE1N1oiIGZpbGw9IndoaXRlIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjAuMyIvPgo8L3N2Zz4K");
      border-color: ${disabled ? color.N300 : color.G200};
    }
    &:hover + label::before {
      background-color: transparent;
      border-color: ${disabled ? color.N300 : color.G200};
    }
    &:checked:hover + label::before,
    &:checked:focus + label::before {
      background-color: ${disabled ? color.N300 : color.G300};
      border-color: ${disabled ? color.N300 : color.G300};
    }
    &:focus + label::before {
      background-color: transparent;
      border-color: ${disabled ? color.N300 : color.G200};
    }
`}
`;

type LabelState = {
  disabled?: boolean;
  hasText: boolean;
};

export const Label = styled.label<LabelState>`
  ${({ disabled, hasText }) => `
    align-items: flex-start;
    display: inline-grid;
    grid-template-columns: 18px auto;
    grid-column-gap: ${hasText ? "8px" : "0"};
    user-select: none;
    cursor: ${disabled ? "not-allowed" : "pointer"};
    &::before {
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 10px 8px;
      border: 1px solid ${disabled ? color.N300 : color.N400};
      border-radius: 2px;
      content: "";
      display: inline-block;
      height: 16px;
      width: 16px;
    }
  `}
`;
