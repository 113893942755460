import styled, { CSSObject } from "@emotion/styled";
import { memo, ReactNode, useMemo } from "react";
import { FieldError } from "react-hook-form";
import NativeSelect, {
  components,
  ControlProps,
  DropdownIndicatorProps,
  GroupBase,
  MultiValueRemoveProps,
  OptionProps,
  Props as NativeSelectProps,
} from "react-select";
import NativeAsyncSelect, {
  AsyncProps as NativeAsyncSelectProps,
} from "react-select/async";
import { Typography } from "ui-kit";
import { v4 as uuidv4 } from "uuid";
import { ErrorMessage } from "../ErrorMessage";
import { CheckmarkIcon, CloseRoundedIcon, SearchIcon } from "../icons";
import { Block } from "../Layout";
import { color } from "../theme";

export const defaultStyles = (hasErrors?: boolean) => ({
  clearIndicator: () => ({
    display: "none",
  }),
  control: (base: CSSObject, state: ControlProps<Option, boolean>) => ({
    ...base,
    border: `1px solid ${getBorderColor({
      menuIsOpen: state.menuIsOpen,
      isFocused: state.isFocused,
      hasValue: state.hasValue,
      hasErrors,
    })}`,
    borderRadius: "4px",
    boxShadow: "none",
    cursor: "pointer",
    minHeight: "40px",
    outline: "none",
    padding: "4px 12px 4px 14px",
    fontSize: "14px",
    lineHeight: "18px",
    "&:hover": {
      borderColor: color.G200,
    },
  }),
  dropdownIndicator: (
    base: CSSObject,
    state: DropdownIndicatorProps<Option, boolean>
  ) => ({
    ...base,
    color: color.N400,
    padding: 0,
    transition: "transform 0.3ms ease-out",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "none",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  option: (base: CSSObject, state: OptionProps<Option, boolean>) => ({
    ...base,
    backgroundColor: getOptionBg({
      isDisabled: state.isDisabled,
      isFocused: state.isFocused,
      isSelected: state.isSelected,
    }),
    color: getOptionColor({
      isDisabled: state.isDisabled,
      isFocused: state.isFocused,
      isSelected: state.isSelected,
    }),
    cursor: state.isDisabled ? "not-allowed" : "pointer",
    padding: "7px 14px",
    fontSize: "14px",
    lineHeight: "18px",
    "&:hover": {
      backgroundColor: state.isDisabled ? "transparent" : color.N100,
      color: state.isDisabled ? "auto" : color.G200,
    },
    "&:focus": {
      backgroundColor: color.N200,
      color: color.G200,
    },
  }),
  placeholder: (base: CSSObject) => ({
    ...base,
    color: color.N300,
  }),
  singleValue: (base: CSSObject) =>
    ({
      ...base,
      position: "static",
      transform: "none",
    } as CSSObject),
  menu: (base: CSSObject) => ({
    ...base,
    border: `1px solid ${color.N200}`,
    borderRadius: "5px",
    boxShadow: "none",
    padding: "10px 0",
    zIndex: 4,
  }),
  menuList: (base: CSSObject) => ({
    ...base,
  }),
  multiValue: (base: CSSObject) => ({
    ...base,
    backgroundColor: color.N200,
    borderRadius: "4px",
    margin: "0 8px 0 0",
    padding: "6px 10px",
  }),
  multiValueLabel: (base: CSSObject) => ({
    ...base,
    color: color.N400,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
    padding: 0,
    paddingLeft: 0,
  }),
  multiValueRemove: (base: CSSObject) => ({
    ...base,
    marginLeft: "4px",
    padding: 0,
    "&:hover": {
      color: "inherit",
      backgroundColor: "transparent",
    },
    "&:hover svg": {
      fill: color.N400,
    },
  }),
  valueContainer: (base: CSSObject) => ({
    ...base,
    padding: 0,
  }),
});

export type SelectProps = NativeSelectProps<Option, boolean> & {
  hasErrors?: boolean;
  errorMessage?: FieldError;
  label?: string;
  isRequired?: boolean;
};

export const Selectbox = memo(
  ({ label, errorMessage, isRequired, ...props }: SelectProps) => {
    const id = useMemo(() => uuidv4(), []);
    return (
      <>
        {label && (
          <Block marginBottom={0.5}>
            <Label htmlFor={id}>
              {label}
              {isRequired && (
                <Typography lineHeight="0" type="h4">
                  &nbsp;&#42;
                </Typography>
              )}
            </Label>
          </Block>
        )}
        <NativeSelect
          {...props}
          components={{
            ...props.components,
            Option: CustomOption,
            MultiValueRemove,
          }}
          inputId={id}
          styles={{
            ...defaultStyles(props.hasErrors),
            ...props.styles,
          }}
        />
        {errorMessage && <ErrorMessage>{errorMessage.message}</ErrorMessage>}
      </>
    );
  }
);

type AsyncSelectProps = NativeAsyncSelectProps<
  Option,
  boolean,
  GroupBase<Option>
> & {
  hasErrors?: boolean;
  errorMessage?: FieldError;
  label?: string;
  searchable?: ReactNode;
  isRequired?: boolean;
};

export const AsyncSelectbox = memo(
  ({
    label,
    errorMessage,
    searchable,
    isRequired,
    ...props
  }: AsyncSelectProps) => {
    const id = useMemo(() => uuidv4(), []);
    return (
      <>
        {label && (
          <Block marginBottom={0.5}>
            <Label htmlFor={id}>
              {label}
              {isRequired && (
                <Typography lineHeight="0" type="h4">
                  &nbsp;&#42;
                </Typography>
              )}
            </Label>
          </Block>
        )}
        <NativeAsyncSelect
          {...props}
          components={{
            ...props.components,
            DropdownIndicator: searchable
              ? SearchDropdownIndicator
              : components.DropdownIndicator,
            Option: CustomOption,
            MultiValueRemove,
            LoadingIndicator: undefined,
          }}
          inputId={id}
          styles={{
            ...defaultStyles(props.hasErrors),
            ...props.styles,
          }}
        />
        {errorMessage && <ErrorMessage>{errorMessage.message}</ErrorMessage>}
      </>
    );
  }
);

const SearchDropdownIndicator = memo(
  (props: DropdownIndicatorProps<Option, boolean>) => (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  )
);

const CustomOption = memo((props: OptionProps<Option, boolean>) => (
  <Block position="relative">
    <components.Option {...props} />
    {props.isSelected && <OptionCheckmark width="12px" height="10px" />}
  </Block>
));

const MultiValueRemove = memo((props: MultiValueRemoveProps<Option>) => (
  <components.MultiValueRemove {...props}>
    <CloseRoundedIcon fill={color.N300} />
  </components.MultiValueRemove>
));

const OptionCheckmark = styled(CheckmarkIcon)`
  margin-top: -5px;
  position: absolute;
  right: 14px;
  top: 50%;
`;

const Label = styled.label`
  color: ${color.N400};
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
`;

export type SelectboxState = {
  menuIsOpen: boolean;
  isFocused: boolean;
  hasValue: boolean;
  hasErrors?: boolean;
};

export function getBorderColor({
  menuIsOpen,
  isFocused,
  hasValue,
  hasErrors,
}: SelectboxState) {
  if (menuIsOpen || isFocused) {
    return color.G200;
  }
  if (hasErrors) {
    return color.R100;
  }
  if (hasValue) {
    return color.N300;
  }
  return color.N200;
}

type OptionState = {
  isSelected: boolean;
  isFocused: boolean;
  isDisabled: boolean;
};

function getOptionBg({ isFocused, isSelected }: OptionState) {
  if (isFocused || isSelected) {
    return color.N100;
  }
  return "transparent";
}

function getOptionColor({ isDisabled, isFocused, isSelected }: OptionState) {
  if (isDisabled) {
    return color.N300;
  }
  if (isFocused || isSelected) {
    return color.G200;
  }
  return color.N400;
}

export type Option = {
  label: string;
  value: string;
};

export function fromStringToOption(
  stringValue: string,
  optionsSource?: Option[]
): Option {
  return {
    label:
      optionsSource?.find((source) => source.value === stringValue)?.label ||
      stringValue,
    value: stringValue,
  };
}
