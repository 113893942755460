import { AUTHENTICATED_ROUTES } from "Patient/routes";
import { useLocation, useParams, Location, matchPath } from "react-router-dom";
import { usePatient } from "./patientSession";

/**
 * This hook is a wrapper around the react-router's `useLocation`.
 * It covers use case, when patient's id was changed in URL manually, and it doesn't match with the real id now.
 * Retuns a location with replaced pathname
 */
export function useLocationWithValidPathname(): Location {
  const patient = usePatient();
  const location = useLocation();
  const URLParams = useParams();
  if (!patient?.id) {
    return location;
  }
  const isPathnameValid = patient.id === URLParams.patientId;
  if (isPathnameValid) {
    return location;
  }
  const currentPath = AUTHENTICATED_ROUTES.find((route) =>
    matchPath(route, location.pathname)
  );
  if (!currentPath) {
    return location;
  }
  return {
    ...location,
    pathname: currentPath.replace(":patientId", patient.id),
  };
}
