import styled from "@emotion/styled";
import { useDoctor } from "Doctor/Auth";
import { useStore } from "effector-react";
import { memo, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Block,
  Flex,
  Loader,
  Toggle,
  TooltipWindow,
  Typography,
  errorNotification,
} from "ui-kit";
import {
  settings as $settings,
  loadNotificationsSettings,
  toggleEmailSetting,
  toggleSMSSetting,
} from "./settings";

export const DeviceNotifications = memo(() => {
  const settings = useStore($settings);
  const isLoading = useStore(loadNotificationsSettings.pending);
  const doctor = useDoctor();

  useEffect(() => {
    loadNotificationsSettings();
  }, []);

  if (isLoading || !(settings.email || settings.sms)) {
    return (
      <Flex alignItems="center" justifyContent="center">
        <Loader />
      </Flex>
    );
  }

  return (
    <>
      <Flex
        alignItems="flex-start"
        justifyContent="space-between"
        marginBottom={4}
      >
        <Block>
          <Block marginBottom={1}>
            <Typography as="h3" type="h3">
              Email notifications
            </Typography>
          </Block>
          <Block>
            <Typography fontColor="N300" type="h5">
              We will send you an email when you have a new notification
            </Typography>
          </Block>
        </Block>
        <Toggle
          checked={settings.email}
          onChange={() => toggleEmailSetting(!settings.email)}
        />
      </Flex>
      <Flex alignItems="flex-start" justifyContent="space-between">
        <Block>
          <Block marginBottom={1}>
            <Typography as="h3" type="h3">
              SMS notifications
            </Typography>
          </Block>
          <Block>
            <Typography fontColor="N300" type="h5">
              We will send you SMS when you have a new notification
            </Typography>
          </Block>
        </Block>
        <Block position="relative">
          <Toggle
            checked={doctor?.personalInformation?.phone ? settings.sms : false}
            disabled={!doctor?.personalInformation?.phone}
            onChange={() => {
              if (!settings.sms && !doctor?.personalInformation?.phone) {
                return errorNotification(
                  "Fill in the phone number in profile settings"
                );
              }
              return toggleSMSSetting(!settings.sms);
            }}
          />
          {!doctor?.personalInformation?.phone && (
            <>
              <TooltipWindow id="sms-notifications" place="bottom" clickable>
                You should add phone number in{" "}
                <Link to={`/doctor/${doctor?.id}/profile`}>
                  Personal record
                </Link>{" "}
                to make the setting available
              </TooltipWindow>
              <JoinHint data-tooltip-id="sms-notifications" />
            </>
          )}
        </Block>
      </Flex>
    </>
  );
});

const JoinHint = styled.span`
  cursor: not-allowed;
  display: inline-block;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
`;
