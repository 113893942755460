import { apiServer } from "apiConnection";
import { AxiosError } from "axios";
import {
  EmergencyInformation,
  Patient,
  PatientDTO,
  PersonalInformationPayload,
  userToPatientAdapter,
} from "Patient/Model";

export type Credentials = {
  email: string;
  password: string;
};

export async function signUp({
  email,
  password,
}: Credentials): Promise<Patient> {
  try {
    const { data: newPatient } = await apiServer.post<PatientDTO>(
      "/api/patient/signup",
      {
        email,
        password,
      }
    );
    return userToPatientAdapter(newPatient);
  } catch (error: unknown) {
    const message = (error as AxiosError<{ message: string }>).response?.data
      .message;
    throw new Error(message);
  }
}

export async function logIn({
  email,
  password,
}: Credentials): Promise<Patient> {
  try {
    const { data: user } = await apiServer.post<PatientDTO>(
      "/api/patient/login",
      {
        email,
        password,
      }
    );
    return userToPatientAdapter(user);
  } catch (error: unknown) {
    console.error(error);
    const message = (error as AxiosError<{ message: string }>).response?.data
      .message;
    throw new Error(message);
  }
}

export async function logOut() {
  try {
    await apiServer.post("/api/patient/logout");
    return true;
  } catch (error: unknown) {
    const message = (error as AxiosError<{ message: string }>).response?.data
      .message;
    throw new Error(message);
  }
}

export async function updateEmailRequest(newEmail: string) {
  try {
    const { data: updatedPatient } = await apiServer.patch<PatientDTO>(
      "/api/patient/email-update",
      {
        newEmail,
      }
    );
    return userToPatientAdapter(updatedPatient);
  } catch (error: unknown) {
    const message = (error as AxiosError<{ message: string }>).response?.data
      .message;
    throw new Error(message);
  }
}

export async function updatePasswordRequest({
  oldPassword,
  newPassword,
}: {
  oldPassword: string;
  newPassword: string;
}) {
  try {
    const { data: updatedPatient } = await apiServer.patch<PatientDTO>(
      "/api/patient/password-update",
      {
        oldPassword,
        newPassword,
      }
    );
    return userToPatientAdapter(updatedPatient);
  } catch (error: unknown) {
    const message = (error as AxiosError<{ message: string }>).response?.data
      .message;
    throw new Error(message);
  }
}

export async function updatePersonalInformation(
  personalInformation: PersonalInformationPayload
): Promise<Patient> {
  const { photo, title, firstName, lastName, gender, DOB, phone } =
    personalInformation;
  const personalData = new FormData();
  if (photo?.item(0)) {
    personalData.append(
      "photo",
      photo.item(0) as File,
      photo.item(0)?.name as string
    );
  }
  if (title) {
    personalData.append("title", title);
  }
  personalData.append("firstName", firstName);
  personalData.append("lastName", lastName);
  personalData.append("gender", gender);
  personalData.append("DOB", DOB);
  if (phone) {
    personalData.append("phone", phone);
  }
  try {
    const { data: userWithPersonalInformation } =
      await apiServer.patch<PatientDTO>("/api/patient/personal", personalData);
    return userToPatientAdapter(userWithPersonalInformation);
  } catch (error: unknown) {
    const message = (error as AxiosError<{ message: string }>).response?.data
      .message;
    throw new Error(message);
  }
}

export async function updateEmergencyInformation(
  emergencyInformation?: EmergencyInformation
) {
  try {
    const { data: userWithEmergencyInformation } =
      await apiServer.patch<PatientDTO>("/api/patient/emergency", {
        emergencyInformation,
      });
    return userToPatientAdapter(userWithEmergencyInformation);
  } catch (error: unknown) {
    const message = (error as AxiosError<{ message: string }>).response?.data
      .message;
    throw new Error(message);
  }
}

export async function createAccount() {
  try {
    const { data: updatedUser } = await apiServer.patch<PatientDTO>(
      "/api/patient/create-account"
    );
    return userToPatientAdapter(updatedUser);
  } catch (error: unknown) {
    const message = (error as AxiosError<{ message: string }>).response?.data
      .message;
    throw new Error(message);
  }
}

export async function sendConfirmationEmail(patient: Patient) {
  try {
    await apiServer.post<void>("/api/account/confirmation", { user: patient });
    return patient;
  } catch (error: unknown) {
    const message = (error as AxiosError<{ message: string }>).response?.data
      .message;
    throw new Error(message);
  }
}

export async function fetchPatientById(id: string) {
  try {
    const { data: patient } = await apiServer.get<PatientDTO>(
      `/api/patient/${id}`
    );
    return userToPatientAdapter(patient);
  } catch (error: unknown) {
    const message = (error as AxiosError<{ message: string }>).response?.data
      .message;
    throw new Error(message);
  }
}
