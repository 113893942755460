import styled from "@emotion/styled";
import {
  updateLicense,
  updatePersonalInformation,
  updateProfessionalInformation,
  useDoctor,
} from "Doctor/Auth";
import { Language, LicenseDocument } from "Doctor/Model";
import { PageLayout } from "Doctor/Profile/Layout";
import { format, parse, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useStore } from "effector-react";
import { memo, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { getCurrentTimezoneIANA } from "shared-functions/date";
import {
  Block,
  BoxWithShadow,
  Button,
  LoaderWithOverlay,
  Typography,
  errorNotification,
  maskedInputToDate,
  successNotification,
  toE164,
} from "ui-kit";
import { LicenseForm } from "./License";
import { PersonalInformationForm } from "./PersonalInformation";
import { ProfessionalInformationForm } from "./ProfessionalInformation";

const personalInformationFieldsNames = {
  photo: "photo",
  firstName: "firstName",
  lastName: "lastName",
  dob: "DOB",
  phone: "phone",
  about: "about",
};

const experienceFieldsNames = {
  specialty: "specialty",
  practiceStarted: "practiceStarted",
  language: "language",
  medicalSchool: "medicalSchool",
  degree: "degree",
  graduationYear: "graduationYear",
};

const licenseFieldsNames = {
  number: "number",
  country: "country",
  state: "state",
  npi: "npi",
  expiration: "expiration",
  document: "document",
};

type ProfileFormData = {
  photo?: FileList | null;
  firstName: string;
  lastName: string;
  DOB: string;
  phone?: string;
  about: string;
  specialty: string;
  practiceStarted: string;
  language: Language[];
  medicalSchool?: string;
  degree?: string;
  graduationYear?: string;
  number: string;
  country: string;
  state?: string;
  npi: string;
  expiration: string;
};

export const ProfilePage = memo(() => {
  const doctorInSession = useDoctor();
  const personalInformation = doctorInSession?.personalInformation;
  const professionalInformation = doctorInSession?.professionalInformation;
  const license = doctorInSession?.license;
  const [licenseDocuments, setLicenseDocuments] = useSelectedFiles(
    license?.documents || []
  );
  const [addedDocuments, updateLicenseDocuments] = useState<File[]>([]);
  const defaultExpirationDate = license?.expirationDate
    ? format(
        utcToZonedTime(license?.expirationDate, getCurrentTimezoneIANA()),
        "MM/dd/yyyy"
      )
    : undefined;
  const defaultDOB = personalInformation?.DOB
    ? format(
        utcToZonedTime(personalInformation.DOB, getCurrentTimezoneIANA()),
        "MM/dd/yyyy"
      )
    : undefined;
  const formAPI = useForm<ProfileFormData>({
    defaultValues: {
      [personalInformationFieldsNames.firstName]:
        personalInformation?.firstName || null,
      [personalInformationFieldsNames.lastName]:
        personalInformation?.lastName || null,
      [personalInformationFieldsNames.dob]: defaultDOB || null,
      [personalInformationFieldsNames.phone]: personalInformation?.phone || "",
      [personalInformationFieldsNames.about]:
        personalInformation?.about || null,
      [experienceFieldsNames.specialty]:
        professionalInformation?.specialty || null,
      [experienceFieldsNames.practiceStarted]:
        professionalInformation?.practiceStarted
          ? format(parseISO(professionalInformation.practiceStarted), "yyyy")
          : null,
      [experienceFieldsNames.language]:
        professionalInformation?.language || null,
      [experienceFieldsNames.medicalSchool]:
        professionalInformation?.education.medicalSchool || null,
      [experienceFieldsNames.degree]:
        professionalInformation?.education.degree || null,
      [experienceFieldsNames.graduationYear]:
        professionalInformation?.education.graduationYear || null,
      [licenseFieldsNames.number]: license?.number || "",
      [licenseFieldsNames.country]: license?.country || "",
      [licenseFieldsNames.state]: license?.state || "",
      [licenseFieldsNames.npi]: license?.npi || "",
      [licenseFieldsNames.expiration]: defaultExpirationDate || "",
    },
  });

  const { handleSubmit } = formAPI;

  const updateProfile: SubmitHandler<ProfileFormData> = ({
    photo,
    firstName,
    lastName,
    DOB,
    phone,
    about,
    specialty,
    practiceStarted,
    language,
    medicalSchool,
    degree,
    graduationYear,
    number,
    country,
    state,
    npi,
    expiration,
  }) =>
    Promise.all([
      updatePersonalInformation({
        photo,
        firstName,
        lastName,
        DOB: maskedInputToDate(DOB)?.toISOString() || "",
        phone: toE164(phone),
        about,
      }),
      updateProfessionalInformation({
        specialty,
        practiceStarted: parse(
          practiceStarted,
          "yyyy",
          Date.nowUniversal
        ).toISOString(),
        language,
        education: {
          medicalSchool,
          degree,
          graduationYear,
        },
      }),
      updateLicense({
        number,
        npi,
        country,
        state: country === "US" ? state : undefined,
        expirationDate: maskedInputToDate(expiration)?.toISOString() || "",
        licenseDocuments: [...licenseDocuments, ...addedDocuments],
      }),
    ])
      .then(() => {
        setLicenseDocuments((licenseDocs) => [
          ...licenseDocs,
          ...addedDocuments,
        ]);
        updateLicenseDocuments([]);
      })
      .then(() => {
        return successNotification("Personal data was updated");
      })
      .catch((error) => {
        return errorNotification(error.message);
      });

  const isUpdatingPersonal = useStore(updatePersonalInformation.pending);
  const isUpdatingProfessional = useStore(
    updateProfessionalInformation.pending
  );
  const isUpdatingLicense = useStore(updateLicense.pending);

  return (
    <PageLayout>
      <Block textAlign="center" marginBottom={5} marginTop={3}>
        <Typography as="h1" type="h1">
          My card
        </Typography>
      </Block>
      <SettingsContainer>
        {(isUpdatingPersonal ||
          isUpdatingProfessional ||
          isUpdatingLicense) && <LoaderWithOverlay />}
        <SettingsSection>
          <FormProvider {...formAPI}>
            <Form onSubmit={handleSubmit(updateProfile)}>
              <Block marginBottom={7}>
                <PersonalInformationForm />
              </Block>
              <Block marginBottom={7}>
                <ProfessionalInformationForm />
              </Block>
              <Block marginBottom={7}>
                <LicenseForm
                  licenseDocuments={licenseDocuments}
                  addedDocuments={addedDocuments}
                  onDocumentsAdd={updateLicenseDocuments}
                  onDocumentRemove={updateLicenseDocuments}
                />
              </Block>
              <Block textAlign="center">
                <Button filled type="submit">
                  Save
                </Button>
              </Block>
            </Form>
          </FormProvider>
        </SettingsSection>
      </SettingsContainer>
    </PageLayout>
  );
});

function useSelectedFiles(
  documents: LicenseDocument[]
): [File[], React.Dispatch<React.SetStateAction<File[]>>] {
  const [selectedFiles, selectFiles] = useState<File[]>([]);

  useEffect(() => {
    if (documents.length > 0) {
      Promise.all(documents.map((doc) => fetch(doc.url)))
        .then((allDocumentsResponse) =>
          Promise.all(allDocumentsResponse.map((docR) => docR.blob()))
        )
        .then((blobsFileList) =>
          blobsFileList.map(
            (blob, index) =>
              new File([blob], documents[index].name, { type: blob.type })
          )
        )
        .then(selectFiles);
    }
  }, [documents]);

  return [selectedFiles, selectFiles];
}

const SettingsContainer = styled(BoxWithShadow)`
  margin: 0 auto 48px;
  position: relative;
  width: 984px;
`;

const SettingsSection = styled.section`
  flex-direction: column;
  padding: 24px 40px 64px;
  width: 100%;
`;

const Form = styled.form`
  width: 100%;
`;
