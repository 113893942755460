import styled from "@emotion/styled";
import { useDoctor } from "Doctor/Auth";
import { Model as Doctor } from "Doctor/Model";
import {
  toDoctorEditProfilePage,
  toDoctorFAQPage,
  toDoctorNotificationsPage,
  toDoctorProfileHomePage,
  toDoctorSupportPage,
} from "Doctor/Router";
import { useStore } from "effector-react";
import { memo, ReactNode, useEffect, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { Link, NavLink } from "react-router-dom";
import {
  Avatar,
  BellIcon,
  Block,
  ChatIcon,
  ChevronDownIcon,
  color,
  Flex,
  Grid,
  HomeIcon,
  IncognitoAvatar,
  Logo,
  ProfileIncognito,
  QuestionIcon,
  RecordIcon,
  Typography,
} from "ui-kit";
import {
  $notificationsWatcher,
  checkUnreadNotifications,
} from "./Notifications";
import { QuickMenu } from "./QuickMenu";

type Props = {
  children: ReactNode;
  hideNavigation?: boolean;
};

export const PageLayout = memo(({ hideNavigation, children }: Props) => {
  const doctor = useDoctor();
  return (
    <Grid
      gridTemplateColumns={hideNavigation ? "auto" : "260px auto"}
      gridTemplateRows="auto"
      minHeight="100vh"
    >
      {!hideNavigation && <Header doctor={doctor} />}
      <Main>{children}</Main>
    </Grid>
  );
});

const Header = memo(({ doctor }: { doctor?: Doctor }) => {
  const [isProfileMenuVisible, setProfileMenuVisibility] = useState(false);
  const quickMenuRef = useDetectClickOutside({
    onTriggered: () => setProfileMenuVisibility(false),
  });

  const { unreadNotificationsAmount } = useStore($notificationsWatcher);
  useEffect(() => {
    checkUnreadNotifications();
  }, []);

  if (!doctor) {
    // Scenarios when doctor is not defined at this stage have not found yet.
    // Added this to prevent application crash in case this scenario was encountered.
    return null;
  }
  const doctorId = doctor.id;
  return (
    <HeaderPanel flexDirection="column" alignItems="center">
      <Block marginBottom={6}>
        <LogoLink to={toDoctorProfileHomePage(doctorId)}>
          <Logo width="160px" fill={color.N0} />
        </LogoLink>
      </Block>
      <Flex
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        marginBottom={5.5}
      >
        <NavigatedAvatar to={toDoctorProfileHomePage(doctorId)}>
          {doctor.personalInformation?.photo ? (
            <img
              src={doctor.personalInformation.photo.url}
              alt="Doctor avatar"
            />
          ) : (
            <IncognitoAvatar bgColor="G100" className="incognito">
              <ProfileIncognito />
            </IncognitoAvatar>
          )}
        </NavigatedAvatar>
        <ProfileContainer
          alignItems="center"
          justifyContent="center"
          onClick={() => setProfileMenuVisibility(!isProfileMenuVisible)}
          position="relative"
          ref={quickMenuRef}
        >
          <Block paddingRight={1}>
            <Typography fontColor="N0" type="h3" wordBreak="normal">
              Dr.&nbsp;{doctor.personalInformation?.firstName}&nbsp;
              {doctor.personalInformation?.lastName}
            </Typography>
          </Block>
          <ProfileOpenArrow isProfileMenuVisible={isProfileMenuVisible}>
            <ChevronDownIcon />
          </ProfileOpenArrow>
          {isProfileMenuVisible && <QuickMenu doctor={doctor} />}
        </ProfileContainer>
      </Flex>
      <Navigation>
        <MenuLink end to={toDoctorProfileHomePage(doctorId)} draggable={false}>
          <HomeIcon className="icon" />
          <Typography type="h3" fontColor="N0">
            Account
          </Typography>
        </MenuLink>
        {!doctor.onModeration && (
          <MenuLink
            end
            draggable={false}
            to={toDoctorEditProfilePage(doctorId)}
          >
            <RecordIcon className="icon" />
            <Typography type="h3" fontColor="N0">
              My card
            </Typography>
          </MenuLink>
        )}
        <MenuLink draggable={false} end to={toDoctorFAQPage(doctorId)}>
          <QuestionIcon className="icon" />
          <Typography type="h3" fontColor="N0">
            FAQ
          </Typography>
        </MenuLink>
        <MenuLink draggable={false} end to={toDoctorSupportPage(doctorId)}>
          <ChatIcon className="icon" />
          <Typography type="h3" fontColor="N0">
            Support
          </Typography>
        </MenuLink>
        <MenuLink
          end
          draggable={false}
          to={toDoctorNotificationsPage(doctorId)}
        >
          <Block marginRight={2} position="relative">
            <BellIcon className="icon no-indent" />
            {unreadNotificationsAmount && unreadNotificationsAmount > 0 ? (
              <UnreadNotificationsIndicator className="indicator" />
            ) : null}
          </Block>
          <Typography type="h3" fontColor="N0">
            Notifications
          </Typography>
        </MenuLink>
      </Navigation>
    </HeaderPanel>
  );
});

const HeaderPanel = styled(Flex.withComponent("header"))`
  background-color: ${color.G200};
  padding: 48px 0;
`;

const Navigation = styled.nav`
  width: 100%;
`;

const LogoLink = styled(Link)`
  text-decoration: none;
`;

const NavigatedAvatar = styled(Avatar)`
  height: 85px;
  margin-bottom: 16px;
  position: relative;
  width: 85px;
`.withComponent(Link);

const MenuLink = styled(NavLink)`
  align-items: center;
  color: ${color.N0};
  display: flex;
  padding: 16px 40px;
  text-decoration: none;
  width: 100%;
  & .icon {
    fill: ${color.G100};
    margin-right: 16px;
  }
  & .icon.no-indent {
    margin-right: 0;
  }
  &:hover,
  &.active {
    background-color: ${color.G300};
  }
  &:hover .icon,
  &.active .icon {
    fill: ${color.N0};
  }
  &:hover .indicator,
  &.active .indicator {
    border-color: ${color.G300};
  }
`;

const Main = styled(Block.withComponent("main"))`
  background-color: ${color.N100};
  position: relative;
`;

const ProfileContainer = styled(Flex)`
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 32px;
  outline: none;
  padding: 0;
`;

type MenuState = {
  isProfileMenuVisible: boolean;
};

const ProfileOpenArrow = styled.span<MenuState>`
  svg {
    transform: ${({ isProfileMenuVisible }) =>
      `rotate(${isProfileMenuVisible ? "180deg" : "0deg"})`};
  }
`;

const UnreadNotificationsIndicator = styled.span`
  background-color: ${color.O100};
  border: 2px solid ${color.G200};
  border-radius: 50%;
  bottom: 4px;
  display: inline-block;
  height: 13px;
  position: absolute;
  right: -6px;
  width: 13px;
`;
