import styled from "@emotion/styled";
import { useDoctor } from "Doctor/Auth";
import { toDoctorWaitingRoomPage, useDoctorId } from "Doctor/Router";
import { useStore } from "effector-react";
import { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Block,
  Button,
  color,
  Flex,
  Loader,
  Typography,
  Watches5FilledClockIcon,
} from "ui-kit";
import { PageLayout } from "../Layout";
import { $visitsHistory, loadPastVisits } from "./list";
import { VisitsList } from "./VisitList";

export const VisitsHistoryPage = memo(() => {
  const [areVisitsLoaded, setVisitsLoadedState] = useState(false);
  const visitsHistory = useStore($visitsHistory);
  const thisDoctor = useDoctor();

  useEffect(() => {
    if (thisDoctor?.id) {
      setVisitsLoadedState(false);
      loadPastVisits({
        doctorId: thisDoctor.id,
        to: Date.nowUniversal,
      }).finally(() => setVisitsLoadedState(true));
    }
  }, [thisDoctor]);

  return (
    <PageLayout>
      <Block marginTop={3} paddingLeft={5} paddingRight={5}>
        <PageTitle />
      </Block>
      {areVisitsLoaded ? (
        <ContentContainer>
          {visitsHistory.length === 0 ? (
            <Block marginTop={13} textAlign="center">
              <Block marginBottom={6}>
                <Watches5FilledClockIcon fill={color.N300} />
              </Block>
              <Block marginBottom={1}>
                <Typography fontColor="N300" type="h2">
                  No history of visits
                </Typography>
              </Block>
              <Block>
                <Typography fontColor="N300" type="h3">
                  Your visits history with patients will be displayed here
                </Typography>
              </Block>
            </Block>
          ) : (
            <VisitsListContainer>
              <VisitsList
                visitsList={visitsHistory}
                sorting="desc"
                hideConnectionControls
              />
            </VisitsListContainer>
          )}
        </ContentContainer>
      ) : (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
    </PageLayout>
  );
});

const PageTitle = memo(() => {
  const navigate = useNavigate();
  const doctorId = useDoctorId();
  const goToWaitingRoom = useCallback(() => {
    navigate(toDoctorWaitingRoomPage(doctorId));
  }, [navigate, doctorId]);
  return (
    <Flex alignItems="center" justifyContent="center" position="relative">
      <Typography as="h1" type="h1">
        History of visits
      </Typography>
      <Block position="absolute" right="0">
        <Button onClick={goToWaitingRoom}>Go to waiting room</Button>
      </Block>
    </Flex>
  );
});

const ContentContainer = styled(Block)`
  margin: 40px auto;
  padding: 0 24px;
  position: relative;
  width: 888px;
`;

const VisitsListContainer = styled(Block)`
  padding-bottom: 24px;
`;

const LoaderContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  height: calc(100vh - 176px);
`;
