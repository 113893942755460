import styled from "@emotion/styled";
import { updateEmail as updateEmailFx, useDoctor } from "Doctor/Auth";
import { useStore } from "effector-react";
import { memo } from "react";
import { useForm } from "react-hook-form";
import { Block, Button, Input, LoaderWithOverlay } from "ui-kit";

type FormData = {
  oldEmail: string;
  newEmail: string;
  newEmailConfirm: string;
};

export const MailUpdate = memo(() => {
  const doctorInSession = useDoctor();
  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm<FormData>();
  const updateEmail = ({ newEmail }: FormData) => updateEmailFx({ newEmail });
  const newEmail = watch("newEmail");
  const oldEmail = watch("oldEmail");
  const isUpdating = useStore(updateEmailFx.pending);

  return (
    <Form onSubmit={handleSubmit(updateEmail)}>
      {isUpdating && <LoaderWithOverlay />}
      <Block marginBottom={3}>
        <Input
          {...register("oldEmail", {
            validate: (email) => {
              if (!email) {
                return "Please, enter your current email";
              }
              if (email !== doctorInSession?.email) {
                return "This is not your current email";
              }
              return true;
            },
          })}
          errorMessage={errors["oldEmail"]?.message}
          hasErrors={!!errors["oldEmail"]}
          label="Old mail"
          placeholder="Enter old mail"
          type="email"
        />
      </Block>
      <Block marginBottom={3}>
        <Input
          {...register("newEmail", {
            required: "Please, enter your new email",
            validate: {
              diff: (newEmail) =>
                oldEmail !== newEmail ||
                "Please, create a new email that is defferent from the old one",
            },
          })}
          errorMessage={errors["newEmail"]?.message}
          hasErrors={!!errors["newEmail"]}
          label="New mail"
          placeholder="Enter new mail"
          type="email"
        />
      </Block>
      <Block marginBottom={6}>
        <Input
          {...register("newEmailConfirm", {
            validate: (confirmedEmail) => {
              if (newEmail !== confirmedEmail) {
                return "New email doesn't match";
              }
              return true;
            },
          })}
          errorMessage={errors["newEmailConfirm"]?.message}
          hasErrors={!!errors["newEmailConfirm"]}
          label="Repeat new mail"
          placeholder="Repeat new mail"
          type="email"
        />
      </Block>
      <Block textAlign="center">
        <Button filled type="submit">
          Save
        </Button>
      </Block>
    </Form>
  );
});

const Form = styled.form`
  position: relative;
`;
