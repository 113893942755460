import styled from "@emotion/styled";
import { useStore } from "effector-react";
import { editConclusion } from "Patient/Record";
import { usePatientId } from "Patient/Router";
import { memo, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Block,
  Button,
  ErrorMessage,
  Flex,
  Input,
  LoaderWithOverlay,
  Textarea,
} from "ui-kit";

type ConclusionProps = {
  recordId: string;
  diseaseName: string;
  prescription?: string;
  rehabilitation?: string;
  medicalExaminations?: string;
  afterFinish: () => void;
};

type ConclusionFormData = {
  diseaseName: string;
  prescription?: string | null;
  rehabilitation?: string | null;
  medicalExaminations?: string | null;
  hasConclusionNote?: boolean;
};

export const EditConclusion = memo(
  ({
    recordId,
    diseaseName,
    prescription,
    rehabilitation,
    medicalExaminations,
    afterFinish,
  }: ConclusionProps) => {
    const patientId = usePatientId();
    const {
      register,
      handleSubmit,
      formState: { errors },
      setError,
      clearErrors,
      watch,
    } = useForm<ConclusionFormData>({
      defaultValues: {
        diseaseName,
        prescription: prescription || null,
        rehabilitation: rehabilitation || null,
        medicalExaminations: medicalExaminations || null,
      },
    });

    useEffect(() => {
      register("hasConclusionNote");
    }, [register]);

    const prescriptionValue = watch("prescription");
    const rehabilitationValue = watch("rehabilitation");
    const medicalExaminationsValue = watch("medicalExaminations");

    useEffect(() => {
      if (
        !(
          prescriptionValue?.trim() ||
          rehabilitationValue?.trim() ||
          medicalExaminationsValue?.trim()
        )
      ) {
        setError("hasConclusionNote", {
          type: "required",
          message:
            "Please fill in at least one field for the patient's treatment strategy",
        });
      } else {
        clearErrors("hasConclusionNote");
      }
    }, [
      prescriptionValue,
      rehabilitationValue,
      medicalExaminationsValue,
      setError,
      clearErrors,
    ]);

    const save = ({
      diseaseName,
      prescription,
      rehabilitation,
      medicalExaminations,
    }: ConclusionFormData) => {
      editConclusion({
        patientId,
        recordId,
        diseaseName,
        prescription: prescription === null ? undefined : prescription,
        rehabilitation: rehabilitation === null ? undefined : rehabilitation,
        medicalExaminations:
          medicalExaminations === null ? undefined : medicalExaminations,
      }).then(afterFinish);
    };

    const isUpdating = useStore(editConclusion.pending);

    return (
      <Form onSubmit={handleSubmit(save)}>
        {isUpdating && <LoaderWithOverlay />}
        <Block marginBottom={3}>
          <Input
            defaultValue={diseaseName}
            hasErrors={!!errors["diseaseName"]}
            errorMessage={errors["diseaseName"]?.message}
            label="Name of the patient's disease"
            placeholder="Write the name of the patient's disease"
            {...register("diseaseName", {
              required: "Please, add disease name",
            })}
          />
        </Block>
        <Block marginBottom={4}>
          <Block marginBottom={1}>
            <Textarea
              defaultValue={prescription}
              hasErrors={
                !!errors["prescription"] || !!errors["hasConclusionNote"]
              }
              errorMessage={errors["prescription"]?.message}
              label="Prescription"
              maxLength={500}
              placeholder="Write a prescription for treating a patient"
              {...register("prescription")}
            />
          </Block>
          <Block marginBottom={1}>
            <Textarea
              defaultValue={rehabilitation}
              hasErrors={
                !!errors["rehabilitation"] || !!errors["hasConclusionNote"]
              }
              errorMessage={errors["rehabilitation"]?.message}
              label="Rehabilitation"
              maxLength={500}
              placeholder="Describe the next steps to rehabilitate the patient"
              {...register("rehabilitation")}
            />
          </Block>
          <Block>
            <Textarea
              defaultValue={medicalExaminations}
              hasErrors={
                !!errors["medicalExaminations"] || !!errors["hasConclusionNote"]
              }
              errorMessage={errors["medicalExaminations"]?.message}
              label="Medical examinations"
              maxLength={500}
              placeholder="Write down what lab tests are necessary for the patient"
              {...register("medicalExaminations")}
            />
          </Block>
          {errors["hasConclusionNote"] && (
            <Block>
              <ErrorMessage>{errors["hasConclusionNote"].message}</ErrorMessage>
            </Block>
          )}
        </Block>
        <Flex alignItems="center" justifyContent="center">
          <Block>
            <Button filled type="submit">
              Save
            </Button>
          </Block>
          <Block marginLeft={3}>
            <Button onClick={afterFinish} type="button">
              Cancel
            </Button>
          </Block>
        </Flex>
      </Form>
    );
  }
);

const Form = styled.form`
  position: relative;
`;
