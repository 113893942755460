import styled from "@emotion/styled";
import { compareDesc, format, formatRelative, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useDoctor } from "Doctor/Auth";
import { Model as Doctor } from "Doctor/Model";
import {
  toDoctorEditProfilePage,
  toDoctorSupportPage,
  toDoctorWaitingRoomPage,
} from "Doctor/Router";
import { useStore } from "effector-react";
import { memo, useEffect } from "react";
import { getCurrentTimezoneIANA } from "shared-functions/date";
import {
  BellIcon,
  Block,
  BoxWithShadow,
  color,
  Flex,
  Grid,
  Loader,
  TrashIcon,
  Typography,
  UnifiedLink,
} from "ui-kit";
import { PageLayout } from "../Layout";
import {
  loadNotifications,
  markNotificationsAsRead,
  NewVisitPayload,
  Notification,
  notificationsCenter,
  removeNotification,
} from "./notifications";

export const NotificationsCenter = memo(() => {
  const notifications = useStore(notificationsCenter);
  const areNotificationsLoading = useStore(loadNotifications.pending);
  const doctor = useDoctor();

  useEffect(() => {
    loadNotifications().then((notificationsList) => {
      const unreadNotificationsId = notificationsList
        .filter((n) => !n.isRead)
        .map((n) => n.objectId);
      if (unreadNotificationsId.length > 0) {
        return markNotificationsAsRead(unreadNotificationsId);
      }
    });
  }, []);

  if (!areNotificationsLoading && notifications.length === 0) {
    return (
      <PageLayout>
        <ContentContainer>
          <Block textAlign="center">
            <Typography as="h1" type="h1">
              Notifications
            </Typography>
          </Block>
          <Block marginTop={13} textAlign="center">
            <Block marginBottom={6}>
              <BellIcon height="86px" width="86px" fill={color.N300} />
            </Block>
            <Block marginBottom={1}>
              <Typography as="h2" type="h2" fontColor="N300">
                No notifications
              </Typography>
            </Block>
            <Block>
              <Typography fontColor="N300" type="h3">
                Your notifications will be displayed here
              </Typography>
            </Block>
          </Block>
        </ContentContainer>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <ContentContainer>
        {areNotificationsLoading ? (
          <>
            <Block textAlign="center">
              <Typography as="h1" type="h1">
                Notifications
              </Typography>
            </Block>
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          </>
        ) : (
          <>
            <Block textAlign="center">
              <Typography as="h1" type="h1">
                Notifications
              </Typography>
            </Block>
            <NotificationsListContainer>
              {notifications.sort(sortByNewestOnTop).map((notification) => (
                <NotificationContainer
                  key={notification.objectId}
                  isRead={notification.isRead}
                >
                  <IconUnderlay isRead={notification.isRead}>
                    <BellIcon height="16px" width="16px" />
                  </IconUnderlay>
                  <Block>
                    <Message doctor={doctor!} notification={notification} />
                  </Block>
                  <NotificationTimeContainer>
                    <Typography type="h5" fontColor="N300">
                      {formatRelative(
                        utcToZonedTime(
                          notification.dateTime.iso,
                          getCurrentTimezoneIANA()
                        ),
                        Date.nowUniversal
                      )}
                    </Typography>
                  </NotificationTimeContainer>
                  <Flex alignItems="center">
                    <RemoveNotificationIcon
                      fill={color.N300}
                      height="16px"
                      width="16px"
                      onClick={() => removeNotification(notification.objectId)}
                    />
                  </Flex>
                </NotificationContainer>
              ))}
            </NotificationsListContainer>
          </>
        )}
      </ContentContainer>
    </PageLayout>
  );
});

type MessageProps = {
  doctor: Doctor;
  notification: Notification;
};

const Message = memo(({ doctor, notification }: MessageProps) => {
  if (notification.type === "moderation") {
    return (
      <>
        <Block marginBottom={1}>
          <Typography type="h5">
            Your medical data has been sent for consideration!
          </Typography>
        </Block>
        <Block>
          <Typography type="h5" fontColor="N300">
            Within 48 hours, we will send a message to your email, where we will
            inform you of the results of the review of your data and also tell
            you about further actions.
          </Typography>
        </Block>
      </>
    );
  }
  if (notification.type === "accountCreated") {
    return (
      <>
        <Block marginBottom={1}>
          <Typography type="h5">Welcome to PocketDoctor!</Typography>
        </Block>
        <Block>
          <Typography type="h5" fontColor="N300">
            You have been added to the list of PocketDoctor doctors.
            Congratulations to you! Wait for your first patient in the{" "}
            <UnifiedLink type="h5" to={toDoctorWaitingRoomPage(doctor.id)}>
              Waiting room
            </UnifiedLink>{" "}
            section.
          </Typography>
        </Block>
      </>
    );
  }
  if (notification.type === "personalInfoUpdated") {
    return (
      <>
        <Block marginBottom={1}>
          <Typography type="h5">
            Personal information has been changed
          </Typography>
        </Block>
        <Block>
          <Typography type="h5" fontColor="N300">
            The{" "}
            <UnifiedLink type="h5" to={toDoctorEditProfilePage(doctor.id)}>
              Personal data
            </UnifiedLink>{" "}
            has been changed in your account. If you have not changed your
            personal data, please contact the{" "}
            <UnifiedLink type="h5" to={toDoctorSupportPage(doctor.id)}>
              Support
            </UnifiedLink>{" "}
            immediately.
          </Typography>
        </Block>
      </>
    );
  }
  if (notification.type === "emailUpdated") {
    return (
      <>
        <Block marginBottom={1}>
          <Typography type="h5">Your email has been changed</Typography>
        </Block>
        <Block>
          <Typography type="h5" fontColor="N300">
            An email has been changed in your account successfully. If you have
            not changed your email address, please contact the{" "}
            <UnifiedLink type="h5" to={toDoctorSupportPage(doctor.id)}>
              Support
            </UnifiedLink>{" "}
            immediately.
          </Typography>
        </Block>
      </>
    );
  }
  if (notification.type === "passwordUpdated") {
    return (
      <>
        <Block marginBottom={1}>
          <Typography type="h5">Your password has been changed</Typography>
        </Block>
        <Block>
          <Typography type="h5" fontColor="N300">
            A password has been changed in your account successfully. If you
            have not changed your password, please contact the{" "}
            <UnifiedLink type="h5" to={toDoctorSupportPage(doctor.id)}>
              Support
            </UnifiedLink>{" "}
            immediately.
          </Typography>
        </Block>
      </>
    );
  }
  if (notification.type === "visitScheduled") {
    const { timeFrom, timeTo } = notification.payload as NewVisitPayload;
    return (
      <>
        <Block marginBottom={1}>
          <Typography type="h5">New visit</Typography>
        </Block>
        <Block>
          <Typography type="h5" fontColor="N300">
            A patient has booked the visit with you. The scheduled time is{" "}
            {format(parseISO(timeFrom), "MMMM dd, yyyy EEEE")} from{" "}
            {format(parseISO(timeFrom), "hh:mm a")} to{" "}
            {format(parseISO(timeTo), "hh:mm a")}.
          </Typography>
        </Block>
      </>
    );
  }
  if (notification.type === "visitStartsSoon") {
    return (
      <>
        <Block marginBottom={1}>
          <Typography type="h5">A reminder about the visit</Typography>
        </Block>
        <Block>
          <Typography type="h5" fontColor="N300">
            Dear Dr. {doctor.personalInformation?.firstName}{" "}
            {doctor.personalInformation?.lastName}, there are 30 minutes left
            before the visit with the patient. Go to the waiting room in
            advance, read the &ldquo;visit instructions&rdquo; and pass the
            &ldquo;device test&rdquo; to be prepared for the visit.
          </Typography>
        </Block>
      </>
    );
  }
  if (notification.type === "visitMissed") {
    return (
      <>
        <Block marginBottom={1}>
          <Typography type="h5">You did not show up for the visit</Typography>
        </Block>
        <Block>
          <Typography type="h5" fontColor="N300">
            You did not show up for a visit with a patient. If you miss more
            than 10 visits with patients, your account will be blocked.
          </Typography>
        </Block>
      </>
    );
  }
  return <Typography type="h5">New activity was detected</Typography>;
});

function sortByNewestOnTop(
  notification_1: Notification,
  notification_2: Notification
) {
  return compareDesc(
    new Date(notification_1.dateTime.iso),
    new Date(notification_2.dateTime.iso)
  );
}

const ContentContainer = styled.section`
  margin: 24px auto 40px;
  width: 968px;
`;

const NotificationsListContainer = styled(Grid)`
  grid-row-gap: 12px;
  margin-top: 40px;
  overflow-x: hidden;
  padding: 32px 40px;
`.withComponent(BoxWithShadow);

type NotificationState = {
  isRead: boolean;
};

const NotificationContainer = styled(Grid)<NotificationState>`
  ${({ isRead }) => `
    align-items: start;
    background-color: ${isRead ? "transparent" : color.N100};
    border: 1px solid ${isRead ? color.N200 : color.G100};
    border-radius: 4px;
    grid-column-gap: 16px;
    grid-template-columns: 32px 3fr 1fr 16px;
    padding: 12px 32px 12px 16px;
  `};
`;

const NotificationTimeContainer = styled(Block)`
  justify-self: end;
  margin-right: 8px;
`;

const IconUnderlay = styled(Flex)<NotificationState>`
  align-items: center;
  background-color: ${({ isRead }) => (isRead ? color.N200 : color.G200)};
  border-radius: 50%;
  justify-content: center;
  height: 32px;
  width: 32px;
  & svg {
    fill: ${({ isRead }) => (isRead ? color.N300 : color.N0)};
  }
`;

const RemoveNotificationIcon = styled(TrashIcon)`
  cursor: pointer;
  &:hover,
  &:focus {
    fill: ${color.O200};
  }
`;

const LoaderContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  height: calc(100vh - 176px);
`;
