import styled, { CSSObject } from "@emotion/styled";
import { isAfter, isBefore, startOfDay } from "date-fns";
import { format, formatInTimeZone, utcToZonedTime } from "date-fns-tz";
import enGB from "date-fns/locale/en-GB";
import { endOfDay } from "date-fns";
import { memo, ReactNode } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import {
  getCurrentTimezoneIANA,
  splitRangeByInterval,
} from "shared-functions/date";
import {
  Block,
  Button,
  Flex,
  fromStringToOption,
  Grid,
  Option,
  Selectbox,
  Typography,
} from "ui-kit";
import { useDoctor } from "./Auth";
import { updateSchedule } from "./Auth/doctorSession";
import { ISO8601, WeekDay } from "./Model";

type ScheduleData = {
  // 12 hours format
  mondayWorkTimeFrom?: string;
  mondayWorkTimeTo?: string;
  mondayBreakFrom?: string;
  mondayBreakTo?: string;
  tuesdayWorkTimeFrom?: string;
  tuesdayWorkTimeTo?: string;
  tuesdayBreakFrom?: string;
  tuesdayBreakTo?: string;
  wednesdayWorkTimeFrom?: string;
  wednesdayWorkTimeTo?: string;
  wednesdayBreakFrom?: string;
  wednesdayBreakTo?: string;
  thursdayWorkTimeFrom?: string;
  thursdayWorkTimeTo?: string;
  thursdayBreakFrom?: string;
  thursdayBreakTo?: string;
  fridayWorkTimeFrom?: string;
  fridayWorkTimeTo?: string;
  fridayBreakFrom?: string;
  fridayBreakTo?: string;
  saturdayWorkTimeFrom?: string;
  saturdayWorkTimeTo?: string;
  saturdayBreakFrom?: string;
  saturdayBreakTo?: string;
  sundayWorkTimeFrom?: string;
  sundayWorkTimeTo?: string;
  sundayBreakFrom?: string;
  sundayBreakTo?: string;
};

type FormProps = {
  className?: string;
  onSave?: () => void;
  resetButton?: ReactNode;
  submitTitle?: string;
};

export const ScheduleForm = memo(
  ({ className, onSave, resetButton, submitTitle }: FormProps) => {
    const doctor = useDoctor();
    const doctorSchedule = doctor?.schedule;

    const {
      handleSubmit,
      control,
      formState: { dirtyFields },
    } = useForm<ScheduleData>({
      defaultValues: {
        mondayBreakFrom: ISODateToTime(
          doctorSchedule?.monday?.breakTime.from,
          doctorSchedule?.monday?.timezone
        ),
        mondayBreakTo: ISODateToTime(
          doctorSchedule?.monday?.breakTime.to,
          doctorSchedule?.monday?.timezone
        ),
        mondayWorkTimeFrom: ISODateToTime(
          doctorSchedule?.monday?.workTime.from,
          doctorSchedule?.monday?.timezone
        ),
        mondayWorkTimeTo: ISODateToTime(
          doctorSchedule?.monday?.workTime.to,
          doctorSchedule?.monday?.timezone
        ),
        tuesdayBreakFrom: ISODateToTime(
          doctorSchedule?.tuesday?.breakTime.from,
          doctorSchedule?.tuesday?.timezone
        ),
        tuesdayBreakTo: ISODateToTime(
          doctorSchedule?.tuesday?.breakTime.to,
          doctorSchedule?.tuesday?.timezone
        ),
        tuesdayWorkTimeFrom: ISODateToTime(
          doctorSchedule?.tuesday?.workTime.from,
          doctorSchedule?.tuesday?.timezone
        ),
        tuesdayWorkTimeTo: ISODateToTime(
          doctorSchedule?.tuesday?.workTime.to,
          doctorSchedule?.tuesday?.timezone
        ),
        wednesdayBreakFrom: ISODateToTime(
          doctorSchedule?.wednesday?.breakTime.from,
          doctorSchedule?.wednesday?.timezone
        ),
        wednesdayBreakTo: ISODateToTime(
          doctorSchedule?.wednesday?.breakTime.to,
          doctorSchedule?.wednesday?.timezone
        ),
        wednesdayWorkTimeFrom: ISODateToTime(
          doctorSchedule?.wednesday?.workTime.from,
          doctorSchedule?.wednesday?.timezone
        ),
        wednesdayWorkTimeTo: ISODateToTime(
          doctorSchedule?.wednesday?.workTime.to,
          doctorSchedule?.wednesday?.timezone
        ),
        thursdayWorkTimeFrom: ISODateToTime(
          doctorSchedule?.thursday?.workTime.from,
          doctorSchedule?.thursday?.timezone
        ),
        thursdayWorkTimeTo: ISODateToTime(
          doctorSchedule?.thursday?.workTime.to,
          doctorSchedule?.thursday?.timezone
        ),
        thursdayBreakFrom: ISODateToTime(
          doctorSchedule?.thursday?.breakTime.from,
          doctorSchedule?.thursday?.timezone
        ),
        thursdayBreakTo: ISODateToTime(
          doctorSchedule?.thursday?.breakTime.to,
          doctorSchedule?.thursday?.timezone
        ),
        fridayWorkTimeFrom: ISODateToTime(
          doctorSchedule?.friday?.workTime.from,
          doctorSchedule?.friday?.timezone
        ),
        fridayWorkTimeTo: ISODateToTime(
          doctorSchedule?.friday?.workTime.to,
          doctorSchedule?.friday?.timezone
        ),
        fridayBreakFrom: ISODateToTime(
          doctorSchedule?.friday?.breakTime.from,
          doctorSchedule?.friday?.timezone
        ),
        fridayBreakTo: ISODateToTime(
          doctorSchedule?.friday?.breakTime.to,
          doctorSchedule?.friday?.timezone
        ),
        saturdayWorkTimeFrom: ISODateToTime(
          doctorSchedule?.saturday?.workTime.from,
          doctorSchedule?.saturday?.timezone
        ),
        saturdayWorkTimeTo: ISODateToTime(
          doctorSchedule?.saturday?.workTime.to,
          doctorSchedule?.saturday?.timezone
        ),
        saturdayBreakFrom: ISODateToTime(
          doctorSchedule?.saturday?.breakTime.from,
          doctorSchedule?.saturday?.timezone
        ),
        saturdayBreakTo: ISODateToTime(
          doctorSchedule?.saturday?.breakTime.to,
          doctorSchedule?.saturday?.timezone
        ),
        sundayWorkTimeFrom: ISODateToTime(
          doctorSchedule?.sunday?.workTime.from,
          doctorSchedule?.sunday?.timezone
        ),
        sundayWorkTimeTo: ISODateToTime(
          doctorSchedule?.sunday?.workTime.to,
          doctorSchedule?.sunday?.timezone
        ),
        sundayBreakFrom: ISODateToTime(
          doctorSchedule?.sunday?.breakTime.from,
          doctorSchedule?.sunday?.timezone
        ),
        sundayBreakTo: ISODateToTime(
          doctorSchedule?.sunday?.breakTime.to,
          doctorSchedule?.sunday?.timezone
        ),
      },
    });

    const saveSchedule = (schedule: ScheduleData) => {
      const isMondayModified =
        dirtyFields.mondayWorkTimeFrom ||
        dirtyFields.mondayWorkTimeTo ||
        dirtyFields.mondayBreakFrom ||
        dirtyFields.mondayBreakTo;
      const isTuesdayModified =
        dirtyFields.tuesdayWorkTimeFrom ||
        dirtyFields.tuesdayWorkTimeTo ||
        dirtyFields.tuesdayBreakFrom ||
        dirtyFields.tuesdayBreakTo;
      const isWednesdayModified =
        dirtyFields.wednesdayWorkTimeFrom ||
        dirtyFields.wednesdayWorkTimeTo ||
        dirtyFields.wednesdayBreakFrom ||
        dirtyFields.wednesdayBreakTo;
      const isThursdayModified =
        dirtyFields.thursdayWorkTimeFrom ||
        dirtyFields.thursdayWorkTimeTo ||
        dirtyFields.thursdayBreakFrom ||
        dirtyFields.thursdayBreakTo;
      const isFridayModified =
        dirtyFields.fridayWorkTimeFrom ||
        dirtyFields.fridayWorkTimeTo ||
        dirtyFields.fridayBreakFrom ||
        dirtyFields.fridayBreakTo;
      const isSaturdayModified =
        dirtyFields.saturdayWorkTimeFrom ||
        dirtyFields.saturdayWorkTimeTo ||
        dirtyFields.saturdayBreakFrom ||
        dirtyFields.saturdayBreakTo;
      const isSundayModified =
        dirtyFields.sundayWorkTimeFrom ||
        dirtyFields.sundayWorkTimeTo ||
        dirtyFields.sundayBreakFrom ||
        dirtyFields.sundayBreakTo;

      const mondayUpdates = isMondayModified
        ? {
            monday: {
              workTime: {
                from: schedule.mondayWorkTimeFrom,
                to: schedule.mondayWorkTimeTo,
              },
              breakTime: {
                from: schedule.mondayBreakFrom,
                to: schedule.mondayBreakTo,
              },
              timezone: getCurrentTimezoneIANA(),
            },
          }
        : {};
      const tuesdayUpdates = isTuesdayModified
        ? {
            tuesday: {
              workTime: {
                from: schedule.tuesdayWorkTimeFrom,
                to: schedule.tuesdayWorkTimeTo,
              },
              breakTime: {
                from: schedule.tuesdayBreakFrom,
                to: schedule.tuesdayBreakTo,
              },
              timezone: getCurrentTimezoneIANA(),
            },
          }
        : {};
      const wednesdayUpdates = isWednesdayModified
        ? {
            wednesday: {
              workTime: {
                from: schedule.wednesdayWorkTimeFrom,
                to: schedule.wednesdayWorkTimeTo,
              },
              breakTime: {
                from: schedule.wednesdayBreakFrom,
                to: schedule.wednesdayBreakTo,
              },
              timezone: getCurrentTimezoneIANA(),
            },
          }
        : {};
      const thursdayUpdates = isThursdayModified
        ? {
            thursday: {
              workTime: {
                from: schedule.thursdayWorkTimeFrom,
                to: schedule.thursdayWorkTimeTo,
              },
              breakTime: {
                from: schedule.thursdayBreakFrom,
                to: schedule.thursdayBreakTo,
              },
              timezone: getCurrentTimezoneIANA(),
            },
          }
        : {};
      const fridayUpdates = isFridayModified
        ? {
            friday: {
              workTime: {
                from: schedule.fridayWorkTimeFrom,
                to: schedule.fridayWorkTimeTo,
              },
              breakTime: {
                from: schedule.fridayBreakFrom,
                to: schedule.fridayBreakTo,
              },
              timezone: getCurrentTimezoneIANA(),
            },
          }
        : {};
      const saturdayUpdates = isSaturdayModified
        ? {
            saturday: {
              workTime: {
                from: schedule.saturdayWorkTimeFrom,
                to: schedule.saturdayWorkTimeTo,
              },
              breakTime: {
                from: schedule.saturdayBreakFrom,
                to: schedule.saturdayBreakTo,
              },
              timezone: getCurrentTimezoneIANA(),
            },
          }
        : {};
      const sundayUpdates = isSundayModified
        ? {
            sunday: {
              workTime: {
                from: schedule.sundayWorkTimeFrom,
                to: schedule.sundayWorkTimeTo,
              },
              breakTime: {
                from: schedule.sundayBreakFrom,
                to: schedule.sundayBreakTo,
              },
              timezone: getCurrentTimezoneIANA(),
            },
          }
        : {};

      return updateSchedule({
        ...mondayUpdates,
        ...tuesdayUpdates,
        ...wednesdayUpdates,
        ...thursdayUpdates,
        ...fridayUpdates,
        ...saturdayUpdates,
        ...sundayUpdates,
      }).then(() => {
        if (onSave) {
          onSave();
        }
      });
    };

    const scheduleTime = useWatch({
      control,
    });

    if (!doctor) {
      return (
        <Typography type="h3">Doctor not found (error in code)</Typography>
      );
    }

    return (
      <form className={className} onSubmit={handleSubmit(saveSchedule)}>
        <Grid
          gridTemplateColumns="max-content 1fr 1fr max-content"
          gridColumnGap={3}
          gridRowGap={1.5}
          marginBottom={6}
        >
          <Block />
          <Block textAlign="center">
            <Typography type="h3" fontColor="N300">
              Work time
            </Typography>
          </Block>
          <Block textAlign="center">
            <Typography type="h3" fontColor="N300">
              Break time
            </Typography>
          </Block>
          <Block />
          <Block paddingTop={1}>
            <Typography>Mon</Typography>
          </Block>
          <Flex>
            <Block width="50%">
              <Controller
                control={control}
                name="mondayWorkTimeFrom"
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    options={getWorkTimeFromOptions({
                      workTimeTo: scheduleTime.mondayWorkTimeTo,
                      breakFrom: scheduleTime.mondayBreakFrom,
                      breakTo: scheduleTime.mondayBreakTo,
                    })}
                    onChange={onChange}
                    value={value}
                    aria-label="Monday work start time"
                  />
                )}
              />
            </Block>
            <TimeSeparator>&mdash;</TimeSeparator>
            <Block width="50%">
              <Controller
                control={control}
                name="mondayWorkTimeTo"
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    options={getWorkTimeToOptions({
                      workTimeFrom: scheduleTime.mondayWorkTimeFrom,
                      breakFrom: scheduleTime.mondayBreakFrom,
                      breakTo: scheduleTime.mondayBreakTo,
                    })}
                    onChange={onChange}
                    value={value}
                    aria-label="Monday work end time"
                  />
                )}
              />
            </Block>
          </Flex>
          <Flex>
            <Block width="50%">
              <Controller
                control={control}
                name="mondayBreakFrom"
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    options={getBreakFromOptions({
                      workTimeFrom: scheduleTime.mondayWorkTimeFrom,
                      workTimeTo: scheduleTime.mondayWorkTimeTo,
                      breakTo: scheduleTime.mondayBreakTo,
                    })}
                    onChange={onChange}
                    value={value}
                    aria-label="Monday break start time"
                  />
                )}
              />
            </Block>
            <TimeSeparator>&mdash;</TimeSeparator>
            <Block width="50%">
              <Controller
                control={control}
                name="mondayBreakTo"
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    options={getBreakToOptions({
                      workTimeFrom: scheduleTime.mondayWorkTimeFrom,
                      workTimeTo: scheduleTime.mondayWorkTimeTo,
                      breakFrom: scheduleTime.mondayBreakFrom,
                    })}
                    onChange={onChange}
                    value={value}
                    aria-label="Monday break end time"
                  />
                )}
              />
            </Block>
          </Flex>
          <TimezoneAbbrContainer>
            <Typography fontColor="N300" type="h5">
              {timezoneAbbr(doctorSchedule?.monday)}
            </Typography>
          </TimezoneAbbrContainer>
          <Block paddingTop={1}>
            <Typography>Tue</Typography>
          </Block>
          <Flex>
            <Block width="50%">
              <Controller
                control={control}
                name="tuesdayWorkTimeFrom"
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    options={getWorkTimeFromOptions({
                      workTimeTo: scheduleTime.tuesdayWorkTimeTo,
                      breakFrom: scheduleTime.tuesdayBreakFrom,
                      breakTo: scheduleTime.tuesdayBreakTo,
                    })}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Block>
            <TimeSeparator>&mdash;</TimeSeparator>
            <Block width="50%">
              <Controller
                control={control}
                name="tuesdayWorkTimeTo"
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    options={getWorkTimeToOptions({
                      workTimeFrom: scheduleTime.tuesdayWorkTimeFrom,
                      breakFrom: scheduleTime.tuesdayBreakFrom,
                      breakTo: scheduleTime.tuesdayBreakTo,
                    })}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Block>
          </Flex>
          <Flex>
            <Block width="50%">
              <Controller
                control={control}
                name="tuesdayBreakFrom"
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    options={getBreakFromOptions({
                      workTimeFrom: scheduleTime.tuesdayWorkTimeFrom,
                      workTimeTo: scheduleTime.tuesdayWorkTimeTo,
                      breakTo: scheduleTime.tuesdayBreakTo,
                    })}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Block>
            <TimeSeparator>&mdash;</TimeSeparator>
            <Block width="50%">
              <Controller
                control={control}
                name="tuesdayBreakTo"
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    options={getBreakToOptions({
                      workTimeFrom: scheduleTime.tuesdayWorkTimeFrom,
                      workTimeTo: scheduleTime.tuesdayWorkTimeTo,
                      breakFrom: scheduleTime.tuesdayBreakFrom,
                    })}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Block>
          </Flex>
          <TimezoneAbbrContainer>
            <Typography fontColor="N300" type="h5">
              {timezoneAbbr(doctorSchedule?.tuesday)}
            </Typography>
          </TimezoneAbbrContainer>
          <Block paddingTop={1}>
            <Typography>Wed</Typography>
          </Block>
          <Flex>
            <Block width="50%">
              <Controller
                control={control}
                name="wednesdayWorkTimeFrom"
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    options={getWorkTimeFromOptions({
                      workTimeTo: scheduleTime.wednesdayWorkTimeTo,
                      breakFrom: scheduleTime.wednesdayBreakFrom,
                      breakTo: scheduleTime.wednesdayBreakTo,
                    })}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Block>
            <TimeSeparator>&mdash;</TimeSeparator>
            <Block width="50%">
              <Controller
                control={control}
                name="wednesdayWorkTimeTo"
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    options={getWorkTimeToOptions({
                      workTimeFrom: scheduleTime.wednesdayWorkTimeFrom,
                      breakFrom: scheduleTime.wednesdayBreakFrom,
                      breakTo: scheduleTime.wednesdayBreakTo,
                    })}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Block>
          </Flex>
          <Flex>
            <Block width="50%">
              <Controller
                control={control}
                name="wednesdayBreakFrom"
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    options={getBreakFromOptions({
                      workTimeFrom: scheduleTime.wednesdayWorkTimeFrom,
                      workTimeTo: scheduleTime.wednesdayWorkTimeTo,
                      breakTo: scheduleTime.wednesdayBreakTo,
                    })}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Block>
            <TimeSeparator>&mdash;</TimeSeparator>
            <Block width="50%">
              <Controller
                control={control}
                name="wednesdayBreakTo"
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    options={getBreakToOptions({
                      workTimeFrom: scheduleTime.wednesdayWorkTimeFrom,
                      workTimeTo: scheduleTime.wednesdayWorkTimeTo,
                      breakFrom: scheduleTime.wednesdayBreakFrom,
                    })}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Block>
          </Flex>
          <TimezoneAbbrContainer>
            <Typography fontColor="N300" type="h5">
              {timezoneAbbr(doctorSchedule?.wednesday)}
            </Typography>
          </TimezoneAbbrContainer>
          <Block paddingTop={1}>
            <Typography>Thu</Typography>
          </Block>
          <Flex>
            <Block width="50%">
              <Controller
                control={control}
                name="thursdayWorkTimeFrom"
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    options={getWorkTimeFromOptions({
                      workTimeTo: scheduleTime.thursdayWorkTimeTo,
                      breakFrom: scheduleTime.thursdayBreakFrom,
                      breakTo: scheduleTime.thursdayBreakTo,
                    })}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Block>
            <TimeSeparator>&mdash;</TimeSeparator>
            <Block width="50%">
              <Controller
                control={control}
                name="thursdayWorkTimeTo"
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    options={getWorkTimeToOptions({
                      workTimeFrom: scheduleTime.thursdayWorkTimeFrom,
                      breakFrom: scheduleTime.thursdayBreakFrom,
                      breakTo: scheduleTime.thursdayBreakTo,
                    })}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Block>
          </Flex>
          <Flex>
            <Block width="50%">
              <Controller
                control={control}
                name="thursdayBreakFrom"
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    options={getBreakFromOptions({
                      workTimeFrom: scheduleTime.thursdayWorkTimeFrom,
                      workTimeTo: scheduleTime.thursdayWorkTimeTo,
                      breakTo: scheduleTime.thursdayBreakTo,
                    })}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Block>
            <TimeSeparator>&mdash;</TimeSeparator>
            <Block width="50%">
              <Controller
                control={control}
                name="thursdayBreakTo"
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    options={getBreakToOptions({
                      workTimeFrom: scheduleTime.thursdayWorkTimeFrom,
                      workTimeTo: scheduleTime.thursdayWorkTimeTo,
                      breakFrom: scheduleTime.thursdayBreakFrom,
                    })}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Block>
          </Flex>
          <TimezoneAbbrContainer>
            <Typography fontColor="N300" type="h5">
              {timezoneAbbr(doctorSchedule?.thursday)}
            </Typography>
          </TimezoneAbbrContainer>
          <Block paddingTop={1}>
            <Typography>Fri</Typography>
          </Block>
          <Flex>
            <Block width="50%">
              <Controller
                control={control}
                name="fridayWorkTimeFrom"
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    options={getWorkTimeFromOptions({
                      workTimeTo: scheduleTime.fridayWorkTimeTo,
                      breakFrom: scheduleTime.fridayBreakFrom,
                      breakTo: scheduleTime.fridayBreakTo,
                    })}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Block>
            <TimeSeparator>&mdash;</TimeSeparator>
            <Block width="50%">
              <Controller
                control={control}
                name="fridayWorkTimeTo"
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    options={getWorkTimeToOptions({
                      workTimeFrom: scheduleTime.fridayWorkTimeFrom,
                      breakFrom: scheduleTime.fridayBreakFrom,
                      breakTo: scheduleTime.fridayBreakTo,
                    })}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Block>
          </Flex>
          <Flex>
            <Block width="50%">
              <Controller
                control={control}
                name="fridayBreakFrom"
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    options={getBreakFromOptions({
                      workTimeFrom: scheduleTime.fridayWorkTimeFrom,
                      workTimeTo: scheduleTime.fridayWorkTimeTo,
                      breakTo: scheduleTime.fridayBreakTo,
                    })}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Block>
            <TimeSeparator>&mdash;</TimeSeparator>
            <Block width="50%">
              <Controller
                control={control}
                name="fridayBreakTo"
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    options={getBreakToOptions({
                      workTimeFrom: scheduleTime.fridayWorkTimeFrom,
                      workTimeTo: scheduleTime.fridayWorkTimeTo,
                      breakFrom: scheduleTime.fridayBreakFrom,
                    })}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Block>
          </Flex>
          <TimezoneAbbrContainer>
            <Typography fontColor="N300" type="h5">
              {timezoneAbbr(doctorSchedule?.friday)}
            </Typography>
          </TimezoneAbbrContainer>
          <Block paddingTop={1}>
            <Typography>Sat</Typography>
          </Block>
          <Flex>
            <Block width="50%">
              <Controller
                control={control}
                name="saturdayWorkTimeFrom"
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    options={getWorkTimeFromOptions({
                      workTimeTo: scheduleTime.saturdayWorkTimeTo,
                      breakFrom: scheduleTime.saturdayBreakFrom,
                      breakTo: scheduleTime.saturdayBreakTo,
                    })}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Block>
            <TimeSeparator>&mdash;</TimeSeparator>
            <Block width="50%">
              <Controller
                control={control}
                name="saturdayWorkTimeTo"
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    options={getWorkTimeToOptions({
                      workTimeFrom: scheduleTime.saturdayWorkTimeFrom,
                      breakFrom: scheduleTime.saturdayBreakFrom,
                      breakTo: scheduleTime.saturdayBreakTo,
                    })}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Block>
          </Flex>
          <Flex>
            <Block width="50%">
              <Controller
                control={control}
                name="saturdayBreakFrom"
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    options={getBreakFromOptions({
                      workTimeFrom: scheduleTime.saturdayWorkTimeFrom,
                      workTimeTo: scheduleTime.saturdayWorkTimeTo,
                      breakTo: scheduleTime.saturdayBreakTo,
                    })}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Block>
            <TimeSeparator>&mdash;</TimeSeparator>
            <Block width="50%">
              <Controller
                control={control}
                name="saturdayBreakTo"
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    options={getBreakToOptions({
                      workTimeFrom: scheduleTime.saturdayWorkTimeFrom,
                      workTimeTo: scheduleTime.saturdayWorkTimeTo,
                      breakFrom: scheduleTime.saturdayBreakFrom,
                    })}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Block>
          </Flex>
          <TimezoneAbbrContainer>
            <Typography fontColor="N300" type="h5">
              {timezoneAbbr(doctorSchedule?.saturday)}
            </Typography>
          </TimezoneAbbrContainer>
          <Block paddingTop={1}>
            <Typography>Sun</Typography>
          </Block>
          <Flex>
            <Block width="50%">
              <Controller
                control={control}
                name="sundayWorkTimeFrom"
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    options={getWorkTimeFromOptions({
                      workTimeTo: scheduleTime.sundayWorkTimeTo,
                      breakFrom: scheduleTime.sundayBreakFrom,
                      breakTo: scheduleTime.sundayBreakTo,
                    })}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Block>
            <TimeSeparator>&mdash;</TimeSeparator>
            <Block width="50%">
              <Controller
                control={control}
                name="sundayWorkTimeTo"
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    options={getWorkTimeToOptions({
                      workTimeFrom: scheduleTime.sundayWorkTimeFrom,
                      breakFrom: scheduleTime.sundayBreakFrom,
                      breakTo: scheduleTime.sundayBreakTo,
                    })}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Block>
          </Flex>
          <Flex>
            <Block width="50%">
              <Controller
                control={control}
                name="sundayBreakFrom"
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    options={getBreakFromOptions({
                      workTimeFrom: scheduleTime.sundayWorkTimeFrom,
                      workTimeTo: scheduleTime.sundayWorkTimeTo,
                      breakTo: scheduleTime.sundayBreakTo,
                    })}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Block>
            <TimeSeparator>&mdash;</TimeSeparator>
            <Block width="50%">
              <Controller
                control={control}
                name="sundayBreakTo"
                render={({ field: { onChange, value } }) => (
                  <TimeSelector
                    options={getBreakToOptions({
                      workTimeFrom: scheduleTime.sundayWorkTimeFrom,
                      workTimeTo: scheduleTime.sundayWorkTimeTo,
                      breakFrom: scheduleTime.sundayBreakFrom,
                    })}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Block>
          </Flex>
          <TimezoneAbbrContainer>
            <Typography fontColor="N300" type="h5">
              {timezoneAbbr(doctorSchedule?.sunday)}
            </Typography>
          </TimezoneAbbrContainer>
        </Grid>
        {resetButton ? (
          <Flex alignItems="center" justifyContent="space-between">
            {resetButton}
            <Button filled type="submit">
              {submitTitle || "Update schedule"}
            </Button>
          </Flex>
        ) : (
          <Block textAlign="center">
            <Button filled type="submit">
              {submitTitle || "Update schedule"}
            </Button>
          </Block>
        )}
      </form>
    );
  }
);

function getAllTimeOptions() {
  return splitRangeByInterval({
    from: startOfDay(Date.nowUniversal),
    to: endOfDay(Date.nowUniversal),
    interval: 15, // minutes. Market de-facto value
  }).map((interval) => ({
    label: format(interval, "hh:mm a"),
    value: interval.toISOString(),
  }));
}

function ISODateToTime(isoDate?: ISO8601, timezone?: string) {
  if (!isoDate) {
    return undefined;
  }
  const zonedTime = format(
    utcToZonedTime(isoDate, timezone || getCurrentTimezoneIANA()),
    "hh:mm a"
  );
  return getAllTimeOptions().find((option) => option.label === zonedTime)
    ?.value;
}

function timezoneAbbr(weekDay?: WeekDay) {
  if (!weekDay || !weekDay?.timezone) {
    return null;
  }
  const isAllTimeEmpty = [
    weekDay.breakTime.from,
    weekDay.breakTime.to,
    weekDay.workTime.from,
    weekDay.workTime.to,
  ].every((time) => !time);
  if (isAllTimeEmpty) {
    return null;
  }
  return formatInTimeZone(new Date(), weekDay.timezone, "zzz", {
    locale: enGB,
  });
}

function getWorkTimeFromOptions({
  workTimeTo,
  breakFrom,
  breakTo,
}: {
  workTimeTo?: string;
  breakFrom?: string;
  breakTo?: string;
}): Option[] {
  let options = getAllTimeOptions();
  // Narrowing the possible options depending on already selected time
  if (workTimeTo) {
    options = options.filter((option) =>
      isBefore(new Date(option.value), new Date(workTimeTo))
    );
  }
  if (breakTo) {
    options = options.filter((option) =>
      isBefore(new Date(option.value), new Date(breakTo))
    );
  }
  if (breakFrom) {
    options = options.filter((option) =>
      isBefore(new Date(option.value), new Date(breakFrom))
    );
  }
  return options;
}

function getWorkTimeToOptions({
  workTimeFrom,
  breakFrom,
  breakTo,
}: {
  workTimeFrom?: string;
  breakFrom?: string;
  breakTo?: string;
}): Option[] {
  let options = getAllTimeOptions();
  // Narrowing the possible options depending on already selected time
  if (breakTo) {
    options = options.filter((option) =>
      isAfter(new Date(option.value), new Date(breakTo))
    );
  }
  if (breakFrom) {
    options = options.filter((option) =>
      isAfter(new Date(option.value), new Date(breakFrom))
    );
  }
  if (workTimeFrom) {
    options = options.filter((option) =>
      isAfter(new Date(option.value), new Date(workTimeFrom))
    );
  }
  return options;
}

function getBreakFromOptions({
  workTimeFrom,
  workTimeTo,
  breakTo,
}: {
  workTimeFrom?: string;
  workTimeTo?: string;
  breakTo?: string;
}): Option[] {
  let options = getAllTimeOptions();
  if (workTimeFrom) {
    options = options.filter((option) =>
      isAfter(new Date(option.value), new Date(workTimeFrom))
    );
  }
  if (workTimeTo) {
    options = options.filter((option) =>
      isBefore(new Date(option.value), new Date(workTimeTo))
    );
  }
  if (breakTo) {
    options = options.filter((option) =>
      isBefore(new Date(option.value), new Date(breakTo))
    );
  }
  return options;
}

function getBreakToOptions({
  workTimeFrom,
  workTimeTo,
  breakFrom,
}: {
  workTimeFrom?: string;
  workTimeTo?: string;
  breakFrom?: string;
}) {
  let options = getAllTimeOptions();
  if (workTimeFrom) {
    options = options.filter((option) =>
      isAfter(new Date(option.value), new Date(workTimeFrom))
    );
  }
  if (workTimeTo) {
    options = options.filter((option) =>
      isBefore(new Date(option.value), new Date(workTimeTo))
    );
  }
  if (breakFrom) {
    options = options.filter((option) =>
      isAfter(new Date(option.value), new Date(breakFrom))
    );
  }
  return options;
}

type TimeSelectorProps = {
  options: Option[];
  onChange: (time?: string) => void;
  value?: string;
  "aria-label"?: string;
};

const TimeSelector = memo(
  ({ onChange, options, value, ...props }: TimeSelectorProps) => {
    return (
      <Selectbox
        {...props}
        options={options || getAllTimeOptions()}
        onChange={(time) => {
          const selectedTime = time ? (time as Option).value : undefined;
          onChange(selectedTime);
        }}
        isClearable
        placeholder="00:00 AM"
        noOptionsMessage={() => "No time"}
        defaultValue={
          value ? fromStringToOption(value, getAllTimeOptions()) : null
        }
        styles={{
          menuList: (base: CSSObject) => ({
            ...base,
            maxHeight: "168px",
          }),
        }}
      />
    );
  }
);

const TimeSeparator = styled(Block)`
  padding: 10px 4px 0;
`;

const TimezoneAbbrContainer = styled(Block)`
  align-self: center;
`;
