import styled from "@emotion/styled";
import {
  ChangeEvent,
  ElementType,
  forwardRef,
  HTMLProps,
  memo,
  Ref,
  useCallback,
  useMemo,
  useState,
} from "react";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { ErrorMessage } from "../ErrorMessage";
import { Block, Grid } from "../Layout";
import { color } from "../theme";
import { Typography } from "../Typography";
import { getBorderColor } from "./getBorderColor";

type NativeTextareaProps = HTMLProps<HTMLTextAreaElement> & {
  errorMessage?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | string;
  hasErrors?: boolean;
  hasValue?: boolean;
  // input has a native `required` property. But to bypass browser validation, need to rely on custom flag
  isRequired?: boolean;
  optional?: boolean;
};

export const Textarea = forwardRef(
  (props: NativeTextareaProps, refObject: Ref<HTMLTextAreaElement>) => (
    <TextareaComponent {...props} ref={null} refObject={refObject} />
  )
);

type Props = NativeTextareaProps & {
  refObject?: Ref<HTMLTextAreaElement>;
};

const TextareaComponent = memo(
  ({ className, isRequired, hasValue, optional, ...props }: Props) => {
    const id = useMemo(() => uuidv4(), []);
    const { errorMessage, label, refObject, defaultValue, onChange } = props;
    const [value, setValue] = useState(defaultValue || "");
    const [isFocused, setFocus] = useState<boolean>(false);
    const onValueChange = useCallback(
      (e: ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value);
        onChange && onChange(e);
      },
      [onChange]
    );
    return (
      <Block>
        {label && (
          <Block marginBottom={0.5}>
            <Label htmlFor={id}>
              {label}
              {isRequired && (
                <Typography lineHeight="0" type="h4">
                  &nbsp;&#42;
                </Typography>
              )}
              {optional && (
                <Typography
                  lineHeight="0"
                  fontColor="N300"
                  fontStyle="italic"
                  type="h6"
                >
                  &nbsp;(Optional)
                </Typography>
              )}
            </Label>
          </Block>
        )}
        <TextareaContainer
          className={className}
          hasErrors={props.hasErrors}
          hasValue={
            hasValue === undefined
              ? !!value && value.toString().length > 0
              : hasValue
          }
          isFocused={isFocused}
          hasMaxLength={!!props.maxLength}
        >
          <TextareaInput
            {...props}
            as={props.as as (ElementType<any> & string) | undefined}
            onBlur={() => setFocus(false)}
            onChange={onValueChange}
            onFocus={() => setFocus(true)}
            ref={refObject}
            id={id}
          />
          {props.maxLength && (
            <Block textAlign="right">
              <Typography fontColor="N300" type="h6">
                {(value as string).length} / {props.maxLength}
              </Typography>
            </Block>
          )}
        </TextareaContainer>
        {errorMessage && <ErrorMessage>{errorMessage.toString()}</ErrorMessage>}
      </Block>
    );
  }
);

type TextareaState = {
  hasErrors?: boolean;
  hasMaxLength: boolean;
  hasValue?: boolean;
  isFocused: boolean;
};

const TextareaContainer = styled(Grid)<TextareaState>`
  background-color: ${color.N0};
  border: ${({ hasErrors, hasValue, isFocused }) =>
    `1px solid ${
      isFocused ? color.G200 : getBorderColor({ hasErrors, hasValue })
    }`};
  border-radius: 4px;
  grid-template-rows: 1fr max-content;
  max-width: 100%;
  min-height: 128px;
  min-width: 100%;
  padding: 10px 14px;
  position: relative;
`;

const TextareaInput = styled.textarea<Props>`
  border: none;
  color: ${color.N400};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  outline: none;
  resize: none;
  &::placeholder {
    color: ${color.N300};
  }
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${color.N300};
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: ${color.N400};
  }
`;

const Label = styled.label`
  cursor: pointer;
  color: ${color.N400};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
`;
