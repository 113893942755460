import { attach, createEffect, createEvent, createStore } from "effector";
import {
  LocalAudioTrack,
  LocalParticipant,
  LocalVideoTrack,
  Participant,
  RemoteAudioTrack,
  RemoteParticipant,
  RemoteVideoTrack,
  Room,
  connect,
} from "twilio-video";
import { StreamInput } from "./Model";
import { getConnectionToken } from "./requests";

export type LocalMediaParticipant = {
  id: Participant.Identity;
  audio: LocalAudioTrack;
  video: LocalVideoTrack;
};

export type RemoteMediaParticipant = {
  id: Participant.Identity;
  audio: RemoteAudioTrack | null;
  video: RemoteVideoTrack | null;
  isMuted?: boolean;
};

export const joinSession = createEffect("joinSession", {
  handler: async ({ sessionName, userId }: StreamInput) => {
    const { token, meetingRoomName } = await getConnectionToken({
      sessionName,
      userId,
    });
    const meetingRoom = await connect(token, {
      name: meetingRoomName,
      audio: true,
      maxAudioBitrate: 16000,
      video: {
        width: 1280,
        height: 720,
        frameRate: 24,
      },
    });

    // Listen to changes
    publisherConnected(meetingRoom.localParticipant);
    meetingRoom.localParticipant.on(
      "disconnected",
      (publisher: LocalParticipant) => {
        return publisherDisconnected(publisher);
      }
    );

    // Handle already connected remote participants
    meetingRoom.participants.forEach((subscriber) => {
      subscriberConnected(subscriber);
      // Remote tracks become available only after local participant subscription. Otherwise, it's null.
      // So, need to have granular events to catch remote tracks, - instead of general "participantConnedted"
      subscriber.audioTracks.forEach((trackPublication) => {
        trackPublication.on("subscribed", (audio) =>
          subscriberAudioConnected({ id: subscriber.identity, audio })
        );
        trackPublication.on("unsubscribed", (audio) =>
          subscriberAudioDisconnected({ id: subscriber.identity, audio })
        );
      });
      subscriber.videoTracks.forEach((trackPublication) => {
        trackPublication.on("subscribed", (video) =>
          subscriberVideoConnected({ id: subscriber.identity, video })
        );
        trackPublication.on("unsubscribed", (video) =>
          subscriberVideoDisconnected({ id: subscriber.identity, video })
        );
      });
    });

    // Handle remote participants who were connected after me
    meetingRoom.on("participantConnected", (subscriber: RemoteParticipant) => {
      subscriberConnected(subscriber);
      // Remote tracks become available only after local participant subscription. Otherwise, it's null.
      // So, need to have granular events to catch remote tracks, - instead of general "participantConnedted"
      subscriber.audioTracks.forEach((trackPublication) => {
        trackPublication.on("subscribed", (audio) =>
          subscriberAudioConnected({ id: subscriber.identity, audio })
        );
        trackPublication.on("unsubscribed", (audio) =>
          subscriberAudioDisconnected({ id: subscriber.identity, audio })
        );
      });
      subscriber.videoTracks.forEach((trackPublication) => {
        trackPublication.on("subscribed", (video) =>
          subscriberVideoConnected({ id: subscriber.identity, video })
        );
        trackPublication.on("unsubscribed", (video) =>
          subscriberVideoDisconnected({ id: subscriber.identity, video })
        );
      });
    });

    meetingRoom.on(
      "participantDisconnected",
      (participant: RemoteParticipant) => {
        return subscriberDisconnected(participant);
      }
    );

    return {
      sessionName,
      session: meetingRoom,
    };
  },
});

const leaveSessionFx = createEffect("leaveSession", {
  handler: async ({ session }: { session?: Room }) => {
    try {
      if (!session) {
        throw new Error("Session data must be defined to leave a session");
      }
      session.disconnect();
    } catch (error: any) {
      throw new Error(error.message as string);
    }
  },
});

const clearSessionError = leaveSessionFx.map(() => undefined);

const publisherConnected = createEvent<LocalParticipant>("publisherConnected");
const publisherDisconnected = createEvent<LocalParticipant>(
  "publisherDisconnected"
);
publisherDisconnected.watch((publisher) => publisher.removeAllListeners());

const subscriberConnected = createEvent<RemoteParticipant>(
  "subscriberConnected"
);
const subscriberAudioConnected = createEvent<{
  id: Participant.Identity;
  audio: RemoteAudioTrack;
}>("subscriberAudioConnected");
const subscriberVideoConnected = createEvent<{
  id: Participant.Identity;
  video: RemoteVideoTrack;
}>("subscriberVideoConnected");
const subscriberDisconnected = createEvent<RemoteParticipant>(
  "subscriberDisconnected"
);
const subscriberAudioDisconnected = createEvent<{
  id: Participant.Identity;
  audio: RemoteAudioTrack;
}>("subscriberAudioDisconnected");
const subscriberVideoDisconnected = createEvent<{
  id: Participant.Identity;
  video: RemoteVideoTrack;
}>("subscriberVideoDisconnected");
export const subscriberMuted = createEvent<{ id: Participant.Identity }>(
  "subscriberMuted"
);
export const subscriberUnmuted = createEvent<{ id: Participant.Identity }>(
  "subscriberUnmuted"
);
subscriberDisconnected.watch((subscriber) => subscriber.removeAllListeners());

export type StreamState = {
  sessionName?: string;
  session?: Room;
  sessionError?: string;
  publisher?: LocalMediaParticipant;
  subscribers: RemoteMediaParticipant[];
};

export const $stream = createStore<StreamState>({ subscribers: [] })
  .on(joinSession.done, (state, { result: { session, sessionName } }) => {
    return {
      ...state,
      session,
      sessionName,
      sessionError: undefined,
    };
  })
  .on(joinSession.fail, (state, { error }) => ({
    ...state,
    session: undefined,
    sessionName: undefined,
    sessionError: error.message,
    publisher: undefined,
  }))
  .on(clearSessionError, (state) => ({
    ...state,
    sessionError: undefined,
  }))
  .on(publisherConnected, (state, publisher) => ({
    ...state,
    publisher: {
      id: publisher.identity,
      audio: getAudioTrack(publisher),
      video: getVideoTrack(publisher),
    } as LocalMediaParticipant,
  }))
  .on(publisherDisconnected, (state, disconnectedPublisher) => ({
    ...state,
    publisher:
      state.publisher?.id === disconnectedPublisher.identity
        ? undefined
        : state.publisher,
  }))
  .on(subscriberConnected, (state, subscriber) => {
    const newSubscriber = {
      id: subscriber.identity,
      audio: getAudioTrack(subscriber),
      video: getVideoTrack(subscriber),
      isMuted: false,
    } as RemoteMediaParticipant;
    return {
      ...state,
      subscribers: [...state.subscribers, newSubscriber],
    };
  })
  .on(subscriberAudioConnected, (state, subscriberWithAudio) => {
    const subscriber = state.subscribers.find(
      (sub) => sub.id === subscriberWithAudio.id
    );
    // Handle edge case (didn't check wether it's real): subscription happend before a connection
    if (!subscriber) {
      const newSubscriber = {
        ...subscriberWithAudio,
        video: null,
      };
      return {
        ...state,
        subscribers: [...state.subscribers, newSubscriber],
      };
    }
    return {
      ...state,
      subscribers: state.subscribers.map((sub) =>
        sub.id === subscriberWithAudio.id
          ? { ...sub, audio: subscriberWithAudio.audio }
          : sub
      ),
    };
  })
  .on(subscriberVideoConnected, (state, subscriberWithVideo) => {
    const subscriber = state.subscribers.find(
      (sub) => sub.id === subscriberWithVideo.id
    );
    // Handle edge case (didn't check wether it's real): subscription happend before a connection
    if (!subscriber) {
      const newSubscriber = {
        ...subscriberWithVideo,
        audio: null,
      };
      return {
        ...state,
        subscribers: [...state.subscribers, newSubscriber],
      };
    }
    return {
      ...state,
      subscribers: state.subscribers.map((sub) =>
        sub.id === subscriberWithVideo.id
          ? { ...sub, video: subscriberWithVideo.video }
          : sub
      ),
    };
  })
  .on(subscriberDisconnected, (state, disconnectedSubscriber) => ({
    ...state,
    subscribers: state.subscribers.filter(
      (sub) => sub.id !== disconnectedSubscriber.identity
    ),
  }))
  .on(subscriberAudioDisconnected, (state, subscriberWithAudio) => ({
    ...state,
    subscribers: state.subscribers.map((sub) =>
      sub.id === subscriberWithAudio.id ? { ...sub, audio: null } : sub
    ),
  }))
  .on(subscriberVideoDisconnected, (state, subscriberWithVideo) => ({
    ...state,
    subscribers: state.subscribers.map((sub) =>
      sub.id === subscriberWithVideo.id ? { ...sub, video: null } : sub
    ),
  }))
  .on(subscriberMuted, (state, mutedSubscriber) => ({
    ...state,
    subscribers: state.subscribers.map((sub) =>
      sub.id === mutedSubscriber.id ? { ...sub, isMuted: true } : sub
    ),
  }))
  .on(subscriberUnmuted, (state, unmutedSubscriber) => ({
    ...state,
    subscribers: state.subscribers.map((sub) =>
      sub.id === unmutedSubscriber.id ? { ...sub, isMuted: false } : sub
    ),
  }))
  .reset(leaveSessionFx.done);

export const leaveSession = attach({
  name: "leaveSession",
  effect: leaveSessionFx,
  source: $stream,
  mapParams: (params: void, $stream) => ({
    session: $stream.session,
  }),
});

function getAudioTrack(participant?: Participant) {
  let audioTrack = null;
  participant?.audioTracks.forEach((trackPublication) => {
    audioTrack = trackPublication.track;
  });
  return audioTrack as LocalAudioTrack | null;
}

function getVideoTrack(participant?: Participant) {
  let localVideoTrack = null;
  participant?.videoTracks.forEach((trackPublication) => {
    localVideoTrack = trackPublication.track;
  });
  return localVideoTrack as LocalVideoTrack | null;
}
