import { ProcedureRecord, ProcedureRecordDTO } from "./typeDefinitions";

export function procedureRecordAdapter(
  record: ProcedureRecordDTO
): ProcedureRecord {
  return {
    id: record.objectId,
    type: record.type,
    name: record.name,
    description: record.description,
    periodFrom: record.type === "past" ? record.periodFrom : undefined,
    periodTo: record.type === "past" ? record.periodTo : undefined,
    createdAt: record.createdAt,
    updatedAt: record.updatedAt,
  };
}
