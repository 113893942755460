import { memo, ReactNode, useEffect, useState } from "react";

type Props = {
  interval: number; // seconds
  whenStop: () => boolean;
  onStop?: () => void;
  children?: () => ReactNode;
};

// This component updates it's state once per tick. Useful for dynamic content, e.g timers
export const AutoUpdates = memo(
  ({ interval, whenStop, onStop, children }: Props) => {
    // if state is differ from previous value, this compomnent re-renders it's children.
    // Math.random is used to be sure that state differs after each tick
    const [, updateState] = useState(Math.random());

    useEffect(() => {
      let timerId = window.setTimeout(function invokeUpdate() {
        if (whenStop()) {
          clearTimeout(timerId);
          return onStop && onStop();
        }
        updateState(Math.random());
        timerId = window.setTimeout(invokeUpdate, interval * 1000);
      }, interval * 1000);
      return () => {
        clearTimeout(timerId);
      };
    }, [interval, onStop, whenStop]);

    return <>{children && children()}</>;
  }
);
