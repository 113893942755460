import { memo } from "react";
import { color } from "../theme";
import { ReactComponent as CrossIcon } from "./cross.svg";

type Props = {
  className?: string;
  onClick: () => void;
};

export const CloseIcon = memo(({ className, onClick }: Props) => (
  <CrossIcon
    className={className}
    cursor="pointer"
    onClick={onClick}
    fill={color.G200}
    width="16px"
    height="16px"
    rotate="-45deg"
  />
));
