import styled from "@emotion/styled";
import { compareDesc, format, parseISO } from "date-fns";
import { EmptyStateIcon } from "Doctor/Profile/PatientRecord/EmptyState";
import { toDoctorPatientRecordAddConclusion, useDoctorId } from "Doctor/Router";
import { useStore } from "effector-react";
import { usePatientId } from "Patient";
import {
  Conclusion,
  conclusionsList as $conclusionsList,
  deleteConclusion,
  loadConclusions,
} from "Patient/Record";
import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Block,
  Button,
  color,
  EditIcon,
  Flex,
  LoaderWithOverlay,
  ModalWindow,
  RecordConclusionsIcon,
  TrashIcon,
  Typography,
} from "ui-kit";
import { LoaderWithSpacing } from "../Loader";
import { EditConclusion } from "./EditView";

export const ConclusionsList = memo(() => {
  const [doctorId, patientId] = [useDoctorId(), usePatientId()];
  const navigate = useNavigate();
  const [recordsInEditMode, setEditMode] = useState<string[]>([]);
  const conclusions = useStore($conclusionsList);
  const [deletingRecordId, toggleDeleteConfirmation] = useState<string>();

  const [isLoading, setIsLoading] = useState(true);
  const isDeleting = useStore(deleteConclusion.pending);

  useEffect(() => {
    loadConclusions(patientId).finally(() => setIsLoading(false));
  }, [patientId]);

  if (isLoading) {
    return <LoaderWithSpacing />;
  }

  if (conclusions.length === 0) {
    return (
      <Block marginBottom={8} marginTop={6} textAlign="center">
        <Flex justifyContent="center" marginBottom={4}>
          <EmptyStateIcon>
            <RecordConclusionsIcon width="86px" height="86px" />
          </EmptyStateIcon>
        </Flex>
        <Block marginBottom={1}>
          <Typography fontColor="N300" type="h2">
            No conclusions
          </Typography>
        </Block>
        <Block marginBottom={4}>
          <Typography fontColor="N300" fontWeight={400} type="h3">
            Add conclusions for the patient
          </Typography>
        </Block>
        <Block textAlign="center">
          <Button
            filled
            type="button"
            onClick={() =>
              navigate(
                toDoctorPatientRecordAddConclusion({ patientId, doctorId })
              )
            }
          >
            Add conclusion
          </Button>
        </Block>
      </Block>
    );
  }

  return (
    <>
      {isDeleting && <LoaderWithOverlay />}
      {deletingRecordId && (
        <ModalWindow>
          <Block padding={8}>
            <Block textAlign="center" marginBottom={3}>
              <Typography type="h3">Do you really want to delete?</Typography>
            </Block>
            <Flex alignItems="center" justifyContent="center">
              <Block marginRight={2}>
                <Button
                  type="button"
                  onClick={() => {
                    toggleDeleteConfirmation(undefined);
                    deleteConclusion({
                      patientId,
                      recordId: deletingRecordId,
                    });
                  }}
                >
                  Yes
                </Button>
              </Block>
              <Block marginLeft={2}>
                <Button
                  type="button"
                  onClick={() => toggleDeleteConfirmation(undefined)}
                >
                  No
                </Button>
              </Block>
            </Flex>
          </Block>
        </ModalWindow>
      )}
      <Block marginTop={3} marginBottom={6} paddingLeft={5} paddingRight={5}>
        {conclusions.sort(newestOnTop).map((record) => (
          <ConclusionRecordContainer key={record.id}>
            {recordsInEditMode.includes(record.id) ? (
              <EditConclusion
                recordId={record.id}
                diseaseName={record.diseaseName}
                prescription={record.prescription}
                rehabilitation={record.rehabilitation}
                medicalExaminations={record.medicalExaminations}
                afterFinish={() =>
                  setEditMode(
                    recordsInEditMode.filter((id) => id !== record.id)
                  )
                }
              />
            ) : (
              <>
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  marginBottom={1}
                >
                  <Typography as="h4" type="h2">
                    {record.diseaseName}
                  </Typography>
                  {record.author.id === doctorId && (
                    <Flex>
                      <Block marginRight={2}>
                        <EditRecordIcon
                          fill={color.N300}
                          onClick={() =>
                            setEditMode([...recordsInEditMode, record.id])
                          }
                        />
                      </Block>
                      <Block>
                        <RemoveRecordIcon
                          onClick={() => toggleDeleteConfirmation(record.id)}
                          fill={color.N300}
                        />
                      </Block>
                    </Flex>
                  )}
                </Flex>
                <Block marginBottom={1}>
                  <Typography fontColor="N300" type="h4">
                    Updated:{" "}
                    {format(parseISO(record.updatedAt), "MM/dd/yyyy hh:mm a")}{" "}
                    by{" "}
                    {record.author.id === doctorId ? "you" : record.author.name}
                  </Typography>
                </Block>
                <Block marginBottom={2}>
                  <Block>
                    <Typography fontWeight={500} type="h3">
                      Prescription
                    </Typography>
                  </Block>
                  <Typography fontWeight={400} type="h3">
                    {record.prescription || "N/A"}
                  </Typography>
                </Block>
                <Block marginBottom={2}>
                  <Block>
                    <Typography fontWeight={500} type="h3">
                      Rehabilitation
                    </Typography>
                  </Block>
                  <Typography fontWeight={400} type="h3">
                    {record.rehabilitation || "N/A"}
                  </Typography>
                </Block>
                <Block marginBottom={2}>
                  <Block>
                    <Typography fontWeight={500} type="h3">
                      Lab tests
                    </Typography>
                  </Block>
                  <Typography fontWeight={400} type="h3">
                    {record.medicalExaminations || "N/A"}
                  </Typography>
                </Block>
              </>
            )}
          </ConclusionRecordContainer>
        ))}
      </Block>
    </>
  );
});

function newestOnTop(conclusion1: Conclusion, conclusion2: Conclusion) {
  return compareDesc(
    new Date(conclusion1.updatedAt),
    new Date(conclusion2.updatedAt)
  );
}

const ConclusionRecordContainer = styled.section`
  border-bottom: 1px solid ${color.N200};
  margin-bottom: 32px;
  padding-bottom: 32px;
  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

const EditRecordIcon = styled(EditIcon)`
  &:hover,
  &:focus {
    cursor: pointer;
    fill: ${color.O200};
  }
`;

const RemoveRecordIcon = styled(TrashIcon)`
  &:hover,
  &:focus {
    cursor: pointer;
    fill: ${color.O200};
  }
`;
