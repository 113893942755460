import styled from "@emotion/styled";
import { format, utcToZonedTime } from "date-fns-tz";
import { differenceInYears } from "date-fns/esm";
import { useStore } from "effector-react";
import { memo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { StepWizardChildProps } from "react-step-wizard";
import { getCurrentTimezoneIANA } from "shared-functions/date";
import { checkSizeBelowLimit, useImageData } from "shared-functions/file";
import {
  AddPhotoIcon,
  Avatar,
  Block,
  Button,
  DateInput,
  errorNotification,
  Input,
  isFormatMatched,
  LoaderWithOverlay,
  maskedInputToDate,
  PhoneNumberInput,
  phoneNumberLengthMatch,
  Textarea,
  toE164,
  Typography,
} from "ui-kit";
import { updatePersonalInformation, useDoctor } from "../doctorSession";

const personalInformationFieldsNames = {
  photo: "photo",
  firstName: "firstName",
  lastName: "lastName",
  dob: "DOB",
  phone: "phone",
  about: "about",
};

type PersonalInfomationFormData = {
  photo?: FileList | null;
  firstName: string;
  lastName: string;
  DOB: string;
  phone?: string;
  about: string;
};

type Props = Partial<StepWizardChildProps>;

export const PersonalInformationForm = memo(
  ({ nextStep, currentStep, totalSteps }: Props) => {
    const doctorInSession = useDoctor();
    const personalInformation = doctorInSession?.personalInformation;
    const defaultDOB = personalInformation?.DOB
      ? format(
          utcToZonedTime(personalInformation.DOB, getCurrentTimezoneIANA()),
          "MM/dd/yyyy"
        )
      : undefined;
    const {
      formState: { errors },
      handleSubmit,
      register,
    } = useForm<PersonalInfomationFormData>({
      defaultValues: {
        [personalInformationFieldsNames.firstName]:
          personalInformation?.firstName || null,
        [personalInformationFieldsNames.lastName]:
          personalInformation?.lastName || null,
        [personalInformationFieldsNames.dob]: defaultDOB || null,
        [personalInformationFieldsNames.phone]:
          personalInformation?.phone || "",
        [personalInformationFieldsNames.about]:
          personalInformation?.about || null,
      },
    });

    const { preview, onChange: changePreview } = useImageData(
      personalInformation?.photo?.url
    );
    const { onChange: onPhotoChange, ...photoFormProps } = register("photo");

    const savePersonalInformation: SubmitHandler<
      PersonalInfomationFormData
    > = ({ photo, firstName, lastName, DOB, phone, about }) => {
      updatePersonalInformation({
        photo,
        firstName,
        lastName,
        DOB: maskedInputToDate(DOB)?.toISOString() || "",
        phone: toE164(phone),
        about,
      })
        .then(nextStep)
        .catch(() => errorNotification("Failed to update personal data"));
    };

    const isUpdating = useStore(updatePersonalInformation.pending);

    if (!doctorInSession) {
      return null;
    }

    return (
      <Form onSubmit={handleSubmit(savePersonalInformation)}>
        {isUpdating && <LoaderWithOverlay />}
        <Block marginBottom={3} textAlign="center">
          <Typography as="h2" type="h2">
            Personal data
          </Typography>
        </Block>
        <Block textAlign="center">
          <input
            accept="image/*"
            id="add-photo"
            type="file"
            hidden
            onChange={(e) => {
              const updatedPhoto = e.target.files?.item(0);
              if (checkSizeBelowLimit({ file: updatedPhoto, limitMB: 15 })) {
                changePreview(updatedPhoto ?? null);
                onPhotoChange(e);
              } else {
                errorNotification("Please, add photo under 15MB");
              }
            }}
            {...photoFormProps}
          />
          <AddPhotoLabel htmlFor="add-photo">
            {preview ? (
              <AvatarImage width="140px" height="140px">
                <img src={preview} alt="Doctor avatar" />
              </AvatarImage>
            ) : (
              <AddPhotoIcon />
            )}
          </AddPhotoLabel>
        </Block>
        <Block marginBottom={3}>
          <Input
            defaultValue={personalInformation?.firstName}
            errorMessage={errors["firstName"]?.message}
            hasErrors={!!errors["lastName"]}
            label="First name"
            placeholder="First name"
            type="text"
            isRequired
            {...register("firstName", {
              required: "Please, enter your first name",
            })}
          />
        </Block>
        <Block marginBottom={3}>
          <Input
            defaultValue={personalInformation?.lastName}
            errorMessage={errors["lastName"]?.message}
            hasErrors={!!errors["lastName"]}
            label="Last name"
            placeholder="Last name"
            type="text"
            isRequired
            {...register("lastName", {
              required: "Please, enter your last name",
            })}
          />
        </Block>
        <Block marginBottom={3}>
          <DateInput
            defaultValue={defaultDOB}
            errorMessage={errors["DOB"]?.message}
            hasErrors={!!errors["DOB"]}
            label="Date of birth"
            isRequired
            {...register("DOB", {
              required: "Please, enter your date of birth",
              validate: {
                format: isFormatMatched,
                validateAdult,
              },
            })}
          />
        </Block>
        <Block marginBottom={3}>
          <PhoneNumberInput
            label="Phone number (SMS)"
            placeholder="Phone number"
            defaultValue={personalInformation?.phone}
            hasErrors={!!errors["phone"]}
            errorMessage={errors["phone"]?.message}
            {...register("phone", {
              validate: {
                length: phoneNumberLengthMatch,
              },
            })}
          />
        </Block>
        <Block marginBottom={7}>
          <Textarea
            defaultValue={personalInformation?.about}
            errorMessage={errors["about"]?.message}
            hasErrors={!!errors["about"]}
            maxLength={500}
            label="About yourself"
            placeholder="Describe your history of healthcare practice"
            isRequired
            {...register("about", {
              required: "Please, describe your history of healthcare practice",
            })}
          />
        </Block>
        <Block textAlign="right">
          <Button filled type="submit">
            Next step {currentStep}/{totalSteps}
          </Button>
        </Block>
      </Form>
    );
  }
);

function validateAdult(dob: string) {
  // DOB can be an empty string
  const dobAsDate = maskedInputToDate(dob);
  const isYoungerThan18 = dobAsDate
    ? differenceInYears(Date.nowUniversal, dobAsDate) < 18
    : false;
  if (isYoungerThan18) {
    return "According to HIPAA, we can't create an account for a doctor under 18 years of age";
  }
  return true;
}

const Form = styled.form`
  position: relative;
  width: 100%;
`;

const AddPhotoLabel = styled.label`
  cursor: pointer;
  display: inline-block;
`;

const AvatarImage = styled(Avatar)`
  display: inline-block;
  margin: 16px auto 22px;
`.withComponent("span");
