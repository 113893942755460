import { useStore } from "effector-react";
import {
  lifeStyle as $lifeStyle,
  loadLifeStyle,
  symptomsLastYear,
} from "Patient/Record";
import { memo, useEffect, useState } from "react";
import { Block, Flex, RadioGroup, RecordRunningIcon, Typography } from "ui-kit";
import { DataContainer } from "./DataContainer";
import { EmptyStateContainer, EmptyStateIcon } from "./EmptyState";
import { LoaderWithSpacing } from "./Loader";

type Props = {
  patientId: string;
};

export const LifestylePage = memo(({ patientId }: Props) => {
  const [isDataLoading, setIsLoading] = useState(true);
  const lifeStyle = useStore($lifeStyle);

  useEffect(() => {
    loadLifeStyle(patientId).finally(() => setIsLoading(false));
  }, [patientId]);

  if (isDataLoading) {
    return <LoaderWithSpacing />;
  }

  if (Object.entries(lifeStyle).length === 0) {
    return <EmptyState />;
  }

  return (
    <DataContainer>
      <Block marginBottom={3}>
        <RadioGroup
          labels={["Yes", "No"]}
          defaultValue={lifeStyle.healthIssues}
          title="Do you have any health issues?"
          readOnly
        />
      </Block>
      <Block marginBottom={3}>
        <RadioGroup
          labels={["Yes", "No"]}
          defaultValue={lifeStyle.surgeries}
          title="Have you had any surgeries?"
          readOnly
        />
      </Block>
      <Block marginBottom={3}>
        <RadioGroup
          labels={["Yes", "No"]}
          defaultValue={lifeStyle.medicalProcedures}
          title="Have you had any medical procedures?"
          readOnly
        />
      </Block>
      <Block marginBottom={3}>
        <RadioGroup
          labels={["Yes", "No"]}
          defaultValue={lifeStyle.disabled}
          title="Are you disabled?"
          readOnly
        />
      </Block>
      <Block marginBottom={3}>
        <RadioGroup
          labels={["Yes", "No"]}
          defaultValue={lifeStyle.sleep7Hours}
          title="Do you sleep at least 7 hours a day?"
          readOnly
        />
      </Block>
      <Block marginBottom={3}>
        <RadioGroup
          labels={["Yes", "No"]}
          defaultValue={lifeStyle.playSports}
          title="Do you play sports?"
          readOnly
        />
      </Block>
      <Block marginBottom={3}>
        <RadioGroup
          labels={["Yes", "No"]}
          defaultValue={lifeStyle.fluVaccines}
          title="Do you take flu vaccines?"
          readOnly
        />
      </Block>
      <Block marginBottom={3}>
        <RadioGroup
          labels={["Yes", "No"]}
          defaultValue={lifeStyle.allergy}
          title="Do you have allergies?"
          readOnly
        />
      </Block>
      <Block marginBottom={3}>
        <RadioGroup
          labels={["Yes", "No"]}
          defaultValue={lifeStyle.smoke}
          title="Do you smoke or use tobacco?"
          readOnly
        />
      </Block>
      <Block marginBottom={3}>
        <RadioGroup
          labels={["Yes", "No"]}
          defaultValue={lifeStyle.drinkAlcohol}
          title="Do you drink alcohol?"
          readOnly
        />
      </Block>
      <Block marginBottom={3}>
        <RadioGroup
          labels={["Yes", "No"]}
          defaultValue={lifeStyle.drugs}
          title="Do you use drugs?"
          readOnly
        />
      </Block>
      <Block marginBottom={3}>
        <RadioGroup
          labels={["Yes", "No"]}
          defaultValue={lifeStyle.workWithToxicChemicals}
          title="Do you work with toxic chemicals?"
          readOnly
        />
      </Block>
      <Block marginBottom={3}>
        <RadioGroup
          labels={["Yes", "No"]}
          defaultValue={lifeStyle.hospitalizedByMentalIssues}
          title="Have you ever been hospitalized due to substance abuse, alcoholism, or mental health issues?"
          readOnly
        />
      </Block>
      <Block marginBottom={3}>
        <Block marginBottom={2}>
          <Typography type="h5">Symptoms in the last 12 months</Typography>
        </Block>
        <Block>
          <Typography type="h5" fontWeight={600}>
            {symptomsLastYear
              .filter((symptomName) =>
                lifeStyle.symptomsLastYear?.includes(symptomName)
              )
              .join(", ")}
          </Typography>
        </Block>
      </Block>
    </DataContainer>
  );
});

const EmptyState = () => (
  <EmptyStateContainer>
    <Flex justifyContent="center" marginBottom={4}>
      <EmptyStateIcon>
        <RecordRunningIcon width="86px" height="86px" />
      </EmptyStateIcon>
    </Flex>
    <Block marginBottom={1}>
      <Typography fontColor="N300" type="h2">
        No lifestyle
      </Typography>
    </Block>
    <Block>
      <Typography fontColor="N300" fontWeight={400} type="h3">
        Lifestyle of the patient will be displayed here
      </Typography>
    </Block>
  </EmptyStateContainer>
);
