import { apiServer } from "apiConnection";
import { ISO8601 } from "Doctor/Model";
import { attach, createEffect, createStore } from "effector";
import { successNotification } from "ui-kit";
import { medicineRecordAdapter } from "./DTOAdapter";
import { MedicineRecord, MedicineRecordDTO } from "./typeDefinitions";

export const loadMedicine = createEffect("loadMedicine", {
  handler: async (patientId: string) => await getMedicine(patientId),
});

export const addMedicineRecord = createEffect("addMedicineRecord", {
  handler: async ({
    patientId,
    type,
    name,
    description,
    periodFrom,
    periodTo,
  }: {
    patientId: string;
    type: "current" | "past";
    name: string;
    description: string;
    periodFrom?: ISO8601;
    periodTo?: ISO8601;
  }) =>
    await createMedicineRecord({
      patientId,
      type,
      name,
      description,
      periodFrom,
      periodTo,
    }),
});

addMedicineRecord.doneData.watch(() => {
  successNotification("Medicine was added");
});

export const editMedicineRecord = createEffect("editMedicineRecord", {
  handler: async ({
    patientId,
    recordId,
    type,
    name,
    description,
    periodFrom,
    periodTo,
  }: {
    patientId: string;
    recordId: string;
    type: "current" | "past";
    name: string;
    description: string;
    periodFrom?: ISO8601;
    periodTo?: ISO8601;
  }) =>
    await updateMedicineRecord({
      patientId,
      recordId,
      type,
      name,
      description,
      periodFrom,
      periodTo,
    }),
});

editMedicineRecord.doneData.watch(() => {
  successNotification("Medicine was updated");
});

const hydrated_moveMedicineToPast = createEffect("moveMedicineToPast", {
  handler: async ({
    patientId,
    recordId,
    store,
  }: {
    patientId: string;
    recordId: string;
    store: MedicineRecord[];
  }) => {
    const medicineRecord = store
      .filter(({ type }) => type === "current")
      .find(({ id }) => id === recordId);
    if (!medicineRecord) {
      throw new Error(`Medicine record with id ${recordId} not found`);
    }
    return await updateMedicineRecord({
      patientId,
      recordId,
      type: "past",
      name: medicineRecord.name,
      description: medicineRecord.description,
      periodFrom: medicineRecord.createdAt,
      periodTo: Date.nowUniversal.toISOString(),
    });
  },
});

hydrated_moveMedicineToPast.doneData.watch(() => {
  successNotification("Medicine was moved to past");
});

export const deleteMedicineRecord = createEffect("deleteMedicineRecord", {
  handler: async ({
    patientId,
    recordId,
  }: {
    patientId: string;
    recordId: string;
  }) => await removeMedicineRecord({ patientId, recordId }),
});

deleteMedicineRecord.doneData.watch(() => {
  successNotification("Medicine was removed");
});

async function getMedicine(patientId: string) {
  const { data: medicineDTO } = await apiServer.get<MedicineRecordDTO[]>(
    `/api/patient/${patientId}/medicine`
  );
  return medicineDTO;
}

async function createMedicineRecord({
  patientId,
  type,
  name,
  description,
  periodFrom,
  periodTo,
}: {
  patientId: string;
  type: "current" | "past";
  name: string;
  description: string;
  periodFrom?: ISO8601;
  periodTo?: ISO8601;
}) {
  const { data: createdRecord } = await apiServer.post<MedicineRecordDTO>(
    `/api/patient/${patientId}/medicine`,
    {
      type,
      name,
      description,
      periodFrom,
      periodTo,
    }
  );
  return createdRecord;
}

async function updateMedicineRecord({
  patientId,
  recordId,
  type,
  name,
  description,
  periodFrom,
  periodTo,
}: {
  patientId: string;
  recordId: string;
  type: "current" | "past";
  name: string;
  description: string;
  periodFrom?: ISO8601;
  periodTo?: ISO8601;
}) {
  const { data: updatedRecord } = await apiServer.post<MedicineRecordDTO>(
    `/api/patient/${patientId}/medicine/${recordId}`,
    {
      type,
      name,
      description,
      periodFrom,
      periodTo,
    }
  );
  return updatedRecord;
}

async function removeMedicineRecord({
  patientId,
  recordId,
}: {
  patientId: string;
  recordId: string;
}) {
  const { data: removedRecord } = await apiServer.delete<{ id: string }>(
    `/api/patient/${patientId}/medicine/${recordId}`
  );
  return removedRecord.id;
}

export const medicine = createStore<MedicineRecord[]>([])
  .on(loadMedicine.doneData, (state, medicineDTO) =>
    medicineDTO.map(medicineRecordAdapter)
  )
  .on(addMedicineRecord.doneData, (state, medicineDTO) => [
    ...state,
    medicineRecordAdapter(medicineDTO),
  ])
  .on(
    [editMedicineRecord.doneData, hydrated_moveMedicineToPast.doneData],
    (state, updatedRecordDTO) =>
      state.map((record) =>
        record.id === updatedRecordDTO.objectId
          ? medicineRecordAdapter(updatedRecordDTO)
          : record
      )
  )
  .on(deleteMedicineRecord.doneData, (state, recordId) =>
    state.filter((record) => record.id !== recordId)
  );

export const moveMedicineToPast = attach({
  effect: hydrated_moveMedicineToPast,
  source: medicine,
  mapParams: (
    { patientId, recordId }: { patientId: string; recordId: string },
    store
  ) => ({ patientId, recordId, store }),
});
