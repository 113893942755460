import styled from "@emotion/styled";
import { ElementType, HTMLProps, memo } from "react";
import { color } from "./theme";

type Props = HTMLProps<HTMLInputElement> & {
  as?: ElementType;
};

export const Toggle = memo((props: Props) => (
  <Switch>
    <Input {...props} type="checkbox" />
    <Slider className="slider" />
  </Switch>
));

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
`;

const Input = styled.input`
  height: 0;
  opacity: 0;
  width: 0;
  &:checked + .slider {
    background-color: ${color.G200};
    border-color: ${color.G200};
  }
  &:hover + .slider,
  &:focus + .slider {
    border-color: ${color.G200};
  }
  &:checked:hover + .slider,
  &:checked:focus + .slider {
    background-color: ${color.G300};
    border-color: ${color.G300};
  }
  &:checked + .slider::before {
    background-color: ${color.N0};
    transform: translateX(22px);
  }
  &:disabled + .slider {
    background-color: ${color.N200};
    border-color: ${color.N200};
    cursor: not-allowed;
  }
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border: 1px solid ${color.N300};
  border-radius: 100px;
  &::before {
    background-color: ${color.N300};
    border-radius: 50%;
    position: absolute;
    content: "";
    height: 16px;
    left: 4px;
    margin-top: -8px;
    top: 50%;
    transition: all 0.2s ease-out;
    width: 16px;
  }
`;
