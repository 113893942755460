import styled from "@emotion/styled";
import { memo } from "react";
import { Link as RouterLink, LinkProps } from "react-router-dom";
import { ChevronLeftIcon } from "./icons";
import { color } from "./theme";
import { Props as TypographyProps, Typography } from "./Typography";

type Props = {
  showLeft?: boolean;
} & TypographyProps &
  LinkProps;

export const UnifiedLink = memo(
  ({ children, showLeft, type, ...linkProps }: Props) => (
    <LinkComponent {...linkProps}>
      {showLeft && <ChevronLeftIcon stroke={color.G200} />}
      <LinkContent type={type} fontColor="G200" showLeft={showLeft}>
        {children}
      </LinkContent>
    </LinkComponent>
  )
);

const LinkComponent = styled(RouterLink)`
  &:hover span,
  &:focus span {
    color: ${color.G300};
    text-decoration-color: ${color.G300};
  }
  &:active span {
    color: ${color.G200};
  }
`;

type LinkContentProps = {
  showLeft?: boolean;
};

const LinkContent = styled(Typography)<LinkContentProps>`
  padding-left: ${({ showLeft }) => (showLeft ? "8px" : "0")};
  text-decoration: underline solid ${color.G200};
`;
