import styled from "@emotion/styled";
import { PageLayout } from "Doctor/Profile/Layout";
import {
  toDoctorVirtualVisitPage,
  toDoctorWaitingRoomPage,
  useDoctorId,
} from "Doctor/Router";
import { $visitStream } from "Doctor/VirtualVisit";
import { useStore } from "effector-react";
import { VideoPlayer } from "MediaStream";
import { fetchPatientById, usePatientId } from "Patient";
import { Patient } from "Patient/Model";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Block,
  BoxWithShadow,
  Button,
  color,
  Flex,
  Grid,
  Loader,
  Typography,
  UnifiedLink,
  VideoIcon,
} from "ui-kit";
import { fetchVisitById, Visit } from "Visit";
import { $accessController, checkAccess } from "./access";
import { Navigation } from "./Navigation";

type Props = {
  children: (patient: Patient) => ReactNode;
  title: string;
  recordSpecificControls?: (patientId: string, doctorId: string) => ReactNode;
};

export const PatientRecordLayout = ({
  children,
  title,
  recordSpecificControls,
}: Props) => {
  const [doctorId, patientId] = [useDoctorId(), usePatientId()];
  const { accessMap, isAccessDataLoaded } = useStore($accessController);
  const [loadingPatient, setPatientLoading] = useState(true);
  const [patient, setPatient] = useState<Patient>();
  const [visit, setVisit] = useState<Visit>();
  const { session, subscribers, visitId } = useStore($visitStream);
  const patientStream = subscribers.length > 0 ? subscribers[0] : undefined;
  const navigate = useNavigate();

  const goToWaitingRoom = useCallback(() => {
    navigate(toDoctorWaitingRoomPage(doctorId));
  }, [doctorId, navigate]);

  useEffect(() => {
    checkAccess({ doctorId, patientIdsList: [patientId] });
  }, [doctorId, patientId]);

  useEffect(() => {
    if (isAccessDataLoaded && accessMap[patientId]) {
      setPatientLoading(true);
      fetchPatientById(patientId)
        .then(setPatient)
        .finally(() => setPatientLoading(false));
    }
  }, [isAccessDataLoaded, accessMap, patientId]);

  useEffect(() => {
    if (visitId) {
      fetchVisitById(visitId).then(setVisit).catch(console.error);
    }
  }, [visitId]);

  if (isAccessDataLoaded && !accessMap[patientId]) {
    return (
      <PageLayout hideNavigation={!!session}>
        <ContentContainer textAlign="center">
          <Block marginBottom={1}>
            <Typography as="h1" type="h2">
              You don't have an access to the patient record.
            </Typography>
          </Block>
          <Block>
            <UnifiedLink type="h2" to={toDoctorWaitingRoomPage(doctorId)}>
              Go to waiting room
            </UnifiedLink>
          </Block>
        </ContentContainer>
      </PageLayout>
    );
  }

  if (loadingPatient) {
    // In case of running virtual visit, display column with running stream, and loader at the same time
    // I.e. don't hide column with a stream
    if (session) {
      return (
        <PageLayout hideNavigation>
          <Grid gridTemplateColumns="456px minmax(1064px, auto)" height="100%">
            <PatientOnVisitSidebar>
              <PatientStreamContainer
                marginBottom={2}
                onClick={() => {
                  if (visitId) {
                    navigate(toDoctorVirtualVisitPage(doctorId, visitId));
                  }
                }}
              >
                {patientStream?.video ? (
                  <PatientVideo
                    audioTrack={patientStream.audio}
                    videoTrack={patientStream.video}
                  />
                ) : (
                  <VideoIcon fill={color.N300} height="64px" width="64px" />
                )}
              </PatientStreamContainer>
              <Block marginBottom={2}>
                <Typography type="h2" wordBreak="normal">
                  {visit?.patient.personalInformation?.firstName}{" "}
                  {visit?.patient.personalInformation?.lastName}
                </Typography>
              </Block>
              {visit && (
                <Block>
                  {visit.description && (
                    <>
                      <Typography fontColor="N300" type="h3">
                        Message:
                      </Typography>{" "}
                      <Typography type="h4">{visit.description}</Typography>
                    </>
                  )}
                </Block>
              )}
            </PatientOnVisitSidebar>
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          </Grid>
        </PageLayout>
      );
    }
    return (
      <PageLayout>
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      </PageLayout>
    );
  }

  if (!patient) {
    return (
      <PageLayout hideNavigation={!!session}>
        <ContentContainer>
          <Typography as="h1" type="h2">
            Patient does not exist.{" "}
            <UnifiedLink type="inherit" to={toDoctorWaitingRoomPage(doctorId)}>
              Go to waiting room
            </UnifiedLink>
          </Typography>
        </ContentContainer>
      </PageLayout>
    );
  }

  // Display special layout when doctor is on a virtual visit
  if (session) {
    return (
      <PageLayout hideNavigation>
        <Grid gridTemplateColumns="456px minmax(1064px, auto)" height="100%">
          <PatientOnVisitSidebar>
            <PatientStreamContainer
              marginBottom={2}
              onClick={() => {
                if (visitId) {
                  navigate(toDoctorVirtualVisitPage(doctorId, visitId));
                }
              }}
            >
              {patientStream?.video ? (
                <PatientVideo
                  audioTrack={patientStream.audio}
                  videoTrack={patientStream.video}
                />
              ) : (
                <VideoIcon fill={color.N300} height="64px" width="64px" />
              )}
            </PatientStreamContainer>
            <Block marginBottom={2}>
              <Typography type="h2" wordBreak="normal">
                {visit?.patient.personalInformation?.firstName}{" "}
                {visit?.patient.personalInformation?.lastName}
              </Typography>
            </Block>
            {visit && (
              <Block>
                {visit.description && (
                  <>
                    <Typography fontColor="N300" type="h3">
                      Message:
                    </Typography>{" "}
                    <Typography type="h4">{visit.description}</Typography>
                  </>
                )}
              </Block>
            )}
          </PatientOnVisitSidebar>
          <Block>
            <Flex
              alignItems="center"
              justifyContent="center"
              marginBottom={5}
              marginTop={3}
              position="relative"
            >
              <Typography as="h1" type="h1">
                Patient record
              </Typography>
              <Block position="absolute" right="40px">
                <Button
                  onClick={() => {
                    if (visitId) {
                      navigate(toDoctorVirtualVisitPage(doctorId, visitId));
                    }
                  }}
                >
                  Go to visit
                </Button>
              </Block>
            </Flex>
            <ContentContainer>
              <Block marginBottom={5}>
                <Navigation />
              </Block>
              <PatientRecordDataContainer
                flexDirection="column"
                position="relative"
              >
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  paddingTop={5}
                  position="relative"
                  width="100%"
                >
                  <Typography as="h2" type="h1">
                    {title}
                  </Typography>
                  {recordSpecificControls && (
                    <Block position="absolute" right="40px">
                      {recordSpecificControls(patientId, doctorId)}
                    </Block>
                  )}
                </Flex>
                {children(patient)}
              </PatientRecordDataContainer>
            </ContentContainer>
          </Block>
        </Grid>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <Flex
        alignItems="center"
        justifyContent="center"
        marginBottom={5}
        marginTop={3}
        position="relative"
      >
        <Typography as="h1" type="h1">
          Patient record
        </Typography>
        <Block position="absolute" right="40px">
          <Button onClick={goToWaitingRoom}>Go to waiting room</Button>
        </Block>
      </Flex>
      <ContentContainer>
        <Block marginBottom={5}>
          <Navigation />
        </Block>
        <PatientRecordDataContainer flexDirection="column" position="relative">
          <Flex
            alignItems="center"
            justifyContent="center"
            paddingTop={5}
            position="relative"
            width="100%"
          >
            <Typography as="h2" type="h1">
              {title}
            </Typography>
            {recordSpecificControls && (
              <Block position="absolute" right="40px">
                {recordSpecificControls(patientId, doctorId)}
              </Block>
            )}
          </Flex>
          {children(patient)}
        </PatientRecordDataContainer>
      </ContentContainer>
    </PageLayout>
  );
};

const ContentContainer = styled(Block)`
  margin: 0 auto 24px;
  max-width: 984px;
`;

const LoaderContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  height: calc(100vh);
`;

const PatientRecordDataContainer = BoxWithShadow.withComponent("section");

const PatientOnVisitSidebar = styled(Block)`
  background-color: ${color.N0};
  padding: 48px 24px;
`;

const PatientStreamContainer = styled(Flex)`
  align-items: center;
  background-color: ${color.N100};
  border-radius: 10px;
  cursor: pointer;
  flex-direction: column;
  height: 272px;
  justify-content: center;
`;

const PatientVideo = styled(VideoPlayer)`
  height: 100%;
  width: 100%;
  & .video {
    border-radius: 10px;
  }
`;
