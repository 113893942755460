import styled from "@emotion/styled";
import { updateSchedule } from "Doctor/Auth/doctorSession";
import { ScheduleForm } from "Doctor/ScheduleForm";
import { useStore } from "effector-react";
import { memo, useState } from "react";
import {
  Block,
  BoxWithShadow,
  ChevronFold,
  color,
  DevicePhoneIcon,
  EnvelopeIcon,
  Flex,
  LoaderWithOverlay,
  LockIcon,
  successNotification,
  Typography,
  Watches5StrokedClockIcon,
} from "ui-kit";
import { PageLayout } from "../Layout";
import { DeviceNotifications } from "./DeviceNotifications";
import { MailUpdate } from "./MailUpdate";
import { PasswordUpdate } from "./PasswordUpdate";

type SettingSections = {
  notifications: boolean;
  payment: boolean;
  mail: boolean;
  password: boolean;
  schedule: boolean;
};

export const SettingsPage = memo(() => {
  const [foldState, changeFoldState] = useState<SettingSections>({
    notifications: true,
    mail: true,
    password: true,
    payment: true,
    schedule: true,
  });

  const toggleFoldState = (section: keyof SettingSections) => {
    changeFoldState({
      ...foldState,
      [section]: !foldState[section],
    });
  };

  const isUpdatingSchedule = useStore(updateSchedule.pending);

  return (
    <PageLayout>
      <SettingsContainer>
        <Block marginBottom={5} textAlign="center">
          <Typography as="h1" type="h1">
            Settings
          </Typography>
        </Block>
        <SettingsSection isFolded={foldState.notifications}>
          <SectionHeader
            className="header"
            onClick={() => toggleFoldState("notifications")}
          >
            <Flex alignItems="center">
              <Flex alignItems="center" marginRight={2}>
                <DevicePhoneIcon fill={color.N300} height="30px" width="30px" />
              </Flex>
              <Typography as="h2" type="h2">
                Device notification
              </Typography>
            </Flex>
            <FoldArrow className="arrow" />
          </SectionHeader>
          <SectionContent className="content">
            <DeviceNotifications />
          </SectionContent>
        </SettingsSection>
        <SettingsSection isFolded={foldState.schedule}>
          <SectionHeader
            className="header"
            onClick={() => toggleFoldState("schedule")}
          >
            <Flex alignItems="center">
              <Flex alignItems="center" marginRight={2}>
                <Watches5StrokedClockIcon height="30px" width="30px" />
              </Flex>
              <Typography as="h2" type="h2">
                Work schedule
              </Typography>
            </Flex>
            <FoldArrow className="arrow" />
          </SectionHeader>
          <SectionContent className="content" position="relative">
            {isUpdatingSchedule && <LoaderWithOverlay />}
            <ScheduleForm
              onSave={() => successNotification("Schedule was updated")}
              submitTitle="Save"
            />
          </SectionContent>
        </SettingsSection>
        <SettingsSection isFolded={foldState.mail}>
          <SectionHeader
            className="header"
            onClick={() => toggleFoldState("mail")}
          >
            <Flex alignItems="center">
              <Flex alignItems="center" marginRight={2}>
                <EnvelopeIcon fill={color.N300} height="30px" width="30px" />
              </Flex>
              <Typography as="h2" type="h2">
                Mail
              </Typography>
            </Flex>
            <FoldArrow className="arrow" />
          </SectionHeader>
          <SectionContent className="content">
            <MailUpdate />
          </SectionContent>
        </SettingsSection>
        <SettingsSection isFolded={foldState.password}>
          <SectionHeader
            className="header"
            onClick={() => toggleFoldState("password")}
          >
            <Flex alignItems="center">
              <Flex alignItems="center" marginRight={2}>
                <LockIcon fill={color.N300} height="30px" width="30px" />
              </Flex>
              <Typography as="h2" type="h2">
                Password
              </Typography>
            </Flex>
            <FoldArrow className="arrow" />
          </SectionHeader>
          <SectionContent className="content">
            <PasswordUpdate />
          </SectionContent>
        </SettingsSection>
      </SettingsContainer>
    </PageLayout>
  );
});

const SettingsContainer = styled(Block)`
  margin: 48px auto;
  width: 888px;
`;

type FoldState = {
  isFolded: boolean;
};

const SettingsSection = styled(BoxWithShadow)<FoldState>`
  ${({ isFolded }) => `
    flex-direction: column;
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
    & .header {
      background-color: ${isFolded ? color.N0 : color.N100};
    }
    & .content {
      display: ${isFolded ? "none" : "block"};
    }
    & .arrow {
      transform: ${isFolded ? "rotate(180deg)" : "none"};
    }
  `}
`.withComponent("section");

const SectionHeader = styled(Flex)`
  align-items: center;
  cursor: pointer;
  border-radius: 10px 10px 0 0;
  justify-content: space-between;
  padding: 32px;
`;

const SectionContent = styled(Block)`
  padding: 24px 32px;
`;

const FoldArrow = styled(ChevronFold)`
  cursor: pointer;
`;
