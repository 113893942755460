import styled from "@emotion/styled";
import { updatePassword as updatePasswordFx } from "Doctor/Auth";
import { doctorLoginPage } from "Doctor/Router";
import { useStore } from "effector-react";
import { memo, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  hasDigit,
  hasLowercaseCharacter,
  hasMoreThan8Symbols,
  hasSpecialCharacter,
  hasUppercaseCharacter,
} from "shared-functions/strongPasswordValidation";
import { Block, Button, LoaderWithOverlay, PasswordInput } from "ui-kit";

type FormData = {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmed: string;
};

export const PasswordUpdate = memo(() => {
  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm<FormData>();

  const navigate = useNavigate();

  const updatePassword = useCallback(
    ({ oldPassword, newPassword }: FormData) =>
      updatePasswordFx({ oldPassword, newPassword })
        .then(() => navigate(doctorLoginPage))
        .catch(console.error),
    [navigate]
  );

  const newPassword = watch("newPassword");

  const isUpdating = useStore(updatePasswordFx.pending);
  return (
    <Form onSubmit={handleSubmit(updatePassword)}>
      {isUpdating && <LoaderWithOverlay />}
      <Block marginBottom={3}>
        <PasswordInput
          {...register("oldPassword", {
            required: "Please, enter old password",
          })}
          hasErrors={!!errors["oldPassword"]}
          errorMessage={errors["oldPassword"]?.message}
          label="Old password"
          placeholder="Enter old password"
        />
      </Block>
      <Block marginBottom={3}>
        <PasswordInput
          {...register("newPassword", {
            validate: (password) => isPasswordValid(password),
          })}
          hasErrors={!!errors["newPassword"]}
          errorMessage={errors["newPassword"]?.message}
          label="New password"
          placeholder="Enter new password"
          enableSecurityCheck
        />
      </Block>
      <Block marginBottom={6}>
        <PasswordInput
          {...register("newPasswordConfirmed", {
            validate: (confirmedPassword) => {
              if (confirmedPassword !== newPassword) {
                return "Password doesn't match";
              }
              return true;
            },
          })}
          hasErrors={!!errors["newPasswordConfirmed"]}
          errorMessage={errors["newPasswordConfirmed"]?.message}
          label="Repeat new password"
          placeholder="Repeat new password"
        />
      </Block>
      <Block textAlign="center">
        <Button filled type="submit">
          Save
        </Button>
      </Block>
    </Form>
  );
});

function isPasswordValid(password: string = "") {
  return (
    hasLowercaseCharacter(password) &&
    hasUppercaseCharacter(password) &&
    hasSpecialCharacter(password) &&
    hasDigit(password) &&
    hasMoreThan8Symbols(password)
  );
}

const Form = styled.form`
  position: relative;
`;
