import styled from "@emotion/styled";
import { memo, ReactNode } from "react";
import { Tooltip as ReactTooltip, ITooltip } from "react-tooltip";
import { InfoIcon } from "./icons";
import { color } from "./theme";

type Props = ITooltip & {
  className?: string;
  children: ReactNode;
  id: string;
};

export const Tooltip = memo(
  ({ className, children, id, ...tooltipNativeProps }: Props) => (
    <>
      <TooltipButton className={className} data-tooltip-id={id} type="button">
        <TooltipIcon />
      </TooltipButton>
      <TooltipWindow {...tooltipNativeProps} id={id}>
        {children}
      </TooltipWindow>
    </>
  )
);

const TooltipButton = styled.button`
  border: none;
  background: none;
  height: 16px;
  margin: 0;
  outline: none;
  padding: 0;
  &:hover svg,
  &:focus svg {
    fill: ${color.O100};
    outline: none;
  }
`;

const TooltipIcon = styled(InfoIcon)`
  cursor: pointer;
  fill: ${color.N300};
`;

export const TooltipWindow = styled(ReactTooltip)`
  max-width: 320px;
  padding: 5px 8px;
  z-index: 1;
`;
