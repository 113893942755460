import { apiServer } from "apiConnection";
import { createEffect, createStore } from "effector";
import { successNotification } from "ui-kit";
import { HealthMetricsDTO } from "./typeDefinitions";

export const loadHealthMetrics = createEffect("loadHealthMetrics", {
  handler: async (patientId: string) => await getHealthMetrics(patientId),
});

export const saveHealthMetrics = createEffect("loadHealthMetrics", {
  handler: async ({
    patientId,
    healthMetrics,
  }: {
    patientId: string;
    healthMetrics: HealthMetricsDTO;
  }) => await saveHealthMetricsRequest(patientId, healthMetrics),
});

saveHealthMetrics.doneData.watch(() => {
  successNotification("Health metrics were updated");
});

async function getHealthMetrics(patientId: string) {
  const { data: healthMetrics } = await apiServer.get<HealthMetricsDTO>(
    `/api/patient/${patientId}/health-metrics`
  );
  return healthMetrics;
}

async function saveHealthMetricsRequest(
  patientId: string,
  healthMetrics: HealthMetricsDTO
) {
  const { data: savedHealthMetrics } = await apiServer.post<HealthMetricsDTO>(
    `/api/patient/${patientId}/health-metrics`,
    { healthMetrics }
  );
  return savedHealthMetrics;
}

export const healthMetrics = createStore<Partial<HealthMetricsDTO>>({}).on(
  [loadHealthMetrics.doneData, saveHealthMetrics.doneData],
  (state, healthMetrics) => healthMetrics
);
