import { memo, ReactNode } from "react";
import { Typography } from "./Typography";

type Props = {
  children?: ReactNode;
};
export const ErrorMessage = memo(({ children }: Props) => {
  if (!children) {
    return null;
  }
  return (
    <Typography type="h6" fontColor="R100">
      {children}
    </Typography>
  );
});
